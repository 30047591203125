import React from 'react';
import reactPaginateModule from 'react-paginate';

// React paginate is not properly exported as a compatible es-module.
// This is gross, but necessary for vitest currently.
// It appears that vitest is not importing as expected
// with a esModule export missing.
//
// TODO: It would be good to figure out if vite can do this.
const ReactPaginate = reactPaginateModule.default
  ? reactPaginateModule.default
  : reactPaginateModule;

function Paginator(props) {
  function handlePageChange(pageNumber) {
    props.setCurrentPage(pageNumber);
  }

  return (
    props.integration.paginationDetails.totalPages !== -1 && (
      // <div>Hi</div>
      <ReactPaginate
        previousLabel={'< Previous'}
        nextLabel={'Next >'}
        breakLabel={'...'}
        pageClassName={'page-item'}
        pageCount={props.integration.paginationDetails.totalPages}
        initialPage={props.currentPage}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={(event) => handlePageChange(event.selected)}
        pageLinkClassName={'page-link'}
        breakLinkClassName={'page-link'}
        previousLinkClassName={'page-link'}
        nextLinkClassName={'page-link'}
        containerClassName={'pagination m-0'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        disabledClassName={'disabled'}
        disableInitialCallback={true}
      />
    )
  );
}

export default Paginator;
