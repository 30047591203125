import React, { useContext, useState } from 'react';

import { css, StyleSheet } from 'aphrodite';

import BeauS from './featured_contractors/BeauS.png';
import DanielT from './featured_contractors/DanielT.png';
import MariaT from './featured_contractors/MariaT.png';
import MirissaS from './featured_contractors/MirissaS.png';
import NickyB from './featured_contractors/NickyB.jpg';

import { localeText } from '../locales/locale';
import { LocaleContext } from '../locales/LocaleContext';

const bios = [
  {
    name: 'Daniel T.',
    location: 'Indiana',
    image: DanielT,
    bio_translation_key: 'featured_contractor:daniel_t',
  },
  {
    name: 'Maria T.',
    location: 'Tenessee',
    image: MariaT,
    bio_translation_key: 'featured_contractor:maria_t',
  },
  {
    name: 'Mirissa S.',
    location: 'Oregon',
    image: MirissaS,
    bio_translation_key: 'featured_contractor:mirissa_s',
  },
  {
    name: 'Beau S.',
    location: 'New York',
    image: BeauS,
    bio_translation_key: 'featured_contractor:beau_s',
  },
  {
    name: 'Nicky B.',
    location: 'Minnesota',
    image: NickyB,
    bio_translation_key: 'featured_contractor:nicky_b',
  },
];

function FeaturedContractors() {
  const [selected, setSelected] = useState(0);
  const locale = useContext(LocaleContext);

  return (
    <>
      <h3>{localeText(locale)['featured_contractor:title']}</h3>
      <div className={'d-flex flex-row ' + css(styles.imageRoster)}>
        {bios.map((bio, ii) => (
          <img
            key={ii}
            src={bio.image}
            alt={bio.name}
            className={css(styles.image, selected === ii && styles.selected)}
            onClick={() => setSelected(ii)}
          />
        ))}
      </div>
      <p key={selected} className="mb-3 slide-up">
        {localeText(locale)[bios[selected].bio_translation_key]}
      </p>
      <p className={css(styles.bio)}>
        <strong>{bios[selected].name}</strong>
        <br />
        {localeText(locale)['featured_contractor:contractor_title']}
      </p>
    </>
  );
}

const styles = StyleSheet.create({
  bio: {
    width: '70%',
  },
  image: {
    boxSizing: 'content-box',
    width: '85px',
    height: '85px',
    border: '5px solid #FFFFFF',
    borderRadius: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    opacity: 0.5,
    cursor: 'pointer',
    ':not(:first-child)': {
      marginLeft: '15px',
    },
    ':hover': {
      opacity: 1,
      transform: 'scale(1.1)',
      boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
    },
    transition: 'opacity 0.1s linear, transform 0.1s linear, boxShadow 0.1s linear',
  },
  imageRoster: {
    overflowX: 'scroll',
    marginTop: '10px',
    paddingBottom: '30px',
  },
  selected: {
    opacity: 1,
  },
});

export default FeaturedContractors;
