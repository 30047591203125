import { durationString } from '~/helpers/numbers';

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function durationToDisplay(file) {
  if (file.duration) {
    return durationString(fileDuration(file));
  } else {
    return 'Unknown';
  }
}

//RETURNS IN SECONDS
export function fileDuration(file) {
  if (file.duration) {
    if (file.sourceS3File) {
      return Math.round(file.duration / 1000);
    } else {
      return Math.round(file.duration);
    }
  } else {
    return 'Unknown';
  }
}
