import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import WithIcon from '~/components/app/common/WithIcon';

function CollapsiblePanel(props) {
  // This state variable is ignored if props contains `open`.
  const [open, setOpen] = useState(true);

  function isControlled() {
    return props.hasOwnProperty('open');
  }

  function toggleOpen() {
    if (isControlled()) {
      props.setOpen && props.setOpen(!props.open);
    } else {
      setOpen(!open);
    }
  }

  function panelIsOpen() {
    return isControlled() ? props.open : open;
  }

  return (
    <React.Fragment>
      <div className={css(styles.headerBar)}>
        <div className={css(styles.headerTitle)} onClick={toggleOpen}>
          <WithIcon icon={panelIsOpen() ? 'fa fa-caret-down' : 'fa fa-caret-right'}>
            {props.title}
          </WithIcon>
        </div>

        <div className={css(styles.actionItems)}>{props.actionItems}</div>
      </div>
      <div className={css(panelIsOpen() ? styles.bodyOpen : styles.bodyClosed)}>
        {props.children}
      </div>
    </React.Fragment>
  );
}

CollapsiblePanel.propTypes = {
  title: PropTypes.string,
  actionItems: PropTypes.node,
  children: PropTypes.node,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

const styles = StyleSheet.create({
  headerBar: {
    width: '100%',
    padding: '8px 4px 8px 18px',
    backgroundColor: '#f3f3f3',
    borderBottom: '1px solid #ebebeb',
  },
  headerTitle: {
    display: 'inline-block',
    background: 'none',
    fontSize: '15px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  actionItems: {
    float: 'right',
  },
  bodyOpen: {},
  bodyClosed: {
    display: 'none',
  },
});

export default CollapsiblePanel;
