import React from 'react';
import PropTypes from 'prop-types';
function EditTableRow(props) {
  const wordlistAssignment = props.wordlistResource.wordlistAssignment;

  return (
    <tr>
      <td className="wordlist-name">{wordlistAssignment.wordlist.name}</td>
      <td>{props.wordlistResource.name}</td>
      <td>{wordlistAssignment.user.name}</td>
      <td>{wordlistAssignment.updatedAt}</td>
      <td className="wordlist-edit-link">
        <span
          onClick={() =>
            props.openWordlistModal(
              props.resourceType,
              props.resourceId,
              wordlistAssignment.wordlist
            )
          }
        >
          <i className="fa fa-file-text-o" /> Edit Wordlist
        </span>
      </td>
      <td className="wordlist-remove-link" onClick={() => props.showRemoveWordlistConfirmation()}>
        X
      </td>
    </tr>
  );
}

EditTableRow.propTypes = {
  openWordlistModal: PropTypes.func,
  resourceId: PropTypes.number,
  resourceType: PropTypes.string,
  showRemoveWordlistConfirmation: PropTypes.func,
  wordlistResource: PropTypes.object,
};

export default EditTableRow;
