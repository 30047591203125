import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import { useDropzone } from 'react-dropzone';

import { awsUploadFileQuery } from '~/components/app/order_form/data/queries';
import UploadTable from '~/components/app/order_more/components/UploadTable';

import { threeplayApi } from '~/logic/ThreeplayApi';
import { userLogger } from '~/logic/UserLogger';

let nextId = 1;

const InputAssetSelector = ({
  acceptedFiles,
  acceptedFileTypes,
  allowMultiple,
  disabled,
  instructions,
  rejectedFiles,
  setAssetData,
}) => {
  const [uploadDisabled, setUploadDisabled] = useState(disabled);

  //  5,368,709,120 bytes = 5 GB
  const maxSize = 5_368_709_120;

  useEffect(() => {
    if (allowMultiple === false) {
      if (acceptedFiles.length === 0) {
        setUploadDisabled(false);
      } else {
        setUploadDisabled(true);
      }
    }
  }, [acceptedFiles]);

  const onDrop = useCallback(
    async (accepted, rejected) => {
      // TODO: Talk to Nathanael to see what improvements can be made here
      if (accepted.length > 0) {
        accepted.forEach(async (file) => {
          const awsData = await fetchUploadData({ file: file });
          const s3Location = `s3://${awsData.bucket}/${awsData.key}`;
          const acceptedFile = {
            id: nextId++,
            name: file.name,
            externalUrl: s3Location,
            percentComplete: 0,
          };
          setAssetData((existing) => ({
            ...existing,
            acceptedFiles: [...existing.acceptedFiles, acceptedFile],
          }));
          uploadFileToS3(awsData, file);
        });
      }
      if (rejected) {
        setAssetData((existing) => ({ ...existing, rejectedFiles: rejected }));
      }
    },
    [acceptedFiles, rejectedFiles]
  );

  const fetchUploadData = async ({ file = file, type = 'newUpload', keyPrefix = '' }) => {
    const response = await threeplayApi.request(awsUploadFileQuery, {
      fileName: file.name,
      type: type,
      keyPrefix: keyPrefix,
    });
    return response.data.project.awsUpload;
  };

  const buildFormData = (awsData, file) => {
    const formData = new FormData();
    formData.append('key', awsData.key);
    formData.append('AWSAccessKeyId', awsData.accessKey);
    formData.append('acl', 'private');
    formData.append('policy', awsData.policy);
    formData.append('signature', awsData.signature);
    formData.append('success_action_status', 200);
    formData.append('file', file);
    return formData;
  };

  const updateProgress = useCallback(
    (e, file) => {
      if (e.lengthComputable) {
        setAssetData((existing) => {
          const percentComplete = Math.round((e.loaded * 100) / e.total);
          const matching = existing.acceptedFiles.find((item) => item.name === file.name);
          if (matching) {
            const updated = { ...matching, percentComplete: percentComplete };
            const others = existing.acceptedFiles.filter((item) => item.name !== file.name);
            return { ...existing, acceptedFiles: [...others, updated] };
          }
          return { ...existing };
        });
      }
    },
    [setAssetData]
  );

  const uploadFileToS3 = (awsData, file) => {
    const payload = buildFormData(awsData, file);
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (e) => updateProgress(e, file));
    xhr.open('POST', awsData.amazonUrl, true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
        const errorDetails = {
          status: xhr.status,
          statusText: xhr.statusText,
          response: xhr.responseText,
        };
        userLogger.logEvent('Order More: AdditionalAssets', 'S3 source upload error', {
          bucket: awsData.bucket,
          key: awsData.key,
          errorDetails: errorDetails,
        });
      }
    };
    xhr.send(payload);
  };

  const removeUploadedFile = (id) => {
    const keepers = acceptedFiles.filter((file) => file.id != id);
    setAssetData((existing) => ({ ...existing, acceptedFiles: keepers }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    disabled: uploadDisabled,
    multiple: allowMultiple,
    onDrop: onDrop,
    minSize: 0,
    maxSize: maxSize,
  });

  return (
    <>
      <div {...getRootProps({ className: css(styles.dropzone) })}>
        <input {...getInputProps()} />
        <div className={css(styles.centerText)}>
          <p>{instructions}</p>
          <Button variant="primary" disabled={uploadDisabled} tabIndex="123456789">
            Choose File
          </Button>
          <p>
            <small className="text-muted">File must be smaller than 5 GB</small>
          </p>
        </div>
      </div>
      {acceptedFiles.length > 0 && (
        <>
          <UploadTable acceptedFiles={acceptedFiles} removeUploadedFile={removeUploadedFile} />
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  centerText: {
    textAlign: 'center',
  },
  dragging: {
    opacity: 0.6,
  },
  dropzone: {
    border: '3px dashed #eee',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px 20px 10px',
    borderWidth: '2px',
    borderRadius: '2px',
    color: '#212529',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    ':focus': {
      borderColor: '#2196f3',
    },
    marginTop: '1em',
    marginBottom: '1em',
  },
  removeText: {
    ':hover': {
      cursor: 'not-allowed',
    },
  },
});

InputAssetSelector.propTypes = {
  acceptedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  acceptedFileTypes: PropTypes.object,
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  instructions: PropTypes.string,
  mediaFileId: PropTypes.number,
  rejectedFiles: PropTypes.arrayOf(PropTypes.object),
  setAssetData: PropTypes.func,
};

export default InputAssetSelector;
