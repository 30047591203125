import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CaptionPercentageLayout from './CaptionPercentageLayout';

import WithIcon from '~/components/app/common/WithIcon';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';
import { userLogger } from '~/logic/UserLogger';
import { vpiEditPath } from '~/helpers/app/paths';

import logoPath from '~/helpers/logos';
import { DOWNGRADED_LINKED_ACCOUNT_EDIT_TOOLTIP } from '~/helpers/constants';

function IntegrationDetails(props) {
  const allowEdit =
    props.enabledFeatures.includes('all_vpis') || ['YouTube', 'Vimeo'].includes(props.platformName);

  function deleteIntegration(integration, platformName) {
    props.integrationToBeRemoved(integration);
    props.setRemoveIntegrationModal(true);
    props.setPlatformToDelete(platformName);
  }

  return props.integrations.map((integration) => (
    <Card key={integration.id} className={css(styles.cardBorder, styles.margin)}>
      <Card.Body>
        <div className="text-center p-1">
          <Card.Img variant="top" className="img-fluid" src={logoPath(props.platformName)} />
        </div>
        <p className={css(styles.integrationName)}>{integration.displayName}</p>
        Created on {new Date(Number(integration.createdAt)).toLocaleDateString()}
        {integration.vpiMonthlyReports && integration.vpiMonthlyReports.length > 0 && (
          <CaptionPercentageLayout
            integration={integration}
            setCurrentIntegration={(integration) => props.setCurrentIntegration(integration)}
            setShowCaptionPercentageModal={(show) => props.setShowCaptionPercentageModal(show)}
          />
        )}
      </Card.Body>
      <Card.Footer>
        <TooltipIfErrors
          errors={allowEdit ? [] : [DOWNGRADED_LINKED_ACCOUNT_EDIT_TOOLTIP]}
          location="top"
        >
          <Button
            href={vpiEditPath(integration.id)}
            disabled={!allowEdit}
            variant="link"
            size="sm"
            className={css(styles.editLink, styles.link)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              userLogger.logEvent('LinkedAccounts', 'Edit Account', {
                'Video Integration': props.platformName,
              })
            }
          >
            <WithIcon icon="fa fa-pencil">Edit</WithIcon>
          </Button>
        </TooltipIfErrors>
        <Button
          variant="link"
          size="sm"
          className={css(styles.deleteButton, styles.link)}
          onClick={() => deleteIntegration(integration, props.platformName)}
        >
          <WithIcon icon="fa fa-times-circle">Remove Account</WithIcon>
        </Button>
      </Card.Footer>
    </Card>
  ));
}

const styles = StyleSheet.create({
  cardBorder: {
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.29)',
    minWidth: '250px',
  },
  margin: {
    margin: '0.5rem',
  },
  integrationName: {
    fontSize: '1rem',
    fontWeight: 700,
    color: '#343a40',
    paddingTop: '0.5rem',
    maxWidth: '200px',
  },
  editLink: {
    color: '#007EB5',
    padding: '0',
  },
  deleteButton: {
    color: '#B10000',
    float: 'right',
    padding: 0,
  },
  link: {
    ':hover': {
      textDecoration: 'none',
    },
    ':active': {
      textDecoration: 'none',
    },
  },
});

IntegrationDetails.propTypes = {
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  integrations: PropTypes.array.isRequired,
  platformName: PropTypes.string.isRequired,
  integrationToBeRemoved: PropTypes.func.isRequired,
  setRemoveIntegrationModal: PropTypes.func.isRequired,
  setShowCaptionPercentageModal: PropTypes.func.isRequired,
  setPlatformToDelete: PropTypes.func.isRequired,
  setCurrentIntegration: PropTypes.func.isRequired,
};

export default IntegrationDetails;
