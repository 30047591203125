import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { use3PMutation } from '~/logic/unstable/use3PMutation';
import { Form } from '@threeplayground/unstable';
import { Input } from '@threeplayground/index';

import { Alert, Button, Card, CloseButton, Modal } from 'react-bootstrap';
import { StyleSheet, css } from 'aphrodite';

const UPDATE_ORDER_TEMPLATE = `
mutation updateOrderTemplate($where: OrderTemplateWhereUniqueInput!, $data: OrderTemplateUpdateInput!){
  updateOrderTemplate(where: $where, data: $data){
    data {
      id
      name
    }
    errors {
      code
      message
    }
  }
}`;

const OrderTemplatesUpdateModal = ({
  handleClose,
  id,
  name,
  setGlobalErrors,
  setGlobalSuccessMessage,
  setTemplates,
}) => {
  const [fieldErrors, setFieldErrors] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { isSubmitting },
  } = useForm({});

  const { mutateAsync } = use3PMutation(UPDATE_ORDER_TEMPLATE, {
    extractKey: 'updateOrderTemplate',
  });

  const onSubmit = async (data) => {
    const {
      data: mutationData,
      errors,
      globalErrors: mutationGlobalErrors,
    } = await mutateAsync({
      data,
      where: {
        idEq: id,
      },
    });
    setGlobalSuccessMessage(undefined); // Clear it, since it might have info from other actions.
    setGlobalErrors(mutationGlobalErrors || undefined);
    setFieldErrors(errors || null);

    if (mutationData && !errors && !mutationGlobalErrors) {
      setTemplates((templates) => {
        // Replace the name of the template that matches the submitted ID.
        // Leave the other templates alone while mapping.
        return templates.map((t) =>
          t.id === mutationData.id
            ? {
                ...t,
                ...{ name: data.name },
              }
            : t
        );
      });
      resetField('name');
      handleClose();
      setGlobalSuccessMessage(`Your Order Template is now named "${data.name}"!`);
    }
  };

  const closeModal = () => {
    resetField('name');
    handleClose();
  };

  return (
    <>
      <Modal size="lg" backdrop="static" show={!!id} onHide={closeModal} animation={false}>
        <Form onSubmit={handleSubmit(onSubmit)} onReset={reset} errors={fieldErrors}>
          <Modal.Header>
            <Card style={{ border: 0 }}>
              <Card.Title className={css(styles.modalTitle)}>
                Rename Order Template: {name}
              </Card.Title>
            </Card>
            <CloseButton onClick={closeModal} />
          </Modal.Header>
          <Modal.Body>
            {fieldErrors && <Alert variant="danger">{fieldErrors[0].message}</Alert>}
            <Input label="New Name" type="string" required {...register('name')} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  modalTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#535353',
    marginBottom: '.5rem',
  },
});

OrderTemplatesUpdateModal.propTypes = {
  handleClose: PropTypes.func,
  id: PropTypes.number,
  setGlobalErrors: PropTypes.func,
  setGlobalSuccessMessage: PropTypes.func,
  setTemplates: PropTypes.func,
};

export default OrderTemplatesUpdateModal;
