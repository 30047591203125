import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import SmpteSettings from './SmpteSettings';
import TranscriptSettings from './TranscriptSettings';
import WebSettings from './WebSettings';

class SelectedFormats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      title: this.props.formatTypeInfo['title'],
    };
  }

  removeFormat(format) {
    this.props.toggleSelectedFormat(this.props.formatType, format);
  }

  presentAdditionalSettings(formatType) {
    switch (formatType) {
      case 'smpteCaptionFormats':
        return (
          <SmpteSettings
            updateFormatSettings={this.props.updateFormatSettings}
            settings={this.props.formatTypeInfo['settings']}
            defaultSettings={this.props.formatTypeInfo['defaultSettings']}
            setError={this.props.setError}
            formatType={formatType}
          />
        );
      case 'transcriptFormats':
        return (
          <TranscriptSettings
            updateFormatSettings={this.props.updateFormatSettings}
            settings={this.props.formatTypeInfo['settings']}
            setError={this.props.setError}
            formatType={formatType}
          />
        );
      case 'webCaptionFormats':
        return (
          <WebSettings
            updateFormatSettings={this.props.updateFormatSettings}
            settings={this.props.formatTypeInfo['settings']}
            setError={this.props.setError}
            formatType={formatType}
          />
        );
      default:
        return <span></span>;
    }
  }

  getFormatDisplayName(format) {
    if (format.adFormat) {
      if (this.props.formatTypeInfo['mixedAssets']['formats'].find((f) => f.id === format.id)) {
        return 'Audio + AD Mixed (.' + format.ext + ')';
      } else if (this.props.formatTypeInfo['adAssets']['formats'].find((f) => f.id === format.id)) {
        return 'AD Only (.' + format.ext + ')';
      } else {
        return format['name'];
      }
    } else {
      return format['name'];
    }
  }

  render() {
    return (
      <>
        <h6>{this.state.title}</h6>
        <div>
          {this.props.formatTypeInfo['selectedFormats'].map((format, index) => (
            <Badge
              className="format-pill"
              key={format['name'] + '-' + index}
              pill
              variant="secondary"
            >
              {this.getFormatDisplayName(format)}{' '}
              <i className="fa fa-times" onClick={() => this.removeFormat(format)} />
            </Badge>
          ))}
        </div>
        {this.presentAdditionalSettings(this.props.formatType)}
      </>
    );
  }
}

SelectedFormats.propTypes = {
  toggleSelectedFormat: PropTypes.func,
  formatType: PropTypes.string,
  setError: PropTypes.func,
  bulk: PropTypes.bool,
  updateFormatSettings: PropTypes.func,
  formatTypeInfo: PropTypes.object,
};

export default SelectedFormats;
