if (!Object.fromEntries) {
  Object.fromEntries = (arr) => Object.assign({}, ...Array.from(arr, ([k, v]) => ({ [k]: v })));
}

/* 
Since we don't have localhost in the name of our development host, we need to
add a polyfill to crypto for randomUUID
https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID

Check if the crypto object exists and if randomUUID is not available
*/
if (typeof crypto !== 'undefined' && typeof crypto.randomUUID !== 'function') {
  // Monkey-patch the crypto object to add a randomUUID method
  crypto.randomUUID = function () {
    // Fallback to a custom UUID generator
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
}
