import { PunctuationFormatter, PunctuationFormatterConfig } from '../_types';

export class EnclosingPunctuationFormatter implements PunctuationFormatter {
  command: PunctuationFormatterConfig['command'];
  punctuation: PunctuationFormatterConfig['punctuation'];

  /**
   * A formatter that adds the first character of the punctuation before the
   * next word and last character of the punctuation after the next word.
   *
   * @example new EnclosingPunctuationFormatter({ command: 'bracket this', punctuation: '[]' }).format("hello bracket this applause welcome")
   * // returns "hello [applause] welcome"
   */
  constructor({ command, punctuation }: PunctuationFormatterConfig) {
    this.command = command;
    this.punctuation = punctuation;
  }

  format(text: string) {
    return text.replace(
      this.regex(),
      (_match, p1: string) => `${this.punctuation[0] || ''}${p1}${this.punctuation[1] || ''}`
    );
  }

  regex() {
    return new RegExp(`${this.command}\\s(\\w*)`, 'gi');
  }
}
