import React from 'react';
import PropTypes from 'prop-types';

import EnsembleLibrariesComponent from './EnsembleLibrariesComponent';
import SearchComponent from './SearchComponent';
import YouTubePlaylistDropDown from './YouTubePlaylistDropDown';

const PLATFORMS_WITH_NOTE_SECTION = ['Dropbox', 'Vimeo'];

function FilterComponent(props) {
  return (
    <div className="align-items-center d-flex">
      {PLATFORMS_WITH_NOTE_SECTION.includes(props.integration.platformName) && (
        <PlatformNoteSection platform={props.integration.platformName} />
      )}
      {props.integration.platformName === 'Ensemble' && <EnsembleLibrariesComponent {...props} />}
      {props.integration.platformName === 'YouTube' && <YouTubePlaylistDropDown {...props} />}
      {props.supportSearching && <SearchComponent {...props} />}
    </div>
  );
}
function PlatformNoteSection(props) {
  function noteText(platform) {
    switch (platform) {
      case 'Dropbox': {
        return 'limit upload files to 2GB for best results. Uploads over 5GB are forbidden';
      }
      case 'Vimeo': {
        return "due to limitations in Vimeo's system, private videos may not show up in search results.";
      }
    }
  }

  return (
    <small className="justify-content-start text-muted">
      <b>NOTE: </b>
      {noteText(props.platform)}
    </small>
  );
}

FilterComponent.propTypes = {
  integration: PropTypes.object,
  supportSearching: PropTypes.bool,
  selectedPlaylistId: PropTypes.string,
};

PlatformNoteSection.propTypes = {
  platform: PropTypes.string,
};

export default FilterComponent;
