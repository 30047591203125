import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from "aphrodite";
import Card from "react-bootstrap/Card";

const TipPanelContainer = ({ children, title }) => {
  return (
    <Card className="mt-3">
      <Card.Header as="h5" className={css(styles.header)}>
        {title}
      </Card.Header>
      {children}
    </Card>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#F6F6F6',
  },
});

TipPanelContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default TipPanelContainer;
