import React from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import { palette } from '~/components/contractor_applications/theme';

function StepCircle(props) {
  return <div className={css(circleStyles.step, circleStyles[props.variant])}></div>;
}

StepCircle.propTypes = {
  variant: PropTypes.string.isRequired,
};

const circleStyles = StyleSheet.create({
  step: {
    display: 'inline-block',
    width: '17px',
    height: '17px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '2px',
    backgroundColor: palette.shaded,
    marginInlineEnd: '1em',
  },
  current: {
    backgroundColor: palette.background,
  },
  complete: {
    backgroundColor: palette.primary,
  },
});

function VerticalStepLine(props) {
  return (
    <div className={css(verticalLineStyles.main, props.complete && verticalLineStyles.complete)} />
  );
}

VerticalStepLine.propTypes = {
  complete: PropTypes.bool,
};

const verticalLineStyles = StyleSheet.create({
  main: {
    position: 'absolute',
    left: '7px',
    top: '-7px',
    zIndex: '-1',
    borderLeft: '3px solid',
    width: '0',
    height: '100%',
  },
  complete: {
    borderColor: palette.primary,
  },
});

function StepperNav(props) {
  function stepState(step) {
    if (step < props.currentStep) {
      return 'complete';
    } else if (step == props.currentStep) {
      return 'current';
    } else {
      return 'pending';
    }
  }

  return (
    <div className="d-flex flex-column">
      {props.children.map((step, ii) => {
        const state = stepState(ii);
        return (
          <div key={ii} className={css(styles.step, styles[state])}>
            {ii > 0 && <VerticalStepLine variant={state} />}
            <StepCircle variant={state} />
            {step}
          </div>
        );
      })}
    </div>
  );
}

StepperNav.propTypes = {
  children: PropTypes.node,
  currentStep: PropTypes.number,
};

StepperNav.defaultProps = {
  steps: [],
  currentStep: 0,
};

const styles = StyleSheet.create({
  step: {
    lineHeight: 1,
    position: 'relative',
    ':not(:first-child)': {
      paddingTop: '1.5em',
    },
  },
  pending: {
    borderColor: palette.inactive,
    color: palette.inactive,
  },
  current: {
    borderColor: palette.primary,
    color: palette.primary,
  },
  complete: {
    borderColor: palette.primary,
    color: palette.primary,
  },
});

StepperNav.Step = (props) => props.children;

StepperNav.Step.displayName = 'Step';

StepperNav.Step.propTypes = {
  state: PropTypes.oneOf(['pending', 'current', 'complete']),
  children: PropTypes.node,
};

export default StepperNav;
