import { threeplayApi } from '~/logic/ThreeplayApi';

import { linkedAccountsQuery } from '~/components/app/video_platform_integrations/data/queries';
import useGraphQLQuery from '~/hooks/useGraphQLQuery';

const useLinkedAccounts = () => {
  const { data, errors, loading } = useGraphQLQuery(() =>
    threeplayApi.request(linkedAccountsQuery, { livePlatformsOnly: false })
  );
  return { linkedAccounts: data?.project?.linkedAccounts, errors, loading };
};

export default useLinkedAccounts;
