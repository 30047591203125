import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { hms } from '~/lib/global/time-utils';

import { jobShape } from '../shapes';

function jobsDurationString(jobs) {
  const totalDuration = jobs.reduce((total, job) => total + job.duration, 0);
  return hms(totalDuration) || '--:--:--';
}

function MarketSummary({ jobs }) {
  const totalJobs = useMemo(() => jobs.length ?? '--', [jobs]);
  const totalDuration = useMemo(() => jobsDurationString(jobs), [jobs]);
  const forcedDuration = useMemo(() => jobsDurationString(jobs.filter((j) => j.forceList)), [jobs]);
  const topDuration = useMemo(() => jobsDurationString(jobs.filter((j) => j.topOfQueue)), [jobs]);

  return (
    <div>
      {totalJobs} jobs &sdot; Total Duration: {totalDuration} &sdot; Forced: {forcedDuration} &sdot;
      Top: {topDuration}
    </div>
  );
}

MarketSummary.propTypes = {
  jobs: PropTypes.arrayOf(jobShape),
};

export default MarketSummary;
