import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import WithIcon from '~/components/app/common/WithIcon';

function errorTooltip(title, errors) {
  return (
    <Tooltip>
      <div className={css(styles.tooltip)}>
        <div>{title}</div>
        {errors.map((error, ii) => (
          <div key={ii}>
            <WithIcon icon="fa fa-ban">{error}</WithIcon>
          </div>
        ))}
      </div>
    </Tooltip>
  );
}

function TooltipIfErrors(props) {
  if (props.errors && props.errors.length > 0) {
    if (props.noSpan) {
      return (
        <OverlayTrigger
          placement={props.location || 'auto'}
          overlay={errorTooltip(props.title, props.errors)}
          style="position: relative"
          trigger={['hover', 'focus']}
        >
          {props.children}
        </OverlayTrigger>
      );
    } else {
      return (
        <OverlayTrigger
          placement={props.location || 'auto'}
          overlay={errorTooltip(props.title, props.errors)}
          trigger={['hover', 'focus']}
        >
          {/* inline-block required on disabled elements https://getbootstrap.com/docs/4.0/components/tooltips/#disabled-elements */}
          {/* Add 'pointer-events: none' to your button or component if you want a consistent tooltip that doesn't hang
          https://stackoverflow.com/questions/13311574/how-to-enable-bootstrap-tooltip-on-disabled-button */}
          <span className={css(styles.inline)}>{props.children}</span>
        </OverlayTrigger>
      );
    }
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
}

TooltipIfErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  noSpan: PropTypes.bool,
  title: PropTypes.string,
};

const styles = StyleSheet.create({
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  inline: {
    display: 'inline-block',
  },
});

export default TooltipIfErrors;
