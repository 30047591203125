import React from 'react';

import './theme.css';

/**
 * Right now, this context does nothing other than import the theme.css
 * As we enhance it, this should accept a configurable theme instead, but provide
 * a default.
 */
const ThemeContext = React.createContext();
const { Provider } = ThemeContext;

export const ThemeProvider = ({ children }) => <Provider>{children}</Provider>;
