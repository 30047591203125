import { OutputFormatCodeEnum } from '../common/constants';

export const offPlatformDubbingJobsPaths = {
  show: {
    getLink: (id: string) => `/off_platform_dubbing_jobs/${id}`,
    route: '/off_platform_dubbing_jobs/:id',
  },
  downloadInputTranscript: {
    getLink: (id: string, outputFormatCode: OutputFormatCodeEnum) => {
      const outputFormat = outputFormatCode ? `&output_format=${outputFormatCode}` : '';

      return `/off_platform_dubbing_jobs/download_input_transcript?id=${id}${outputFormat}`;
    },
  },
};
