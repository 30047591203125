import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

function MessageList(props) {
  return (
    <div className={css(styles.queue)}>
      {props.messages.map((msg, ii) => (
        <div key={ii} className={css(styles.message, styles[props.variant])}>
          {msg}
        </div>
      ))}
    </div>
  );
}

MessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.oneOf(['error', 'warning', 'info']),
};

MessageList.defaultProps = {
  variant: 'info',
};

const styles = StyleSheet.create({
  error: {
    borderColor: '#c88',
    backgroundColor: '#fcc',
    color: '#a66',
  },
  warning: {
    borderColor: '#8B621988',
    backgroundColor: '#fff2d0',
    color: '#8B6219',
  },
  info: {
    borderColor: '#8bc',
    backgroundColor: '#cef',
    color: '#68a',
  },
  message: {
    borderRadius: '5px',
    borderWidth: '2px',
    borderStyle: 'solid',
    padding: '0.3em 0.7em',
    margin: '3px 0px',
  },
  queue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
});

export default MessageList;
