import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';
import { offPlatformVendorTranscriptionJobsPaths } from './offPlatformVendorTranscriptionJobsPaths';
import { OffPlatformVendorTranscriptionJob } from './:id/OffPlatformVendorTranscriptionJob';

function OffPlatformVendorTranscriptionJobsApp({ permissions }: RootProps) {
  return (
    <JobAppContainer
      showPath={offPlatformVendorTranscriptionJobsPaths.show.route}
      jobComponent={<OffPlatformVendorTranscriptionJob />}
      permissions={permissions}
    />
  );
}

export default OffPlatformVendorTranscriptionJobsApp;
