import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['partialSubmit', 'setExcludedDatesInputContainer', 'setExcludedDatesPicker'];

  addSet() {
    document.getElementById('live_order_include_set').value = 'true';
    this.partialSubmit();
  }

  addSetExcludedDate() {
    const newDate = this.setExcludedDatesPickerTargets[0].value;
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = 'live_order[set_excluded_dates][]';
    hiddenInput.value = newDate;
    this.setExcludedDatesInputContainerTargets[0].appendChild(hiddenInput);
    this.partialSubmit();
  }

  disableModalButtons() {
    document
      .querySelector('#turbo_frame_form')
      .querySelectorAll('button[data-toggle="modal"]')
      .forEach((target) => {
        target.disabled = true;
      });
  }

  partialSubmit() {
    this.disableModalButtons();
    this.partialSubmitTargets.forEach((target) => target.click());
  }

  removeSet() {
    document.getElementById('live_order_include_set').value = 'false';
    this.partialSubmit();
  }

  removeSetExcludedDate(event) {
    const listItem = event.target.closest('li.list-group-item');
    const date = listItem.querySelector('span').textContent.trim();
    const hiddenInputs =
      this.setExcludedDatesInputContainerTargets[0].querySelectorAll('input[type="hidden"]');
    hiddenInputs.forEach((input) => {
      if (input.value === date) {
        input.remove();
      }
    });
    this.partialSubmit();
  }
}
