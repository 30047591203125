import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { difference } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPencilAlt,
  faPlusCircle,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import { VALID_CHARACTERS } from './utils/keyboardShortcutsUtil';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';
function AvailableCharacters({ character, mappedKeys }) {
  // We're doing some string comparison, so we normalize the casing.
  const normalizedCharacter = character.toUpperCase();
  const normalizedMappedKeys = mappedKeys.map((key) => key.toUpperCase());

  // mappedKeys includes the key we'd like to modify, so we filter it out from the keys to omit.
  const alreadyMappedKeys = normalizedMappedKeys.filter((key) => key !== normalizedCharacter);
  const availableCharacters = difference(VALID_CHARACTERS, alreadyMappedKeys);

  return availableCharacters.map((characterToDisplay) => {
    // The reducer that manages this state expects lowercase values.
    // Passing it uppercase appears to work, but triggers extra renders and edge-case bugs
    // TODO: Update this set of components to improve this contract
    const optionValue = characterToDisplay.toLowerCase();

    return (
      <option key={optionValue} value={optionValue}>
        {characterToDisplay}
      </option>
    );
  });
}

const DeleteButton = ({ index, keyboardShortcutsDispatcher }) => {
  return (
    <span className="ml-4">
      <FontAwesomeIcon
        className={`text-danger ${css(styles.iconButton)}`}
        icon={faTrashAlt}
        onClick={() => keyboardShortcutsDispatcher({ type: 'delete', shortcutIndex: index })}
      />
    </span>
  );
};
function KeyboardShortcuts({ keyboardShortcuts, keyboardShortcutsDispatcher }) {
  const mappedKeys = keyboardShortcuts
    .filter((shortcut) => shortcut.character === shortcut.editedCharacter)
    .map((shortcut) => shortcut.character)
    // Remove any "undefined" keys
    .filter((key) => key !== undefined);

  const keyboardShortcutLimitReached = mappedKeys.length === VALID_CHARACTERS.length;

  const updateEditedShortcut = (index, newShortcuts) => {
    keyboardShortcutsDispatcher({ type: 'edit', shortcut: newShortcuts, shortcutIndex: index });
  };

  return (
    <>
      <h2 className="swatei-title">Custom Text Shortcuts</h2>
      <Table>
        <tbody>
          {keyboardShortcuts?.map((shortcut, index) => {
            return (
              <tr key={index}>
                {shortcut.editing ? (
                  <>
                    <td>
                      <Form>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            updateEditedShortcut(index, { editedCharacter: e.target.value })
                          }
                          size="sm"
                          value={shortcut.editedCharacter}
                        >
                          <AvailableCharacters
                            character={shortcut.editedCharacter}
                            mappedKeys={mappedKeys}
                          />
                        </Form.Control>
                      </Form>
                    </td>
                    <td className={css(styles.validationContainer)}>
                      <Form>
                        <Form.Control
                          isInvalid={shortcut.error}
                          onChange={(e) =>
                            updateEditedShortcut(index, { editedCommand: e.target.value })
                          }
                          size="sm"
                          value={shortcut.editedCommand}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {shortcut.error}
                        </Form.Control.Feedback>
                      </Form>
                    </td>
                    <td className="text-right">
                      <span className="mr-4">
                        <FontAwesomeIcon
                          className={`text-success ${css(styles.iconButton)}`}
                          icon={faCheck}
                          onClick={() =>
                            keyboardShortcutsDispatcher({ type: 'update', shortcutIndex: index })
                          }
                        />
                      </span>
                      <span>
                        <FontAwesomeIcon
                          className={`text-danger ${css(styles.iconButton)}`}
                          icon={faTimes}
                          onClick={() =>
                            keyboardShortcutsDispatcher({
                              type: 'toggleEditing',
                              shortcutIndex: index,
                            })
                          }
                        />
                      </span>
                      <DeleteButton
                        index={index}
                        keyboardShortcutsDispatcher={keyboardShortcutsDispatcher}
                      />
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <span className="p-2 keyboard-button">Ctrl</span>
                      <span className="p-2">+</span>
                      <span className="p-2 keyboard-button">
                        {keyboardShortcuts[index]?.character.toUpperCase()}
                      </span>
                    </td>
                    <td>{keyboardShortcuts[index]?.command}</td>
                    <td className="text-right">
                      <span>
                        <FontAwesomeIcon
                          className={`icon-threeplay-blue ${css(styles.iconButton)}`}
                          icon={faPencilAlt}
                          onClick={() =>
                            keyboardShortcutsDispatcher({
                              type: 'toggleEditing',
                              shortcutIndex: index,
                            })
                          }
                        />
                      </span>
                      <DeleteButton
                        index={index}
                        keyboardShortcutsDispatcher={keyboardShortcutsDispatcher}
                      />
                    </td>
                  </>
                )}
              </tr>
            );
          })}
          <tr>
            <td colSpan="4">
              <TooltipIfErrors
                errors={
                  keyboardShortcutLimitReached
                    ? [
                        'All keyboard shortcuts are currently in use. Please delete an existing shortcut to create a new command.',
                      ]
                    : []
                }
              >
                <Button
                  aria-label="Add New Shortcut"
                  className={
                    keyboardShortcutLimitReached ? css(styles.disabled) : css(styles.iconButton)
                  }
                  onClick={() => keyboardShortcutsDispatcher({ type: 'create' })}
                  size="sm"
                  variant="link"
                  disabled={keyboardShortcutLimitReached}
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> New Shortcut
                </Button>
              </TooltipIfErrors>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

AvailableCharacters.propTypes = {
  character: PropTypes.string,
  mappedKeys: PropTypes.arrayOf(PropTypes.string),
};

DeleteButton.propTypes = {
  index: PropTypes.number,
  keyboardShortcutsDispatcher: PropTypes.func,
};

KeyboardShortcuts.propTypes = {
  keyboardShortcuts: PropTypes.arrayOf(
    PropTypes.shape({
      character: PropTypes.string,
      command: PropTypes.string,
      editing: PropTypes.bool,
    })
  ),
  keyboardShortcutsDispatcher: PropTypes.func,
};

const styles = StyleSheet.create({
  disabled: {
    pointerEvents: 'none',
  },
  iconButton: {
    border: 'none',
    ':hover': {
      cursor: 'pointer',
    },
  },
  validationContainer: {
    position: 'relative',
  },
});

export default KeyboardShortcuts;
