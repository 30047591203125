import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import Card from 'react-bootstrap/Card';

import ScheduledOrderForm from './ScheduledOrderForm';

import { StyleSheet, css } from 'aphrodite';

const ScheduledOrdersModal = ({
  handleClose,
  show,
  transcriptionLanguages,
  fromEnglishLanguages,
  toEnglishLanguages,
}) => {
  return (
    <>
      <Modal size="lg" backdrop="static" show={show} onHide={handleClose} animation={false}>
        <Modal.Header>
          <Card style={{ border: 0 }}>
            <Card.Title className={css(styles.modalTitle)}>Add Scheduled Order Details</Card.Title>
            <Card.Subtitle>
              <small>All fields below are required.</small>
            </Card.Subtitle>
          </Card>
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <ScheduledOrderForm
            handleClose={handleClose}
            transcriptionLanguages={transcriptionLanguages}
            fromEnglishLanguages={fromEnglishLanguages}
            toEnglishLanguages={toEnglishLanguages}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  modalTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#535353',
    marginBottom: '.5rem',
  },
});

ScheduledOrdersModal.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  transcriptionLanguages: PropTypes.arrayOf(PropTypes.shape({ fullName: PropTypes.string })),
  fromEnglishLanguages: PropTypes.arrayOf(PropTypes.string),
  toEnglishLanguages: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduledOrdersModal;
