.grid {
  --padding-lg: 24px;
  --padding-sm: 10px;
  display: grid;
  grid-template-areas: 'header header' 'form preview' 'form share';
  grid-template-columns: fit-content(40%) 1fr;
  grid-template-rows: min-content 2.5fr auto;
  height: 100%;
}

.nav {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-sm) var(--padding-lg);
  border-bottom: 2px solid #007eb5;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

nav h1 {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
}

.branding {
  display: flex;
  align-items: center;
}

.branding img {
  height: 1.5rem;
}

.branding > :first-child {
  margin-right: 0.5rem;
}

.form {
  min-width: 300px;
  max-width: 500px;
  grid-area: form;
  background-color: #fff;
  padding: var(--padding-lg);
  filter: drop-shadow(0px 2px 5px rgba(63, 61, 73, 0.1));
  overflow: auto;
  z-index: 2;
}

.preview {
  grid-area: preview;
  background: rgb(253, 254, 255);
  background-image: radial-gradient(rgb(232, 232, 241) 1px, transparent 0);
  background-size: 16px 16px;
  background-position: -19px -19px;
  overflow: hidden;
  position: relative;
}

.preview h2,
.share h2 {
  font-size: 1.25rem;
}

.preview h2 {
  position: absolute;
  top: 0;
  left: 0;
  padding: var(--padding-lg);
  z-index: 10;
}

.share {
  background-color: white;
  grid-area: share;
  padding: var(--padding-lg);
  border-top: 1px solid #eee;
  box-shadow: -1px 0 5px rgba(52, 53, 61, 0.1);
  z-index: 1;
}

.playerCanvas {
  padding: var(--padding-lg);
  overflow: scroll;
  max-height: 100%;
}

.player {
  /* --scale is set via the component and defaults to 100%. */
  margin: 3rem auto;
  width: max(100%, 300px);
  transform-origin: top center;
  transform: translateZ(0) scale(var(--scale));
  /* 
    Because the scaled content keeps it's original dimensions,
    we reduce the height by using a negative margin.
    This geometry calc is not quite correct but is close enough for a 
    nice result between 0 and 100%.
  */
  margin-bottom: calc((var(--scale) - 100%) * 1);

  transition: transform 0.2s;
}

@media (prefers-reduced-motion) {
  .player {
    transition: none;
  }
}

.canvasScale {
  position: absolute;
  margin: var(--padding-lg);
  top: 0;
  right: 0;
  width: calc(50px + 8ch);
}

.canvasScale input {
  text-align: center;
}

.canvasScale button {
  background-color: #fff;
}
