import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { Card } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { appStyles } from '~/components/app/common/styles';
import {
  aiDubbingOrderOptionType,
  selectedServicesType,
  translationProfileType,
} from '~/components/app/order_form/propTypes';
import AIDubbingOrderOptionsTable from '~/components/app/order_form/serviceOptions/dubbing/AIDubbingOrderOptionsTable';
import ObnoxiousYellowBox from '../../units/ObnoxiousYellowBox';

const AIDubbingOrderOptions = ({
  dubbingOrderOptions,
  dubbingWarningSelected,
  selectedServices,
  setDubbingWarningSelected,
  showDubbingRefundNotice,
  translationProfiles,
  updateOrderOptions,
}) => {
  const dubbingOrderData =
    selectedServices.find((service) => service.serviceType === 'Dubbing')?.orderOptions || {};
  const selectedDubbingOrderOptions = dubbingOrderData?.selectedDubbingOptions || [];

  const firstOptionPerLanguage = useMemo(() => {
    const languages = {};
    dubbingOrderOptions.forEach((option) => {
      if (!languages[option.targetLanguage.fullName]) {
        languages[option.targetLanguage.fullName] = option;
      }
    });
    return Object.values(languages);
  }, [dubbingOrderOptions]);

  return (
    <>
      <Card.Title className={css(appStyles.title)}>Select Languages</Card.Title>
      <Card.Subtitle className="mb-2 text-muted">
        Select languages that you want a dubbed audio track for:
      </Card.Subtitle>
      <Typeahead
        clearButton
        id="dubbing-language-dropdown"
        multiple={true}
        onChange={(selectedDubbingOptions) => {
          selectedDubbingOptions.map((option) => {
            if (!option.translationProfileID) {
              option.translationProfileID = translationProfiles[0].id;
            }
          });
          updateOrderOptions('Dubbing', { ...dubbingOrderData, selectedDubbingOptions });
        }}
        options={firstOptionPerLanguage}
        labelKey={(item) => item.targetLanguage.fullName}
        placeholder="Search Languages"
        selected={selectedDubbingOrderOptions}
      />
      <AIDubbingOrderOptionsTable
        dubbingOrderData={dubbingOrderData}
        dubbingOrderOptions={dubbingOrderOptions}
        selectedOrderOptions={selectedDubbingOrderOptions}
        selectedServices={selectedServices}
        translationProfiles={translationProfiles}
        updateOrderOptions={updateOrderOptions}
      />
      {showDubbingRefundNotice && (
        <ObnoxiousYellowBox
          checked={dubbingWarningSelected}
          title="Dubbing cannot be Refunded"
          toggleChecked={setDubbingWarningSelected}
        >
          By checking this box, you indicate that you will not have the opportunity to review your
          transcripts before they are submitted for dubbing. Dubbing will begin as soon as the
          primary service is completed.
        </ObnoxiousYellowBox>
      )}
    </>
  );
};

AIDubbingOrderOptions.propTypes = {
  dubbingOrderOptions: PropTypes.arrayOf(aiDubbingOrderOptionType),
  dubbingWarningSelected: PropTypes.bool,
  showDubbingRefundNotice: PropTypes.bool,
  selectedServices: selectedServicesType,
  setDubbingWarningSelected: PropTypes.func,
  translationProfiles: PropTypes.arrayOf(translationProfileType),
  updateOrderOptions: PropTypes.func,
};

export default AIDubbingOrderOptions;
