import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Card, Tab, Tabs } from 'react-bootstrap';

import {
  GENGO_VENDOR_ID,
  MACHINE_TRANSLATOR_VENDOR_ID,
  TRANSPERFECT_VENDOR_ID,
  CHOOSE_FOR_ME_VENDOR_ID,
} from '~/helpers/constants';
import TipPanelContainer from "~/components/app/order_form/tipPanels/TipPanelContainer";

function VendorInfo(props) {
  switch (props.vendor) {
    case TRANSPERFECT_VENDOR_ID: {
      return (
        <>
          <p>
            TransPerfect is a high-quality translation vendor. All translations ordered through
            TransPerfect will go through their rigorous quality and accuracy review process.
            TransPerfect offers translation services in three turnaround levels (Standard,
            Expedited, and Rush), and all charges are calculated per minute of video.
          </p>
          <p>
            <b>Standard Turnaround:</b> 5 day option
          </p>
          <p>
            <b>Expedited Turnaround:</b> 2 day option
          </p>
          <p>
            <b>Rush Turnaround:</b> 1 day option
          </p>
          <p>
            <b>Note:&nbsp;</b>
            TransPerfect has a minimum duration of {props.transperfectMinimumDuration / 60} minutes
            per file for services ordered through them. Files under this minimum will be rounded up
            to {props.transperfectMinimumDuration / 60} minutes. To order Rush or Expedited
            Turnaround for TransPerfect, make sure the Fast TransPerfect Turnaround Add-On Module is
            enabled in your Settings.
          </p>
          <a
            href="https://support.3playmedia.com/hc/en-us/articles/4617358734487-Translation-Provide-Notice-for-a-Fast-Transperfect-Turnaround"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn More
          </a>
        </>
      );
    }
    case GENGO_VENDOR_ID: {
      return (
        <>
          <p>
            <b>Standard:</b> Best for everyday content and dynamic online content, Standard Level
            ensures a fast translation with attention to meaning and tone. Your order is handled by
            a highly proficient native speaker.
          </p>
          <p>
            <b>Advanced:</b> As fast as Standard Level, Advanced Level ensures a higher degree of
            accuracy and is recommended for the most business content. Your order is handled by an
            expirenced professional translator.
          </p>
          <a
            href="https://support.3playmedia.com/hc/en-us/articles/227734788-Translation-Gengo-Overview"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn More
          </a>
        </>
      );
    }
    case MACHINE_TRANSLATOR_VENDOR_ID: {
      return (
        <>
          <p>
            Fast and affordable automated translation. Suggested uses include internal, informal,
            unscripted, and user-generated content. Pricing for Machine Translation is on a per word
            basis. The service costs $0.005/word ($0.50 per 100 words) for any language option
            before discounts. Any discounts, if applicable, are reflected in the estimated cost
            column. Machine Translations requests cannot be canceled - all orders are final.
          </p>
          <a
            href="https://support.3playmedia.com/hc/en-us/articles/360014907733-Translation-Machine-Translation-Overview"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn More
          </a>
        </>
      );
    }
    case CHOOSE_FOR_ME_VENDOR_ID: {
      return (
        <>
          <p>
            Choose For Me tells 3Play Media to choose a translation vendor for you. Vendors are
            approved and limited to localization teams with in-country subtitlers/translators who
            have extensive experience with Media and Entertainment content and specifications.
          </p>
          <p>
            <b>Note:&nbsp;</b>
            Choose For Me has a minimum duration of 15 minutes per file for services ordered through
            them. Files under this minimum will be rounded up to 15 minutes.
          </p>
        </>
      );
    }
  }
}

function TranslationVendorInfoPanel(props) {
  const [activeTab, setActiveTab] = useState(props.selectedVendor);

  useEffect(() => {
    setActiveTab(props.selectedVendor);
  }, [props.selectedVendor]);

  function vendorsToDisplay() {
    const vendors = [
      {
        name: 'TransPerfect',
        key: TRANSPERFECT_VENDOR_ID,
      },
      {
        name: 'Gengo',
        key: GENGO_VENDOR_ID,
      },
      {
        name: 'Machine Translation',
        key: MACHINE_TRANSLATOR_VENDOR_ID,
      },
    ];
    if (props.threeplaySourcedEnabled) {
      vendors.unshift({
        name: 'Choose For Me',
        key: CHOOSE_FOR_ME_VENDOR_ID,
      });
    }
    return vendors;
  }
  const vendors = [
    {
      name: 'Gengo',
      key: GENGO_VENDOR_ID,
    },
    {
      name: 'Machine Translation',
      key: MACHINE_TRANSLATOR_VENDOR_ID,
    },
  ];

  return (
    <TipPanelContainer title="Translation Vendors and Service Levels">
      <Card.Body>
        <Tabs
          id="translation-vendor-info-tab"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(Number(k))}
        >
          {vendorsToDisplay().map((vendor) => {
            return (
              <Tab className="mt-3" eventKey={vendor.key} key={vendor.key} title={vendor.name}>
                <VendorInfo
                  transperfectMinimumDuration={props.transperfectMinimumDuration}
                  vendor={activeTab}
                />
              </Tab>
            );
          })}
        </Tabs>
      </Card.Body>
    </TipPanelContainer>
  );
}

TranslationVendorInfoPanel.propTypes = {
  selectedVendor: PropTypes.number,
  threeplaySourcedEnabled: PropTypes.bool,
  transperfectMinimumDuration: PropTypes.number,
};

export default TranslationVendorInfoPanel;
