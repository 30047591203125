import React, { useContext } from 'react';
import { CheatSheet, JobInstructions } from './JobInstructions';
import { PermissionContext, PermissionProps } from './permission_provider/PermissionProvider';

interface DeliveryInstructionInterface {
  cheatSheets: CheatSheet[] | null;
  shouldDeliver: boolean;
}

const title = 'Delivery Instructions';

export const DeliveryInstructions = ({
  cheatSheets,
  shouldDeliver,
}: DeliveryInstructionInterface) => {
  const { canViewDeliveryInstructions }: PermissionProps = useContext(PermissionContext);

  if (!canViewDeliveryInstructions) {
    return null;
  }
  return shouldDeliver ? (
    <JobInstructions
      cheatSheets={cheatSheets}
      title={title}
      placeholder={'Please contact Ops to find out next steps on delivery.'}
    />
  ) : (
    <DoNotDeliver />
  );
};

const DoNotDeliver = () => (
  <JobInstructions
    cheatSheets={null}
    title={title}
    placeholder={
      'We do not yet have the final video from the customer, so please do not deliver your asset.'
    }
  />
);
