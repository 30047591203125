interface Language {
  name: string;
}

export const formatSingleLanguageDisplay = (
  sourcePrimaryLanguage: Language,
  serviceLanguages?: Language[]
) => {
  if (!sourcePrimaryLanguage) return '';

  const isDualLanguage = serviceLanguages && serviceLanguages?.length > 1;
  const isPrimaryEnglish = sourcePrimaryLanguage.name === 'English';

  if (!isDualLanguage) return sourcePrimaryLanguage.name ?? '';

  return `Dual Language - ${isPrimaryEnglish ? 'English / Spanish' : 'Spanish / English'}`;
};
