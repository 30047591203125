// Definitions
// "TimeSlot" suffix: the "time_slot" representation from the back-end
// "CalendarEntry" suffix: the ReactBigCalendar "event" representation on the front end,
// decorated with additional metadata in the "object" property

export type DateTimeString = string; // ISO8601 - ex: "2023-05-31T21:11:12.769-04:00"

export interface DateTimeRange {
  starts_at: DateTimeString;
  ends_at: DateTimeString;
}

export interface Contractor {
  id: string;
  firstname: string;
  lastname: string;
  profile: {
    id: string;
    contractor_type: string; // "Admin", "MPLS FTE", "Calgary IC", "MPLS IC", "Calgary FTE"
  };
}

export interface CaptionerShiftTimeSlot extends DateTimeRange {
  id: string;
  bookable_type: 'User';
  time_slot_type: 'bookable';
  bookable: Contractor;
}

// Same as CaptionerShiftTimeSlot, but with a different time_slot_type
export interface CaptionerAvailableSlot extends Omit<CaptionerShiftTimeSlot, 'time_slot_type'> {
  time_slot_type: 'bookable_segment';
}

export interface LiveEventJob {
  id: string;
  name: string;
}

export interface LiveEventBase {
  id: string;
}

export interface LiveEventServiceBase {
  id: string;
  display_state: string;
  ops_state: string;
  state: string;
}

export interface LiveEventJobBase {
  id: string;
  name: string;
}

export interface LiveEventService extends LiveEventServiceBase {
  jobs: Array<{
    id: string;
    name: string;
    time_slot: LiveEventTimeSlot;
    _event: LiveEventBase; // Added here so that we can attach to the time_slot later in the covert-data file
    _service: LiveEventServiceBase; // Added here so that we can attach to the time_slot later in the covert-data file
  }>;
}

export interface LiveEventTimeSlotBase extends DateTimeRange {
  id: string;
  name: string;
  time_slot_type: 'booking';
  booking: LiveEventJob;
  _event: LiveEventBase;
  _service: LiveEventServiceBase;
  _job: LiveEventJobBase;
}

export interface BookedLiveEventTimeSlot extends LiveEventTimeSlotBase {
  bookable: Contractor;
}

export interface UnBookedLiveEventTimeSlot extends LiveEventTimeSlotBase {
  bookable: null;
}

export type LiveEventTimeSlot = BookedLiveEventTimeSlot | UnBookedLiveEventTimeSlot;

export interface LiveEvent extends LiveEventBase {
  service: LiveEventService;
}

export interface NewBookingTimeSlot extends DateTimeRange {
  time_slot_type: 'new_booking';
}

export interface ReactBigCalenadarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  isSelected: boolean;
}

export interface BookedLiveEventCalendarEntry extends ReactBigCalenadarEvent {
  object: BookedLiveEventTimeSlot;
}

export interface UnBookedLiveEventCalendarEntry extends ReactBigCalenadarEvent {
  object: UnBookedLiveEventTimeSlot;
}

export type LiveEventCalendarEntry = BookedLiveEventCalendarEntry | UnBookedLiveEventCalendarEntry;

export interface CaptionerShiftCalendarEntry extends ReactBigCalenadarEvent {
  object: CaptionerShiftTimeSlot;
}

export interface CaptionerAvailabilityCalendarEntry extends ReactBigCalenadarEvent {
  object: CaptionerAvailableSlot;
}

export interface NewBookingCalendarEntry extends ReactBigCalenadarEvent {
  object: null;
}

export type AnyTimeSlot =
  | BookedLiveEventTimeSlot
  | UnBookedLiveEventTimeSlot
  | CaptionerShiftTimeSlot
  | CaptionerAvailableSlot
  | NewBookingTimeSlot;

export interface TimeSlottable {
  time_slot_type: 'booking' | 'bookable' | 'new_booking' | 'bookable_segment';
  bookable?: Contractor | null;
}

export function isBookedLiveEventTimeSlot(
  timeSlot?: TimeSlottable
): timeSlot is BookedLiveEventTimeSlot {
  return timeSlot?.time_slot_type === 'booking' && Boolean(timeSlot.bookable);
}

export function isUnbookedLiveEventTimeSlot(
  timeSlot?: TimeSlottable
): timeSlot is UnBookedLiveEventTimeSlot {
  return timeSlot?.time_slot_type === 'booking' && !timeSlot.bookable;
}

export function isCaptionerShiftTimeSlot(
  timeSlot?: TimeSlottable
): timeSlot is CaptionerShiftTimeSlot {
  return timeSlot?.time_slot_type === 'bookable';
}

export function isCaptionerAvailabeSlot(
  timeSlot?: TimeSlottable
): timeSlot is CaptionerAvailableSlot {
  return timeSlot?.time_slot_type === 'bookable_segment';
}

export function isNewBookingCalendarEntry(
  timeSlot?: TimeSlottable
): timeSlot is NewBookingTimeSlot {
  return timeSlot?.time_slot_type === 'new_booking';
}
