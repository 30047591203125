const availableWordlistQuery = `
  query AvailableWordlistQuery {
    availableWordlistResources {
      accounts {
        name,
        id,
        wordlistAssignment {
          id,
          updatedAt,
          user { name },
          wordlist {
            id,
            name,
            words
          }
        }
      },
      projects {
        id,
        name,
        wordlistAssignment {
          id,
          updatedAt,
          user { name },
          wordlist {
            id,
            name,
            words
          }
        }
      },
      folders {
        id,
        name,
        wordlistAssignment {
          id,
          updatedAt,
          user { name },
          wordlist {
            id,
            name,
            words
          }
        }
      }
    }
  }
`;

const wordlistAssignmentQuery = `
  query WordlistAssignmentQuery($batchId: ID) {
    wordlistAssignment(batchId: $batchId) {
      resourceType,
      wordlist {
        name,
        words
      }
    }
  }
`;

export { availableWordlistQuery, wordlistAssignmentQuery };
