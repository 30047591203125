import { Formatter } from '../_types';

export class AddLeadingWhitespaceFormatter implements Formatter {
  /**
   * A formatter that removes repeated white space.
   *
   * @example new AddLeadingWhitespaceFormatter().format("world", "hello")
   * // returns " world"
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  format(text: string, lastWordSent: string) {
    return this.shouldAddWhitespace(lastWordSent) ? ` ${text.trimStart()}` : text;
  }

  shouldAddWhitespace(lastWordSent: string) {
    return lastWordSent !== '';
  }
}
