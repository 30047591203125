import React from 'react';
import moment from 'moment-timezone';
import { titleize } from '~/helpers/strings';

import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';
import MultiServiceTable from './MultiServiceTable';

interface Service {
  id: string;
  orderedAt: string;
  status: string;
}

interface CaptionRevisionServiceCardProps {
  services: Service[];
  userTimeZone: string;
}

const CaptionRevisionServiceCard = ({
  services,
  userTimeZone,
}: CaptionRevisionServiceCardProps) => (
  <MultiServiceTable headers={['Ordered', 'Status']}>
    {services.map((service) => (
      <tr key={service.id}>
        <td>{moment(parseInt(service.orderedAt)).tz(userTimeZone).format(DATE_TIME_FORMAT)}</td>
        <td>{titleize(service.status)}</td>
      </tr>
    ))}
  </MultiServiceTable>
);

export default CaptionRevisionServiceCard;
