import React from 'react';
import { Card } from '@threeplayground/unstable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

interface InstructionProps {
  instructions: string | null;
  title?: string | null;
}

export function AssetInstructions({ instructions, title = 'Media Shuttle' }: InstructionProps) {
  return (
    <Card>
      <Card.Header className="font-weight-bold">
        <FontAwesomeIcon icon={faExclamationCircle} /> {title} Instructions
      </Card.Header>
      <Card.Body>{instructions}</Card.Body>
    </Card>
  );
}
