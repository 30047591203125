import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

import { pluralize } from '~/helpers/strings';
import { selectedServicesType } from './propTypes';

export const CHOOSE_FOR_ME_VENDOR = 'Choose For Me translation vendor';
function getServiceWithMinDuration(durationMinimums, selectedServices, vendor, hasVoiceArtistAD) {
  if (vendor === 'TransPerfect') {
    return durationMinimums.transperfect / 60;
  }
  if (vendor === 'AI Dubbing') {
    return durationMinimums.aiDubbing / 60;
  }
  if (vendor === CHOOSE_FOR_ME_VENDOR) {
    return durationMinimums.chooseForMe / 60;
  }
  if (hasVoiceArtistAD) {
    return durationMinimums.voiceArtistAudioDescription / 60;
  }
  let minServiceDuration = undefined;
  selectedServices.forEach((ss) => {
    let serviceDuration = undefined;
    switch (ss.serviceType) {
      case 'Transcription':
        serviceDuration = durationMinimums.transcription;
        break;
      case 'AudioDescription':
        serviceDuration = durationMinimums.audioDescription;
        break;
      default:
        serviceDuration = 0;
        break;
    }
    if (minServiceDuration) {
      minServiceDuration = Math.max(minServiceDuration, serviceDuration);
    } else {
      minServiceDuration = serviceDuration;
    }
  });
  return minServiceDuration;
}

function durationUnit(vendor) {
  switch (vendor) {
    case 'AI Dubbing':
    case 'TransPerfect':
    case 'Captionmax':
    case CHOOSE_FOR_ME_VENDOR:
      return 'minute';
    default:
      return 'second';
  }
}

function displayVendorName(vendor) {
  if (vendor === 'Captionmax') {
    return '3Play';
  }

  return vendor;
}

function DurationMinimumNote(props) {
  const duration = getServiceWithMinDuration(
    props.durationMinimums,
    props.selectedServices,
    props.vendor,
    props.hasVoiceArtistAudioDescription
  );
  const minimum = pluralize(durationUnit(props.vendor), duration);
  const displayVendor = displayVendorName(props.vendor);
  return (
    <Alert className="mb-0" variant="warning">
      <span className="font-weight-bold">Note: </span>
      {displayVendor} has a minimum duration of&nbsp;
      {minimum} per file for the services you ordered. Files under this minimum will be rounded up
      to {minimum}.
    </Alert>
  );
}

DurationMinimumNote.propTypes = {
  durationMinimums: PropTypes.object,
  selectedServices: selectedServicesType,
  vendor: PropTypes.string,
  hasVoiceArtistAudioDescription: PropTypes.bool,
};

export default DurationMinimumNote;
