import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import styles from '~/components/app/order_form/serviceDetails/serviceDetailStyles';
import { VENDORS_WITH_PER_WORD_PRICING } from '~/helpers/constants';
import { displayVendorPricePerMinute, displayPrice } from '~/helpers/numbers';

const TranslationServiceDetails = ({ translationOrderOptions }) => (
  <>
    {translationOrderOptions.map((too) => {
      return (
        <div className={css(styles.serviceOptionHeader)} key={too.targetLanguage}>
          <span>
            <b>{too.targetLanguage}</b>:{' '}
            {too.vendor.name === 'MachineTranslator' ? 'Machine' : too.vendor.name}{' '}
            {too.serviceLevel.name}
          </span>
          <div className={css(styles.serviceOptionPrice)}>
            {VENDORS_WITH_PER_WORD_PRICING.includes(Number(too.vendor.id))
              ? `${displayVendorPricePerMinute(too.price.amount, 'min', false)}*`
              : displayPrice(too.price.amount, 'min', false)}
          </div>
        </div>
      );
    })}
    {translationOrderOptions.some((too) =>
      VENDORS_WITH_PER_WORD_PRICING.includes(Number(too.vendor.id))
    ) && (
      <p className="pt-2">
        *Final prices for translation from these vendors will vary and are based on a transcript
        containing an average of 150 words per minute.
      </p>
    )}
  </>
);

TranslationServiceDetails.propTypes = {
  translationOrderOptions: PropTypes.arrayOf(PropTypes.object),
};

export default TranslationServiceDetails;
