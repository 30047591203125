import React, { useState } from 'react';

import { Card, Tab, Tabs } from 'react-bootstrap';
import TipPanelContainer from "~/components/app/order_form/tipPanels/TipPanelContainer";

const formats = [
  {
    name: 'As-Broadcast Script',
    abbr: 'ABS',
    description:
      'These include timecodes, speaker, sound effects, on-screen graphics, and the verbatim dialogue in paragraph form. This simple format is commonly used for translation or submitting projects to awards shows.',
    link: 'http://www.3playmedia.com',
  },
  {
    name: 'Technical As-Broadcast Script',
    abbr: 'ABT',
    description:
      'These include scene descriptions and timecode-out references in addition to everything contained in a basic \
      ABS. This format often is used for translation and dubbing, as it tells the dubbing company exactly what \
      space in time can be filled with the new audio.',
    link: 'http://www.3playmedia.com',
  },
  {
    name: 'Dialogue Continuity Script',
    abbr: 'DCS',
    description:
      'This contains general action descriptions, all dialogue, indication of voice-over and off-screen dialogue, \
    character names, and time codes. This method is required by HBO, among others.',
    link: 'http://www.3playmedia.com',
  },
  {
    name: 'Combined Continuity Script',
    abbr: 'CCL',
    description:
      'This contains cut-by-cut descriptions of all camera shots, movements of camera and actors within the shot, \
    and dialogue spoken within the shot as well as sound effects, music cues, and indication of voice-over and \
    off-screen sound.',
    link: 'http://www.3playmedia.com',
  },
  {
    name: 'Combined Dialogue and Spotting List',
    abbr: 'CDSL',
    description:
      'This is a script that combines verbatim dialogue and CCL elements like music cues, sound effects, and \
    cut-by-cut descriptions of all camera shots with the actual subtitle, including in and out timecodes.',
    link: 'http://www.3playmedia.com',
  },
  {
    name: 'Combined Continuity and Spotting List',
    abbr: 'CCSL',
    description:
      'This includes both of the above: camera movements, movement of actors within a shot, spoken dialogue, \
    subtitles, and in and out timecodes.',
    link: 'http://www.3playmedia.com',
  },
];

function ScriptInfo(props) {
  return (
    <>
      <p>
        <b>{props.format.name}</b> {props.format.description}
      </p>
      <a href={props.format.link} rel="noopener noreferrer" target="_blank">
        Learn More
      </a>
    </>
  );
}

function BroadcastScriptsInfoPanel(props) {
  const [activeTab, setActiveTab] = useState(formats[0].abbr);
  return (
    <TipPanelContainer title="Broadcast Script Formats">
      <Card.Body>
        <Tabs
          id="broadcast-script-info-tab"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
        >
          {formats.map((fmt, i) => {
            return (
              <Tab className="mt-3" eventKey={fmt.abbr} key={fmt.abbr} title={fmt.abbr}>
                <ScriptInfo format={fmt} />
              </Tab>
            );
          })}
        </Tabs>
      </Card.Body>
    </TipPanelContainer>
  );
}
export default BroadcastScriptsInfoPanel;
