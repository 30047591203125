import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';
import { use3PMutation } from '~/logic/unstable/use3PMutation';

import RevisionsForm from './RevisionsForm';
import OrderMore from '~/components/app/order_more/OrderMore';

const CREATE_REVISION = `
mutation createRevision($data: RevisionCreateInput!) {
  createRevision(data: $data) {
    data {
      ids
    }
    errors {
      code
      message
    }
  }
}`;

const RevisionsAppWrapper = (props) => {
  const apiClient = new ThreeplayApiV2('/data');

  return (
    <ThreeplayAPIProvider client={apiClient}>
      <RevisionsApp {...props} />
    </ThreeplayAPIProvider>
  );
};

const RevisionsOrderSummary = ({ fileName, instructions, uploadedFileName }) => {
  return (
    <>
      <div>
        <p>
          <b>Current File Name:</b> {fileName}
        </p>
        {uploadedFileName && (
          <p>
            <b>Provided File:</b> {uploadedFileName}
          </p>
        )}
        <p>
          <b>Revision Instructions:</b> {instructions}
        </p>
      </div>
    </>
  );
};

RevisionsOrderSummary.propTypes = {
  fileName: PropTypes.string,
  instructions: PropTypes.string,
  uploadedFileName: PropTypes.string,
};

const RevisionsTipPanel = () => {
  return (
    <>
      <strong>To order revision services:</strong>
      <p>
        You can request a revision when there are changes or updates that you would like made to the
        content in your caption file. If you need updates to your captions because your media file
        has changed or you need to republish your captions, please order a reformat service.
      </p>
    </>
  );
};

const RevisionsApp = ({ mediaFile, options, serviceType, supportedFormats }) => {
  const [additionalErrors, setAdditionalErrors] = useState([]);
  const [instructions, setInstructions] = useState('');
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedScriptingFormats, setSelectedScriptingFormats] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sourceUploaded, setSourceUploaded] = useState(false);
  const [revisionFileLink, setRevisionFileLink] = useState('');

  const { mutateAsync } = use3PMutation(CREATE_REVISION, {
    extractKey: 'createRevision',
  });

  const canSubmit = () => {
    const baseRequirements = instructions.length > 0;

    switch (serviceType) {
      case 'broadcast_scripts':
        return baseRequirements && selectedScriptingFormats.length > 0;
      case 'dubbing':
      case 'localization':
        return baseRequirements && selectedLanguages.length > 0;
      default:
        return baseRequirements;
    }
  };

  const onSubmit = async ({ setErrors, setSuccess }) => {
    const {
      data: mutationData,
      errors,
      globalErrors: mutationGlobalErrors,
    } = await mutateAsync({
      data: {
        mediaFileId: mediaFile.id,
        serviceType: serviceType,
        broadcastScriptingFormats: selectedScriptingFormats,
        languages: selectedLanguages,
        revisionInstructions: instructions,
        revisionMediaFileSourceUrl: revisionFileLink,
      },
    });

    if (errors || mutationGlobalErrors) {
      setErrors([...(errors || []), ...(mutationGlobalErrors || []).map((me) => me.message)]);
      return;
    }

    setSuccess(true);
  };

  return (
    <OrderMore
      additionalErrors={additionalErrors}
      mainContent={
        <RevisionsForm
          mediaFile={mediaFile}
          options={options}
          selectedFile={selectedFile}
          selectedLanguages={selectedLanguages}
          selectedScriptingFormats={selectedScriptingFormats}
          serviceType={serviceType}
          setErrors={setAdditionalErrors}
          setSelectedFile={setSelectedFile}
          setSelectedLanguages={setSelectedLanguages}
          setSelectedScriptingFormats={setSelectedScriptingFormats}
          setRevisionFileLink={setRevisionFileLink}
          setInstructions={setInstructions}
          setSourceUploaded={setSourceUploaded}
          sourceUploaded={sourceUploaded}
          supportedFormats={supportedFormats}
        />
      }
      onSubmit={onSubmit}
      orderType="Revision"
      submissionDisabled={!canSubmit()}
      summaryContent={
        <RevisionsOrderSummary
          fileName={mediaFile.name}
          instructions={instructions}
          uploadedFileName={selectedFile?.name || revisionFileLink}
        />
      }
      tipPanelContent={<RevisionsTipPanel />}
    />
  );
};

const optionShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    displayName: PropTypes.string,
  })
);

RevisionsApp.propTypes = {
  mediaFile: PropTypes.object,
  options: PropTypes.shape({
    broadcastScriptingFormats: optionShape,
    languages: optionShape,
    mixingOptions: PropTypes.shape({
      mix: optionShape,
      embed: optionShape,
    }),
  }),
  serviceType: PropTypes.string,
  supportedFormats: PropTypes.arrayOf(PropTypes.string),
};

export default RevisionsAppWrapper;
