import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPencilAlt,
  faPlusCircle,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';

function SpeakerLabels(props) {
  const [editedSpeakers, setEditedSpeakers] = useState([]);
  const [editing, setEditing] = useState([]);

  const speakerLimitReached = editedSpeakers.length === 11;

  useEffect(() => {
    if (editing.length === 0) {
      setEditing(Array(props.speakerNames.length).fill(false));
    }
    setEditedSpeakers(props.speakerNames);
  }, [props.speakerNames]);

  function addNewSpeaker() {
    props.setSpeakerNames([...editedSpeakers, '']);
    setEditing([...editing, true]);
  }

  function deleteSpeaker(index) {
    const speakers = editedSpeakers.filter((_, i) => i !== index);
    const newEditing = editing.filter((_, i) => i !== index);

    props.setSpeakerNames(speakers);
    setEditing(newEditing);
  }

  function updateSpeaker(index) {
    props.setSpeakerNames(
      Object.assign([...props.speakerNames], { [index]: editedSpeakers[index] })
    );
    toggleEditingState(index);
  }

  function toggleEditingState(index) {
    setEditing(Object.assign([...editing], { [index]: !editing[index] }));
  }

  return (
    <>
      <h2 className="swatei-title">Speaker Labels</h2>
      <Table>
        <tbody>
          {props.speakerNames.map((name, index) => {
            const speakerName = index === 0 ? `NEW SPEAKER(${name})` : name;
            return (
              <tr key={index}>
                <td>
                  <span className="p-2 keyboard-button">Ctrl</span>
                  <span className="p-2">+</span>
                  <span className="p-2 keyboard-button">{index === 0 ? '`' : index % 10}</span>
                </td>
                <td>
                  {editing[index] ? (
                    <input
                      name={`speakerLabel-${index}`}
                      value={editedSpeakers[index]}
                      onChange={(e) =>
                        setEditedSpeakers(
                          Object.assign([...editedSpeakers], { [index]: e.target.value })
                        )
                      }
                    />
                  ) : (
                    speakerName
                  )}
                </td>
                <td className="text-right">
                  {editing[index] ? (
                    <>
                      <span className="mr-4">
                        <FontAwesomeIcon
                          className={`text-success ${css(styles.iconButton)}`}
                          icon={faCheck}
                          onClick={() => updateSpeaker(index)}
                        />
                      </span>
                      <span>
                        <FontAwesomeIcon
                          className={`text-danger ${css(styles.iconButton)}`}
                          icon={faTimes}
                          onClick={() => toggleEditingState(index)}
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        <FontAwesomeIcon
                          className={
                            index === 0
                              ? `invisible`
                              : `icon-threeplay-blue ${css(styles.iconButton)}`
                          }
                          icon={faPencilAlt}
                          onClick={() => toggleEditingState(index)}
                        />
                      </span>
                    </>
                  )}
                  <span className="ml-4">
                    <FontAwesomeIcon
                      className={
                        index === 0 ? `invisible` : `text-danger ${css(styles.iconButton)}`
                      }
                      icon={faTrashAlt}
                      onClick={() => deleteSpeaker(index)}
                    />
                  </span>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="3">
              <TooltipIfErrors
                errors={
                  speakerLimitReached
                    ? [
                        'The maximum number of speaker labels is 11. If you need to add a new speaker label, please delete an existing speaker label.',
                      ]
                    : []
                }
              >
                <Button
                  aria-label="Add New Speaker"
                  className={speakerLimitReached ? css(styles.disabled) : css(styles.iconButton)}
                  onClick={() => addNewSpeaker()}
                  size="sm"
                  variant="link"
                  disabled={speakerLimitReached}
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> New Speaker
                </Button>
              </TooltipIfErrors>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

SpeakerLabels.propTypes = {
  speakerNames: PropTypes.arrayOf(PropTypes.string),
  setSpeakerNames: PropTypes.func,
};

const styles = StyleSheet.create({
  disabled: {
    pointerEvents: 'none',
  },
  iconButton: {
    border: 'none',
    ':hover': {
      cursor: 'pointer',
    },
  },
});

export default SpeakerLabels;
