import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { use3PMutation } from '~/logic/unstable/use3PMutation';

import { Button, Card, CloseButton, Modal } from 'react-bootstrap';
import { StyleSheet, css } from 'aphrodite';

const DELETE_ORDER_TEMPLATE = `mutation deleteOrderTemplate($where: OrderTemplateWhereUniqueInput!){
  deleteOrderTemplate(where: $where){
    data {
      id
      name
    }
    errors {
      code
      message
    }
  }
}`;

const OrderTemplatesDeleteModal = ({
  handleClose,
  id,
  name,
  setGlobalErrors,
  setGlobalSuccessMessage,
  setTemplates,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync } = use3PMutation(DELETE_ORDER_TEMPLATE, {
    extractKey: 'deleteOrderTemplate',
  });

  const onSubmit = async () => {
    setIsSubmitting(true);
    const { data: mutationData, globalErrors: mutationGlobalErrors } = await mutateAsync({
      where: { idEq: id },
    });
    setGlobalSuccessMessage(undefined); // Clear it, since it might have info from other actions.
    setGlobalErrors(mutationGlobalErrors || undefined);
    setIsSubmitting(false);

    if (mutationData && !mutationGlobalErrors) {
      setTemplates((templates) => templates.filter((t) => t.id !== mutationData.id));
      handleClose();
      setGlobalSuccessMessage('Your order template has been deleted.');
    }
  };

  return (
    <>
      <Modal size="lg" backdrop="static" show={!!id} onHide={handleClose} animation={false}>
        <Modal.Header>
          <Card style={{ border: 0 }}>
            <Card.Title className={css(styles.modalTitle)}>
              Delete Order Template: {name}
            </Card.Title>
          </Card>
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" type="button" onClick={() => onSubmit()}>
            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
            Yes, delete this template.
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  modalTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#535353',
    marginBottom: '.5rem',
  },
});

OrderTemplatesDeleteModal.propTypes = {
  handleClose: PropTypes.func,
  id: PropTypes.number,
  setGlobalErrors: PropTypes.func,
  setGlobalSuccessMessage: PropTypes.func,
  setTemplates: PropTypes.func,
};

export default OrderTemplatesDeleteModal;
