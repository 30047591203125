import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import WordlistTipPanel from '~/components/app/common/wordlist/WordlistTipPanel';

// TODO: Evaluate the need for this component
// Could WordlistModal from common/wordlist be reused here?
function WordlistModal(props) {
  const [words, setWords] = useState('');

  // This seems redundant - porbably only the initial state needs to use this
  // The onChange event is already setting the state
  useEffect(() => {
    setWords(props.words);
  }, [props.words]);

  return (
    <Modal size="lg" show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`${props.resourceType} Wordlist: ${props.name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form>
              <Form.Group>
                <Form.Label>
                  <strong>Words</strong>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  onChange={(e) => setWords(e.target.value)}
                  readOnly={props.readOnly}
                  rows={14}
                  value={words || ''}
                />
              </Form.Group>
            </Form>
          </Col>
          {!props.readOnly && (
            <Col>
              <WordlistTipPanel />
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onClose()} variant="outline-dark">
          Close
        </Button>
        {!props.readOnly && (
          <Button onClick={() => props.handleWordlistChange(words)} variant="primary">
            Add Wordlist
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

WordlistModal.propTypes = {
  handleWordlistChange: PropTypes.func,
  name: PropTypes.string,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  resourceType: PropTypes.string,
  show: PropTypes.bool,
  words: PropTypes.string,
};

export default WordlistModal;
