import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Card from 'react-bootstrap/Card';

import { checkKeyPress } from '~/helpers/checkKeyPress';
import selectedStyles from '../styles/selectedDeselectedStyles';

const LEARN_MORE_URLS = {
  Synthesized:
    'https://support.3playmedia.com/hc/en-us/articles/115010653947-What-Is-Audio-Description-',
  'Voice Artist':
    'https://support.3playmedia.com/hc/en-us/articles/115010653947-What-Is-Audio-Description-',
};

function SpeakerTypeCard(props) {
  return (
    <Card
      bg="light"
      data-testid={props.name}
      tabIndex={props.index}
      style={{ width: '20rem' }}
      onKeyDown={(e) =>
        checkKeyPress(e, () => props.setSpeakerType(props.name), { triggerOnSpace: true })
      }
      onClick={() => props.setSpeakerType(props.name)}
      className={
        props.className ||
        (props.orderOptions.speakerType && props.orderOptions.speakerType.name === props.name)
          ? css(selectedStyles.selectedWithoutMargin, styles.box)
          : css(selectedStyles.deselectedWithoutMargin, styles.box)
      }
    >
      <Card.Body>
        <div>
          <b className={css(styles.left)}>{props.name}</b>&nbsp;
          <b className={css(styles.right)}>{props.displayPrice}</b>
        </div>
        <div>{props.deliveryDate}</div>
        <div className={css(styles.inline)}>{props.description}</div>
        <br />
        <a href={LEARN_MORE_URLS[props.name]} rel="noreferrer" target="_blank">
          Learn More
        </a>
      </Card.Body>
    </Card>
  );
}

SpeakerTypeCard.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.string,
  }),
  description: PropTypes.string,
  deliveryDate: PropTypes.string,
  setSpeakerType: PropTypes.func,
  orderOptions: PropTypes.object,
  displayPrice: PropTypes.string,
  className: PropTypes.string,
};

const styles = StyleSheet.create({
  box: {
    'margin-right': '1rem',
    'margin-bottom': '0.5rem',
    cursor: 'pointer',
    'max-width': '25rem',
    'min-width': '15rem',
  },
  right: {
    float: 'right',
    'font-size': '.9rem',
    'font-weight': 'bold',
    color: '#4c4c4c',
  },
  left: {
    float: 'left',
  },
  inline: {
    display: 'inline-block',
    'font-size': '.9rem',
    'margin-top': '.2rem',
  },
});

export default SpeakerTypeCard;
