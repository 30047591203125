import React from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BasicInformationForm from '../shared/forms/BasicInformationForm';
import ConfirmEmail from '../shared/forms/ConfirmEmail';
import LocationInformation from '../shared/forms/LocationInformation';
import SingleStepNav from '../shared/SingleStepNav';
import SkillzAndExperience from './SkillzAndExperience';
import StepperNav from '../shared/StepperNav';
import { submitBasicInfo } from '../../applicationWorkflow';

import { applicantShape } from '../shapes';
import { palette } from '~/components/contractor_applications/theme';

const BASIC_INFORMATION = 0;
const CONFIRM_EMAIL = 1;
const LOCATION_INFORMATION = 2;
const SKILLS_AND_EXPERIENCE = 3;

const APPLICATION_STEPS = {
  0: 'Basic Information',
  1: 'Confirm Email',
  2: 'Location Information',
  3: 'Skills and Experience',
};

const MainApplication = ({
  applicantInfo,
  countries,
  usStates,
  step,
  setStep,
  onStartTest,
  onUpdate,
  redirectPage,
  authToken,
  setAuthToken,
  referrerParams,
  languageId,
  emailCode,
}) => {
  const updateApplicantInfo = (applicantInfo) => onUpdate(applicantInfo);

  const confirmEmail = ({ authToken }) => {
    setAuthToken(authToken);
    setStep(LOCATION_INFORMATION);
  };

  function nextStep({ applicantInfo = {} }) {
    updateApplicantInfo(applicantInfo);
    setStep(step + 1);
  }

  const nextStepBasicInfo = ({ applicantInfo = {}, authToken }) => {
    updateApplicantInfo(applicantInfo);
    setAuthToken(authToken);
    setStep(step + 1);
  };

  const handleNextStepSkillzAndExperience = ({ applicantInfo, tests }) => {
    updateApplicantInfo(applicantInfo);
    onStartTest({ tests });
  };

  const previousStep = ({ applicantInfo = {} }) => {
    updateApplicantInfo(applicantInfo);

    if (step > 0) {
      setStep(step - 1);
    }
  };

  return (
    <>
      <Row>
        <Col lg={3}>
          <div className={'d-lg-none d-block'}>
            <SingleStepNav currentStep={step} appSteps={APPLICATION_STEPS}></SingleStepNav>
          </div>
          <div className={'mt-lg-5 ml-lg-5 pl-2 pr-2 d-none d-lg-block ' + css(styles.nav)}>
            <h3 className={css(styles.navSectionHeader)}>Initial Application</h3>
            <StepperNav currentStep={step}>
              <StepperNav.Step>1. Basic Information</StepperNav.Step>
              <StepperNav.Step>2. Confirm Email</StepperNav.Step>
              <StepperNav.Step>3. Location Information</StepperNav.Step>
              <StepperNav.Step>4. Skills and Experience</StepperNav.Step>
            </StepperNav>
            <h3 className={css(styles.navSectionHeader, styles.pending)}>Transcription Test</h3>
          </div>
        </Col>
        <Col lg={9}>
          <div className={'pl-1 mt-lg-5 ml-lg-5 pl-3 pr-2 ' + css(styles.form)}>
            {step === BASIC_INFORMATION && (
              <BasicInformationForm
                countries={countries}
                usStates={usStates}
                applicantInfo={applicantInfo}
                submitBasicInformation={submitBasicInfo}
                nextStep={nextStepBasicInfo}
                redirectPage={redirectPage}
                referrerParams={referrerParams}
                languageId={languageId}
              />
            )}
            {step === CONFIRM_EMAIL && (
              <ConfirmEmail
                email={applicantInfo.email}
                onSubmit={confirmEmail}
                previousStep={previousStep}
                authToken={authToken}
                emailCode={emailCode}
              />
            )}
            {step === LOCATION_INFORMATION && (
              <LocationInformation
                applicantInfo={applicantInfo}
                previousStep={previousStep}
                nextStep={nextStep}
                authToken={authToken}
              />
            )}
            {step === SKILLS_AND_EXPERIENCE && (
              <SkillzAndExperience
                applicantInfo={applicantInfo}
                previousStep={previousStep}
                nextStep={handleNextStepSkillzAndExperience}
                authToken={authToken}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

MainApplication.propTypes = {
  applicantInfo: applicantShape,
  countries: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  usStates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  onStartTest: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  redirectPage: PropTypes.func,
  authToken: PropTypes.object,
  setAuthToken: PropTypes.func,
  languageId: PropTypes.number,
  emailCode: PropTypes.string,
  referrerParams: PropTypes.object,
};

const styles = StyleSheet.create({
  nav: {
    fontWeight: 600,
    fontSize: '13px',
  },
  navSectionHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '1em',
    marginBottom: '1em',
    color: palette.text,
  },
  pending: {
    color: palette.inactive,
  },
});

Object.assign(MainApplication, {
  BASIC_INFORMATION,
  LOCATION_INFORMATION,
  CONFIRM_EMAIL,
  SKILLS_AND_EXPERIENCE,
});

export default MainApplication;
