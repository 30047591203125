import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';

import AddAlignment from '~/components/app/order_form/AddAlignment';
import WordlistModal from '../WordlistModal';

import { Alert, Button, Form, ProgressBar, Table } from 'react-bootstrap';

import { threeplayApi } from '~/logic/ThreeplayApi';
import { bytesToSize, durationToDisplay } from '~/helpers/files';
import { durationString } from '~/helpers/numbers';
import EditableFileName from '~/components/app/order_form/uploads/EditableFileName';
import { turnaroundLevelsQuery } from '~/components/app/order_form/data/queries';
import { selectedServicesType } from '~/components/app/order_form/propTypes';

import { FILE_UPLOAD_TABLE_INDEX } from '~/helpers/constants';
import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';

const VOICE_ARTIST_AUDIO_DESCRIPTION_FILE_LIMIT = 30 * 60 * 1000; // 30 Minutes

function FileUploadTable(props) {
  const [turnaroundLevels, setTurnaroundLevels] = useState([]);
  const [wordlistModal, setWordlistModal] = useState({ show: false });

  const missingDurations = props.filesToUpload?.some((f) => f.duration === null);
  const durationSum =
    props.filesToUpload?.reduce((total, file) => {
      return total + file.duration;
    }, 0) || 0;
  const fileSizeSum =
    props.filesToUpload?.reduce((total, file) => {
      return total + file.size;
    }, 0) || 0;
  const batchId = props.batchId || '';
  const transcriptionTurnaroundLevel =
    (props.selectedServices?.find((s) => s.serviceType == 'Transcription') &&
      props.selectedServices.find((s) => s.serviceType == 'Transcription').orderOptions
        .turnaroundLevel) ||
    {};
  const hasVoiceArtistAudioDescription =
    props.selectedServices?.find((s) => s.serviceType == 'AudioDescription')?.orderOptions
      ?.speakerType?.name === 'Voice Artist';

  const alignmentService =
    props.selectedServices?.find((s) => s.serviceType == 'Alignment') || null;

  useEffect(() => {
    threeplayApi.request(turnaroundLevelsQuery, { batchId: batchId }).then((res) => {
      const data = res.data || {};
      if (data.turnaroundLevels) {
        setTurnaroundLevels(data.turnaroundLevels);
      }
    });
  }, [props.batchId]);

  function progressBar(fileKey) {
    const uploadComplete = props.uploadProgress[fileKey] === 100;
    const fileUploadValidated = props.cheatsheet ? true : props.validFileUploads[fileKey];
    if (props.uploadProgress[fileKey] === -1) {
      var progress = (
        <span className="text-danger">
          <i className="fa fa-exclamation-triangle pr-2"></i>
          There was an error during the upload process
        </span>
      );
    } else if (uploadComplete) {
      if (fileUploadValidated) {
        progress = (
          <span className="text-success">
            <i className="fa fa-check pr-2"></i>
            File successfully uploaded
          </span>
        );
      } else if (fileUploadValidated === undefined) {
        progress = (
          <span className={css(styles.textProcessing)}>
            <i className="fa fa-spinner fa-fw pr-2"></i>
            File is currently processing
          </span>
        );
      }
    } else {
      progress = (
        <ProgressBar variant="success" now={props.uploadProgress[fileKey]} striped animated />
      );
    }
    return progress;
  }

  function totalDurationTooLong() {
    if (turnaroundLevels.length > 0) {
      const transcriptionService = props.selectedServices?.find(
        (s) => s.serviceType === 'Transcription'
      );
      const audioDescriptionService = props.selectedServices?.find(
        (s) => s.serviceType === 'AudioDescription'
      );
      return compareDuration(transcriptionService)
        ? true
        : compareDuration(audioDescriptionService);
    } else {
      return false;
    }
  }

  function compareDuration(service) {
    if (service) {
      const selectedName = service.orderOptions.turnaroundLevel.name;
      const tlDuration = turnaroundLevels.find((tl) => tl.name === selectedName).maxDailyDuration;
      return durationSum > tlDuration;
    }
    return false;
  }

  const unknownDurationNote = (
    <tr>
      <td colSpan="4">
        <Alert variant="warning">
          <div className={css(styles.durationText)}>
            <span className={css(styles.bold)}>Note:</span>
            &nbsp;We can not determine the duration for one or more of your files. Please note that
            if the total duration of all your selected files exceeds the duration&nbsp; limit as
            stated in our Service Level Agreement, we will make reasonable efforts to complete&nbsp;
            all your files on time, but can not guarantee completion by the delivery time for files
            that exceed this duration limit.
          </div>
        </Alert>
      </td>
    </tr>
  );

  const totalDurationTooLongNote = (
    <tr>
      <td colSpan="4">
        <Alert variant="warning">
          <div className={css(styles.durationText)}>
            <span className={css(styles.bold)}>Note:</span>
            &nbsp;The total duration of all your selected files exceeds the duration limit as stated
            in our&nbsp;
            <a href="https://www.3playmedia.com/sla/" target="_blank" rel="noopener noreferrer">
              Service Level Agreement
            </a>
            . We will make reasonable efforts to complete all your files on time, but can not&nbsp;
            guarantee completion by the delivery time for files that exceed this duration limit.
          </div>
        </Alert>
      </td>
    </tr>
  );

  const FileDurationMessage = ({ file }) => {
    if (!file.duration) return null;
    const transcriptionOverLimit = file.duration > transcriptionTurnaroundLevel.maxFileDuration;
    const voiceArtistAudioDescriptionOverLimit =
      hasVoiceArtistAudioDescription && file.duration > VOICE_ARTIST_AUDIO_DESCRIPTION_FILE_LIMIT;
    const showMessage = transcriptionOverLimit || voiceArtistAudioDescriptionOverLimit;

    return (
      showMessage && (
        <tr>
          <td colSpan="4">
            <Alert variant="warning">
              <div className={css(styles.durationText)}>
                <span className={css(styles.bold)}>Note:</span>
                &nbsp;The duration of this file exceeds the per-file duration limit
                {transcriptionOverLimit && (
                  <>
                    &nbsp;for&nbsp;
                    <span className={css(styles.bold)}>
                      {transcriptionTurnaroundLevel.displayName}
                    </span>
                    &nbsp;turnaround
                  </>
                )}
                . We will make reasonable efforts to complete this file on time, but can not
                guarantee completion by the delivery time.
              </div>
            </Alert>
          </td>
        </tr>
      )
    );
  };

  FileDurationMessage.propTypes = {
    file: PropTypes.object,
  };

  function openWordlistModal(file) {
    setWordlistModal({ fileName: file.name, show: true });
  }

  function getWordlistWords() {
    const file = props.filesToUpload.find((f) => f.name === wordlistModal.fileName);
    return file ? file.words : null;
  }

  function handleWordlistChange(words) {
    props.setFilesToUpload(
      props.filesToUpload.map((f) =>
        f.name === wordlistModal.fileName ? { ...f, words: words } : f
      )
    );
    setWordlistModal({ show: false });
  }

  if (props.filesToUpload.length === 0 && props.rejectedFiles.length === 0) {
    return null;
  }

  return (
    <>
      <Table className={`mt-4 ${css(styles.table)}`}>
        <thead>
          <tr>
            <th>{props.cheatsheet && 'Cheatsheet '}File Name</th>
            {props.showProofToFinal && (
              <th className={css(styles.smallTableColumn)}>
                File is Proof To Final
                <ThreePlayTooltip tooltipText="Files marked Proof to Final are expected to be updated during service processing" />
              </th>
            )}
            {props.showFileSize && <th className={css(styles.smallTableColumn)}>File Size</th>}
            {props.showFileDuration && <th className={css(styles.smallTableColumn)}>Duration</th>}
            {props.wordlistAssignment !== null && props.serviceSupportingWordlistSelected && (
              <th className={css(styles.mediumTableColumn)}>
                Wordlist
                <ThreePlayTooltip tooltipText="To improve captioning accuracy, add a Wordlist of the proper nouns, terms, and phrases unique to your organization that may be commonly used in the audio for your file." />
              </th>
            )}
            <th className={css(styles.smallTableColumn)}>Remove</th>
          </tr>
        </thead>
        <tbody>
          {props.filesToUpload.map((file, index) => {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <EditableFileName
                      file={file}
                      setFileName={(name) => {
                        props.setFilesToUpload((currFiles) => {
                          const newObj = [...currFiles];
                          newObj[index].name = name;
                          return newObj;
                        });
                      }}
                    />
                    <br />
                    {props.showUploader && props.uploading && progressBar(file.sourceS3File.key)}
                    {alignmentService && (
                      <AddAlignment
                        alignmentText={props.filesToUpload[index].alignmentText}
                        setAlignmentText={props.setAlignmentTexts}
                        index={index}
                      />
                    )}
                  </td>
                  {props.showProofToFinal && (
                    <td>
                      <Form.Check
                        checked={props.filesToUpload[index]['proofToFinal'] || false}
                        className="d-inline-block"
                        onChange={() =>
                          props.setFilesToUpload((currFiles) => {
                            const newObj = [...currFiles]; // Create a new object to get react to re-render
                            newObj[index]['proofToFinal'] = !newObj[index]['proofToFinal'];
                            return newObj;
                          })
                        }
                        type="checkbox"
                      />
                    </td>
                  )}
                  {props.showFileSize && <td>{bytesToSize(file.size)}</td>}
                  {props.showFileDuration && <td>{durationToDisplay(file)}</td>}
                  {props.wordlistAssignment !== null && props.serviceSupportingWordlistSelected && (
                    <td className={css(styles.wordlistLink)}>
                      <span onClick={() => openWordlistModal(file)}>
                        {file.words === null || file.words === '' ? (
                          <>
                            <i className="fa fa-plus" /> Add Wordlist
                          </>
                        ) : (
                          <>
                            <i className="fa fa-file-text-o" /> Edit Wordlist
                          </>
                        )}
                      </span>
                    </td>
                  )}
                  <td onClick={() => props.removeFromUploadList(index)}>
                    <Button
                      size="sm"
                      className={css(styles.removeButton)}
                      tabIndex={index + FILE_UPLOAD_TABLE_INDEX}
                    >
                      <i className="btn fa fa-times text-danger pl-4" aria-hidden="true" />
                    </Button>
                  </td>
                </tr>
                <FileDurationMessage file={file} />
              </React.Fragment>
            );
          })}
          {missingDurations && unknownDurationNote}
          {(props.showFileSize || props.showFileDuration) && (
            <tr>
              <td>Total</td>
              {props.showProofToFinal && <td></td>}
              {props.showFileSize && <td>{bytesToSize(fileSizeSum)}</td>}
              {props.showFileDuration && (
                <td>
                  {props.filesToUpload.length > 0 && props.filesToUpload[0].sourceS3File
                    ? durationString(Math.round(durationSum / 1000))
                    : durationString(Math.round(durationSum))}
                </td>
              )}
              {props.wordlistAssignment !== null && <td></td>}
              <td></td>
            </tr>
          )}
          {totalDurationTooLong() && totalDurationTooLongNote}
          {props.rejectedFiles.map((file, index) => (
            <tr key={index}>
              <td className="text-error" colSpan="3">
                {file.name}
                <br />
                <span className="text-danger">
                  <i className="fa fa-exclamation-triangle pr-2"></i>
                  Error: This file is{' '}
                  {file.fileTooLarge ? 'larger than ' + props.sizeLimit : 'not the right format!'}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {props.wordlistAssignment !== null && (
        <WordlistModal
          handleWordlistChange={handleWordlistChange}
          name={wordlistModal.fileName}
          onClose={() => setWordlistModal({ show: false })}
          readOnly={false}
          resourceType={'File'}
          show={wordlistModal.show}
          words={getWordlistWords()}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  bold: {
    'font-weight': 'bold',
  },
  durationText: {
    width: '80%',
    color: '#8A6D3B',
    'font-size': '0.8rem',
  },
  mediumTableColumn: {
    width: '15%',
    maxWidth: '15%',
  },
  removeButton: {
    backgroundColor: 'transparent',
    border: 'none',
  },
  table: {
    tableLayout: 'fixed',
  },
  textProcessing: {
    color: '#976C00',
  },
  smallTableColumn: {
    width: '10%',
    maxWidth: '10%',
  },
  wordlistLink: {
    color: '#007EB5',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'bold',
  },
});

FileUploadTable.propTypes = {
  batchId: PropTypes.string,
  cheatsheet: PropTypes.bool,
  filesToUpload: PropTypes.arrayOf(PropTypes.object),
  rejectedFiles: PropTypes.arrayOf(PropTypes.object),
  removeFromUploadList: PropTypes.func,
  selectedServices: selectedServicesType,
  serviceSupportingWordlistSelected: PropTypes.bool,
  setAlignmentTexts: PropTypes.func,
  setFilesToUpload: PropTypes.func,
  showProofToFinal: PropTypes.bool,
  showFileDuration: PropTypes.bool,
  showFileSize: PropTypes.bool,
  showUploader: PropTypes.bool,
  sizeLimit: PropTypes.string,
  uploading: PropTypes.bool,
  uploadProgress: PropTypes.object,
  validFileUploads: PropTypes.object,
  wordlistAssignment: PropTypes.shape({
    resourceType: PropTypes.string,
    wordlist: PropTypes.shape({
      name: PropTypes.string,
      words: PropTypes.string,
    }),
  }),
};

export default FileUploadTable;
