import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function StartFileUploadModal(props) {
  return (
    <Modal show={props.show} onHide={props.closeStartUploadModal}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
        <p className="font-weight-bold">
          {props.acceptedFiles.length} Total file{props.acceptedFiles.length > 1 ? 's' : ''}
        </p>
        {props.acceptedFiles.map((file, index) => (
          <p key={index}>{file.name.replace(/\.[^.]*$/, '')}</p>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.closeStartUploadModal()}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => props.startUpload()}>
          Start Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

StartFileUploadModal.propTypes = {
  acceptedFiles: PropTypes.arrayOf(PropTypes.object),
  closeStartUploadModal: PropTypes.func,
  show: PropTypes.bool,
  startUpload: PropTypes.func,
};

export default StartFileUploadModal;
