export const query = `{
    referralCodes {
      active
      id
      referralLimit
      token
      referrals {
        hoursTarget
        payout
        percentCompleted
        createdAt
        referredApplicant {
          name
          email
      }
    }
  }
}`;
