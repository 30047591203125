import React, { useMemo, useReducer } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { env } from '~/core/env';

import WithIcon from '~/components/app/common/WithIcon';
import Logo from '../common/images/3play_logo.svg';

import { versionQuery } from './queries/versionQuery';
import { libraryQuery } from './queries/libraryQuery';
import { AccessPlayerBuilderProps, Source } from './types/AccessPlayerTypes';
import { BetaTerms } from './forms/v0/BetaTerms';
import { FormV0 } from './forms/v0/FormV0';
import { builderReducer, emptySourceForPlatform, State } from './state/builderReducer';
import { SharePlayer } from './components/SharePlayer/SharePlayer';
import { PlayerPreview } from './components/PlayerPreview';

import css from './AccessPlayerBuilder.module.css';

function hasMissingIntegrationData(source: Source) {
  if (source.platform === 'Kaltura') {
    return !source.kaUiconfId;
  }

  if (source.platform === 'Brightcove') {
    return !source.bcPlayer;
  }

  return false;
}

// We don't want to pass along id or displayName.
function sourceToSnippetSettings(source: Source) {
  const { id, displayName, ...remainingValues } = source;

  return remainingValues;
}

function AccessPlayerBuilder({
  mediaFile,
  previewViewApiAccess,
  publicViewApiAccess,
  availableSources,
  betaTermsAccepted,
  betaTermsActiveDate,
}: AccessPlayerBuilderProps) {
  const initialSource = availableSources[0] || emptySourceForPlatform('native');
  const initialState: State = {
    priority: 'lowPriority',
    advancedSettingsVisible: hasMissingIntegrationData(initialSource),
    loadingParsedURL: null,

    grantedToken: null,
    loadingGrantedToken: false,

    source: initialSource,
    showTranscript: true,
    showAd: true,
    showDownload: true,
    showLanguageDropdown: true,

    betaTermsAccepted: betaTermsAccepted,
  };

  const [state, dispatch] = useReducer(builderReducer, initialState);

  const { data: accessPlayerVersions = [] } = useQuery(['versions'], versionQuery);
  const latestVersion = accessPlayerVersions[0];

  const { data: library } = useQuery(
    ['library', latestVersion],
    () => libraryQuery(latestVersion || null),
    {
      enabled: Boolean(latestVersion),
    }
  );

  /**
   * Neat part about our versioning scheme is that we can dynamically
   * switch between running the page against production assets/API v.s.
   * local assets/API.
   */
  const usingLocalVersion = env.threeplayEnv === 'local' && latestVersion === 'local';

  /**
   * This snippet is rendered with the preview token to allow 3play-hosted s3
   * files to play.
   */

  const { previewSnippet, previewKey } = useMemo(() => {
    const createSnippetOrNode =
      state.priority === 'highPriority' ? library?.createNode : library?.createSnippet;

    return {
      /** We use this key to helpfully remount when the snippet changes */
      previewKey: Date.now().toString(),
      previewSnippet:
        (latestVersion &&
          createSnippetOrNode &&
          createSnippetOrNode(
            {
              accessId: previewViewApiAccess.publicAccessId,
              version: latestVersion,
              baseSettings: state.priority,
              threeplayId: mediaFile.id.toString(),
              showTranscript: state.showTranscript,
              showAd: state.showAd,
              showDownload: state.showDownload,
              // do not pass this at all for now; until product finds a way to make it
              // a paid feature. We don't want it visible in the snippet.
              // showLanguageDropdown: state.showLanguageDropdown,
              ...sourceToSnippetSettings(state.source),
              // Tell the plugin to request data from our local rails (proxied
              // through the local @private/cdn in 3players) when development locally.
              ...(usingLocalVersion ? { apiHost: 'local' } : {}),
            },
            // Generate the snippet to with a local URL to the 3players CDN
            // so we can load local assets.
            usingLocalVersion ? { pluginURL: 'http://localhost:8787' } : {}
          )) ||
        '',
    };
  }, [
    usingLocalVersion,
    state.priority,
    state.source,
    state.showTranscript,
    state.showDownload,
    state.showAd,
    library,
    latestVersion,
    mediaFile.id,
    previewViewApiAccess,
  ]);

  /**
   * We don't offer video hosting; therefore the share snippet
   * is generated separately.
   */
  const shareSnippet =
    (latestVersion &&
      library?.createSnippet({
        accessId: publicViewApiAccess?.publicAccessId || state.grantedToken || 'YOUR_API_KEY',
        version: latestVersion,
        baseSettings: state.priority,
        showTranscript: state.showTranscript,
        showAd: state.showAd,
        showDownload: state.showDownload,
        // do not pass this at all for now; until product finds a way to make it
        // a paid feature. We don't want it visible in the snippet.
        // showLanguageDropdown: state.showLanguageDropdown,
        ...sourceToSnippetSettings(state.source),
        ...(state.source.id === 'custom' ? { threeplayId: mediaFile.id.toString() } : {}),
      })) ||
    '';

  return (
    <>
      {!state.betaTermsAccepted && (
        <BetaTerms dispatch={dispatch} activeDate={betaTermsActiveDate} />
      )}

      {state.betaTermsAccepted && (
        <div className={css.grid}>
          <nav className={css.nav}>
            <div className={css.branding}>
              <img src={Logo} alt="3Play Media Logo" />
              <h1>Access Player Builder</h1>
            </div>
            <a
              className="btn btn-primary btn-sm"
              target="_blank"
              rel="noreferrer"
              href="https://support.3playmedia.com/hc/en-us/sections/8978635358231-Access-Player"
            >
              <WithIcon icon="fa fa-question-circle" side="right">
                Help
              </WithIcon>
            </a>
          </nav>

          <div className={css.form}>
            <FormV0 availableSources={availableSources} state={state} dispatch={dispatch} />
          </div>

          <div className={css.preview}>
            <PlayerPreview previewKey={previewKey} previewSnippet={previewSnippet} />
          </div>

          <div className={css.share}>
            <SharePlayer
              state={state}
              dispatch={dispatch}
              shareSnippet={shareSnippet}
              hasApiKey={Boolean(state.grantedToken ?? publicViewApiAccess)}
            />
          </div>
        </div>
      )}
    </>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function Container(props: AccessPlayerBuilderProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <AccessPlayerBuilder {...props} />
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}

export default Container;
