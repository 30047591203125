const refreshModalDataQuery = `query refreshModalData {
  project{
    paymentAccounts {
      autobill, 
      billingContactName,
      id,
      validCard,
      lastDigits,
      name,
    },
    billingInformation {
      billingContactName,
      billingPhoneNumber,
      billingAddressOne,
      billingAddressTwo,
      billingAddressThree,
      billingAddressFour,
      billingAddressCity,
      billingAddressState,
      billingAddressCountry,
      billingAddressZipcode,
      purchaseOrder, 
      billingContactName,
      validBillingAddress,
    },
  }
}`;

const getAccountPlanOverviewQuery = `query getAccountPlan {
  account {
    accountType,
    accountPlan {
      id,
      termEnd,
      termStart,
      autoRenew,
      paymentFrequency,
      renewalPaymentFrequency,
    },
  }
}`;

export { refreshModalDataQuery, getAccountPlanOverviewQuery };
