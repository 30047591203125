import React from "react";
import PropTypes from "prop-types";

import OutputAssetShape from "~/components/app/order_more/components/OutputAssetShape";

const OutputAssetSummary = ({ selectedOutputAssets }) => {
  return (
    <div>
      <h4>Output Formats Ordered</h4>
      <ul>
        {selectedOutputAssets.map((mix) => (
          <>
            <li key={mix.code}>{mix.adDisplayName}</li>
            <ul>
              {mix.embeds.map((embed) => (
                <li key={embed.code}>{embed.adDisplayName}</li>
              ))}
            </ul>
          </>
        ))}
      </ul>
    </div>
  );
};

OutputAssetSummary.propTypes = {
  selectedOutputAssets: PropTypes.arrayOf(
    PropTypes.shape({
      ...OutputAssetShape,
      embeds: PropTypes.arrayOf(OutputAssetShape),
    })
  ),
};

export default OutputAssetSummary;
