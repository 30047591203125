import React, { Dispatch, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';

import { Action } from '../../state/builderReducer';

import css from './BetaTerms.module.css';

interface BetaTermsProps {
  dispatch: Dispatch<Action>;
  activeDate: string;
}

type Acceptance = {
  success: boolean;
};

export function BetaTerms(props: BetaTermsProps) {
  const { dispatch, activeDate } = props;
  const [termsAccepted, setTermsAccepted] = useState(false);

  const acceptTermsURI = '/access_player_builder/accept_terms';
  const csrfToken =
    document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';

  function recordTermsAcceptance() {
    dispatch({ type: 'TOGGLE_BETA_TERMS_ACCEPTANCE' });
  }

  function request<TResponse>(url: string, options: RequestInit = {}): Promise<TResponse> {
    return fetch(url, options)
      .then((response) => response.json())
      .then((data) => data as TResponse);
  }

  function requestOptions(): RequestInit {
    return {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
    };
  }

  function submitAcceptance() {
    request<Acceptance>(acceptTermsURI, requestOptions())
      .then((success) => {
        if (success) {
          recordTermsAcceptance();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function displayDate(dateString: string) {
    let date = new Date();
    if (dateString.length > 0) {
      date = new Date(dateString);
    }
    return date.toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  return (
    <Alert variant="warning" className={css.betaTerms}>
      <Alert.Heading>Beta Terms of Service</Alert.Heading>
      <p>
        <em>Last updated {displayDate(activeDate)}</em>
      </p>
      <p>
        By checking the box below, you indicate that you agree to our{' '}
        <Alert.Link
          className={css.termsLink}
          target="_blank"
          href="https://www.3playmedia.com/beta-agreement/"
        >
          Beta Terms of Service
        </Alert.Link>
        .
      </p>
      <Form.Group controlId="betaServicesAcceptance">
        <Form.Check
          checked={termsAccepted}
          className="d-inline-block"
          onChange={() => setTermsAccepted(!termsAccepted)}
          type="checkbox"
        />
        I have read and accept the 3Play Beta Terms of Service
      </Form.Group>
      <Button disabled={!termsAccepted} onClick={submitAcceptance}>
        Submit
      </Button>
    </Alert>
  );
}
