import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function RemoveLinkedAccountModal(props) {
  return (
    <Modal show={props.show} onHide={() => props.setShowRemoveLinkedAccountModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Remove Linked Account</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to remove this linked account?</p>
        <p>
          <b>{props.integrationName}</b>
        </p>
        {!(
          props.enabledFeatures.includes('all_vpis') ||
          ['YouTube', 'Vimeo'].includes(props.integrationPlatform)
        ) && (
          <p>
            <b>Warning: </b>
            {
              "Express accounts will not be able to re-add linked accounts that aren't Youtube or Vimeo without upgrading to Pro."
            }
          </p>
        )}
        <p>
          All files that have already been uploaded for processing will remain in the system. You
          can re-link your account at any time.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.setShowRemoveLinkedAccountModal(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => props.deleteIntegration()}>
          Yes - Remove Account
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

RemoveLinkedAccountModal.propTypes = {
  deleteIntegration: PropTypes.func,
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  integrationName: PropTypes.string,
  integrationPlatform: PropTypes.string,
  setShowRemoveLinkedAccountModal: PropTypes.func,
  show: PropTypes.bool,
};

export default RemoveLinkedAccountModal;
