import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Card } from '@threeplayground/unstable';
import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';

import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { DownloadableAsset } from '../../common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';
import { FileUploader } from '../../common/file_uploads/FileUploader';
import { JobInstructions } from '../../common/JobInstructions';
import { JobLoadError } from '../../common/JobLoadError';
import { MediaShuttleUploader } from '../../common/audio_assets/MediaShuttleUploader';
import { OffPlatformJobDetails } from '../../common/OffPlatformJobDetails';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';

type ParamType = {
  id: string;
};

export const OffPlatformVoiceOverJob = () => {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, isError } = useJobQuery({ where: { idEq: id } });
  if (isError) return <JobLoadError />;
  if (isLoading) return null;

  if (!data.job || data.job.__typename !== 'OffPlatformVoiceOverJobGraphql')
    return <JobLoadError />;

  const job = data.job;
  const { orderInstructions, mediaFile, voiceOverScript, voiceOverDescriptionAudio } = job;
  const getAssets = (): DownloadableAsset[] => {
    const sourceAsset = {
      displayName: 'Source Video',
      url: `/off_platform_voice_over_jobs/${id}/download_source_video`,
    };

    const files = voiceOverScript.inputVideoDescriptionScript?.files;
    if (files != null && files.length != 0 && files[0] != undefined) {
      return [
        sourceAsset,
        {
          displayName: 'Video Description Script',
          url: files[0].presignedDownloadUrl,
        },
      ];
    }
    return [sourceAsset];
  };

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Voice Over Job'}>
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails job={job} downloadableAssets={getAssets()} />

          <Card>
            <Card.Header className="font-weight-bold">Script Files</Card.Header>
            <Card.Body>
              <UploadedFileList files={voiceOverScript.files} />
              <FileUploader
                assetId={voiceOverScript.id}
                assetType={AssetClassNameEnum.VoiceOverScript}
                allowedFileFormats={voiceOverScript.allowedFileFormats}
                uploadedFiles={voiceOverScript.files}
              />
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header className="font-weight-bold">Voice Over Audio Description</Card.Header>
            <Card.Body>
              <MediaShuttleUploader
                assetFileId={voiceOverDescriptionAudio.id}
                instructions={voiceOverDescriptionAudio.mediaShuttleInstructions}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="mt-4">
          <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
};
