import React, { createContext, ReactNode } from 'react';

export interface FlashMessage {
  id: number;
  variant: 'success' | 'danger' | 'warning' | 'info';
  content: string;
}

export interface FlashMessageContextType {
  messages: FlashMessage[];
  setMessages: React.Dispatch<React.SetStateAction<FlashMessage[]>>;
}

export const FlashMessageContext = createContext<FlashMessageContextType | undefined>(undefined);

interface FlashMessageProviderProps {
  children: ReactNode;
}

export function FlashMessageProvider({ children }: FlashMessageProviderProps): JSX.Element {
  const [messages, setMessages] = React.useState<FlashMessage[]>([]);

  return (
    <FlashMessageContext.Provider value={{ messages, setMessages }}>
      {children}
    </FlashMessageContext.Provider>
  );
}
