import React from 'react';
import PropTypes from 'prop-types';

import TakeMeToThreeplayButton from './TakeMeToThreeplayButton';
import Success from './images/success.png';
function Thanks({ email }) {
  return (
    <div className="d-flex flex-column align-items-center justy-content-center">
      <img className="main-image mb-4" src={Success}></img>
      <div className="middle-lane">
        <h1 className="mb-4">Thanks for submitting your initial application!</h1>
        <p className="primary-text mb-3">
          We plan to review preliminary applications as necessary to meet our workflow demands.
          Thus, it may be some time before we contact you about the next steps in your application
          process.
        </p>
        <p className="primary-text">
          We understand that in the interim your availability and interest may change. We’ll send an
          email to {email} to invite you to take the extended application when you’re next in line.
        </p>
        <TakeMeToThreeplayButton />
      </div>
    </div>
  );
}

Thanks.propTypes = {
  email: PropTypes.string.isRequired,
};

export default Thanks;
