import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { Alert, Button, Form } from 'react-bootstrap';

import Welcome from '~/components/signin/Welcome';
import { BillingInformationForm } from '~/components/app/account_plans/steps/BillingInformation';
import { TermsAcceptanceForm } from '~/components/signin/terms_acceptance/TermsAcceptance';

function CreateAccount({
  csrfToken,
  metadata,
  partnerToken,
  partnerTokenActive,
  termsActiveDate,
  termsText,
  whatsNew,
}) {
  const [billingInfo, setBillingInfo] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);

  if (!partnerTokenActive) {
    return (
      <Welcome whatsNew={whatsNew} sidePanel={'whats_new'}>
        <Alert variant="danger">
          This request has expired, please go back to AIM to restart the 3Play Media account
          creation process.
        </Alert>
      </Welcome>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('authenticity_token', csrfToken);
    data.append('billing_phone_number', billingInfo.billingPhoneNumber);
    data.append('billing_address_one', billingInfo.billingAddressOne);
    if (billingInfo.billingAddressTwo) {
      data.append('billing_address_two', billingInfo.billingAddressTwo);
    }
    if (billingInfo.billingAddressThree) {
      data.append('billing_address_three', billingInfo.billingAddressThree);
    }
    if (billingInfo.billingAddressTwo) {
      data.append('billing_address_four', billingInfo.billingAddressFour);
    }
    data.append('billing_address_city', billingInfo.billingAddressCity);
    data.append('billing_address_state', billingInfo.billingAddressState);
    data.append('billing_address_country', billingInfo.billingAddressCountry);
    data.append('billing_address_zipcode', billingInfo.billingAddressZipcode);
    data.append('purchase_order', billingInfo.purchaseOrder);
    data.append('partner_token', partnerToken);

    fetch('/aim/accounts', {
      method: 'POST',
      body: data,
    })
      .then((response) => response.json())
      .then((json) => console.log(json));
  };

  const enableSubmit =
    billingInfo.billingPhoneNumber &&
    billingInfo.billingAddressOne &&
    billingInfo.billingAddressCity &&
    billingInfo.billingAddressState &&
    billingInfo.billingAddressCountry &&
    billingInfo.billingAddressZipcode &&
    termsAccepted;

  return (
    <Welcome whatsNew={whatsNew} sidePanel={'whats_new'}>
      <h3>Welcome {metadata['account_name']}</h3>
      <Form>
        <p>
          Please fill out the information below to create your 3Play Media account. This is required
          to set up your account and billing. You will not be invoiced until services are ordered.
          Once created you will be able to request captions from 3Play from within your AIM account.
        </p>
        <p>
          If you already have a 3Play Media account,{' '}
          <Alert.Link href="https://account.3playmedia.com">login</Alert.Link> to 3Play Media and
          follow the steps here to create an AIM integration.
        </p>
        <h4>Billing Information</h4>
        <BillingInformationForm billingInfo={billingInfo} setBillingInfo={setBillingInfo} />
        <TermsAcceptanceForm
          activeDate={termsActiveDate}
          legalese={termsText}
          isAccepted={termsAccepted}
          setIsAccepted={setTermsAccepted}
        />
        <Button
          className="ml-2"
          size="sm"
          variant="primary"
          disabled={!enableSubmit}
          onClick={handleSubmit}
        >
          Create Account
        </Button>
      </Form>
    </Welcome>
  );
}

CreateAccount.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
  partnerToken: PropTypes.string.isRequired,
  partnerTokenActive: PropTypes.bool.isRequired,
  termsActiveDate: PropTypes.string.isRequired,
  termsText: PropTypes.string.isRequired,
  whatsNew: PropTypes.string.isRequired,
};

export default CreateAccount;
