/**
 * WARNING: Consider this function UNSTABLE and as such the API may change, use at your own risk
 */
import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';

import { ThreeplayAPIContext } from './ThreeplayApiProvider';

/**
 * Call a GraphQL mutation
 *
 * @async
 * @function use3PMutation
 * @param {string} mutationString - The GraphQL mutation string
 * @param {object} options - A place to pass configuration options; Note options unknown to use3PQuery will be passed to useQuery.
 * @param {string} options.extractKey - The name of the key to extract from the response object.  This will always be the mutation name.  If you chose this value for your cacheKey, you can omit it and we'll use that as the default
 * @returns {Promise<data|errors>} The data returned back from the API or an errors array.
 */

export const use3PMutation = (mutationString, { extractKey, ...useMutationOptions }) => {
  const client = useContext(ThreeplayAPIContext);

  if (client === undefined) {
    throw new Error('use3PMutation must be used within a ThreeplayAPIProvider');
  }

  return useMutation(
    (data) => client.request(mutationString, data, extractKey),
    useMutationOptions
  );
};
