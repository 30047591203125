import React from 'react';

import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';
import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { StudioFileDownloads } from './StudioFileDownloads';

const apiClient = new ThreeplayApiV2('/data');
interface StudioDownloadPageProps {
  mediaFileId: string;
}
const StudioDownloadPage = ({ mediaFileId }: StudioDownloadPageProps) => {
  return (
    <ThreeplayAPIProvider client={apiClient}>
      <StudioFileDownloads mediaFileId={mediaFileId} />
    </ThreeplayAPIProvider>
  );
};

export default StudioDownloadPage;
