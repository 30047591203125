import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';
import MultiServiceTable from './MultiServiceTable';

function DubbingServiceCard(props) {
  const headers = ['Language', 'Translation Type', 'Ordered'];

  return (
    <>
      <MultiServiceTable headers={headers}>
        {props.services.map((service) => (
          <tr key={service.id}>
            <td>{service.targetLanguage?.fullName ?? 'Unknown Language'}</td>
            <td>{service.translationType}</td>
            <td>
              {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
            </td>
          </tr>
        ))}
      </MultiServiceTable>
    </>
  );
}

DubbingServiceCard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  userTimeZone: PropTypes.string,
};

export default DubbingServiceCard;
