import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import WithIcon from '~/components/app/common/WithIcon';

import { revisePath } from '~/helpers/app/paths';

const ReviseButton = ({ fileId, services }) => {
  return (
    <DropdownButton
      size="sm"
      className={css(styles.reviseButton)}
      title={<WithIcon icon="fa fa-pencil">Revise</WithIcon>}
    >
      {services.map((service, index) => (
        <Dropdown.Item key={index} href={revisePath(fileId, service.serviceSlug)}>
          {service.serviceName}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

ReviseButton.propTypes = {
  fileId: PropTypes.number,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      serviceName: PropTypes.string,
      serviceSlug: PropTypes.string,
    })
  ),
};

const styles = StyleSheet.create({
  reviseButton: {
    float: 'right',
    marginRight: '13px',
    marginTop: '-4px',
  },
});

export default ReviseButton;
