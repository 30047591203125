import React from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ChallengeBadge from '../ChallengeBadge';
import NaiveDisplayTracker from '../trackers/NaiveDisplayTracker';
import { DEFAULT_COPY } from '../constants';

const InProgresssChallengeModal = ({ challenge = {}, show = false, onClose = () => {} }) => {
  const { description, name, awardLevels, membership } = challenge;
  const { targetUnit } = awardLevels?.[0] ? awardLevels?.[0] : {};

  return (
    <Modal size="lg" data-testid="in-progress-challenge-modal" show={show} onHide={onClose}>
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ChallengeBadge />
          </Col>
          <Col className={'col-8'}>
            <h5>Challenge Details</h5>
            <p>{description ? description : DEFAULT_COPY.noChallengeDescription}</p>
            {/* temporary fix for the wayfair challenge.
              for the wayfair challenge, we're storing progress and AwardLevel.target in minutes
              we want to niavely display the data for this challenge
            */}
            <NaiveDisplayTracker progress={membership?.progress} targetUnit={targetUnit} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

InProgresssChallengeModal.propTypes = {
  challenge: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default InProgresssChallengeModal;
