import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';

import { ThemeProvider } from '@threeplayground/index';
import {
  PermissionProvider,
  PermissionProps,
} from '~/components/jobs/common/permission_provider/PermissionProvider';

const apiClient = new ThreeplayApiV2('/data');

type JobAppProps = {
  showPath: string;
  jobComponent: React.ReactNode;
  permissions: PermissionProps;
};

export function JobAppContainer({ showPath, jobComponent, permissions }: JobAppProps) {
  return (
    <PermissionProvider permissions={permissions}>
      <ThemeProvider>
        <ThreeplayAPIProvider client={apiClient}>
          <BrowserRouter>
            <Routes>
              <Route path={showPath} element={jobComponent} />
            </Routes>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </ThreeplayAPIProvider>
      </ThemeProvider>
    </PermissionProvider>
  );
}
