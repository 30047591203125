import { Formatter } from '../_types';

export class ColonNumberFormatter implements Formatter {
  /**
   * A formatter that removes the whitespace after a colon if it is followed
   * by a number.
   *
   * @example new ColonNumberFormatter().format("5: 00 pm today")
   * // returns "5:00 pm today"
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  format(text: string) {
    return text.replace(/(:\s)([0-9]+)/g, (_match, p1: string, p2: string) => `${p1.trim()}${p2}`);
  }
}
