import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';
import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CreatePineapplePage } from './CreatePineapplePage';
import { UpdatePineapplePage } from './UpdatePineapplePage';
import { ThemeProvider } from '@threeplayground/index';
import { PineappleList } from './PineappleList';
import { pineapplePaths } from './pineapplePaths';

const queryClient = new ThreeplayApiV2('/pattern_library/graphql');

function PineappleApp() {
  return (
    <ThemeProvider>
      <ThreeplayAPIProvider client={queryClient}>
        <PineappleRoutes />
        <ReactQueryDevtools initialIsOpen={false} />
      </ThreeplayAPIProvider>
    </ThemeProvider>
  );
}

function PineappleRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={pineapplePaths.list.route} element={<PineappleList />}></Route>
        <Route path={pineapplePaths.show.route} element={<UpdatePineapplePage />}></Route>
        <Route path={pineapplePaths.create.route} element={<CreatePineapplePage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default PineappleApp;
