import React from 'react';
import PropTypes from 'prop-types';

export const SwateiContext = React.createContext();

const getContextData = (dataToCaption, generalInfo) => {
  return {
    isLiveEvent: dataToCaption.isLiveEvent,
    streamlessEvent: !generalInfo.hasLiveStream,
    useEndEventButton: generalInfo.useEndEventButton,
    estimatedDurationInMS: generalInfo.estimatedDurationInMS,
    videoUrl: dataToCaption.videoUrl,
    isPracticeMode: dataToCaption.isPracticeMode,
    eventId: generalInfo.eventId,
    eventPublicId: generalInfo.eventPublicId,
  };
};
export const SwateiProvider = ({ dataToCaption, generalInfo, children }) => {
  return (
    <SwateiContext.Provider value={getContextData(dataToCaption, generalInfo)}>
      {children}
    </SwateiContext.Provider>
  );
};

SwateiProvider.propTypes = {
  dataToCaption: PropTypes.shape({
    videoUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        url: PropTypes.string,
        app_name: PropTypes.string,
        stream_name: PropTypes.string,
      }),
    ]),
    isLiveEvent: PropTypes.bool.isRequired,
  }),
  generalInfo: PropTypes.shape({
    estimatedDurationInMS: PropTypes.number,
    eventId: PropTypes.number,
    eventPublicId: PropTypes.string,
    formAuthToken: PropTypes.string,
    hasLiveStream: PropTypes.bool,
  }),
  children: PropTypes.node,
};
