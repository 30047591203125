import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';
import { use3PMutation } from '~/logic/unstable/use3PMutation';

import ReformatsForm from './ReformatsForm';
import OrderMore from '~/components/app/order_more/OrderMore';

const ORDER_REFORMAT_SERVICE = `
mutation orderReformatServiceMutation($data: OrderReformatServiceInput!) {
  orderReformatServiceMutation(data: $data) {
    data {
      instructions
      reformatMediaFileSourceUrl
      originalMediaFileId
    }
    errors {
      code
      message
    }
  }
}`;

const ReformatsWrapper = (props) => {
  const apiClient = new ThreeplayApiV2('/data');

  return (
    <>
      <ErrorBoundary component="ReformatsApp">
        <ThreeplayAPIProvider client={apiClient}>
          <ReformatsApp {...props} />
        </ThreeplayAPIProvider>
      </ErrorBoundary>
    </>
  );
};

const ReformatsOrderSummary = ({ fileName, instructions, uploadedFileName }) => {
  return (
    <>
      <div>
        <p>
          <b>Current File Name:</b> {fileName}
        </p>
        <p>
          <b>Provided File:</b> {uploadedFileName}
        </p>
        <p>
          <b>Reformat Instructions:</b> {instructions}
        </p>
      </div>
    </>
  );
};

ReformatsOrderSummary.propTypes = {
  fileName: PropTypes.string,
  instructions: PropTypes.string,
  uploadedFileName: PropTypes.string,
};

const ReformatsTipPanel = () => {
  return (
    <>
      <strong>To order reformat services:</strong>
      <ul>
        <li>Upload the new media file to be formatted against.</li>
        <li>
          Add any special instructions to help our team deliver you the format of your dreams.
        </li>
      </ul>
    </>
  );
};

const ReformatsApp = ({
  adReformatWorkflow,
  mediaFile,
  options,
  serviceType,
  supportedFormats,
}) => {
  const [additionalErrors, setAdditionalErrors] = useState([]);
  const [onlyReformatMixing, setOnlyReformatMixing] = useState(false);
  const [reformatFileLink, setReformatFileLink] = useState('');
  const [reformatInstructions, setReformatInstructions] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [sourceUploaded, setSourceUploaded] = useState(false);

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedScriptingFormats, setSelectedScriptingFormats] = useState([]);

  const { mutateAsync } = use3PMutation(ORDER_REFORMAT_SERVICE, {
    extractKey: 'orderReformatServiceMutation',
  });

  const canSubmitReformatOrder = () => {
    const baseRequirements =
      (sourceUploaded || reformatFileLink.length > 0) && reformatInstructions?.length > 0;

    switch (serviceType) {
      case 'dubbing':
      case 'localization':
        return baseRequirements && selectedLanguages.length > 0;
      case 'broadcast_scripts':
        return baseRequirements && selectedScriptingFormats.length > 0;
      default:
        return baseRequirements;
    }
  };

  const onSubmit = async ({ setErrors, setSuccess }) => {
    const data = {
      mediaFileId: mediaFile.id,
      onlyReformatMixing,
      serviceType: serviceType,
      reformatMediaFileSourceUrl: reformatFileLink,
      languages: selectedLanguages,
      broadcastScriptingFormats: selectedScriptingFormats,
      instructions: reformatInstructions,
    };

    const {
      data: responseData,
      errors,
      globalErrors: mutationGlobalErrors,
    } = await mutateAsync({
      data,
    });

    if (errors || mutationGlobalErrors) {
      setErrors([...(errors || []), ...(mutationGlobalErrors || [])].map((me) => me.message));
      return;
    }

    setSuccess(true);
  };

  return (
    <OrderMore
      additionalErrors={additionalErrors}
      mainContent={
        <ReformatsForm
          mediaFile={mediaFile}
          onlyReformatMixing={onlyReformatMixing}
          options={options}
          setErrors={setAdditionalErrors}
          selectedFile={selectedFile}
          selectedLanguages={selectedLanguages}
          selectedScriptingFormats={selectedScriptingFormats}
          setOnlyReformatMixing={setOnlyReformatMixing}
          setReformatFileLink={setReformatFileLink}
          setReformatInstructions={setReformatInstructions}
          setSelectedFile={setSelectedFile}
          setSelectedLanguages={setSelectedLanguages}
          setSelectedScriptingFormats={setSelectedScriptingFormats}
          setSourceUploaded={setSourceUploaded}
          showAudioDescriptionReformatCheckbox={
            serviceType == 'audio_description' && adReformatWorkflow == 'Prompt User'
          }
          sourceUploaded={sourceUploaded}
          supportedFormats={supportedFormats}
        />
      }
      onSubmit={onSubmit}
      orderType="Reformat"
      submissionDisabled={!canSubmitReformatOrder()}
      summaryContent={
        <ReformatsOrderSummary
          fileName={mediaFile.name}
          instructions={reformatInstructions}
          uploadedFileName={selectedFile?.name || reformatFileLink}
        />
      }
      tipPanelContent={<ReformatsTipPanel />}
    />
  );
};

const optionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    displayName: PropTypes.string,
  })
);

ReformatsApp.propTypes = {
  adReformatWorkflow: PropTypes.string,
  mediaFile: PropTypes.object,
  options: PropTypes.shape({
    broadcastScriptingFormats: optionsShape,
    languages: optionsShape,
    mixingOptions: PropTypes.shape({
      mix: optionsShape,
      embed: optionsShape,
    }),
  }),
  serviceType: PropTypes.string,
  supportedFormats: PropTypes.arrayOf(PropTypes.string),
};

export default ReformatsWrapper;
