import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { useVariantList } from '~/core/hooks/useVariantList';
import { useVariantListQuery } from '~/api/opsApi';

function JobFilters(props) {
  const [jobTypeId, setJobTypeId] = useState('1');

  const [accountName, setAccountName] = useState('');
  const [forceList, setForceList] = useState('');
  const [notProjectName, setNotProjectName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [sendToTop, setSendToTop] = useState('');
  const [submarketId, setSubmarketId] = useState('');
  const [traitId, setTraitId] = useState('');

  const traitVariants = useVariantListQuery({ name: 'TRAIT' });
  const traits = traitVariants.data?.variants;
  const isLoadingTraits = traitVariants.isLoading;

  const jobTypeVariants = useVariantListQuery({ name: 'JOB_TYPE' });
  const jobTypes = jobTypeVariants.data?.variants;
  const isLoadingJobTypes = jobTypeVariants.isLoading;

  function computeRansackParams() {
    const filters = {};
    if (jobTypeId) {
      filters.job_type_id_eq = jobTypeId;
    }

    if (accountName) {
      filters.account_name_cont = accountName;
    }
    if (forceList) {
      filters.force_list_eq = forceList;
    }
    if (projectName) {
      filters.project_name_cont = projectName;
    }
    if (notProjectName) {
      filters.project_name_not_cont = notProjectName;
    }
    if (sendToTop) {
      filters.top_of_queue_eq = sendToTop;
    }
    if (submarketId) {
      filters.submarkets_id_eq = submarketId;
    }
    if (traitId) {
      filters.job_traits_trait_id_eq = traitId;
    }
    return filters;
  }

  function submit() {
    props.onSubmit(computeRansackParams());
  }

  function handleForceList(event) {
    setForceList(event.target.value);
  }

  function handleJobType(event) {
    setJobTypeId(event.target.value);
  }

  function handleProjectName(event) {
    setProjectName(event.target.value);
  }

  function handleNotProjectName(event) {
    setNotProjectName(event.target.value);
  }

  function handleAccountName(event) {
    setAccountName(event.target.value);
  }

  function handleSendToTop(event) {
    setSendToTop(event.target.value);
  }

  function handleSubmarket(event) {
    setSubmarketId(event.target.value);
  }

  function handleTrait(event) {
    setTraitId(event.target.value);
  }

  return (
    <Form>
      <Form.Row>
        <Form.Group as={Col} xs="auto" className="my-1 mr-3">
          <Form.Label className="font-weight-bold mb-1">Job Type</Form.Label>
          <Form.Control size="sm" value={jobTypeId} as="select" onChange={handleJobType}>
            <option value=""></option>
            {!isLoadingJobTypes &&
              jobTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.displayName}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group as={Col} xs="auto" className="my-1 mr-3">
          <Form.Label className="font-weight-bold mb-1">Project</Form.Label>
          <Form.Control size="sm" value={projectName} onChange={handleProjectName}></Form.Control>
        </Form.Group>

        <Form.Group as={Col} xs="auto" className="my-1 mr-3">
          <Form.Label className="font-weight-bold mb-1">Not Project</Form.Label>
          <Form.Control
            size="sm"
            value={notProjectName}
            onChange={handleNotProjectName}
          ></Form.Control>
        </Form.Group>

        <Form.Group as={Col} xs="auto" className="my-1 mr-3">
          <Form.Label className="font-weight-bold mb-1">Force List</Form.Label>
          <Form.Control size="sm" value={forceList} as="select" onChange={handleForceList}>
            <option value=""></option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} xs="auto" className="my-1 mr-3">
          <Form.Label className="font-weight-bold mb-1">Trait</Form.Label>
          <Form.Control size="sm" value={traitId} as="select" onChange={handleTrait}>
            <option value=""></option>
            {!isLoadingTraits &&
              traits.map((trait) => (
                <option key={trait.id} value={trait.id}>
                  {trait.displayName}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group as={Col} xs="auto" className="my-1 mr-3">
          <Form.Label className="font-weight-bold mb-1">Sent to Top</Form.Label>
          <Form.Control size="sm" value={sendToTop} as="select" onChange={handleSendToTop}>
            <option value=""></option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Control>
        </Form.Group>

        <Form.Group as={Col} xs="auto" className="my-1 mr-3">
          <Form.Label className="font-weight-bold mb-1">Account</Form.Label>
          <Form.Control size="sm" value={accountName} onChange={handleAccountName}></Form.Control>
        </Form.Group>
        <Form.Group as={Col} xs="auto" className="my-1 mr-3">
          <Form.Label className="font-weight-bold mb-1">Submarket</Form.Label>
          <Form.Control size="sm" value={submarketId} as="select" onChange={handleSubmarket}>
            <option value=""></option>
            {props.submarkets &&
              props.submarkets.map((submarket) => (
                <option key={submarket.id} value={submarket.id}>
                  {submarket.name}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} xs={'auto'} className="my-3">
          <Button className="align-self-end" size="sm" onClick={submit}>
            Apply Filters
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

JobFilters.propTypes = {
  submarkets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func.isRequired,
};

export default JobFilters;
