import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { formatDate, calculateDowngradeDate } from './utils/AccountPlanUtils';
import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import ManagePlanModal from './ManagePlanModal';
import PlanOverviewNote from './PlanOverviewNote';
import ProAccountStatus from './ProAccountStatus';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { getAccountPlanOverviewQuery } from './data/queries';

function Overview(props) {
  const [showManagePlanModal, setShowManagePlanModal] = useState(false);
  const [accountPlan, setAccountPlan] = useState({});
  const [accountType, setAccountType] = useState(null);

  useEffect(() => {
    getAccountPlanOverview();
  }, []);

  function getAccountPlanOverview() {
    threeplayApi.request(getAccountPlanOverviewQuery).then((response) => {
      const account = response?.data?.account;
      if (!account) {
        return;
      }

      setAccountPlan(account.accountPlan);
      setAccountType(account.accountType);
    });
  }

  function openManagePlanModal() {
    setShowManagePlanModal(true);
  }

  useEffect(() => {
    if (!showManagePlanModal) {
      getAccountPlanOverview();
    }
  }, [showManagePlanModal]);

  const formattedTermStart = formatDate(accountPlan?.termStart);

  const formattedTermEnd = formatDate(accountPlan?.termEnd);

  function getAccountStatusColor() {
    return accountType === 'sunset' ? 'threeplay-yellow' : 'threeplay-green';
  }

  function getAccountStatus() {
    if (accountType === 'sunset') {
      return `Pending Transition to Express on ${formatDate(
        calculateDowngradeDate(props.account_type_history.updated_at)
      )}`;
    } else {
      return 'Active';
    }
  }

  function getAccountType() {
    if (accountType === 'sunset') {
      return 'enterprise';
    } else if (accountType === 'transcription_only') {
      return 'transcription only';
    } else {
      return accountType;
    }
  }

  function accountSummary(type) {
    switch (type) {
      case 'enterprise':
      case 'express':
        return 'Your account is currently active and up-to-date.';
      case 'sunset':
        return (
          <div>
            If you do not confirm your plan before{' '}
            {formatDate(calculateDowngradeDate(props.account_type_history.updated_at))}, we will
            transition your account to an Express account. You may upgrade to a Pro account at any
            time. See a feature breakdown of account types{' '}
            <a href="https://www.3playmedia.com/plans-pricing/">here</a>.
          </div>
        );
      case 'pro':
      case 'transcription_only':
      default:
        return '';
    }
  }

  return (
    <>
      <ErrorBoundary component="AccountPlansOverview">
        <Card>
          <Card.Header className="billing-card-header">Plan Overview</Card.Header>
          <Card.Body>
            {!['enterprise', 'pro', 'transcription_only'].includes(accountType) && (
              <PlanOverviewNote
                account_plan={accountPlan}
                account_type={accountType}
                account_type_history={props.account_type_history}
                openManagePlanModal={openManagePlanModal}
              />
            )}
            <>
              <Container fluid>
                <Row>
                  <Col sm={2}>
                    <Row className="h5 text-secondary">Subscription Status</Row>
                  </Col>
                  <Col className="mt-1">
                    <Row className="mb-2 h6 font-weight-thin">
                      {accountSummary(props.account.account_type)}
                    </Row>
                    <Row className="mt-4">
                      <Col sm={3}>
                        <Row className="h6 uppercase text-secondary">Account Type</Row>
                        <Row className="font-weight-bold capitalize status-font-size">
                          {getAccountType()}
                        </Row>
                      </Col>
                      <Col sm={9}>
                        <Row className="h6 uppercase text-secondary">Account Status</Row>
                        <Row
                          className={
                            'h4 font-weight-bold capitalize status-font-size ' +
                            getAccountStatusColor()
                          }
                        >
                          {getAccountStatus()}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </>
            {accountPlan && accountType === 'pro' && (
              <ProAccountStatus
                accountPlanId={accountPlan.id}
                autoRenew={accountPlan.autoRenew}
                defaultPrice={props.defaultPrice}
                paymentFrequency={accountPlan.paymentFrequency}
                renewalPaymentFrequency={accountPlan.renewalPaymentFrequency}
                refreshAccountData={getAccountPlanOverview}
                termEnd={formattedTermEnd}
                termStart={formattedTermStart}
                termInvoices={props.termInvoices}
              />
            )}
          </Card.Body>
        </Card>
        <ManagePlanModal
          authenticityToken={props.authenticityToken}
          account={props.account}
          clientToken={props.clientToken}
          closeManagePlanModal={() => setShowManagePlanModal(false)}
          defaultPrice={props.defaultPrice}
          introPrice={props.introPrice}
          termsDocument={props.termsDocument}
          show={showManagePlanModal}
          siteKey={props.siteKey}
        />
      </ErrorBoundary>
    </>
  );
}

Overview.propTypes = {
  account: PropTypes.object,
  account_type_history: PropTypes.object,
  authenticityToken: PropTypes.string,
  clientToken: PropTypes.string,
  defaultPrice: PropTypes.number,
  introPrice: PropTypes.number,
  siteKey: PropTypes.string,
  termInvoices: PropTypes.arrayOf(PropTypes.object),
  termsDocument: PropTypes.object,
};
export default Overview;
