import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { switchToProjectPath } from '~/helpers/app/paths';

import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

function SwitchToProject({
  adminMode,
  availableAccounts,
  bs_4,
  isCurrentlyImpersonating,
  isProduction,
  projectId,
  projectName,
}) {
  const [dropdownStatus, setDropdownStatus] = useState('');
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const navbarVersionIfInProduction =
    isCurrentlyImpersonating && adminMode ? styles.impersonationNavbar : styles.productionNavbar;

  const navbarVersion = isProduction ? navbarVersionIfInProduction : styles.nonProductionNavbar;

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredAccounts(availableAccounts);
      return;
    }

    const accounts = [];
    if (availableAccounts) {
      availableAccounts.forEach((account) => {
        const projects = [];
        account.projects.forEach((project) => {
          if (project.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
            projects.push(project);
          }
        });
        if (projects.length > 0) {
          accounts.push({
            id: account.id,
            name: account.name,
            projects: projects,
          });
        }
      });
    }
    setFilteredAccounts(accounts);
  }, [searchTerm]);

  const handleKeyDown = (event) => {
    if (event.key == 'esc' || event.key == 'Escape') {
      setDropdownStatus('');
    }
  };

  function toggleDropDown() {
    setDropdownStatus(dropdownStatus === '' ? 'open' : '');
  }

  function bs4_template() {
    return (
      <Dropdown as="span" className={css(styles.inline)}>
        <Dropdown.Toggle className={css(styles.projectName, navbarVersion)}>
          {projectName}
        </Dropdown.Toggle>

        {filteredAccounts && (
          <Dropdown.Menu className={`${css(styles.switchProjectDropdown)}`}>
            <Form.Control
              className={css(styles.searchBox)}
              onKeyUp={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by project name"
              size="sm"
              type="text"
            />
            {filteredAccounts.length === 0 && (
              <div className={css(styles.noResults)}>
                <small>No projects found</small>
              </div>
            )}
            {filteredAccounts.length > 0 && (
              <>
                <div className={`${css(styles.dropdownHeader)} text-dark`}>
                  Switch Project To...
                </div>
                {filteredAccounts.map((account) => {
                  return (
                    <React.Fragment key={account.id}>
                      {filteredAccounts.length > 1 && (
                        <Dropdown.Header className={css(styles.dropdownHeader)}>
                          {account.name}
                        </Dropdown.Header>
                      )}
                      {account.projects.map((project) => {
                        return (
                          <Dropdown.Item
                            active={projectId === Number(project.id)}
                            data-remote="true"
                            key={project.id}
                            href={switchToProjectPath(project.id)}
                          >
                            {project.name}
                          </Dropdown.Item>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
    );
  }

  function bs2_template() {
    return (
      <li className={`${dropdownStatus} dropdown boot_tab`}>
        <a
          className={[css(styles.projectName, navbarVersion), 'dropdown-toggle', 'main_link'].join(
            ' '
          )}
          onClick={() => toggleDropDown()}
        >
          {projectName}
          <b className="caret"></b>
        </a>
        {filteredAccounts && (
          <ul className={`${css(styles.switchProjectDropdown)} dropdown-menu`}>
            <input
              className={css(styles.searchBoxBS2)}
              onKeyUp={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by project name"
              size="sm"
              type="text"
            />
            {filteredAccounts.length === 0 && (
              <div className={css(styles.noResults)}>
                <small>No projects found</small>
              </div>
            )}
            {filteredAccounts.length > 0 && (
              <>
                <div className={`${css(styles.dropdownHeader)} text-dark`}>
                  Switch Project To...
                </div>
                {filteredAccounts.map((account) => {
                  return (
                    <React.Fragment key={account.id}>
                      {filteredAccounts.length > 1 && (
                        <li className="nav-header">{account.name}</li>
                      )}
                      {account.projects.map((project) => {
                        return (
                          <li
                            className={projectId === Number(project.id) ? 'active' : ''}
                            key={project.id}
                          >
                            <a
                              className={css(styles.bs2Font)}
                              data-remote="true"
                              href={switchToProjectPath(project.id)}
                              onClick={() => toggleDropDown()}
                            >
                              {project.name}
                            </a>
                          </li>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </ul>
        )}
      </li>
    );
  }

  return bs_4 ? bs4_template() : bs2_template();
}

const styles = StyleSheet.create({
  bs2Font: {
    fontSize: '14px',
  },
  dropdownHeader: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px',
    padding: '8px 15px',
    textTransform: 'uppercase',
  },
  inline: {
    display: 'inline',
  },
  noResults: {
    fontSize: '80%',
    fontWeight: '400',
    color: '#6c757d!',
    textAlign: 'center',
    margin: '1.5rem',
  },
  projectName: {
    color: '#fefefe',
    fontSize: '14px',
    ':hover': {
      background: '#2c2c2c',
      textDecoration: 'none',
    },
  },
  nonProductionNavbar: {
    background: '#273469',
    borderColor: '#273469',
  },
  productionNavbar: {
    background: '#000000',
    borderColor: '#000000',
  },
  impersonationNavbar: {
    background: '#900021',
    borderColor: '#900021',
  },
  searchBox: {
    marginLeft: '0.5rem',
    width: '95%',
  },
  searchBoxBS2: {
    marginLeft: '0.5rem',
    width: '90%',
  },
  switchProjectDropdown: {
    zIndex: '1000',
    minWidth: '300px',
    padding: '.5rem 0',
    fontSize: '1rem',
    color: '#212529',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0,0,0,.15)',
    borderRadius: '.25rem',
    maxHeight: '300px',
    overflowY: 'auto',
  },
});

SwitchToProject.propTypes = {
  adminMode: PropTypes.bool,
  availableAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      projects: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
    })
  ),
  bs_4: PropTypes.bool,
  isCurrentlyImpersonating: PropTypes.bool,
  isProduction: PropTypes.bool,
  projectId: PropTypes.number,
  projectName: PropTypes.string,
};

export default SwitchToProject;
