import * as d3 from 'd3';

function standardRadius(min = 0, max = 1) {
  return d3.scaleLinear([min, max], [4, 15]).clamp(true);
}

export const defaults = {
  color: d3.scaleSequential([0, 1], d3.interpolatePlasma),
  fillOpacity: 0.5,
  radius: standardRadius(),
  sort: d3.scaleLinear(),
  strokeOpacity: 0.8,
  strokeWidth: 3,
  x: d3.scaleLinear(),
  y: d3.scaleLinear(),
  xDomain: d3.extent,
  yDomain: d3.extent,
};

export const dictionary = {
  accessCount: {
    label: '# Accesses',
    radius: standardRadius(0, 500),
    xDomain: (data) => [0, d3.max(data)],
    yDomain: (data) => [0, d3.max(data)],
  },
  duration: {
    label: 'Duration',
    radius: d3.scaleSequential((dur) => 4 + (8 / Math.log(60)) * Math.log(1 + (dur || 0) / 60000)),
    x: d3.scaleLinear([0, 60000], [0, 1]),
    y: d3.scaleLinear([0, 60000], [0, 1]),
  },
  forceList: {
    label: 'Force List',
    color: d3.scaleOrdinal([true, false], ['#ff3322', '#dddd33']),
  },
  hoursToDeadline: {
    label: 'Hours to Deadline',
    radius: standardRadius(20, -20),
    xDomain: (data) => d3.extent(data).reverse(), // deadline moves left to right
    yDomain: (data) => d3.extent(data),
  },
  loggedInAccessCount: {
    label: '# Accesses (logged in)',
    radius: standardRadius(0, 100),
    xDomain: (data) => [0, Math.max(10, d3.max(data))],
    yDomain: (data) => [0, Math.max(10, d3.max(data))],
  },
  hoursOnMarket: {
    label: 'Hours on Market',
    radius: standardRadius(0, 40),
    xDomain: (data) => [0, d3.max(data)],
    yDomain: (data) => [0, d3.max(data)],
  },
  priorityStars: {
    label: 'Priority Stars',
    color: d3.scaleOrdinal([0, 1, 2, 3], ['#ddddcc', '#dddd33', '#ddaa22', '#ff3322']),
    radius: d3.scaleOrdinal([0, 1, 2, 3], [4, 7, 11, 15]),
  },
  topOfQueue: {
    label: 'Send to Top',
    color: d3.scaleOrdinal([true, false], ['#ff3322', '#dddd33']),
  },
};

export function getTransform(dataFeature, visualFeature) {
  return (
    (dictionary[dataFeature] && dictionary[dataFeature][visualFeature]) || defaults[visualFeature]
  );
}

export function getLabel(dataFeature) {
  return (dictionary[dataFeature] && dictionary[dataFeature].label) || dataFeature;
}
