import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import WithIcon from './WithIcon';

function CopyButton(props) {
  return (
    <Button onClick={() => props.copyText()} variant="primary">
      <WithIcon icon="fa fa-copy">Copy</WithIcon>
    </Button>
  );
}

function TextBoxWithCopyButton(props) {
  const [copied, setCopied] = useState(false);

  function copyText() {
    setCopied(true);
    navigator.clipboard.writeText(props.text);
    setTimeout(() => setCopied(false), 2000);
  }

  return (
    <>
      <InputGroup>
        <Form.Control type="text" value={props.text} readOnly />
        <InputGroup.Append>
          {!copied && <CopyButton copyText={copyText} />}
          {copied && (
            <OverlayTrigger
              placement="auto"
              delay={{ hide: 2000 }}
              trigger={['hover', 'focus']}
              overlay={<Tooltip>Copied to Clipboard!</Tooltip>}
            >
              <span>
                <CopyButton copyText={copyText} />
              </span>
            </OverlayTrigger>
          )}
        </InputGroup.Append>
      </InputGroup>
    </>
  );
}

CopyButton.propTypes = {
  copyText: PropTypes.func,
};

TextBoxWithCopyButton.propTypes = {
  text: PropTypes.string,
};

export default TextBoxWithCopyButton;
