import React from 'react';
import { StyleSheet, css } from 'aphrodite';

function AccessibilityQuote() {
  const accessibilityQuotations = [
    { quotation: 'Accessibility allows us to tap into everyone’s potential.', name: 'Debra Ruh' },
    {
      quotation:
        'Let’s stop ‘tolerating’ or ‘accepting’ difference, as if we’re so \
                  much better for not being different in the first place. Instead, let’s celebrate \
                  difference, because in this world it takes a lot of guts to be different.',
      name: 'Kate Bornstein',
    },
    {
      quotation:
        'The power of the Web is in its universality. Access by everyone regardless of \
                  disability is an essential aspect.',
      name: 'Tim Berners-Lee',
    },
    {
      quotation:
        'The one argument for accessibility that doesn’t get made nearly often enough is\
                  how extraordinarily better it makes some people’s lives. How many opportunities \
                  do we have to dramatically improve people’s lives just by doing our job a little better?',
      name: 'Steve Krug',
    },
    {
      quotation:
        'We need to make every single thing accessible to every single person with a disability.',
      name: 'Stevie Wonder',
    },
    {
      quotation:
        'The issue in Web accessibility is the fact that blind and visually-impaired people \
                  need the single biggest boost to achieve equivalence, since the real-world Web is a visual medium.',
      name: 'Joe Clark',
    },
    {
      quotation:
        'Web Accessibility is not only for people with disabilities but for all of us who are \
                  thankful that there are no barriers in using our abilities.',
      name: 'Remberto Esposa Jr.',
    },
    {
      quotation:
        'Accessibility is the degree to which anyone can access and use a website using any \
                  web browsing technology.',
      name: 'Royal National Institute of Blind People',
    },
    {
      quotation:
        'It’s not just about disabled users being able to access your website — it’s about\
                  everyone being able to access your website.',
      name: 'Trenton Moss',
    },
    {
      quotation: '80% of people who use captions aren’t deaf or hard of hearing.',
      name: '3Play Media',
    },
    {
      quotation: "20% of Americans are d/Deaf or hard of hearing. That's 48 million Americans!",
      name: '3Play Media',
    },
    {
      quotation:
        '62% of employees with a disability have an invisible disability or a disability that \
                  one cannot immediately identify upon meeting a person.',
      name: '3Play Media',
    },
    {
      quotation:
        "10% of all adult Americans have some degree of vision loss. That's 23.7 million \
                  American adults ages 18 and older!",
      name: '3Play Media',
    },
    {
      quotation:
        'People are not disabled, people are impaired. Experiences disable impaired people.',
      name: 'Jamie Knight',
    },
    {
      quotation:
        '1 in 4 will have a disability by the time they retire. You may think you don’t ‘need\
                  accessibility’ now, but that may change.',
      name: 'Cordelia McGee-Tubb',
    },
    {
      quotation:
        "Accessibility isn’t about limiting creative vision but removing unintentional \
                  'designed disabilities' so more people can play.",
      name: 'Adriane Kuzminski',
    },
    {
      quotation: 'The social model of disability: impairment + environment = disability.',
      name: 'Jamie Knight',
    },
    {
      quotation:
        '71% of web users with a disability will simply leave a website that is not accessible to them.',
      name: 'United States Section 508 Accessibility Standards',
    },
    {
      quotation:
        '52% of students found captions helpful as a learning aid by improving comprehension.',
      name: 'Oregon State University and 3Play Media',
    },
  ];
  const quote = accessibilityQuotations[Math.floor(Math.random() * accessibilityQuotations.length)];

  return (
    <div className="m-4">
      <p className={css(styles.thankYou)}>
        Every order you place helps makes the world a little more accessible.
      </p>
      <p className={css(styles.thankYouSecond)}>
        Thank you for using 3Play Media for all your web accessibility needs.
      </p>
      <div className={css(styles.quote)}>&quot;{quote.quotation}&quot;</div>
      <div className={css(styles.quoteName)}>-{quote.name}</div>
    </div>
  );
}

const styles = StyleSheet.create({
  quote: {
    textAlign: 'center',
    fontSize: '14px',
    opacity: 0.6,
    fontStyle: 'italic',
    fontWeight: 'bold',
    marginTop: '.3rem',
  },
  quoteName: {
    textAlign: 'center',
    fontSize: '14px',
    opacity: 0.6,
    marginTop: '.1rem',
  },
  thankYou: {
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '2px',
  },
  thankYouSecond: {
    fontSize: '16px',
    textAlign: 'center',
  },
});

export default AccessibilityQuote;
