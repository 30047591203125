import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import ThreeplayTable from '../common/ThreeplayTable';
import ReferredApplicantsTable from './ReferredApplicantsTable';

const ReferralsTable = ({ referrals = [] }) => {
  return referrals.length ? (
    <div className={css(styles.subcomponent)}>
      <ReferredApplicantsTable disableSortBy={true} detailed={false} data={referrals} />
    </div>
  ) : (
    <p>No applicants referred yet!</p>
  );
};

ReferralsTable.propTypes = {
  referrals: PropTypes.array,
};

const shapeReferralsData = (referrals) =>
  referrals &&
  referrals.map((referral) => {
    const { applicant, state, payout, hoursTarget } = referral;

    return {
      applicantId: applicant?.id,
      applicant: applicant?.name,
      applicantState: applicant?.state,
      referralState: state,
      payout,
      hoursTarget,
    };
  });

const shapeData = (data) =>
  data.map((referralCode) => ({
    id: referralCode.id,
    active: referralCode.active ? 'Active' : 'Not Active',
    contractor: referralCode?.contractor?.name,
    token: referralCode.token,
    referredCount: referralCode?.referrals.length ?? 0,
    referralLimit: referralCode.referralLimit,
    referrals: shapeReferralsData(referralCode?.referrals),
  }));

const ContractorReferralsTable = ({ referralCodes = [] }) => {
  const data = useMemo(() => shapeData(referralCodes), [referralCodes]);
  const columns = useMemo(
    () => [
      { Header: 'Status', accessor: 'active' },
      { Header: 'Contractor', accessor: 'contractor' },
      { Header: 'Referral Token', accessor: 'token' },
      { Header: '# Referred', accessor: 'referredCount' },
      { Header: 'Limit', accessor: 'referralLimit' },
    ],
    []
  );

  return <ThreeplayTable striped columns={columns} data={data} RowSubComponent={ReferralsTable} />;
};

ContractorReferralsTable.propTypes = {
  referralCodes: PropTypes.array,
};

const styles = StyleSheet.create({
  subcomponent: {
    padding: '20px',
  },
});

export default ContractorReferralsTable;
