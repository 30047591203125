import React, { MouseEventHandler, useState } from 'react';
import { useUpdateAudioAssetMutation } from '~/api/jobsApi';
import Form from 'react-bootstrap/Form';
import { Button, Alert } from '@threeplayground/index';

type MediaShuttleUploaderProps = {
  assetFileId: string;
  instructions: string | null;
};

export function MediaShuttleUploader({ assetFileId, instructions }: MediaShuttleUploaderProps) {
  const [mediaShuttleInstructions, setMediaShuttleInstructions] = useState('');
  const { mutate, isLoading, isError: errors, isSuccess: success } = useUpdateAudioAssetMutation();

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (confirm('Are you sure you want to upload the Media Shuttle instructions? ')) {
      mutate({
        where: { idEq: assetFileId },
        data: { mediaShuttleInstructions: mediaShuttleInstructions },
      });
    }
  };

  return (
    <div>
      <Form>
        <Form.Group controlId="mediaShuttle">
          <Form.Label>Instructions for Media Shuttle</Form.Label>
          <Form.Control
            onChange={(e) => setMediaShuttleInstructions(e.target.value)}
            as="textarea"
            rows={3}
            value={mediaShuttleInstructions || instructions || ''}
          />
        </Form.Group>
        {errors && <Alert variant="danger">Failed to update Media Shuttle instructions.</Alert>}
        {success && (
          <Alert variant="success"> Successfully updated Media Shuttle instructions.</Alert>
        )}
      </Form>
      <Button onClick={handleSubmit} variant="primary" disabled={isLoading}>
        Submit Instructions
      </Button>
    </div>
  );
}
