import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';

import { Alert, Button, Form } from 'react-bootstrap';

export interface TermsAcceptanceDisplayProps {
  busy?: boolean;
  termName: string;
  activeAt?: string;
  url: string;
  handleSubmit: () => void;
}

export const TermsAcceptanceDisplay = ({
  busy = false,
  termName,
  activeAt,
  url,
  handleSubmit,
}: TermsAcceptanceDisplayProps) => {
  const [isAccepted, setIsAccepted] = useState(false);

  return (
    <>
      <Alert variant="primary">
        <Alert.Heading className={css(styles.heading)}>{termName}</Alert.Heading>
        {activeAt && <i>Last updated {activeAt}</i>}
        <p>
          By checking the box below, you indicate that you agree to our{' '}
          <Alert.Link className={css(styles.link)} href={url}>
            {termName}
          </Alert.Link>
          {'.'}
        </p>
        <Form>
          <Form.Group className="mb-3" controlId="termsAcceptanceCheckBox">
            <Form.Check
              label={`I have read and accept the ${termName}`}
              onChange={() => {
                setIsAccepted((currentChecked) => !currentChecked);
              }}
              checked={isAccepted}
              disabled={busy}
            />
          </Form.Group>
        </Form>
      </Alert>
      <Button
        size="lg"
        className={css(isAccepted ? styles.enabledButton : styles.disabledButton)}
        disabled={!isAccepted || busy}
        type="submit"
        onClick={handleSubmit}
      >
        Accept
      </Button>
    </>
  );
};

const styles = StyleSheet.create({
  enabledButton: {
    width: '100%',
    fontWeight: 700,
    backgroundColor: 'var(--primary)',
  },
  disabledButton: {
    width: '100%',
    fontWeight: 700,
    backgroundColor: 'var(--secondary)',
    border: '1px solid var(--secondary)',
    cursor: 'not-allowed',
  },
  heading: {
    fontWeight: 700,
  },
  link: {
    textDecoration: 'underline',
  },
  legal: {
    marginTop: '1rem',
  },
});
