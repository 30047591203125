import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useState,
  FocusEvent,
  ChangeEvent,
} from 'react';
import { FormControl } from 'react-bootstrap';

import { InputWrapper, InputWrapperProps } from '../_implementation/InputWrapper';

export const supportedInputTypes = [
  'datetime-local',
  'email',
  'hidden',
  'number',
  'password',
  'tel',
  'text',
  'url',
  'search',
] as const;

type SupportedInputType = typeof supportedInputTypes[number];

// NOTE: if you update this, make sure you also explicitly pass all props from
// ForwardedNativeInputProps to the <FormControl /> below.
// This needs to be destructured from props explicitly as all remaining props
// are forwarded to the InputWrapper.
export type ForwardedNativeInputProps =
  | 'autocomplete'
  | 'disabled'
  | 'max'
  | 'maxLength'
  | 'min'
  | 'minLength'
  | 'placeholder'
  | 'readOnly'
  | 'required'
  | 'step';

interface PatternErrors {
  [key: string]: string | undefined;
}

const patternValidationErrors: PatternErrors = {
  tel: 'Please enter a valid phone number',
};

function computeValidationError(target: HTMLInputElement, type: SupportedInputType): string | null {
  // We rely on the native validity API browsers provide.
  // This is feature-filled, well-supported, and localized by default.
  // https://developer.mozilla.org/en-US/docs/Web/API/ValidityState

  const { validity } = target;

  const shouldUseLocalizedValidationMessage =
    navigator.languages && !navigator.languages[0]?.startsWith('en');

  // If we are on a modern-browser with `languages` support but NOT in english,
  // we'll use the browser default. In rare cases, code outside this component
  // could have set a custom error too.
  if (shouldUseLocalizedValidationMessage || validity.customError) {
    return target.validationMessage;
  }

  // When we are in an english locale, we can normalize error text based on
  // ValidationState to make the messaging and priority of certain errors
  // consistent for the majority of our customers.
  // For now, we just go native, but should adjust before merging.

  // Patterns mismatch is somewhat awkward in language for our phone pattern
  if (validity.patternMismatch) {
    return (patternValidationErrors[type] as string | undefined) || target.validationMessage;
  }

  return target.validationMessage || null;
}

/**
 * This returns a "built-in" regex for the given type. This is business logic
 * we effectively default to for each type and expect to be overridden rarely.
 *
 * Regex patterns are not necessary for all types. For example, `email` is
 * generally validated by browsers already and does not need us to define a
 * huge, insane regex. Other types need more restrictions from default behavior,
 * including `tel` and `number`.
 *
 * Generally speaking, these regex should be quite generous in their acceptance
 * of different formats (when unambiguous) to avoid frustrating a user.
 */
function getPatternForType(type: SupportedInputType) {
  switch (type) {
    // no need for email or number, browsers handle this well already.
    case 'tel':
      // https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
      // Has the following capture groups:
      // Group1: Country Code (ex: 1 or 86)
      // Group2: Area Code (ex: 800)
      // Group3: Exchange (ex: 555)
      // Group4: Subscriber Number (ex: 1234)
      // Group5: Extension (ex: 5678)
      return '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$';

    default:
      return undefined;
  }
}

function getInputModeForType(type: SupportedInputType) {
  switch (type) {
    // A NOTE ON type="number"
    // We don't set inputmode="numeric" or inputmode="decimal" automatically,
    // since mobile keyboards are inconsistent and do not generally support
    // negative numbers with `inputmode=decimal`. Both iOS and Android keyboards
    // are equally spotty. Therefore, relying on a full keyboard (which mobile
    // browsers do generally switch to the number keyboard automatically)
    // is the best option. This may change in the future with future iOS/Android
    // versions.
    //
    // Our interface should automatically do the right thing. As an enhancement,
    // developers at 3play can specify a pattern of `[0-9]*` or `\d*` on any
    // input type to get a large number entry (e.g. for non-negative integers
    // or credit cards) when appropriate.
    //
    // You can test the behavior here on an mobile device.
    // https://output.jsbin.com/necuzoj
    case 'tel':
      return 'tel';
    case 'email':
      return 'email';
    case 'url':
      return 'url';
    default:
      return undefined;
  }
}

function toSafeValue(value: number | string | null | undefined): string | number | undefined {
  if (typeof value === 'number') {
    return isNaN(value) ? '' : value;
  }

  return value ?? undefined;
}

export type InputValue = string | number;

/**
 * `InputProps` are a combination of `InputWrapper` props
 * (which control rendering and error handling) and allowed props on the
 * HTMLInputProps from the DOM library.
 *
 * We use a generic to constrain the value automatically based on type. Set
 * with `type=number` and pass in numbers as values (you should get type errors
 * if both don't line up properly).
 *
 * `alt-click` on `HTMLTextAreaElement` and `InputWrapperProps` to see a
 * concrete list of types.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea
 */
export interface InputProps<Type extends SupportedInputType>
  extends InputWrapperProps<HTMLInputElement>,
    Partial<Pick<HTMLInputElement, ForwardedNativeInputProps>> {
  type?: Type;
  value?: Type extends 'number' ? number | null : string | null;
  defaultValue?: Type extends 'number' ? number | null : string | null;
  pattern?: string;
  inputMode?: 'email' | 'tel' | 'text' | 'url' | 'search' | 'numeric' | 'decimal';

  /**
   * This prop is really useful when you need to control a `value` that takes
   * priority but you would like the field to remain editable. This adds typing
   * state for you so all you have to do is add an onBlur handler.
   *
   * It's also generally more efficient to let the input own the state
   * in a lot of cases, as the update tree is much smaller compared to
   * rerendering the parent component.
   */
  withTypingState?: boolean;

  /**
   * This callback will give you a _parsed_ value if changed after a user
   * completes typing and focuses out of the input. You can think of it similar
   * to an `onBlur` with a typed value; but it only calls the callback if the
   * value changed.
   *
   * Very useful to use along `withTypingState` for a low-code input.
   */
  onUpdate?: (newValue: Type extends 'number' ? number | null : string | null) => void;
}

function InputInner<Type extends SupportedInputType = 'text'>(
  props: InputProps<Type>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const {
    // Props from InputProps
    type = 'text',
    value,
    defaultValue,
    pattern,
    inputMode,
    withTypingState,
    onUpdate,

    // Props from ForwardedNativeInputProps
    autocomplete,
    disabled,
    max,
    maxLength,
    min,
    minLength,
    placeholder,
    readOnly,
    required,
    step,

    // Props from Input Wrapper
    onBlur,
    onChange,
    ...inputWrapperProps
  } = props;

  const [typingState, setTypingState] = useState<string | null>(null);

  const handleValidate = useCallback(
    (element: HTMLInputElement) => {
      return computeValidationError(element, type);
    },
    [type]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (withTypingState) {
        setTypingState(event.target.value);
      }
      onChange && onChange(event);
    },
    [withTypingState, setTypingState, onChange]
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const newValue =
        type === 'number' ? event.currentTarget.valueAsNumber : event.currentTarget.value;
      const safeValue = typeof newValue === 'number' && isNaN(newValue) ? null : newValue;

      setTypingState(null);

      // We have to cast in the case of a conditional type sometimes.
      // https://github.com/Microsoft/TypeScript/issues/13995#issuecomment-363265172
      value !== safeValue &&
        onUpdate &&
        onUpdate(safeValue as Type extends 'number' ? number | null : string | null);

      onBlur && onBlur(event);
    },
    [setTypingState, onBlur, onUpdate, value, type]
  );

  return (
    <InputWrapper<HTMLInputElement>
      {...inputWrapperProps}
      onBlur={handleBlur}
      onChange={handleChange}
      onValidate={handleValidate}
    >
      {(wrapperProps) => (
        <FormControl
          autoComplete={autocomplete}
          disabled={disabled}
          max={max}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          step={step}
          {...wrapperProps}
          type={type}
          ref={ref}
          value={typingState ?? toSafeValue(value)}
          defaultValue={toSafeValue(defaultValue)}
          pattern={pattern || getPatternForType(type)}
          inputMode={inputMode || getInputModeForType(type)}
        />
      )}
    </InputWrapper>
  );
}

InputInner.displayName = 'Input';

/**
 * `Input` is a nicely typed overlay on top of native/Bootstrap `<input>`.
 * It supports a subset of the native type (for all text-like and number types).
 *
 * ---
 *
 * #### Native form behavior
 *
 * `Input` aligns closely with `Form`, `Select`, and `Textarea` by sharing
 * validation, label, and rendering functionality via `InputWrapper`
 * and `ErrorsContext`.
 *
 * If you are using this within a `Form` component, you likely don't need to set
 * much more than `label`, `name`, and whatever presentational props you want.
 * Validation and parsing are automatically handled for you. Prefer using native
 * input props to adjust behavior including `readOnly`, `required`, `min`,
 * `minLength`, `max`, `maxLength`, `size`, etc.
 *
 * ---
 *
 * #### Direct state wiring
 *
 * The typing of `value` set to `string` or `number` based on the input type.
 * If you encounter errors with `type: "number"` or `value: number` not
 * aligning, this is likely the component trying to help you from incorrectly
 * getting the wrong value out! Make sure `value`, `defaultValue`, and `type`
 * all match on type.
 *
 * ---
 *
 * @example
 *
 * ```tsx
 * <Input
 *   label="Example"
 *   type="text" // or password, tel, etc
 *   name="example"
 *   onValueChange={(value) => console.log(value)} // typed as string
 * />
 *
 * <Input
 *   label="Example"
 *   type="number"
 *   name="example"
 *   onValueChange={(value) => console.log(value)} // typed as number
 * />
 * ```
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
 * @see https://react-bootstrap-v4.netlify.app/components/forms/
 */
export const Input = forwardRef(InputInner);
