import { Formatter } from '../_types';

interface CapitalizeNamesFormatterConfig {
  /** A lookup table from the server with casing information for names */
  casedNamesMap: Record<string, string>;
}

type CasedNamesMap = Map<string, string>;

export class CapitalizeNamesFormatter implements Formatter {
  casedNamesMap: CasedNamesMap;

  /**
   * A formatter that uses the fetched cased_names_list to capitalize names
   * in the text.
   *
   * @example new CapitalizeNamesFormatter({ casedNamesMap: { "3play": "3Play" } }).format("3play presents")
   * // returns "3Play presents"
   */
  constructor({ casedNamesMap }: CapitalizeNamesFormatterConfig) {
    this.casedNamesMap = this.transformCasedNamesMap(casedNamesMap);
  }

  format(text: string) {
    return text.replace(/\b(\w+)\b/g, (match, p1: string) => this.casedNamesMap.get(p1) || p1);
  }

  /**
   * Transforms the cased names map from an object to a Map. This is important as an object could return
   * undesirable values for some valid keys. e.g. for the key "constructor", the lookup on an object would
   * return "ƒ Object() { [native code] }".
   *
   * @param casedNamesObject - The cased names object to transform
   * @returns A map of the cased names
   */
  private transformCasedNamesMap(
    casedNamesObject: CapitalizeNamesFormatterConfig['casedNamesMap'] = {}
  ) {
    return new Map(Object.entries(casedNamesObject));
  }
}
