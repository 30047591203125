import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

function TokenPaginator(props) {
  return (
    <ButtonGroup className="mr-2" aria-label="Pagination Button group">
      {props.integration.paginationDetails.showFirstPageButton && (
        <Button
          aria-label="First Page"
          className="m-1"
          onClick={() => props.setPageToken('')}
          variant="outline-primary"
        >
          <i className="fa fa-angle-double-left"></i>
        </Button>
      )}
      <Button
        aria-label="Previous Page"
        className="m-1"
        disabled={props.integration.paginationDetails.prevPage === null}
        onClick={() => props.setPageToken(props.integration.paginationDetails.prevPage)}
        variant="outline-primary"
      >
        <i className="fa fa-angle-left"></i>
      </Button>
      <Button
        aria-label="Next Page"
        className="m-1"
        disabled={props.integration.paginationDetails.nextPage === null}
        onClick={() => props.setPageToken(props.integration.paginationDetails.nextPage)}
        variant="outline-primary"
      >
        <i className="fa fa-angle-right"></i>
      </Button>
    </ButtonGroup>
  );
}

TokenPaginator.propTypes = {
  integration: PropTypes.object,
  setPageToken: PropTypes.func,
};

export default TokenPaginator;
