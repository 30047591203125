import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['click'];

  click(event) {
    const selectedValue = event.target.value;
    this.clickTargets.forEach((target) => {
      target.value = selectedValue;
      target.click();
    });
  }
}
