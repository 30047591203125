import { useReducer, useEffect } from 'react';

const graphQLQueryReducer = (state, action) => {
  switch (action.type) {
    case 'fetch':
      return {
        data: undefined,
        errors: [],
        loading: true,
      };
    case 'success':
      return {
        data: action.data,
        errors: [],
        loading: false,
      };
    case 'error':
      return {
        data: undefined,
        errors: action.errors,
        loading: false,
      };
    default:
      throw new Error('Unknown Action');
  }
};

const useGraphQLQuery = (fetcherFunction, dependencies = []) => {
  const [state, dispatcher] = useReducer(graphQLQueryReducer, {
    data: undefined,
    errors: [],
    loading: true,
  });

  useEffect(() => {
    dispatcher({ type: 'fetch' });
    fetcherFunction().then((response) => {
      if (response.errors) {
        dispatcher({ type: 'error', errors: response.errors });
      } else {
        dispatcher({ type: 'success', data: response.data });
      }
    });
  }, dependencies);

  return state;
};

export default useGraphQLQuery;
