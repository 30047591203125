import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { StyleSheet, css } from 'aphrodite';

import WithIcon from '~/components/app/common/WithIcon';

function EditButton(props) {
  return (
    <Button className={css(styles.editButton)} size="sm" onClick={props.onClick}>
      <WithIcon icon="fa fa-pencil">Edit</WithIcon>
    </Button>
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func,
};

const styles = StyleSheet.create({
  editButton: {
    marginRight: '14px',
    marginTop: '-4px',
  },
});

export default EditButton;
