import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import { threeplayApi } from '~/logic/ThreeplayApi';

const orderTemplateQuery = `
  query OrderTemplate($id: ID!) {
    orderTemplate(id: $id) {
      id
      name
      batch {
        id
        name
      }
      series {
        id
        name
      }
      services {
        serviceType
        displayName
        price {
          amount
          unit
        }
        primary
        orderOptions
      }
      instructions {
        service
        text
      }
    }
  }
`;

const TemplateSelection = ({
  selectedTemplate,
  setLoadedTemplate,
  setSelectedTemplate,
  templates,
}) => {
  const [hasLoadErrors, setHasLoadErrors] = useState(false);

  const loadTemplate = () => {
    if (selectedTemplate === '0') {
      setHasLoadErrors(false);
      setLoadedTemplate(null);
      return;
    }

    threeplayApi.request(orderTemplateQuery, { id: selectedTemplate }).then((res) => {
      const data = res.data || {};
      if (data.orderTemplate) {
        setHasLoadErrors(false);
        setLoadedTemplate(data.orderTemplate);
      } else {
        setHasLoadErrors(true);
        setLoadedTemplate(null);
      }
    });
  };

  return (
    <div className={css(styles.templateContainer)}>
      <Card.Title className={css(styles.heading)}>Use an Order Template</Card.Title>
      {selectedTemplate.length > 0 && hasLoadErrors && (
        <Alert variant="danger">Unable to load the template</Alert>
      )}
      <Form.Group>
        <Form.Row>
          <Form.Control
            as="select"
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
          >
            <option value="0"></option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </Form.Control>
          <Button variant="primary" onClick={loadTemplate} disabled={selectedTemplate === 0}>
            Load
          </Button>
        </Form.Row>
      </Form.Group>
    </div>
  );
};

TemplateSelection.propTypes = {
  selectedTemplate: PropTypes.string,
  setLoadedTemplate: PropTypes.func,
  setSelectedTemplate: PropTypes.func,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

const styles = StyleSheet.create({
  templateContainer: {
    'margin-bottom': '1em',
  },
  heading: {
    'font-weight': 'bold',
  },
});

export default TemplateSelection;
