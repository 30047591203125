import React from 'react';
import PropTypes from 'prop-types';

import { jobPath } from '../../paths';

export const CheckIcon = ({ cell: { value } }) => (value ? <i className="fa fa-check"></i> : null);

CheckIcon.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.bool }),
};

export const JobLink = ({ cell: { value } }) => {
  return (
    <>
      {value}{' '}
      <a href={jobPath(value)} target="_blank" rel="noopener noreferrer">
        <i className="fa fa-external-link" />
      </a>
    </>
  );
};

JobLink.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.number }),
};

export const RemoveJob = ({ cell: { value }, column }) => {
  return (
    <i
      className="fa fa-times"
      onClick={() => {
        column.getProps().removeJob(value);
      }}
    />
  );
};

RemoveJob.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.number }),
  column: PropTypes.object,
};

export const PriorityStars = ({ cell: { value } }) => {
  return new Array(value).fill().map((_, ii) => <i key={ii} className="fa fa-star" />);
};

PriorityStars.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.number }),
};
