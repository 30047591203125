interface GraphQLError {
  message: string;
  extensions?: {
    path?: string;
    [key: string]: unknown;
  };
}

export interface Payload<T> {
  data?: T;
  errors?: GraphQLError[];
}

export interface ExtractedGraphQLError {
  code?: string;
  message: string;
  path: string;
  [key: string]: unknown;
}

class UnknownShapeError extends Error {
  json: unknown;
}

function isPayload<T>(payload: unknown): payload is Payload<T> {
  return (
    Boolean(payload) &&
    payload !== null &&
    typeof payload === 'object' &&
    ('data' in payload || 'errors' in payload)
  );
}

/**
 * WARNING: Consider this function UNSTABLE and as such the API may change, use at your own risk
 */
export class ThreeplayApiV2 {
  endpoint: string;

  constructor(endpoint = '/data') {
    // TODO: should we dynamically grab this from Rails?
    this.endpoint = endpoint;
  }

  /**
   * Make a GraphQL call (query or mutation)
   *
   * @async
   * @function request
   * @param queryOrMutation - The query or mutation
   * @param variables - The variables to get passed to the mutation
   * @param extractKey - The name of the mutation so that we can return the result object rather than an object that you have to call `result.data.createPineapple`
   * @returns {Promise<data|errors>} The data from the URL.
   */
  request<T>(queryOrMutation: string, variables = {}, extractKey?: keyof T) {
    /*
        Example call:
        -----------
        mutation
        -----------
        mutation createPineapple($data: PineappleCreateInput!) {
          createPineapple(data: $data) {
            record {
              id
              createdAt
              updatedAt
            }
          }
        }
        -----------
        variables
        -----------
        {
          data: {
            sku: '3P000001',
            name: 'My awesome product'
          }
        }
  
        -----------
        extractKey
        -----------
        createPineapple
      */

    const csrfToken = this.getCsrfToken();

    return fetch(this.endpoint, {
      body: JSON.stringify({
        query: queryOrMutation,
        variables: JSON.stringify(variables),
      }),
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        ...(csrfToken ? { 'X-CSRF-Token': csrfToken } : {}),
      },
    })
      .then(async (response) => {
        // We need this first .then as fetch's `response.json()` is async and needs to be unpacked
        // console.log('response :>> ', response);
        const json: unknown = await response.json();

        if (!isPayload<T>(json)) {
          const error = new UnknownShapeError('Unknown response shape returned');
          error.json = json;
          throw error;
        }

        return json;
      })
      .then((payload) => {
        // console.log('payload :>> ', payload);
        const globalErrors = this.extractErrors<T>(payload);
        if (globalErrors) {
          return {
            globalErrors,
          };
        }

        if (!payload.data) {
          const error = new UnknownShapeError('Response shape is missing data');
          error.json = payload;
          throw error;
        }

        // Shortcut so that consumer doesn't need to look at `response.data.mutationName`, for example
        if (extractKey) {
          return payload.data[extractKey];
        }

        return payload.data;
      })
      .catch((error: Error) => {
        if (error.message === 'Failed to fetch') {
          return {
            globalErrors: [
              {
                code: 'NO_INTERNET',
                path: 'global',
                message: 'Failed to connect: is your internet down?',
              } as ExtractedGraphQLError,
            ],
          };
        }

        // Generic error if we don't know what went wrong
        return {
          globalErrors: [
            {
              code: 'GENERAL_ERROR',
              path: 'global',
              message: 'Unknown Error.  Please contact support team.',
            } as ExtractedGraphQLError,
          ],
        };
      });
  }

  extractErrors<T>(payload: Payload<T>) {
    if (payload.errors && payload.errors.length) {
      // This is our standard GraphQL error shape
      return payload.errors.map((error) => {
        const { message, extensions } = error;

        const errObject: ExtractedGraphQLError = {
          ...extensions, // Flatten: drop in all extensions attributes from standard backend shape, which is {message: 'error message', extensions { [a bunch of extra fields] }}
          message,
          path: extensions?.path || 'global',
        };

        return errObject;
      });
    }

    // For any new error shapes we could receive from the application, put in another block of
    // adapter code that will convert from the API shape to the shape expected by the consumer
  }

  getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? undefined;
  }
}
