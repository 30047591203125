import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import Card from 'react-bootstrap/Card';

import { checkKeyPress } from '~/helpers/checkKeyPress';
import selectedStyles from '../styles/selectedDeselectedStyles';

function ServiceLevelCard(props) {
  return (
    <Card
      bg="light"
      data-testid={props.name}
      tabIndex={props.index}
      style={{ width: '15rem' }}
      onKeyDown={(e) =>
        checkKeyPress(e, () => props.setServiceLevel(props.name), { triggerOnSpace: true })
      }
      onClick={() => props.setServiceLevel(props.name)}
      className={
        props.orderOptions.serviceLevel && props.orderOptions.serviceLevel.name === props.name
          ? css(selectedStyles.selectedWithoutMargin, styles.box)
          : css(selectedStyles.deselectedWithoutMargin, styles.box)
      }
    >
      <Card.Body>
        <div>
          <b className={css(styles.left)}>{props.name}</b>
          <b className={css(styles.right)}>{props.displayPrice}</b>
        </div>
        <div>{props.deliveryDate}</div>
        <div className={css(styles.inline)}>{props.description}</div>
      </Card.Body>
    </Card>
  );
}

ServiceLevelCard.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.string,
  }),
  description: PropTypes.string,
  deliveryDate: PropTypes.string,
  setServiceLevel: PropTypes.func,
  orderOptions: PropTypes.object,
  displayPrice: PropTypes.string,
};

const styles = StyleSheet.create({
  box: {
    'margin-right': '15px',
    cursor: 'pointer',
  },
  right: {
    float: 'right',
    'font-size': '.9rem',
    'font-weight': 'bold',
    color: '#4c4c4c',
  },
  left: {
    float: 'left',
  },
  inline: {
    display: 'inline-block',
    'font-size': '.9rem',
    'margin-top': '.2rem',
  },
});

export default ServiceLevelCard;
