export const CHALLENGE_STATES = {
  staged: 'staged',
  active: 'active',
  closed: 'closed',
};

export const MEMBERSHIP_STATES = {
  eligible: 'eligible',
  accepted: 'accepted',
  closed: 'closed',
};

export const DEFAULT_COPY = {
  noChallengeDescription: 'No description available.',
};
