import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useCookies } from 'react-cookie';

import { getOrganizationPath, checkSsoPath } from '~/helpers/paths';

function SingleSignOn(props) {
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [cookies, setCookie] = useCookies(['SsoOrganization']);

  async function handleSubmit(event) {
    event.preventDefault();
    setFetching(true);
    const form = event.target;
    const data = formData(form);
    setCookie('SsoOrganization', form.organization.value, { path: '/' });

    const response = await fetch(checkSsoPath, {
      method: 'POST',
      body: data,
      credentials: 'same-origin',
    });

    const responseData = await response.json();

    if (!responseData.ok) {
      setError({
        title: 'Incorrect Organization URL',
        message: 'The organization URL does not exist or does not have Single Sign-On enabled.',
      });
      setFetching(false);
      return { ok: false };
    } else {
      setFetching(false);
      window.location.href = responseData.url;
    }
  }

  function formData(form) {
    const data = new FormData(form);
    data.append('authenticity_token', props.csrfToken);
    return data;
  }

  return (
    <div className={css(styles.main)}>
      {error && (
        <Alert variant="danger" dismissible onClose={() => setError(null)}>
          {error.title && (
            <Alert.Heading>
              <FontAwesomeIcon icon="exclamation-triangle" /> {error.title}
            </Alert.Heading>
          )}
          {error.message}
        </Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Organization URL</Form.Label>
          <Form.Control
            name="organization"
            required
            type="text"
            disabled={fetching}
            placeholder="www.companyname.com"
            defaultValue={cookies.SsoOrganization}
          />
          <a href={getOrganizationPath} className={css(styles.small)}>
            Forgot your organization?
          </a>
        </Form.Group>
        <div className={css(styles.footer)}>
          <Button type="submit" block disabled={fetching}>
            {fetching ? 'Please wait' : 'Log In'}
          </Button>
        </div>
      </Form>
    </div>
  );
}

const styles = StyleSheet.create({
  main: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  small: {
    fontSize: '0.8rem',
  },
});

SingleSignOn.propTypes = {
  csrfToken: PropTypes.string,
};

export default SingleSignOn;
