import React from 'react';
import PropTypes from 'prop-types';
import AwardLevelsTable from '../AwardLevelsTable';

const EngagementProgramChallenge = ({ challenge }) => {
  const membership = challenge.membership;
  const highestAwardLevelReached = membership.highestAwardLevelReached?.name;
  const eligibleAwardAmount = membership.eligibleAwardAmount || 0;

  return (
    <div>
      <h3>{challenge.name}</h3>

      <p>
        {membership.progressDisplayText && `You've completed ${membership.progressDisplayText}.`}
      </p>
      {highestAwardLevelReached ? (
        <p>
          {`You're currently at ${highestAwardLevelReached}! You've earned a cumulative $${eligibleAwardAmount} in
          this challenge.`}
        </p>
      ) : null}

      <AwardLevelsTable awardLevels={challenge.awardLevels} />
    </div>
  );
};

EngagementProgramChallenge.propTypes = {
  challenge: PropTypes.object.isRequired,
};

export default EngagementProgramChallenge;
