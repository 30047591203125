import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { submitVoiceWritingApplication } from './services/services';
import { hasFormErrors, isStringEmpty } from '../helpers';

const PLEASE_SELECT_OPTION_ERROR_COPY = 'Please select an answer.';

const TRAINING_START_OPTIONS = [
  { id: 'immediately', label: 'Immediately' },
  { id: 'one_to_two', label: 'In 1-2 weeks' },
  { id: 'two_to_three', label: 'In 2-3 weeks' },
  { id: 'three_to_four', label: 'In 3-4 weeks' },
  { id: 'more_than_four', label: 'Longer than 4 weeks' },
];

const PREVIOUS_EXPERIENCE_OPTIONS = [
  { id: 'expert', label: 'Expert' },
  { id: 'some_experience', label: 'Some experience' },
  { id: 'none', label: 'Never tried it before' },
  { id: 'dont_know', label: "I don't know" },
];

const NON_COMPETE_OPTIONS = [
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No' },
  { id: 'unsure', label: "I'm not sure" },
];

const HEAD_SET_OPTIONS = [
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No' },
  { id: 'not_yet', label: 'No, but willing to get one' },
  { id: 'unsure', label: "I'm not sure" },
];

const REFERRAL_SOURCE_OPTIONS = [
  { id: 'existing_contractor', label: "I'm already a contractor with 3Play" },
  { id: 'linkedin_recruitment', label: 'Linkedin recruitment' },
  { id: 'linkedin_job_posting', label: 'Linkedin job posting' },
  { id: 'craigslist', label: 'Craigslist' },
  { id: 'social_media_ad', label: 'Social media ad' },
  { id: 'threeplay_referral', label: 'I was referred by someone at 3Play' },
  { id: 'article_video', label: 'Article/Video mentioning 3Play' },
  { id: 'other', label: 'Other' },
];

export const validate = ({
  trainingStart,
  previousExperience,
  nonCompete,
  headset,
  referralSource,
}) => {
  const formErrors = {};

  if (isStringEmpty(trainingStart)) {
    formErrors.trainingStart = PLEASE_SELECT_OPTION_ERROR_COPY;
  }

  if (isStringEmpty(previousExperience)) {
    formErrors.previousExperience = PLEASE_SELECT_OPTION_ERROR_COPY;
  }

  if (isStringEmpty(nonCompete)) {
    formErrors.nonCompete = PLEASE_SELECT_OPTION_ERROR_COPY;
  }

  if (isStringEmpty(headset)) {
    formErrors.headset = PLEASE_SELECT_OPTION_ERROR_COPY;
  }

  if (isStringEmpty(referralSource)) {
    formErrors.referralSource = PLEASE_SELECT_OPTION_ERROR_COPY;
  }

  return formErrors;
};

const VoiceWritingExperience = ({
  applicant = {},
  onSubmit = submitVoiceWritingApplication,
  nextStep = () => {},
  authToken,
}) => {
  const [trainingStart, setTrainingStart] = useState('');
  const [previousExperience, setPreviousExperience] = useState('');
  const [nonCompete, setNonCompete] = useState('');
  const [headset, setHeadset] = useState('');
  const [referralSource, setReferralSource] = useState('');
  const [additionalComments, setAdditionalComments] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formatData = () => {
    return {
      referralSource,
      experience: JSON.stringify({
        trainingStart,
        previousExperience,
        nonCompete,
        headset,
        additionalComments,
      }),
    };
  };

  const handleSubmit = () => {
    const formErrors = validate({
      trainingStart,
      previousExperience,
      nonCompete,
      headset,
      referralSource,
      additionalComments,
    });

    setFormErrors(formErrors);

    if (hasFormErrors(formErrors)) {
      return;
    }

    setLoading(true);
    const data = formatData();

    onSubmit({ ...data, authToken })
      .then(({ errors, data }) => {
        if (errors) {
          throw Error();
        }

        return data;
      })
      .then(({ submitVoiceWritingApplication: { success, error } }) => {
        setLoading(false);
        if (!success || error) {
          throw Error();
        }

        nextStep(applicant?.email);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <div className={`slide-up ${css(styles.div)}`}>
      {error && <p> error</p>}
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="training_start">
            <Form.Label>
              How soon would you be available to begin training on 3Play&apos;s voice writing
              interface?*
            </Form.Label>
            <Form.Control
              as="select"
              value={trainingStart}
              onChange={(e) => setTrainingStart(e.target.value)}
            >
              <option key="training-start-none" value="" disabled>
                Please Select
              </option>
              {TRAINING_START_OPTIONS.map(({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              ))}
            </Form.Control>
            {formErrors.trainingStart && (
              <Form.Text className="text-danger">{formErrors.trainingStart}</Form.Text>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="previousExperience">
            <Form.Label>
              How would you describe your level of experience with live voice writing?*
            </Form.Label>
            <Form.Control
              as="select"
              value={previousExperience}
              onChange={(e) => setPreviousExperience(e.target.value)}
            >
              <option key="previous-experience-none" value="" disabled>
                Please Select
              </option>
              {PREVIOUS_EXPERIENCE_OPTIONS.map(({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              ))}
            </Form.Control>
            {formErrors.previousExperience && (
              <Form.Text className="text-danger">{formErrors.previousExperience}</Form.Text>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="nonCompete">
            <Form.Label>
              Are you currently obligated to a non-compete with another live captioning company?*
            </Form.Label>
            <Form.Control
              as="select"
              value={nonCompete}
              onChange={(e) => setNonCompete(e.target.value)}
            >
              <option key="non-compete-none" value="" disabled>
                Please Select
              </option>
              {NON_COMPETE_OPTIONS.map(({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              ))}
            </Form.Control>
            {formErrors.nonCompete && (
              <Form.Text className="text-danger">{formErrors.nonCompete}</Form.Text>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="headset">
            <Form.Label>Do you have a headset with a built-in microphone?*</Form.Label>
            <Form.Control
              as="select"
              value={headset}
              onChange={(e) => setHeadset(e.target.value)}
              aria-label="headset"
            >
              <option key="headset-none" value="" disabled>
                Please Select
              </option>
              {HEAD_SET_OPTIONS.map(({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              ))}
            </Form.Control>
            {formErrors.headset && (
              <Form.Text className="text-danger">{formErrors.headset}</Form.Text>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="referralSource">
            <Form.Label>How did you learn about this position?*</Form.Label>
            <Form.Control
              as="select"
              value={referralSource}
              onChange={(e) => setReferralSource(e.target.value)}
            >
              <option key="referral-source-none" value="" disabled>
                Please Select
              </option>
              {REFERRAL_SOURCE_OPTIONS.map(({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              ))}
            </Form.Control>
            {formErrors.referralSource && (
              <Form.Text className="text-danger">{formErrors.referralSource}</Form.Text>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="additionalComments">
            <Form.Label>Do you have any additional questions or comments for 3Play?</Form.Label>
            <Form.Control
              as="textarea"
              value={additionalComments}
              onChange={(e) => setAdditionalComments(e.target.value)}
            />
            {formErrors.additionalComments && (
              <Form.Text className="text-danger">{formErrors.additionalComments}</Form.Text>
            )}
          </Form.Group>
        </Form.Row>
      </Form>

      <Button variant="3play" disabled={loading} onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

VoiceWritingExperience.propTypes = {
  applicant: PropTypes.object,
  onSubmit: PropTypes.func,
  nextStep: PropTypes.func,
  authToken: PropTypes.object,
};

const styles = StyleSheet.create({
  div: {
    maxWidth: '650px',
  },
});

export default VoiceWritingExperience;
