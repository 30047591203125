import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'react-bootstrap/Dropdown';

function ArchiveButton(props) {
  return (
    <Dropdown.Item className="dropdown-item" onClick={props.archiveFile}>
      <i className="fa fa-archive"></i> Archive File
    </Dropdown.Item>
  );
}

ArchiveButton.propTypes = {
  archiveFile: PropTypes.func,
};

export default ArchiveButton;
