import { Formatter } from '../_types';

export class CapitalizeNewSentencesFormatter implements Formatter {
  /**
   * A formatter that capitalizes the first letters following sentence ending
   * punctuation mark or right brackets (ie: speaker labels).
   *
   * @example new CapitalizeNewSentencesFormatter().format("Hello! welcome! we missed you")
   * // returns "Hello! Welcome! We missed you"
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  format(text: string) {
    return text.replace(
      /([!?.\]]\s)([a-z])/g,
      (match, p1: string, p2: string) => `${p1}${p2.toUpperCase()}`
    );
  }
}
