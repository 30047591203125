import React from 'react';
import PropTypes from 'prop-types';

import CalendarImage from '~/components/app/live_auto_captioning/images/calendar.svg';

function BlankState(props) {
  const eventText = {
    previous: {
      header: 'You have no previous live events captioned yet!',
      note: 'Caption your first live event by clicking the “Schedule Live Captions” button above to get started.',
    },
    upcoming: {
      header: 'You currently have no scheduled live captioned events!',
      note: 'Click the “Schedule Live Captions” button above to schedule captions for your upcoming live event.',
    },
  };

  return (
    <div className="text-center p-5">
      <img src={CalendarImage} alt="Calendar" />
      <p className="mt-2 font-weight-bold">{eventText[props.eventType].header}</p>
      <p>{eventText[props.eventType].note}</p>
    </div>
  );
}

BlankState.propTypes = {
  eventType: PropTypes.string,
};

export default BlankState;
