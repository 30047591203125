import React from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function ControlTextInput({
  value,
  valid = true,
  readOnly,
  units,
  onChange,
  as,
  prefix,
  placeholder,
}) {
  return (
    <>
      <InputGroup as={as}>
        {prefix && (
          <InputGroup.Prepend>
            <InputGroup.Text>{prefix}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Form.Control
          type="text"
          className={!valid && css(styles.invalid)}
          value={value}
          readOnly={readOnly}
          placeholder={placeholder}
          onChange={onChange}
        />
        {units && (
          <InputGroup.Append>
            <InputGroup.Text>{units}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    </>
  );
}

ControlTextInput.propTypes = {
  value: PropTypes.any.isRequired,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  units: PropTypes.string,
  onChange: PropTypes.func,
  as: PropTypes.any,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
};

const styles = StyleSheet.create({
  invalid: {
    color: 'red',
    borderColor: 'red',
  },
});

export default ControlTextInput;
