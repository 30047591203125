export const createApplicationWaitlistMutation = `mutation createApplicantWaitlist(
    $firstname: String!
    $lastname: String!
    $email: String!
    $country: String!
    $state: String
    $languageId: Int
) {
    createApplicantWaitlist(
        fields: {
            firstname: $firstname
            lastname: $lastname
            email: $email
            country: $country
            state: $state
            languageId: $languageId
        }
    ) {
        success
    }
}`;
