import React from 'react';

import TakeMeToThreeplayButton from './TakeMeToThreeplayButton';
import Success from './images/success.png';

function AlreadySubmitted() {
  return (
    <div className="d-flex flex-column align-items-center justy-content-center">
      <img className="main-image mb-4" src={Success}></img>
      <div className="middle-lane text-center">
        <h1>Thanks for applying!</h1>
        <p className="primary-text">
          You&apos;ve already submitted an application. We&apos;ll be in touch when we&apos;ve had a
          chance to look it over!
        </p>
        <TakeMeToThreeplayButton />
      </div>
    </div>
  );
}

export default AlreadySubmitted;
