import React, { Dispatch } from 'react';
import { Form } from 'react-bootstrap';

import { State, Action } from '../../state/builderReducer';
import { Source } from '../../types/AccessPlayerTypes';

import { MediaSource } from './MediaSource';

import css from './FormV0.module.css';
import { PlayerSettings } from './PlayerSettings';

interface FormV0Props {
  availableSources: Source[];
  state: State;
  dispatch: Dispatch<Action>;
}

// TODO: Rename this to FormV1 once we release beta at the end of Q3. We won't
// need to keep a v0 around since no customers are on it.
export function FormV0(props: FormV0Props) {
  const { state, dispatch } = props;

  return (
    <div>
      <p>
        The 3Play Access Player Builder helps you build accessible audio and video experiences for
        your users.
      </p>

      <br />

      <MediaSource {...props} />

      <br />

      <h6>Embed Code Settings</h6>

      <Form>
        <Form.Check className={css.blockCheck} type="radio">
          <Form.Check.Input
            checked={state.priority === 'highPriority'}
            onChange={() => dispatch({ type: 'CHANGED_PRIORITY', newValue: 'highPriority' })}
            name="priority"
            id="high-priority"
            type="radio"
          />

          <Form.Check.Label htmlFor="high-priority">
            <div className={css.labelHeader}>Embed with JavaScript</div>
            <Form.Text muted className={css.helpText}>
              Select this option if the 3Play Access Player is the main component that get rendered
              on the page. This option is the fastest to load and provides the best SEO.
            </Form.Text>
          </Form.Check.Label>
        </Form.Check>

        <Form.Check className={css.blockCheck} type="radio">
          <Form.Check.Input
            checked={state.priority === 'lowPriority'}
            onChange={() => dispatch({ type: 'CHANGED_PRIORITY', newValue: 'lowPriority' })}
            name="priority"
            id="low-priority"
            type="radio"
          />

          <Form.Check.Label htmlFor="low-priority">
            <div className={css.labelHeader}>Embed with iframe</div>
            <Form.Text muted className={css.helpText}>
              Select this option if you are loading a lot of other types of content on your page in
              addition to the 3Play Access Player. This option is more compatible with CMS systems
              that may remove JavaScript.
            </Form.Text>
          </Form.Check.Label>
        </Form.Check>

        <p className={css.learnMore}>
          Not sure which to pick?{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.3playmedia.com/hc/en-us/articles/8998404162583-Constructing-Access-Player-Snippets-beta"
          >
            Learn More
          </a>
        </p>

        <br />

        <PlayerSettings state={state} dispatch={dispatch} />

        <br />
      </Form>
    </div>
  );
}
