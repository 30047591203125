import React, { useState, useMemo } from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';

import ContinuousCardLayout from './ContinuousCardLayout';
import { userLogger } from '~/logic/UserLogger';
import PlatformDetails from './PlatformDetails';

import '~/components/app/common/app.css';
function AddLinkedAccountModal(props) {
  const [searchTerm, setSearchTerm] = useState('');

  const popularPlatforms = useMemo(() => {
    return (
      props.integrationPlatforms &&
      props.integrationPlatforms.filter(
        (platform) =>
          platform.popularPlatform === true &&
          platform.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      )
    );
  }, [searchTerm, props.integrationPlatforms]);

  const otherPlatforms = useMemo(() => {
    return (
      props.integrationPlatforms &&
      props.integrationPlatforms.filter(
        (platform) =>
          platform.popularPlatform === false &&
          platform.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      )
    );
  }, [searchTerm, props.integrationPlatforms]);

  function closeModal(closeMethod) {
    setSearchTerm('');
    props.setShowAddLinkedAccountModal(false);
    userLogger.logEvent('LinkedAccounts', 'Close New Account Modal', { Method: closeMethod });
  }

  function renderPlatformData(platformData, platformType) {
    if (platformData.length === 0) {
      return;
    } else {
      return (
        <>
          {platformType && (
            <div className="pt-2">
              <h6 className={css(styles.heading)}>{platformType}</h6>
            </div>
          )}
          <ContinuousCardLayout>
            {platformData.map((platform) => (
              <PlatformDetails key={platform.id} name={platform.name} />
            ))}
          </ContinuousCardLayout>
        </>
      );
    }
  }

  const modalWidth =
    props.integrationPlatforms && props.integrationPlatforms.length > 6
      ? 'modal-width-large'
      : 'modal-width-small';

  return (
    <Modal show={props.show} onHide={() => closeModal('X')} dialogClassName={modalWidth}>
      <Modal.Header closeButton>
        <Modal.Title>Link a New Account</Modal.Title>
      </Modal.Header>

      <Modal.Body className={css(styles.modalBodyScrollable)}>
        {props.showSearchButton && (
          <InputGroup className="mb-3 col-sm-6">
            <FormControl
              placeholder="Search Linked Accounts"
              aria-label="Search Linked Accounts"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        )}
        {props.showPlatformsGrouped && renderPlatformData(props.integrationPlatforms, null)}
        {!props.showPlatformsGrouped &&
          popularPlatforms &&
          renderPlatformData(popularPlatforms, 'Popular Platforms')}
        {!props.showPlatformsGrouped &&
          otherPlatforms &&
          renderPlatformData(otherPlatforms, 'Other Platforms')}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="mr-auto" onClick={() => closeModal('Close Button')}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalBodyScrollable: {
    overflowY: 'scroll',
    maxHeight: '60vh',
  },
  heading: {
    fontWeight: 600,
  },
});

AddLinkedAccountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  showSearchButton: PropTypes.bool,
  showPlatformsGrouped: PropTypes.bool,
  setShowAddLinkedAccountModal: PropTypes.func.isRequired,
  integrationPlatforms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      logoPath: PropTypes.string,
      popularPlatform: PropTypes.bool,
    })
  ),
};

export default AddLinkedAccountModal;
