import React from 'react';
import PropTypes from 'prop-types';

import { Table } from '@threeplayground/index';

const PricingBlocksTable = ({ pricingBlocks }) => {
  const columns = [
    {
      header: 'SKU',
      accessor: 'sku',
      cell: 'string',
    },
    {
      header: 'Block Start',
      accessor: 'startMinute',
      cell: 'number',
    },
    {
      header: 'Block End',
      accessor: 'endMinute',
      cell: 'number',
    },
    {
      header: 'Block Price',
      accessor: 'price',
      cell: 'price',
    },
  ];

  return (
    <>
      <Table columns={columns} data={pricingBlocks} />
    </>
  );
};

PricingBlocksTable.propTypes = {
  pricingBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      startMinute: PropTypes.number,
      endMinute: PropTypes.number,
      price: PropTypes.number,
      sku: PropTypes.string,
    })
  ),
};

export default PricingBlocksTable;
