import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import { Typeahead } from 'react-bootstrap-typeahead';

import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';
import WordlistTipPanel from '~/components/app/common/wordlist/WordlistTipPanel';

import { appStyles } from '~/components/app/common/styles';

function AsrWordlistModal(props) {
  const [invalidResource, setInvalidResource] = useState(false);

  function handleResourceChange(obj) {
    if (obj.length === 0 || props.resourceWithWordlist.includes(Number(obj[0].id))) {
      props.setWordlistAssignment('resourceId', null);
      setInvalidResource(true);
    } else {
      props.setWordlistAssignment('resourceId', Number(obj[0].id));
      setInvalidResource(false);
    }
  }

  function getValidationErrorMessages() {
    const errors = [];
    if (props.name === '') {
      errors.push('Please provide a valid name for your wordlist.');
    }
    if (props.resourceId === null) {
      errors.push(`Please select a ${props.resourceType} to associate your wordlist with.`);
    }
    if (props.wordlist === '') {
      errors.push("The wordlist can't be empty.");
    }
    if (
      !props.editingList &&
      props.resourceWithWordlist &&
      props.resourceWithWordlist.includes(Number(props.resourceId))
    ) {
      errors.push(`The selected ${props.resourceType} already has an associated wordlist.`);
    }
    return errors;
  }

  const selectedResource = props.resourceOptions.find(
    (resource) => Number(resource.id) === props.resourceId
  );
  const validationErrorMessages = getValidationErrorMessages();

  return (
    <Modal size="lg" show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="capitalize">
          {props.editingList ? 'Edit ' : 'New '}
          {props.resourceType + ' Wordlist'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.error && (
          <Alert variant="danger">
            We weren't able to save your wordlist. Double check your wordlist settings or try again
            later.
          </Alert>
        )}
        {props.successAlert && (
          <Alert variant="success">
            <i className="fa fa-check" aria-hidden="true"></i> Your wordlist has been successfully
            added!
          </Alert>
        )}
        {props.invalidWords.length > 0 && (
          <Alert variant="danger">
            Sorry, the following words can not be added to the wordlist because they are too short
            or they are already in 3Play’s default dictionary of terms and phrases:{' '}
            <b>{props.invalidWords.join(', ')}</b>
          </Alert>
        )}
        <Row>
          <Col>
            <Form>
              <Form.Group controlId="formWordlistName">
                <Form.Label>
                  <strong>Wordlist Name</strong>
                </Form.Label>
                <Form.Control
                  placeholder="My Wordlist"
                  onChange={(e) => props.setWordlistAssignment('name', e.target.value)}
                  value={props.name}
                />
              </Form.Group>
              <Form.Group controlId="formWordlistResource">
                <Form.Label className="capitalize">
                  <strong>Add Wordlist to {props.resourceType}</strong>
                </Form.Label>
                <Typeahead
                  defaultInputValue={selectedResource ? selectedResource.name : ''}
                  id="resources-dropdown"
                  isInvalid={invalidResource}
                  labelKey="name"
                  options={props.resourceOptions}
                  placeholder={`Select ${props.resourceType}`}
                  onChange={(obj) => handleResourceChange(obj)}
                  renderMenuItemChildren={(option) => (
                    <TooltipIfErrors
                      errors={
                        props.resourceWithWordlist.includes(Number(option.id))
                          ? [`This ${props.resourceType} already has an associated wordlist.`]
                          : []
                      }
                    >
                      <div
                        className={
                          props.resourceWithWordlist.includes(Number(option.id)) ? 'disabled' : ''
                        }
                        key={option.id}
                      >
                        {option.name}
                      </div>
                    </TooltipIfErrors>
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <strong>Words</strong>
                </Form.Label>
                <Form.Control
                  value={props.wordlist}
                  onChange={(e) => props.setWordlistAssignment('wordlist', e.target.value)}
                  as="textarea"
                  rows={7}
                  placeholder="Write each word on a new line"
                />
              </Form.Group>
            </Form>
          </Col>
          <Col>
            <WordlistTipPanel />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={() => props.onClose()}>
          Cancel
        </Button>
        <TooltipIfErrors errors={validationErrorMessages}>
          <Button
            className={validationErrorMessages.length > 0 ? css(appStyles.pointerEventsNone) : ''}
            disabled={validationErrorMessages.length > 0}
            onClick={() => props.saveWordlist()}
            variant="primary"
          >
            {props.editingList ? 'Edit ' : 'Add '} Wordlist
          </Button>
        </TooltipIfErrors>
      </Modal.Footer>
    </Modal>
  );
}

AsrWordlistModal.propTypes = {
  editingList: PropTypes.bool,
  error: PropTypes.bool,
  onClose: PropTypes.func,
  invalidWords: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  onClose: PropTypes.func,
  resourceId: PropTypes.number,
  resourceOptions: PropTypes.array,
  resourceType: PropTypes.string,
  resourceWithWordlist: PropTypes.arrayOf(PropTypes.number),
  saveWordlist: PropTypes.func,
  setResourceId: PropTypes.func,
  setWordlistAssignment: PropTypes.func,
  show: PropTypes.bool,
  successAlert: PropTypes.bool,
  wordlist: PropTypes.string,
};
export default AsrWordlistModal;
