import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { Card, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { appStyles } from '~/components/app/common/styles';
import OrderMore from '~/components/app/order_more/OrderMore';
import { canOrderDubbingAudioEmbed, validQuoteUrl } from '~/components/app/order_helpers/dubbing';

const DubbingOrderSummary = ({ selectedFormats }) => {
  return (
    <div>
      <b>Services Ordered</b>
      <ul>
        {selectedFormats.map((format) => (
          <li key={format.id}>{format.targetLanguage.fullName}</li>
        ))}
      </ul>
    </div>
  );
};

DubbingOrderSummary.propTypes = {
  selectedFormats: PropTypes.arrayOf(PropTypes.any),
};

const DubbingTipPanel = () => {
  return (
    <>
      <strong>To order Dubbing:</strong>
      <p>
        Receive a recorded spoken audio track for your video in a different language than the
        original speaker’s voice(s).
      </p>
      <p>
        3Play works with partners to complete hiqh-quality, broadcast-ready Dubbing. You will
        receive a .wav file, with additional audio mixing available on request.
      </p>
    </>
  );
};

const DubbingOrderMore = ({
  authToken,
  dubbingOrderOptions,
  isStudio,
  mediaFiles,
  submissionUrl,
}) => {
  const [isEmbeddingChecked, setIsEmbeddingChecked] = useState(false);
  const [quoteUrl, setQuoteUrl] = useState('');
  const [selectedDubbingOrderOptions, setSelectedDubbingOrderOptions] = useState([]);

  const onSubmit = ({ setErrors, setSuccess }) => {
    const formData = new FormData();
    formData.append('media_file_ids', mediaFiles.map((file) => file.id).join(','));
    formData.append(
      'order_option_ids',
      selectedDubbingOrderOptions.map((option) => option.id).join(',')
    );
    formData.append('should_order_embedding', isEmbeddingChecked);
    formData.append('quote_url', quoteUrl);

    return fetch(submissionUrl, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-TOKEN': authToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccess(true);
        } else {
          setErrors((currErrors) => [
            ...currErrors,
            'There was an error submitting your order. Please try again.',
          ]);
        }
      });
  };

  return (
    <OrderMore
      mainContent={
        <>
          <Card.Title className={css(appStyles.title)}>Select Languages</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            Select languages that you want a dubbed audio track for:
          </Card.Subtitle>
          <Typeahead
            clearButton
            id="dubbing-language-dropdown"
            multiple={true}
            onChange={setSelectedDubbingOrderOptions}
            options={dubbingOrderOptions}
            labelKey={(item) => item.targetLanguage.fullName}
            placeholder="Search Languages"
            selected={selectedDubbingOrderOptions}
          />
          <Form.Group>
            <Card.Title className={css(appStyles.title)}>Link Dubbing Quote</Card.Title>
            <Form.Label className="mb-2 text-muted">Dubbing Quote URL</Form.Label>
            <Form.Control
              id="dubbingQuote"
              isInvalid={!validQuoteUrl(quoteUrl)}
              onChange={(e) => setQuoteUrl(e.target.value)}
              type="text"
              value={quoteUrl}
            />
            <Form.Text className="mb-2 text-muted">
              By linking your Dubbing Quote, you agree to the prices quoted based on the information
              provided in the Dubbing Checklist.
            </Form.Text>
          </Form.Group>
          {canOrderDubbingAudioEmbed(isStudio) && (
            <Form.Check
              id="dubbingAudioEmbed"
              aria-checked={isEmbeddingChecked}
              defaultChecked={isEmbeddingChecked}
              label="I need an embedded dubbing asset"
              onClick={() => setIsEmbeddingChecked((curr) => !curr)}
              type="checkbox"
            />
          )}
        </>
      }
      onSubmit={onSubmit}
      orderType="Dubbing"
      submissionDisabled={!validQuoteUrl(quoteUrl) || selectedDubbingOrderOptions.length === 0}
      summaryContent={<DubbingOrderSummary selectedFormats={selectedDubbingOrderOptions} />}
      tipPanelContent={<DubbingTipPanel />}
    />
  );
};

DubbingOrderMore.propTypes = {
  authToken: PropTypes.string,
  dubbingOrderOptions: PropTypes.arrayOf(PropTypes.any),
  isStudio: PropTypes.bool,
  mediaFiles: PropTypes.arrayOf(PropTypes.any),
  submissionUrl: PropTypes.string,
};

export default DubbingOrderMore;
