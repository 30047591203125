import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import {
  SECTIONED_LAYOUT,
  CONTINUOUS_LAYOUT,
  INTEGRATION_COUNT_THRESHOLD,
} from '~/helpers/constants';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { userLogger } from '~/logic/UserLogger';

import { linkedAccountMutation } from './data/mutations';
import { integrationPlatformsQuery, linkedAccountsQuery } from './data/queries';

import AddLinkedAccountModal from './AddLinkedAccountModal';
import ContinuousCardLayout from './ContinuousCardLayout';
import IntegrationDetails from './IntegrationDetails';
import RemoveLinkedAccountModal from './RemoveLinkedAccountModal';
import SectionedCardLayout from './SectionedCardLayout';
import CaptionPercentageModal from './CaptionPercentageModal';
function LinkedAccounts(props) {
  const [currentIntegration, setCurrentIntegration] = useState(null);
  const [disabledFeatures, setDisabledFeatures] = useState([]);
  const [enabledFeatures, setEnabledFeatures] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [filteredLinkedAccounts, setFilteredLinkedAccounts] = useState([]);
  const [integrationPlatforms, setIntegrationPlatforms] = useState(null);
  const [integrationToBeRemoved, setIntegrationToBeRemoved] = useState(null);
  const [layoutStyle, setLayoutStyle] = useState(null);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [platformToDelete, setPlatformToDelete] = useState('');
  const [showAddLinkedAccountModal, setShowAddLinkedAccountModal] = useState(false);
  const [showCaptionPercentageModal, setShowCaptionPercentageModal] = useState(false);
  const [showRemoveLinkedAccountModal, setShowRemoveLinkedAccountModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  async function deleteIntegration() {
    const response = await threeplayApi.request(linkedAccountMutation, {
      integrationId: integrationToBeRemoved.id,
    });
    userLogger.logEvent('LinkedAccounts', 'Remove Account', {
      'Video Integration': platformToDelete,
    });

    // Server error
    if (response.errors) {
      setErrorMessages(response.errors);
    }

    // Success
    else if (response.data.deleteLinkedAccount.success) {
      setSuccessMessage(response.data.deleteLinkedAccount.success);
    }

    setShowRemoveLinkedAccountModal(false);
    getLinkedAccounts();
  }

  useEffect(() => {
    getLinkedAccounts();
  }, []);

  useEffect(() => {
    setFilteredLinkedAccounts(linkedAccounts);
  }, [linkedAccounts]);

  function getLinkedAccounts() {
    threeplayApi.request(linkedAccountsQuery, { livePlatformsOnly: false }).then((response) => {
      if (response.data.project.linkedAccounts) {
        setLinkedAccounts(response.data.project.linkedAccounts);
        setEnabledFeatures(response.data.project.enabledFeatures);
        setDisabledFeatures(response.data.project.disabledFeatures);
        if (props.integrationsCount > INTEGRATION_COUNT_THRESHOLD) {
          setLayoutStyle(SECTIONED_LAYOUT);
        } else {
          setLayoutStyle(CONTINUOUS_LAYOUT);
        }
      }
    });
  }

  function successAlert() {
    return (
      <Alert className="mt-4" variant="success" onClose={() => setSuccessMessage(null)} dismissible>
        {successMessage}
      </Alert>
    );
  }

  function errorAlert() {
    return (
      <Alert className="mt-4" variant="danger" onClose={() => setErrorMessages([])} dismissible>
        {errorMessages.map((message, i) => (
          <div key={i}>{message}</div>
        ))}
      </Alert>
    );
  }

  function handlePlatformFilterChange(platformName) {
    let filtered = linkedAccounts;
    // Filter only if "All Integrations" is not selected
    if (platformName !== '') {
      filtered = linkedAccounts.filter(
        (linkedAccount) =>
          linkedAccount.platformDetails.name.toLowerCase() === platformName.toLowerCase()
      );
    }
    setFilteredLinkedAccounts(filtered);
  }

  function filterByPlatform() {
    return (
      <span className="pl-4">
        <label>Filter by integration</label>
        <select className="ml-2" onChange={() => handlePlatformFilterChange(event.target.value)}>
          <option value="">All integrations</option>
          {[...linkedAccounts]
            .sort((a, b) => (a.platformDetails.name > b.platformDetails.name ? 1 : -1))
            .map((linkedAccount) => {
              return (
                <option
                  key={linkedAccount.platformDetails.id}
                  value={linkedAccount.platformDetails.name}
                >
                  {linkedAccount.platformDetails.name}
                </option>
              );
            })}
        </select>
      </span>
    );
  }

  useEffect(() => {
    threeplayApi
      .request(integrationPlatformsQuery, { livePlatformsOnly: false })
      .then((response) => {
        if (response.data.integrationPlatforms) {
          setIntegrationPlatforms(response.data.integrationPlatforms);
        }
      });
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickEvent);
    return () => {
      window.removeEventListener('mousedown', handleClickEvent);
    };
  }, []);

  const handleClickEvent = (event) => {
    const addLinkedAccountEvent = event.target
      .getAttributeNames()
      .includes('data-add-linked-account');
    if (addLinkedAccountEvent) {
      setShowAddLinkedAccountModal(true);
      userLogger.logEvent('LinkedAccounts', 'Add New Account', { Location: 'Empty State' });
    }
  };

  function openAddLinkedAccountModal() {
    userLogger.logEvent('LinkedAccounts', 'Add New Account', { Location: 'Populated View' });
    setShowAddLinkedAccountModal(true);
  }

  if (props.integrationsCount === 0) {
    return (
      <AddLinkedAccountModal
        integrationPlatforms={integrationPlatforms}
        show={showAddLinkedAccountModal}
        setShowAddLinkedAccountModal={(show) => setShowAddLinkedAccountModal(show)}
        showSearchButton
      />
    );
  } else {
    return (
      <div className="container-fluid text-left">
        <>
          <Button
            id="add-linked-account"
            variant="primary"
            size="sm"
            onClick={() => openAddLinkedAccountModal()}
          >
            Link a New Account
          </Button>
          {linkedAccounts.length > 2 && filterByPlatform()}
        </>
        {successMessage && successAlert()}
        {errorMessages.length > 0 && errorAlert()}
        {layoutStyle === SECTIONED_LAYOUT &&
          filteredLinkedAccounts.map((linkedAccount) => (
            <SectionedCardLayout
              className="pt-4"
              key={linkedAccount.platformDetails.id}
              platformName={linkedAccount.platformDetails.name}
            >
              <IntegrationDetails
                disabledFeatures={disabledFeatures}
                enabledFeatures={enabledFeatures}
                integrationToBeRemoved={(integration) => setIntegrationToBeRemoved(integration)}
                integrations={linkedAccount.integrations}
                platformName={linkedAccount.platformDetails.name}
                setRemoveIntegrationModal={(show) => setShowRemoveLinkedAccountModal(show)}
                setShowCaptionPercentageModal={(show) => setShowCaptionPercentageModal(show)}
                setCurrentIntegration={(integration) => setCurrentIntegration(integration)}
                setPlatformToDelete={setPlatformToDelete}
              />
            </SectionedCardLayout>
          ))}
        {layoutStyle === CONTINUOUS_LAYOUT && (
          <ContinuousCardLayout>
            {filteredLinkedAccounts.map((linkedAccount, index) => (
              <IntegrationDetails
                disabledFeatures={disabledFeatures}
                enabledFeatures={enabledFeatures}
                key={index}
                integrationToBeRemoved={(integration) => setIntegrationToBeRemoved(integration)}
                integrations={linkedAccount.integrations}
                platformName={linkedAccount.platformDetails.name}
                setRemoveIntegrationModal={(show) => setShowRemoveLinkedAccountModal(show)}
                setShowCaptionPercentageModal={(show) => setShowCaptionPercentageModal(show)}
                setCurrentIntegration={(integration) => setCurrentIntegration(integration)}
                setPlatformToDelete={setPlatformToDelete}
              />
            ))}
          </ContinuousCardLayout>
        )}
        <AddLinkedAccountModal
          integrationPlatforms={integrationPlatforms}
          show={showAddLinkedAccountModal}
          setShowAddLinkedAccountModal={(show) => setShowAddLinkedAccountModal(show)}
          showSearchButton
        />
        <RemoveLinkedAccountModal
          enabledFeatures={enabledFeatures}
          integrationName={integrationToBeRemoved && integrationToBeRemoved.displayName}
          integrationPlatform={platformToDelete}
          show={showRemoveLinkedAccountModal}
          setShowRemoveLinkedAccountModal={(show) => setShowRemoveLinkedAccountModal(show)}
          deleteIntegration={deleteIntegration}
        />
        <CaptionPercentageModal
          currentIntegration={currentIntegration}
          setShowCaptionPercentageModal={(show) => setShowCaptionPercentageModal(show)}
          show={showCaptionPercentageModal}
        />
      </div>
    );
  }
}

LinkedAccounts.propTypes = {
  integrationsCount: PropTypes.number.isRequired,
};

export default LinkedAccounts;
