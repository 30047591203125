import React from 'react';
import PropTypes from 'prop-types';

import { ccLogoPath } from '~/helpers/logos';

function CreditCardLabel(props) {
  return (
    <>
      <>{props.card.billingContactName + ' '}</>
      <img className="img-fluid" src={ccLogoPath(props.card.name)} />
      <>{' xxxx-xxxx-xxxx-' + props.card.lastDigits}</>
    </>
  );
}

CreditCardLabel.propTypes = {
  card: PropTypes.shape({
    billingContactName: PropTypes.string,
    lastDigits: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default CreditCardLabel;
