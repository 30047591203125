import React, { ForwardedRef, forwardRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { InputDecorationDelegate } from '../_implementation/InputDecorations';

import { InputWrapper, InputWrapperProps } from '../_implementation/InputWrapper';

// NOTE: if you update this, make sure you also explicitly pass all props from
// ForwardedNativeSelectProps to the <FormControl as="select" /> below.
// This needs to be destructured from props explicitly as all remaining props
// are forwarded to the InputWrapper.
type ForwardedNativeSelectProps = 'autocomplete' | 'disabled' | 'required';

/**
 * For simple, human-readable strings you can directly use string values
 * for both value and display.
 *
 * Otherwise, pass an object with `id` as the value and `name` to display.
 */
export type SelectObjectOption = { id: string; name: string };
export type SelectOption = string | SelectObjectOption;

/** This generic allows for a conditional type of null depending on other generics. */
type NullableValue<Value extends SelectOption, Nullable extends boolean> = Nullable extends true
  ? Value | null
  : Value;

/** This generic allows input to be either a full option or a key of an option */
type KeyOrOption<Value extends SelectOption> = Value extends SelectObjectOption
  ? Value['id'] | Value
  : Value;

/* These type generics are generally meant to be inferred from options and
 * clearable passed to the Select. We discourage explicitly passing in these
 * generic.
 */
export interface SelectProps<Value extends SelectOption, Clearable extends boolean = false>
  extends InputWrapperProps<HTMLSelectElement>,
    Partial<Pick<HTMLSelectElement, ForwardedNativeSelectProps>> {
  options: readonly Value[]; // readonly allows for a nice `as const` experience.
  clearable?: Clearable;

  // These types look complex, but all are designed for the purpose of type
  // inference. What the below is saying is narrowing the type of `value`,
  // `defaultValue`, and `onSelect` depending if the input is clearable and if
  // options are constant values or not.
  //
  // The value of this is obvious if you start wiring up to a Select;
  // onSelect gives you a really nice type, and you don't have to worry about
  // unnecessary `null` checking when getting values out of a non-clearable
  // Select.
  value?: NullableValue<KeyOrOption<Value>, true>; // value is always nullable, as the parent may force it empty
  defaultValue?: NullableValue<KeyOrOption<Value>, Clearable>;
  onSelect?: (value: NullableValue<Value, Clearable>) => void;

  emptyOptionName?: string;
}

function isValueObject(value?: SelectOption | null): value is { id: string; name: string } {
  return Boolean(value && typeof value !== 'string' && 'id' in value);
}

function toNativeSelectValue(value?: SelectOption | null): string | undefined {
  if (typeof value === 'undefined') {
    return value; // we want to continue keeping things uncontrolled if undefined
  }

  const stringValue: string = isValueObject(value) ? value.id : value || '';
  return stringValue;
}

function SelectInner<Value extends SelectOption, Clearable extends boolean = false>(
  props: SelectProps<Value, Clearable>,
  ref: ForwardedRef<HTMLSelectElement>
) {
  const {
    // Props from SelectProps
    value,
    defaultValue,
    onChange,
    onSelect,
    options,
    emptyOptionName = 'Select...',
    clearable = false,

    // Props from ForwardedNativeSelectProps
    autocomplete,
    disabled,
    required,

    // Props from Input Wrapper
    append = [],
    ...inputWrapperProps
  } = props;

  // This is kind of silly state to track, but we need to capture this to
  // have uncontrolled Selects (value === undefined) work the same as controlled
  // Selects. Specifically, we need to know when we've historically selected
  // a value and should hide the "empty" option for Selects that are
  // not clearable.
  const [hasSelected, setHasSelected] = useState(Boolean(defaultValue));

  // Even when a non-clearable input, we can only hide the empty option
  // after another option is selected. Otherwise it incorrectly looks like
  // the first option was selected when the Select was untouched.
  const isEmptyOptionHidden = !clearable && (Boolean(value) || hasSelected);

  // Note that we don't call onChange here, as that is techically a different
  // event (change). This should be ok, as change events should not be used
  // tracking in React state (relying on Form submission for native behavior).
  const clearButton: InputDecorationDelegate = ({ Button }) => (
    <Button
      variant="outline-secondary"
      // Typescript struggles with the null union with the `clearable`
      // generic, so we do a yucky typecast. Be careful with editing this
      // as this may mask bugs.
      onClick={() => onSelect && onSelect(null as unknown as Value)}
    >
      ✕
    </Button>
  );

  return (
    <InputWrapper<HTMLSelectElement>
      {...inputWrapperProps}
      append={clearable ? clearButton : append}
      onChange={(event) => {
        const index = event.target.selectedIndex - 1; // offset empty option
        const option = options[index] || null;
        setHasSelected(true);
        onChange && onChange(event);

        // Typescript struggles with the null union with the `clearable`
        // generic, so we do a yucky typecast. Be careful with editing this
        // as this may mask bugs.
        onSelect && onSelect(option as Value);
      }}
    >
      {(wrapperProps) => (
        <FormControl
          autoComplete={autocomplete}
          disabled={disabled}
          required={required}
          {...wrapperProps}
          as="select"
          ref={ref}
          value={toNativeSelectValue(value)}
          defaultValue={toNativeSelectValue(defaultValue)}
        >
          <option disabled={isEmptyOptionHidden} hidden={isEmptyOptionHidden} value="">
            {emptyOptionName}
          </option>

          {options.map((option) => {
            const val: string = isValueObject(option) ? option.id : option;
            const display: string = isValueObject(option) ? option.name : option;

            return (
              <option key={val} value={val}>
                {display}
              </option>
            );
          })}
        </FormControl>
      )}
    </InputWrapper>
  );
}

SelectInner.displayName = 'Select';

/**
 * `Select` is a nicely typed overlay on top of native/Bootstrap `<select>`.
 *
 * #### Options and types
 *
 * `Select` allows values in the form of `string` or `{ id: string, name: string }`.
 * Usually type inference will help you out and you don't have to specify the
 * generic, just start by passing in options!
 *
 * For cases where all options are known, prefer using `as const` with a static set of options!
 * That will give you much more constrained types that are safer and easier to
 * assign to state, pass to functions, etc.
 *
 * ```tsx
 * <Select
 *   label="My Color"
 *   name="color"
 *   options={['Red', "Blue", "Yellow"] as const}
 *   onSelect={(value) => console.log(value)} // typed as `"Red" | "Blue" | "Yellow" | null`!
 * />
 *
 * <Select
 *   label="My Color"
 *   name="color"
 *   options={[{ id: 'r', name: "Red" }, { id: 'b', name: "Blue" }] as const}
 *   onSelect={(value) => console.log(value)} // typed as `{ id: 'r', name: "Red" } | { id: 'b', name: "Blue" }  | null`!
 * />
 * ```
 *
 * ---
 *
 * It shares much of the same form handling and native behavior from as `Form`,
 * `Input`, and `Textarea`; see those components for more examples of usage.
 *
 * If you are using this within a `Form` component, you likely don't need to set
 * much more than `label`, `name`, and whatever presentational props you want.
 * Validation and parsing are automatically handled for you. Prefer using native
 * input props to adjust behavior including `readOnly`, `required`, `disabled`,
 * etc.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/select
 * @see https://react-bootstrap-v4.netlify.app/components/forms/
 */
export const Select = forwardRef(SelectInner);
