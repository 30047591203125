const getEnabledFormatsForAccountQuery = `query GetEnabledFormatsForAccountQuery($acctId: Int!) {
    accountOutputFormats(accountId: $acctId) {
      id
      accountId
      outputFormatId
    }
}
`;
const getAdvancedFormatsEnabledForAccountQuery = `query GetAdvancedFormatsEnabledForAccountQuery($acctId: Int!) {
  accountAdvancedFormatsEnabled(accountId: $acctId)
}
`;

export { getEnabledFormatsForAccountQuery, getAdvancedFormatsEnabledForAccountQuery };
