import { TimestampInMs } from './_types';

/**
 * Creates a clock instance that makes it easier to mock the current datetime in tests
 */
export class Clock {
  now(): TimestampInMs {
    return Date.now();
  }
}
