import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MessageList from '~/components/app/common/MessageList';
import Alert from 'react-bootstrap/Alert';
import { threeplayApi } from '~/logic/ThreeplayApi';

function CancelServiceModal(props) {
  const [showSuccess, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const splitServiceName =
    props.services &&
    props.services[0] &&
    props.services[0].type &&
    props.services[0].type.split(/(?=[A-Z])/).join(' ');
  const formattedServiceName = splitServiceName || 'service';
  function handleClose() {
    props.onClose();
  }

  function handleCancelService() {
    cancelServiceGroup();
  }

  function cancelMutation(serviceId) {
    return `cancelService( serviceId: ${serviceId}) {
      errors,
      service { status },
    }`;
  }

  async function cancelServiceGroup() {
    const { data: { cancelService: { errors } = {} } = {} } = await threeplayApi.mutate(
      cancelMutation(props.services[0].id)
    );
    if (errors) {
      setErrors(errors);
    } else {
      setSuccess(true);
      setTimeout(() => {
        handleClose();
        props.onUpdate();
        setSuccess(false);
      }, 3000);
    }
  }

  function successAlert() {
    return <Alert variant="success">Successfully cancelled!</Alert>;
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Service</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
        {showSuccess && successAlert()}
        {errors.length >= 0 && <MessageList variant="error" messages={errors} />}
        <p>Are you sure you want to cancel this {formattedServiceName}?</p>
        <p>This action cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button className="float-left" variant="primary" onClick={handleCancelService}>
          YES - Cancel service
        </Button>
        <Button className="float-right" variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CancelServiceModal.propTypes = {
  show: PropTypes.bool,
  handleModalChange: PropTypes.func,
  fileId: PropTypes.string,
  projectId: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
};

export default CancelServiceModal;
