import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MessageList from '~/components/app/common/MessageList';
import Alert from 'react-bootstrap/Alert';
import { threeplayApi } from '~/logic/ThreeplayApi';

function DeleteCaptionImportModal(props) {
  const [showSuccess, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [fetching, setFetching] = useState(false);
  const language =
    props.service && props.service.language.name.replace(/^\w/, (c) => c.toUpperCase());

  function handleClose() {
    props.onClose();
  }

  function handleDeleteImport() {
    deleteCaptionImport();
  }

  function deleteCaptionImportMutation(serviceId) {
    return `deleteCaptionImport( serviceId: ${serviceId}) {
      errors,
      service { status },
    }`;
  }

  async function deleteCaptionImport() {
    setFetching(true);
    const { data: { deleteCaptionImport: { errors } = {} } = {} } = await threeplayApi.mutate(
      deleteCaptionImportMutation(props.service.id)
    );
    if (errors) {
      setErrors(errors);
      setFetching(false);
    } else {
      setSuccess(true);
      setFetching(false);
      setTimeout(() => {
        handleClose();
        props.onUpdate();
        setSuccess(false);
      }, 3000);
    }
  }

  function successAlert() {
    return <Alert variant="success">Amara Caption Import successfully deleted!</Alert>;
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Caption Import</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
        {showSuccess && successAlert()}
        {errors.length >= 0 && <MessageList variant="error" messages={errors} />}
        <p className="font-weight-bold">
          Are you sure you want to delete this{' ' + language} imported caption?
        </p>
        <p>This action cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button
          className="float-left"
          disabled={fetching || showSuccess}
          variant="primary"
          onClick={handleDeleteImport}
        >
          YES - Delete Import
        </Button>
        <Button className="float-right" variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DeleteCaptionImportModal.propTypes = {
  show: PropTypes.bool,
  service: PropTypes.object,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default DeleteCaptionImportModal;
