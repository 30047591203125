import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import { StyleSheet, css } from 'aphrodite';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { batchesQuery } from '~/components/app/order_form/data/queries';
import { checkKeyPress } from '~/helpers/checkKeyPress';
import {
  BATCH_NEW_FOLDER_INDEX,
  BATCH_NEW_FOLDER_TEXT_INDEX,
  BATCH_EXISTING_FOLDER_INDEX,
  BATCH_EXISTING_FOLDER_SELECT_INDEX,
} from '~/helpers/constants';

import { Typeahead } from 'react-bootstrap-typeahead';
import { wordlistSettingsPath } from '~/helpers/app/paths';

import WordlistModal from './WordlistModal';
function WordlistSection(props) {
  if (props.wordlistAssignment.wordlist === null) {
    return (
      <div>
        No wordlist is associated with this folder.{' '}
        <a href={wordlistSettingsPath} rel="noopener noreferrer" target="_blank">
          Add one in the settings page
        </a>
        .
      </div>
    );
  } else {
    return (
      <div>
        All files uploaded to this folder will use the following Wordlist,{' '}
        <a href="#" onClick={() => props.setShowWordlistModal(true)}>
          {props.name}
        </a>
      </div>
    );
  }
}
function BatchSelection(props) {
  const [batches, setBatches] = useState([]);
  const [defaultBatchId, setDefaultBatchId] = useState('');
  const [errors, setErrors] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [dupeName, setDupeName] = useState(false);
  const [emptyName, setEmptyName] = useState(false);
  const [showFolders, setShowFolders] = useState(false);
  const [showWordlistModal, setShowWordlistModal] = useState(false);

  useEffect(() => {
    setFetching(true);
    threeplayApi.request(batchesQuery).then((res) => {
      const data = res.data || {};
      if (data.batches) {
        const unarchivedBatches = sortBatches(data.batches);
        setBatches(unarchivedBatches);
        const defaultBatch = unarchivedBatches.find((b) => b.name === 'My Latest Upload');
        if (defaultBatch) {
          setDefaultBatchId(defaultBatch.id);
          props.setBatchId(defaultBatch.id);
        }
      } else {
        setErrors(res.errors);
      }
    });
    setFetching(false);
  }, []);

  function sortBatches(batches) {
    return batches
      .filter((batch) => !batch.archived)
      .sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
  }

  function chooseExistingBatch(obj) {
    if (obj) {
      props.setBatchError(false);
      props.setBatchId(obj.id);
      props.setBatchName(obj.name);
    }
  }

  function createNewBatch(name) {
    props.setBatchId('');
    props.setBatchName(name);
    verifyValidity(name);
  }

  function clearBatchName(event) {
    event.stopPropagation();
    createNewBatch('');
  }

  function toggleSelected(id) {
    if (id === 'existingBatch') {
      props.setBatchName('');
      setShowFolders(true);
      props.setBatchError(true);
      setDupeName(false);
      setEmptyName(false);
    } else {
      props.setBatchName('My Latest Upload');
      props.setBatchId(defaultBatchId);
      props.setBatchError(false);
      setShowFolders(false);
    }
    props.setBatchId('');
  }

  function verifyValidity(name) {
    if (name === '') {
      setEmptyName(true);
      props.setBatchError(true);
    } else {
      setEmptyName(false);
      props.setBatchError(false);
    }
    if (batches.some((item) => item.name === name) && name !== 'My Latest Upload') {
      setDupeName(true);
      props.setBatchError(true);
    } else if (dupeName === true) {
      setDupeName(false);
      props.setBatchError(false);
    }
  }

  function nameAlert() {
    if (dupeName) {
      return (
        <span className={css(styles.error)}>
          A folder with this name already exists. Please choose a different name or select an
          existing folder.
        </span>
      );
    } else if (emptyName) {
      return <span className={css(styles.error)}>Please enter a valid folder name.</span>;
    }
  }

  return (
    <>
      <div className={css(styles.question)}>Where would you like to store your files?</div>
      <Form.Group className={css(styles.inputWidth)}>
        <Form.Check
          tabIndex={BATCH_NEW_FOLDER_INDEX}
          type="radio"
          label="Create New Folder"
          name="batchMethod"
          id="newBatch"
          onChange={(e) => toggleSelected(e.target.id)}
          onKeyDown={(e) => checkKeyPress(e, () => toggleSelected(e.target.id))}
          defaultChecked={props.batchId === ''}
        />
        <Form.Control
          tabIndex={BATCH_NEW_FOLDER_TEXT_INDEX}
          type="text"
          value={props.batchName}
          id="newBatchName"
          onChange={(e) => createNewBatch(e.target.value)}
          onFocus={(e) => clearBatchName(e)}
          className={css(styles.checkboxInput)}
          disabled={showFolders}
          hidden={showFolders}
          isInvalid={dupeName || emptyName}
        />
        {nameAlert()}
        <Form.Check
          tabIndex={BATCH_EXISTING_FOLDER_INDEX}
          type="radio"
          label="Select Existing Folder"
          name="batchMethod"
          id="existingBatch"
          onChange={(e) => toggleSelected(e.target.id)}
          onKeyDown={(e) => checkKeyPress(e)}
          defaultChecked={props.batchId != ''}
        />
        {fetching && <div> Loading....</div>}
        {errors.length > 0 ? (
          <div className={css(styles.error)}>
            There was an error loading your folders. Please click the &lsquo;Back&lsquo; button
            below and then return to this page. If the problem persists, contact
            support@3playmedia.com.
          </div>
        ) : (
          <Typeahead
            tabIndex={BATCH_EXISTING_FOLDER_SELECT_INDEX}
            id="existing-batch-dropdown"
            labelKey="name"
            onChange={(obj) => chooseExistingBatch(obj[0])}
            options={batches}
            placeholder="Search Folder"
            disabled={!showFolders}
            hidden={!showFolders}
            value={batches.find((batch) => batch.id === props.batchId)}
          />
        )}
      </Form.Group>
      {props.wordlistAssignment !== null && props.serviceSupportingWordlistSelected && (
        <>
          <WordlistSection
            name={props.wordlistAssignment.wordlist?.name}
            setShowWordlistModal={setShowWordlistModal}
            wordlistAssignment={props.wordlistAssignment}
          />
          <WordlistModal
            name={props.wordlistAssignment.wordlist?.name}
            onClose={() => setShowWordlistModal(false)}
            readOnly={true}
            resourceType={props.wordlistAssignment.resourceType}
            show={showWordlistModal}
            words={props.wordlistAssignment.wordlist?.words}
          />
        </>
      )}
    </>
  );
}

BatchSelection.propTypes = {
  batchName: PropTypes.string,
  batchId: PropTypes.string,
  serviceSupportingWordlistSelected: PropTypes.bool,
  setBatchError: PropTypes.func,
  setBatchId: PropTypes.func,
  setBatchName: PropTypes.func,
  wordlistAssignment: PropTypes.shape({
    resourceType: PropTypes.string,
    wordlist: PropTypes.shape({
      name: PropTypes.string,
      words: PropTypes.string,
    }),
  }),
};

WordlistSection.propTypes = {
  setShowWordlistModal: PropTypes.func,
  wordlistAssignment: PropTypes.shape({
    resourceType: PropTypes.string,
    wordlist: PropTypes.shape({
      name: PropTypes.string,
      words: PropTypes.string,
    }),
  }),
};

const styles = StyleSheet.create({
  question: {
    fontWeight: 'bold',
    fontSize: '1.25em',
    marginBottom: '0.25em',
  },
  checkboxInput: {
    marginLeft: '0.1rem',
  },
  error: {
    color: '#ff0000',
  },
  inputWidth: {
    maxWidth: '19rem',
  },
});

export default BatchSelection;
