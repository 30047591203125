import { Table } from '@threeplayground/index';
import { use3PQuery } from '~/logic/unstable/use3PQuery';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Pagination } from 'react-bootstrap';

export const ActiveTasksTable = ({ query, refetchInterval = 10000 }) => {
  const [limit] = useState(5);
  const [offset, setOffset] = useState(0);

  const handleNextPage = () => {
    setOffset((prevOffset) => prevOffset + limit);
  };
  const handlePrevPage = () => {
    setOffset((prevOffset) => Math.min(Math.abs(prevOffset - limit)), 0);
  };

  const { data, isLoading, globalError } = use3PQuery(query, {
    variables: { limit, offset, orderBy: ['CREATED_AT_DESC'] },
    extractKey: 'opsTaskInvocations',
    // TODO: loading indicator when we use keepPreviousData
    // TODO: disabling pagination when we're loading
    keepPreviousData: true,
    refetchInterval,
  });

  const tableData = useMemo(
    () => data && data.edges && !globalError && data.edges.map((el) => el.node),
    [data]
  );

  const TABLE_COLUMNS = [
    { header: 'ID', accessor: 'id', cell: 'string' },
    { header: 'Task', accessor: 'opsTask.displayName', cell: 'string' },
    { header: 'Parameters', accessor: 'args', cell: 'string' },
    { header: 'Notes', accessor: 'runNotes', cell: 'string' },
    { header: 'Updated', accessor: 'updatedAt', cell: 'date' },
    { header: 'State', accessor: 'state', cell: 'string' },
    { header: 'Errors', accessor: 'errorNotes', cell: 'string' },
    { header: 'Queue', accessor: 'threeplayResqueJob.queue', cell: 'string' },
    { header: 'Worker', accessor: 'threeplayResqueJob.worker', cell: 'string' },
  ];

  // TODO: Replace with better alert
  if (globalError) return 'Something Went Wrong';
  if (isLoading || !data) return <span className="spinner-border spinner-border-sm mr-1" />;
  return (
    <>
      <Table columns={TABLE_COLUMNS} data={tableData} />
      {/* TODO: Fix display */}
      {data?.pageInfo && (
        <Pagination className="d-flex">
          <Pagination.Prev disabled={!data.pageInfo.hasPreviousPage} onClick={handlePrevPage} />
          <Pagination.Next disabled={!data.pageInfo.hasNextPage} onClick={handleNextPage} />
        </Pagination>
      )}
    </>
  );
};

ActiveTasksTable.propTypes = {
  query: PropTypes.string,
  refetchInterval: PropTypes.number,
};
