import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';
import { OffPlatformJobDetails, JobDetailsRow } from '../../common/OffPlatformJobDetails';
import { DownloadableAsset } from '../../common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';
import { formatTranscriptForDownload } from '../../common/downloadable_asset_cell/helpers';
import { JobLoadError } from '../../common/JobLoadError';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';
import { FileUploader } from '../../common/file_uploads/FileUploader';
import { OffPlatformLocalizationJobInstructions } from './OffPlatformLocalizationJobInstructions';
import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { offPlatformLocalizationJobsPaths } from '../offPlatformLocalizationJobsPaths';
import { DeliveryInstructions } from '../../common/DeliveryInstructions';
import { JobInstructions } from '../../common/JobInstructions';

type ParamType = {
  id: string;
};

export function OffPlatformLocalizationJob() {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, error } = useJobQuery({ where: { idEq: id } });

  if (error) return <JobLoadError />;
  if (isLoading) return null;

  const job = data?.job;
  if (!job || job.__typename !== 'OffPlatformLocalizationJobGraphql') return <JobLoadError />;

  const {
    orderInstructions,
    deliveryInstructions,
    vendorLocalization,
    translationProfile,
    service,
    mediaFile,
  } = job;
  if (service.__typename !== 'LocalizationServiceGraphql') return <JobLoadError />;

  const { sourceLanguage, targetLanguage } = service.localization.localizationOrderOption;

  const getAssets = (): DownloadableAsset[] => {
    const generateUrl = () =>
      offPlatformLocalizationJobsPaths.downloadInputTranscript.getLink(job.id);

    const sourceScripts = formatTranscriptForDownload({
      transcript: vendorLocalization.inputTranscript,
      generateUrl: generateUrl,
    });

    return [
      {
        displayName: 'Source Video',
        url: `/off_platform_localization_jobs/${id}/download_source_video`,
      },
      ...sourceScripts,
    ];
  };

  const localizationJobDetails: JobDetailsRow[] = [
    {
      header: 'Source Language',
      value: sourceLanguage.fullName,
    },
    {
      header: 'Target Language',
      value: targetLanguage.fullName,
    },
  ];

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Localization Job'}>
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails
            job={job}
            downloadableAssets={getAssets()}
            customJobDetails={localizationJobDetails}
          />
          <UploadedFileList files={vendorLocalization.files} />
          <FileUploader
            assetId={vendorLocalization.id}
            assetType={AssetClassNameEnum.VendorLocalization}
            allowedFileFormats={vendorLocalization.allowedFileFormats}
            uploadedFiles={vendorLocalization.files}
          />
        </Col>
        <Col lg={6} className="mt-4">
          <OffPlatformLocalizationJobInstructions translationProfile={translationProfile} />
          {service.reformat && (
            <JobInstructions cheatSheets={orderInstructions} title={'Reformat Instructions'} />
          )}
          <DeliveryInstructions
            cheatSheets={deliveryInstructions}
            shouldDeliver={mediaFile.proofToFinalFinalized}
          />
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
}
