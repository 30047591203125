// This helper provides paths that DO NOT live in any subdomain. Basically sign-in
// routes. Did you mean to add what you're adding to app/, ops/, or jobs/?

// TODO: Move this endpoint into the login_managers_controller as well
export const getOrganizationPath = '/user/password_reset/forgot_organization';

export const emailLoginPath = '/user/sessions/email_login';
export const resetPasswordPath = '/user/login_manager/send_password_reset';
export const updatePasswordPath = '/user/login_manager/update_password';
export const migratePath = '/user/login_manager/migrate';
export const signinPath = '/user/sessions';
export const signoutPath = '/user/sessions/signout';
export const invitedUserCreatePath = '/user/login_manager/invited_user_create';
export const checkSsoPath = '/user/login_manager/validate_sso_org';
