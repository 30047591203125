import React, { ForwardedRef, forwardRef } from 'react';
import { FormControl } from 'react-bootstrap';

import { InputWrapper, InputWrapperProps } from '../_implementation/InputWrapper';

// NOTE: if you update this, make sure you also explicitly pass all props from
// ForwardedNativeTextareaProps to the <FormControl as="textarea" /> below.
// This needs to be destructured from props explicitly as all remaining props
// are forwarded to the InputWrapper.
export type ForwardedNativeTextareaProps =
  | 'autocomplete'
  | 'disabled'
  | 'maxLength'
  | 'minLength'
  | 'placeholder'
  | 'readOnly'
  | 'required'
  | 'spellcheck'
  | 'wrap';

/**
 * `TextareaProps` are a combination of `InputWrapper` props
 * (which control rendering and error handling) and allowed props on the
 * HTMLTextAreaElement from the DOM library.
 *
 * `alt-click` on `HTMLTextAreaElement` and `InputWrapperProps` to see a
 * concrete list of types.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea
 */
export interface TextareaProps
  extends InputWrapperProps<HTMLTextAreaElement>,
    Partial<Pick<HTMLTextAreaElement, ForwardedNativeTextareaProps>> {
  value?: string;
  defaultValue?: string;
}

/**
 * Textarea is a high-level wrapper that hides implementation details of
 * Bootstrap (allowing us to upgrade or even replace it in future). It provides
 * much nicer typing as well, and omits parts of the native/bootstrap props
 * we don't want to use.
 *
 * It provides a consistent interface with `Form`, `Input`, and `Select`
 * by sharing validation, label, and rendering functionality via `InputWrapper`
 * and `ErrorsContext.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea
 * @see https://react-bootstrap-v4.netlify.app/components/forms/
 */
function TextareaInner(props: TextareaProps, ref: ForwardedRef<HTMLTextAreaElement>) {
  const {
    // Props from TextareaProps
    value,
    defaultValue,

    // Props from ForwardedNativeTextareaProps
    autocomplete,
    disabled,
    maxLength,
    minLength,
    placeholder,
    readOnly,
    required,
    spellcheck,
    wrap,

    // Props from Input Wrapper
    ...inputWrapperProps
  } = props;

  return (
    <InputWrapper<HTMLTextAreaElement> {...inputWrapperProps}>
      {(wrapperProps) => (
        <FormControl
          autoComplete={autocomplete}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          spellCheck={spellcheck}
          wrap={wrap}
          {...wrapperProps}
          as="textarea"
          ref={ref}
          value={value}
          defaultValue={defaultValue}
        />
      )}
    </InputWrapper>
  );
}

TextareaInner.displayName = 'Textarea';

/**
 * `Textarea` is a nicely typed overlay on top of native/Bootstrap `<textarea>`.
 *
 * It shares much of the same form handling and native behavior from as `Form`,
 * `Input`, and `Select`; see those components for more examples of usage.
 *
 * If you are using this within a `Form` component, you likely don't need to set
 * much more than `label`, `name`, and whatever presentational props you want.
 * Validation and parsing are automatically handled for you. Prefer using native
 * input props to adjust behavior including `readOnly`, `required`, `disabled`,
 * `maxLength`, `spellcheck`, `wrap`, etc.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea
 * @see https://react-bootstrap-v4.netlify.app/components/forms/
 */
export const Textarea = forwardRef(TextareaInner);
