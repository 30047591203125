{
  "splash:welcome": "Thanks for your interest in being a transcriptionist with 3Play Media!",
  "splash:welcome_benefits": "Work from Home - Earn Weekly Paychecks - Create Your Schedule",
  "splash:button_start": "Start New Transcriptionist Application",
  "splash:button_continue": "Continue Existing Application",
  "splash:about_this_role_title": "ABOUT THE ROLE",
  "splash:about_this_role": "We are seeking eager editors for contract work to transcribe recorded audio & edit imperfect transcription.",
  "splash:benefits_title": "Benefits of editing at 3Play Media:",
  "splash:benefits": [
    "Choose the jobs you want to work on, from a selection of major entertainment, corporate, and education content producers.",
    "As a member of our elite team of editors, you choose where and when you want to work on your claimed jobs.",
    "Receive weekly paychecks based on a per project, per-minute rate for the work you completed in the prior week."
  ],
  "splash:tips_computer": "For the best results, complete the application on a computer.",
  "splash:tips_timer": "We recommend completing the application in one sitting (estimated time is 15 min).",
  "splash:tips_envelope": "You'll receive an email if you passed to the next step.",
  "splash:requirements_title": "To be a good transcriptionist, you need to meet the following qualifications:",
  "splash:requirements": [
    "Excellent written communication skills",
    "Excellent command of English grammar and punctuation",
    "Proficiency with internet research and reference resources",
    "Ability to manage projects and work independently",
    "Must be at least 18 years old"
  ],
  "splash:background_tooltip": "This check will include name, address, SSN, felony convictions (past 7 years), sex offender registry, and terrorist watch list. It will NOT include misdemeanors or credit checks, etc. This background check will be paid for by 3Play Media.",
  "splash:background_bold": "3Play will obtain a background check for all US-based applicants.",
  "splash:background": "Completion of this background check is required prior to starting work with 3Play.",
  "featured_contractor:title": "Featured 3Play Editors",
  "featured_contractor:contractor_title": "Editor at 3Play Media",
  "splash:not_allowed_selling": "Please note, we are only interested in receiving applications from individuals who are interested in performing the services themselves – please do not apply on behalf of another person! We do not permit the \"selling\" or \"subcontracting\" of our contract work.",
  "featured_contractor:beau_s": "I’m a fairly nomadic sort. I’m 25 and enjoy moving from place to place. Currently, I’m in New York City doing my master’s in English at Columbia and this has really given me the flexibility that I need in juggling various responsibilities. Furthermore, it fits my interests. I love getting to be a fly on the wall in places where I’d never ever be in real life. I’m curious about a lot of things and appreciate learning on the job!",
  "featured_contractor:daniel_t": "I have an organic urban farm that supplies restaurants and area farmer’s markets. Sometimes I am incredibly busy and sometimes I am not. Even a regular part-time job absolutely wouldn’t work because it wouldn’t be flexible enough for what I do. 3Play is perfect because I am able to work when I can, and cut back when I can’t. Plus it adds a very nice supplemental income during my off-season when the farm work is bringing in less!",
  "featured_contractor:maria_t": "3Play is a perfect fit for me because I love English, grammar, editing, am extremely detail-oriented, and was looking for work I could do from home. While I was extremely thankful to be hired, I had no idea that I would enjoy the work as much as I do.",
  "featured_contractor:mirissa_s": "I graduated with an English degree and wanted to work with words (specializing in editing, mostly), but also wanted the convenience of a part-time, flexible-hours job while my husband’s internship schedule is so wonky. This job fits in perfectly with our lives because it allows us to travel whenever we need or want to while still picking up jobs here and there. I don’t have to ask for time off when I want to spend a day with my family, but can work whenever I just have a half-hour to spare. The flexibility absolutely cannot be beat.",
  "featured_contractor:nicky_b": "I am a single mother to a beautiful 16 month old boy. 3Play allows me to earn extra income above and beyond my salary so I can afford the best things for my son and can afford to send him to the best childcare center.",
  "test:instructions": "You will be evaluated based on the quality of your transcription, alongside a variety of other factors. Please pay close attention to the instructions, as this is crucial to receiving a passing score. You will be timed on this section, and time will start once you play your first file."
}
