import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import EmailLoginModal from './EmailLoginModal';
import ForgotPasswordModal from './ForgotPasswordModal';

import { signinPath } from '~/helpers/paths';

function BasicAuth(props) {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [shouldDisplayValidation, setShouldDisplayValidation] = useState(false);
  const [showEmailLoginModal, setShowEmailLoginModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  function formData(form) {
    const data = new FormData(form);
    data.append('authenticity_token', props.csrfToken);
    return data;
  }

  function closeForgotPasswordModal(result = null) {
    setShowForgotPasswordModal(false);
    if (result?.status == 'success') {
      setInfo(result);
    } else {
      setError(result);
    }
  }

  function openForgotPasswordModal() {
    setShowForgotPasswordModal(true);
  }

  function closeEmailLoginModal(result = null) {
    setShowEmailLoginModal(false);
    if (result?.status == 'success') {
      setInfo(result);
    } else {
      setError(result);
    }
  }

  function openEmailLoginModal() {
    setShowEmailLoginModal(true);
  }

  async function attemptLogin(form) {
    const data = formData(form);
    const response = await fetch(signinPath, {
      method: 'POST',
      body: data,
    });
    if (!response.ok) {
      setError({
        title: 'Something went wrong',
        message: 'Please try again. Contact support@3playmedia.com if this issue persists.',
      });
      return { ok: false };
    }

    const responseData = await response.json();
    if (!responseData.ok) {
      setError({
        title: 'Invalid login',
        message: 'The credentials you provided were invalid. Please try again.',
      });
      return { ok: false };
    }
    return responseData;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    if (!form.checkValidity()) {
      setShouldDisplayValidation(true);
      return;
    }

    setShouldDisplayValidation(false);
    setFetching(true);
    const response = await attemptLogin(form);
    if (response.ok) {
      if (response.confirmLogin) {
        props.setSidePanel('admin_signon_success');
      }
      window.location.href = response.redirect;
    } else {
      setFetching(false);
    }
  }

  return (
    <div className={css(styles.main)}>
      {error && (
        <Alert variant="danger" dismissible onClose={() => setError(null)}>
          {error.title && (
            <Alert.Heading>
              <FontAwesomeIcon icon="exclamation-triangle" /> {error.title}
            </Alert.Heading>
          )}
          {error.message}
        </Alert>
      )}
      {info && (
        <Alert variant="info" dismissible onClose={() => setInfo(null)}>
          {info.title && <Alert.Heading>{info.title}</Alert.Heading>}
          {info.message}
        </Alert>
      )}

      <Form validated={shouldDisplayValidation} noValidate onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Username or Email</Form.Label>
          <Form.Control
            name="username"
            required
            type="text"
            disabled={fetching}
            placeholder="Username or Email Address"
          />
          <Form.Control.Feedback type="invalid">
            <FontAwesomeIcon icon="exclamation-circle" /> This field cannot be empty
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            required
            type="password"
            disabled={fetching}
            placeholder="Password"
          />
          <Form.Control.Feedback type="invalid">
            <FontAwesomeIcon icon="exclamation-circle" /> This field cannot be empty
          </Form.Control.Feedback>
          <Form.Text>
            <a href="#" onClick={openForgotPasswordModal}>
              Forgot your password?
            </a>
          </Form.Text>
          {props.show_email_login && (
            <Form.Text>
              <a href="#" onClick={openEmailLoginModal}>
                Login via email
              </a>
            </Form.Text>
          )}
        </Form.Group>
        <div className={css(styles.footer)}>
          <Button type="submit" block disabled={fetching}>
            {fetching ? 'Please wait' : 'Log In'}
          </Button>
        </div>
      </Form>
      {props.show_email_login && (
        <EmailLoginModal
          show={showEmailLoginModal}
          csrfToken={props.csrfToken}
          onClose={closeEmailLoginModal}
        />
      )}
      <ForgotPasswordModal
        show={showForgotPasswordModal}
        csrfToken={props.csrfToken}
        onClose={closeForgotPasswordModal}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  main: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  small: {
    fontSize: '0.8rem',
  },
});

BasicAuth.propTypes = {
  csrfToken: PropTypes.string,
  setSidePanel: PropTypes.func,
  show_email_login: PropTypes.bool,
};

export default BasicAuth;
