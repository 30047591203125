import React from 'react';

import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationTriangle,
  faExclamationCircle,
  faCheckCircle,
  faCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
library.add(faExclamationTriangle);
library.add(faExclamationCircle);
library.add(faCheckCircle);
library.add(faCircle);
library.add(faQuestionCircle);

import Logo from '~/core/images/3play-media-main-logo-800x175.png';
import SignOnSuccess from './SignOnSuccess';
import ThemeColors from '~/styles/ThemeColors';
import WhatsNew from './WhatsNew';

import { CookiesProvider } from 'react-cookie';

function Welcome({ children, sidePanel, whatsNew, limitWidth = true }) {
  const backgroundColor = whatsNew?.background_color || ThemeColors.skysTheLimit;

  return (
    <CookiesProvider>
      <div className={css(styles.container)}>
        <div className={css(styles.main)}>
          <div className={css(styles.formContainer, limitWidth && styles.WidthLimited)}>
            <a href="https://www.3playmedia.com" className={css(styles.logoContainer)}>
              <img src={Logo} className={css(styles.logoImage)} />
            </a>
            {children}
          </div>
        </div>
        {sidePanel && (
          <div className={css(styles.sidebar)} style={{ background: backgroundColor }}>
            {sidePanel === 'admin_signon_success' && <SignOnSuccess />}
            {sidePanel === 'whats_new' && <WhatsNew whatsNew={whatsNew} />}
          </div>
        )}
      </div>
    </CookiesProvider>
  );
}

Welcome.propTypes = {
  children: PropTypes.node,
  sidePanel: PropTypes.string,
  whatsNew: PropTypes.object,
  limitWidth: PropTypes.bool,
};

const styles = StyleSheet.create({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  main: {
    height: '100%',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '40%',
  },
  logoContainer: {
    maxWidth: '80%',
    paddingBottom: '3rem',
    marginBottom: '1rem',
  },
  logoImage: {
    maxWidth: '360px',
  },
  WidthLimited: {
    maxWidth: '600px',
  },
  formContainer: {
    position: 'relative',
    minWidth: '400px',
    width: '70%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sidebar: {
    height: '100%',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
  },
});

export default Welcome;
