import { useContext, useCallback } from 'react';
import { FlashMessage, FlashMessageContext } from '../context/FlashMessageContext';

export function useFlashMessage() {
  const context = useContext(FlashMessageContext);
  if (!context) {
    throw new Error('useFlashMessage must be used within a FlashMessageProvider');
  }

  const { messages, setMessages } = context;

  const addFlashMessage = useCallback(
    (variant: FlashMessage['variant'], content: string) => {
      const id = Date.now(); // TODO: Use a real unique identifier in the case of dupes.
      setMessages((prevMessages) => [...prevMessages, { id, variant, content }]);
      setTimeout(() => dismissFlashMessage(id), 5000);
    },
    [setMessages]
  );

  const dismissFlashMessage = useCallback(
    (id: number) => {
      setMessages((prevMessages) => prevMessages.filter((message) => message.id !== id));
    },
    [setMessages]
  );

  return {
    messages,
    addFlashMessage,
    dismissFlashMessage,
  };
}
