import React from 'react';

import PropTypes from 'prop-types';

function SlideToggle(props) {
  return (
    <label className="slide-toggle-outer">
      <input type="checkbox" onChange={props.onChange} checked={props.checked}></input>
      <div className="slide-toggle-knob"></div>
    </label>
  );
}

SlideToggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SlideToggle;
