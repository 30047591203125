import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import * as services from './services/services';

const ERROR_MESSAGE =
  'Something went wrong. Refresh the page and try again. If the problem persist, contact operations.';

const RequestPaybumpForm = ({
  jobId,
  onSuccess = () => {},
  createJobPaybumpRequest = services.createJobPaybumpRequest,
}) => {
  const [note, setNote] = useState('');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isButtonDisabled = note.length < 1 || success || isLoading;

  const handleNote = (e) => {
    e.preventDefault();
    setNote(e.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    createJobPaybumpRequest({ jobId, note })
      .then(({ errors, data }) => {
        if (errors && errors.length > 0) {
          // TODO: launch an airbrake.
          setError(ERROR_MESSAGE);
          return;
        }
        return data;
      })
      .then(({ createJobPaybumpRequest: { success, errors } }) => {
        if (!success || errors?.length < 0) {
          // TODO: granular error messages based on error returned
          setError(ERROR_MESSAGE);
          return;
        }

        setSuccess(true);
        onSuccess();
      })
      .catch(() => {
        // TODO: launch an airbrake
        setError(ERROR_MESSAGE);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {success && <Alert variant="success">Paybump request successfully submitted!</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        <Form.Group controlId="paybumpRequestNote">
          <Form.Text>
            Paybumps exist to bridge the gap between anticipated difficulty and actual difficulty of
            a file to help compensate for these extreme experiences.
            <br />
            <br />
            When the following common indicators are incongruous with the pay rate, it may be an
            underpaid outlier job.
            <ul>
              <li>Difficult to decipher audio for much/all of the file</li>
              <li>Files that require a lot of research</li>
              <li>A lot of music lyrics to transcribe by hand </li>
            </ul>
          </Form.Text>
          <Form.Label>Why would you like to request a paybump?*</Form.Label>
          <Form.Control
            aria-required="true"
            required
            as="textarea"
            rows={3}
            value={note}
            onChange={(e) => handleNote(e)}
          />
        </Form.Group>
        <Button onClick={handleSubmit} disabled={isButtonDisabled}>
          Submit
        </Button>
      </Form>
    </>
  );
};

RequestPaybumpForm.propTypes = {
  jobId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
  createJobPaybumpRequest: PropTypes.func,
};

export default RequestPaybumpForm;
