import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Logo from '~/core/images/3play-media-main-logo-800x175.png';
import Alert from 'react-bootstrap/Alert';
import LoginManagerForm from './LoginManagerForm';
import LegalNotice from './LegalNotice';

import { invitedUserCreatePath, updatePasswordPath, migratePath } from '~/helpers/paths';
import { userLogger } from '~/logic/UserLogger';

function LoginManager(props) {
  let submitPath = '';

  switch (props.loginTask) {
    case 'NewUser':
      submitPath = invitedUserCreatePath;
      break;
    case 'PasswordReset':
      submitPath = updatePasswordPath;
      break;
    case 'MigrateUser':
      submitPath = migratePath;
  }

  useEffect(() => {
    userLogger.logEvent('Login', 'Login Manager visited', { login_task: props.loginTask });
  }, []);

  return (
    <div className={css(styles.canvas)}>
      <div className={css(styles.main)}>
        <a href="https://www.3playmedia.com" className={css(styles.logoContainer)}>
          <img src={Logo} className={css(styles.logoImage)} />
        </a>
        {props.loginTask == 'NewUser' && (
          <Alert variant="primary" className="w-100">
            <Alert.Heading>Welcome!</Alert.Heading>
            <p>Hello {props.name}, welcome to 3Play Media!</p>
            <p className="mb-0">Choose a password and username to create an account</p>
          </Alert>
        )}
        {props.loginTask == 'MigrateUser' && (
          <Alert variant="warning">
            <Alert.Heading>Reset Your Password</Alert.Heading>
            <p>
              For your safety and protection, please create a new username and password that meet
              our security requirements. You will be prompted to use this new username and password
              to log in moving forward.
            </p>
          </Alert>
        )}
        {props.loginTask == 'PasswordReset' && (
          <Alert variant="warning" className="w-100">
            <Alert.Heading>Reset Your Password</Alert.Heading>
            <p>
              For your safety and protection, please create a new password that meets our security
              requirements.
            </p>
          </Alert>
        )}

        <LoginManagerForm
          csrfToken={props.csrfToken}
          email={props.email}
          perishableToken={props.perishableToken}
          submitPath={submitPath}
          loginTask={props.loginTask}
        />
        <LegalNotice />
      </div>
    </div>
  );
}

LoginManager.propTypes = {
  csrfToken: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  perishableToken: PropTypes.string,
  loginTask: PropTypes.oneOf(['NewUser', 'PasswordReset', 'MigrateUser']),
};

const styles = StyleSheet.create({
  canvas: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  main: {
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  logoContainer: {
    maxWidth: '80%',
    paddingBottom: '3rem',
  },
  logoImage: {
    maxWidth: '360px',
  },
});

export default LoginManager;
