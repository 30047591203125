import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { Card, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { appStyles } from '~/components/app/common/styles';
import { ProjectContext } from '~/components/app/order_form/OrderForm';
import { selectedServicesType } from '~/components/app/order_form/propTypes';
import { DUBBING_START_INDEX } from '~/helpers/constants';
import { canOrderDubbingAudioEmbed, validQuoteUrl } from '~/components/app/order_helpers/dubbing';

const HumanDubbingOrderOptions = ({
  cheatSheetComponent,
  dubbingOrderOptions,
  selectedServices,
  setValidDubbingQuote,
  updateOrderOptions,
  validDubbingQuote,
}) => {
  const dubbingOrderData =
    selectedServices.find((service) => service.serviceType === 'Dubbing')?.orderOptions || {};
  const selectedDubbingOrderOptions = dubbingOrderData?.selectedDubbingOptions || [];
  const { accountType } = useContext(ProjectContext);
  const isEmbeddingChecked = dubbingOrderData['embedding'];

  const toggleCheckbox = () => {
    updateOrderOptions('Dubbing', { ...dubbingOrderData, embedding: !isEmbeddingChecked });
  };

  const validateQuoteUrl = (url) => {
    if (!validQuoteUrl(url)) {
      setValidDubbingQuote(false);
      updateOrderOptions('Dubbing', { ...dubbingOrderData, quoteUrl: url });
      return false;
    }
    setValidDubbingQuote(true);
    updateOrderOptions('Dubbing', { ...dubbingOrderData, quoteUrl: url });
    return true;
  };

  return (
    <>
      <Card.Title className={css(appStyles.title)}>Select Languages</Card.Title>
      <Card.Subtitle className="mb-2 text-muted">
        Select languages that you want a dubbed audio track for:
      </Card.Subtitle>
      <Typeahead
        clearButton
        id="dubbing-language-dropdown"
        multiple={true}
        onChange={(selectedDubbingOptions) =>
          updateOrderOptions('Dubbing', { ...dubbingOrderData, selectedDubbingOptions })
        }
        options={dubbingOrderOptions}
        labelKey={(item) => item.targetLanguage.fullName}
        placeholder="Search Languages"
        selected={selectedDubbingOrderOptions}
      />
      <Form.Group>
        <Card.Title className={css(appStyles.title)}>Link Dubbing Quote</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Dubbing Quote URL:</Card.Subtitle>
        <Form.Control
          id="dubbingQuote"
          isInvalid={dubbingOrderData?.quoteUrl && !validDubbingQuote}
          onChange={(e) => validateQuoteUrl(e.target.value)}
          tabIndex={DUBBING_START_INDEX}
          type="text"
          value={dubbingOrderData?.quoteUrl || ''}
        />
        <Form.Text className="mb-2 text-muted">
          By linking your Dubbing Quote, you agree to the prices quoted based on the information
          provided in the Dubbing Checklist.
        </Form.Text>
      </Form.Group>
      {cheatSheetComponent('Dubbings::Human::DubbingService')}
      {canOrderDubbingAudioEmbed(accountType.studio) && (
        <Form.Check
          id="dubbingAudioEmbed"
          aria-checked={isEmbeddingChecked}
          defaultChecked={isEmbeddingChecked}
          label="I need an embedded dubbing asset"
          onClick={toggleCheckbox}
          tabIndex={DUBBING_START_INDEX + 1}
          type="checkbox"
        />
      )}
    </>
  );
};

HumanDubbingOrderOptions.propTypes = {
  cheatSheetComponent: PropTypes.func,
  dubbingOrderOptions: PropTypes.arrayOf(PropTypes.any),
  selectedServices: selectedServicesType,
  setValidDubbingQuote: PropTypes.func,
  updateOrderOptions: PropTypes.func,
  validDubbingQuote: PropTypes.bool,
};

export default HumanDubbingOrderOptions;
