export default {
  bigInt: (props, propName, componentName) => {
    if (/^\d+$/.test(props[propName])) {
      return;
    }

    return new Error(
      `Invalid prop ${propName} supplied to ${componentName}. Expected: BigInt string.`
    );
  },
};
