import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import { NextButton } from '../shared/ApplicationButtons';
import * as workflow from '../../applicationWorkflow';
import PleaseWait from '../PleaseWait';
import { PAGES } from '../constants';
import { continueApplicationErrorsToPageRedirect } from '../helpers';
import { getGrecaptchaSiteKey } from '../../meta';

const waitForGrecaptcha = () =>
  new Promise((resolve) => {
    window.grecaptcha.ready(() => {
      resolve();
    });
  });

const grecaptchaToken = async () => {
  await waitForGrecaptcha();
  const grecaptchaToken = await window.grecaptcha.execute(getGrecaptchaSiteKey(), {
    action: 'submit',
  });
  return grecaptchaToken;
};

const ContinueApplication = ({
  emailAddress = '',
  continueApplication = workflow.continueApplication,
  nextStep = () => {},
  redirectPage = () => {},
  getGrecaptchaToken = grecaptchaToken,
}) => {
  const [email, setEmail] = useState(emailAddress);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [canContinue, setCanContinue] = useState(true);

  const handleChange = (e) => {
    setError('');
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    setError('');
    const formError = workflow.validateEmail(email);
    if (formError) {
      setError(formError);
      return;
    }

    setIsLoading(true);
    const grecaptchaToken = await getGrecaptchaToken();

    continueApplication({ grecaptchaToken, email }).then((response) => {
      setIsLoading(false);
      const { success, error, authToken } = response?.data?.continueApplication
        ? response.data.continueApplication
        : {};

      const path = continueApplicationErrorsToPageRedirect(error);
      if (path) {
        redirectPage(path);
      }

      if (error === 'not_found') {
        setCanContinue(false);
        return;
      }

      if (error || !success) {
        setError('Something went wrong! Try again later.');
        return;
      }

      nextStep({ authToken, email });
    });
  };

  const handleStartNewApplication = (event) => {
    event.preventDefault();
    redirectPage(PAGES.NEW_APPLICATION);
  };

  return (
    <>
      {isLoading && <PleaseWait />}
      <h1>Welcome back! Let&apos;s continue with your editor application.</h1>
      <p>
        Enter the email address you previously used to continue your application or check the status
        of an existing application.
      </p>

      <Form.Group controlId="email">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          type="email"
          value={email}
          onChange={handleChange}
          className={error && 'is-invalid'}
        />
        {error && <Form.Text className="text-danger">{error}</Form.Text>}
        {!canContinue && (
          <Form.Text className="text-muted">
            This email address is not associated with any existing applications.{' '}
            <a href="" size="small" variant="link" onClick={handleStartNewApplication}>
              Start a New Application.
            </a>
          </Form.Text>
        )}
      </Form.Group>
      <NextButton onClick={handleSubmit} disabled={isLoading && canContinue} />
    </>
  );
};

ContinueApplication.propTypes = {
  emailAddress: PropTypes.string,
  continueApplication: PropTypes.func,
  nextStep: PropTypes.func,
  redirectPage: PropTypes.func,
  getGrecaptchaToken: PropTypes.func,
};

export default ContinueApplication;
