import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import TranscriptActions from './TranscriptActions';
import TranscriptBody from './TranscriptBody';

import { Button } from 'react-bootstrap';

import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';
import WithIcon from '~/components/app/common/WithIcon';
import CollapsiblePanel from '~/components/app/common/CollapsiblePanel';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { userLogger } from '~/logic/UserLogger';
import { transcriptQuery } from './data/queries';

function Transcript(props) {
  const [fetching, setFetching] = useState(false);
  const [transcript, setTranscript] = useState(null);
  const [transcriptList, setTranscriptList] = useState(null);
  const [showFlags, setShowFlags] = useState(true);
  const tagPattern = /(\[\?.+?\?\]|\[INAUDIBLE\])/;
  const displayTranscript = useMemo(
    () =>
      transcript &&
      computeDisplayTranscript(transcript.formattedTranscript, transcript.language.iso6391Code, {
        showFlags,
      }),
    [showFlags, transcript]
  );

  useEffect(() => {
    setFetching(true);
    setTranscript(null);
    setTranscriptList(null);
    // transcriptId can be null (fetches default transcript)
    if (props.fileId) {
      threeplayApi
        .request(transcriptQuery, { fileId: props.fileId, transcriptId: props.transcriptId })
        .then((res) => {
          const data = res.data || {};
          const file = data.file || {};
          setTranscript(file.transcript);
          file.transcript && props.updateTranscript(file.transcript);
          setTranscriptList(file.transcriptList);
          setFetching(false);
        });
    }
  }, [props.fileId, props.transcriptId]);

  function toggleFlags() {
    setShowFlags((prev) => !prev);
  }

  function computeDisplayTranscript(formattedTranscript, languageCode, { showFlags }) {
    return formattedTranscript
      .split('\n')
      .map((paragraph, ii) => getParagraphNode(paragraph, languageCode, { showFlags, key: ii }));
  }

  function getParagraphNode(paragraph, languageCode, { showFlags, key }) {
    if (showFlags) {
      return (
        <p key={key} lang={languageCode}>
          {paragraph.split(tagPattern).map((text, ii) => {
            const highlight = tagPattern.test(text);
            const spanClass = highlight ? 'label ' + css(styles.labelWarning) : null;
            return (
              <span key={ii} className={spanClass}>
                {text}
              </span>
            );
          })}
        </p>
      );
    } else {
      return (
        <p key={key} lang={languageCode}>
          {paragraph}
        </p>
      );
    }
  }

  const getActionItems = (props, transcript) => {
    const actionItems = [];
    if (!transcript) {
      return actionItems;
    }

    if (transcript?.editUrl.enabled) {
      actionItems.push(<TranscriptEditButton {...props} transcript={transcript} />);
    }

    return actionItems;
  };

  return (
    <div className={css(styles.main)}>
      <CollapsiblePanel
        title="Transcript"
        type="transcript"
        open={props.open}
        setOpen={props.setOpen}
        actionItems={
          <>
            {getActionItems(props, transcript).map((ai, index) => (
              <div key={index}>{ai}</div>
            ))}
          </>
        }
      >
        <TranscriptActions
          contentPublishing={props.contentPublishing}
          enabledFeatures={props.enabledFeatures}
          fileId={props.fileId}
          projectId={props.projectId}
          setShowModal={props.setShowModal}
          showFlags={showFlags}
          transcript={transcript}
          transcriptList={transcriptList}
          toggleFlags={toggleFlags}
          updateTranscript={props.updateTranscript}
        />
        <TranscriptBody
          fetching={fetching}
          transcript={transcript}
          displayTranscript={displayTranscript}
        />
      </CollapsiblePanel>
    </div>
  );
}

Transcript.propTypes = {
  captionStyling: PropTypes.bool,
  contentPublishing: PropTypes.bool,
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  fileId: PropTypes.string,
  open: PropTypes.bool,
  projectId: PropTypes.string,
  reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
  setOpen: PropTypes.func,
  setShowModal: PropTypes.shape({
    preview: PropTypes.func,
    quickLink: PropTypes.func,
  }),
  transcriptId: PropTypes.string,
  updateTranscript: PropTypes.func,
};

const TranscriptEditButton = (props) => {
  const setErrors = () => {
    if (disabled) {
      return props.reprocessingAssets.map((asset) => `${asset}s are still processing`);
    }

    if (props.captionStyling) {
      return ['The Edit Transcript tool is unavailable when Caption Styling is ordered.'];
    }

    return [];
  };

  const disabled = props.reprocessingAssets?.length > 0 || false;
  const errors = setErrors();

  return (
    <TooltipIfErrors title="Editing unavailable:" errors={errors}>
      <Button
        size="sm"
        className={
          ((disabled || props.captionStyling) && css(styles.disabled, styles.editButton)) ||
          css(styles.editButton)
        }
        href={props.captionStyling ? null : props.transcript.editUrl.url}
        variant="primary"
        onClick={() => userLogger.logEvent('FileShow', 'Edit Transcript')}
        target="_blank"
        rel="noopener"
      >
        <WithIcon icon="fa fa-pencil">Edit</WithIcon>
      </Button>
    </TooltipIfErrors>
  );
};

TranscriptEditButton.propTypes = {
  captionStyling: PropTypes.bool,
  reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
  setShowModal: PropTypes.shape({
    preview: PropTypes.func,
    quickLink: PropTypes.func,
  }),
  transcript: PropTypes.shape({
    editUrl: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

const RevisionButton = (props) => {
  const disabled = props.transcript.revisions?.length > 0 || false;
  const setErrors = () => {
    if (disabled) {
      return [
        'You have already requested a revision on this service, please contact 3Play Support for assistance.',
      ];
    }

    return [];
  };
  const errors = setErrors();

  return (
    <TooltipIfErrors title="Revision unavailable:" errors={errors}>
      <Button
        size="sm"
        className={css(styles.editButton)}
        href={props.transcript.revisionUrl.url}
        variant="primary"
        onClick={() => userLogger.logEvent('FileShow', 'Revise')}
        target="_blank"
        disabled={disabled}
        rel="noopener"
      >
        <WithIcon icon="fa fa-pencil">Revise</WithIcon>
      </Button>
    </TooltipIfErrors>
  );
};

RevisionButton.propTypes = {
  transcript: PropTypes.shape({
    revisionUrl: PropTypes.shape({
      url: PropTypes.string,
    }),
    revisions: PropTypes.arrayOf(PropTypes.number),
  }),
};

const styles = StyleSheet.create({
  main: {
    width: '100%',
  },
  editTranscriptButton: {
    float: 'right',
    marginRight: '13px',
  },
  editButton: {
    marginRight: '14px',
    marginTop: '-4px',
  },
  labelWarning: {
    backgroundColor: '#f89406',
  },
  disabled: {
    pointerEvents: 'none',
  },
});

export default Transcript;
