import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { userLogger } from '~/logic/UserLogger';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const URL_VALIDATOR =
  /^(?:https?\:\/\/)?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(?:\??)[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~]+)$/gi;
const VIMEO_URL_CHECKER = /^(https?:\/\/)?(\w+\.)?vimeo\./gi;

function ErrorComponent(props) {
  return (
    <Alert variant="warning">
      <strong>Note:</strong> The following submissions are not URLs or cannot be found. Please make
      sure your links point to a valid web address with an audio or video file and try again.
      <ul className="text-dark">
        {props.invalidUrls.map((url, index) => {
          return <li key={index}>{url}</li>;
        })}
      </ul>
    </Alert>
  );
}

function VimeoErrorComponent() {
  return (
    <div className="text-danger">
      ERROR: Vimeo links are not valid video sources. Please use our{' '}
      <a
        href="https://support.3playmedia.com/hc/en-us/sections/206152728-Vimeo-Integration"
        rel="noreferrer noopener"
        target="_blank"
      >
        Vimeo Integration
      </a>
    </div>
  );
}

function LinksModal(props) {
  const [urls, setUrls] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [showVimeoError, setShowVimeoError] = useState(false);
  const [incorrectUrls, setIncorrectUrls] = useState([]);

  function addLinksToOrder() {
    setShowVimeoError(false);
    const { invalidUrls, validUrls } = validateAndGenerateURLlist();
    if (invalidUrls.length > 0) {
      setShowErrors(true);
      setIncorrectUrls(invalidUrls);
    } else if (validUrls.length > 0) {
      setShowErrors(false);
      props.setFilesToUpload(validUrls);
      props.closeLinksModal();
      userLogger.logEvent('NewOrder', 'Start Upload', {
        'File Source': 'Links',
        'File count': validUrls.length,
      });
    }
  }

  function validateAndGenerateURLlist() {
    // Removes leading and trailing whitespaces and splits on new lines
    const urlList = urls.replace(/^\s+|\s+$/, '').split(/[\r\n]+/);
    const validUrls = [];
    const invalidUrls = [];
    urlList.forEach((url) => {
      url = url.trim();
      if (url.match(VIMEO_URL_CHECKER)) {
        setShowVimeoError(true);
        invalidUrls.push(url);
      } else if (url.match(URL_VALIDATOR)) {
        validUrls.push({
          name: url.split('/').slice(-1)[0] || url,
          sourceUrl: url,
          words: props.words,
        });
      } else {
        setShowErrors(true);
        invalidUrls.push(url);
      }
    });
    return {
      invalidUrls,
      validUrls,
    };
  }

  return (
    <Modal show={props.show} size="lg" onHide={() => props.closeLinksModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Add Files from Links</Modal.Title>
      </Modal.Header>
      <Modal.Body data-testid="modalBody">
        {showErrors && <ErrorComponent invalidUrls={incorrectUrls} />}
        {showVimeoError && <VimeoErrorComponent />}
        {!showErrors && !showVimeoError && (
          <>
            <p className="mb-0">
              Add audio files or videos from other websites by copying the URLs into the text box
              below. To add multiple links, add each link on a separate line.
            </p>
            <a
              href="https://support.3playmedia.com/entries/21271136-how-to-upload-files-that-are-hosted-on-a-public-server-by-using-links"
              rel="noreferrer noopener"
              target="_blank"
            >
              Learn More
            </a>
          </>
        )}
        <Form.Group>
          <Form.Control
            aria-label="Upload links"
            as="textarea"
            className="mt-2 text-dark"
            onChange={(e) => setUrls(e.target.value)}
            placeholder="https://www.mywebsite.com/myvideo.mp4"
            rows="10"
            value={urls}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.closeLinksModal()}>
          Cancel
        </Button>
        <Button disabled={urls === ''} onClick={() => addLinksToOrder()} variant="primary">
          Add Files
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ErrorComponent.propTypes = {
  invalidUrls: PropTypes.array,
};

LinksModal.propTypes = {
  closeLinksModal: PropTypes.func,
  setFilesToUpload: PropTypes.func,
  show: PropTypes.bool,
  words: PropTypes.string,
};

export default LinksModal;
