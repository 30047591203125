import React, { useState } from 'react';

import Login from './Login';
import Welcome from './Welcome';
function LoginPage(props) {
  const [sidePanel, setSidePanel] = useState('whats_new');

  return (
    <Welcome {...props} sidePanel={sidePanel}>
      <Login {...props} setSidePanel={setSidePanel} />
    </Welcome>
  );
}

export default LoginPage;
