import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import { Card, OverlayTrigger, Popover, PopoverContent } from 'react-bootstrap';

import { ProjectContext } from '~/components/app/order_form/OrderForm';
import { embedOptionsType } from '~/components/app/order_form/propTypes';
import selectedStyles from '../styles/selectedDeselectedStyles';
import {
  AD_AUDIO_MIX_START_INDEX,
  AUDIO_MIX_CODE_ICON_MAP,
  ENTER_KEY_CODE,
  SPACE_KEY_CODE,
} from '~/helpers/constants';

/*
 Used with AudioDescription.
 Populates a given orderOptions object with the following key
  {
    ...existingData,
    outputAssetOptions: {
      fiveOne: undefined, // State when the checkbox is not selected.
      stereo: { // State when the checkbox is selected, but no sub-options are.
        name: "Stereo",
        price: 0,
        code: "stereo",
        embedSelections: [],
      }
      mono: { // State when the checkbox is selected, and various sub-options are. embedSelections must not be empty to be valid.
        name: "Mono",
        price: 25,
        code: "mono",
        embedSelections: [{code: "embedded", name: "Video Embedded"}],
      }
    }
  }
*/

const AudioDescriptionMixSection = ({ orderOptions, updateOrderOptions }) => {
  const isNonStandard = orderOptions.serviceLevel.name !== 'Standard';
  const { mixingOrderOptions } = useContext(ProjectContext);
  const audioEmbedAssetOptions = mixingOrderOptions.embed;
  const audioMixAssetOptions = mixingOrderOptions.mix;

  return (
    <>
      <Card.Title className={css(appStyles.title)}>
        Desired Output Asset{' '}
        <OverlayTrigger
          placement="auto"
          trigger={['hover', 'focus']}
          delay={{ hide: 2000 }}
          overlay={
            <Popover>
              <PopoverContent>
                The final file type you need for your Audio Description service.
              </PopoverContent>
            </Popover>
          }
        >
          <i className="fa fa-info-circle"></i>
        </OverlayTrigger>
      </Card.Title>
      <span className="d-flex">
        {audioMixAssetOptions?.map((audioMixAsset, index) => {
          if (isNonStandard && !audioMixAsset.validForExtended) {
            return;
          }
          return (
            <AudioDescriptionSelection
              audioEmbedAssetOptions={audioEmbedAssetOptions}
              code={audioMixAsset.code}
              index={index * 5 + AD_AUDIO_MIX_START_INDEX}
              key={audioMixAsset.code}
              name={audioMixAsset.adDisplayName}
              price={audioMixAsset.price}
              orderOptions={orderOptions}
              updateOrderOptions={updateOrderOptions}
              validForExtended={audioMixAsset.validForExtended}
            />
          );
        })}
      </span>
    </>
  );
};

AudioDescriptionMixSection.propTypes = {
  orderOptions: PropTypes.object,
  updateOrderOptions: PropTypes.func,
};

const AudioDescriptionSelection = ({
  audioEmbedAssetOptions,
  code,
  index,
  name,
  orderOptions,
  price,
  updateOrderOptions,
  validForExtended,
}) => {
  const isMixSelected = orderOptions.outputAssetOptions?.[code] !== undefined;
  const toggleMixSelection = () => {
    updateOrderOptions('AudioDescription', {
      ...orderOptions,
      outputAssetOptions: {
        ...orderOptions.outputAssetOptions,
        [code]: isMixSelected
          ? undefined
          : {
              code,
              name,
              price,
              validForExtended,
              embedSelections: [],
            },
      },
    });
  };

  const checkKeyPress = (event) => {
    const eventCode = event.charCode || event.keyCode;
    if (eventCode === ENTER_KEY_CODE || eventCode === SPACE_KEY_CODE) {
      toggleMixSelection();
    }
  };

  const updateEmbedSelections = (
    currentAudioOption,
    isAssetSelected,
    embedSelections,
    audioEmbedOption
  ) => {
    updateOrderOptions('AudioDescription', {
      ...orderOptions,
      outputAssetOptions: {
        ...orderOptions.outputAssetOptions,
        [code]: {
          ...currentAudioOption,
          embedSelections: isAssetSelected
            ? embedSelections.filter((e) => e.code !== audioEmbedOption.code)
            : [
                ...embedSelections,
                {
                  code: audioEmbedOption.code,
                  name: audioEmbedOption.adDisplayName,
                },
              ],
        },
      },
    });
  };

  return (
    <span className="d-inline-block mr-4">
      <Card
        bg="light"
        data-testid={code}
        tabIndex={index}
        onClick={toggleMixSelection}
        onKeyDown={(e) => checkKeyPress(e)}
        className={isMixSelected ? css(selectedStyles.selected) : css(selectedStyles.deselected)}
      >
        <Card.Body className={css(styles.audioMixAsset)}>
          <img src={AUDIO_MIX_CODE_ICON_MAP[code]} alt="" />
          <div className={css(styles.audioMixAssetName)}>{name}</div>
        </Card.Body>
      </Card>
      {isMixSelected && (
        <>
          <p style={{ fontSize: '12px' }}>
            <b>What type of assets do you need?</b>
          </p>
          <div>
            {audioEmbedAssetOptions.map((audioEmbedOption, assetIndex) => {
              const currentAudioOption = orderOptions.outputAssetOptions?.[code];
              const embedSelections = currentAudioOption?.embedSelections;
              const isAssetSelected = embedSelections?.some(
                (e) => e.code === audioEmbedOption.code
              );
              return (
                <div
                  key={audioEmbedOption.code}
                  style={{ paddingBottom: '4px', paddingLeft: '8px' }}
                >
                  <input
                    id={`${code}-${audioEmbedOption.code}`}
                    tabIndex={index + assetIndex + 1}
                    type="checkbox"
                    checked={isAssetSelected}
                    onChange={() =>
                      updateEmbedSelections(
                        currentAudioOption,
                        isAssetSelected,
                        embedSelections,
                        audioEmbedOption
                      )
                    }
                  />
                  <label
                    style={{ paddingLeft: '8px' }}
                    htmlFor={`${code}-${audioEmbedOption.code}`}
                  >
                    {audioEmbedOption.adDisplayName}
                  </label>
                </div>
              );
            })}
          </div>
        </>
      )}
    </span>
  );
};

AudioDescriptionSelection.propTypes = {
  audioEmbedAssetOptions: embedOptionsType,
  code: PropTypes.string,
  index: PropTypes.string,
  name: PropTypes.string,
  orderOptions: PropTypes.object,
  price: PropTypes.string,
  updateOrderOptions: PropTypes.func,
  validForExtended: PropTypes.bool,
};

const styles = StyleSheet.create({
  audioMixAsset: {
    'text-align': 'center',
  },
  audioMixAssetName: {
    'font-size': '1.1rem',
    'font-weight': 'bold',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
});

export default AudioDescriptionMixSection;
