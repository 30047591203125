import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const ErrorMessage = () => (
  <p>
    <b>
      {
        "We couldn't fetch your referral code! Refresh the page to try again. Email operations if this problem persists."
      }
    </b>
  </p>
);

const ReferralCode = ({ token = '' }) => {
  return token ? (
    <>
      <div className={css(styles.token)}>
        <h3>
          <b>This is your personal referral code:</b>
        </h3>
        <InputGroup>
          <Form.Control onChange={() => {}} value={token}></Form.Control>
        </InputGroup>
      </div>
      <p>
        Tell your friends to enter this code during the preliminary application process so that you
        can get rewarded and we’ll take a look at their application more quickly!
      </p>
    </>
  ) : (
    <ErrorMessage />
  );
};

ReferralCode.propTypes = {
  token: PropTypes.string,
};

const styles = StyleSheet.create({
  token: {
    width: 'fit-content',
    paddingBottom: '10px',
  },
});

export default ReferralCode;
