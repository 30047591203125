import React from 'react';
import PropTypes from 'prop-types';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function RejectionNote(props) {
  if (props.rejections.length !== 1) {
    return (
      <div>
        Some of your orders have been rejected. Please reach out to 3Play support if you have any
        questions.
      </div>
    );
  }

  const rejection = props.rejections[0];

  if (!rejection.reason || rejection.reason === 'Other') {
    return (
      <div>
        Your order has been rejected. Please reach out to 3Play support if you have any questions.
      </div>
    );
  }

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip {...props}>
          {rejection.explanation}
          {rejection.suggestion}
        </Tooltip>
      }
    >
      <div>
        Your order has been rejected for {rejection.reason}. Please reach out to 3Play support if
        you have any questions.
      </div>
    </OverlayTrigger>
  );
}

RejectionNote.propTypes = {
  rejections: PropTypes.arrayOf(
    PropTypes.shape({
      reason: PropTypes.string,
      explanation: PropTypes.string,
      suggestion: PropTypes.string,
    })
  ).isRequired,
};

export default RejectionNote;
