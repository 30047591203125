import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';

import InputErrorList from '~/components/app/common/InputErrorList';

import { threeplayApi } from '~/logic/ThreeplayApi';

function tagsQuery(fileId) {
  return `tags(fileId: ${fileId}) { id name marked }`;
}

function manageTagsMutation(fileId, tags) {
  return `manageTags(
    fileId: ${fileId}, tags: [${tags}]
  ) { errors, file { id } }`;
}

function ManageTagsModal(props) {
  const [tags, setTags] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [errors, setErrors] = useState([]);

  function handleClose() {
    props.onClose();
  }

  function handleCheckboxClick(e) {
    setTags(
      tags.map((tag) => (tag.id === e.target.id ? { ...tag, marked: e.target.checked } : tag))
    );
  }

  useEffect(() => {
    if (!props.show) {
      return;
    }
    // Don't fetch tags if we've already fetched them
    if (tags) {
      return;
    }

    let cancelled = false;
    async function fetchTags() {
      const response = await threeplayApi.query(tagsQuery(props.fileId), 'ManageTags');
      if (cancelled) {
        return;
      }
      setTags(response.data.tags);
    }

    fetchTags();
    return () => {
      cancelled = true;
    };
  }, [props.show]);

  async function updateTags() {
    setUpdating(true);
    const tags_array = tags.filter((tag) => tag.marked === true).map((tag) => tag.id);
    const response = await threeplayApi.mutate(manageTagsMutation(props.fileId, tags_array));
    if (response.data.manageTags) {
      const { errors } = response.data.manageTags;
      if (errors > 0) {
        setErrors(errors);
        setUpdating(false);
        return;
      }

      setUpdating(false);
      setSuccessAlert(true);
      setTimeout(() => {
        handleClose(), setSuccessAlert(false);
      }, 1000);
    } else {
      setErrors(errors);
      setUpdating(false);
      return;
    }
  }

  function successAlert() {
    return <Alert variant="success">Success! Tags have been updated for the media file</Alert>;
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Tags</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'scroll', maxHeight: '60vh' }} data-testid="modalBody">
        {tags === null ? (
          <p>Loading...</p>
        ) : tags.length === 0 ? (
          <p>Your project has no tags</p>
        ) : (
          <React.Fragment>
            {showSuccessAlert && successAlert()}
            <Form>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th className={css(styles.table)}></th>
                    <th>Tag name</th>
                  </tr>
                </thead>
                <tbody>
                  {tags.map((tag) => (
                    <tr key={tag.id}>
                      <td>
                        <Form.Check
                          id={tag.id}
                          data-testid={tag.id}
                          name={tag.name}
                          checked={tag.marked}
                          onChange={(e) => handleCheckboxClick(e)}
                        />
                      </td>
                      <td>
                        <span>{tag.name}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form>
            {errors && <InputErrorList errors={errors} />}
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={updateTags} disabled={updating}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ManageTagsModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  fileId: PropTypes.string,
};

const styles = StyleSheet.create({
  table: {
    width: '10px',
  },
});

export default ManageTagsModal;
