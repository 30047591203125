import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Modal from 'react-bootstrap/Modal';

import { quickLinkUrl, quickLinkADUrl } from '~/helpers/app/paths';
import { transcriptListSpec } from '~/components/app/media_files/ShowPanel/data/propspec';

function QuickLinkModal(props) {
  return (
    <>
      {props.format && (
        <Modal show={props.show} onHide={props.onClose}>
          <Modal.Header closeButton>
            <Modal.Title>Publish Link ({props.format.name})</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {props.transcriptList &&
              props.transcriptList.map((transcript) => (
                <React.Fragment key={transcript.id}>
                  <h5>{transcript.language.fullName}</h5>
                  <a
                    className={css(styles.wordWrap)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      props.format && props.format.inputType === 'Description'
                        ? quickLinkADUrl(
                            props.projectId,
                            props.fileId,
                            props.format.id,
                            props.format.httpFormat
                          )
                        : quickLinkUrl(
                            props.fileId,
                            transcript.id,
                            props.format.id,
                            props.projectId,
                            props.format.httpFormat
                          )
                    }
                  >
                    {props.format && props.format.inputType === 'Description'
                      ? quickLinkADUrl(
                          props.projectId,
                          props.fileId,
                          props.format.id,
                          props.format.httpFormat
                        )
                      : quickLinkUrl(
                          props.fileId,
                          transcript.id,
                          props.format.id,
                          props.projectId,
                          props.format.httpFormat
                        )}
                  </a>
                </React.Fragment>
              ))}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

QuickLinkModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  fileId: PropTypes.string,
  format: PropTypes.object,
  transcriptList: transcriptListSpec,
  projectId: PropTypes.string,
};

const styles = StyleSheet.create({
  wordWrap: {
    'word-wrap': 'break-word',
  },
});

export default QuickLinkModal;
