import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import ReferralCode from './ReferralCode';
import ReferralStatus from './ReferralStatus';
import * as services from './services/services';

const ReferralsPage = ({ getReferralCodes = services.getReferralCodes }) => {
  const [error, setError] = useState('');
  const [referralCode, setReferralCode] = useState(null);

  useEffect(() => {
    getReferralCodes()
      .then(({ errors, data }) => {
        if (errors || !data) {
          const message = errors ? JSON.stringify(errors) : 'no data returned';
          setError(message);
        }

        return data;
      })
      .then(({ referralCodes }) => {
        // TODO: only fetch the one active referral code so that we don't assume we're grabbing the first inquiry
        const data = referralCodes?.[0];
        setReferralCode(data);
      });
  }, []);

  return (
    <>
      <h1>Referrals</h1>
      <hr />
      {error && (
        <p>
          {
            "We couldn't fetch your referral code! Refresh the page to try again. Email operations if this problem persists."
          }
        </p>
      )}
      {referralCode && (
        <>
          {/* 
            target hours and payout live on each specific contractor_referral.  
            rather than flattening that data on the front end (and have to handle any variances), 
            we're hardcoding the values for now. 
         */}
          <h2>Enjoy working for 3Play?</h2>
          <p>
            Refer a friend to join the 3Play Media team and you’ll be credited <b>$50</b> to your
            account once they reach <b>10</b> hours of captioning with us!
          </p>

          <div className={css(styles.padding)}>
            <ReferralCode token={referralCode.token} />
          </div>
          <ReferralStatus
            referralLimit={referralCode.referralLimit}
            referrals={referralCode.referrals}
          />
        </>
      )}
    </>
  );
};

ReferralsPage.propTypes = {
  getReferralCodes: PropTypes.func,
};

const styles = StyleSheet.create({
  padding: {
    paddingBottom: '40px',
  },
});

export default ReferralsPage;
