import React from 'react';
import { Card } from '@threeplayground/unstable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { DownloadableAssetCell } from './downloadable_asset_cell/OffPlatformDownloadableAssetCell';

interface JobInstructionInterface {
  cheatSheets: CheatSheet[] | null;
  title: string;
  placeholder?: string;
}

export type CheatSheet = {
  id: string;
  content: string | null;
  presignedDownloadUrl: string | null;
  resource: Resource;
};

type Resource = {
  __typename: string;
};

function InstructionBlock({ sheet }: { sheet: CheatSheet }) {
  const { presignedDownloadUrl, resource, content } = sheet;
  const resource_name = resource.__typename.split('Graphql')[0];
  return (
    <>
      <h3>{resource_name} Instructions</h3>
      {presignedDownloadUrl && (
        <DownloadableAssetCell assets={[{ url: presignedDownloadUrl, displayName: 'pdf' }]} />
      )}
      <div style={{ whiteSpace: 'pre-line' }}>{content}</div>
    </>
  );
}

export function JobInstructions({ cheatSheets, title, placeholder }: JobInstructionInterface) {
  return (
    <Card>
      <Card.Header className="font-weight-bold">
        <FontAwesomeIcon icon={faExclamationCircle} /> {title}
      </Card.Header>
      <Card.Body>
        {cheatSheets?.map((sheet) => (
          <InstructionBlock sheet={sheet} key={sheet.id} />
        ))}
        {placeholder && (!cheatSheets || cheatSheets.length === 0) && <p>{placeholder}</p>}
      </Card.Body>
    </Card>
  );
}
