.blockCheck + .blockCheck {
  margin-top: 10px;
}

.labelHeader {
  font-weight: 500;
}

/* force this to be at least 12px; rather than the 11.2 that is computed by bootstrap */
.helpText {
  font-size: 0.75rem;
}

.learnMore {
  display: block;
  text-align: right;
  margin-top: 4px;
  font-size: 0.75rem;
}
