import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Form } from 'react-bootstrap';
import { StyleSheet, css } from 'aphrodite';

function BetaTermsNotice({ betaTermsDisplayDate, betaTermsAccepted, setBetaTermsAccepted }) {
  const displayDate = (date) => {
    return date.toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <Alert className="mt-2" variant="warning">
      <Alert.Heading>Beta Terms of Service</Alert.Heading>
      <p>
        <em>Last updated {displayDate(betaTermsDisplayDate)}</em>
      </p>
      <p>
        By checking the box below, you indicate that you agree to our{' '}
        <Alert.Link
          className={css(styles.link)}
          target="_blank"
          href="https://www.3playmedia.com/beta-agreement/"
        >
          Beta Terms of Service
        </Alert.Link>
        .
      </p>
      <Form.Group controlId="betaServicesAcceptance">
        <Form.Check
          checked={betaTermsAccepted}
          className="d-inline-block"
          onChange={() => setBetaTermsAccepted((curr) => !curr)}
          type="checkbox"
        />
        I have read and accept the 3Play Beta Terms of Service
      </Form.Group>
    </Alert>
  );
}

const styles = StyleSheet.create({
  link: {
    color: '#1a5a73',
  },
});

BetaTermsNotice.propTypes = {
  betaTermsDisplayDate: PropTypes.instanceOf(Date),
  betaTermsAccepted: PropTypes.bool,
  setBetaTermsAccepted: PropTypes.func,
};

export default BetaTermsNotice;
