export function timeToMs(duration) {
  const minutes = parseInt(duration.split(':')[0]);
  const seconds = parseInt(duration.split(':')[1].split('.')[0]);
  let msec = duration.split(':')[1].split('.')[1];
  if (msec) {
    msec = parseInt(msec);
  }

  msec += minutes * 60000;
  msec += seconds * 1000;

  return msec;
}
