import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

function MoveButton(props) {
  return (
    <Button size="sm" onClick={props.onClick} variant="light">
      <i className="fa fa-folder-open"></i>
      Move
    </Button>
  );
}

MoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MoveButton;
