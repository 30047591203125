import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import VoiceWritingApplication from './VoiceWritingApplication';
import { PAGES } from '../constants';
import Thanks from '../../submitted_states/Thanks';
import NotAcceptingLocation from '../../submitted_states/NotAcceptingLocation';
import AlreadySubmitted from '../../submitted_states/AlreadySubmitted';
import PleaseEmailOps from '../../submitted_states/PleaseEmailOps';
import { getReferrerParams } from '~/components/contractor_applications/application/helpers.js';
import { logReferrer } from '~/components/contractor_applications/application/applicationWorkflow.js';

const APPLICATION_PAGE = 'application';

const VoiceWritingApplicationPage = (props) => {
  const [email, setEmail] = useState('');
  const [page, setPage] = useState(APPLICATION_PAGE);

  useEffect(() => {
    logReferrer({ referrerParams, jobTypeId: props.jobTypeId });
  }, []);

  const referrerParams = useMemo(() => getReferrerParams(), []);

  const handleRedirect = (error) => {
    setPage(error);
  };

  const handleApplicationSubmission = (applicantEmail) => {
    setEmail(applicantEmail);
    setPage(PAGES.SUCCESSFULLY_SUBMITTED_APPLICATION);
  };

  return (
    <>
      {(() => {
        switch (page) {
          case PAGES.NOT_ACCEPTING_LOCATION:
            return <NotAcceptingLocation />;
          case PAGES.ALREADY_SUBMITTED_APPLICATION:
            return <AlreadySubmitted />;
          case PAGES.SUCCESSFULLY_SUBMITTED_APPLICATION:
            return <Thanks email={email} />;
          case PAGES.USER_EMAIL_EXISTS:
            return <PleaseEmailOps />;
          case APPLICATION_PAGE:
            return (
              <VoiceWritingApplication
                {...props}
                redirectPage={handleRedirect}
                onSuccessfulSubmission={handleApplicationSubmission}
                referrerParams={referrerParams}
              />
            );
        }
      })()}
    </>
  );
};

VoiceWritingApplicationPage.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string.isRequired),
  usStates: PropTypes.arrayOf(PropTypes.string.isRequired),
  jobTypeId: PropTypes.number.isRequired,
};

export default VoiceWritingApplicationPage;
