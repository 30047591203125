import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Image from 'react-bootstrap/Image';
import NerdBo from './images/bobadge.png';

const ChallengeBadge = ({ badgePath = NerdBo }) => (
  <div className={css(styles.challengeBadge)}>
    <Image src={badgePath} roundedCircle fluid />
  </div>
);

ChallengeBadge.propTypes = {
  badgePath: PropTypes.string,
};

const styles = StyleSheet.create({
  challengeBadge: {
    backgroundColor: '#fbebc3',
    padding: '5px',
    border: '5px solid #007eb5',
    borderRadius: '50%',
  },
});

export default ChallengeBadge;
