import React from 'react';
import PropTypes from 'prop-types';

import ServiceCard from './service_cards/ServiceCard';

import { SERVICE_TYPES, isServiceCancellable } from '~/logic/services';

function OrderedServices(props) {
  const serviceGroups = props.serviceGroups || [];

  function setServiceOpen(serviceType, open) {
    props.setServicesOpen((prev) => ({ ...prev, [serviceType]: open }));
  }

  return (
    <>
      {serviceGroups.map((collection) => {
        // collection.name should be defined in SERVICE_TYPES
        const serviceKey = SERVICE_TYPES[collection.name];
        const cancellable = isServiceCancellable(
          collection.name,
          collection.services,
          serviceGroups
        );

        return (
          <ServiceCard
            enabledFeatures={props.enabledFeatures}
            fileData={props.fileData}
            isInternalUser={props.isInternalUser}
            isManagedAccount={props.isManagedAccount}
            key={collection.name}
            onUpdate={props.onUpdate}
            open={props.servicesOpen && props.servicesOpen[serviceKey]}
            serviceGroup={collection}
            cancellable={cancellable}
            setErrorsAlert={props.setErrorsAlert}
            setOpen={(open) => setServiceOpen(serviceKey, open)}
            setShowModal={props.setShowModal}
            setSuccess={props.setSuccess}
            showGetItNow={props.showGetItNow}
            showProgressBar={props.showProgressBar}
            userTimeZone={props.userTimeZone}
          />
        );
      })}
    </>
  );
}

OrderedServices.propTypes = {
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  fileData: PropTypes.shape({
    id: PropTypes.string,
    reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
  }),
  isInternalUser: PropTypes.bool,
  isManagedAccount: PropTypes.bool,
  serviceGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      status: PropTypes.string,
      services: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  refreshToken: PropTypes.string,
  servicesOpen: PropTypes.object,
  setServicesOpen: PropTypes.func,
  setShowModal: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  setErrorsAlert: PropTypes.func,
  setSuccess: PropTypes.func,
  showGetItNow: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  userTimeZone: PropTypes.string,
};

export default OrderedServices;
