import React from 'react';
import PropTypes from 'prop-types';

const NaiveDisplayTracker = ({ progress = 0, targetUnit }) => {
  return (
    <div>
      <h5>Track Your Progress</h5>
      <p>{`You've completed ${progress} ${targetUnit}!`}</p>
    </div>
  );
};

NaiveDisplayTracker.propTypes = {
  progress: PropTypes.number,
  targetUnit: PropTypes.string.isRequired,
};

export default NaiveDisplayTracker;
