.dropdownItemName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdownUrl {
  font-size: 0.75rem;
  color: #4a525b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

*:active .dropdownUrl,
:global(.disabled) .dropdownUrl {
  color: inherit;
}

.advancedSettings {
  display: flex;
  justify-content: end;
  margin-top: 4px;
  font-size: 0.75rem;
}

.dropdownItem {
  max-width: 400px;
  overflow: hidden;
}
