/**
 * WARNING: Consider this function UNSTABLE and as such the API may change, use at your own risk
 */
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { ThreeplayAPIContext } from './ThreeplayApiProvider';

/**
 * Call a GraphQL query
 *
 * @async
 * @function use3PQuery
 * @param {string} queryString - The GraphQL query string
 * @param {object} options - A place to pass configuration options; Note options unknown to use3PQuery will be passed to useQuery.
 * @param {object} options.variables - A place to pass configuration options; Note options unknown to use3PQuery will be passed to useQuery.
 * @param {string} options.extractKey - The name of the key to extract from the response object.  This will always be the mutation name.  If you chose this value for your cacheKey, you can omit it and we'll use that as the default
 * @returns {Promise<data|errors>} The data returned back from the API or an errors array.
 */

const USE_QUERY_DEFAULTS = {
  cacheTime: 0,
  staleTime: 0,
  refetchOnWindowFocus: false,
};

export const use3PQuery = (queryString, { variables = {}, extractKey, ...useQueryOptions }) => {
  const client = useContext(ThreeplayAPIContext);

  if (client === undefined) {
    throw new Error('use3PQuery must be used within a ThreeplayAPIProvider');
  }

  return useQuery(
    [queryString, variables],
    () => client.request(queryString, variables, extractKey),
    {
      ...USE_QUERY_DEFAULTS,
      ...useQueryOptions,
    }
  );
};
