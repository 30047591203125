import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Card, Form } from 'react-bootstrap';

import { appStyles } from '~/components/app/common/styles';

function TranslationTypeSelector(props) {
  return (
    <>
      <Card.Title className={css(appStyles.title)}>
        What type of translation do you need?&nbsp;
      </Card.Title>
      <Form.Control
        as="select"
        className={css(styles.selector)}
        onChange={(e) => props.setTranslationType(e.target.value)}
        value={props.selectedTranslationType}
      >
        {props.translationTypes.map((type) => (
          <option value={type.displayName} key={type.displayName}>
            {type.displayName}
          </option>
        ))}
      </Form.Control>
    </>
  );
}

TranslationTypeSelector.propTypes = {
  translationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codeName: PropTypes.string,
      displayName: PropTypes.string,
    })
  ),
  selectedTranslationType: PropTypes.string,
  setTranslationType: PropTypes.func,
};

const styles = StyleSheet.create({
  stepTitle: {
    'font-weight': 'bold',
    display: 'block',
  },
  selector: {
    width: '25rem',
    display: 'inline-block',
  },
});
export default TranslationTypeSelector;
