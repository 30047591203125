import React from 'react';

import { ListView } from '@threeplayground/unstable';

import { pineapplePaths } from './pineapplePaths';

const QUERY = `
query pineapples($where:PineappleWhereInput, $orderBy: [PineappleOrderByEnum!], $limit:Int!, $offset:Int!) {
  pineapples(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
    edges {
      node {
        createdAt
        id
        leaves
        name
        sku
        updatedAt
        userId
        variety {
          displayName
        }
        weight
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      resultCount
    }
  }
}
`;

const TABLE_COLUMNS = [
  { header: 'ID', accessor: 'id', cell: 'number' },
  { header: 'Name', accessor: 'name', cell: 'string' },
  { header: 'Variety', accessor: 'variety.displayName', cell: 'string' },
  { header: 'Weight', accessor: 'weight', cell: 'number' },
  { header: 'Created At', accessor: 'createdAt', cell: 'date' },
  { header: 'Updated At', accessor: 'updatedAt', cell: 'date' },
  {
    header: 'Created By',
    accessor: 'userId',
    cell: 'link',
    cellProps: ({ userId }) => ({ href: `/some/path/to/user/${userId}`, linkText: userId }),
  },
  { header: 'Leaves', accessor: 'leaves', cell: 'string' },
  { header: 'SKU', accessor: 'sku', cell: 'string' },
  {
    header: 'Details',
    accessor: 'detailsLink',
    cell: 'link',
    cellProps: (rowData) => ({
      href: pineapplePaths.show.getLink(rowData.id),
      linkText: 'Details',
    }),
  },
];

export function PineappleList() {
  return (
    <ListView
      columns={TABLE_COLUMNS}
      createLink={pineapplePaths.create.route}
      resourceName="pineapple"
      query={QUERY}
      showCreateLink={true} // should be based off of user permissions
    />
  );
}
