import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import AlertMessage from '~/components/app/common/AlertMessage';

function DeleteInProgressEventModal(props) {
  return (
    <Modal show={props.show} onHide={() => props.closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>End your captions now?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.success && <AlertMessage message={props.success} variant="success" />}
        {props.error && <AlertMessage message={props.error} variant="danger" />}
        <p>
          Ending captions now will stop sending captions for your live event,{' '}
          <b>{props.eventName}</b>. If the data flow for your stream depends on this event, your
          final live-streamed output may be disrupted as well.
        </p>
        <p>It can take up to sixty seconds for this process to take effect.</p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-flex-end">
        <div>
          <Button className="mr-2" variant="outline-dark" onClick={() => props.closeModal()}>
            Cancel
          </Button>
          <Button
            disabled={props.buttonDisabled}
            onClick={() => props.endInProgressEvent()}
            variant="danger"
          >
            End Captions Now
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

DeleteInProgressEventModal.propTypes = {
  buttonDisabled: PropTypes.bool,
  closeModal: PropTypes.func,
  endInProgressEvent: PropTypes.func,
  error: PropTypes.string,
  eventName: PropTypes.string,
  show: PropTypes.bool,
  success: PropTypes.string,
};

export default DeleteInProgressEventModal;
