import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Pagination, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';

import { use3PQuery } from '~/logic/unstable/use3PQuery';

import { Page } from '../Page/Page';
import { Table } from '../Table/Table';

export const ListView = ({ columns, createLink, query, resourceName, showCreateLink = false }) => {
  const [limit] = useState(3);
  const [offset, setOffset] = useState(0);

  const capitalizedResourceName = capitalize(resourceName);

  // TODO: Pull out to ThreeplayAPI Pagination helpers
  const handleNextPage = () => {
    setOffset((prevOffset) => prevOffset + limit);
  };
  const handlePrevPage = () => {
    setOffset((prevOffset) => Math.min(Math.abs(prevOffset - limit)), 0);
  };

  const { data, isLoading } = use3PQuery(query, {
    variables: { limit, offset },
    extractKey: 'pineapples',
  });

  const tableData = useMemo(() => data && data.edges.map((el) => el.node), [data]);

  return (
    <Page fullWidth={false}>
      <Page.Title>{`${capitalizedResourceName} List`}</Page.Title>
      <Page.Body>
        {showCreateLink && (
          <Row>
            <Col>
              <Link to={createLink}>{`Create ${capitalizedResourceName}`}</Link>
            </Col>
          </Row>
        )}
        {/* TODO: Replace with Loading Component */}
        {isLoading && <span className="spinner-border spinner-border-sm mr-1" />}
        {!isLoading && (
          <>
            {/* TODO: Empty State (ideally handled at the table level) */}
            <Row className="py-2">
              {data && (
                <Col>
                  <Table columns={columns} data={tableData} />
                </Col>
              )}
            </Row>
            {data?.pageInfo && (
              // TODO: Pull into wrapped Pagination Component
              <Pagination>
                <Pagination.Prev
                  disabled={!data.pageInfo.hasPreviousPage}
                  onClick={handlePrevPage}
                />
                <Pagination.Next disabled={!data.pageInfo.hasNextPage} onClick={handleNextPage} />
              </Pagination>
            )}
          </>
        )}
      </Page.Body>
    </Page>
  );
};

ListView.propTypes = {
  columns: Table.propTypes.columns,
  createLink: PropTypes.string,
  query: PropTypes.string,
  resourceName: PropTypes.string,
  showCreateLink: PropTypes.bool,
};
