import React from 'react';
import PropTypes from 'prop-types';

import { formatNumber } from './utils/utils';

const secondsToMinutes = (input) => formatNumber(input / 60);
const secondsToHours = (input) => formatNumber(input / (60 * 60));

const minutesCopy = (progress, target) => {
  const total = secondsToMinutes(target);
  const completed = progress < target ? secondsToMinutes(progress) : total;
  const isOneMinute = total === 1;

  return isOneMinute
    ? `${completed} of ${total} minute edited`
    : `${completed} of ${total} minutes edited`;
};

const hoursCopy = (progress, target) => {
  const total = secondsToHours(target);
  const completed = progress < target ? secondsToHours(progress) : total;
  const isOneHour = total === 1;

  return isOneHour
    ? `${completed} of ${total} hour edited`
    : `${completed} of ${total} hours edited`;
};

const getCopy = (progress, target) => {
  const isHours = target >= 60 * 60;
  return isHours ? hoursCopy(progress, target) : minutesCopy(progress, target);
};

const EditingTimeTracker = ({ progress = 0, target = 0 }) => {
  return <p>{getCopy(progress, target)}</p>;
};

EditingTimeTracker.propTypes = {
  progress: PropTypes.number,
  target: PropTypes.number,
};

export default EditingTimeTracker;
