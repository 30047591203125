import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { transcriptSpec } from './data/propspec';

function TranscriptBody(props) {
  if (props.fetching) {
    return 'Loading...';
  } else if (!props.transcript) {
    return (
      <p className={css(styles.transcript, styles.transcriptMessage)}>Transcript unavailable</p>
    );
  }

  return (
    <React.Fragment>
      <div className={css(styles.transcript)} id="transcript_preview_container">
        {props.transcript.wordCount === 0 ? (
          <p className={css(styles.transcriptMessage)}>This transcript is empty</p>
        ) : (
          props.displayTranscript
        )}
      </div>
      <div className={css(styles.footer)}>
        Last Edited by {props.transcript.lastEditedBy} on {props.transcript.lastEditedAt}
        <span className={css(styles.wordCount)}>{props.transcript.wordCount} Words</span>
      </div>
    </React.Fragment>
  );
}

TranscriptBody.propTypes = {
  fetching: PropTypes.bool,
  transcript: transcriptSpec,
  toggleFlags: PropTypes.func,
  displayTranscript: PropTypes.arrayOf(PropTypes.node),
};

const styles = StyleSheet.create({
  transcript: {
    padding: '4px 13px',
  },
  transcriptMessage: {
    color: '#aaa',
    width: '100%',
    textAlign: 'center',
    margin: '1em 0em',
  },
  footer: {
    width: '100%',
    padding: '4px 13px',
    backgroundColor: '#F3F3F3',
    position: 'sticky',
    bottom: '0px',
    color: '#333333',
    display: 'absolute',
  },
  wordCount: {
    float: 'right',
  },
});

export default TranscriptBody;
