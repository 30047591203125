import React, { useMemo } from 'react';

import { Card, Alert, Button } from 'react-bootstrap';
import {
  useStudioFilesDownloadsQuery,
  StudioFilesDownloadsQuery,
  TranscriptionService,
  LocalizationService,
  Language,
  AudioMixingService,
  BroadcastScriptingService,
  DescriptiveTranscriptService,
  DubbingService,
  VendorTranscriptionChooseForMeService,
  VideoEmbeddingService,
} from '~/api/appApi';

interface DownloadableAsset {
  format: AssetFileFormat;
  fileName?: string | null;
  url: string;
}

interface AssetFileFormat {
  extension: string;
  displayName: string;
}

interface StudioFileDownloadsProps {
  mediaFileId: string;
}

const formatAssets = (data: StudioFilesDownloadsQuery | undefined): DownloadableAsset[] => {
  if (!data?.file?.services) {
    return [];
  }
  const availableDownloads: DownloadableAsset[] = [];
  const mediaFileId: string = data?.file?.id;
  data?.file?.services.map((s) => {
    if (s.type === 'AudioMixes::AudioMixingService') {
      (s as AudioMixingService).mixedAudioAsset?.files?.map((f) => {
        const fmt: AssetFileFormat = f.assetFileFormat;
        fmt.displayName = fmt?.displayName?.split(' ')[1] || '';
        availableDownloads.push({
          format: f.assetFileFormat,
          fileName: data.file?.name,
          url: `/downloads/studio/${mediaFileId}/audio_asset/${f.id}`,
        });
      });
    }
    if (s.type === 'Dubbings::Human::DubbingService') {
      (s as DubbingService).dubAudioAsset?.files?.map((f) => {
        const fmt: AssetFileFormat = f.assetFileFormat;
        fmt.displayName = fmt?.displayName?.split(' ')[1] || '';
        availableDownloads.push({
          format: f.assetFileFormat,
          fileName: data.file?.name,
          url: `/downloads/studio/${mediaFileId}/audio_asset/${f.id}`,
        });
      });
    }
    if (s.type === 'DescriptiveTranscriptions::DescriptiveTranscriptService') {
      (s as DescriptiveTranscriptService).descriptiveTranscript?.files?.map((f) => {
        const fmt: AssetFileFormat = f.assetFileFormat;
        fmt.displayName = fmt?.displayName?.split(' ')[1] || '';
        availableDownloads.push({
          format: f.assetFileFormat,
          fileName: data.file?.name,
          url: `/downloads/studio/${mediaFileId}/descriptive_transcripts/${f.id}`,
        });
      });
    }
    if (
      s.type === 'TranscriptionService' ||
      s.type === 'Transcriptions::Revisions::CaptionRevisionService'
    ) {
      (s as TranscriptionService).qualityControlCaption?.files?.map((f) => {
        const fmt: AssetFileFormat = f.assetFileFormat;
        fmt.displayName = fmt?.displayName?.split(' ')[1] || '';
        availableDownloads.push({
          format: f.assetFileFormat,
          fileName: data.file?.name,
          url: `/downloads/studio/${mediaFileId}/captions/${f.id}`,
        });
      });
    }
    if (
      s.type ===
      'Transcriptions::VendorTranscriptionChooseForMes::VendorTranscriptionChooseForMeService'
    ) {
      (s as VendorTranscriptionChooseForMeService).vendorTranscriptionCaption?.files?.map((f) => {
        const fmt: AssetFileFormat = f.assetFileFormat;
        fmt.displayName = fmt?.displayName?.split(' ')[1] || '';
        availableDownloads.push({
          format: f.assetFileFormat,
          fileName: data.file?.name,
          url: `/downloads/studio/${mediaFileId}/vendor_transcriptions/${f.id}`,
        });
      });
    }
    if (s.type === 'Transcriptions::Localizations::LocalizationService') {
      const lang: Language = (s as LocalizationService).targetLanguage;
      (s as LocalizationService).vendorLocalization?.files?.map((f) => {
        const fmt: AssetFileFormat = f.assetFileFormat;
        fmt.displayName = lang.fullName;
        availableDownloads.push({
          format: f.assetFileFormat,
          fileName: data.file?.name,
          url: `/downloads/studio/${mediaFileId}/localization/${f.id}`,
        });
      });
    }
    if (
      s.type === 'BroadcastScriptings::BroadcastScriptingService' ||
      s.type === 'BroadcastScriptings::Revisions::BroadcastScriptingRevisionService'
    ) {
      (s as BroadcastScriptingService).broadcastScript?.files?.map((f) => {
        const fmt: AssetFileFormat = f.assetFileFormat;
        fmt.displayName = fmt?.displayName?.split(' ')[1] || '';
        availableDownloads.push({
          format: f.assetFileFormat,
          fileName: data.file?.name,
          url: `/downloads/studio/${mediaFileId}/broadcastscripts/${f.id}`,
        });
      });
    }
    if (
      s.type === 'VideoEmbeds::VideoEmbeddingService' ||
      s.type === 'CaptionEmbeddings::CaptionEmbeddingService'
    ) {
      (s as VideoEmbeddingService).videoAsset?.files?.map((f) => {
        const fmt: AssetFileFormat = f.assetFileFormat;
        fmt.displayName = fmt?.displayName?.split(' ')[1] || '';
        availableDownloads.push({
          format: f.assetFileFormat,
          fileName: data.file?.name,
          url: `/downloads/studio/${mediaFileId}/video_asset/${f.id}`,
        });
      });
    }
  });
  return availableDownloads;
};

export const StudioFileDownloads = ({ mediaFileId }: StudioFileDownloadsProps) => {
  const { data, isLoading, isError } = useStudioFilesDownloadsQuery({
    mediaFileId: mediaFileId,
  });
  const assetList = useMemo(() => formatAssets(data), [data]);

  if (isError) {
    return <Alert>Error getting downloads for this file.</Alert>;
  }

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <Card>
      <Card.Header as="h2" className="pull-left">
        Downloadable Formats
      </Card.Header>
      <Card.Body>
        {assetList.length ? (
          assetList.map((asset) => (
            <Button key={asset.format.extension} variant="link" href={asset.url}>
              <i className="fa fa-download"></i> {asset.format.displayName}
            </Button>
          ))
        ) : (
          <h6>There are no completed caption formats for this file.</h6>
        )}
      </Card.Body>
    </Card>
  );
};
