import { Filters, Page } from '@threeplayground/unstable';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ActiveTasksTable } from './ActiveTasksTable';
import { OpsTasksTable } from './OpsTasksTable';

const OPS_TASK_INVOCATIONS = `
query opsTaskInvocations($where: OpsTaskInvocationWhereInput, $limit: Int!, $offset: Int!, $orderBy: [OpsTaskInvocationOrderByEnum!] ) {
  opsTaskInvocations(where: $where, limit: $limit, offset: $offset, orderBy: $orderBy) {
    edges {
      node {
        id
        args
        runNotes
        opsTask {
          displayName
        }
        threeplayResqueJob {
          queue
          worker
        }
        updatedAt
        state
        errorNotes
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

const OPS_TASKS = `
query opsTasks($where: OpsTaskWhereInput, $limit: Int!, $offset: Int! ) {
  opsTasks(where: $where, limit: $limit, offset: $offset) {
    edges {
      node {
        id
        typicalRunTimeInMinutes
        notes
        description
        displayName
        updatedAt
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

export const OpsTasksPage = () => {
  const [where, setWhere] = useState({});

  return (
    <Page fullWidth={true}>
      <Page.Title>Ops Tasks</Page.Title>
      <Page.Body>
        <Row>
          <Col className="mb-2">
            <h3>Active Tasks</h3>
            <ActiveTasksTable query={OPS_TASK_INVOCATIONS} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Available Tasks</h3>
            <Filters
              items={[
                {
                  type: 'SEARCH',
                  label: 'Search',
                  filter: 'search',
                },
              ]}
              onChange={setWhere}
            />
            <OpsTasksTable query={OPS_TASKS} where={where} />
          </Col>
        </Row>
      </Page.Body>
    </Page>
  );
};
export default OpsTasksPage;
