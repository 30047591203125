class ThreeplayApi {
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  request(query, variables) {
    return fetch(this.endpoint, {
      body: JSON.stringify({
        query,
        variables: JSON.stringify(variables),
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getCsrfToken(),
      },
    });
  }

  json(query, variables) {
    return this.request(query, variables).then((r) => r.json());
  }

  getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }
}

export const threeplayApi = new ThreeplayApi('/apply/data');

export default ThreeplayApi;
