import React from 'react';
import { Alert } from '~/packages/threeplayground';
import { useFlashMessage } from './hooks/useFlashMessage';

const containerStyle: React.CSSProperties = {
  position: 'fixed',
  top: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
  width: '80%',
  maxWidth: '600px',
};

export function FlashMessageContainer(): JSX.Element {
  const { messages, dismissFlashMessage } = useFlashMessage();

  return (
    <div style={containerStyle}>
      {messages.map(({ id, variant, content }) => (
        <Alert key={id} variant={variant} onClose={() => dismissFlashMessage(id)} dismissible mb-1>
          {content}
        </Alert>
      ))}
    </div>
  );
}
