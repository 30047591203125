const setAccountOutputFormatEnabledMutation = `mutation SetAccountOutputFormatEnabled($aof: AccountOutputFormatInput!){
    setAccountOutputFormatEnabled(accountOutputFormat: $aof){
      errors,
      success
    }
  }
`;

const setAdvancedOutputFormatsMutation = `mutation SetAdvancedOutputFormatsMutation($input: AccountAdvancedOutputFormatsInput!){
  setAdvancedOutputFormats(input: $input){
    errors,
    success
  }
}
`;
export { setAccountOutputFormatEnabledMutation, setAdvancedOutputFormatsMutation };
