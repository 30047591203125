import { OutputFormatCodeEnum } from '../common/constants';

export const offPlatformBroadcastScriptingJobsPaths = {
  show: {
    getLink: (id: string) => `/off_platform_broadcast_scripting_jobs/${id}`,
    route: '/off_platform_broadcast_scripting_jobs/:id',
  },
  downloadInputTranscript: {
    getLink: (id: string, outputFormatCode: OutputFormatCodeEnum) =>
      `/off_platform_broadcast_scripting_jobs/download_input_transcript?id=${id}&output_format=${outputFormatCode}`,
  },
};
