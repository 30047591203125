import React from 'react';

import { StyleSheet, css } from 'aphrodite';

function LegalNotice() {
  return (
    <div className={css(styles.legal)}>
      By signing in, you agree to 3Play Media&apos;s{' '}
      <a
        href="https://www.3playmedia.com/terms/"
        target="_blank"
        rel="noopener noreferrer"
        className={css(styles.legalLink)}
      >
        Terms of Service
      </a>
      ,{' '}
      <a
        href="https://www.3playmedia.com/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
        className={css(styles.legalLink)}
      >
        Privacy Policy
      </a>
      , and{' '}
      <a
        href="https://www.3playmedia.com/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
        className={css(styles.legalLink)}
      >
        Cookie Policy
      </a>
      .
    </div>
  );
}

const styles = StyleSheet.create({
  legal: {
    fontSize: '0.8em',
    marginTop: '1rem',
  },
  legalLink: {
    color: 'inherit',
    fontWeight: 'bold',
  },
});

export default LegalNotice;
