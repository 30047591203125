import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import WithIcon from '../../common/WithIcon';

const EditableFileName = ({ file, setFileName }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div>
      {isEditing ? (
        <input
          autoFocus
          className={css(styles.editInput)}
          onBlur={() => setIsEditing(false)}
          onChange={(e) => setFileName(e.target.value)}
          type="text"
          value={file.name}
        />
      ) : (
        <div onClick={() => setIsEditing(true)}>
          <WithIcon icon="fa fa-pencil">
            <span>{file.name}</span>
          </WithIcon>
        </div>
      )}
    </div>
  );
};

EditableFileName.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  setFileName: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  editInput: {
    width: '100%',
  },
});

export default EditableFileName;
