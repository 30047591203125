import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ProjectContext } from '~/components/app/order_form/OrderForm';
import { selectedServicesType } from '~/components/app/order_form/propTypes';
import OrderOptionsContainer from '~/components/app/order_form/serviceOptions/OrderOptionsContainer';

const DescriptiveTranscriptOrderOptions = ({
  cheatSheetComponent,
  selectedServices,
  sourceLanguage,
  updateOrderOptions,
}) => {
  const { descriptiveTranscriptPrices } = useContext(ProjectContext);
  const descriptiveTranscriptOrderOptions =
    selectedServices.find((service) => service.serviceType === 'DescriptiveTranscript')
      ?.orderOptions || {};
  useEffect(() => {
    updateOrderOptions('DescriptiveTranscript', {
      ...descriptiveTranscriptOrderOptions,
      price: {
        amount: descriptiveTranscriptPrices[sourceLanguage.ids[0]],
        unit: 'file',
      },
    });
  }, []);

  return (
    <OrderOptionsContainer
      title="Descriptive Transcript"
      componentName="DescriptiveTranscriptOrderOptions"
    >
      {cheatSheetComponent('DescriptiveTranscriptions::DescriptiveTranscriptService')}
    </OrderOptionsContainer>
  );
};

DescriptiveTranscriptOrderOptions.propTypes = {
  cheatSheetComponent: PropTypes.func,
  selectedServices: selectedServicesType,
  sourceLanguage: PropTypes.arrayOf(PropTypes.number),
  updateOrderOptions: PropTypes.func,
};

export default DescriptiveTranscriptOrderOptions;
