export const submitBasicInformationMutation = `mutation StartApplication(
  $email: String!
  $firstname: String!
  $lastname: String!
  $country: String!
  $region: String!
  $jobTypeId: Int
  $referrerParams: ReferrerParamsInput
) {
  startApplication (
    fields: {
      email: $email
      firstname: $firstname
      lastname: $lastname
      country: $country
      region: $region
      jobTypeId: $jobTypeId
      referrerParams: $referrerParams
    }
  ) {
    success error authToken { tokenId secret }
  }
}`;

export const submitVoiceWritingApplicationMutation = `mutation SubmitVoiceWritingApplication(
  $tokenId: String!
  $secret: String!
  $experience: String!
  $referralSource: String!
) {
  submitVoiceWritingApplication(
    authToken: {
      tokenId: $tokenId
      secret: $secret
    }
    fields: {
      experience: $experience
      referralSource: $referralSource
    }
  ) {
    success error
  }
}`;
