import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { ButtonToolbar } from 'react-bootstrap';

import ErrorOverlay from '~/components/app/common/error_boundaries/ErrorOverlay';

import DownloadButton from './file_actions/DownloadButton';
import MoreButton from './file_actions/MoreButton';
import MoveButton from './file_actions/MoveButton';
import PublishButton from './file_actions/PublishButton';

function ActionBar(props) {
  const downloadProps = props.fileActions?.download || {};
  const publishProps = props.fileActions?.publish || {};
  const more = props.fileActions || {};

  return (
    <ButtonToolbar>
      <div className={css(styles.firstButtonContainer)}>
        <DownloadButton
          {...downloadProps}
          fileId={props.fileId}
          transcriptId={props.transcriptId}
          actionData={props.actionBarData}
          reprocessingAssets={props.fileData.reprocessingAssets}
        />
      </div>
      {props.enabledFeatures.includes('publish') && publishProps?.enabled && (
        <div className={css(styles.buttonContainer)}>
          <PublishButton
            fileId={props.fileId}
            projectId={props.projectId}
            actionData={props.actionBarData}
            publish={publishProps}
            setErrorsAlert={props.setErrorsAlert}
            setSuccess={props.setSuccess}
            setShowModal={props.setShowModal}
          />
        </div>
      )}
      <div className={css(styles.buttonContainer)}>
        <MoveButton onClick={() => props.setShowModal.move(true)} />
      </div>
      {Object.keys(more).length > 0 && (
        <div className={css(styles.buttonContainer)}>
          <MoreButton
            enabledFeatures={props.enabledFeatures}
            fileActionFunctions={props.fileActionFunctions}
            more={more}
            setShowModal={props.setShowModal}
          />
        </div>
      )}
    </ButtonToolbar>
  );
}

ActionBar.propTypes = {
  actionBarData: PropTypes.shape({
    favoriteFormats: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    pluginTemplates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  fileId: PropTypes.string,
  transcriptId: PropTypes.string,
  projectId: PropTypes.string,
  fileActions: PropTypes.shape({
    download: PropTypes.object,
    publish: PropTypes.object,
    move: PropTypes.object,
    import: PropTypes.object,
    add_cheatsheet: PropTypes.object,
    archive: PropTypes.object,
    manage_tags: PropTypes.object,
  }),
  fileActionFunctions: PropTypes.shape({
    archiveFile: PropTypes.func,
  }),
  setErrorsAlert: PropTypes.func,
  setShowModal: PropTypes.object,
  setSuccess: PropTypes.func,
  fileData: PropTypes.shape({
    reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
  }),
};

const styles = StyleSheet.create({
  firstButtonContainer: {
    paddingRight: '0.3em',
  },
  buttonContainer: {
    paddingLeft: '0.3em',
    paddingRight: '0.3em',
  },
});

export default ActionBar;
