export enum OutputFormatCodeEnum {
  PlainDoc = 'tx-doc',
  Vtt = 'tx-vtt',
  VttNoBlankFrames = 'tx-vtt-nobl',
  VttMusicMarkup = 'tx-vtt-musmrkp',
  VttMusicMarkupNoBlanks = 'tx-vtt-musmrkpnobl',
  SccBroadcast = 'tx-scc-brdcst',
  SccBroadcastTop = 'tx-scc-brdcsttop',
  SccBroadcastNoPaintOn = 'tx-scc-bnpo',
  SccBroadcastTopNoPaintOn = 'tx-scc-btnpo',
  SrtNoBlanks = 'tx-srt-nb',
  SrtMusicMarkupNoBlanks = 'tx-srt-musmrkpnobl',
  IttITunesSMPTECaptionFormat = 'tx-itt',
}
