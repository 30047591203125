import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { BrightcoveSource, KalturaSource, Source, WistiaSource } from '../types/AccessPlayerTypes';

/**
 *  Youtube has a bunch of different links in the wild, including m.youtube.com,
 *youtube-nocookie, youtu.be, etc.
 *
 * This regex is a best guess, otherwise it will fallback to advanced settings.
 * https://gist.github.com/rodrigoborgesdeoliveira/987683cfbfcc8d800192da1e73adc486?permalink_comment_id=3261716#gistcomment-3261716
 */
/* eslint-disable no-useless-escape */
const youtubeRegex =
  /(?:http:|https:)*?\/\/(?:www\.|)(?:youtube\.com|m\.youtube\.com|youtu\.|youtube-nocookie\.com).*(?:v=|v%3D|v\/|(?:a|p)\/(?:a|u)\/\d.*\/|watch\?|vi(?:=|\/)|\/embed\/|oembed\?|be\/|e\/)([^&?%#\/\n]*)/i;
/* eslint-enable no-useless-escape */

/**
 * @see parse_source_from_url.rb
 * @example http://www.kaltura.com/tiny/0vg6a
 */
const tinyKalturaRegex = /^https?:\/\/www\.kaltura\.com\/tiny\/(.*)/i;

/**
 * @see parse_source_from_url.rb
 * @example https://www.kaltura.com/index.php/extwidget/preview/partner_id/111032/uiconf_id/50355392/entry_id/1_kglm11oe/embed/dynamic?
 */
const kalturaRegex =
  /^https?:\/\/www\.kaltura\.com.*partner_id\/(?<kaPartnerId>[^/]*).*uiconf_id\/(?<kaUiconfId>[^/]*).*entry_id\/(?<mediaId>[^/]*)/i;

/**
 * @see parse_source_from_url.rb
 * @example https://bcove.video/3dDWWZN
 */
const tinyBrightcoveRegex = /^https?:\/\/.*bcove\.video\/(.*)/i;

/**
 * @see parse_source_from_url.rb
 * @example https://players.brightcove.net/31193522001/default_default/index.html?videoId=6307131764112/
 */
const brightcoveRegex =
  /https?:\/\/players\.brightcove\.net\/(?<bcAccount>[^/]*)\/(?<bcPlayer>[^_/]*)_default\/.*videoId=(?<mediaId>[^&/]*)/i;

/**
 * @see parse_source_from_url.rb
 * @example http://embed.wistia.com/deliveries/3f19febcfb0e9547d61c6228948a566b/file.mp4
 * @example https://3playmedia-2.wistia.com/medias/tbt0wwl95h
 */
const wistiaRegex = /https?:\/\/(?<wistiaAccount>[^_/]*)\.wistia\.com\/medias\/(?<mediaId>[^_/]*)/i;

const hasHTTPHeader = /^(?:f|ht)tps?:\/\//;

const query = `
query ParseSourceFromUrl($url: String!) {
  parsedIntegrationSource(url: $url) {
    ... on KalturaSource {
      kaPartnerId
      kaUiconfId
      mediaId
      platform
    }
    ... on BrightcoveSource {
      mediaId
      bcAccount
      bcPlayer
      platform
    }
    ... on WistiaSource {
      mediaId
      wistiaAccount
      wistiaHash
      platform
    }
  }
}
`;

interface Response {
  parsedIntegrationSource:
    | Pick<KalturaSource, 'kaPartnerId' | 'kaUiconfId' | 'mediaId' | 'platform'>
    | Pick<BrightcoveSource, 'bcAccount' | 'bcPlayer' | 'mediaId' | 'platform'>
    | Pick<WistiaSource, 'wistiaAccount' | 'wistiaHash' | 'mediaId' | 'platform'>
    | Record<string, never>;
}

const defaultApi = new ThreeplayApiV2();

export async function asyncParseSourceFromURL(url: string, api = defaultApi): Promise<Source> {
  if (url.match(tinyKalturaRegex) || url.match(tinyBrightcoveRegex)) {
    // Tiny URLs must have redirects followed. This is not generally possible
    // from the front-end (due to common CORS headers as good security
    // practice). As such, we query our backend to follow redirects and return
    // a parsed source for us.
    //
    // We still do most of the parsing locally to avoid waiting for a request
    // when we recognize a URL that we fully understand.
    // await fetch('https://google.com');
    const data = await api.request<Response>(query, { url }, 'parsedIntegrationSource');

    if ('platform' in data) {
      return { id: 'custom', displayName: 'custom', ...data } as KalturaSource | BrightcoveSource;
    }
  }

  const youtubeMatch = url.match(youtubeRegex);

  if (youtubeMatch) {
    return {
      id: 'custom',
      displayName: 'custom',
      platform: 'YouTube',
      mediaId: youtubeMatch[1] || '',
    };
  }

  const kalturaMatch = url.match(kalturaRegex);

  if (kalturaMatch) {
    return {
      id: 'custom',
      displayName: 'custom',
      platform: 'Kaltura',
      ...(kalturaMatch.groups as Pick<KalturaSource, 'kaPartnerId' | 'kaUiconfId' | 'mediaId'>),
    };
  }

  const brightcoveMatch = url.match(brightcoveRegex);

  if (brightcoveMatch) {
    return {
      id: 'custom',
      displayName: 'custom',
      platform: 'Brightcove',
      ...(brightcoveMatch.groups as Pick<BrightcoveSource, 'bcAccount' | 'bcPlayer' | 'mediaId'>),
    };
  }

  const wistiaMatch = url.match(wistiaRegex);

  if (wistiaMatch) {
    return {
      id: 'custom',
      displayName: 'custom',
      platform: 'Wistia',
      ...(wistiaMatch.groups as Pick<WistiaSource, 'wistiaAccount' | 'mediaId'>),
    };
  }

  return {
    id: 'custom',
    displayName: 'custom',
    platform: 'native',
    playback: hasHTTPHeader.test(url) ? url : `https://${url}`,
    playAs: 'infer',
  };
}
