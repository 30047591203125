const awsDeleteMutation = `mutation AWSDeleteFile($bucket: String!, $key: String!){
  awsDelete(bucket: $bucket, key: $key){
    errors,
    success
  }
}`;

const submitOrderMutation = `mutation SubmitOrder($order: OrderInput!) {
  orderServices(order: $order) {
    errors,
    success,
    order
  }
}`;

const saveOrderTemplateMutation = `mutation CreateOrderTemplate($template: OrderTemplateInput!) {
  createOrderTemplate(template: $template) {
    success,
    errors
  }
}`;

export { awsDeleteMutation, submitOrderMutation, saveOrderTemplateMutation };
