import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectionCount', 'selectionActionButton'];

  connect() {
    this.updateSelectionCount();

    // TODO: generalize and extract the select-all checkbox behavior
    document.querySelector('.select-all-applications')?.addEventListener('change', (e) => {
      const checkboxes = document.querySelectorAll('.application-checkbox');
      checkboxes.forEach((checkbox) => (checkbox.checked = e.target.checked));
      this.updateSelectionCount();
    });

    document.querySelectorAll('.application-checkbox').forEach((checkbox) => {
      checkbox.addEventListener('change', () => this.updateSelectionCount());
    });
  }

  updateSelectionCount() {
    const selectedCount = document.querySelectorAll('.application-checkbox:checked').length;
    this.selectionCountTarget.textContent = `${selectedCount} selected of `;

    this.selectionActionButtonTargets.forEach((button) => {
      button.disabled = selectedCount === 0;
    });
  }
}
