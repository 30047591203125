import { Logger } from '~/logic/Logger/Logger';

const COMMON_WORD_SEARCH_INDEX_STRATEGY = [0, 1, -1, 2, -2];

export function recognizedWordsToAdd(
  recognizingWords: string[],
  recognizedWords: string[]
): string[] {
  let indexofLastCommonWord: null | number = null;

  for (const [recognizingWordIndex, recognizingWord] of recognizingWords
    .slice()
    .reverse()
    .entries()) {
    for (const index of COMMON_WORD_SEARCH_INDEX_STRATEGY.map(
      (i) => recognizingWords.length - 1 - recognizingWordIndex + i
    )) {
      if (recognizingWord === recognizedWords[index]) {
        indexofLastCommonWord = index;
        break;
      }
    }
    if (indexofLastCommonWord !== null) break;
  }

  if (indexofLastCommonWord === null) {
    Logger.getInstance().info({
      message: 'recognizedWordsToAdd: No match found within 2 words.',
      info: { recognizingWords, recognizedWords },
    });
    return recognizedWords.slice(recognizingWords.length);
  }

  return recognizedWords.slice(indexofLastCommonWord + 1);
}
