import React from 'react';

import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function StartTestModal(props) {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <h2>Submit transcription test?</h2>
      </Modal.Header>
      <Modal.Body>
        <p>
          Once you submit this test, you&apos;ll be added to our pool of pending applicants.
          We&apos;ll look over your submitted application and be in touch!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="3play-outline" onClick={props.onHide}>
          No, Cancel
        </Button>
        <Button variant="3play" onClick={props.onConfirm}>
          Yes, Submit Test
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

StartTestModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default StartTestModal;
