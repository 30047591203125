import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {
  BACK_BUTTON_INDEX,
  FINISH_BUTTON_INDEX,
  NEXT_BUTTON_INDEX,
  ORDER_FORM_STEPS,
  ORDER_FORM_TRANSCRIPTION_ONLY_STEPS,
} from '~/helpers/constants';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';
import { ProjectContext } from './OrderForm';
import Steps from 'rc-steps';
import 'rc-steps/assets/index.css';
function StepContainer({
  alignmentErrors,
  changeStep,
  children,
  currentStep,
  enableNext,
  setShowAlignmentErrorModal,
  stepErrors,
  submit,
}) {
  const { features, loadedTemplate } = useContext(ProjectContext);
  const containerStyle = {
    marginBottom: 24,
  };

  const stepTitles = features.other ? ORDER_FORM_STEPS : ORDER_FORM_TRANSCRIPTION_ONLY_STEPS;

  const handleStepAlignmentErrors = (stepType, destination) => {
    if (currentStep.type === 'upload' && !!features.other && alignmentErrors) {
      return setShowAlignmentErrorModal(true);
    } else {
      changeStep({ type: stepType, destination: destination });
    }
  };

  return (
    <>
      <Steps style={containerStyle} type="navigation" current={currentStep.id}>
        {stepTitles.map((step, index) => {
          return (
            <Steps.Step
              aria-current={currentStep.step === index ? 'step' : ''}
              className={currentStep.step > index ? css(styles.pointer) : ''}
              key={index}
              tabIndex={index + 1}
              onClick={() => changeStep({ type: 'jump', destination: index })}
              title={step}
            />
          );
        })}
      </Steps>

      <Card className={css(styles.borderless)}>
        <Card.Body
          className={currentStep.type === 'finalize' ? css(styles.finalStepContainer) : ''}
        >
          {children}
        </Card.Body>
        <Card.Footer className={css(styles.borderless, styles.white)}>
          {currentStep.type != 'primaryServices' && (
            <Button
              tabIndex={BACK_BUTTON_INDEX}
              variant="secondary"
              className={css(styles.backButton)}
              onClick={() => changeStep({ type: 'previous' })}
              disabled={loadedTemplate != null}
            >
              Back
            </Button>
          )}
          {currentStep.type != 'finalize' && (
            <div className={css(styles.floatRight)}>
              <TooltipIfErrors errors={stepErrors} location="top">
                <Button
                  tabIndex={NEXT_BUTTON_INDEX}
                  onClick={() => handleStepAlignmentErrors('next')}
                  className={css(styles.nextButton)}
                  disabled={!enableNext}
                >
                  Next
                </Button>
              </TooltipIfErrors>
            </div>
          )}
          {currentStep.type === 'finalize' && (
            <Button
              tabIndex={FINISH_BUTTON_INDEX}
              onClick={submit}
              className={css(styles.submitButton)}
              disabled={!enableNext}
            >
              Submit
            </Button>
          )}
        </Card.Footer>
      </Card>
    </>
  );
}

StepContainer.propTypes = {
  alignmentErrors: PropTypes.array,
  changeStep: PropTypes.any,
  children: PropTypes.node,
  currentStep: PropTypes.number,
  enableNext: PropTypes.bool,
  stepErrors: PropTypes.array,
  setShowAlignmentErrorModal: PropTypes.func,
  submit: PropTypes.func,
};

const styles = StyleSheet.create({
  finalStepContainer: {
    padding: '0px',
  },
  floatRight: {
    float: 'right',
  },
  nextButton: {
    float: 'right',
    borderWidth: '2px',
    fontWeight: 'bold',
    ':hover': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    ':disabled': {
      pointerEvents: 'none',
    },
  },
  backButton: {
    fontWeight: 'bold',
    ':focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    ':hover': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  submitButton: {
    float: 'right',
    ':hover': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  borderless: { border: '0' },
  white: { backgroundColor: 'white' },
});

export default StepContainer;
