import { Logger } from '~/logic/Logger/Logger';

interface AwsUploadData {
  accessKey: string;
  amazonUrl: string;
  bucket: string;
  key: string;
  policy: string;
  signature: string;
  expiration: string;
  conditions: Array<{ [key: string]: string } | string[]>;
}

export const uploadAudioChunk = async (swateiJobId: number, audioFile: File): Promise<void> => {
  try {
    const awsUploadData: AwsUploadData = await getAwsUploadData(swateiJobId);
    const fileName = awsUploadData.key.split(/[\\/]/).pop() as string;
    const renamedFile = new File([audioFile], fileName, { type: audioFile.type });
    const formData = buildFormData(awsUploadData, renamedFile);
    await uploadToAws(formData, awsUploadData.amazonUrl);
    Logger.getInstance().info({
      message: 'AudioUploader: Audio chunk uploaded successfully',
      info: { fileName, swateiJobId },
    });
  } catch (error) {
    Logger.getInstance().warn({
      message: 'AudioUploader: Failed to upload audio chunk',
      info: { error, swateiJobId },
    });
  }
};

const getAwsUploadData = async (swateiJobId: number): Promise<AwsUploadData> => {
  const awsUploadDataUrl = `/swatei/live_events/${swateiJobId}/aws_upload_path?sub_folder=audiochunks`;
  const response = await fetch(awsUploadDataUrl, {
    headers: { Accept: 'application/json' },
    method: 'GET',
  });

  if (!response.ok) {
    if ([401, 404].includes(response.status)) {
      await handleRedirect(response);
    }
    throw new Error(`Failed to fetch AWS upload data: ${response.status}`);
  }

  return response.json() as Promise<AwsUploadData>;
};

const buildFormData = (awsData: AwsUploadData, file: File): FormData => {
  const formData = new FormData();
  formData.append('key', awsData.key);
  formData.append('AWSAccessKeyId', awsData.accessKey);
  formData.append('acl', 'private');
  formData.append('policy', awsData.policy);
  formData.append('signature', awsData.signature);
  formData.append('success_action_status', '200');
  formData.append('Content-Type', file.type);
  formData.append('file', file);
  return formData;
};

const uploadToAws = async (formData: FormData, url: string): Promise<void> => {
  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`Failed to upload to AWS: ${response.status}`);
  }
};

const handleRedirect = async (response: Response) => {
  Logger.getInstance().warn({
    message: '3play App aws_upload_path triggered redirect',
    info: { response },
  });

  const { redirect } = (await response.json()) as { redirect: string };
  window.skipConfirmLeavePage = true;
  window.location.replace(redirect);
};
