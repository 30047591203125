import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ThreeplayTable from '../../../common/ThreeplayTable';
import { JobLink, PriorityStars, CheckIcon, RemoveJob } from './Cells';
import { jobShape } from '../../shapes';

const hms = (ms) => (ms ? new Date(ms).toISOString().substr(11, 8) : '--:--:--');
const formatDate = (date) => date && new Date(date * 1000).toLocaleString();

const shapeData = (jobs) =>
  jobs.map((job) => {
    return {
      id: job.id,
      projectName: job.project?.name,
      type: job.type,
      jobName: job.name,
      duration: hms(job.duration),
      access: `${job.loggedInAccessCount} / ${job.accessCount}`,
      deadline: formatDate(job.deadline),
      opsDeadline: formatDate(job.service?.opsDeadline),
      serviceDeadline: formatDate(job.service?.deadline),
      priorityStars: job.priorityStars ?? 0,
      force: job.forceList,
      topOfQueue: job.topOfQueue,
      hoursOnMarket: job.hoursOnMarket.toFixed(1),
      removeJob: job.id,
    };
  });

const JobsTable = ({ jobs, onRemoveJob }) => {
  const data = useMemo(() => shapeData(jobs), [jobs]);
  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id', Cell: JobLink },
      { Header: 'Project', accessor: 'projectName' },
      { Header: 'Type', accessor: 'type' },
      { Header: 'Name', accessor: 'jobName', maxWidth: 50 },
      { Header: 'Duration', accessor: 'duration' },
      { Header: '# Accesses', accessor: 'access' },
      { Header: 'Deadline', accessor: 'deadline' },
      { Header: 'Ops Deadline', accessor: 'opsDeadline' },
      { Header: 'Service Deadline', accessor: 'serviceDeadline' },
      { Header: 'Priority', accessor: 'priorityStars', Cell: PriorityStars },
      { Header: 'Force', accessor: 'force', Cell: CheckIcon },
      { Header: 'Top', accessor: 'topOfQueue', Cell: CheckIcon },
      { Header: 'Hours on Market', accessor: 'hoursOnMakret' },
      {
        Header: 'Remove Job',
        accessor: 'removeJob',
        disableSortBy: true,
        Cell: RemoveJob,
        getProps: () => ({
          removeJob: onRemoveJob,
        }),
      },
    ],
    []
  );

  return <ThreeplayTable columns={columns} data={data} striped />;
};

JobsTable.propTypes = {
  jobs: PropTypes.arrayOf(jobShape),
  onRemoveJob: PropTypes.func,
};

export default JobsTable;
