import React, { useState } from 'react';
import { Button, Input } from '@threeplayground/index';
import { Form } from '@threeplayground/unstable';
import { use3PMutation } from '~/logic/unstable/use3PMutation';
import { useForm } from 'react-hook-form';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CREATE_DELETION_REQUEST = `mutation createGdprDeletionRequest($data: GdprDeletionRequestCreateInput!){
  createGdprDeletionRequest(data: $data){
    data {
      id
      email
      state
      stateTransitions(limit: 1, offset: 0, orderBy: [CREATED_AT_DESC]) {
        edges {
          node {
            errorCode
          }
        }
      }
    }
    errors {
      code
      message
    }
  }
}`;

const DataDeletionPage = () => {
  const [globalErrors, setGlobalErrors] = useState(undefined);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [globalSuccessMessage, setGlobalSuccessMessage] = useState(undefined);

  const { mutateAsync } = use3PMutation(CREATE_DELETION_REQUEST, {
    extractKey: 'createGdprDeletionRequest',
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({});

  const onSubmit = async (data) => {
    setGlobalErrors(undefined);
    setGlobalSuccessMessage(undefined);
    if (confirm(`Are you sure you want to delete ${data.email}? This action can't be undone.`)) {
      const {
        data: mutationData,
        errors,
        globalErrors: mutationGlobalErrors,
      } = await mutateAsync({
        data,
      });
      setGlobalErrors(mutationGlobalErrors || undefined);
      setFieldErrors(errors || null);

      if (mutationData && !errors && !mutationGlobalErrors) {
        const { state, stateTransitions, email } = mutationData;
        const errorCode = stateTransitions.edges[0]?.node.errorCode;

        switch (state) {
          case 'error':
            setGlobalErrors(getErrorCodeMessage(errorCode));
            break;
          case 'complete':
            setGlobalSuccessMessage(`${email} has already been deleted.`);
            break;
          case 'in_progress':
            setGlobalSuccessMessage(`${email} will be deleted in the next 24 hours.`);
            break;
          default:
            setGlobalSuccessMessage(
              `Deletion Request has been recorded, but put in unknown state ${state}`
            );
            break;
        }
      }
    }
  };

  return (
    <>
      {globalSuccessMessage && (
        <Alert className="in" variant="success">
          {globalSuccessMessage}
        </Alert>
      )}
      {globalErrors && <SummaryBanner errors={globalErrors} />}
      <Form onSubmit={handleSubmit(onSubmit)} onReset={reset} errors={fieldErrors}>
        <Input type="email" label="Email:" required {...register('email')} />
        <Button type="submit" variant="danger" disabled={isSubmitting}>
          {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
          Submit Deletion Request
        </Button>
      </Form>
    </>
  );
};

const getErrorCodeMessage = (errorCode) => {
  switch (errorCode) {
    case 'contractor_cannot_be_terminated':
      return [
        {
          message:
            'Request has been recorded but we cannot terminate the contractor. Please verify contractor data.',
        },
      ];
    case 'client_outstanding_invoice_balance':
      return [
        {
          message:
            'Request has been recorded but we cannot terminate the client due to invoice issues. The user has been notified.',
        },
      ];
    case 'client_cannot_be_deleted':
      return [
        {
          message:
            'Request has been recorded but we cannot terminate the client. Please verify they are not the last user with billing and manage users access.',
        },
      ];
    default:
      return [
        {
          message: `An unknown error occurred. Please retry. If the issue persists please contact the OC Dev team at oc-dev@3playmedia.com`,
        },
      ];
  }
};

/*
 * This is pulled right from the Form Component
 * once https://3playmedia.atlassian.net/browse/FRAME-262 and https://3playmedia.atlassian.net/browse/FRAME-263
 * are fixed we can use the Forms built is error handling
 **/
function SummaryBanner({ errors }) {
  if (errors.length === 0) {
    return null;
  }

  // TODO we should probably add a title top to Alert and make the paragraph
  return (
    <Alert variant="danger" className="in">
      <p>Please correct the following issues:</p>
      <ul>
        {errors.map((error) => (
          <li key={error.message}>{error.message}</li>
        ))}
      </ul>
    </Alert>
  );
}

SummaryBanner.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
};

export default DataDeletionPage;
