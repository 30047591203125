import React from 'react';
import { css } from 'aphrodite';
import PropTypes from 'prop-types';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import WithIcon from '~/components/app/common/WithIcon';
import { appStyles } from '~/components/app/common/styles';
import { threeplayApi } from '~/logic/ThreeplayApi';
import {
  publishPluginPath,
  accessPlayerBuilderPath,
  seoPluginPath,
  ftpDeliveryPath,
  pluginPath,
} from '~/helpers/app/paths';

async function publishVpiWithMutation(props, forAd) {
  const response = await threeplayApi.mutate(postbackVpiMutation(props.fileId, forAd));
  if (response.data.postbackVpi?.errors) {
    props.setErrorsAlert(response.data.postbackVpi.errors);
  } else {
    props.setSuccess(response.data.postbackVpi.success);
  }
}

function postbackVpiMutation(fileId, forAd) {
  return `postbackVpi(
    fileId: ${fileId}, forAd: ${forAd}
  ) { success, errors }`;
}

function publishDropdown(props, pluginTemplates) {
  const youtubePtId = pluginTemplates['YouTube Interactive Transcript Embed'];
  const lmsPtId = pluginTemplates['LMS Plugin Embed'];
  const actions = [
    props.publish.postbackVpi?.enabled && (
      <Dropdown.Item
        key="1"
        className={css(appStyles.dropdownLink)}
        onClick={() => publishVpiWithMutation(props, false)}
      >
        <i className="fa fa-upload"></i> Postback to {props.publish.postbackVpi.name}
      </Dropdown.Item>
    ),
    props.publish.postbackLinkedAccount?.enabled && (
      <Dropdown.Item
        key="2"
        className={css(appStyles.dropdownLink)}
        href="#manualPostbackModal"
        as="button"
        data-toggle="modal"
      >
        <i className="fa fa-upload"></i> Postback to {props.publish.postbackLinkedAccount.name}
      </Dropdown.Item>
    ),
    props.publish.publishAccessPlayer?.enabled && (
      <Dropdown.Item
        key="publishAccessPlayerBuilder"
        className={css(appStyles.dropdownLink)}
        href={accessPlayerBuilderPath(props.fileId)}
      >
        <i className="fa fa-code"></i> Publish Access Player
      </Dropdown.Item>
    ),
    props.publish.publishPlugin?.enabled && (
      <Dropdown.Item
        key="3"
        className={css(appStyles.dropdownLink)}
        href={publishPluginPath(props.fileId)}
      >
        <i className="fa fa-code"></i> Publish Plugin
      </Dropdown.Item>
    ),
    props.publish.adPostbackVpi?.enabled && (
      <Dropdown.Item
        key="4"
        className={css(appStyles.dropdownLink)}
        onClick={() => publishVpiWithMutation(props, true)}
      >
        <i className="fa fa-upload"></i> Postback description to {props.publish.adPostbackVpi.name}
      </Dropdown.Item>
    ),
    props.publish.publishVideo?.enabled && (
      <Dropdown.Item
        key="5"
        className={css(appStyles.dropdownLink)}
        onClick={() => props.setShowModal.publishVideo(true)}
      >
        <i className="fa fa-film"></i> Publish Video
      </Dropdown.Item>
    ),
    props.publish.youtubePlugin?.enabled && (
      <Dropdown.Item
        key="6"
        className={css(appStyles.dropdownLink)}
        href={pluginPath(props.fileId, youtubePtId)}
      >
        <i className="fa fa-youtube"></i> One Line YouTube Embed
      </Dropdown.Item>
    ),
    props.publish.seoPlugin?.enabled && (
      <Dropdown.Item
        key="7"
        className={css(appStyles.dropdownLink)}
        href={seoPluginPath(props.fileId)}
      >
        Publish SEO Plugin
      </Dropdown.Item>
    ),
    props.publish.lmsPlugin?.enabled && (
      <Dropdown.Item
        key="8"
        className={css(appStyles.dropdownLink)}
        href={pluginPath(props.fileId, lmsPtId)}
      >
        LMS Plugin Embed
      </Dropdown.Item>
    ),
    props.publish.ftpDeliveries?.length > 0 &&
      props.publish.ftpDeliveries.map((ftp) => (
        <Dropdown.Item
          key="9"
          className={css(appStyles.dropdownLink)}
          href={ftpDeliveryPath(props.fileId, ftp.id)}
        >
          FTP delivery to {ftp.name}
        </Dropdown.Item>
      )),
  ];
  return actions;
}

function PublishButton(props) {
  // TODO: Don't use Object keys; instead return an array of plugin data
  // from the controller
  const pluginTemplates = {};
  const actionData = props.actionData || {};
  actionData.pluginTemplates?.forEach((pt) => {
    pluginTemplates[pt.name] = pt.id;
  });

  if (!props.actionData) {
    return <DropdownButton size="sm" />;
  }

  return (
    <DropdownButton
      id="dropdown-variants-secondary"
      variant="light"
      size="sm"
      title={<WithIcon icon="fa fa-upload">Publish</WithIcon>}
      data-testid="publishDropdown"
      focusFirstItemOnShow="keyboard"
    >
      {publishDropdown(props, pluginTemplates)}
    </DropdownButton>
  );
}

PublishButton.propTypes = {
  actionData: PropTypes.shape({
    pluginTemplates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  fileId: PropTypes.string,
  publish: PropTypes.object,
  setErrorsAlert: PropTypes.func,
  setSuccess: PropTypes.func,
  setShowModal: PropTypes.shape({
    publishVideo: PropTypes.func,
  }),
};

export default PublishButton;
