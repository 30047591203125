import React from 'react';

import PropTypes from 'prop-types';

function MessageBanner(props) {
  return (
    <div className={`update-jobs-message ${props.variant}`}>
      {props.children}
      <i className="fa fa-times update-jobs-close-banner" onClick={props.onClose}></i>
    </div>
  );
}

MessageBanner.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'error']),
};

MessageBanner.defaultProps = {
  variant: 'error',
};

export default MessageBanner;
