import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

import Card from 'react-bootstrap/Card';

import { vpiNewPath } from '~/helpers/app/paths';
import { userLogger } from '~/logic/UserLogger';
import { BETA_PLATFORMS } from '~/helpers/constants';

import logoPath from '~/helpers/logos';

function betaPlatform(name) {
  return BETA_PLATFORMS.includes(name);
}

function betaRibbon() {
  return (
    <div className={css(styles.betaLabel)}>
      <span>BETA</span>
    </div>
  );
}

function PlatformDetails(props) {
  return (
    <Card className="m-2 text-center overflow-hidden">
      <Card.Body>
        {betaPlatform(props.name) && betaRibbon()}
        <a
          href={vpiNewPath(props.name)}
          onClick={() =>
            userLogger.logEvent('LinkedAccounts', 'Link a New Account', {
              'Video Integration': props.name,
            })
          }
        >
          <Card.Img
            variant="top"
            className={css(styles.responsiveImage)}
            src={logoPath(props.name)}
          />
        </a>
      </Card.Body>
    </Card>
  );
}

const styles = StyleSheet.create({
  betaLabel: {
    position: 'absolute',
    width: '80px',
    height: '25px',
    background: 'red',
    top: '7px',
    left: '-20px',
    textAlign: 'center',
    fontSize: '13px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#fff',
    lineHeight: '27px',
    transform: 'rotate(-45deg)',
  },
  responsiveImage: {
    maxWidth: '100%',
    height: 'auto',
  },
});

PlatformDetails.propTypes = {
  name: PropTypes.string.isRequired,
};

export default PlatformDetails;
