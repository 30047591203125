import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Table, Badge } from 'react-bootstrap';

class MediaFileModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {Object.keys(this.props.mediaFiles).length} {this.props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered>
            <tbody>
              {Object.keys(this.props.mediaFiles).map((fileId) => {
                return (
                  <tr key={fileId}>
                    <td>
                      <div>{this.props.mediaFiles[fileId]['title']}</div>
                      {this.props.mediaFiles[fileId]['services'].map((service, index) => {
                        return (
                          <Badge
                            pill
                            variant="secondary"
                            className="file-pill"
                            key={fileId.toString() + '-' + index + '-' + service}
                          >
                            {service}
                          </Badge>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

MediaFileModal.propTypes = {
  handleCloseModal: PropTypes.func,
  hideModal: PropTypes.func,
  mediaFiles: PropTypes.object,
  show: PropTypes.bool,
  title: PropTypes.string,
};

export default MediaFileModal;
