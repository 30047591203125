import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import styles from '~/components/app/order_form/serviceDetails/serviceDetailStyles';

import { displayPrice } from '~/helpers/numbers';

const OutputAssetDetails = ({ outputAssetOptions }) => {
  return Object.values(outputAssetOptions).map((mixOption) => {
    if (!mixOption) {
      return;
    } // They did not select this, but the key is present.

    const mixName = mixOption.name;
    const mixPrice = mixOption.price;
    return mixOption.embedSelections.map((embedOption) => {
      const embedName = embedOption.name;
      return (
        <div className={css(styles.serviceOptionHeader)} key={embedOption.code}>
          <b>Audio Mix:&nbsp;</b> {mixName}&nbsp;&nbsp;
          <b>Output Asset:&nbsp;</b> {embedName}
          <div className={css(styles.serviceOptionPrice)}>{displayPrice(mixPrice, 'file')}</div>
        </div>
      );
    });
  });
};

OutputAssetDetails.propTypes = {
  outputAssetOptions: PropTypes.object.isRequired,
};

export default OutputAssetDetails;
