import React from 'react';
import PropTypes from 'prop-types';

// I have an approved PR, but can't get the owner of the module to publish
// So I'm just using the raw (non-minified) code from my personal fork
// of the repo.
import { StackedBar } from 'chart.xkcd-react-3play';
import { StyleSheet, css } from 'aphrodite';

function ZenmasterChart(props) {
  return (
    <div className={css(styles.chart)}>
      <a className="btn btn-primary" rel="nofollow" data-method="POST" href={props.finish_path}>
        Finish Zenmaster
      </a>
      <StackedBar
        config={{
          title: 'Zenmaster', // optional
          yLabel: 'cards', // optional
          data: {
            labels: props.dates,
            datasets: [
              {
                label: 'Solved',
                data: props.solved,
              },
              {
                label: 'Card',
                data: props.card,
              },
              {
                label: 'Rejected',
                data: props.rejected,
              },
            ],
          },
          options: {
            dataColors: ['#28ddbd', '#28a3dd', '#2849dd'],
          },
        }}
      />
    </div>
  );
}

ZenmasterChart.propTypes = {
  dates: PropTypes.array,
  solved: PropTypes.array,
  card: PropTypes.array,
  rejected: PropTypes.array,
  finish_path: PropTypes.string,
};

const styles = StyleSheet.create({
  chart: {
    width: '70%',
    height: '100%',
    margin: 'auto',
  },
});

export default ZenmasterChart;
