import React from 'react';
import { Table } from 'react-bootstrap';

interface UploadedFilesProps {
  files: File[] | null;
}

interface FileListRow {
  header: string;
  value: string;
}

interface File {
  id: string;
  assetFileFormat: AssetFileFormat;
  externalUrl: string;
  state: string;
}

interface AssetFileFormat {
  id: string;
  displayName: string;
}

function FileDetails(file: File): FileListRow[] {
  return [
    {
      header: 'Id',
      value: file.id,
    },
    {
      header: 'Format',
      value: file.assetFileFormat.displayName,
    },
    {
      header: 'External Url',
      value: file?.externalUrl,
    },
    {
      header: 'Status',
      value: file.state,
    },
  ];
}

export const UploadedFileList = ({ files }: UploadedFilesProps) => (
  <>
    <h2>Uploaded Files</h2>
    {!files?.length ? (
      <div> No files uploaded yet</div>
    ) : (
      <Table className="table-bordered">
        <tbody>
          <tr>
            <th>Id</th>
            <th>Asset Format</th>
            <th>External Url</th>
            <th>Status</th>
          </tr>
          {files.map((file: File) => (
            <tr key={file.id}>
              {FileDetails(file).map(({ value }) => (
                <td style={{ wordBreak: 'break-word' }} key={value}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    )}
  </>
);
