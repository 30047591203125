import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function YouTubePlaylistDropDown(props) {
  function searchYouTubeVideos(selectedPlaylistId) {
    props.setPageToken('');
    props.setSearchParams({ playlistId: selectedPlaylistId });
  }

  return (
    <div className="d-flex justify-content-start col-sm-4">
      <Form.Control
        as="select"
        disabled={props.integrationPlaylistId === ''}
        onChange={(e) => searchYouTubeVideos(e.target.value)}
        value={props.searchParams.playlistId}
      >
        <option value="">Public Videos</option>
        {props.integrationPlaylistId !== '' && (
          <option value={props.integration.playlistId}>Playlist Videos</option>
        )}
      </Form.Control>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            Public Videos: public videos only. Playlist Videos: includes unlisted Videos in
            configured playlist (see settings)
          </Tooltip>
        }
        trigger={['hover', 'focus']}
      >
        <i className="p-2 d-flex align-items-center fa fa-info-circle"></i>
      </OverlayTrigger>
    </div>
  );
}

YouTubePlaylistDropDown.propTypes = {
  integration: PropTypes.object,
  integrationPlaylistId: PropTypes.string,
  searchParams: PropTypes.object,
  setSearchParams: PropTypes.func,
  setPageToken: PropTypes.func,
};

export default YouTubePlaylistDropDown;
