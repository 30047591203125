import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Alert, Form } from 'react-bootstrap';

const ObnoxiousYellowBox = ({ checked, children, supportLink, toggleChecked, title }) => (
  <Alert className="mt-2" variant="warning">
    <Alert.Heading className={css(styles.header)}>{title}</Alert.Heading>
    <Form.Group controlId="translationWarning">
      <Form.Check
        checked={checked}
        className="d-inline-block"
        onChange={() => toggleChecked((currState) => !currState)}
        type="checkbox"
      />
      {children}
      {supportLink && (
        <div className={css(styles.supportBlurb)}>
          For more information please see&nbsp;
          <Alert.Link
            href={supportLink}
            target="_blank"
            rel="noopener noreferrer"
            className={css(styles.supportLink)}
          >
            this support document
          </Alert.Link>
          .
        </div>
      )}
    </Form.Group>
  </Alert>
);

ObnoxiousYellowBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  supportLink: PropTypes.string,
  toggleChecked: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  header: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
  },
  supportBlurb: {
    marginTop: '0.5rem',
  },
  supportLink: {
    textDecoration: 'underline',
  },
});

export default ObnoxiousYellowBox;
