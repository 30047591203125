import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { toggleCaptionImportSoftDeletePath } from '~/helpers/app/paths';

import MultiServiceTable from './MultiServiceTable';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';

function DeleteCaptionImportButton(props) {
  return (
    <OverlayTrigger overlay={<Tooltip>Archive this Caption Import</Tooltip>}>
      <Button variant="link" className="text-dark" size="sm" onClick={props.onClick}>
        <i className="fa fa-archive"></i>
      </Button>
    </OverlayTrigger>
  );
}

DeleteCaptionImportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function CaptionImportServiceCardContents(props) {
  function setError(message = 'Something went wrong.') {
    props.setErrorsAlert((errors) => [...errors, message]);
  }

  async function archiveCaptionImport(serviceId) {
    props.setShowModal.confirm(false, {});
    const query = new URLSearchParams({ service_id: serviceId, format: 'json' }).toString();
    const response = await fetch(`${toggleCaptionImportSoftDeletePath}?${query}`, {
      credentials: 'same-origin',
    }).catch(setError);
    if (!response.ok) {
      setError();
    }
    const body = await response.json();
    props.onUpdate();
    return body;
  }

  function cancelArchive() {
    props.setShowModal.confirm(false, {});
  }

  function handleArchive(serviceId) {
    props.setShowModal.confirm(true, {
      message: 'Are you sure you want to archive this Caption Import?',
      onCancel: cancelArchive,
      onConfirm: () => archiveCaptionImport(serviceId),
    });
  }

  const headers = ['Ordered', 'Language', 'Imported File Type', 'Completed', ''];
  return (
    <MultiServiceTable headers={headers}>
      {props.services.map((service) => (
        <tr key={service.id}>
          <td>
            {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
          </td>
          <td>{service.language.name}</td>
          <td>{service.importedFileType}</td>
          {service.status === 'complete' && (
            <td>
              {moment(parseInt(service.completedAt))
                .tz(props.userTimeZone)
                .format(DATE_TIME_FORMAT)}
            </td>
          )}
          {service.status === 'in_progress' && <td>In Progress</td>}
          <td>
            <DeleteCaptionImportButton onClick={() => handleArchive(service.id)} />
          </td>
        </tr>
      ))}
    </MultiServiceTable>
  );
}

CaptionImportServiceCardContents.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(PropTypes.object),
  setErrorsAlert: PropTypes.func.isRequired,
  setShowModal: PropTypes.shape({
    confirm: PropTypes.func.isRequired,
  }),
  userTimeZone: PropTypes.string,
};

export default CaptionImportServiceCardContents;
