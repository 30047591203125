import React, { createContext, ReactNode, useContext } from 'react';
import {
  DropdownButton,
  DropdownButtonProps,
  InputGroup,
  Dropdown as BootstrapDropdown,
} from 'react-bootstrap';

import { Button, ButtonProps } from '../Button/Button';

/**
 * We open up a context to make it easier to thread through values
 * without currying our delegates.
 */
const DirectionContext = createContext(InputGroup.Prepend);

const ButtonDecoration = (props: ButtonProps) => {
  const Direction = useContext(DirectionContext);
  // Same component interface with some defaults sensible for an InputGroup
  // TODO check this against the final Button interface and Derek.
  return (
    <Direction>
      <Button variant="outline-primary" {...props} />
    </Direction>
  );
};

interface IconProps {
  icon: string;
}

/** Icon is a font-awesome class. You don't need to pass `fa`. */
const IconDecoration = ({ icon }: IconProps) => {
  const Direction = useContext(DirectionContext);

  return (
    <Direction>
      <InputGroup.Text>
        <i className={`fa ${icon}`} />
      </InputGroup.Text>
    </Direction>
  );
};

/**
 * WIP interface. Eventually we'd like to encapsulate rendering better
 * but for now forwarding the bootstrap API isn't too bad.
 *
 * @see https://react-bootstrap-v4.netlify.app/components/dropdowns/
 */
const DropdownDecoration = (props: DropdownButtonProps) => {
  const Direction = useContext(DirectionContext);
  // we should export our own dropdown button outside of this group also

  return <DropdownButton variant="outline-primary" {...props} as={Direction} />;
};

/** Forward the bootstrap classes for convenience */
DropdownDecoration.Item = BootstrapDropdown.Item;
DropdownDecoration.Divider = BootstrapDropdown.Divider;

const TextDecoration = ({ children }: { children: ReactNode }) => {
  const Direction = useContext(DirectionContext);

  return (
    <Direction>
      <InputGroup.Text>{children}</InputGroup.Text>
    </Direction>
  );
};

interface RenderPropProps {
  Text: typeof TextDecoration;
  Icon: typeof IconDecoration;
  Button: typeof ButtonDecoration;
  Dropdown: typeof DropdownDecoration;
}

type DecorationDelegateReturn = string | ReactNode;
export type InputDecorationDelegate = (
  props: RenderPropProps
) => DecorationDelegateReturn | DecorationDelegateReturn[];

export type InputDecorations = string | string[] | InputDecorationDelegate;

interface InputDecorationsListProps {
  decorations: InputDecorations;
  direction: 'prepend' | 'append';
}

export function InputDecorationsList({ decorations, direction }: InputDecorationsListProps) {
  const resolved =
    decorations instanceof Function
      ? decorations({
          Text: TextDecoration,
          Icon: IconDecoration,
          Button: ButtonDecoration,
          Dropdown: DropdownDecoration,
        })
      : decorations;

  const decorationsList = Array.isArray(resolved)
    ? (resolved as DecorationDelegateReturn[])
    : [resolved];

  const Direction = direction === 'prepend' ? InputGroup.Prepend : InputGroup.Append;

  return (
    <DirectionContext.Provider value={Direction}>
      {decorationsList.map((item, index) => (
        <React.Fragment key={index}>
          {typeof item === 'string' ? <TextDecoration>{item}</TextDecoration> : item}
        </React.Fragment>
      ))}
    </DirectionContext.Provider>
  );
}
