import React, { useContext } from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { localeText } from '../locales/locale';
import { LocaleContext } from '../locales/LocaleContext';

// creating a custom component rather than reusing the common WithIcon so that we could colocate styles
const WithIcon = ({ icon, children }) => (
  <Col role="list-item" xs={12} sm={12} md={4}>
    <div className={'py-3'}>
      <i className={`${icon} ${css(styles.icon)}`} aria-hidden="true" />
      {children}
    </div>
  </Col>
);

WithIcon.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
};

const ApplicationTips = () => {
  const locale = useContext(LocaleContext);

  return (
    <Row role="list">
      <WithIcon icon={'fa fa-laptop'}>{localeText(locale)['splash:tips_computer']}</WithIcon>
      <WithIcon icon={'fa fa-history fa-flip-horizontal'}>
        {localeText(locale)['splash:tips_timer']}
      </WithIcon>
      <WithIcon icon={'fa fa-envelope'}>{localeText(locale)['splash:tips_envelope']}</WithIcon>
    </Row>
  );
};

const styles = StyleSheet.create({
  icon: {
    fontSize: '3em',
    float: 'left',
    marginRight: '0.5em',
  },
});

export default ApplicationTips;
