import React, { useRef, useEffect } from 'react';

declare global {
  interface Window {
    __PRIVATE_REMOUNT_THREEPLAY_ACCESS_PLAYER?: () => void;
  }
}

interface JavaScriptPlayerSnippetProps {
  previewSnippet: HTMLElement;
}

export function JavaScriptPlayerSnippet({ previewSnippet }: JavaScriptPlayerSnippetProps) {
  const ref = useRef<HTMLDivElement>(null);
  const rendered = useRef(false);

  // We only run this once. We tell React to fully remount this component via
  // the key on the parent (see PlayerPreview).
  useEffect(() => {
    // We intentionally only want to render this once; so we use an explicit
    // variable to avoid mistakes.
    if (!rendered.current) {
      ref.current?.append(previewSnippet);

      // Annoyingly, if we have loaded the script within the previewSnippet
      // it appears it does not get rerun. This means the side effect of
      // rendering does not happen the second time we render.
      //
      // To resolve this; we expose a private method on the window that we
      // can manually invoke a preact render with.
      window.__PRIVATE_REMOUNT_THREEPLAY_ACCESS_PLAYER &&
        window.__PRIVATE_REMOUNT_THREEPLAY_ACCESS_PLAYER();
    }

    rendered.current = true;
  });

  return <div ref={ref}></div>;
}
