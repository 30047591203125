import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import { threeplayApi } from '~/logic/ThreeplayApi';
import logoPath from '~/helpers/logos';

function LinkIntegrationModal(props) {
  const [step, setStep] = useState(1);
  const [selectedVpi, setSelectedVpi] = useState(null);
  const [videoId, setVideoId] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errorsAlert, setErrorsAlert] = useState(null);

  useEffect(() => {
    setVideoId(props.videoId);
  }, [props.videoId]);

  async function linkIntegration() {
    setSubmitting(true);
    const response = await threeplayApi.mutate(linkIntegrationQuery());

    // Server error
    if (response.errors) {
      setSubmitting(false);
      setErrorsAlert(response.errors);
    }

    // Data error
    if (response.data.linkIntegration.error) {
      setSubmitting(false);
      setErrorsAlert(response.data.linkIntegration.errors);
    }
    // Success
    else {
      setSuccess(response.data.linkIntegration.success);
      setTimeout(() => {
        props.onClose();
        setSubmitting(false);
        setSuccess(null);
        setStep(1);
        props.onUpdate();
      }, 3000);
    }
  }

  function linkIntegrationQuery() {
    return `
      linkIntegration(fileId: ${props.fileId}, integrationId: ${selectedVpi}, videoId: "${videoId}"){
        success, error
      }
    `;
  }

  function stepOneBody() {
    return (
      <Table striped bordered>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Provider</th>
          </tr>
        </thead>
        <tbody>
          {props.vpis.map((vpi) => (
            <tr key={parseInt(vpi.id)}>
              <td width="10%">
                <Form.Check
                  id={`vpi-${vpi.id}`}
                  value={parseInt(vpi.id)}
                  onChange={() => setSelectedVpi(parseInt(vpi.id))}
                  type="radio"
                />
              </td>
              <td width="40%">{vpi.name}</td>
              <td>
                <img className="img-fluid" src={logoPath(vpi.platformName)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  function stepTwoBody() {
    return (
      <Form.Group as={Form.Row} controlId="videoId">
        <Form.Label column sm={2}>
          Video Id:
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type="text"
            placeholder="Enter video id"
            value={videoId || ''}
            onChange={(e) => setVideoId(e.target.value)}
          />
        </Col>
      </Form.Group>
    );
  }

  function successAlert(success) {
    return (
      <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
        <Alert.Heading>Success!</Alert.Heading>
        {success}
      </Alert>
    );
  }
  function errorAlert(errorsAlert) {
    return (
      <Alert variant="danger" onClose={() => setErrorsAlert(null)} dismissible>
        <Alert.Heading>Error!</Alert.Heading>
        <p>{errorsAlert}</p>
      </Alert>
    );
  }

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Connect to Linked Account</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
        {success && successAlert(success)}
        {errorsAlert && errorAlert(errorsAlert)}
        {step === 1 && stepOneBody()}
        {step === 2 && stepTwoBody()}
      </Modal.Body>

      <Modal.Footer>
        {step === 1 && (
          <Button
            size="sm"
            variant="primary"
            onClick={() => setStep(2)}
            disabled={selectedVpi === null}
          >
            Continue
          </Button>
        )}
        {step === 2 && (
          <>
            <Button size="sm" variant="secondary" onClick={() => setStep(1)}>
              Back
            </Button>
            <Button
              size="sm"
              variant="primary"
              onClick={() => linkIntegration()}
              disabled={videoId === '' || submitting}
            >
              Submit
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

LinkIntegrationModal.propTypes = {
  show: PropTypes.bool,
  vpis: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      platformName: PropTypes.string,
    })
  ),
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
  fileId: PropTypes.string,
  videoId: PropTypes.string,
};
export default LinkIntegrationModal;
