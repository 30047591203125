import React from 'react';
import PropTypes from 'prop-types';

import NewChallengeModal from './NewChallengeModal';
import InProgressChallengeModal from './InProgressChallengeModal';
import { MEMBERSHIP_STATES } from '../constants';

const ChallengeModal = (props) => {
  switch (props.membershipState) {
    case MEMBERSHIP_STATES.eligible:
      return <NewChallengeModal {...props} />;
    case MEMBERSHIP_STATES.accepted:
      return <InProgressChallengeModal {...props} />;
    default:
      return null;
  }
};

ChallengeModal.propTypes = {
  challenge: PropTypes.object,
  show: PropTypes.bool,
  markChallengeAsAccepted: PropTypes.func,
  onClose: PropTypes.func,
  membershipState: PropTypes.string,
};

export default ChallengeModal;
