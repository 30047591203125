import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Textarea } from '@threeplayground/index';

import S3UploadComponent from '~/components/app/media_files/order_more/S3UploadComponent';
import SelectOptionsComponent from '~/components/app/media_files/order_more/SelectOptionsComponent';
import FileLinkComponent from '~/components/app/media_files/order_more/FileLinkComponent';

const ReformatsForm = ({
  mediaFile,
  onlyReformatMixing,
  options,
  selectedFile,
  selectedLanguages,
  selectedScriptingFormats,
  setErrors,
  setOnlyReformatMixing,
  setSelectedFile,
  setSelectedLanguages,
  setSelectedScriptingFormats,
  setReformatFileLink,
  setReformatInstructions,
  setSourceUploaded,
  showAudioDescriptionReformatCheckbox,
  sourceUploaded,
  supportedFormats,
}) => {
  return (
    <>
      <h4>{mediaFile.name}</h4>
      <br />
      <h5>Upload File For Reformat</h5>
      <S3UploadComponent
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        sourceUploaded={sourceUploaded}
        setSourceUploaded={setSourceUploaded}
        setErrors={setErrors}
        setSelectedFileUrl={setReformatFileLink}
        mediaFile={mediaFile}
        supportedFormats={supportedFormats}
      />
      <br />
      <div style={{ marginBottom: '2rem' }}>
        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>- OR -</p>
        {/* TODO: Should this really be the same state as used by the UploadComponent? */}
        <FileLinkComponent enabled={sourceUploaded === false} setFileLink={setReformatFileLink} />
      </div>

      {options.languages.length > 0 && (
        <SelectOptionsComponent
          options={options.languages}
          optionName="Langauges"
          selectedOptions={selectedLanguages}
          setSelectedOptions={setSelectedLanguages}
        />
      )}
      {options.broadcastScriptingFormats.length > 0 && (
        <SelectOptionsComponent
          options={options.broadcastScriptingFormats}
          optionName="Formats"
          selectedOptions={selectedScriptingFormats}
          setSelectedOptions={setSelectedScriptingFormats}
        />
      )}
      {showAudioDescriptionReformatCheckbox && (
        <Form.Check
          type="checkbox"
          id="adReformatCheckbox"
          label="I only need to reformat audio mixing."
          checked={onlyReformatMixing}
          onChange={() => setOnlyReformatMixing((curr) => !curr)}
        />
      )}
      <Textarea
        label="Reformat Instructions"
        placeholder="Enter any additional instructions for this reformat here."
        onChange={(e) => setReformatInstructions(e.target.value)}
        required
      />
    </>
  );
};

const optionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    displayName: PropTypes.string,
  })
);

ReformatsForm.propTypes = {
  mediaFile: PropTypes.object,
  onlyReformatMixing: PropTypes.bool,
  options: PropTypes.shape({
    broadcastScriptingFormats: optionsShape,
    languages: optionsShape,
    mixingOptions: PropTypes.shape({
      mix: optionsShape,
      embed: optionsShape,
    }),
  }),
  setErrors: PropTypes.func,
  selectedFile: PropTypes.shape({
    path: PropTypes.string,
    lastModified: PropTypes.number,
    lastModifiedDate: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    webkitRelativePath: PropTypes.string,
  }),
  selectedLanguages: PropTypes.any,
  selectedScriptingFormats: PropTypes.any,
  setOnlyReformatMixing: PropTypes.func,
  setReformatFileLink: PropTypes.func,
  setReformatInstructions: PropTypes.func,
  setSelectedFile: PropTypes.func,
  setSelectedLanguages: PropTypes.func,
  setSelectedScriptingFormats: PropTypes.func,
  setSourceUploaded: PropTypes.func,
  showAudioDescriptionReformatCheckbox: PropTypes.bool,
  sourceUploaded: PropTypes.bool,
  supportedFormats: PropTypes.arrayOf(PropTypes.string),
};

export default ReformatsForm;
