import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { checkKeyPress } from '~/helpers/checkKeyPress';

function SearchComponent(props) {
  function clearSearchResults() {
    props.setSearchTerm('');
    props.searchIntegration('');
  }

  function placeholderTextForPlatform(platformName, selectedPlaylistId = '') {
    switch (platformName) {
      case 'JW Platform': {
        return 'Search by video name, ID, or tag';
      }
      case 'Vidyard':
      case 'Wistia': {
        return 'Search by exact video name';
      }
      case 'Box':
      case 'Dropbox':
      case 'Ensemble':
      case 'Kaltura':
      case 'Vimeo': {
        return 'Search by video name';
      }
      case 'YouTube': {
        if (selectedPlaylistId === '') return 'Enter search terms';
        else return 'Enter video ID';
      }
      default: {
        return 'Search by video name or ID';
      }
    }
  }

  return (
    <div className="d-flex justify-content-end w-100">
      <InputGroup className="m-2 p-0 col-sm-6">
        <FormControl
          aria-label={placeholderTextForPlatform(
            props.integration.platformName,
            props.searchParams.playlistId
          )}
          value={props.searchTerm}
          onChange={(e) => props.setSearchTerm(e.target.value)}
          onKeyDown={(e) => checkKeyPress(e, () => props.searchIntegration(props.searchTerm))}
          placeholder={placeholderTextForPlatform(
            props.integration.platformName,
            props.searchParams.playlistId
          )}
        />
        <InputGroup.Append>
          <Button
            className={css(appStyles.clearButton)}
            variant="link"
            onClick={() => clearSearchResults()}
          >
            &times;
          </Button>
          <Button
            aria-label="Search"
            variant="primary"
            onClick={() => props.searchIntegration(props.searchTerm)}
          >
            Search
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
}

SearchComponent.propTypes = {
  integration: PropTypes.object,
  searchIntegration: PropTypes.func,
  searchParams: PropTypes.object,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

export default SearchComponent;
