import { LogLevel } from '~/logic/Logger/_types';
import { Caption } from '../CaptionCreator/Caption';
import { CuriousCaptionMatcher } from './_types';

interface RepeatedCharactersMatcherConfig {
  /** The number of repeated characters that will trigger the matcher */
  numberOfRepeats?: number;
}

export class RepeatedCharactersMatcher implements CuriousCaptionMatcher {
  logLevel: LogLevel = 'warn';

  /** The number of repeated characters that will trigger the matcher */
  private numberOfRepeats: number;

  constructor({ numberOfRepeats = 4 }: RepeatedCharactersMatcherConfig = {}) {
    this.numberOfRepeats = numberOfRepeats;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  matches(_prevCaption: Caption | undefined, newCaption: Caption) {
    return new RegExp(`(.)\\1{${this.numberOfRepeats - 1},}`).test(
      newCaption.captionWordsArray.join(' ')
    );
  }
}
