import React from 'react';
import Table from 'react-bootstrap/Table';

const PUNCTUATION_COMMANDS_MAP: Record<string, string> = {
  'bam bam': '[>>]',
  comma: ',',
  peerk: '.',
  peermak: '.',
  kyumak: '?',
  quex: '?',
  sklam: '!',
  apostrophe: "'",
  hyphen: '-',
  'double dash': ' --',
  colon: ':',
};

function PunctuationCommands() {
  return (
    <>
      <h2 className="swatei-title">Punctuation Commands</h2>
      <Table>
        <tbody>
          {Object.keys(PUNCTUATION_COMMANDS_MAP).map((command) => {
            return (
              <tr key={command}>
                <td className="w-50">{command}</td>
                <td>{PUNCTUATION_COMMANDS_MAP[command]}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default PunctuationCommands;
