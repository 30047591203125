import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import { Card, OverlayTrigger, Popover, PopoverContent } from 'react-bootstrap';

import { threeplayApi } from '~/logic/ThreeplayApi';

import ErrorOverlay from '~/components/app/common/error_boundaries/ErrorOverlay';

import TurnaroundLevelCard from '~/components/app/order_form/TurnaroundLevelCard';
import ServiceLevelCard from '~/components/app/order_form/ServiceLevelCard';
import SpeakerTypeCard from '~/components/app/order_form/SpeakerTypeCard';
import OrderOptionsContainer from '~/components/app/order_form/serviceOptions/OrderOptionsContainer';
import { additionalServicesQuery } from '~/components/app/order_form/data/queries';

import {
  AD_OPTIONS_START_INDEX,
  AD_SPEAKERS_START_INDEX,
  AD_TURNAROUND_START_INDEX,
} from '~/helpers/constants';
import ObnoxiousYellowBox from '~/components/app/order_form/units/ObnoxiousYellowBox';

import { ProjectContext } from '../OrderForm';
import AudioDescriptionMixSection from '../AudioDescriptionMixSection';

function AudioDescriptionOrderOptions(props) {
  const [availableServiceLevels, setAvailableServiceLevels] = useState([]);
  const [availableTurnaroundLevels, setAvailableTurnaroundLevels] = useState([]);
  const [availableSpeakerTypes, setAvailableSpeakerTypes] = useState([]);
  const [errors, setErrors] = useState([]);
  const { accountType } = useContext(ProjectContext);

  const isVoiceArtistAD = props.orderOptions?.speakerType?.name === 'Voice Artist';
  const voiceArtistServiceType = 'AudioDescriptions::VoiceOvers::VoiceOverAudioDescriptionService';

  useEffect(() => {
    threeplayApi
      .request(additionalServicesQuery, {
        languageType: props.selectedLanguage,
        primaryServiceType: props.selectedPrimaryService.serviceType,
        primaryServiceTurnaroundLevel:
          props.selectedPrimaryService.orderOptions.turnaroundLevel &&
          parseInt(props.selectedPrimaryService.orderOptions.turnaroundLevel.id),
        selectedAdditionalServiceType: 'AudioDescription',
      })
      .then((res) => {
        const data = res.data || {};
        const options = data.project?.orderableServices[0].additionalServices.find(
          (service) => service.serviceType == 'AudioDescription'
        ).orderOptions;
        if (res.errors) {
          setErrors(res.errors.map((error) => error.message));
        } else if (options.turnaroundLevels || options.serviceLevels) {
          setAvailableServiceLevels(options.serviceLevels);
          setAvailableTurnaroundLevels(options.turnaroundLevels);
          setAvailableSpeakerTypes(options.speakerTypes);
          if (!Object.keys(props.orderOptions).length) {
            // setDefaults
            const defaultTurnaround = options.turnaroundLevels.find(
              (tl) => tl.name === 'Standard AD'
            );
            const defaultServiceLevel = options.serviceLevels.find((sl) => sl.name === 'Standard');
            const defaultSpeakerType = options.speakerTypes?.find(
              (st) => st.name === 'Synthesized'
            );

            props.updateOrderOptions('AudioDescription', {
              ...props.orderOptions,
              turnaroundLevel: {
                id: defaultTurnaround.id,
                name: 'Standard AD',
                displayName: defaultTurnaround.displayName,
                price: defaultTurnaround.surcharge,
                deliveryDate: defaultTurnaround.deliveryDate,
              },
              serviceLevel: {
                name: 'Standard',
                price: defaultServiceLevel.price,
                displayPrice: defaultServiceLevel.displayPrice,
                voiceArtistPrice: defaultServiceLevel?.voiceArtistPrice,
                voiceArtistDisplayPrice: defaultServiceLevel?.voiceArtistDisplayPrice,
              },
              ...(defaultSpeakerType && {
                speakerType: {
                  name: defaultSpeakerType.name,
                  price: defaultSpeakerType.price,
                  displayPrice: defaultServiceLevel.displayPrice,
                },
              }),
            });
          }
        }
      });
  }, []);

  function setTurnaroundLevel(turnaroundLevelId) {
    const turnaroundLevel = availableTurnaroundLevels.find((tl) => tl.id === turnaroundLevelId);
    props.updateOrderOptions('AudioDescription', {
      ...props.orderOptions,
      turnaroundLevel: {
        id: turnaroundLevel.id,
        name: turnaroundLevel.name,
        displayName: turnaroundLevel.displayName,
        price: turnaroundLevel.surcharge,
        deliveryDate: turnaroundLevel.deliveryDate,
      },
    });
  }

  function setServiceLevel(serviceLevel) {
    const selectedServiceLevel = availableServiceLevels.find((sl) => sl.name === serviceLevel);

    // Remove mixing options that are invalid for extended if serviceLevel is not standard
    let outputAssetOptions = { ...props.orderOptions?.outputAssetOptions };
    if (outputAssetOptions) {
      outputAssetOptions = Object.fromEntries(
        Object.entries(outputAssetOptions).filter(([key, value]) => {
          return value?.validForExtended || serviceLevel === 'Standard';
        })
      );
    }

    props.updateOrderOptions('AudioDescription', {
      ...props.orderOptions,
      outputAssetOptions,
      serviceLevel: {
        name: serviceLevel,
        price: selectedServiceLevel.price,
        displayPrice: selectedServiceLevel.displayPrice,
        voiceArtistPrice: selectedServiceLevel?.voiceArtistPrice,
        voiceArtistDisplayPrice: selectedServiceLevel?.voiceArtistDisplayPrice,
      },
    });
  }

  function setSpeakerType(speakerType) {
    if (speakerType === 'Voice Artist') {
      const standardServiceLevel = availableServiceLevels.find((sl) => sl.name === 'Standard');
      const standardTurnaround = availableTurnaroundLevels.find(
        (tl) => tl.name.includes('Standard') && tl.serviceType === voiceArtistServiceType
      );
      props.updateOrderOptions('AudioDescription', {
        ...props.orderOptions,
        serviceLevel: {
          name: standardServiceLevel.name,
          price: standardServiceLevel.price,
          displayPrice: standardServiceLevel.displayPrice,
          voiceArtistPrice: standardServiceLevel?.voiceArtistPrice,
          voiceArtistDisplayPrice: standardServiceLevel?.voiceArtistDisplayPrice,
        },
        speakerType: {
          name: speakerType,
          price: availableSpeakerTypes.find((sl) => sl.name === speakerType).price,
          displayPrice: availableSpeakerTypes.find((sl) => sl.name === speakerType).displayPrice,
        },
        turnaroundLevel: {
          id: standardTurnaround.id,
          name: standardTurnaround.name,
          displayName: standardTurnaround.displayName,
          price: standardTurnaround.surcharge,
          deliveryDate: standardTurnaround.deliveryDate,
        },
      });
    } else {
      const standardTurnaround = availableTurnaroundLevels.find(
        (tl) => tl.name.includes('Standard') && tl.serviceType === 'AudioDescriptionService'
      );
      props.updateOrderOptions('AudioDescription', {
        ...props.orderOptions,
        speakerType: {
          name: speakerType,
          price: availableSpeakerTypes.find((sl) => sl.name === speakerType).price,
          displayPrice: availableSpeakerTypes.find((sl) => sl.name === speakerType).displayPrice,
        },
        turnaroundLevel: {
          id: standardTurnaround.id,
          name: standardTurnaround.name,
          displayName: standardTurnaround.displayName,
          price: standardTurnaround.surcharge,
          deliveryDate: standardTurnaround.deliveryDate,
        },
      });
    }
  }

  function currentlyAvailableTurnaroundLevels() {
    if (isVoiceArtistAD) {
      return availableTurnaroundLevels.filter((tl) => tl.serviceType === voiceArtistServiceType);
    }

    return availableTurnaroundLevels.filter((tl) => tl.serviceType === 'AudioDescriptionService');
  }

  if (errors.length > 0) {
    return (
      <ErrorOverlay
        messages={['Please refresh the page and contact support if the problem persists.']}
      />
    );
  } else {
    return (
      <OrderOptionsContainer title="Audio Description" componentName="AudioDescriptionOrderOptions">
        {availableSpeakerTypes?.length > 0 && (
          <>
            <Card.Title className={css(appStyles.title)}>
              Audio Description Speaker Type&nbsp;
            </Card.Title>
            <div className="d-flex">
              {availableSpeakerTypes?.map((type, index) => (
                <SpeakerTypeCard
                  key={type.name}
                  index={index + AD_SPEAKERS_START_INDEX}
                  name={type.name}
                  price={type.price}
                  displayPrice={type.displayPrice}
                  description={type.description}
                  orderOptions={props.orderOptions}
                  setSpeakerType={setSpeakerType}
                />
              ))}
            </div>
          </>
        )}
        <Card.Title className={css(appStyles.title)}>
          Audio Description Service Level&nbsp;
        </Card.Title>
        <div className="d-flex">
          {availableServiceLevels.map((level, index) => (
            <ServiceLevelCard
              key={level.name}
              index={index + AD_OPTIONS_START_INDEX}
              name={level.name}
              price={isVoiceArtistAD ? level.voiceArtistPrice : level.price}
              displayPrice={isVoiceArtistAD ? level.voiceArtistDisplayPrice : level.displayPrice}
              description={level.description}
              deliveryDate={level.deliveryDate}
              setServiceLevel={setServiceLevel}
              orderOptions={props.orderOptions}
            />
          ))}
        </div>
        <Card.Title className={css(appStyles.title)}>
          Audio Description Turnaround Time&nbsp;
          <OverlayTrigger
            placement="auto"
            trigger={['hover', 'focus']}
            delay={{ hide: 2000 }}
            overlay={
              <Popover>
                <PopoverContent>
                  The delivery date for your audio description is separate from the delivery of your
                  main service.
                </PopoverContent>
              </Popover>
            }
          >
            <i className="fa fa-info-circle"></i>
          </OverlayTrigger>
        </Card.Title>
        <div className="d-flex">
          {currentlyAvailableTurnaroundLevels().map((turnaround, index) => (
            <TurnaroundLevelCard
              id={turnaround.id}
              key={turnaround.name}
              index={index + AD_TURNAROUND_START_INDEX}
              name={turnaround.name}
              displayName={turnaround.displayName}
              price={turnaround.price}
              surcharge={turnaround.surcharge}
              deliveryDate={turnaround.deliveryDate}
              turnaroundDisabled={false}
              turnaroundDisabledTooltip={''}
              setTurnaroundLevel={setTurnaroundLevel}
              orderOptions={props.orderOptions}
            />
          ))}
        </div>
        {props.cheatSheetComponent('audioDescriptionService', {
          showVoiceArtistAudioDescriptionNote: isVoiceArtistAD,
        })}
        {isVoiceArtistAD && accountType.studio && (
          <span>
            <AudioDescriptionMixSection
              orderOptions={props.orderOptions}
              updateOrderOptions={props.updateOrderOptions}
            />
          </span>
        )}
        {isVoiceArtistAD && (
          <ObnoxiousYellowBox
            checked={props.voiceArtistAdWarningSelected}
            title="Voice Artist Audio Description cannot be Refunded"
            toggleChecked={props.setVoiceArtistAdWarningSelected}
          >
            By checking this box, you indicate that you will not have the opportunity to review your
            transcripts before they are submitted for audio description. Voice artist audio
            description will begin as soon as the primary service is completed.
          </ObnoxiousYellowBox>
        )}
      </OrderOptionsContainer>
    );
  }
}

AudioDescriptionOrderOptions.propTypes = {
  cheatSheetComponent: PropTypes.func,
  orderOptions: PropTypes.object,
  selectedLanguage: PropTypes.string,
  setVoiceArtistAdWarningSelected: PropTypes.func,
  updateOrderOptions: PropTypes.func,
  voiceArtistAdWarningSelected: PropTypes.bool,
};

export default AudioDescriptionOrderOptions;
