import React from "react";
import PropTypes from "prop-types";

import { css, StyleSheet } from "aphrodite";
import { Card } from "react-bootstrap";

import OutputAssetShape from "~/components/app/order_more/components/OutputAssetShape";
import selectedStyles from "~/components/app/styles/selectedDeselectedStyles";

import { AUDIO_MIX_CODE_ICON_MAP } from "~/helpers/constants";

const OutputAssetSelector = ({ mixingOptions, selectedOutputAssets, setSelectedOutputAssets }) => {
  const mixIsSelected = (mix) => {
    return selectedOutputAssets.some((selection) => selection.code === mix.code);
  };

  const toggleEmbed = (mix, embed) => {
    if (!mixIsSelected(mix)) {
      return;
    }

    const selectedMix = selectedOutputAssets.find((asset) => asset.code === mix.code);
    let updatedMix;

    if (selectedMix.embeds.some((selected) => selected.code === embed.code)) {
      const updatedEmbeds = selectedMix.embeds.filter((e) => e.id !== embed.id);
      updatedMix = { ...selectedMix, embeds: updatedEmbeds };
    } else {
      updatedMix = { ...selectedMix, embeds: [...selectedMix.embeds, embed] };
    }

    setSelectedOutputAssets([
      ...selectedOutputAssets.filter((asset) => asset.id !== mix.id),
      updatedMix,
    ]);
  };

  const toggleMix = (mix) => {
    if (mixIsSelected(mix)) {
      setSelectedOutputAssets(selectedOutputAssets.filter((asset) => asset.id !== mix.id));
      return;
    }

    setSelectedOutputAssets([...selectedOutputAssets, { ...mix, embeds: [] }]);
  };

  return (
    <>
      <Card.Title>Desired Output Asset</Card.Title>
      <div className="d-flex">
        {mixingOptions.mix.map((mix) => (
          <div key={mix.code} className="d-inline-block mr-4">
            <Card
              bg="light"
              className={
                mixIsSelected(mix)
                  ? css(selectedStyles.selected, styles.box, styles.center, styles.mix)
                  : css(selectedStyles.deselected, styles.box, styles.center, styles.mix)
              }
              onClick={() => toggleMix(mix)}
            >
              <Card.Body>
                <img src={AUDIO_MIX_CODE_ICON_MAP[mix.code]} alt="" />
                <div className={css(styles.bold)}>{mix.adDisplayName}</div>
              </Card.Body>
            </Card>
            {mixIsSelected(mix) && (
              <div style={{ paddingLeft: '0.25rem' }}>
                <p>What type of assets do you need?</p>
                {mixingOptions.embed.map((embed) => (
                  <div key={embed.code}>
                    <input type="checkbox" onChange={() => toggleEmbed(mix, embed)} />
                    <label style={{ paddingLeft: '0.25rem' }}>{embed.adDisplayName}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

OutputAssetSelector.propTypes = {
  mixingOptions: PropTypes.shape({
    embed: PropTypes.arrayOf(OutputAssetShape),
    mix: PropTypes.arrayOf(OutputAssetShape),
  }),
  selectedOutputAssets: PropTypes.arrayOf(
    PropTypes.shape({
      ...OutputAssetShape,
      embeds: PropTypes.arrayOf(OutputAssetShape),
    })
  ),
  setSelectedOutputAssets: PropTypes.func,
};

const styles = StyleSheet.create({
  bold: {
    'font-weight': 'bold',
  },
  box: {
    'margin-right': '0.25rem',
    cursor: 'pointer',
  },
  center: {
    'text-align': 'center',
  },
  mix: {
    width: '18rem',
  },
});

export default OutputAssetSelector;
