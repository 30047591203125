import { Formatter } from '../_types';

export class RemoveExcessWhitespaceFormatter implements Formatter {
  /**
   * A formatter that removes repeated white space.
   *
   * @example new RemoveExcessWhitespaceFormatter().format(" hello   there  ")
   * // returns " hello there "
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  format(text: string) {
    return text.replace(/(\s)(\s+)/g, (_match, p1: string) => p1);
  }
}
