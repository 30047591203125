import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

function OverviewBody(props) {
  return <div className={css(styles.body)}>{props.children}</div>;
}

OverviewBody.propTypes = {
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  body: {
    width: '100%',
    flex: '1 1 auto',
    overflowY: 'scroll',
  },
});

export default OverviewBody;
