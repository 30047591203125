import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  serviceOptionHeader: {
    display: 'flex',
  },
  serviceOptionPrice: {
    'margin-left': 'auto',
  },
});

export default styles;
