import React from 'react';
import PropTypes from 'prop-types';

import MultiServiceTable from './MultiServiceTable';
import ViccRating from './ViccRating';
import ViccTimeline from './ViccTimeline';

import { mediaFilePath } from '~/helpers/app/paths';

function VideoClipCaptioningParentServiceCard(props) {
  const headers = ['Clip Name', 'Clip ID', 'Clip Timeline', 'Status', 'Rating'];
  const service = props.service || {};
  const childGroup = service.childGroup || {};
  const children = childGroup.services || [];
  const file = service.mediaFile || {};
  const duration = file.duration || 3600;

  return (
    <MultiServiceTable headers={headers}>
      {children.map((child) => {
        const clipMap = child.clipMap || {};
        const matchedRegions = clipMap.matchedRegions || [];
        const childFile = child.mediaFile || {};
        return (
          <tr key={service.id}>
            <td>{childFile && <a href={mediaFilePath(childFile.id)}>{childFile.name}</a>}</td>
            <td>{childFile.id}</td>
            <td>
              {matchedRegions && (
                <ViccTimeline matchedRegions={matchedRegions} parentDuration={duration} />
              )}
            </td>
            <td>
              {childGroup.status.replace('_', ' ').replace(/\b([a-z])/g, (m) => m.toUpperCase())}
            </td>
            <td>{clipMap.score && <ViccRating score={clipMap.score} />}</td>
          </tr>
        );
      })}
    </MultiServiceTable>
  );
}

VideoClipCaptioningParentServiceCard.propTypes = {
  service: PropTypes.object,
};

export default VideoClipCaptioningParentServiceCard;
