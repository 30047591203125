import React from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';

export const PreviousButton = ({ onClick, disabled = false }) => (
  <>
    <Button
      variant="3play-outline"
      onClick={onClick}
      disabled={disabled}
      className={css(styles.buttons)}
    >
      Previous Step
    </Button>{' '}
  </>
);

PreviousButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export const NextButton = ({ onClick = () => {}, disabled = false }) => (
  <Button variant="3play" disabled={disabled} onClick={onClick} className={css(styles.buttons)}>
    Save and Continue
  </Button>
);

NextButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const styles = StyleSheet.create({
  buttons: {
    marginTop: '1em',
  },
});
