import React, { useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import BulkUpdateJobs from '~/components/ops/market/updateJobs/BulkUpdateJobs';
import MarketSummary from './MarketSummary';
import ModalErrorBoundary from '~/components/ops/common/ModalErrorBoundary';
import JobGraph from './JobGraph';
import JobGraphSettings from './JobGraphSettings';
import JobsTable from './tablez/JobsTable';

import { jobShape } from '../shapes';

function JobGraphUI({ jobs, selectedJobs, setSelectedJobs }) {
  const [showBulkUpdate, setShowBulkUpdate] = useState(false);

  const [color, setColor] = useState('priorityStars');
  const [radius, setRadius] = useState('duration');
  const [sort, setSort] = useState('priorityStars');
  const [x, setX] = useState('hoursToDeadline');
  const [y, setY] = useState('accessCount');

  // Other supported configurable dimensions include:
  // fillOpacity
  // strokeOpacity
  // strokeWidth
  // xDomain
  // yDomain

  const graphSettings = {
    color,
    radius,
    sort,
    x,
    y,
  };

  const setGraphSettings = {
    setColor,
    setRadius,
    setSort,
    setX,
    setY,
  };

  function unSelectJob(jobId) {
    setSelectedJobs(selectedJobs.filter((job) => job.id !== jobId));
  }

  return (
    <div className="d-flex flex-column">
      <ModalErrorBoundary>
        <Modal show={showBulkUpdate} onHide={() => setShowBulkUpdate(false)}>
          <Modal.Header closeButton>Bulk Update</Modal.Header>
          <Modal.Body>
            <BulkUpdateJobs jobIds={selectedJobs.map((job) => job.id)} />
          </Modal.Body>
        </Modal>
      </ModalErrorBoundary>

      <MarketSummary jobs={jobs} />

      <div className="d-flex flex-row">
        <div>
          <JobGraph jobs={jobs} onSelectJobs={setSelectedJobs} {...graphSettings} />
        </div>
        <div>
          <JobGraphSettings {...graphSettings} {...setGraphSettings} />
        </div>
      </div>

      {selectedJobs.length > 0 && (
        <div>
          <div className={css(styles.actionBar)}>
            <Button size="sm" onClick={() => setShowBulkUpdate(true)}>
              Bulk Update
            </Button>
          </div>
          <div className={css(styles.jobsTable)}>
            <JobsTable jobs={selectedJobs} onRemoveJob={unSelectJob} />
          </div>
        </div>
      )}
    </div>
  );
}

JobGraphUI.propTypes = {
  jobs: PropTypes.arrayOf(jobShape),
  selectedJobs: PropTypes.arrayOf(jobShape),
  setSelectedJobs: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  actionBar: {
    marginBottom: '1em',
  },
  jobsTable: {
    paddingRight: '1em',
    maxHeight: '500px',
    overflowY: 'scroll',
  },
});

export default JobGraphUI;
