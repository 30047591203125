import React from 'react';

import { StyleSheet, css } from 'aphrodite';

import SuccessImage from './images/signon_success.png';

function WhatsNew() {
  return (
    <div className={css(styles.main)}>
      <img src={SuccessImage} className={css(styles.mainImage)} />
    </div>
  );
}

const styles = StyleSheet.create({
  main: {
    width: '100%',
    height: '100%',
    background: '#000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainImage: {
    maxWidth: '100%',
  },
});

export default WhatsNew;
