// repairSpeakerLabels converts non utf-8 characters to ??
// This will notify users that they should modify their transcripts to be proper utf-8 characters
export function checkForIllegalQuestionMarks(alignmentText) {
  const pos = alignmentText.indexOf('??');
  if (pos > -1) {
    return ['Non utf-8 characters found. Please fix before submitting'];
  }
  return [];
}

export function checkForBlankTranscript(alignmentText) {
  let error = [];
  if (alignmentText.length < 1) {
    error = error.concat(['Blank transcript']);
  }
  return error;
}

// converts lower case speaker labels to UPPER CASE: (colon)
// converts non utf-8 characters to ??
export function repairSpeakerLabels(alignmentText) {
  const ps = alignmentText.split(/[\n\r]+/);
  const new_ps = [];
  ps.forEach((p) => {
    p = p.replace(/^([A-Za-z0-9\s\-\_\,\'\.]{1,30}?\:)/, function (s, group1) {
      return group1.toUpperCase();
    });
    new_ps.push(p);
  });
  return new_ps.join('\n\n');
}
