import { PunctuationFormatter, PunctuationFormatterConfig } from '../_types';

export class MidSentencePunctuationFormatter implements PunctuationFormatter {
  command: PunctuationFormatterConfig['command'];
  punctuation: PunctuationFormatterConfig['punctuation'];

  /**
   * A formatter that adds the punctuation and removes any leading white spaces
   *
   * @example new MidSentencePunctuationFormatter({ command: 'comma', punctuation: ',' }).format("hello comma there")
   * // returns "hello, there"
   */
  constructor({ command, punctuation }: PunctuationFormatterConfig) {
    this.command = command;
    this.punctuation = punctuation;
  }

  format(text: string) {
    return text.replace(this.regex(), this.punctuation);
  }

  regex() {
    return new RegExp(`\\s*\\b${this.command}\\b`, 'gi');
  }
}
