import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AlignmentErrorModal(props) {
  return (
    <Modal show={props.show} size="lg" onHide={() => props.closeAlignmentErrorModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Error Submitting Transcripts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.filesToUpload?.some((mediaFile) => !mediaFile.alignmentText) && (
          <p>
            You have blank alignment transcripts. Please make sure that you have pasted in
            transcripts for all of your files.
          </p>
        )}
        {props.filesToUpload
          ?.filter((mediaFile) => mediaFile.alignmentText?.errors?.length > 0)
          .map((file, index) => (
            <p key={index}>
              <strong>{file.name}:</strong>
              {file.alignmentText?.errors?.join(',')}
            </p>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.closeAlignmentErrorModal()} variant="primary">
          Ok, Got it
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AlignmentErrorModal.propTypes = {
  closeAlignmentErrorModal: PropTypes.func,
  filesToUpload: PropTypes.arrayOf(PropTypes.object),
  show: PropTypes.bool,
};

export default AlignmentErrorModal;
