import React, { useRef } from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

const NUDGE_SECONDS = 3;

function TestFile(props) {
  const media = useRef(null);
  const mediaElement = media.current;
  const { test } = props;

  function inputHandler(index) {
    return (event) => props.onUpdate(index, event.target.value);
  }

  function handleKeyPress(event) {
    if (event.ctrlKey && event.key === ' ') {
      handleMediaCommandKey('play/pause');
      event.preventDefault();
    } else if (event.ctrlKey && event.key === '[') {
      handleMediaCommandKey('nudgeBackward');
      event.preventDefault();
    } else if (event.ctrlKey && event.key === ']') {
      handleMediaCommandKey('nudgeForward');
      event.preventDefault();
    }
  }

  function handleMediaCommandKey(command) {
    if (command === 'play/pause') {
      togglePlay();
    } else if (command === 'nudgeBackward') {
      nudgeBackward();
    } else if (command === 'nudgeForward') {
      nudgeForward();
    }
  }

  function togglePlay() {
    if (mediaElement.paused) {
      mediaElement.play();
    } else {
      mediaElement.pause();
    }
  }

  function nudgeBackward() {
    const t = Math.max(0, mediaElement.currentTime - NUDGE_SECONDS);
    mediaElement.currentTime = t;
  }

  function nudgeForward() {
    const t = Math.min(mediaElement.duration || 0, mediaElement.currentTime + NUDGE_SECONDS);
    mediaElement.currentTime = t;
  }

  return (
    <>
      <h2 className={css(styles.fileHeader)}>{test.name}</h2>
      <div className="d-flex">
        <p className={css(styles.flex1)}>
          <strong>Instructions:</strong> {test.instructions}
        </p>
        <audio
          ref={media}
          controls
          className={css(styles.flex1)}
          onPlay={props.handlePlay}
          data-index={props.index}
        >
          <source src={test.testFile.url} />
        </audio>
      </div>
      <strong>Transcript for {test.name}</strong>
      <Form.Control
        as="textarea"
        onChange={inputHandler(props.index)}
        rows={8}
        value={test.transcript || ''}
        className={css(styles.transcript)}
        data-index={props.index}
        onKeyUp={handleKeyPress}
      />
    </>
  );
}

TestFile.propTypes = {
  index: PropTypes.number.isRequired,
  handlePlay: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  test: PropTypes.shape({
    instructions: PropTypes.string,
    name: PropTypes.string,
    transcript: PropTypes.string,
    testFile: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
};

const styles = StyleSheet.create({
  fileHeader: {
    marginTop: '1em',
  },
  flex1: {
    flex: 1,
  },
  transcript: {
    maxWidth: '775px',
  },
});

export default TestFile;
