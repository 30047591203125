import React from 'react';
import PropTypes from 'prop-types';
import EngagementProgram from './EngagementProgram';

const EngagementProgramTracker = ({ data = [] }) => {
  return !data.length ? (
    <></>
  ) : (
    <div data-testid="engagement-program-tracker">
      {data.map((engagementProgram, index) => (
        <EngagementProgram key={index} engagementProgram={engagementProgram} />
      ))}
    </div>
  );
};

EngagementProgramTracker.propTypes = {
  data: PropTypes.array,
};

export default EngagementProgramTracker;
