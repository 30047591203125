import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import { SelectInput } from '../SelectInput/SelectInput';

/**
 * @deprecated Use `Select instead; it has all behavior for variants built in
 * with generic / typing support.
 */
export const VariantSelect = ({
  variants,
  fieldId,
  label,
  register,
  name,
  required,
  showPlaceholder,
  placeholder,
  onChange,
  isInvalid,
  ...selectProps
}) => {
  const firstValue = variants[0]?.id;
  const [value, setValue] = useState(showPlaceholder ? null : firstValue);

  isInvalid = isInvalid || (required && value == null);

  const handleChange = useCallback(
    (event) => {
      onChange && onChange(event);
      setValue(event.target.value);
    },
    [onChange]
  );

  return (
    <SelectInput
      aria-label={`variants-${name}`}
      fieldId={fieldId}
      isInvalid={isInvalid}
      label={label}
      onChange={handleChange}
      register={register}
      {...selectProps}
    >
      {showPlaceholder && (
        <option value="" hidden={required}>
          {placeholder}
        </option>
      )}
      {variants.map(({ id, displayName }) => {
        return (
          <option key={id} value={id}>
            {displayName}
          </option>
        );
      })}
    </SelectInput>
  );
};

VariantSelect.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      description: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
  fieldId: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  placeholder: PropTypes.string,
};

VariantSelect.defaultProps = {
  name: 'list',
  isInvalid: false,
  required: false,
  showPlaceholder: true,
  placeholder: ' -- Please select -- ',
};
