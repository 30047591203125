import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX } from '~/helpers/constants';

import { addBillingInfoPath } from '~/helpers/app/paths';

function BillingInformation(props) {
  const [error, setError] = useState('');
  const [billingInfo, setBillingInfo] = useState(props.billingInformation);
  const [validated, setValidated] = useState(false);

  function formData() {
    const data = new FormData();
    data.append('billing_information[billing_phone_number]', billingInfo.billingPhoneNumber);
    data.append('billing_information[billing_address_one]', billingInfo.billingAddressOne);
    if (billingInfo.billingAddressTwo) {
      data.append('billing_information[billing_address_two]', billingInfo.billingAddressTwo);
    }
    if (billingInfo.billingAddressThree) {
      data.append('billing_information[billing_address_three]', billingInfo.billingAddressThree);
    }
    if (billingInfo.billingAddressFour) {
      data.append('billing_information[billing_address_four]', billingInfo.billingAddressFour);
    }
    data.append('billing_information[billing_address_city]', billingInfo.billingAddressCity);
    data.append('billing_information[billing_address_state]', billingInfo.billingAddressState);
    data.append('billing_information[billing_address_country]', billingInfo.billingAddressCountry);
    data.append('billing_information[billing_address_zipcode]', billingInfo.billingAddressZipcode);
    if (billingInfo.purchaseOrder) {
      data.append('billing_information[purchase_order]', billingInfo.purchaseOrder);
    }
    data.append('authenticity_token', props.authenticityToken);
    return data;
  }

  function updateView() {
    props.fetchManagePlanData();
    props.closeInvoiceBillingForm();
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const data = formData();
    fetch(addBillingInfoPath, {
      method: 'PUT',
      body: data,
    })
      .then((response) => response.json())
      .then((resp) => updateView(resp))
      .catch((resp) => setError(resp.error));
  }

  const enableSubmit =
    billingInfo.billingPhoneNumber &&
    billingInfo.billingAddressOne &&
    billingInfo.billingAddressCity &&
    billingInfo.billingAddressState &&
    billingInfo.billingAddressCountry &&
    billingInfo.billingAddressZipcode;

  return (
    <Form validated={validated}>
      <BillingInformationForm
        billingInfo={billingInfo}
        setBillingInfo={setBillingInfo}
        setValidated={setValidated}
      />
      <ButtonGroup>
        {props.billingInformation.validBillingAddress && (
          <Button
            size="sm"
            variant="outline-primary"
            onClick={props.closeInvoiceBillingForm}
            tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 12}
          >
            Cancel
          </Button>
        )}
        <Button
          className="ml-2"
          size="sm"
          variant="primary"
          disabled={!enableSubmit}
          onClick={handleSubmit}
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 13}
        >
          Save Billing Information
        </Button>
      </ButtonGroup>
      {error && <Row>{error}</Row>}
    </Form>
  );
}

BillingInformation.propTypes = {
  authenticityToken: PropTypes.string,
  closeInvoiceBillingForm: PropTypes.func,
  fetchManagePlanData: PropTypes.func,
  billingInformation: PropTypes.object,
};

export const BillingInformationForm = ({
  billingInfo,
  setBillingInfo,
  setValidated = () => {},
}) => {
  function checkValidForm() {
    setValidated(true);
  }

  function onInfoChange(e) {
    const name = e.target.name;
    const val = e.target.value;

    checkValidForm();
    setBillingInfo(() => ({
      ...billingInfo,
      [name]: val,
    }));
  }

  return (
    <>
      <Form.Group controlId="formGroupPhoneNumber">
        <Form.Label className="required-input">Phone Number</Form.Label>
        <Form.Control
          name="billingPhoneNumber"
          onChange={(e) => onInfoChange(e)}
          placeholder="(xxx) xxx-xxxx"
          required
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 2}
          type="text"
          value={billingInfo.billingPhoneNumber}
        />
        <Form.Control.Feedback type="invalid">Please enter a phone number.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formGroupBillingAddress1">
        <Form.Label className="required-input">Address (line 1)</Form.Label>
        <Form.Control
          name="billingAddressOne"
          placeholder="Billing Address"
          onChange={(e) => onInfoChange(e)}
          required
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 3}
          type="text"
          value={billingInfo.billingAddressOne}
        />
        <Form.Control.Feedback type="invalid">Please enter a street address.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formGroupBillingAddress2">
        <Form.Label>Address (line 2)</Form.Label>
        <Form.Control
          name="billingAddressTwo"
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 4}
          type="text"
          value={billingInfo.billingAddressTwo}
          onChange={(e) => onInfoChange(e)}
        />
      </Form.Group>
      <Form.Group controlId="formGroupBillingAddress3">
        <Form.Label>Address (line 3)</Form.Label>
        <Form.Control
          name="billingAddressThree"
          onChange={(e) => onInfoChange(e)}
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 5}
          type="text"
          value={billingInfo.billingAddressThree}
        />
      </Form.Group>
      <Form.Group controlId="formGroupBillingAddress4">
        <Form.Label>Address (line 4)</Form.Label>
        <Form.Control
          name="billingAddressFour"
          onChange={(e) => onInfoChange(e)}
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 6}
          type="text"
          value={billingInfo.billingAddressFour}
        />
      </Form.Group>
      <Form.Group controlId="formGroupBillingAddressCity">
        <Form.Label className="required-input">City</Form.Label>
        <Form.Control
          name="billingAddressCity"
          onChange={(e) => onInfoChange(e)}
          placeholder="City"
          required
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 7}
          type="text"
          value={billingInfo.billingAddressCity}
        />
        <Form.Control.Feedback type="invalid">Please provide a city.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formGroupBillingAddressState">
        <Form.Label className="required-input">State</Form.Label>
        <Form.Control
          name="billingAddressState"
          onChange={(e) => onInfoChange(e)}
          placeholder="State"
          required
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 8}
          type="text"
          value={billingInfo.billingAddressState}
        />
        <Form.Control.Feedback type="invalid">Please enter state.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formGroupBillingAddressCountry">
        <Form.Label className="required-input">Country</Form.Label>
        <Form.Control
          name="billingAddressCountry"
          onChange={(e) => onInfoChange(e)}
          placeholder="Country"
          required
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 9}
          type="text"
          value={billingInfo.billingAddressCountry}
        />
        <Form.Control.Feedback type="invalid">Please enter a country.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formGroupBillingAddressZipcode">
        <Form.Label className="required-input">Zipcode</Form.Label>
        <Form.Control
          name="billingAddressZipcode"
          onChange={(e) => onInfoChange(e)}
          placeholder="Zipcode"
          required
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 10}
          type="text"
          value={billingInfo.billingAddressZipcode}
        />
        <Form.Control.Feedback type="invalid">Please enter a zip code.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formGroupPurchaseOrder">
        <Form.Label>Purchase Order #</Form.Label>
        <Form.Control
          name="purchaseOrder"
          onChange={(e) => onInfoChange(e)}
          placeholder="Purchase Order"
          tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 11}
          type="text"
          value={billingInfo.purchaseOrder}
        />
      </Form.Group>
    </>
  );
};

BillingInformationForm.propTypes = {
  billingInfo: PropTypes.object,
  setBillingInfo: PropTypes.func,
  setValidated: PropTypes.func,
};

export default BillingInformation;
