import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { checkKeyPress } from '~/helpers/checkKeyPress';
import { MULTI_METHOD_TOOLTIP, DOWNGRADED_LINKED_ACCOUNT_TOOLTIP } from '~/helpers/constants';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';
import { ProjectContext } from '../../OrderForm';

import logoPath from '~/helpers/logos';

function FileUploadComponent(props) {
  return (
    <Card.Body>
      <div className="text-left p-1 mb-2">
        <Card.Img
          variant="top"
          className={css(styles.linkedAccountImage)}
          src={logoPath(props.linkedAccount.platformDetails.name)}
          alt={`Add Files from ${props.linkedAccount.platformDetails.name}`}
        />
      </div>
      <Card.Text>
        <Button
          tabIndex={props.index}
          className={props.disabled ? 'disabled' : ''}
          style={{ marginTop: '9px' }}
          variant="primary"
          onKeyDown={(e) =>
            checkKeyPress(e, () => props.openLinkedAccountModal(props.linkedAccount), {
              disabled: props.disabled,
            })
          }
          onClick={() => props.openLinkedAccountModal(props.linkedAccount)}
        >
          Choose Files
        </Button>
      </Card.Text>
    </Card.Body>
  );
}

function FileUpload(props) {
  const { features } = useContext(ProjectContext);
  function getTooltipText() {
    if (features.all_vpis) {
      return [MULTI_METHOD_TOOLTIP];
    } else {
      return [DOWNGRADED_LINKED_ACCOUNT_TOOLTIP];
    }
  }

  if (props.disabled) {
    return (
      <TooltipIfErrors errors={getTooltipText()} location="top">
        <FileUploadComponent {...props} />
      </TooltipIfErrors>
    );
  } else {
    return <FileUploadComponent {...props} />;
  }
}
FileUploadComponent.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  linkedAccount: PropTypes.object,
  openLinkedAccountModal: PropTypes.func,
};

FileUpload.propTypes = {
  disabled: PropTypes.bool,
  openLinkedAccountModal: PropTypes.func,
};

const styles = StyleSheet.create({
  uploadName: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  linkedAccountImage: {
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '40px',
    marginTop: '5px',
  },
});

export default FileUpload;
