import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import moment from 'moment-timezone';

import BlankState from './BlankState';
import Paginator from '../common/Paginator';
import TableHeader from './TableHeader';
import DetailedEventStatus from '../common/DetailedEventStatus';

import { DATE_TIME_FORMAT } from '../common/constants';
import { checkKeyPress } from '~/helpers/checkKeyPress';
import { PER_PAGE_COUNT } from '~/helpers/constants';
import { pluralize } from '~/helpers/strings';
import { lacFileShowEndpoint } from '~/helpers/app/paths';

function EventsList(props) {
  function clearSearchTerm() {
    props.setSearchTerm('');
    props.getUpcomingEvents('');
  }

  function getCurrentVideoRange(paginationParam, totalVideos) {
    const startIndex = (paginationParam.currentPage - 1) * paginationParam.entriesPerPage + 1;
    const endIndex = paginationParam.currentPage * paginationParam.entriesPerPage;
    return `${startIndex} - ${Math.min(endIndex, totalVideos)}`;
  }

  return (
    <>
      {((props.events.length === 0 && props.searchTerm.length > 0) || props.events.length > 0) && (
        <InputGroup className="col-sm-3 p-0">
          <FormControl
            aria-label="Search by Name or ID"
            value={props.searchTerm || ''}
            onChange={(e) => props.setSearchTerm(e.target.value)}
            onKeyDown={(e) =>
              checkKeyPress(e, () => {
                props.setPageNumber(1);
                props.getUpcomingEvents(props.searchTerm);
              })
            }
            placeholder="Search by Name or ID"
          />
          <InputGroup.Append>
            <Button
              className={css(appStyles.clearButton)}
              variant="link"
              onClick={() => clearSearchTerm()}
            >
              &times;
            </Button>
            <Button aria-label="Search" variant="primary" onClick={() => props.setPageNumber(1)}>
              Search
            </Button>
          </InputGroup.Append>
        </InputGroup>
      )}
      {props.events.length === 0 && <BlankState eventType="upcoming" />}
      {props.events.length > 0 && (
        <>
          <Table className="v-align-middle-table mb-0 mt-3">
            <thead>
              <tr>
                {props.headerDetails.map((header) => {
                  return (
                    <TableHeader
                      key={header.displayName}
                      header={header}
                      setSortParams={props.setSortParams}
                    />
                  );
                })}
                {props.headerDetails.find((header) => header.displayName === 'Zoom API Token') ===
                  undefined && <th width="5%"></th>}
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
              {props.events.map((event) => {
                return (
                  <tr key={event.id}>
                    <td>
                      <a href={lacFileShowEndpoint(event.id)}>{event.name}</a>
                    </td>
                    <td>
                      {event.startTime === null
                        ? 'Unknown'
                        : moment(event.startTime * 1000)
                            .tz(props.userTimeZone)
                            .format(DATE_TIME_FORMAT)}
                    </td>
                    <td>{event.captionServiceType}</td>
                    <td>{event.integrationName === ' : ' ? 'n/a' : event.integrationName}</td>
                    <td>
                      <DetailedEventStatus status={event.status} />
                    </td>
                    <td className="w-25">
                      {event.videoPlatform === 'Zoom' && (
                        <Row className="mb-2">
                          <Col sm={10} className="pr-0">
                            <InputGroup className="p-0">
                              <FormControl
                                aria-label="API Token"
                                isInvalid={
                                  props.erroredEvents.hasOwnProperty(event.id) &&
                                  !(props.erroredEvents[event.id] === '')
                                }
                                isValid={
                                  event.zoomApiTokenPresent ||
                                  (props.erroredEvents.hasOwnProperty(event.id) &&
                                    props.erroredEvents[event.id] === '')
                                }
                                value={event.captionIngestionUrl || ''}
                                onChange={(e) =>
                                  props.setCaptionIngestUrl(event.id, e.target.value)
                                }
                              />
                              <InputGroup.Append>
                                <Button
                                  aria-label="Update"
                                  variant="primary"
                                  onClick={() => {
                                    props.updateZoomCaptionUrlForEvent(event.id);
                                  }}
                                >
                                  Update
                                </Button>
                              </InputGroup.Append>
                              <Form.Control.Feedback type="invalid">
                                {props.erroredEvents[event.id]}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Col>
                          <Col sm={2} className="pt-2">
                            <OverlayTrigger
                              placement="top"
                              trigger={['hover', 'focus']}
                              overlay={
                                <Tooltip>
                                  {event.zoomApiTokenPresent
                                    ? '3Play has successfully added your Zoom API token.'
                                    : '3Play will add the Zoom API token for you. Refresh this page after your Zoom event has started to verify the token has been added. If you still do not see the token, then retrieve the token from Zoom and enter into this field.'}
                                </Tooltip>
                              }
                            >
                              <i className="fa fa-question-circle"></i>
                            </OverlayTrigger>
                          </Col>
                        </Row>
                      )}
                    </td>
                    <td>
                      <DropdownButton
                        alignRight
                        as={ButtonGroup}
                        variant="outline-primary"
                        title="Actions"
                        size="sm"
                      >
                        <Dropdown.Item href={event.editPath} target="_blank">
                          Edit
                        </Dropdown.Item>
                        {(event.startTime === null ||
                          moment(event.startTime * 1000).isAfter(moment().add(5, 'minutes'))) && (
                          <Dropdown.Item onClick={() => props.openEndUpcomingEventModal(event)}>
                            Cancel Captions
                          </Dropdown.Item>
                        )}
                        {event.captionServiceType === 'Professional' && (
                          <Dropdown.Item onClick={() => props.openCustomerSupportModal(event.id)}>
                            Request Customer Support
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className={css(styles.pagination)}>
            <div className="d-flex justify-content-between">
              <span className="m-2">
                Showing{' '}
                {props.paginationDetails.totalPages === 1
                  ? 'all'
                  : `${getCurrentVideoRange(
                      props.paginationParams,
                      props.paginationDetails.totalVideos
                    )} of`}{' '}
                {pluralize('Event', props.paginationDetails.totalVideos)}
              </span>
              <Paginator
                paginationParams={props.paginationParams}
                setPageNumber={(pageNumber) => props.setPageNumber(Number(pageNumber))}
                totalPages={props.paginationDetails.totalPages}
              />
              <span className={css(appStyles.perPageSpan)}>
                Show&nbsp;
                <Form.Control
                  as="select"
                  className={css(styles.dropDown)}
                  defaultValue={props.paginationParams.entriesPerPage}
                  onChange={(event) => props.setEntriesPerPage(Number(event.target.value))}
                >
                  {PER_PAGE_COUNT.map((count) => (
                    <option key={count} value={count}>
                      {count}
                    </option>
                  ))}
                </Form.Control>
                &nbsp;per page
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function UpcomingEvents(props) {
  if (props.fetching) {
    return null;
  } else {
    return (
      <div className="border-top-0 border p-2">
        <EventsList
          captionIngestUrl={props.captionIngestUrl}
          events={props.upcomingEvents.liveEventDetails}
          erroredEvents={props.erroredEvents}
          getUpcomingEvents={props.getUpcomingEvents}
          headerDetails={props.upcomingEvents.headerDetails}
          openEndUpcomingEventModal={props.openEndUpcomingEventModal}
          openEventSettingsModal={props.openEventSettingsModal}
          paginationDetails={props.upcomingEvents.paginationDetails}
          paginationParams={props.paginationParams}
          searchTerm={props.searchTerm}
          setEntriesPerPage={props.setEntriesPerPage}
          setCaptionIngestUrl={props.setCaptionIngestUrl}
          setPageNumber={props.setPageNumber}
          setSearchTerm={props.setSearchTerm}
          setSortParams={props.setSortParams}
          openCustomerSupportModal={props.openCustomerSupportModal}
          updateZoomCaptionUrlForEvent={props.updateZoomCaptionUrlForEvent}
          userTimeZone={props.userTimeZone}
          validateZoomApiToken={props.validateZoomApiToken}
        />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  dropDown: {
    width: '30%',
    display: 'inline-block',
    textAlign: 'right',
  },
  pagination: {
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    padding: '5px 10px',
  },
});

EventsList.propTypes = {
  captionIngestUrl: PropTypes.object,
  events: PropTypes.array,
  erroredEvents: PropTypes.object,
  getUpcomingEvents: PropTypes.func,
  headerDetails: PropTypes.array,
  openCustomerSupportModal: PropTypes.func,
  openEndUpcomingEventModal: PropTypes.func,
  openEventSettingsModal: PropTypes.func,
  paginationDetails: PropTypes.object,
  paginationParams: PropTypes.object,
  searchTerm: PropTypes.string,
  setCaptionIngestUrl: PropTypes.func,
  setEntriesPerPage: PropTypes.func,
  setPageNumber: PropTypes.func,
  setSearchTerm: PropTypes.func,
  setSortParams: PropTypes.func,
  updateZoomCaptionUrlForEvent: PropTypes.func,
  userTimeZone: PropTypes.string,
  validateZoomApiToken: PropTypes.func,
};

UpcomingEvents.propTypes = {
  captionIngestUrl: PropTypes.object,
  closeEventSettingsModal: PropTypes.func,
  entriesPerPage: PropTypes.number,
  erroredEvents: PropTypes.object,
  fetching: PropTypes.bool,
  getUpcomingEvents: PropTypes.func,
  openCustomerSupportModal: PropTypes.func,
  openEndUpcomingEventModal: PropTypes.func,
  openEventSettingsModal: PropTypes.func,
  paginationParams: PropTypes.object,
  previousEvents: PropTypes.object,
  searchTerm: PropTypes.string,
  setCaptionIngestUrl: PropTypes.func,
  setEntriesPerPage: PropTypes.func,
  setPageNumber: PropTypes.func,
  setSearchTerm: PropTypes.func,
  updateZoomCaptionUrlForEvent: PropTypes.func,
  userTimeZone: PropTypes.string,
  validateZoomApiToken: PropTypes.func,
};

export default UpcomingEvents;
