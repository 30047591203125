import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';
import { offPlatformCaptionQualityControlJobsPaths } from './offPlatformCaptionQualityControlJobsPaths';
import { OffPlatformCaptionQualityControlJob } from './:id/OffPlatformCaptionQualityControlJob';

function OffPlatformCaptionQualityControlJobsApp({ permissions }: RootProps) {
  return (
    <JobAppContainer
      showPath={offPlatformCaptionQualityControlJobsPaths.show.route}
      jobComponent={<OffPlatformCaptionQualityControlJob />}
      permissions={permissions}
    />
  );
}

export default OffPlatformCaptionQualityControlJobsApp;
