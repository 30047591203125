.embed {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
}

.embed textarea[readonly] {
  font-size: 0.875rem;
  background: rgb(253, 254, 255);
  min-height: 10rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.overlayArea {
  position: relative;
}

.overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  backdrop-filter: blur(1px);
  background-color: #ededf6df;
  border-radius: 4px;
  padding: var(--padding-lg);
  text-align: center;
}
