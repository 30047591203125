import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { durationString } from '~/helpers/numbers';

function ViccTimeline(props) {
  const sortedRegions = useMemo(() => {
    return props.matchedRegions.sort((a, b) => {
      if (a.parentStart < b.parentStart) {
        return -1;
      } else if (a.parentStart > b.parentStart) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [props.matchedRegions]);

  return (
    <div className={css(styles.progressBar)}>
      {sortedRegions.map((region) => {
        const left = Math.round((100 * region.parentStart) / (props.parentDuration * 1000));
        const width = Math.round(
          (100 * (region.parentEnd - region.parentStart)) / (props.parentDuration * 1000)
        );
        const parentStartLabel = durationString(Math.floor(region.parentStart / 1000));
        const parentEndLabel = durationString(Math.floor(region.parentEnd / 1000));
        const childStartLabel = durationString(Math.floor(region.childStart / 1000));
        const childEndLabel = durationString(Math.floor(region.childEnd / 1000));
        return (
          <OverlayTrigger
            key={region.childStart}
            placement="bottom"
            overlay={
              <Tooltip>
                Parent: {parentStartLabel} - {parentEndLabel} <br />
                Child: {childStartLabel} - {childEndLabel} <br />
                Score: {Math.round(region.score * 100) / 100}
              </Tooltip>
            }
          >
            <div
              className={css(styles.matchedRegion)}
              style={{ left: `${left}%`, width: `${width}%` }}
            />
          </OverlayTrigger>
        );
      })}
    </div>
  );
}

const styles = StyleSheet.create({
  progressBar: {
    border: '1px solid #b3b3b3',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    height: '0.7em',
    marginTop: '2px',
    width: '100%',
  },
  matchedRegion: {
    background: '#007EB5',
    border: '1px solid #007EB5',
    display: 'inline-block',
    height: '100%',
    minWidth: '5px',
    position: 'relative',
  },
});

ViccTimeline.propTypes = {
  matchedRegions: PropTypes.arrayOf(
    PropTypes.shape({
      childStart: PropTypes.number,
      childEnd: PropTypes.number,
      parentStart: PropTypes.number,
      parentEnd: PropTypes.number,
    })
  ),
  parentDuration: PropTypes.number,
};

export default ViccTimeline;
