import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';
import { paths } from './paths';
import { OffPlatformCaptionEmbeddingJob as JobPage } from './:id/OffPlatformCaptionEmbeddingJob';

const OffPlatformCaptionEmbeddingJobsApp = ({ permissions }: RootProps) => (
  <JobAppContainer
    showPath={paths.show.route}
    jobComponent={<JobPage />}
    permissions={permissions}
  />
);

export default OffPlatformCaptionEmbeddingJobsApp;
