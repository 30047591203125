export function confirmLeavePage() {
  window.addEventListener('beforeunload', showConfirmPopup);
  return () => {
    window.removeEventListener('beforeunload', showConfirmPopup);
  };
}

function showConfirmPopup(event) {
  if (!window.skipConfirmLeavePage) {
    event.preventDefault();
    event.returnValue = ''; // Chrome requires returnValue to be set
  }
}
