import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import WithIcon from '~/components/app/common/WithIcon';

import { threeplayApi } from '~/logic/ThreeplayApi';
import {
  defaultCheatSheetPath,
  adDefaultCheatSheetPath,
  newCheatSheetEndpoint,
} from '~/helpers/app/paths';
import BlankLoadingOverlay from '~/components/app/common/BlankLoadingOverlay';

const cheatSheetFields = `
  content,
  resourceType,
  batch { name },
  pdf,
  pdfResource,
  serviceType,
`;

function cheatSheetQuery(fileId, serviceType) {
  const args = serviceType ? `(serviceType: "${serviceType}")` : '';
  return `file(id: ${fileId}) {
    cheatSheet ${args} { ${cheatSheetFields} }
  }`;
}

function ResourceInfo(props) {
  let resourceLabel;
  if (props.resourceType === 'Batch') {
    resourceLabel = (
      <>
        folder <i>{props.resourceName}</i>
      </>
    );
  } else {
    resourceLabel = 'project';
  }
  return (
    <p>
      <b>
        The information you see below was inherited from the
        <a
          href={
            props.serviceType === 'AudioDescriptionService'
              ? adDefaultCheatSheetPath
              : defaultCheatSheetPath
          }
        >
          {' '}
          default order instructions{' '}
        </a>
        for the {resourceLabel}. Any changes you make will be applied to the order instructions for
        this file only.
      </b>
    </p>
  );
}

ResourceInfo.propTypes = {
  resourceType: PropTypes.oneOf(['Batch', 'Project']).isRequired,
  resourceName: PropTypes.string,
  serviceType: PropTypes.oneOf(['TranscriptionService', 'AudioDescriptionService']),
};

function CheatSheetModal(props) {
  const [uploading, setUploading] = useState(false);
  const [showRemoveOption, setRemoveOption] = useState(false);
  const [cheatSheet, setCheatSheet] = useState(null);
  const [content, setContent] = useState(null);
  const [removePDFCheckBox, setRemovePDF] = useState(false);
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [error, setError] = useState(false);
  const isLiveService = ['LiveAsrService', 'LiveCaptioningService'].includes(props.serviceType);

  const cheatSheetTitle = (() => {
    switch (props.serviceType) {
      case 'AudioDescriptionService':
        return 'Order Instructions (Audio Description)';
      case 'LiveAsrService':
      case 'LiveCaptioningService':
        return 'Live Event PDF Instructions';
      default:
        return 'Order Instructions';
    }
  })();

  useEffect(() => {
    if (props.show) {
      threeplayApi
        .query(cheatSheetQuery(props.fileId, props.serviceType), 'CheatSheet')
        .then((response) => {
          if (response.errors) {
            setError(true);
          } else {
            setError(false);
            const sheet = response.data.file && response.data.file.cheatSheet;
            setCheatSheet(sheet);
            setContent(sheet && sheet.content);
          }
        });
    }
  }, [props.fileId, props.serviceType, props.show]);

  function handleClose() {
    props.onClose();
  }

  function toggleRemovePDFButton() {
    setRemoveOption(!showRemoveOption);
  }

  function handleContentChange(e) {
    setContent(e.target.value);
  }

  function handleRemovePDF() {
    setRemovePDF(!removePDFCheckBox);
  }

  function handleUploadCheatSheet() {
    setUploading(true);
    const payload = buildFormData();
    fetch(newCheatSheetEndpoint(props.fileId), {
      method: 'PUT',
      body: payload,
      credentials: 'same-origin',
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setUploading(false);
        if (data) {
          if (data.success) {
            setSuccessAlert(true);
          }
          setTimeout(() => {
            handleClose(), setSuccessAlert(false);
          }, 3000);
        }
      });
  }

  function buildFormData() {
    const formData = new FormData();
    const file = document.querySelector('input[type="file"]').files[0];
    formData.append('v2', true);
    formData.append('authenticity_token', props.authToken);
    formData.append('resource_type', 'MediaFile');
    formData.append('resource_id', props.fileId);
    formData.append('cheat_sheet[service_type]', props.serviceType);
    formData.append('cheat_sheet[content]', content);
    if (file) {
      formData.append('pdf_attachment', file);
    }
    if (removePDFCheckBox) {
      formData.append('remove_pdf_attachment', '1');
    }
    return formData;
  }

  function cheatSheetUpload() {
    if (cheatSheet && cheatSheet.pdfResource) {
      return (
        <Form.Group controlId="cheat_sheet">
          <Form.Label>
            <WithIcon icon="fa fa-file">
              <b>PDF Attached </b>
            </WithIcon>
          </Form.Label>
          <span className={css(styles.leftPad)}>
            <a href={cheatSheet.pdfResource}>View</a> |
            <a href="#" onClick={toggleRemovePDFButton}>
              {' '}
              Edit
            </a>
          </span>
          <Form.Control type="file" name="pdf_attachment" accept=".pdf,.PDF,application/pdf" />
          {showRemoveOption && removePDF()}
        </Form.Group>
      );
    } else {
      return <Form.Control type="file" name="pdf_attachment" accept=".pdf,.PDF,application/pdf" />;
    }
  }

  function removePDF() {
    return (
      <Form.Check
        className={css(styles.checkbox)}
        type="checkbox"
        label="Remove PDF"
        checked={removePDFCheckBox}
        onChange={(e) => handleRemovePDF(e)}
      />
    );
  }

  function cheatSheetTip() {
    if (cheatSheet && cheatSheet.serviceType === 'AudioDescriptionService')
      return (
        <p>
          <b>Tip:</b> you can create{' '}
          <a href={adDefaultCheatSheetPath}> default order instructions </a>
          for your whole project!
        </p>
      );
    else
      return (
        <p>
          <b>Tip:</b> you can create{' '}
          <a href={defaultCheatSheetPath}> default order instructions </a>
          for a folder or for your whole project!
        </p>
      );
  }

  function successAlert() {
    return <Alert variant="success">The order instructions for this file has been updated!</Alert>;
  }

  if (error) {
    return (
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{cheatSheetTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={css(styles.shiftLeft)}>
          <Alert variant="error">
            There was error in getting the order instructions. Please try again.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button className="float-right" variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal size="lg" show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{cheatSheetTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={css(styles.shiftLeft)}>
          {uploading && <BlankLoadingOverlay />}
          {showSuccessAlert && successAlert()}
          {cheatSheet && ['Batch', 'Project'].includes(cheatSheet.resourceType) && (
            <ResourceInfo
              resourceType={cheatSheet.resourceType}
              resourceName={cheatSheet.batch && cheatSheet.batch.name}
              serviceType={cheatSheet.serviceType}
            />
          )}
          <p>
            Help your editors provide you with the most accurate content possible by providing notes
            about correct formatting, spellings of speaker names (note: speaker identification will
            only be added if selected in your transcription settings), product/company names,
            technical vocabulary, acronyms, other terminology.
          </p>
          {!isLiveService && (
            <>
              <p>Enter information here (500 characters maximum) or attach a PDF.</p>
              <Form.Control
                name="cheat_sheet[content]"
                as="textarea"
                rows="5"
                maxLength="500"
                value={cheatSheet && content}
                onChange={(e) => handleContentChange(e)}
              />
              <p className={css(styles.muted)}>
                <small>
                  Eg. Company name must always be written as 3Play Media. Common acronyms and names:
                  ASR, PO, RFP, Ian McCawley, Megan Kritikos.
                </small>
              </p>
            </>
          )}
          {cheatSheetUpload()}
          {!isLiveService && cheatSheetTip()}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button className="float-left" variant="primary" onClick={handleUploadCheatSheet}>
            Save
          </Button>
          <Button className="float-right" variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

CheatSheetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  authToken: PropTypes.string,
  fileId: PropTypes.string,
  serviceType: PropTypes.oneOf(['TranscriptionService', 'AudioDescriptionService']),
};

const styles = StyleSheet.create({
  muted: {
    color: '#999',
  },
  checkbox: {
    'margin-top': 0,
  },
  leftPad: {
    paddingLeft: '4px',
  },
  shiftLeft: {
    padding: '0em',
    margin: '1em',
  },
});

export default CheatSheetModal;
