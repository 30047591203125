import { ENTER_KEY_CODE, SPACE_KEY_CODE } from '~/helpers/constants';

export const checkKeyPress = (event, callback, opts = {}) => {
  if (opts['disabled']) {
    return;
  }

  const code = event.keyCode || event.charCode;
  if (code === ENTER_KEY_CODE || (opts['triggerOnSpace'] && code === SPACE_KEY_CODE)) {
    callback();
  }
};
