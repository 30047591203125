import React from 'react';
import { Button } from '@threeplayground/index';
import { useDropzone } from 'react-dropzone';

interface FileUploadProps {
  extension: string;
  onDrop: (files: File[]) => void;
}

export const FileUpload = ({ extension, onDrop }: FileUploadProps) => {
  // dropzone's implementation was raising a eslint@typescript-eslint/unbound-method error
  // eslint-disable-next-line
  const { getRootProps, getInputProps } = useDropzone({
    accept: extension,
    onDrop: onDrop,
    multiple: false,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div>
        <Button variant="primary">Choose File</Button>
      </div>
    </div>
  );
};
