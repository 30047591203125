import React from 'react';
import PropTypes from 'prop-types';

import { Form, Table } from 'react-bootstrap';

import { translationProfileType } from '~/components/app/order_form/propTypes';
import TranslationProfileDropdown from '~/components/app/order_form/serviceOptions/translation/TranslationProfileDropdown';
import TranslationProfileInfoPopover from '~/components/app/order_form/serviceOptions/translation/TranslationProfileInfoPopover';
import { displayPrice } from '~/helpers/numbers';

function VendorDropdown(props) {
  const vendors = props.availableOptionsForLanguages[props.targetLanguage].map(
    (option) => option.translationVendor
  );
  const uniqueVendors = [
    ...new Set(
      vendors.map((vendor) => vendor.id).map((id) => vendors.find((vendor) => vendor.id == id))
    ),
  ];
  return (
    <Form.Control
      as="select"
      defaultValue={props.selectedVendorId}
      onChange={(e) => props.setSelectedVendorForLanguage(props.targetLanguage, e.target.value)}
      size="sm"
    >
      {uniqueVendors.map((vendor) => {
        return (
          <option key={vendor.id} value={vendor.id}>
            {vendor.name === 'MachineTranslator' ? 'Machine Translation' : vendor.name}
          </option>
        );
      })}
    </Form.Control>
  );
}

function ServiceLevelsForVendorDropDown(props) {
  const serviceLevels = props.availableOptionsForLanguages[props.targetLanguage]
    .filter((option) => option.serviceLevel.translationVendor.id === props.selectedVendorId)
    .map((options) => options.serviceLevel);

  return (
    <Form.Control
      as="select"
      defaultValue={props.selectedServiceId}
      onChange={(e) => props.setSelectedServiceForLanguage(props.targetLanguage, e.target.value)}
      size="sm"
    >
      {serviceLevels.map((serviceLevel) => {
        return (
          <option key={serviceLevel.id} value={serviceLevel.id}>
            {serviceLevel.name}
          </option>
        );
      })}
    </Form.Control>
  );
}

function TranslationOrderOptionTable(props) {
  if (props.selectedOrderOptions.length === 0) {
    return null;
  } else {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Language</th>
            <th>Vendor</th>
            <th>Service Level</th>
            <th>
              Translation Profile <TranslationProfileInfoPopover />
            </th>
            <th>Estimated Cost</th>
          </tr>
        </thead>
        <tbody>
          {props.selectedOrderOptions.map((orderOption) => {
            return (
              <tr key={orderOption.targetLanguage}>
                <td>{orderOption.targetLanguage}</td>
                <td>
                  <VendorDropdown
                    availableOptionsForLanguages={props.availableOptionsForLanguages}
                    selectedVendorId={orderOption.vendor.id}
                    setSelectedVendorForLanguage={props.setSelectedVendorForLanguage}
                    targetLanguage={orderOption.targetLanguage}
                  />
                </td>
                <td>
                  <ServiceLevelsForVendorDropDown
                    availableOptionsForLanguages={props.availableOptionsForLanguages}
                    selectedServiceId={orderOption.serviceLevel.id}
                    selectedVendorId={orderOption.vendor.id}
                    setSelectedServiceForLanguage={props.setSelectedServiceForLanguage}
                    targetLanguage={orderOption.targetLanguage}
                  />
                </td>
                <td>
                  <TranslationProfileDropdown
                    selectedTranslationProfile={orderOption.translationProfile}
                    setSelectedTranslationProfile={props.setSelectedTranslationProfile}
                    targetLanguage={orderOption.targetLanguage}
                    translationProfiles={props.translationProfiles}
                  />
                </td>
                <td>{displayPrice(orderOption.price.amount, orderOption.price.unit, false)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

ServiceLevelsForVendorDropDown.propTypes = {
  availableOptionsForLanguages: PropTypes.object,
  selectedServiceId: PropTypes.string,
  selectedVendorId: PropTypes.string,
  setSelectedServiceForLanguage: PropTypes.func,
  targetLanguage: PropTypes.string,
};

TranslationOrderOptionTable.propTypes = {
  availableOptionsForLanguages: PropTypes.object,
  selectedOrderOptions: PropTypes.array,
  setSelectedServiceForLanguage: PropTypes.func,
  setSelectedTranslationProfile: PropTypes.func,
  setSelectedVendorForLanguage: PropTypes.func,
  translationProfiles: PropTypes.arrayOf(translationProfileType),
};

VendorDropdown.propTypes = {
  availableOptionsForLanguages: PropTypes.object,
  selectedVendorId: PropTypes.string,
  setSelectedVendorForLanguage: PropTypes.func,
  targetLanguage: PropTypes.string,
};

export default TranslationOrderOptionTable;
