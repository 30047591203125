import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import SingleStepNav from '../shared/SingleStepNav';
import StepperNav from '../shared/StepperNav';

const VoiceWritingNav = ({ steps, currentStep }) => {
  return (
    <>
      <div className={'d-lg-none d-block'}>
        <SingleStepNav currentStep={currentStep} appSteps={steps}></SingleStepNav>
      </div>
      <div className={'mt-lg-5 ml-lg-5 pl-2 pr-2 d-none d-lg-block ' + css(styles.nav)}>
        <h3 className={css(styles.navSectionHeader)}>Initial Application</h3>
        <StepperNav currentStep={currentStep}>
          {Object.values(steps).map((step, i) => (
            <StepperNav.Step key={i}>{`${i + 1}. ${step}`}</StepperNav.Step>
          ))}
        </StepperNav>
      </div>
    </>
  );
};

VoiceWritingNav.propTypes = {
  steps: PropTypes.object,
  currentStep: PropTypes.number,
};

const styles = StyleSheet.create({
  nav: {
    fontWeight: 600,
    fontSize: '13px',
  },
  navSectionHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '1em',
    marginBottom: '1em',
  },
});

export default VoiceWritingNav;
