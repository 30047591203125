import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { Table as BootstrapTable } from 'react-bootstrap';

import { Input } from '../Input/Input';
import { Link } from '../Link/Link';

/**
 * TODO: Should these Cells live in another file?
 * Here we define a set of cells that are used internally by table.
 * Our Cell Props API
 * @param {columnShape} columnConfig The Column config passed as props to the Table
 * @param {object} rowData The data element passed to the whole row
 * @param {*)} value The cell value accessed via rowData[accessor]
 * @returns ReactNode
 */

const EMPTY_CELL = '-';

const StringCell = ({ value }) => {
  let valueToRender;

  if (typeof value === 'number') {
    throw new Error('detected a number in a Threeplayground Table cell of type "string"');
  } else if (typeof value === 'boolean') {
    valueToRender = value.toString();
  } else {
    valueToRender = value;
  }

  return valueToRender || EMPTY_CELL;
};

const NumberCell = ({ columnConfig, value }) => {
  let precision = 2; // default
  if (columnConfig.cellProps) {
    precision = columnConfig.cellProps().precision;
  }
  return value?.toLocaleString('en-US', { maximumFractionDigits: precision }) || EMPTY_CELL;
};

const PriceCell = ({ columnConfig, value }) => {
  let minimumFractionDigits = undefined; // default
  let maximumFractionDigits = undefined; // default
  if (columnConfig.cellProps) {
    minimumFractionDigits = columnConfig.cellProps().minimumFractionDigits;
    maximumFractionDigits = columnConfig.cellProps().maximumFractionDigits;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...(minimumFractionDigits ? { minimumFractionDigits } : {}),
    ...(maximumFractionDigits ? { maximumFractionDigits } : {}),
  });
  return value ? formatter.format(value) : EMPTY_CELL;
};

const DateCell = ({ value }) => {
  return value ? new Intl.DateTimeFormat('en-US').format(new Date(value)) : EMPTY_CELL;
};

const InputCell = ({ columnConfig: { cellProps }, rowData }) => {
  const { registerProps, additionalProps } = cellProps(rowData);
  return <Input size="small" {...registerProps} {...additionalProps} />;
};

const LinkCell = ({ columnConfig: { cellProps }, rowData }) => {
  const { href, linkText } = cellProps(rowData);
  return <Link to={href}>{linkText}</Link>;
};

/**
 * TODO: Should these Cells live in another file?
 * Header cells currently only support rendering strings by default
 * Our Header Props API
 * @param {columnShape} columnConfig The Column config passed as props to the Table
 * @returns ReactNode
 */
const Header = ({ columnConfig }) => {
  return typeof columnConfig.header === 'string'
    ? columnConfig.header
    : columnConfig.header({ columnConfig });
};

/**
 * Supported cell types, used for formatting. Adding to this constant will make the cell type
 * accessible via the column config. If extra props are necessary for a cell type, add a:
 * 'get<cellType>Props' method to the column configuration.
 */
const CELL_TYPES = {
  date: DateCell,
  link: LinkCell,
  number: NumberCell,
  price: PriceCell,
  string: StringCell,
  input: InputCell,
};

const DEFAULTS = {
  EMPTY_MESSAGE: 'No data to display',
};

/**
 * Here we translate our Cell Type API into something react-table undertsands. Also, since
 * react-table passes a grab-bag of props to their cells. Here we intercept that behavior and
 * convert those to our own Cell Props API.
 */
const getCell = (column) => {
  const { cell } = column;
  const cellToRender = CELL_TYPES[cell] || cell;

  if (!cellToRender) {
    throw new Error('unrecognized or missing cell type use for threeplayground Table');
  }

  return ({ row, value }) => cellToRender({ columnConfig: column, rowData: row.original, value });
};

// This is pulled out here to extend for when we need additional headers. Could be inline below
const getHeader = (columnConfig) => {
  return () => Header({ columnConfig });
};

// Translates our Column API into one react-table understands
const tablePropsToReactTableColumns = (columns = []) =>
  columns.map((column) => {
    const { accessor } = column;
    return {
      accessor,
      id: accessor, // we may need to expand our own API to take a column ID some day
      Cell: getCell(column),
      Header: getHeader(column),
    };
  });

export const Table = ({ columns, data = [], emptyMessage = DEFAULTS.EMPTY_MESSAGE }) => {
  // react-table requires columns to be memoized
  const reactTableColumns = useMemo(() => tablePropsToReactTableColumns(columns), [columns]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: reactTableColumns,
    data,
  });

  return (
    <BootstrapTable striped bordered hover {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => {
          return (
            // getHeaderGroupProps() returns a key
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // getHeaderProps() returns a key
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {!rows.length && (
          <tr>
            <td colSpan={columns.length} className="text-center">
              {emptyMessage}
            </td>
          </tr>
        )}
        {rows.length > 0 &&
          rows.map((row, index) => {
            prepareRow(row);
            return (
              <React.Fragment key={index}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    // getCellProps() returns a key
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              </React.Fragment>
            );
          })}
      </tbody>
    </BootstrapTable>
  );
};

const columnShape = {
  accessor: PropTypes.string.required,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).required,
  cell: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(CELL_TYPES)), PropTypes.func]),
  // expected return shapes for cell type:
  // 'link': {href: string, linkText: string}
  // 'number': {precision: int}
  cellProps: PropTypes.func,
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(columnShape)).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  emptyMessage: PropTypes.string,
};

StringCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

NumberCell.propTypes = {
  value: PropTypes.number,
};

PriceCell.propTypes = {
  value: PropTypes.number,
  columnConfig: columnShape.required,
};

LinkCell.propTypes = {
  columnConfig: columnShape.required,
  rowData: PropTypes.object.required,
};
