import React from 'react';
import PropTypes from 'prop-types';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function ThreePlayTooltip(props) {
  return (
    <OverlayTrigger
      placement="auto"
      delay={{ hide: 2000 }}
      trigger={['hover', 'focus']}
      overlay={<Tooltip>{props.tooltipText}</Tooltip>}
    >
      <i
        className="pl-2 fa fa-info-circle"
        {...(props.appearInTabNavigation ? { tabIndex: 0 } : {})}
      ></i>
    </OverlayTrigger>
  );
}

ThreePlayTooltip.propTypes = {
  tooltipText: PropTypes.string,
  appearInTabNavigation: PropTypes.boolean,
};

export default ThreePlayTooltip;
