import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Fetched from '~/lib/global/Fetched';
import useFetcher from '~/lib/global/useFetcher';

import { useVariantList } from '~/core/hooks/useVariantList';

import { threeplayApi } from '~/components/ops/common/threeplayApi';
import { bulkUpdateJobsMutation } from '~/components/ops/market/mutations';

import MessageBanner from './MessageBanner';
import SlideToggle from './SlideToggle';
import TraitBadge from './TraitBadge';

// Aphrodite does not work easily with server-side rendering
import './styles.css';
import './slide-toggle.css';

function getJobs(ids) {
  const query = JSON.stringify({ id_in: ids });
  return threeplayApi.json(
    `query BulkUpdateJobQuery($query: String) {
      jobs(query: $query) { items { id deadline jobTraits { traited hierarchyCopy trait { name } } payrate state } }
    }`,
    { query }
  );
}

function bulkUpdate(jobIds, actions) {
  return threeplayApi.json(bulkUpdateJobsMutation, { jobIds, ...actions });
}

function tally(things) {
  const t = {};
  things.forEach((thing) => {
    t[thing] = (t[thing] || 0) + 1;
  });
  return t;
}

function BulkUpdateJobs(props) {
  const [errors, setErrors] = useState([]);
  const [lockJobs, setLockJobs] = useState(true);
  const [refreshKey, setRefreshKey] = useState(Date.now());
  const [selectedTrait, setSelectedTrait] = useState('');
  const [success, setSuccess] = useState(null);

  const jobs = useFetcher(() => getJobs(props.jobIds), [props.jobIds, refreshKey]);
  const states = (jobs.response && jobs.response.data.jobs.items.map((job) => job.state)) || [];
  const stateCounts = tally(states);
  const jobTraits =
    (jobs.response && jobs.response.data.jobs.items.map((job) => job.jobTraits).flat()) || [];
  const traitedTraits = jobTraits.filter((trait) => trait.traited);
  const traitCounts = tally(traitedTraits.map((trait) => trait.trait.name));

  const { data: traits, isLoading: isLoadingTraits } = useVariantList('TRAIT');

  function refresh() {
    setRefreshKey(Date.now());
  }

  function handleTraitSelection(event) {
    setSelectedTrait(event.target.value);
  }

  function traitSelected() {
    return selectedTrait !== '';
  }

  function handleErrors(newErrors) {
    if (!newErrors) {
      return;
    }

    setErrors([...errors, ...newErrors]);
  }

  function handleUpdates(result) {
    const results = result.data && result.data.bulkUpdateJobs.results;
    if (!results) {
      return;
    }

    const failures = results.filter((r) => r.status === 'failed');
    setErrors([...errors, ...failures.map((f) => `Could not modify job ${f.jobId}: ${f.reason}`)]);

    const failedIds = failures.map((f) => f.jobId);
    const successes = new Set(props.jobIds);
    failedIds.forEach((id) => successes.delete(id));
    setSuccess(`${successes.size} jobs processed.`);
  }

  function dismissError(ii) {
    errors.splice(ii, 1);
    setErrors([...errors]);
  }

  async function addTrait() {
    if (!traitSelected()) {
      return;
    }

    const result = await bulkUpdate(props.jobIds, {
      lockJobs,
      addTraitIds: [Number(selectedTrait)],
    });
    handleErrors(result.errors && result.errors.map((e) => e.message));
    handleUpdates(result);
    refresh();
  }

  async function removeTrait() {
    if (!traitSelected()) {
      return;
    }

    const result = await bulkUpdate(props.jobIds, {
      lockJobs,
      removeTraitIds: [Number(selectedTrait)],
    });
    handleErrors(result.errors && result.errors.map((e) => e.message));
    handleUpdates(result);
    refresh();
  }

  async function resetTraits() {
    const result = await bulkUpdate(props.jobIds, { lockJobs, resetTraits: true });
    handleErrors(result.errors && result.errors.map((e) => e.message));
    handleUpdates(result);
    refresh();
  }

  async function setForceList(value) {
    const result = await bulkUpdate(props.jobIds, { lockJobs, forceList: value });
    handleErrors(result.errors && result.errors.map((e) => e.message));
    handleUpdates(result);
    refresh();
  }

  function forceList() {
    setForceList(true);
  }

  function unforceList() {
    setForceList(false);
  }

  return (
    <div id="jobs-bulk-update-form">
      {success && (
        <MessageBanner variant="success" onClose={() => setSuccess(null)}>
          {success}
        </MessageBanner>
      )}
      {errors.map((error, ii) => (
        <MessageBanner key={ii} onClose={() => dismissError(ii)}>
          {error}
        </MessageBanner>
      ))}

      <p>{props.jobIds.length} jobs selected.</p>
      <div className="block-margin input-row space-items">
        <SlideToggle checked={lockJobs} onChange={(e) => setLockJobs(e.target.checked)} />
        <label>Lock jobs while performing modifications</label>
      </div>

      <h3>
        <span className="text">States</span>
      </h3>
      <Fetched fetched={[jobs]}>
        {Object.entries(stateCounts).map(([state, count]) => (
          <TraitBadge key={state}>{`${state} (${count})`}</TraitBadge>
        ))}
      </Fetched>

      <h3>
        <span className="text">Traits</span>
      </h3>
      <Fetched fetched={[jobs]}>
        {Object.entries(traitCounts).map(([trait, count]) => (
          <TraitBadge key={trait}>{`${trait} (${count})`}</TraitBadge>
        ))}
        <select
          className="block-margin display-block"
          value={selectedTrait}
          onChange={handleTraitSelection}
        >
          <option disabled value="">
            {' '}
            -- select a trait --{' '}
          </option>
          {traits &&
            !isLoadingTraits &&
            traits.map(({ id, displayName }) => (
              <option key={id} value={id}>
                {displayName}
              </option>
            ))}
        </select>
        <div className="block-margin input-row space-items">
          <button disabled={!traitSelected()} onClick={addTrait}>
            Add
          </button>
          <button disabled={!traitSelected()} onClick={removeTrait}>
            Remove
          </button>
          <button onClick={resetTraits}>Reset to inherited</button>
        </div>
        <h3>
          <span className="text">Listing</span>
        </h3>
        <div className="block-margin input-row space-items">
          <button onClick={forceList}>Force list</button>
          <button onClick={unforceList}>Un-force list</button>
        </div>
        <h3></h3>
      </Fetched>
    </div>
  );
}

BulkUpdateJobs.propTypes = {
  jobIds: PropTypes.arrayOf(Number),
};

export default BulkUpdateJobs;
