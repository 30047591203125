import React from 'react';
import PropTypes from 'prop-types';

import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function AudioRating({ rating }) {
  if (rating === 0) {
    return <span>N/A</span>;
  } else {
    const badgeVariant = rating === 1 ? 'warning' : 'success';
    const badgeText = rating === 1 ? 'Difficult' : 'Good';

    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            A surcharge is only applied to files ordered for Transcription and Captioning with
            Difficult audio quality.
          </Tooltip>
        }
      >
        <Badge variant={badgeVariant}>{badgeText}</Badge>
      </OverlayTrigger>
    );
  }
}

AudioRating.propTypes = {
  value: PropTypes.number,
};

export default AudioRating;
