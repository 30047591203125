import React, { createContext, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThreeplayApiV2 } from './ThreeplayApiV2';

const reactQueryClient = new QueryClient();
const apiV2 = new ThreeplayApiV2();

export const ThreeplayAPIContext = createContext(apiV2);

interface ThreeplayAPIProviderProps {
  client: ThreeplayApiV2;
  children: ReactNode;
}

export const ThreeplayAPIProvider = ({ client, children }: ThreeplayAPIProviderProps) => {
  return (
    <QueryClientProvider client={reactQueryClient}>
      <ThreeplayAPIContext.Provider value={client}>{children}</ThreeplayAPIContext.Provider>
    </QueryClientProvider>
  );
};
