export const createReferralCodesMutation = `mutation createReferralCodes($contractorIds: [Int!]) {
    createReferralCodes(contractorIds: $contractorIds) {
      results {
        success
        error
        contractorId
        referralCode {
          token
          referralLimit
          contractor {
            id
            name
          }
        }
      }
    }
  }`;
