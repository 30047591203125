import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { browserStreamingPath, iframeEmbedPath } from '~/helpers/app/paths';
import { css, StyleSheet } from 'aphrodite';

function PublishVideoModal(props) {
  function handleClose() {
    props.onClose();
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Publish Stream</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
        <React.Fragment>
          <p>Browser View:</p>
          <textarea
            type="text"
            className={css(styles.fullWidth)}
            value={browserStreamingPath(props.fileId, props.projectId)}
            readOnly={true}
          />
          <p>Iframe Embed:</p>
          <textarea
            type="text"
            className={css(styles.fullWidth)}
            value={iframeEmbedPath(props.fileId, props.projectId)}
            readOnly={true}
          />
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

PublishVideoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fileId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
};

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
});

export default PublishVideoModal;
