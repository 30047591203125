import React from 'react';

import PropTypes from 'prop-types';

function TraitBadge(props) {
  return <span className="trait-badge inline-margin">{props.children}</span>;
}

TraitBadge.propTypes = {
  children: PropTypes.node,
};

export default TraitBadge;
