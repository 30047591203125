import React from 'react';

import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

const options = (
  <>
    <option value="hoursToDeadline">Hours to Deadline</option>
    <option value="hoursOnMarket">Hours on Market</option>
    <option value="accessCount"># Job Accesses</option>
    <option value="loggedInAccessCount"># Logged In Job Accesses</option>
    <option value="priorityStars">Priority Stars</option>
    <option value="forceList">Force List</option>
    <option value="topOfQueue">Send to Top</option>
    <option value="duration">Duration</option>
  </>
);

function JobGraphSettings(props) {
  function handleXAxis(event) {
    props.setX(event.target.value);
  }

  function handleYAxis(event) {
    props.setY(event.target.value);
  }

  function handleColor(event) {
    props.setColor(event.target.value);
    // bind sort order and color
    props.setSort(event.target.value);
  }

  function handleRadius(event) {
    props.setRadius(event.target.value);
  }

  return (
    <Form>
      <Form.Group controlId={'graphSettings.xAxis'}>
        <Form.Label>X Axis</Form.Label>
        <Form.Control as="select" size="sm" value={props.x} onChange={handleXAxis}>
          {options}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId={'graphSettings.yAxis'}>
        <Form.Label>Y Axis</Form.Label>
        <Form.Control as="select" size="sm" value={props.y} onChange={handleYAxis}>
          {options}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId={'graphSettings.color'}>
        <Form.Label>Color</Form.Label>
        <Form.Control as="select" size="sm" value={props.color} onChange={handleColor}>
          {options}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId={'graphSettings.radius'}>
        <Form.Label>Size</Form.Label>
        <Form.Control as="select" size="sm" value={props.radius} onChange={handleRadius}>
          {options}
        </Form.Control>
      </Form.Group>
    </Form>
  );
}

JobGraphSettings.propTypes = {
  color: PropTypes.string,
  setColor: PropTypes.func.isRequired,
  radius: PropTypes.string,
  setRadius: PropTypes.func.isRequired,
  // sort: PropTypes.string,
  setSort: PropTypes.func.isRequired,
  x: PropTypes.string,
  setX: PropTypes.func.isRequired,
  y: PropTypes.string,
  setY: PropTypes.func.isRequired,
};

export default JobGraphSettings;
