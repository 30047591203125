import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import MultiServiceTable from './MultiServiceTable';
import { titleize } from '~/helpers/strings';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';

function AmaraCard(props) {
  const headers = ['Language', 'Ordered', 'Level', 'Status', 'Vendor', null];
  function deleteImport(service) {
    props.setShowModal.deleteImport(true, service);
  }

  function formatServiceStatus(service) {
    if (service.status === 'complete') {
      return (
        'Completed ' +
        moment(parseInt(service.completedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)
      );
    } else {
      return titleize(service.status);
    }
  }

  return (
    <MultiServiceTable headers={headers}>
      {props.services.map((service) => (
        <tr key={service.id}>
          <td>{service.language.name}</td>
          <td>
            {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
          </td>
          <td>Standard</td>
          <td>{formatServiceStatus(service)}</td>
          <td>Amara</td>
          <td>
            {service.status == 'complete' ? (
              <a
                className={css(styles.serviceAction)}
                href="#"
                onClick={() => {
                  deleteImport(service);
                }}
              >
                <i className="fa fa-times" />
              </a>
            ) : null}
          </td>
        </tr>
      ))}
    </MultiServiceTable>
  );
}

AmaraCard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  setShowModal: PropTypes.shape({
    deleteImport: PropTypes.func,
  }),
  userTimeZone: PropTypes.string,
};

const styles = StyleSheet.create({
  serviceAction: {
    color: '#C90202',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
});

export default AmaraCard;
