import React from 'react';
import PropTypes from 'prop-types';
import FileFormats from './FileFormats';
import SmpteSettings from './SmpteSettings';
import TranscriptSettings from './TranscriptSettings';
import WebSettings from './WebSettings';
import AudioDescriptionFormats from './AudioDescriptionFormats';
import MediaFileModal from './MediaFileModal';
import { Card, Tab, Tabs } from 'react-bootstrap';

class FileFormatsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    const tab = this.props.formatInfo['favoriteFormats'] ? 'favoriteFormats' : 'webCaptionFormats';
    this.state = {
      errors: {
        smpteCaptionFormats: false,
        transcriptFormats: false,
        audioDescriptionFormats: false,
        showModal: false,
      },
      currentTab: tab,
      bulk: props.bulk,
    };
    this.setError = this.setError.bind(this);
  }

  setError(formatType, isError) {
    const errors = this.state.errors;
    if (errors[formatType] !== isError) {
      errors[formatType] = isError;
      this.setState({ errors: errors });
    }
  }

  presentAdditionalSettings(formatType) {
    switch (formatType) {
      case 'smpteCaptionFormats':
        return (
          <>
            <hr />
            <SmpteSettings
              updateFormatSettings={this.props.updateFormatSettings}
              settings={this.props.formatInfo[formatType]['settings']}
              defaultSettings={this.props.formatInfo[formatType]['defaultSettings']}
              formatType={formatType}
              setError={this.setError}
            />
          </>
        );
      case 'transcriptFormats':
        return (
          <>
            <hr />
            <TranscriptSettings
              updateFormatSettings={this.props.updateFormatSettings}
              settings={this.props.formatInfo[formatType]['settings']}
              formatType={formatType}
              setError={this.setError}
            />
          </>
        );
      case 'webCaptionFormats':
        return (
          <>
            <hr />
            <WebSettings
              updateFormatSettings={this.props.updateFormatSettings}
              settings={this.props.formatInfo[formatType]['settings']}
              formatType={formatType}
              setError={this.setError}
            />
          </>
        );
      default:
        return <span></span>;
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleShowModal() {
    this.setState({ showModal: true });
  }

  multiFiles() {
    return Object.keys(this.props.mediaFiles).length > 1;
  }

  filePluralizer() {
    return this.multiFiles() ? 'Total Files' : 'Total File';
  }

  render() {
    return (
      <React.Fragment>
        <MediaFileModal
          title={this.filePluralizer()}
          mediaFiles={this.props.mediaFiles}
          show={this.state.showModal}
          handleCloseModal={this.handleCloseModal}
        />

        <Card>
          <Card.Header>
            <h2 className="pull-left"> Download File Formats</h2>
            {this.state.bulk ? (
              <a className="pull-right" href="#" onClick={this.handleShowModal}>
                View {Object.keys(this.props.mediaFiles).length} {this.filePluralizer()}
              </a>
            ) : (
              ''
            )}
          </Card.Header>

          <Card.Body>
            <Tabs
              activeKey={this.state.currentTab}
              onSelect={(currentTab) => this.setState({ currentTab })}
            >
              {Object.keys(this.props.formatInfo).map((formatType, index) => (
                <Tab
                  key={index}
                  eventKey={formatType}
                  title={this.props.formatInfo[formatType]['title']}
                >
                  {formatType == 'audioDescriptionFormats' ? (
                    <AudioDescriptionFormats
                      adFormatTypes={this.props.formatInfo[formatType]}
                      selectedFormats={this.props.formatInfo[formatType]['selectedFormats']}
                      toggleSelectedFormat={this.props.toggleSelectedFormat}
                      formatType={formatType}
                      mediaFiles={this.props.mediaFiles}
                      formAuthToken={this.props.formAuthToken}
                      errors={this.state.errors[formatType]}
                    />
                  ) : (
                    <FileFormats
                      formats={this.props.formatInfo[formatType]['formats']}
                      formatTypes={this.props.formatInfo[formatType]}
                      selectedFormats={this.props.formatInfo[formatType]['selectedFormats']}
                      toggleSelectedFormat={this.props.toggleSelectedFormat}
                      formatType={formatType}
                      bulk={this.state.bulk}
                      mediaFiles={this.props.mediaFiles}
                      settings={this.props.formatInfo[formatType]['settings']}
                    />
                  )}

                  {this.props.bulk ? <span /> : this.presentAdditionalSettings(formatType)}
                </Tab>
              ))}
            </Tabs>
          </Card.Body>
          <Card.Footer>
            Tired of selecting the same formats over and over again? Save your favorite formats to
            have them shown here by default!
            <br />
            <a href="/project/favorite_formats" target="_blank">
              Set Default Formats
            </a>
          </Card.Footer>
        </Card>
      </React.Fragment>
    );
  }
}

FileFormatsContainer.propTypes = {
  bulk: PropTypes.bool,
  toggleSelectedFormat: PropTypes.func,
  formatInfo: PropTypes.object,
  mediaFiles: PropTypes.object,
  formAuthToken: PropTypes.string,
  updateFormatSettings: PropTypes.func,
};
export default FileFormatsContainer;
