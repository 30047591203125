import PropTypes from 'prop-types';

import React from 'react';

import { StyleSheet, css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import { Typeahead } from 'react-bootstrap-typeahead';

import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

const TranslationOrderDetails = ({
  fromEnglishLanguages,
  translationLanguagesAndTurnarounds,
  setTranslationLanguagesAndTurnarounds,
  transcriptionLanguage,
  numberOfFiles,
  contentMinutes,
  populateInitialTurnaroundLevel,
  errors,
}) => {
  return (
    <>
      <Form.Group className={css(appStyles.sectionPadding)}>
        <Form.Label className={css(styles.title)} as="h3">
          Translation Order Details
        </Form.Label>
        <Typeahead
          clearButton={true}
          id="translation-language-dropdown"
          labelKey="name"
          multiple={true}
          onChange={populateInitialTurnaroundLevel}
          options={transcriptionLanguage === 'English' ? fromEnglishLanguages : ['English']}
          placeholder="Search Languages"
          selected={Object.keys(translationLanguagesAndTurnarounds)}
        />
        {Object.keys(translationLanguagesAndTurnarounds).length >= 1 && (
          <Table>
            <thead>
              <tr>
                <th>Language</th>
                <th>Turnaround Level</th>
                <th>Minutes of content</th>
                <th>Number of Files</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(translationLanguagesAndTurnarounds).map((entry) => {
                const [language, languageTurnaroundPairing] = entry;
                return (
                  <tr key={language}>
                    <td>{language}</td>
                    <td>
                      <Form.Control
                        value={languageTurnaroundPairing['turnaroundLevel']}
                        as="select"
                        aria-label="Translation Turnaround level"
                        onChange={(e) => {
                          e.persist();
                          setTranslationLanguagesAndTurnarounds((prev) => {
                            return {
                              ...prev,
                              [language]: {
                                language: language,
                                turnaroundLevel: e.target?.value,
                              },
                            };
                          });
                        }}
                      >
                        <option value="Standard">Standard</option>
                        <option value="Expedited">Expedited</option>
                        <option value="Rush">Rush</option>
                      </Form.Control>
                    </td>
                    <td>{contentMinutes}</td>
                    <td>{numberOfFiles}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
        {errors.translationLanguagesAndTurnarounds && (
          <Form.Text className="text-danger">{errors.translationLanguagesAndTurnarounds}</Form.Text>
        )}
      </Form.Group>
    </>
  );
};

export default TranslationOrderDetails;
const styles = StyleSheet.create({
  title: {
    color: '#666666',
    fontSize: '24px',
    fontWeight: '400',
  },
  line: {
    margin: '20px 0',
    borderBottom: '0px solid #dcdcdc',
  },
  labels: {
    fontWeight: 'bold',
  },
});

TranslationOrderDetails.propTypes = {
  fromEnglishLanguages: PropTypes.arrayOf(PropTypes.string),
  transcriptionLanguage: PropTypes.string,
  translationLanguagesAndTurnarounds: PropTypes.object,
  setTranslationLanguagesAndTurnarounds: PropTypes.func,
  numberOfFiles: PropTypes.string,
  contentMinutes: PropTypes.string,
  populateInitialTurnaroundLevel: PropTypes.func,
  errors: PropTypes.object,
};
