import PropTypes from 'prop-types';

export const applicantShape = PropTypes.shape({
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  country: PropTypes.string,
  region: PropTypes.string,
  addressStreet: PropTypes.string,
  addressNumber: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  wordsPerMinute: PropTypes.number,
  experience: PropTypes.string,
  languages: PropTypes.string,
  referralSource: PropTypes.string,
  referralText: PropTypes.string,
  referralToken: PropTypes.string,
});

export const blankApplicantInfo = {
  firstname: '',
  lastname: '',
  email: '',
  country: '',
  region: '',
  addressStreet: '',
  addressNumber: '',
  city: '',
  postalCode: '',
  phoneNumber: '',
  wordsPerMinute: null,
  experience: '',
  languages: '',
  referralSource: '',
  referralText: '',
  referralToken: '',
};
