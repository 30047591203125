import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function StartCheatsheetUploadModal(props) {
  return (
    <Modal show={props.show} onHide={props.closeStartUploadCheatsheetModal}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
        {props.selectedCheatsheet && <p>{props.selectedCheatsheet.file.name}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.closeStartUploadCheatsheetModal()}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => props.startCheatsheetUpload()}>
          Start Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

StartCheatsheetUploadModal.propTypes = {
  selectedCheatsheet: PropTypes.object,
  closeStartUploadCheatsheetModal: PropTypes.func,
  show: PropTypes.bool,
  startCheatsheetUpload: PropTypes.func,
};

export default StartCheatsheetUploadModal;
