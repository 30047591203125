import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { Form } from 'react-bootstrap';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import { threeplayApi } from '~/logic/ThreeplayApi';

import OutputFormatRow from './OutputFormatRow';
import {
  setAccountOutputFormatEnabledMutation,
  setAdvancedOutputFormatsMutation,
} from './data/mutations';
import {
  getEnabledFormatsForAccountQuery,
  getAdvancedFormatsEnabledForAccountQuery,
} from './data/queries';

function OutputFormats({ formats, advancedFormats, account }) {
  const [enabledFormats, setEnabledFormats] = useState([]);
  const [showDfxpFormats, setShowDfxpFormats] = useState(false);
  const [errors, setErrors] = useState([]);
  const [advancedFormatsEnabled, setAdvancedFormatsEnabled] = useState(false);

  useEffect(() => {
    getEnabledFormats();
    getAdvancedFormatsEnabled();
  }, []);

  const getEnabledFormats = () => {
    threeplayApi
      .request(getEnabledFormatsForAccountQuery, {
        acctId: account.id,
      })
      .then((response) => {
        response.errors
          ? handleErrors(response.errors)
          : handleGetEnabledFormats(response.data.accountOutputFormats);
      });
  };

  const getAdvancedFormatsEnabled = () => {
    threeplayApi
      .request(getAdvancedFormatsEnabledForAccountQuery, {
        acctId: account.id,
      })
      .then((response) => {
        response.errors
          ? handleErrors(response.errors)
          : handleGetAdvancedFormatsEnabled(response.data.accountAdvancedFormatsEnabled);
      });
  };

  const handleGetAdvancedFormatsEnabled = (enabled) => {
    setErrors([]);
    setAdvancedFormatsEnabled(enabled);
  };

  const handleGetEnabledFormats = (enabledFormats) => {
    setErrors([]);
    setEnabledFormats(enabledFormats);
  };

  const toggleDxfpFormats = (e) => {
    e.stopPropagation();
    setShowDfxpFormats(!showDfxpFormats);
  };

  const handleToggleFormat = (formatIsEnabled, format) => {
    threeplayApi
      .request(setAccountOutputFormatEnabledMutation, {
        aof: {
          existingAccountOutputFormatId: enabledFormats.find(
            (ef) => Number.parseInt(ef.outputFormatId) === format.id
          )?.id,
          accountId: account.id,
          outputFormatId: format.id,
          enableFormat: !formatIsEnabled,
        },
      })
      .then((response) => {
        response.errors ? handleErrors(response.errors) : handleToggleFormatSuccess();
      });
  };

  const handleToggleFormatSuccess = () => {
    setErrors([]);
    getEnabledFormats();
  };

  const handleToggleAdvancedFormat = (formatIsEnabled) => {
    threeplayApi
      .request(setAdvancedOutputFormatsMutation, {
        input: {
          accountId: account.id,
          enableFormat: !formatIsEnabled,
        },
      })
      .then((response) => {
        response.errors ? handleErrors(response.errors) : handleToggleAdvancedFormatSuccess();
      });
  };

  const handleToggleAdvancedFormatSuccess = () => {
    setErrors([]);
    getAdvancedFormatsEnabled();
  };

  const handleErrors = (err) => {
    setErrors(err);
  };

  return (
    <ErrorBoundary component="OutputFormats">
      {errors.length > 0 && (
        <Alert variant={'danger'}>
          <div>Something went wrong. Try refreshing the page. </div>
          <div>Errors:</div>
          {errors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </Alert>
      )}
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Accordion>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <Row>
                    <Col>Caption Formats</Col>
                    <Col xs={3}>
                      <Form.Check
                        type={'checkbox'}
                        label={'Show DFXP Formats'}
                        onClick={toggleDxfpFormats}
                      />
                    </Col>
                  </Row>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {formats
                      .filter((f) => f.input_type === 'Caption')
                      .filter((f) => showDfxpFormats || f.http_format !== 'dfxp')
                      .map((format, index) => {
                        const enabled = enabledFormats
                          .map((enabledFormat) => Number.parseInt(enabledFormat.outputFormatId))
                          .includes(format.id);
                        return (
                          <OutputFormatRow
                            key={index}
                            format={format}
                            enabled={enabled}
                            handleToggleFormat={handleToggleFormat}
                          />
                        );
                      })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  Transcript Formats
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    {formats
                      .filter((f) => f.input_type === 'Transcript')
                      .map((format, index) => {
                        const enabled = enabledFormats
                          .map((enabledFormat) => Number.parseInt(enabledFormat.outputFormatId))
                          .includes(format.id);
                        return (
                          <OutputFormatRow
                            key={index}
                            format={format}
                            enabled={enabled}
                            handleToggleFormat={handleToggleFormat}
                          />
                        );
                      })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  Description Formats
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    {formats
                      .filter((f) => f.input_type === 'Description')
                      .map((format, index) => {
                        const enabled = enabledFormats
                          .map((enabledFormat) => Number.parseInt(enabledFormat.outputFormatId))
                          .includes(format.id);
                        return (
                          <OutputFormatRow
                            key={index}
                            format={format}
                            enabled={enabled}
                            handleToggleFormat={handleToggleFormat}
                          />
                        );
                      })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
        <Row className={css(styles.advancedFormats)}>
          <Col>
            <Card>
              <Card.Header>
                <OutputFormatRow
                  format={{ name: 'Advanced Formats', http_format: '', converter: '' }}
                  enabled={advancedFormatsEnabled}
                  handleToggleFormat={handleToggleAdvancedFormat}
                />
              </Card.Header>
              <Card.Body>
                <Row>Enabling advanced formats adds the following formats to the account:</Row>
                <Row className={'text-secondary'}>
                  {advancedFormats.map((af) => af.name).join(', ')}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

const styles = StyleSheet.create({
  advancedFormats: {
    marginTop: '15px',
  },
});

OutputFormats.propTypes = {
  formats: PropTypes.arrayOf(PropTypes.object),
  advancedFormats: PropTypes.arrayOf(PropTypes.object),
  account: PropTypes.object,
};

export default OutputFormats;
