import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';

function CaptionPercentageLayout(props) {
  function calculateCaptionPercentage(integration) {
    let totalVideos = 0;
    let captionedVideos = 0;
    integration.vpiMonthlyReports.forEach((vmr) => {
      if (!vmr) {
        return;
      }
      totalVideos += vmr.videoCount || 0;
      captionedVideos += vmr.captionedCount || 0;
    });
    return ((captionedVideos / totalVideos) * 100).toFixed(1);
  }

  function openCaptionPercentageModal() {
    props.setCurrentIntegration(props.integration);
    props.setShowCaptionPercentageModal(true);
  }

  return (
    <>
      <div>{calculateCaptionPercentage(props.integration) + '% Files Captioned'}</div>
      <Button
        id="view-caption-details"
        className="p-0"
        variant="link"
        size="sm"
        onClick={() => openCaptionPercentageModal()}
      >
        View Details
      </Button>
    </>
  );
}

CaptionPercentageLayout.propTypes = {
  integration: PropTypes.object.isRequired,
  setShowCaptionPercentageModal: PropTypes.func.isRequired,
  setCurrentIntegration: PropTypes.func.isRequired,
};

export default CaptionPercentageLayout;
