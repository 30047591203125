import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { userLogger } from '~/logic/UserLogger';

import { Table } from '@threeplayground/index';
import { Button, Form, Modal } from 'react-bootstrap';

const SAMPLE_FILES = [
  {
    name: 'Words of Wilderness',
    format: 'mp4',
    duration: '3:06',
    url: 'https://3play-testing.s3.amazonaws.com/media_samples/input_files/order_form/words_of_wilderness.mp4',
  },
  {
    name: 'Its a hat',
    format: 'mp4',
    duration: '0:05',
    url: 'https://3play-testing.s3.amazonaws.com/media_samples/input_files/order_form/its_a_hat.mp4',
  },
  {
    name: 'Chair Slideshow (No Audio)',
    format: 'mp4',
    duration: '0:06',
    url: 'https://3play-testing.s3.amazonaws.com/media_samples/input_files/order_form/chair_slideshow.mp4',
  },
];

const SampleFileModal = ({ closeSampleFileModal, setFilesToUpload, show, words }) => {
  const [selectedSampleUrls, setSelectedSampleUrls] = useState([]);

  const toggleSelectedUrl = (url) => {
    setSelectedSampleUrls((currentlySelectedUrls) => {
      if (currentlySelectedUrls.includes(url)) {
        return currentlySelectedUrls.filter((item) => {
          return item !== url;
        });
      } else {
        return [...currentlySelectedUrls, url];
      }
    });
  };

  const headers = [
    {
      header: '',
      accessor: 'url',
      cell: ({ value }) => {
        return (
          <Form.Check
            checked={selectedSampleUrls.includes(value)}
            id={value}
            onChange={(e) => toggleSelectedUrl(e.target.value)}
            type="checkbox"
            value={value}
          />
        );
      },
    },
    { header: 'Name', accessor: 'name', cell: 'string' },
    { header: 'Duration', accessor: 'duration', cell: 'string' },
    { header: 'Format', accessor: 'format', cell: 'string' },
  ];

  const lookupName = (url) => {
    return SAMPLE_FILES.find((item) => item['url'] === url).name || url;
  };

  const addLinksToOrder = () => {
    setFilesToUpload(
      selectedSampleUrls.map((url) => {
        return {
          // Add the date at the end to fake out any duplicate checking
          name: `${lookupName(url)}-${Date.now()}`,
          sourceUrl: url,
          words: words,
          sampleFile: true,
        };
      })
    );

    closeSampleFileModal();
    userLogger.logEvent('NewOrder', 'Start Upload', {
      'File Source': 'Sample',
      'File count': selectedSampleUrls.count,
    });
  };

  return (
    <Modal show={show} size="lg" onHide={() => closeSampleFileModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Select a Sample File</Modal.Title>
      </Modal.Header>
      <Modal.Body data-testid="modalBody">
        <p className="mb-0">
          Select any of the below sample videos to upload. This collection of videos is curated by
          the 3Play Media Development team. If you would like to request additional sample files to
          be added to the list, please reach out.
        </p>
        <br />
        <Table columns={headers} data={SAMPLE_FILES} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeSampleFileModal()}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => addLinksToOrder()}>
          Add Files
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SampleFileModal.propTypes = {
  closeSampleFileModal: PropTypes.func,
  setFilesToUpload: PropTypes.func,
  show: PropTypes.bool,
  words: PropTypes.string,
};

export default SampleFileModal;
