import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ErrorTable, ContractorCodes } from './CreateReferralCodesTables';

const getContractorName = (id, contractors) => {
  const contractor = contractors.find((contractor) => id === contractor.id);
  return contractor?.name;
};

const getSuccesses = (data) =>
  data
    .filter((result) => result.success)
    .map((result) => {
      const { token, referralLimit, contractor } = result?.referralCode ?? {};

      return {
        contractorId: contractor?.id,
        name: contractor?.name,
        token,
        referralLimit,
      };
    });

const getErrors = (data, contractors) =>
  data
    .filter((result) => !result.success)
    .map((result) => ({
      contractorId: result.contractorId,
      name: getContractorName(result.contractorId, contractors) ?? '',
      error: result.error,
    }));

const Results = ({ data = [], contractors = [] }) => {
  const successes = useMemo(() => getSuccesses(data), [data]);
  const errors = useMemo(() => getErrors(data, contractors), [data]);

  return (
    <>
      {!!successes.length && (
        <>
          {successes.length === data.length ? (
            <h5>{`Successfully created all ${successes.length} referral code(s)`}</h5>
          ) : (
            <h5>{`Successfully created ${successes.length} of ${data.length} referral codes`}</h5>
          )}
          <ContractorCodes data={successes} />
        </>
      )}
      {!!errors.length && (
        <>
          <h5>{errors.length > 1 ? `${errors.length} errors!` : '1 error!'}</h5>

          <ErrorTable data={errors} />
        </>
      )}
    </>
  );
};

Results.propTypes = {
  data: PropTypes.array,
  contractors: PropTypes.array,
};

export default Results;
