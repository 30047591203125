import React from 'react';
import PropTypes from 'prop-types';

import { Table } from '@threeplayground/index';

const SkuTable = ({ skus }) => {
  const columns = [
    {
      header: 'Service',
      accessor: 'name',
      cell: 'string',
    },
    {
      header: 'Netsuite SKU',
      accessor: 'sku',
      cell: 'string',
    },
  ];

  return (
    <>
      <Table columns={columns} data={skus} />
    </>
  );
};

SkuTable.propTypes = {
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default SkuTable;
