import React, { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { StyleSheet, css } from 'aphrodite';
import { FAQ_ONE_INDEX, FAQ_TWO_INDEX, FAQ_THREE_INDEX } from '~/helpers/constants';
import { ProjectContext } from './OrderForm';

function FaqPanel() {
  const { features } = useContext(ProjectContext);
  return (
    <>
      <h2 className={css(styles.sectionHeader)}>Frequently Asked Questions</h2>
      <Accordion>
        <Accordion.Toggle
          className={css(styles.question)}
          as={Button}
          variant="link"
          eventKey="0"
          tabIndex={FAQ_ONE_INDEX}
        >
          <b>
            1. What is your accuracy for
            {features.other ? ' captioning/transcription?' : ' transcription?'}
          </b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <p>
            We guarantee a minimum of 99% accuracy and our actual measured accuracy averages 99.6%.
            We achieve this level of accuracy the first time and every time, eliminating the need
            for review and rework. This article describes the effort we invest to make sure we&nbsp;
            <a
              href="https://www.3playmedia.com/2018/06/06/what-is-99-accuracy-really/"
              target="_blank"
              rel="noreferrer noopener"
              tabIndex={FAQ_ONE_INDEX}
            >
              always achieve at least 99% accuracy
            </a>
            .
          </p>
        </Accordion.Collapse>

        <Accordion.Toggle
          className={css(styles.question)}
          as={Button}
          variant="link"
          eventKey="1"
          tabIndex={FAQ_TWO_INDEX}
        >
          <b>2. How do you calculate accuracy?</b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <p>
            While our 99.6% accuracy output is considered very high quality, it is not perfect.
            99.6% accuracy leaves up to 0.4% error, which means that a 10-minute file with 1,500
            words could have up to 6 errors. If a word contains a typo or is misspelled, that counts
            as one error. Our standard process aims to transcribe all content exactly as it is
            spoken, including false starts, while omitting certain unnecessary utterances, such as
            “um” and “uh”.
          </p>
        </Accordion.Collapse>

        <Accordion.Toggle
          className={css(styles.question)}
          as={Button}
          variant="link"
          eventKey="2"
          tabIndex={FAQ_THREE_INDEX}
        >
          <b>3. What quality standards do you follow?</b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <p>
            We adhere to DCMP best practices for speaker identification, sound effects and
            non-speech elements, tone, caption formatting, grammar, and line breaks. We also adhere
            to a strict set of transcription standards that covers paragraphing, inaudible tags,
            treatment of technical terminology, word synchronization, false starts, filler words,
            broken sentences, interruptions, handling poor audio quality, accents, and overlapping
            voices, general numbers, lists and ranges, roman numerals,
            percentages/decimals/fractions, currency, ordinal place, scores, websites, telephone
            numbers, punctuation, capitalization, dates and times, onomatopoeias, abbreviations,
            acronyms, and quotations.
          </p>
        </Accordion.Collapse>
      </Accordion>
      <div className="mt-3">
        <h2 className={css(styles.sectionHeader)}>Terms &amp; Conditions</h2>
        <p>
          By clicking the “Submit” button, you confirm you have read, understand, and agree to be
          bound by the{' '}
          <a href="https://www.3playmedia.com/terms/" target="_blank" rel="noopener noreferrer">
            Terms and Conditions
          </a>
          .
        </p>
        <p></p>
      </div>
      <div className="mt-4">
        <h2 className={css(styles.sectionHeader)}>File Cancellation Policy</h2>
        <p>
          Orders will begin processing immediately. If you would like to cancel a file, you must do
          so while it is still pre-processing. This time is roughly equivalent to the duration of
          your file. Files submitted for transcription in a language other than English or Spanish
          can not be cancelled.
        </p>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  question: {
    paddingLeft: '0rem',
    color: '#007eb5',
    display: 'block',
    whiteSpace: 'normal',
    textAlign: 'left',
  },
  sectionHeader: {
    fontWeight: 'bold',
    marginBottom: '.25rem',
    fontSize: '1.25rem',
  },
});

export default FaqPanel;
