import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export interface PageProps {
  children: React.ReactNode;
  fullWidth?: boolean;
}
//TODO: Support Nav
//TODO: Import theme and style bg color etc.
export const Page = ({ children, fullWidth = true }: PageProps) => {
  return <Container fluid={fullWidth}>{children}</Container>;
};

interface PageTitleProps {
  children: React.ReactNode;
}
const PageTitle = ({ children }: PageTitleProps) => {
  return (
    <Row>
      <Col>
        <h1>{children}</h1>
        <hr />
      </Col>
    </Row>
  );
};

interface PageBodyProps {
  children: React.ReactNode;
}
const PageBody = ({ children }: PageBodyProps) => {
  return (
    <Row>
      <Col className="py-2">{children}</Col>
    </Row>
  );
};

Page.Title = PageTitle;
Page.Body = PageBody;
