import { Logger } from '~/logic/Logger/Logger';
import { getCsrfToken } from '~/helpers/authtokenHelper';

export function endEvent(jobId) {
  const confirmBool = window.confirm(
    "By selecting 'end event', this will complete this captioning job and prevent any further captioning for this customer event.Please confirm this event no longer requires captions.\n\nIf the event has not ended, but you are unable to continue captioning, please close this tab without selecting 'end session', and notify live_operations@3playmedia.com that you are unable to continue captioning."
  );

  if (confirmBool) {
    removeLocallyPersistedJobData(jobId);
    const endpoint = `/swatei/live_events/${jobId}/end_event`;
    callLiveController(endpoint);
  }

  return confirmBool;
}

export function endSession(jobId) {
  const confirmBool = window.confirm(
    'Are you sure you want to end your session?\n\nEnding your session will stop captions for this event. If the stream is still active and requires captions, ending your session early may result in penalties and negative marks to your 3Play Media account.'
  );

  if (confirmBool) {
    removeLocallyPersistedJobData(jobId);
    const endpoint = `/swatei/live_events/${jobId}/end_session`;
    callLiveController(endpoint);
  }

  return confirmBool;
}

function removeLocallyPersistedJobData(jobId) {
  localStorage.removeItem(jobId);
}

function getFormData() {
  const formData = new FormData();
  formData.append('authenticity_token', getCsrfToken());
  return formData;
}

function redirectHome() {
  window.location = window.origin;
}

async function callLiveController(endpoint) {
  const response = await fetch(endpoint, {
    headers: { Accept: 'application/json' },
    method: 'POST',
    body: getFormData(),
  });
  if ([401, 404].includes(response.status)) {
    Logger.getInstance().warn({
      message: '3play App end_session triggered redirect',
      info: { response },
    });

    const { redirect } = await response.json();
    window.skipConfirmLeavePage = true;
    window.location.replace(redirect);
    return;
  }
  const { success } = await response.json();
  if (success) {
    redirectHome();
  } else {
    window.alert('Something went wrong. Please try again.');
  }
}
