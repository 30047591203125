import React, { useCallback, useContext, useEffect, useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';

import SubmitTestModal from './SubmitTestModal';
import TestFile from './TestFile';

import { localeText } from '../locales/locale';
import { palette } from '~/components/contractor_applications/theme';
import { LocaleContext } from '../locales/LocaleContext';

function Test(props) {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [time, setTime] = useState(0);
  const locale = useContext(LocaleContext);

  function confirmSubmit() {
    setShowSubmitModal(true);
  }

  function hideSubmitModal() {
    setShowSubmitModal(false);
  }

  function submit() {
    props.onSubmit(time / 1000);
  }

  const tick = useCallback(() => {
    setCurrentTime(new Date());
  }, [startTime, setTime]);

  const handlePlay = useCallback(() => {
    if (startTime) {
      return;
    }
    setStartTime(new Date());
  });

  useEffect(() => {
    const measure = startTime ? currentTime - startTime : 0;
    setTime(measure);
  }, [currentTime, startTime]);

  useEffect(() => {
    const interval = setInterval(tick, 100);
    return () => {
      clearInterval(interval);
    };
  }, [startTime, tick]);

  return (
    <>
      <div className={'full d-flex flex-row ' + css(styles.main)}>
        <SubmitTestModal show={showSubmitModal} onHide={hideSubmitModal} onConfirm={submit} />

        <div className={css(styles.nav)}>
          <h3 className={css(styles.navSectionHeader)}>Initial Application</h3>
          <h3 className={css(styles.navSectionHeader, styles.active)}>Transcription Test</h3>
        </div>
        <div className={css(styles.navSpacer)}></div>
        <div className={css(styles.test)}>
          <div className={css(styles.testHeader)}>
            <h2>On this page, you’ll be tested on your ability to transcribe a series of files.</h2>
            <div
              className="primary-text"
              // disable react/no-danger justificartion: this is server-controlled,
              // static content loaded by locale.js, and we have one use case to support a link.
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: localeText(locale)['test:instructions'] }}
            />{' '}
            <small className="text-muted">
              While editing a transcript, press <code>ctrl</code> + <code>space</code> to play /
              pause the video.
              <br />
              You can also press <code>ctrl</code> + <code>[</code> and <code>ctrl</code> +{' '}
              <code>]</code> to rewind / fast foward by a few seconds.
            </small>
          </div>

          {props.testData.map((test, ii) => (
            <TestFile
              key={ii}
              index={ii}
              test={test}
              handlePlay={handlePlay}
              onUpdate={props.onUpdate}
            />
          ))}

          <Button variant="3play" className="mt-4" onClick={confirmSubmit}>
            Submit Transcription Test
          </Button>
        </div>
      </div>
    </>
  );
}

Test.propTypes = {
  testData: PropTypes.arrayOf(
    PropTypes.shape({
      instructions: PropTypes.string,
      name: PropTypes.string,
      transcript: PropTypes.string,
      testFile: PropTypes.shape({
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequred,
      }),
    })
  ),
  onUpdate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  active: {
    color: palette.primary,
  },
  main: {
    marginTop: '30px',
  },
  nav: {
    display: 'block',
    marginTop: '10vh',
    fontWeight: 600,
    fontSize: '13px',
    width: '270px',
    position: 'fixed',
    padding: '40px 30px',
  },
  navSpacer: {
    marginRight: '300px',
    width: '0',
  },
  navSectionHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '1em',
    color: palette.text,
  },
  test: {
    marginRight: '20px',
  },
  testHeader: {
    maxWidth: '775px',
  },
});

export default Test;
