import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { threeplayApi } from '~/logic/ThreeplayApi';
import { additionalServicesQuery } from '~/components/app/order_form/data/queries';

import { Card, Table } from 'react-bootstrap';

import { css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import OrderOptionsContainer from '~/components/app/order_form/serviceOptions/OrderOptionsContainer';
import ObnoxiousYellowBox from '~/components/app/order_form/units/ObnoxiousYellowBox';

import { SCRIPTING_OPTIONS_START_INDEX } from '~/helpers/constants';

function BroadcastScriptOrderOptions(props) {
  const DATE_FORMAT = 'MM/DD/YY, hh:mma';

  const [availableFormats, setAvailableFormats] = useState([]);
  const selectedFormats = props.orderOptions?.scriptFormats || [];
  const primaryServiceDeliveryDate = moment(
    props.primaryService.orderOptions.turnaroundLevel.deliveryDate,
    DATE_FORMAT
  );

  useEffect(() => {
    threeplayApi
      .request(additionalServicesQuery, {
        languageType: props.selectedLanguage,
        primaryServiceType: props.primaryService.serviceType,
        primaryServiceTurnaroundLevel:
          props.primaryService.orderOptions.turnaroundLevel &&
          parseInt(props.primaryService.orderOptions.turnaroundLevel.id),
        selectedAdditionalServiceType: 'BroadcastScript',
      })
      .then((response) => {
        const data = response.data || {};
        if (data.project?.orderableServices[0].additionalServices) {
          const options = data.project.orderableServices[0].additionalServices.find(
            (service) => service.serviceType === 'BroadcastScript'
          ).orderOptions;
          setAvailableFormats(options.broadcastScriptFormats);
        }
      });
  }, []);

  function updateSelectedFormats(formatCode) {
    const format = availableFormats.find((format) => format.code === formatCode);
    const inList = selectedFormats.some((format) => format.code === formatCode);

    if (inList) {
      removeSelectedFormat(format);
    } else {
      addSelectedFormat(format);
    }
  }

  function addSelectedFormat(format) {
    const selections = [...selectedFormats, format];
    updateOptions(selections);
  }

  function removeSelectedFormat(format) {
    const selections = [...selectedFormats];
    selections.splice(
      selections.findIndex((f) => f.code === format.code),
      1
    );
    updateOptions(selections);
  }

  function updateOptions(selections) {
    props.updateOrderOptions('BroadcastScript', {
      ...props.orderOptions,
      scriptFormats: selections,
    });
  }

  function estimateDeliveryDate(turnaroundHours) {
    return primaryServiceDeliveryDate.add(turnaroundHours, 'hours').format(DATE_FORMAT);
  }

  return (
    <OrderOptionsContainer title="Broadcast Scripts" componentName="BroadcastScriptOrderOptions">
      <Card.Title className={css(appStyles.title)}>Choose Broadcast Script Formats</Card.Title>
      <Card.Subtitle className="mt-2 mb-4 text-muted">
        3Play Media offers transcripts in a variety of post-production formats, which include both
        basic audio scripts reflecting dialogue only, or as complex post-production scripts that
        include not only dialogue but also sound effects, speaker identification, scene headings,
        continuity, on-screen graphics, timestamps, and more. Broadcast Scripts can only be
        completed on English source transcripts at this time.
      </Card.Subtitle>
      <Table responsive>
        <thead>
          <tr>
            <th></th>
            <th>Format</th>
            <th>Description</th>
            <th>Estimated Delivery</th>
            <th>Price/min</th>
          </tr>
        </thead>
        <tbody>
          {availableFormats?.map((option, index) => (
            <tr key={option.name}>
              <td>
                <input
                  type="checkbox"
                  tabIndex={index + SCRIPTING_OPTIONS_START_INDEX}
                  name={option.code}
                  value={option.code}
                  checked={selectedFormats.map((format) => format.code).includes(option.code)}
                  onChange={(e) => updateSelectedFormats(e.target.value)}
                />
              </td>
              <td>{option.name}</td>
              <td>{option.description}</td>
              <td>{estimateDeliveryDate(option.turnaroundHours)}</td>
              <td>{option.displayPrice}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {props.cheatSheetComponent('broadcastScriptingService')}
      <ObnoxiousYellowBox
        checked={props.warningSelected}
        toggleChecked={props.setWarningSelected}
        title="Broadcast Scripts cannot be Refunded"
      >
        By checking this box, you indicate that you will not have the opportunity to review your
        transcripts before they are submitted for Broadcast Scripts. Broadcast Scripts will begin as
        soon as the main service is completed.
      </ObnoxiousYellowBox>
    </OrderOptionsContainer>
  );
}

BroadcastScriptOrderOptions.propTypes = {
  cheatSheetComponent: PropTypes.func,
  orderOptions: PropTypes.object,
  primaryService: PropTypes.shape({
    description: PropTypes.string,
    displayName: PropTypes.string,
    orderOptions: PropTypes.arrayOf(PropTypes.object),
    price: PropTypes.shape({
      amount: PropTypes.number,
      unit: PropTypes.string,
    }),
    primary: PropTypes.bool,
    serviceType: PropTypes.string,
  }),
  selectedLanguage: PropTypes.string,
  setWarningSelected: PropTypes.func,
  updateOrderOptions: PropTypes.func,
  warningSelected: PropTypes.bool,
};

export default BroadcastScriptOrderOptions;
