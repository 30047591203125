import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';

function SectionedCardLayout(props) {
  return (
    <>
      <div className="pt-4">
        <h5 className={css(styles.platformName)}>{props.platformName}</h5>
      </div>
      <Row>{props.children}</Row>
    </>
  );
}

const styles = StyleSheet.create({
  platformName: {
    fontWeight: 600,
  },
});

SectionedCardLayout.propTypes = {
  children: PropTypes.object,
  platformName: PropTypes.string,
};

export default SectionedCardLayout;
