import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

function NoData(props) {
  return (
    <div className={css(styles.panel)}>
      <div className={css(styles.head)} />
      <div className={css(styles.body)}>{props.children}</div>
    </div>
  );
}

NoData.propTypes = {
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  panel: {
    disply: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  head: {
    minHeight: '20%',
    backgroundColor: '#ccc',
  },
  body: {
    flex: '1',
    backgroundColor: '#fff',
  },
});

export default NoData;
