import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function ReplaceSource(props) {
  return (
    <div className={css(styles.replaceSource)}>
      <div className={css(styles.replaceButtonContainer)}>
        {props.disabled === false && (
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => props.setShowModal.replaceSource(true)}
          >
            {props.action === 'replace' ? 'Replace Source Media' : 'Add'}
          </Button>
        )}
        {props.disabled === true && (
          <OverlayTrigger
            popperConfig={{
              modifiers: {
                preventOverflow: {
                  enabled: false,
                },
              },
            }}
            overlay={
              <Tooltip id="tooltip-disabled">
                One or more of the services aren&apos;t completed. You can only replace source media
                on file that is completed.
              </Tooltip>
            }
          >
            <span className="d-inline-block">
              <Button
                className={css(styles.replaceButtonDisabled)}
                variant="outline-dark"
                size="sm"
                disabled
              >
                Replace Source Media
              </Button>
            </span>
          </OverlayTrigger>
        )}
      </div>
    </div>
  );
}

ReplaceSource.propTypes = {
  action: PropTypes.oneOf(['replace', 'add']),
  disabled: PropTypes.bool,
  setShowModal: PropTypes.shape({
    replaceSource: PropTypes.func,
  }),
};

const styles = StyleSheet.create({
  replaceSource: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  replaceButtonContainer: {
    paddingLeft: '2em',
  },
  replaceButtonDisabled: {
    pointerEvents: 'none',
  },
});

export default ReplaceSource;
