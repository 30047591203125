import { Formatter } from '../_types';

export class CapitalizeStartFormatter implements Formatter {
  /**
   * A formatter that capitalizes the start of the text if the proceeding text
   * is empty or ends with punctuation or a right bracket.
   *
   * @example new CapitalizeStartFormatter().format("hello", "")
   * // returns "Hello"
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  format(text: string, lastWordSent: string) {
    return this.shouldCapitalize(text, lastWordSent)
      ? text.replace(/(^[a-z])/, (match, p1: string) => p1.toUpperCase())
      : text;
  }

  shouldCapitalize(text: string, lastWordSent: string) {
    return lastWordSent === '' || !!lastWordSent.match(/[!?.\]]$/);
  }
}
