/* eslint-disable @typescript-eslint/no-implied-eval */

import type { LibraryV0 } from './types/libraryV0';

import { env } from '~/core/env';

// NOTE: this hack won't work if unsafe-eval is disallowed via CSP headers.
// This is only necessary as Parcel is too aggressively transforming
// imports -> requires. We can remove this and use a normal `await import(URL)`
// once this bug is resolved.
// https://github.com/parcel-bundler/parcel/issues/8316
const realImport = new Function('u', 'return import(u)');

const LOCAL_URL = 'http://localhost:8787';
const PROD_URL = 'https://view.3playmedia.com';
const HOST = env.threeplayEnv === 'local' ? LOCAL_URL : PROD_URL;

const accessPlayerLibraryUrl = (version: string) =>
  `${HOST}/assets/access-player/${version}/lib.js`;

/**
 * This query returns code! We fetch versions of the Access Player
 * dynamically to support the latest snippet generation.
 *
 * The versions we pull from the `versionQuery` are always the latest major
 * versions, so we can confindently add the latest types to each types file.
 */
export async function libraryQuery(version: string | null) {
  if (!version) {
    throw new Error('Missing version to get Access Player Library');
  }

  const module = (await realImport(accessPlayerLibraryUrl(version))) as LibraryV0;
  return module;
}
