import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ThreeplayTable from '~/components/ops/common/ThreeplayTable';

export const AddNewContractorsTable = ({ data = [] }) => {
  const tableData = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
    ],
    []
  );

  return <ThreeplayTable disableSortBy columns={columns} data={tableData} />;
};

AddNewContractorsTable.propTypes = {
  data: PropTypes.array,
};

export const ContractorCodes = ({ data = [] }) => {
  const tableData = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      { Header: 'Contractor ID', accessor: 'contractorId' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Token', accessor: 'token' },
      { Header: 'Referral Limit', accessor: 'referralLimit' },
    ],
    []
  );

  return <ThreeplayTable disableSortBy columns={columns} data={tableData} />;
};

ContractorCodes.propTypes = {
  data: PropTypes.array,
};

export const ErrorTable = ({ data = [] }) => {
  const tableData = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      { Header: 'Contractor ID', accessor: 'contractorId' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Error', accessor: 'error' },
    ],
    []
  );

  return <ThreeplayTable disableSortBy columns={columns} data={tableData} />;
};

ErrorTable.propTypes = {
  data: PropTypes.array,
};
