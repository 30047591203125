import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Table from 'react-bootstrap/Table';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '../common/constants';

import AlertMessage from '~/components/app/common/AlertMessage';
import TableHeader from './TableHeader';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';
import { durationString } from '~/helpers/numbers';
import { lacFileShowEndpoint } from '~/helpers/app/paths';

function InProgressEvents(props) {
  function eventName(event) {
    const eventName = <a href={lacFileShowEndpoint(event.id)}>{event.name}</a>;
    const errors = [...event.dashboardErrors['action'], ...event.dashboardErrors['warning']];

    if (errors.length > 0) {
      return (
        <TooltipIfErrors errors={errors}>
          <i
            className={`fa fa-exclamation-triangle mr-1 ${statusTextStyle(event.dashboardErrors)}`}
          ></i>
          {eventName}
        </TooltipIfErrors>
      );
    } else {
      return eventName;
    }
  }

  const statusStyle = (errors) => {
    if (errors.action.length > 0) {
      return css(styles.errorState);
    }

    if (errors.warning.length > 0) {
      return css(styles.warningState);
    }

    return '';
  };

  const statusTextStyle = (errors) => {
    if (errors.action.length > 0) {
      return css(styles.errorStateText);
    }

    if (errors.warning.length > 0) {
      return css(styles.warningStateText);
    }

    return '';
  };

  return (
    <Card className="mt-3">
      <Card.Header as="h5" className={`${css(styles.cardHeader)} p2 font-weight-bold`}>
        In Progress Live Captions
        <span className={`${css(styles.liveBadge)} ml-2`}>
          <span className={css(styles.liveLabel)}>• LIVE</span>
        </span>
      </Card.Header>
      <Table className="mb-0 v-align-middle-table">
        <thead>
          <tr>
            {props.data.headerDetails.map((header) => {
              return (
                <TableHeader
                  key={header.displayName}
                  header={header}
                  setSortParams={props.setSortParams}
                />
              );
            })}
            {props.data.liveEventDetails.every((event) => event.videoPlatform !== 'Zoom') && (
              <th width="5%"></th>
            )}
            <th width="5%"></th>
          </tr>
        </thead>
        <tbody>
          {props.data.liveEventDetails.map((event) => {
            return (
              <tr key={event.id} className={statusStyle(event.dashboardErrors)}>
                <td>{eventName(event)}</td>
                <td>
                  {event.startTime === null
                    ? 'Unknown'
                    : moment(event.startTime * 1000)
                        .tz(props.userTimeZone)
                        .format(DATE_TIME_FORMAT)}
                </td>
                <td>{event.captionServiceType}</td>
                <td>{durationString(Math.floor(event.duration / 1000))}</td>
                <td>{event.integrationName === ' : ' ? 'n/a' : event.integrationName}</td>
                <td>
                  {event.videoPlatform === 'Zoom' && (
                    <Row className="mb-2">
                      <Col sm={10} className="pr-0">
                        <InputGroup className="p-0">
                          <FormControl
                            aria-label="API Token"
                            defaultValue={event.captionIngestionUrl}
                            isInvalid={
                              props.erroredEvents.hasOwnProperty(event.id) &&
                              !(props.erroredEvents[event.id] === '')
                            }
                            isValid={
                              event.zoomApiTokenPresent ||
                              (props.erroredEvents.hasOwnProperty(event.id) &&
                                props.erroredEvents[event.id] === '')
                            }
                            onChange={(e) => props.setCaptionIngestUrl(event.id, e.target.value)}
                          />
                          <InputGroup.Append>
                            <Button
                              aria-label="Update"
                              variant="primary"
                              onClick={() => {
                                props.updateZoomCaptionUrlForEvent(event.id);
                              }}
                            >
                              Update
                            </Button>
                          </InputGroup.Append>
                          <Form.Control.Feedback type="invalid">
                            {props.erroredEvents[event.id]}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                      <Col sm={2} className="pt-2">
                        <OverlayTrigger
                          placement="top"
                          trigger={['hover', 'focus']}
                          overlay={
                            <Tooltip>
                              {event.zoomApiTokenPresent
                                ? '3Play has successfully added your Zoom API token.'
                                : '3Play will add the Zoom API token for you. Refresh this page after your Zoom event has started to verify the token has been added. If you still do not see the token, then retrieve the token from Zoom and enter into this field.'}
                            </Tooltip>
                          }
                        >
                          <i className="fa fa-question-circle"></i>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  )}
                </td>
                <td>
                  <DropdownButton
                    alignRight
                    as={ButtonGroup}
                    variant="outline-primary"
                    title="Actions"
                    size="sm"
                  >
                    <Dropdown.Item onClick={() => props.openEndInProgressEventModal(event)}>
                      End Event Now
                    </Dropdown.Item>
                    {event.videoPlatform === 'Zoom' && (
                      <Dropdown.Item
                        onClick={() =>
                          props.resendZoomApiTokenEmail(
                            Number(event.id),
                            Number(event.integrationId)
                          )
                        }
                      >
                        Resend API Token Email
                      </Dropdown.Item>
                    )}
                    {event.captionServiceType === 'Professional' && (
                      <Dropdown.Item onClick={() => props.openCustomerSupportModal(event.id)}>
                        Request Customer Support
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {props.success && <AlertMessage message={props.success} variant="success" />}
      {props.error && <AlertMessage message={props.error} variant="danger" />}
    </Card>
  );
}

const styles = StyleSheet.create({
  cardHeader: {
    background: '#F1F1F1',
  },
  errorState: {
    background: '#F2DEDE',
  },
  errorStateText: {
    color: '#843534',
  },
  liveBadge: {
    background: '#FFFFFF',
    border: '1px solid #C90202',
    borderRadius: '5px',
    boxSizing: 'border-box',
    padding: '0px 0px 3px 0px',
  },
  liveLabel: {
    color: '#C90202',
    fontSize: '15px',
    margin: '0.25rem',
    display: 'inline-block',
    verticalAlign: 'baseline',
    fontWeight: 'bold',
  },
  warningState: {
    background: '#FCF8E3',
  },
  warningStateText: {
    color: '#8A6D3B',
  },
});

InProgressEvents.propTypes = {
  data: PropTypes.object,
  error: PropTypes.string,
  openEndInProgressEventModal: PropTypes.func,
  resendZoomApiTokenEmail: PropTypes.func,
  setCaptionIngestUrl: PropTypes.func,
  openCustomerSupportModal: PropTypes.func,
  setSortParams: PropTypes.func,
  success: PropTypes.string,
  updateZoomCaptionUrlForEvent: PropTypes.func,
  userTimeZone: PropTypes.string,
};

export default InProgressEvents;
