import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';
import { offPlatformVoiceOverQualityControlJobsPaths } from './offPlatformVoiceOverQualityControlJobsPaths';
import { OffPlatformVoiceOverQualityControlJob } from './:id/OffPlatformVoiceOverQualityControlJob';

function OffPlatformVoiceOverQualityControlJobsApp({ permissions }: RootProps) {
  return (
    <JobAppContainer
      showPath={offPlatformVoiceOverQualityControlJobsPaths.show.route}
      jobComponent={<OffPlatformVoiceOverQualityControlJob />}
      permissions={permissions}
    />
  );
}

export default OffPlatformVoiceOverQualityControlJobsApp;
