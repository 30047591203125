import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { StyleSheet, css } from 'aphrodite';

import { TEMPLATE_NEW_INDEX, TEMPLATE_SUBMIT_BUTTON_INDEX } from '~/helpers/constants';

const TemplateCreation = ({
  saveTemplate,
  setTemplateName,
  templateName,
  templateSaved,
  templateErrors,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [emptyName, setEmptyName] = useState(null);

  function updateTemplateName(name) {
    if (name.length === 0) {
      setEmptyName(true);
      setButtonDisabled(true);
    } else {
      setTemplateName(name);
      setEmptyName(false);
      setButtonDisabled(false);
    }
  }

  return (
    <>
      <div className={css(styles.question)}>Would you like to save a template?</div>
      <Form.Group className={css(styles.inputWidth)}>
        {templateSaved && <Alert variant="success">Template saved!</Alert>}
        {templateErrors.length > 0 && <Alert variant="danger">{templateErrors[0]}</Alert>}
        <Form.Control
          id="newTemplateName"
          isInvalid={emptyName === true}
          onChange={(e) => updateTemplateName(e.target.value)}
          placeholder={'My new template'}
          tabIndex={TEMPLATE_NEW_INDEX}
          type="text"
          value={templateName}
        />
        <Button
          className={css(styles.templateButton)}
          disabled={buttonDisabled}
          onClick={saveTemplate}
          tabIndex={TEMPLATE_SUBMIT_BUTTON_INDEX}
          type="submit"
          variant="primary"
        >
          Save Template
        </Button>
      </Form.Group>
    </>
  );
};

TemplateCreation.propTypes = {
  templateErrors: PropTypes.arrayOf(PropTypes.string),
  templateName: PropTypes.string,
  templateSaved: PropTypes.bool,
  saveTemplate: PropTypes.func,
  setTemplateName: PropTypes.func,
};

const styles = StyleSheet.create({
  question: {
    fontWeight: 'bold',
    fontSize: '1.25em',
    marginBottom: '0.25em',
  },
  error: {
    color: '#ff0000',
  },
  templateButton: {
    marginTop: '5px',
  },
  inputWidth: {
    maxWidth: '19rem',
  },
});

export default TemplateCreation;
