import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form as BootstrapForm } from 'react-bootstrap';

import { Alert, Button, Link, Table } from '@threeplayground/index';
// Have to alias the Form due to issues with how the Form.d.ts file is pulled in.
// If we don't alias, we'll get a collision with the aliased BootstrapForm.
import { Form as ThreeplayForm, Page } from '@threeplayground/unstable';
import { opsTasksPaths } from './opsTasksPaths';

import { use3PMutation } from '~/logic/unstable/use3PMutation';
import { use3PQuery } from '~/logic/unstable/use3PQuery';

const OPS_TASK_QUERY = `
  query opsTask($where: OpsTaskWhereUniqueInput!) {
    opsTask(where: $where) {
      active
      argsSpec
      callCount
      id
      description
      displayName
      notes
      template
      typicalRunTimeInMinutes
    }
  }
`;

const INVOKE_OPS_TASK_MUTATION = `
  mutation createOpsTaskInvocation($data: OpsTaskInvocationCreateInput!) {
    createOpsTaskInvocation(data: $data) {
      data {
        id
      }
      errors {
        message
      }
    }
  }
`;

const TABLE_COLUMNS = [
  { header: 'ID', accessor: 'id', cell: 'number' },
  { header: 'Name', accessor: 'displayName', cell: 'string' },
  { header: 'Description', accessor: 'description', cell: 'string' },
  { header: 'Typical Runtime', accessor: 'typicalRunTimeInMinutes', cell: 'number' },
  { header: 'Active', accessor: 'active', cell: 'string' },
  { header: 'Notes', accessor: 'notes', cell: 'string' },
  { header: 'Call Count', accessor: 'callCount', cell: 'number' },
];

const prettyPrintJson = (json) => {
  return JSON.stringify(JSON.parse(json), undefined, 4);
};

export function OpsTasksShowPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [globalErrors, setGlobalErrors] = useState();
  const [fieldErrors, setFieldErrors] = useState();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({});

  const {
    data,
    error: opsTaskQueryError,
    isLoading,
  } = use3PQuery(OPS_TASK_QUERY, {
    variables: { where: { idEq: id } },
    extractKey: 'opsTask',
  });

  const { mutateAsync } = use3PMutation(INVOKE_OPS_TASK_MUTATION, {
    extractKey: 'createOpsTaskInvocation',
  });

  const onSubmit = async (formData) => {
    const payload = { opsTaskId: Number(id) };
    if (formData.args) {
      payload.args = formData.args;
    }
    if (formData.runNotes) {
      payload.runNotes = formData.runNotes;
    }
    const { data, errors, globalErrors } = await mutateAsync({
      data: payload,
    });

    if (data && !errors && !globalErrors) {
      navigate(opsTasksPaths.list.getLink(), { replace: true });
    } else {
      setGlobalErrors(globalErrors);
      setFieldErrors(errors);
    }
  };

  // If the initial load of the task errors, provide an escape hatch
  if (opsTaskQueryError) {
    return (
      <p>
        Something went wrong. Try going back to{' '}
        <Link to={opsTasksPaths.list.getLink()}>Task List</Link> and try again.
      </p>
    );
  }

  return (
    <Page fullWidth={false}>
      <Page.Title>{`Enqueue Task: ${id}`}</Page.Title>
      <Page.Body>
        {globalErrors && (
          <Alert dismissible variant="danger" onClose={() => setGlobalErrors()}>
            {globalErrors.map((err) => err.message)}
          </Alert>
        )}
        {fieldErrors && (
          <Alert dismissible variant="danger" onClose={() => setFieldErrors()}>
            {fieldErrors.map((err) => err.message)}
          </Alert>
        )}
        <Link to={opsTasksPaths.list.getLink()}>Back to all Tasks</Link>
        {isLoading && <span className="spinner-border spinner-border-sm mr-1" />}
        {!isLoading && (
          <>
            <Row className="py-2">
              <Col>
                <Table columns={TABLE_COLUMNS} data={[data]} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ThreeplayForm onSubmit={handleSubmit(onSubmit)}>
                  <BootstrapForm.Row className="mb-3">
                    <BootstrapForm.Label>Run Notes</BootstrapForm.Label>
                    <Form.Control
                      as="input"
                      placeholder="Jira ticket ID / notes about why you're running this task"
                      disabled={isSubmitting}
                      {...register('runNotes')}
                    />
                  </BootstrapForm.Row>
                  {Boolean(data?.template) && (
                    <BootstrapForm.Row className="mb-3">
                      <BootstrapForm.Label>Task Parameters</BootstrapForm.Label>
                      <Form.Control
                        as="textarea"
                        defaultValue={prettyPrintJson(data.template)}
                        style={{ height: '400px' }}
                        disabled={isSubmitting}
                        {...register('args')}
                      />
                    </BootstrapForm.Row>
                  )}

                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Enqueue Task
                  </Button>
                </ThreeplayForm>
              </Col>
            </Row>
          </>
        )}
      </Page.Body>
    </Page>
  );
}
