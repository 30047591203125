import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { StyleSheet, css } from 'aphrodite';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { MULTI_METHOD_TOOLTIP } from '~/helpers/constants';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';

function FileUploadComponent(props) {
  let lastTarget = null;

  function isFile(evt) {
    const dt = evt.dataTransfer;

    for (let i = 0; i < dt.types.length; i++) {
      if (dt.types[i] === 'Files') {
        return true;
      }
    }
    return false;
  }

  function isABootstrapModalOpen() {
    return document.querySelectorAll('.modal.show').length > 0;
  }

  useEffect(() => {
    window.addEventListener('dragenter', handleDragEnter);
    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('dragleave', handleDragLeave);
    return () => {
      window.removeEventListener('dragleave', handleDragLeave);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('dragover', handleDragOver);
    return () => {
      window.removeEventListener('dragover', handleDragOver);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('drop', handleDrop);
    return () => {
      window.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleDragEnter = (event) => {
    if (isFile(event) && !props.disabled && !isABootstrapModalOpen()) {
      lastTarget = event.target;
      document.querySelector('#dropzone').style.setProperty('visibility', 'visible', 'important');
      document.querySelector('#dropzone').style.setProperty('opacity', '1', 'important');
      document.querySelector('#dropzone').style.setProperty('display', 'flex', 'important');
    }
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    if (event.target === document || event.target === lastTarget) {
      document.querySelector('#dropzone').style.setProperty('visibility', 'hidden', 'important');
      document.querySelector('#dropzone').style.setProperty('opacity', '0', 'important');
      document.querySelector('#dropzone').style.setProperty('display', 'none', 'important');
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    document.querySelector('#dropzone').style.setProperty('visibility', 'hidden', 'important');
    document.querySelector('#dropzone').style.setProperty('opacity', '0', 'important');
    document.querySelector('#dropzone').style.setProperty('display', 'none', 'important');
  };

  // 2147483648 bytes = 2 GB
  const maxSize = 2147483648;

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        props.openStartUploadModal(acceptedFiles);
      }
      if (rejectedFiles.length > 0) {
        const rejected = rejectedFiles.map((file) => {
          const isFileTooLarge = file.size > maxSize;
          return {
            name: file.path,
            size: file.size,
            fileTooLarge: isFileTooLarge,
          };
        });
        props.setRejectedFiles(rejected);
      }
    },
    [props.filesToUpload, props.rejectedFiles]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: props.supportedFormats,
    disabled: props.disabled,
    maxSize: maxSize,
    minSize: 0,
    multiple: true,
    onDrop: onDrop,
  });

  return (
    <>
      <div className={css(styles.dropzone)} id="dropzone" {...getRootProps()}>
        <i aria-hidden="true" className={css(styles.uploadIconDrag) + ' fa fa-upload'}></i>
        <div className={css(styles.textnode)} id="textnode">
          Drop your files anywhere on this page!
        </div>
        <input {...getInputProps()} />
      </div>
      <Card.Body>
        <div className={css(styles.uploadIcon)}>
          <i className="fa fa-cloud-upload"></i>
        </div>
        <div className={css(styles.uploadName)}>Drop Files Anywhere</div>
        <Button
          tabIndex={props.index}
          variant="primary"
          className={props.disabled ? 'disabled mt-1' : 'mt-1'}
          onClick={open}
        >
          Select from Computer
        </Button>
        <div>
          <small className="text-muted">Each file must be &lt;2GB.</small>
        </div>
      </Card.Body>
    </>
  );
}

function FileUpload(props) {
  if (props.disabled) {
    return (
      <TooltipIfErrors errors={[MULTI_METHOD_TOOLTIP]} location="top">
        <FileUploadComponent {...props} />
      </TooltipIfErrors>
    );
  } else {
    return <FileUploadComponent {...props} />;
  }
}

const styles = StyleSheet.create({
  card: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    color: '#212529',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    ':focus': {
      borderColor: '#2196f3',
    },
  },
  dropzone: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    visibility: 'hidden',
    opacity: '0',
    zIndex: '1000',
    transition: 'visibility 175ms, opacity 175ms',
    display: 'none',
    textShadow: '1px 1px 2px #000',
    color: '#fff',
    background: 'rgba(39, 52, 105, 0.95)',
    fontSize: '24px',
    fontWeight: 'bold',
    border: '3vw solid rgba(0,0,0,.3)',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
  },
  uploadIconDrag: {
    fontSize: '2.5rem',
    marginBottom: '10px',
    color: 'white',
  },
  textnode: {
    textAlign: 'center',
    verticalAlign: 'middle',
    transition: 'font-size 175ms',
  },
  dragging: {
    opacity: 0.6,
  },
  centerText: {
    textAlign: 'center',
  },
  uploadIcon: {
    display: 'flex',
    alignContent: 'flex-start',
    fontSize: '2.5rem',
    color: '#a5a5a5',
  },
  uploadName: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
});

FileUploadComponent.propTypes = {
  disabled: PropTypes.bool,
  filesToUpload: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number,
  openStartUploadModal: PropTypes.func,
  rejectedFiles: PropTypes.arrayOf(PropTypes.object),
  removeFromUploadList: PropTypes.func,
  setRejectedFiles: PropTypes.func,
  supportedFormats: PropTypes.string,
};

FileUpload.propTypes = {
  disabled: PropTypes.bool,
};

export default FileUpload;
