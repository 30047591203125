import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { threeplayApi } from '~/logic/ThreeplayApi';
import { addAlignmentMutation } from '~/components/app/media_files/ShowPanel/data/mutations';

function SubmissionConfirmation() {
  return (
    <React.Fragment>
      <b>Please check the following before submitting your file for alignment: </b>
      <p>The only formatting in your transcript should be:</p>
      <p>Speaker IDs eg. BILL JOHNSON:</p>
      <p>Paragraphs - all hard line breaks are interpreted as paragraphs</p>
      <p>
        All text should correspond with the words spoken in the video/audio file. The Alignment
        Service is an automated service. Your file will not be seen by editors.
      </p>
      <p>
        The transcript is in the same language as the video/audio. The Alignment Service is not a
        translation service. Your file will not be seen by translators.
      </p>
      <p>
        <b>Are you sure you want to submit this file for alignment?</b>
      </p>
    </React.Fragment>
  );
}

function AlignmentSubmitModal(props) {
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState(false);
  const [showSuccessAlert, setSuccessAlert] = useState(false);

  function handleSubmit(props) {
    if (props.contentErrors.length < 1) {
      sendAddAlignmentMutation();
    }
  }

  async function sendAddAlignmentMutation() {
    setUpdating(true);
    const response = await threeplayApi.request(addAlignmentMutation, {
      id: props.fileId,
      alignmentText: props.alignmentText,
    });
    if (response.data && response.data.addAlignment) {
      if (response.data.addAlignment.error) {
        setErrors([response.data.addAlignment.error]);
        setUpdating(false);
        return;
      }
      setUpdating(false);
      setSuccessAlert(true);
      setTimeout(() => {
        handleClose();
        setSuccessAlert(false);
      }, 3000);
    } else {
      setErrors([response.errors]);
      setUpdating(false);
      return;
    }
  }

  function handleClose() {
    props.setShowAlignmentModal(false);
  }

  function successAlert() {
    return <Alert variant="success">You successfully added text for alignment!</Alert>;
  }

  function errorAlert() {
    return <Alert variant="error">There was an error submitting this text for alignment.</Alert>;
  }

  function sendingAlignment() {
    return <Alert variant="info">Sending file for alignment. Please wait....</Alert>;
  }

  function SubmissionErrors() {
    return (
      <React.Fragment>
        <p>The following illegal symbol(s) were found in your transcript:</p>
        {props.contentErrors.map((error, index) => (
          <>
            <b key={index}>{error}</b>
            <br />
          </>
        ))}
        <p>Please correct these before submitting this file for alignment!</p>
      </React.Fragment>
    );
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Submit file for Alignment</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showSuccessAlert && successAlert()}
        {errors && errorAlert()}
        {updating && sendingAlignment()}
        {props.contentErrors.length > 0 ? <SubmissionErrors /> : <SubmissionConfirmation />}
      </Modal.Body>

      <Modal.Footer>
        <Button
          className={css(styles.submitButton)}
          variant="primary"
          type="submit"
          onClick={() => handleSubmit(props)}
        >
          {props.contentErrors.length > 0 ? 'Okay' : 'Submit'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AlignmentSubmitModal.propTypes = {
  fileId: PropTypes.string,
  show: PropTypes.bool,
  setShowAlignmentModal: PropTypes.func,
  contentErrors: PropTypes.arrayOf(PropTypes.string),
  alignmentText: PropTypes.string,
};

const styles = StyleSheet.create({
  submitButton: {
    float: 'right',
  },
});

export default AlignmentSubmitModal;
