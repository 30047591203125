import React from 'react';
import PropTypes from 'prop-types';

import AIDubbingServiceDetails from '~/components/app/order_form/serviceDetails/AIDubbingServiceDetails';
import HumanDubbingServiceDetails from '~/components/app/order_form/serviceDetails/HumanDubbingServiceDetails';

const DubbingServiceDetails = ({ dubbingOrderOptions, embedding }) => {
  return dubbingOrderOptions[0].dubbingType.toLowerCase() === 'human' ? (
    <HumanDubbingServiceDetails dubbingOrderOptions={dubbingOrderOptions} embedding={embedding} />
  ) : (
    <AIDubbingServiceDetails dubbingOrderOptions={dubbingOrderOptions} />
  );
};

DubbingServiceDetails.propTypes = {
  dubbingOrderOptions: PropTypes.arrayOf(PropTypes.object),
  embedding: PropTypes.boolean,
};

export default DubbingServiceDetails;
