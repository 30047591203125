import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import RequestPaybumpModal from './RequestPaybumpModal';

export const PAYBUMP_REJECTED_LABEL = 'Paybump Rejected';
export const PAYBUMP_ACCEPTED_LABEL = 'Paybump Accepted';
export const PAYBUMP_PENDING_LABEL = 'Paybump Requested';
export const REQUEST_PAYBUMP_LABEL = 'Request a Paybump';

const RequestPaybump = ({ jobId, jobPaybumpRequest }) => {
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [paybumpStatus, setPaybumpStatus] = useState(getPaybumpStatus(jobPaybumpRequest));

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    success && setPaybumpStatus(getPaybumpStatus({ state: 'pending' }));
  };

  const handleSuccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <Button
        variant="outline-secondary"
        onClick={handleOpenModal}
        disabled={paybumpStatus.disabled}
      >
        {paybumpStatus.message}
      </Button>
      <RequestPaybumpModal
        jobId={jobId}
        show={showModal}
        onClose={handleCloseModal}
        onSuccess={handleSuccess}
      />
    </>
  );
};

RequestPaybump.propTypes = {
  jobId: PropTypes.number,
  jobPaybumpRequest: PropTypes.object,
};

const getPaybumpStatus = (requestedPayBump) => {
  if (!requestedPayBump) {
    return { disabled: false, message: REQUEST_PAYBUMP_LABEL };
  }
  if (requestedPayBump.state === 'accepted') {
    return { disabled: true, message: PAYBUMP_ACCEPTED_LABEL };
  }
  if (requestedPayBump.state === 'rejected') {
    return { disabled: true, message: PAYBUMP_REJECTED_LABEL };
  }
  return { disabled: true, message: PAYBUMP_PENDING_LABEL };
};

export default RequestPaybump;
