/* eslint-disable react/no-danger */

import React, { useState, useCallback, CSSProperties } from 'react';
import { find, findLast, clamp } from 'lodash';

import { Input } from '@threeplayground/index';

import css from './PlayerPreview.module.css';
import { JavaScriptPlayerSnippet } from './JavaScriptPlayerSnippet';

interface PlayerPreview {
  previewSnippet: string | HTMLElement;
  previewKey: string;
}

const SCALE_STEPS = [25, 50, 75, 100] as const;
const FIRST_STEP = SCALE_STEPS[0] as number;
const LAST_STEP = SCALE_STEPS[SCALE_STEPS.length - 1] as number;

export function PlayerPreview({ previewKey, previewSnippet }: PlayerPreview) {
  const [scale, setScale] = useState(100);

  const setAdjustedScale = useCallback(
    (newValue: string) => {
      const cleanedNumber = parseInt(newValue.trim().replace(/%/gi, ''));

      if (isNaN(cleanedNumber)) {
        setScale(100);
      }

      setScale(clamp(cleanedNumber, 25, 100));
    },
    [setScale]
  );

  const setStep = (direction: '-' | '+') => {
    if (direction === '-') {
      setScale(findLast(SCALE_STEPS, (step) => step < scale) ?? FIRST_STEP);
    } else {
      setScale(find(SCALE_STEPS, (step) => step > scale) ?? LAST_STEP);
    }
  };

  return (
    <>
      <h2 className={css.previewHeader}>Player Preview</h2>

      <div className={css.playerCanvas} style={{ '--scale': `${scale}%` } as CSSProperties}>
        {typeof previewSnippet === 'string' ? (
          <div className={css.player} dangerouslySetInnerHTML={{ __html: previewSnippet }} />
        ) : (
          // This `key` is very important! It forces react to unmount and
          // remount the player snippet to get the remount logic of setting up
          // the player.
          <div className={css.player}>
            <JavaScriptPlayerSnippet previewSnippet={previewSnippet} key={previewKey} />
          </div>
        )}
      </div>
      <div className={css.canvasScale}>
        <Input
          size="small"
          label="Canvas Scale"
          withTypingState
          hideLabel
          value={`${scale}%`}
          onUpdate={(newValue) => setAdjustedScale(newValue || '')}
          prepend={({ Button }) => (
            <Button disabled={scale <= FIRST_STEP} onClick={() => setStep('-')}>
              -
            </Button>
          )}
          append={({ Button }) => (
            <Button disabled={scale >= LAST_STEP} onClick={() => setStep('+')}>
              +
            </Button>
          )}
        />
      </div>
    </>
  );
}
