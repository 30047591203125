import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';
import { OffPlatformJobDetails, JobDetailsRow } from '../../common/OffPlatformJobDetails';
import { JobLoadError } from '../../common/JobLoadError';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';
import { FileUploader } from '../../common/file_uploads/FileUploader';
import { JobInstructions } from '../../common/JobInstructions';
import { DeliveryInstructions } from '../../common/DeliveryInstructions';
import { DownloadableAsset } from '../../common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';
import { formatTranscriptForDownload } from '../../common/downloadable_asset_cell/helpers';
import { offPlatformVideoEmbeddingJobsPaths } from '../../off_platform_video_embedding_jobs/offPlatformVideoEmbeddingJobsPaths';

type ParamType = {
  id: string;
};

export const OffPlatformCaptionEmbeddingJob = () => {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, isError } = useJobQuery({ where: { idEq: id } });

  if (isError) return <JobLoadError />;
  if (isLoading) return null;

  const job = data.job;

  if (!job || job.__typename !== 'OffPlatformCaptionEmbeddingJobGraphql') return <JobLoadError />;

  const { orderInstructions, mediaFile, deliveryInstructions, service } = job;

  if (!service || service.__typename !== 'CaptionEmbeddingServiceGraphql') return <JobLoadError />;

  const { videoAsset, captionEmbed } = service;
  const { allowedFileFormats, files } = videoAsset;

  const getAssets = (): DownloadableAsset[] => {
    const generateUrl = () =>
      offPlatformVideoEmbeddingJobsPaths.downloadInputTranscript.getLink(job.id);

    const sourceScripts = formatTranscriptForDownload({
      transcript: service.inputTranscript,
      generateUrl: generateUrl,
    });

    return [
      {
        displayName: 'Source Video',
        url: `/off_platform_caption_embedding_jobs/${id}/download_source_video`,
      },
      ...sourceScripts,
    ];
  };

  const customJobDetails: JobDetailsRow[] = [
    {
      header: 'Order Option',
      value: captionEmbed.orderOption.displayName,
    },
  ];

  const showOrderInstructions = orderInstructions && orderInstructions.length > 0;

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Caption Embedding Job'}>
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails
            job={job}
            downloadableAssets={getAssets()}
            customJobDetails={customJobDetails}
          />
          <UploadedFileList files={files} />
          <FileUploader
            assetId={videoAsset.id}
            assetType={AssetClassNameEnum.VideoAsset}
            allowedFileFormats={allowedFileFormats}
            uploadedFiles={files}
          />
        </Col>
        <Col lg={6} className="mt-4">
          {showOrderInstructions && (
            <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
          )}

          <DeliveryInstructions
            cheatSheets={deliveryInstructions}
            shouldDeliver={mediaFile.proofToFinalFinalized}
          />
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
};
