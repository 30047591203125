import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  CreateTermAcceptanceMutationPayload,
  Mutation,
  MutationCreateTermAcceptanceArgs,
  Query,
} from '~/api/appApi';
import { ExtractedGraphQLError } from '~/logic/unstable/ThreeplayApiV2';
import { ThreeplayAPIContext } from '~/logic/unstable/ThreeplayApiProvider';

import { TermsAcceptanceDisplay } from './TermsAcceptanceDisplay';
import { LOOKUP_QUERY, SUBMISSION_MUTATION } from './termsAcceptanceOperations';

const USE_QUERY_DEFAULTS = {
  cacheTime: 0,
  staleTime: 0,
  refetchOnWindowFocus: false,
};

export interface TermsAcceptanceProps {
  versionType: string;
  userType: string;
  termName: string;
  onLoadError: () => void;
  onSubmissionError: () => void;
  onSubmissionSuccess: () => void;
}

/* 
  This component requires being nested within a ThreeplayAPIProvider.
*/
export const TermsAcceptance = ({
  versionType,
  userType,
  termName,
  onLoadError,
  onSubmissionError,
  onSubmissionSuccess,
}: TermsAcceptanceProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const client = useContext(ThreeplayAPIContext);

  // Fetch data about the currently active term version.
  const variables = { where: { userTypeEq: userType, versionTypeEq: versionType } };
  const { data, isLoading } = useQuery(
    [LOOKUP_QUERY, variables],
    () => client.request<Pick<Query, 'termVersion'>>(LOOKUP_QUERY, variables, 'termVersion'),
    USE_QUERY_DEFAULTS
  );

  // Set up the mutation to submit terms acceptance. The strange syntax is using the
  // Auto generated types in appApi.ts to fill in a lot for us.
  const { mutateAsync } = useMutation<
    | CreateTermAcceptanceMutationPayload
    | Pick<Mutation, 'createTermAcceptance'>
    | { globalErrors: ExtractedGraphQLError[] }
    | null,
    unknown,
    MutationCreateTermAcceptanceArgs
  >(
    (data) =>
      client.request<Pick<Mutation, 'createTermAcceptance'>>(
        SUBMISSION_MUTATION,
        data,
        'createTermAcceptance'
      ),
    {}
  );

  const handleSubmit = async (termVersionId: number) => {
    setIsSubmitting(true);
    const response = await mutateAsync({ data: { termVersionId } });
    setIsSubmitting(false);

    // Handle missing response
    if (!response) {
      onSubmissionError();
      return;
    }

    // If there are any errors, call the onSubmissionError function
    if ('globalErrors' in response) {
      onSubmissionError();
      return;
    }

    if ('createTermAcceptance' in response) {
      /* Based on createTermAcceptance key we extract from the useQuery above, we should never end up here.
       TS believes this could be a key, so this is here to appease TS. */
      onSubmissionError();
      return;
    }

    const { data: responseData, errors } = response;

    if (errors) {
      onSubmissionError();
      return;
    }

    if (responseData) {
      onSubmissionSuccess();
      return;
    }
  };

  if (isLoading) {
    return null;
  }
  if (!data) {
    onLoadError();
    return null;
  }
  if ('globalErrors' in data) {
    onLoadError();
    return null;
  }
  if ('termVersion' in data) {
    /* Based on termVersion key we extract from the useQuery above, we should never end up here.
       TS believes this could be a key, so this is here to appease TS. */
    onLoadError();
    return null;
  }

  return (
    <TermsAcceptanceDisplay
      busy={isSubmitting}
      termName={termName}
      activeAt={new Date(data.activeAt).toLocaleDateString()}
      url={data.url}
      handleSubmit={() => void handleSubmit(data.id)}
    />
  );
};
