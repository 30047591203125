import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { StyleSheet, css } from 'aphrodite';

import Card from 'react-bootstrap/Card';

import { ftpPath } from '~/helpers/app/paths';

import useLinkedAccounts from '~/hooks/useLinkedAccounts';

import { selectedServicesType } from '~/components/app/order_form/propTypes';
import BatchSelection from '~/components/app/order_form/BatchSelection';
import TemplateCreation from '~/components/app/order_form/TemplateCreation';
import FileUploadTable from '~/components/app/order_form/uploads/FileUploadTable';
import LinkedAccountFileUpload from '~/components/app/order_form/uploads/linked_accounts/FileUpload';
import LinkFileUpload from '~/components/app/order_form/uploads/links/FileUpload';
import LocalFileUpload from '~/components/app/order_form/uploads/local/FileUpload';
import SampleFileUpload from '~/components/app/order_form/uploads/sample/FileUpload';

import WithIcon from '~/components/app/common/WithIcon';
import { ProjectContext } from '../OrderForm';

import {
  ADD_FILES_INDEX,
  FTP_UPLOAD_INDEX,
  SERVICES_REQUIRING_VIDEO_SOURCE,
  SERVICES_SUPPORTING_WORDLIST,
} from '~/helpers/constants';
import { userLogger } from '~/logic/UserLogger';

function UploadFiles(props) {
  const { accountType, features, fileFormats } = useContext(ProjectContext);
  const servicesRequiringVideoSource = props.selectedServices.find((service) =>
    SERVICES_REQUIRING_VIDEO_SOURCE.includes(service.serviceType)
  );
  const serviceSupportingWordlistSelected = !!props.selectedServices.find((service) =>
    SERVICES_SUPPORTING_WORDLIST.includes(service.serviceType)
  );
  const fileUploadTable = fileUploadTableParams();

  const { linkedAccounts, errors, loading } = useLinkedAccounts();

  useEffect(() => {
    userLogger.logEvent('NewOrder', 'Upload Files Page', {});
  }, []);

  function fileUploadTableParams() {
    switch (getUploadedFileType()) {
      case 'LinkedAccounts':
        return { showFileDuration: true, showFileSize: false, showFileUploader: false };
      case 'Links':
        return { showFileDuration: false, showFileSize: false, showFileUploader: false };
      case 'LocalFile':
        return { showFileDuration: true, showFileSize: true, showFileUploader: true };
      default:
        return {};
    }
  }

  function getUploadedFileType() {
    if (props.filesToUpload.length === 0) {
      return null;
    } else if (props.filesToUpload[0].hasOwnProperty('sampleFile')) {
      return 'SampleFile';
    } else if (props.filesToUpload[0].hasOwnProperty('sourceLinkedAccountVideoFile')) {
      return 'LinkedAccounts';
    } else if (props.filesToUpload[0].hasOwnProperty('sourceUrl')) {
      return 'Links';
    } else if (props.filesToUpload[0].hasOwnProperty('sourceS3File')) {
      return 'LocalFile';
    }
  }

  const disabledUploaders = {
    linkedAccounts: props.filesToUpload.length > 0 && getUploadedFileType() !== 'LinkedAccounts',
    links: props.filesToUpload.length > 0 && getUploadedFileType() !== 'Links',
    localFile: props.filesToUpload.length > 0 && getUploadedFileType() !== 'LocalFile',
    sampleFile: props.filesToUpload.length > 0 && getUploadedFileType() !== 'SampleFile',
  };

  function disabledLinkedAccount(linkedAccount) {
    if (features.all_vpis) {
      return false;
    }
    if (
      linkedAccount.platformDetails.name === 'YouTube' ||
      linkedAccount.platformDetails.name === 'Vimeo'
    ) {
      return false;
    }
    return true;
  }

  function fileTypeInfoMessage() {
    if (servicesRequiringVideoSource) {
      const videoOnlySelectedServices = props.selectedServices
        .filter((service) => SERVICES_REQUIRING_VIDEO_SOURCE.includes(service.serviceType))
        .map((service) => service.displayName);
      let note = 'Based on your selected services, please select files containing a video track. ';
      note += videoOnlySelectedServices.join(' and ');
      note += ' will be rejected for uploaded files that are audio-only.';
      return note;
    } else {
      return 'Based on your selected services, please select only audio or video files.';
    }
  }

  return (
    <>
      <Row>
        <Col>
          <span className={css(styles.folderOrTemplateForm)}>
            <BatchSelection
              batchName={props.batchName}
              batchId={props.batchId}
              setBatchName={props.setBatchName}
              setBatchId={props.setBatchId}
              setBatchError={props.setBatchError}
              serviceSupportingWordlistSelected={serviceSupportingWordlistSelected}
              wordlistAssignment={props.wordlistAssignment}
            />
          </span>
        </Col>
      </Row>
      {props.showTemplateForm && (
        <Row>
          <Col>
            <span className={css(styles.folderOrTemplateForm)}>
              <TemplateCreation
                className={css(styles.folderOrTemplateForm)}
                saveTemplate={props.saveTemplate}
                setTemplateName={props.setTemplateName}
                templateName={props.templateName}
                templateSaved={props.templateSaved}
                templateErrors={props.templateErrors}
              />
            </span>
          </Col>
        </Row>
      )}
      <hr />
      <Card.Title className={css(styles.stepTitle)}>Add Media Files</Card.Title>
      <Card.Text>{fileTypeInfoMessage()}</Card.Text>
      <div className={css(styles.flex)}>
        {!!features.orderFormSampleFile && (
          <Card
            bg="light"
            className={css(styles.uploadCard, disabledUploaders.sampleFile && styles.disabled)}
          >
            <SampleFileUpload
              index={ADD_FILES_INDEX - 1}
              disabled={disabledUploaders.sampleFile}
              openSampleFileModal={props.openSampleFileModal}
            />
          </Card>
        )}

        <Card
          bg="light"
          className={css(styles.uploadCard, disabledUploaders.localFile && styles.disabled)}
        >
          <LocalFileUpload
            index={ADD_FILES_INDEX}
            disabled={disabledUploaders.localFile}
            openStartUploadModal={props.openStartUploadModal}
            rejectedFiles={props.rejectedFiles}
            setRejectedFiles={props.setRejectedFiles}
            supportedFormats={
              servicesRequiringVideoSource
                ? fileFormats.videoFormat
                : fileFormats.supportedFormat
            }
          />
        </Card>

        <Card
          bg="light"
          className={css(styles.uploadCard, disabledUploaders.links && styles.disabled)}
        >
          <LinkFileUpload
            index={ADD_FILES_INDEX + 1}
            disabled={disabledUploaders.links}
            filesToUpload={props.filesToUpload}
            openLinksModal={props.openLinksModal}
          />
        </Card>

        {linkedAccounts
          ?.filter((linkedAccount) => linkedAccount.platformDetails.supportsVideoListing)
          ?.map((linkedAccount, index) => {
            return (
              <Card
                bg="light"
                key={linkedAccount.platformDetails.id}
                className={css(
                  styles.uploadCard,
                  disabledUploaders.linkedAccounts && styles.disabled,
                  disabledLinkedAccount(linkedAccount) && styles.disabled
                )}
              >
                <LinkedAccountFileUpload
                  index={index + 2 + ADD_FILES_INDEX}
                  disabled={
                    disabledUploaders.linkedAccounts || disabledLinkedAccount(linkedAccount)
                  }
                  filesToUpload={props.filesToUpload}
                  openLinkedAccountModal={props.openLinkedAccountModal}
                  linkedAccount={linkedAccount}
                />
              </Card>
            );
          })}
      </div>
      {!accountType.express && (
        <a
          className="font-weight-bold text-dark"
          tabIndex={FTP_UPLOAD_INDEX}
          href={ftpPath}
          target="_blank"
          rel="noopener noreferrer"
        >
          <WithIcon icon="fa fa-info-circle">Looking to upload videos through FTP?</WithIcon>
        </a>
      )}
      <FileUploadTable
        batchId={props.batchId}
        filesToUpload={props.filesToUpload}
        rejectedFiles={props.rejectedFiles}
        removeFromUploadList={props.removeFromUploadList}
        selectedServices={props.selectedServices}
        setAlignmentTexts={props.setAlignmentTexts}
        setRejectedFiles={props.setRejectedFiles}
        setFilesToUpload={props.setFilesToUpload}
        sizeLimit={'2 GB'}
        showProofToFinal={props.showProofToFinal}
        uploading={props.uploading}
        uploadProgress={props.uploadProgress}
        serviceSupportingWordlistSelected={serviceSupportingWordlistSelected}
        showFileDuration={fileUploadTable.showFileDuration}
        showFileSize={fileUploadTable.showFileSize}
        showUploader={fileUploadTable.showFileUploader}
        validFileUploads={props.validFileUploads}
        wordlistAssignment={props.wordlistAssignment}
      />
    </>
  );
}

const styles = StyleSheet.create({
  stepTitle: {
    'font-weight': 'bold',
    'margin-bottom': '.25rem',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  flex: {
    display: 'flex',
    'flex-wrap': 'wrap',
    'flex-direction': 'row',
  },
  uploadCard: {
    'box-shadow': '2px 2px 4px rgba(0, 0, 0, 0.1)',
    border: '1px solid #DEDEDE',
    background: '#F3F3F3',
    'border-radius': '5px',
    'margin-bottom': '10px',
    'margin-right': '10px',
    width: 'calc(25% - 10px)',
    minWidth: '14rem',
    maxWidth: '15rem',
  },
  folderOrTemplateForm: {
    width: 'calc(50% - 10px)',
  },
});

UploadFiles.propTypes = {
  batchName: PropTypes.string,
  batchId: PropTypes.string,
  filesToUpload: PropTypes.arrayOf(PropTypes.object),
  linkedAccounts: PropTypes.arrayOf(PropTypes.object),
  openLinkedAccountModal: PropTypes.func,
  openLinksModal: PropTypes.func,
  openSampleFileModal: PropTypes.func,
  openStartUploadModal: PropTypes.func,
  rejectedFiles: PropTypes.arrayOf(PropTypes.object),
  removeFromUploadList: PropTypes.func,
  saveTemplate: PropTypes.func,
  selectedServices: selectedServicesType,
  setBatchName: PropTypes.func,
  setBatchId: PropTypes.func,
  setBatchError: PropTypes.func,
  setFilesToUpload: PropTypes.func,
  setRejectedFiles: PropTypes.func,
  setTemplateName: PropTypes.func,
  showProofToFinal: PropTypes.bool,
  showTemplateForm: PropTypes.bool,
  templateErrors: PropTypes.arrayOf(PropTypes.string),
  templateName: PropTypes.string,
  templateSaved: PropTypes.bool,
  uploading: PropTypes.bool,
  uploadProgress: PropTypes.object,
  validFileUploads: PropTypes.object,
  wordlistAssignment: PropTypes.shape({
    resourceType: PropTypes.string,
    wordlist: PropTypes.shape({
      name: PropTypes.string,
      words: PropTypes.string,
    }),
  }),
};

export default UploadFiles;
