import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { StyleSheet, css } from 'aphrodite';
import { MANAGE_PLAN_CONFIRMATION_START_TAB_INDEX } from '~/helpers/constants';

const ConfirmationTextRow = (props) => (
  <Row className={css(styles.confirmationTextRow)}> {props.children} </Row>
);
ConfirmationTextRow.propTypes = {
  children: PropTypes.node,
};

function Confirmation(props) {
  function generateCheckLabelHtml(text) {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  }

  function handleCheckChanged(e) {
    const id = e.target.id;
    const val = e.target.checked;

    props.setCheckedTerms(() => ({
      ...props.checkedTerms,
      [id]: val,
    }));
  }

  return (
    <Container fluid>
      <Col>
        <Row className="h4 font-weight-bold">Confirm Plan Details</Row>
        <ConfirmationTextRow>
          Total due now is{' '}
          <b>
            &nbsp;
            {props.calculatePrice({
              monthly: props.selectedFrequency === 'monthly',
              renewal: false,
            })}{' '}
          </b>
        </ConfirmationTextRow>
        <ConfirmationTextRow>
          Your account will be on the
          <span className="capitalize">&nbsp;{props.selectedPlan}&nbsp;</span>Plan.
        </ConfirmationTextRow>
        <ConfirmationTextRow>
          Pro plans require upfront payment of entire Prepay Commitment.
        </ConfirmationTextRow>
        {props.autoRenew && (
          <>
            <ConfirmationTextRow>
              If you have selected automatic renewal, at the end of your term you will be
              automatically renewed for the same plan.
            </ConfirmationTextRow>
            <ConfirmationTextRow>
              The renewal fee for your first renewal is
              <b>
                &nbsp;
                {props.calculatePrice({
                  monthly: props.selectedFrequency === 'monthly',
                  renewal: true,
                })}
              </b>
              .
            </ConfirmationTextRow>
          </>
        )}
        <Row>
          <Form>
            <Form.Group>
              <Form.Check
                type="checkbox"
                id="commitment"
                onChange={handleCheckChanged}
                checked={props.checkedTerms.commitment}
                label={generateCheckLabelHtml(props.termsDocument.commitment)}
                role="checkbox"
                tabIndex={MANAGE_PLAN_CONFIRMATION_START_TAB_INDEX}
                className={css(styles.confirmationTextRow)}
              />
              <Form.Check
                type="checkbox"
                id="cancellation"
                onChange={handleCheckChanged}
                checked={props.checkedTerms.cancellation}
                label={generateCheckLabelHtml(props.termsDocument.cancellation)}
                role="checkbox"
                tabIndex={MANAGE_PLAN_CONFIRMATION_START_TAB_INDEX + 1}
                className={css(styles.confirmationTextRow)}
              />
              <Form.Check
                type="checkbox"
                id="terms"
                onChange={handleCheckChanged}
                checked={props.checkedTerms.terms}
                label={generateCheckLabelHtml(props.termsDocument.terms)}
                role="checkbox"
                tabIndex={MANAGE_PLAN_CONFIRMATION_START_TAB_INDEX + 2}
                className={css(styles.confirmationTextRow)}
              />
            </Form.Group>
          </Form>
        </Row>
      </Col>
    </Container>
  );
}

const styles = StyleSheet.create({
  confirmationTextRow: {
    color: '#686868',
    marginTop: '7px',
  },
});

Confirmation.propTypes = {
  autoRenew: PropTypes.bool,
  calculatePrice: PropTypes.func,
  checkedTerms: PropTypes.object,
  selectedPlan: PropTypes.string,
  selectedFrequency: PropTypes.string,
  setCheckedTerms: PropTypes.func,
  termsDocument: PropTypes.object,
};

export default Confirmation;
