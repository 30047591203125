import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import { Col, Form, Row } from 'react-bootstrap';
import InputAssetSelector from '~/components/app/media_files/supplemental_assets/InputAssetSelector';

export const supplementalAssetInitialState = {
  acceptedFiles: [],
  declined: false,
  fileName: null,
  rejectedFiles: [],
};

const SupplementalAssets = ({ assetData, canDecline, isInternalUser, isManagedAccount, setAssetData }) => {
  const [mode, setMode] = useState('files');

  const changeMode = (mode) => {
    setAssetData({ declined: mode === 'decline', acceptedFiles: [], fileName: null, rejectedFiles: [] });
    setMode(mode);
  };

  const setFileName = (filename) => {
    setAssetData((existing) => ({...existing, fileName: filename}));
  }

  const showFileNameField = () => {
    return mode === 'filename';
  };

  const showFileUpload = () => {
    return mode === 'files';
  };

  const showZipUpload = () => {
    return mode === 'zip';
  };

  const hasMultipleOptions = () => {
    return isInternalUser || canDecline;
  };

  return (
    <>
      {hasMultipleOptions() && (
        <div>
          <div>I want to:</div>
          <Row>
            <Col xs={12}>
              <Form.Check
                inline
                id="asset-upload"
                type="radio"
                name="upload"
                label="Upload individual assets"
                checked={mode === 'files'}
                onChange={() => changeMode('files')}
              />
              {isInternalUser && (
                <Form.Check
                  inline
                  id="zip-upload"
                  type="radio"
                  name="upload"
                  label="Upload a zip file"
                  checked={mode === 'zip'}
                  onChange={() => changeMode('zip')}
                />
              )}
              {canDecline && (
                <Form.Check
                  inline
                  id="decline"
                  type="radio"
                  name="upload"
                  label="Decline to upload"
                  checked={mode === 'decline'}
                  onChange={() => changeMode('decline')}
                />
              )}
              {isManagedAccount && (
                <Form.Check
                  inline
                  id="filename"
                  type="radio"
                  name="upload"
                  label="Provide a filename"
                  checked={mode === 'filename'}
                  onChange={() => changeMode('filename')}
                />
              )}
            </Col>
          </Row>
        </div>
      )}
      {showFileUpload() && (
        <InputAssetSelector
          key="files"
          acceptedFileTypes="audio/*,video/*"
          allowMultiple={true}
          disabled={false}
          instructions="Please upload the required supplemental audio assets"
          acceptedFiles={assetData.acceptedFiles}
          rejectedFiles={assetData.rejectedFiles}
          setAssetData={setAssetData}
        />
      )}
      {showZipUpload() && (
        <InputAssetSelector
          key="zip"
          acceptedFileTypes=".zip"
          allowMultiple={false}
          disabled={false}
          instructions="Please upload a single zip file containing supplemental audio assets"
          acceptedFiles={assetData.acceptedFiles}
          rejectedFiles={assetData.rejectedFiles}
          setAssetData={setAssetData}
        />
      )}
      {showFileNameField() && (
        <>
          <Form.Control
            className={css(styles.filenameField)}
            onChange={(e) => setFileName(e.target.value)}
            placeholder="Please enter a file name"
          />
        </>
      )}
    </>
  );
};

SupplementalAssets.propTypes = {
  assetData: PropTypes.shape({
    acceptedFiles: PropTypes.array,
    declined: PropTypes.bool,
    rejectedFiles: PropTypes.array,
  }).isRequired,
  canDecline: PropTypes.bool.isRequired,
  isInternalUser: PropTypes.bool.isRequired,
  isManagedAccount: PropTypes.bool.isRequired,
  setAssetData: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  filenameField: {
    margin: "0.5rem 0 1rem",
  }
});

export default SupplementalAssets;
