import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Accordion, Button, Card } from 'react-bootstrap';

import { css } from 'aphrodite';

import { appStyles } from '~/components/app/common/styles';
import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';

const OrderOptionsContainer = ({ children, title, componentName }) => {
  const [open, setOpen] = useState(true);

  return (
    <ErrorBoundary component={componentName}>
      <div className={css(appStyles.sectionPadding)}>
        <Accordion defaultActiveKey="0">
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="0"
            tabIndex={1}
            onClick={() => setOpen((state) => !state)}
            className={css(appStyles.accordionTitle)}
          >
            <Card.Title className={css(appStyles.sectionTitle)}>
              {title}
              <span
                className={`fa fa-chevron-up ${css(appStyles.chevron)} ${
                  open ? '' : css(appStyles.inverted)
                }`}
              />
            </Card.Title>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>{children}</>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </ErrorBoundary>
  );
};

OrderOptionsContainer.propTypes = {
  children: PropTypes.node,
  componentName: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default OrderOptionsContainer;
