// This file contains common styles used throughout the account domain.
// To use them in a react component:
//   1. `import { css } from 'aphrodite'`
//   2. `import { appStyles } from '~/components/app/common/styles'`
//   use `className={css(appStyles.headerBar, ...otherStyles)}` in your component.

import { StyleSheet } from 'aphrodite';

export const appStyles = StyleSheet.create({
  cursorPointer: {
    cursor: 'pointer',
  },
  clearButton: {
    color: '#000',
    cursor: 'pointer',
    fontSize: '1.2em',
    fontStyle: 'normal',
    padding: '0 8px',
    position: 'absolute',
    right: '75px',
    textDecoration: 'none',
    top: '5px',
    userSelect: 'none',
  },
  dropdownLink: {
    display: 'block',
    padding: '2px 4px',
    fontSize: '14px',
  },
  headerBar: {
    width: '100%',
    padding: '6px 0px 10px',
    backgroundColor: '#f3f3f3',
  },
  headerCollapse: {
    marginLeft: '13px',
    border: 'none',
    background: 'none',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  perPageSpan: {
    width: '210px',
  },
  pointerEventsNone: {
    pointerEvents: 'none',
  },
  sectionPadding: {
    padding: '2rem 0 0',
  },
  accordionTitle: {
    padding: 0,
    textDecoration: 'none !important',
    boxShadow: 'none !important',
    ':hover': {
      color: '#007eb5',
    },
  },
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    borderLeft: '3px solid #007eb5',
    paddingLeft: '8px',
  },
  title: {
    margin: '.5rem 0 .5rem 0',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  chevron: {
    transition: 'all 300ms ease-in-out',
    marginLeft: '1rem',
  },
  inverted: {
    transform: 'rotate(180deg)',
  },
});
