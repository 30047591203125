import ThreeplayApi from '../../../services/ThreeplayApi';
import { submitBasicInformationMutation, submitVoiceWritingApplicationMutation } from './mutations';
import { graphqlPath } from '../../../services/mutations';

const api = new ThreeplayApi(graphqlPath);

export const submitBasicInformation = ({
  email,
  firstname,
  lastname,
  country,
  region,
  jobTypeId,
  referrerParams,
}) =>
  api.json(submitBasicInformationMutation, {
    email,
    firstname,
    lastname,
    country,
    region,
    jobTypeId,
    referrerParams,
  });

export const submitVoiceWritingApplication = ({ authToken, experience, referralSource }) =>
  api.json(submitVoiceWritingApplicationMutation, { experience, referralSource, ...authToken });
