import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import CollapsiblePanel from '~/components/app/common/CollapsiblePanel';
import AlignmentSubmitModal from '~/components/app/media_files/modals/AlignmentSubmitModal';

function AddAlignment(props) {
  const [alignmentText, setAlignmentText] = useState('');
  const [showAlignmentModal, setShowAlignmentModal] = useState(false);
  const [contentErrors, setContentErrors] = useState([]);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    setPending(true);
    checkForErrors();
    setPending(false);
  }, [alignmentText]);

  function checkForErrors() {
    const blankError = checkForBlankTranscript();
    const illegalChars = checkForIllegalQuestionMarks();
    const errors = blankError.concat(illegalChars);
    setContentErrors(errors);
  }

  function checkForIllegalQuestionMarks() {
    let pos = alignmentText.indexOf('??');
    const matches = [];
    while (pos > -1) {
      matches.push('Character ' + pos + ': ??');
      pos = alignmentText.indexOf('??', pos + 1);
    }
    return matches;
  }

  function checkForBlankTranscript() {
    let error = [];
    if (alignmentText.length < 1) {
      error = error.concat(['Blank transcript']);
    }
    return error;
  }

  return (
    <div className={css(styles.main)}>
      <CollapsiblePanel title="Add Alignment Transcript">
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label className={css(styles.alignmentFormLabel)}>Alignment Text</Form.Label>
          <Form.Control
            draggable="false"
            as="textarea"
            rows="3"
            value={alignmentText}
            onChange={(e) => setAlignmentText(e.target.value)}
          />
        </Form.Group>
        <div className={css(styles.alignmentBar)}>
          <Button size="sm" variant="light" type="submit" onClick={() => setAlignmentText('')}>
            Clear
          </Button>
          <div className={css(styles.errors)}>{contentErrors.length} errors</div>
          <Button
            size="sm"
            className={css(styles.submitButton)}
            variant="primary"
            type="submit"
            disabled={pending}
            onClick={() => setShowAlignmentModal(true)}
          >
            Submit
          </Button>
        </div>
        <AlignmentSubmitModal
          show={showAlignmentModal}
          fileId={props.fileId}
          setShowAlignmentModal={setShowAlignmentModal}
          contentErrors={contentErrors}
          alignmentText={alignmentText}
        />
      </CollapsiblePanel>
    </div>
  );
}

AddAlignment.propTypes = {
  fileId: PropTypes.string,
};

const styles = StyleSheet.create({
  submitButton: {
    float: 'right',
  },
  errors: {
    display: 'inline-block',
  },
  alignmentFormLabel: {
    display: 'none',
  },
});

export default AddAlignment;
