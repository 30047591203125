import React from 'react';
import PropTypes from 'prop-types';

import MultiServiceTable from './MultiServiceTable';

import { titleize } from '~/helpers/strings';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';

const ABBREVIATIONS = {
  'As-broadcast Script (ABS)': 'ABS',
  'Abs Broadcast Scripting': 'ABS',
  'As-broadcast Script - Grid (ABS Grid)': 'ABS Grid',
  'Abs Grid Broadcast Scripting': 'ABS Grid',
  'Dialogue List (DL)': 'DL',
  'Dialogue Continuity Script (DCS)': 'DCS',
  'Combined Dialogue and Spotting List (CDSL)': 'CDSL',
  'Combined Continuity and Spotting List (CCSL)': 'CCSL',
};

function BroadcastScriptsServiceCard(props) {
  const headers = ['Format', 'Ordered', 'Status'];
  return (
    <MultiServiceTable headers={headers}>
      {props.services.map((service) => (
        <tr key={service.id}>
          <td>{ABBREVIATIONS[service.displayType]}</td>
          <td>
            {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
          </td>
          <td>{titleize(service.status)}</td>
        </tr>
      ))}
    </MultiServiceTable>
  );
}

BroadcastScriptsServiceCard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  userTimeZone: PropTypes.string,
};

export default BroadcastScriptsServiceCard;
