import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { Dropdown } from 'react-bootstrap';

import { transcriptSpec, transcriptListSpec } from './data/propspec';
import { threeplayApi } from '~/logic/ThreeplayApi';
import WithIcon from '~/components/app/common/WithIcon';

const favoriteFormatsQuery = `favoriteFormats {
  id, inputType, name, httpFormat
}`;

function NoFavorites() {
  return <Dropdown.Item href="/project/favorite_formats">+ Add favorite formats</Dropdown.Item>;
}

function RenderFavorites(props) {
  return (
    <React.Fragment>
      {props.favorites?.map((format) => (
        <Dropdown.Item
          eventKey={format.id}
          key={format.id}
          onClick={() => {
            props.setShowModal.quickLink(true, props.transcriptList, format);
          }}
        >
          {format.inputType === 'Description' ? format.name + '(Audio Description)' : format.name}
        </Dropdown.Item>
      ))}
      <Dropdown.Item href="/project/favorite_formats">
        <WithIcon icon="fa fa-pencil">Edit favorite formats</WithIcon>
      </Dropdown.Item>
    </React.Fragment>
  );
}

RenderFavorites.propTypes = {
  setShowModal: PropTypes.shape({
    quickLink: PropTypes.func,
  }),
  favorites: PropTypes.arrayOf(PropTypes.object),
  transcriptList: transcriptListSpec,
};

function QuickLinkButton(props) {
  const [favorites, setFavorites] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    let cancelled = false;
    async function fetchFormats() {
      setFetching(true);
      setFavorites([]);
      const { data, errors } = await threeplayApi.query(favoriteFormatsQuery, 'FavoriteFormats');
      if (errors) {
        setFetching(false);
        return;
      } else if (cancelled) {
        return;
      }
      setFavorites(data.favoriteFormats);
      setFetching(false);
    }

    fetchFormats();
    return () => {
      cancelled = true;
    };
  }, [props.fileId]);

  return (
    <Dropdown className={css(styles.quick_link_dropdown)}>
      <Dropdown.Toggle
        variant="outline-secondary"
        size="sm"
        aria-label="Quick Link"
        className={css(styles.quick_link_toggle)}
      >
        <i className="fa fa-link" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {fetching ? (
          <Dropdown.Item disabled>Loading...</Dropdown.Item>
        ) : favorites.length > 0 ? (
          <RenderFavorites
            favorites={favorites}
            setShowModal={props.setShowModal}
            transcriptList={props.transcriptList}
          />
        ) : (
          <NoFavorites />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const styles = StyleSheet.create({
  quick_link_dropdown: {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingLeft: '0.3em',
  },
  quick_link_toggle: {
    height: '31px',
    fontSize: 'initial',
    color: '#4D4D4D',
    ':hover': {
      color: '#FFFFFF',
    },
  },
});

QuickLinkButton.propTypes = {
  transcript: transcriptSpec,
  fileId: PropTypes.string,
  projectId: PropTypes.string,
  formatId: PropTypes.string,
  transcriptList: transcriptListSpec,
  setShowModal: PropTypes.shape({
    quickLink: PropTypes.fun,
  }),
};

export default QuickLinkButton;
