import React from 'react';
import { Table } from 'react-bootstrap';
import {
  DownloadableAssetCell,
  DownloadableAsset,
} from './downloadable_asset_cell/OffPlatformDownloadableAssetCell';

interface DataProps {
  job: JobInterface;
  downloadableAssets: DownloadableAsset[];
  customJobDetails?: JobDetailsRow[];
}

export interface JobDetailsRow {
  header: string;
  value: string | JSX.Element;
}

export interface JobInterface {
  deadline: string;
  account: AccountInterface;
  batch: {
    name: string;
  };
  project: {
    name: string;
  };
  mediaFile: MediaFileInterface;
}

interface AccountInterface {
  projectManager: UserInterface | null;
}

interface UserInterface {
  name: string;
  email: string;
}
interface MediaFileInterface {
  name: string;
  videoId: string | null;
  proofToFinalStatus: string;
}

const projectManagerDetails = (project_manager: UserInterface | null): string => {
  if (project_manager) {
    const { name, email } = project_manager;
    return `${name} (${email})`;
  }
  return 'N/A';
};

const jobDetails = (
  job: JobInterface,
  assets: DownloadableAsset[],
  customJobDetails: JobDetailsRow[] | undefined
): JobDetailsRow[] => {
  let headers: JobDetailsRow[] = [
    {
      header: 'Project',
      value: job.project.name,
    },
    {
      header: 'Batch/Folder',
      value: job.batch.name,
    },
    {
      header: 'Project Manager',
      value: projectManagerDetails(job.account.projectManager),
    },
    {
      header: 'File name',
      value: job.mediaFile.name,
    },
    { header: 'Video ID', value: job.mediaFile.videoId || 'N/A' },
    {
      header: 'Deadline',
      value: new Date(job.deadline).toLocaleString(),
    },
    {
      header: 'Assets',
      value: <DownloadableAssetCell assets={assets} />,
    },
    {
      header: 'Proof To Final State',
      value: job.mediaFile.proofToFinalStatus,
    },
  ];

  if (customJobDetails) {
    headers = headers.concat(customJobDetails);
  }

  return headers;
};

export const OffPlatformJobDetails = ({ job, downloadableAssets, customJobDetails }: DataProps) => (
  <>
    <h2>Job Details</h2>
    <Table>
      <tbody>
        {jobDetails(job, downloadableAssets, customJobDetails).map(({ header, value }) => (
          <tr key={header}>
            <th>{header}</th>
            <td style={{ wordBreak: 'break-all' }}>{value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </>
);
