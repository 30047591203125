import { PunctuationFormatter, PunctuationFormatterConfig } from '../_types';

export class PunctuationWithSpacesFormatter implements PunctuationFormatter {
  command: PunctuationFormatterConfig['command'];
  punctuation: PunctuationFormatterConfig['punctuation'];

  /**
   * A formatter that adds the punctuation and removes any leading or
   * trailing white spaces
   *
   * @example new PunctuationWithSpacesFormatter({ command: 'apostrophe', punctuation: "'" }).format("what apostrophe s up")
   * // returns "what's up"
   */
  constructor({ command, punctuation }: PunctuationFormatterConfig) {
    this.command = command;
    this.punctuation = punctuation;
  }

  format(text: string) {
    return text.replace(this.regex(), this.punctuation);
  }

  regex() {
    return new RegExp(`\\s*\\b${this.command}\\b\\s*`, 'gi');
  }
}
