const createAccountPlanMutation = `
  mutation CreateAccountPlan($plan: AccountPlanInput!){
    createAccountPlan(accountPlan: $plan){
      errors,
      success
    }
  }
`;

const updateAccountPlanMutation = `
  mutation UpdateAccountPlan($plan: AccountPlanInput!){
    updateAccountPlan(accountPlan: $plan){
      errors,
      success
    }
  }
`;

export { createAccountPlanMutation, updateAccountPlanMutation };
