import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function OutputFormatRow({ format, enabled, handleToggleFormat }) {
  return (
    <Row className={css(styles.formatRow)}>
      <Col xs={2}>{format.name}</Col>
      <Col xs={3}>{format.http_format}</Col>
      <Col xs={5}>{format.converter}</Col>
      <Col xs={2}>
        <Button
          className={enabled ? css(styles.enabledFormat) : css(styles.disabledFormat)}
          onClick={() => handleToggleFormat(enabled, format)}
        />
      </Col>
    </Row>
  );
}

const styles = StyleSheet.create({
  formatRow: {
    alignItems: 'center',
    paddingBottom: '3px',
  },
  enabledFormat: {
    width: '100px',
    color: '#fff',
    backgroundColor: '#28a745',
    borderColor: '#28a745',
    ':after': {
      content: '"Enabled"',
    },
    ':hover': {
      backgroundColor: '#dc3545',
      borderColor: '#dc3545',
      ':after': {
        content: '"Disable"',
      },
    },
  },
  disabledFormat: {
    width: '100px',
    color: '#fff',
    backgroundColor: '#dc3545',
    borderColor: '#dc3545',
    ':after': {
      content: '"Disabled"',
    },
    ':hover': {
      backgroundColor: '#28a745',
      borderColor: '#28a745',
      ':after': {
        content: '"Enable"',
      },
    },
  },
});

OutputFormatRow.propTypes = {
  format: PropTypes.shape({
    name: PropTypes.string,
    http_format: PropTypes.string,
    converter: PropTypes.string,
  }),
  enabled: PropTypes.bool,
  handleToggleFormat: PropTypes.func,
};

export default OutputFormatRow;
