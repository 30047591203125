import React, { useMemo, useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import ControlTextInput from './ControlTextInput';
import ControlLabel from './ControlLabel';
import HoursPercentageSlider from './HoursPercentageSlider';

import { marketManagementControlShape } from '~/components/ops/market/shapes';
import { useControlValue } from './useControlValue';

function pct(fraction) {
  return String(Math.round(fraction * 100));
}

function pctToFrac(pct) {
  return Number(pct) / 100;
}

function computeTotalDistribution(dist) {
  if (!dist) {
    return 0;
  }

  const total = dist.reduce((sum, next) => sum + next.target, 0);
  return Math.round(total * 100) / 100;
}

function ControlGroupSetting(props) {
  const hourTarget = useControlValue(props.controlsList, 'total_hour_target');
  const jobCountRange = useControlValue(props.controlsList, 'job_count_range', [0, null]);
  const projectCap = useControlValue(props.controlsList, 'project_cap');
  const deadlineDistribution = useControlValue(props.controlsList, 'deadline_distribution', []);
  const jobOrderRandomization = useControlValue(props.controlsList, 'job_order_randomization');
  const priorityProjects = useControlValue(props.controlsList, 'priority_projects', []);
  const contractorPreference = useControlValue(props.controlsList, 'contractor_preference');

  const anyModified =
    hourTarget.isModified ||
    jobCountRange.isModified ||
    projectCap.isModified ||
    deadlineDistribution.isModified ||
    jobOrderRandomization.isModified ||
    priorityProjects.isModified ||
    contractorPreference.isModified;
  const [errors, setErrors] = useState([]);
  const [confirmations, setConfirmations] = useState({});

  const totalDistribution = useMemo(
    () => computeTotalDistribution(deadlineDistribution.value),
    [deadlineDistribution.value]
  );
  const jobCountRangeValid =
    jobCountRange.value[1] === null || jobCountRange.value[1] >= jobCountRange.value[0];
  const distributionValid = Math.round(totalDistribution * 100) === 100;
  const allValuesValid = jobCountRangeValid && distributionValid;

  function save() {
    if (hourTarget.isModified) {
      submitControlValue('total_hour_target', hourTarget.value);
    }
    if (jobCountRange.isModified) {
      submitControlValue('job_count_range', jobCountRange.value);
    }
    if (projectCap.isModified) {
      submitControlValue('project_cap', projectCap.value);
    }
    if (deadlineDistribution.isModified) {
      submitControlValue('deadline_distribution', deadlineDistribution.value);
    }
    if (jobOrderRandomization.isModified) {
      submitControlValue('job_order_randomization', jobOrderRandomization.value);
    }
    if (priorityProjects.isModified) {
      submitControlValue('priority_projects', priorityProjects.value);
    }
    if (contractorPreference.isModified) {
      submitControlValue('contractor_preference', contractorPreference.value);
    }
  }

  function reset() {
    hourTarget.reset();
    jobCountRange.reset();
    projectCap.reset();
    deadlineDistribution.reset();
    jobOrderRandomization.reset();
    priorityProjects.reset();
    contractorPreference.reset();
  }

  // Update the control value at the server, then update the UI
  async function submitControlValue(type, value) {
    const response = await props.updateControlValue({
      controlType: type,
      controlValue: value,
    });
    const errors = response.errors
      ? response.errors.map((err) => err.message)
      : response.data.marketManagementControl.errors;
    confirmMutationResult({ type, errors });
  }

  // Delete the control override at the server, update the UI
  async function removeControlOverride(control) {
    const response = await props.removeControlOverride(control.id);
    const errors = response.errors
      ? response.errors.map((err) => err.message)
      : response.data.deleteMarketManagementControl.errors;
    confirmMutationResult({ type: control.controlType, errors });
  }

  // Standardized handling of a completed update or delete action for a control
  function confirmMutationResult({ type, errors }) {
    const confirmation = {
      displayKey: new Date().getTime(),
      success: !errors,
    };
    setConfirmations((confirmations) => ({ ...confirmations, [type]: confirmation }));
    if (errors) {
      setErrors(errors);
    }
  }

  function handleTotalHourTarget(event) {
    hourTarget.setValue(Number(event.target.value));
  }

  function handleJobCountMin(event) {
    const min = Number(event.target.value);
    const max = jobCountRange.value[1];
    jobCountRange.setValue([min, max]);
  }

  function handleJobCountMax(event) {
    const min = jobCountRange.value[0];
    const max = event.target.value === '' ? null : Number(event.target.value);
    jobCountRange.setValue([min, max]);
  }

  function handleProjectCap(event) {
    projectCap.setValue(pctToFrac(event.target.value));
  }

  function handleDeadlineDistribution(event, ii) {
    const newDistribution = proposeDistribuionChange(ii, pctToFrac(event.target.value));
    deadlineDistribution.setValue(newDistribution);
  }

  function handlePriorityProjects(event, ii) {
    const newPriorityProjects = proposePriorityProjectChange(ii, pctToFrac(event.target.value));
    priorityProjects.setValue(newPriorityProjects);
  }

  function handleJobOrderRandomization(event) {
    jobOrderRandomization.setValue(pctToFrac(event.target.value));
  }

  function handleContractorPreference(event) {
    contractorPreference.setValue(pctToFrac(event.target.value));
  }

  function proposePriorityProjectChange(ii, newTarget) {
    const newPriorityProjects = priorityProjects.value.slice(); // allocate new object
    newPriorityProjects[ii] = { ...newPriorityProjects[ii], target: newTarget };
    return newPriorityProjects;
  }

  // Return a new distribution given the proposed change bounded to total 100%
  function proposeDistribuionChange(ii, newTarget) {
    const newDistribution = deadlineDistribution.value.slice(); // allocate new object
    newDistribution[ii] = { ...newDistribution[ii], target: newTarget };
    const totalDistribution = computeTotalDistribution(newDistribution);
    if (totalDistribution > 1) {
      const overage = totalDistribution - 1;
      newDistribution[ii].target = Math.round(100 * (newDistribution[ii].target - overage)) / 100;
    }
    return newDistribution;
  }

  function dismissError(ii) {
    const newErrors = errors.slice();
    newErrors.splice(ii, 1);
    setErrors(newErrors);
  }

  return (
    <Form>
      {errors &&
        errors.map((err, ii) => (
          <Alert key={ii} variant="danger" dismissible onClose={() => dismissError(ii)}>
            {err}
          </Alert>
        ))}

      <Form.Row>
        {/* Total Hour Target (text) */}
        <Form.Group as={Col} controlId="marketControlSettings.totalDurationTarget">
          <ControlLabel
            control={hourTarget}
            confirmation={confirmations['total_hour_target']}
            onRemoveOverride={removeControlOverride}
          >
            Total Duration Target
          </ControlLabel>
          <ControlTextInput value={hourTarget.value} units="hrs" onChange={handleTotalHourTarget} />
          <Form.Text muted>
            We will attempt to release this many hours to each user&apos;s market.
          </Form.Text>
        </Form.Group>

        {/* Job Count Range (text, text) */}
        <Form.Group as={Col} controlId="marketControlSettings.jobCountRange">
          <ControlLabel
            control={jobCountRange}
            confirmation={confirmations['job_count_range']}
            onRemoveOverride={removeControlOverride}
          >
            Job Count Range
          </ControlLabel>
          <Form.Row>
            <ControlTextInput
              as={Col}
              value={jobCountRange.value[0]}
              valid={jobCountRangeValid}
              prefix="Min"
              onChange={handleJobCountMin}
            />
            <ControlTextInput
              as={Col}
              value={jobCountRange.value[1] ?? ''}
              valid={jobCountRangeValid}
              prefix="Max"
              placeholder="&infin;"
              onChange={handleJobCountMax}
            />
          </Form.Row>
          <Form.Text muted>
            We will attempt to release a number of jobs inside this range.
          </Form.Text>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        {/* Project Cap (text) */}
        <Form.Group as={Col} controlId="marketControlSettings.projectCap">
          <ControlLabel
            control={projectCap}
            confirmation={confirmations['project_cap']}
            onRemoveOverride={removeControlOverride}
          >
            Project Cap
          </ControlLabel>
          <ControlTextInput value={pct(projectCap.value)} units="%" onChange={handleProjectCap} />
          <Form.Text muted>
            We will try to keep each project to below this percentage in a user&apos;s market.
          </Form.Text>
        </Form.Group>

        {/* Job Order Randomization (text) */}
        <Form.Group as={Col} controlId="marketControlSettings.jobOrderRandomization">
          <ControlLabel
            control={jobOrderRandomization}
            confirmation={confirmations['job_order_randomization']}
            onRemoveOverride={removeControlOverride}
          >
            Job Order Randomization
          </ControlLabel>
          <ControlTextInput
            value={pct(jobOrderRandomization.value)}
            units="%"
            onChange={handleJobOrderRandomization}
          />
          <Form.Text muted>
            We will randomize the secondary sort order of jobs within a deadline distribution bin by
            this percentage.
          </Form.Text>
        </Form.Group>

        {/* Contractor Preference (text) */}
        <Form.Group as={Col} controlId="marketControlSettings.contractorPreference">
          <ControlLabel
            control={contractorPreference}
            confirmation={confirmations['contractor_preference']}
            onRemoveOverride={removeControlOverride}
          >
            Contractor Preference
          </ControlLabel>
          <ControlTextInput
            value={pct(contractorPreference.value)}
            units="%"
            onChange={handleContractorPreference}
          />
          <Form.Text muted>
            We will target this percentage of contractor preferred projects before moving onto the
            regular deadline bins.
          </Form.Text>
        </Form.Group>
      </Form.Row>

      {/* Priority Projects (sliders) */}
      <div>
        <ControlLabel
          control={priorityProjects}
          confirmation={confirmations['priority_projects']}
          onRemoveOverride={removeControlOverride}
        >
          Priority Projects
        </ControlLabel>
        <Form.Text muted>
          We will release jobs from these projects up to the specified target before cycling through
          deadline bins.
        </Form.Text>
        {priorityProjects.value.length === 0 && (
          <p className="text-muted">There are currently no priority projects.</p>
        )}
        {priorityProjects.value.map(({ name, target }, ii) => (
          <HoursPercentageSlider
            key={ii}
            controlId={`marketControlSettings.priorityProjectsTarget${ii}`}
            label={name}
            target={target}
            totalHours={hourTarget.value}
            onChange={(event) => handlePriorityProjects(event, ii)}
          />
        ))}
      </div>

      {/* Deadline Distribution (sliders) */}
      <div>
        <ControlLabel
          control={deadlineDistribution}
          confirmation={confirmations['deadline_distribution']}
          onRemoveOverride={removeControlOverride}
        >
          Deadline Distribution
        </ControlLabel>
        <Form.Text muted>
          We will attempt to release jobs with matching time-to-deadline matching this distribution.
        </Form.Text>
        {deadlineDistribution.value.map(({ name, target, start_hours, end_hours }, ii) => (
          <HoursPercentageSlider
            key={ii}
            controlId={`marketControlSettings.deadlineDistributionTarget${ii}`}
            label={
              <>
                {name}
                <Form.Text className="m-0">
                  {start_hours ?? '*'} - {end_hours ?? '*'} hours
                </Form.Text>
              </>
            }
            target={target}
            valid={distributionValid}
            totalHours={hourTarget.value}
            onChange={(event) => handleDeadlineDistribution(event, ii)}
          />
        ))}

        {/* Totals for Deadline Distribution */}
        <HoursPercentageSlider
          controlId={`marketControlSettings.deadlineDistributionTarget.total`}
          label="Total"
          target={totalDistribution}
          readOnly
          valid={distributionValid}
          totalHours={hourTarget.value}
        />
      </div>

      <Form.Row className={css(styles.fakeFooter)}>
        <Col className="d-flex justify-content-end">
          <Button
            className="ml-1"
            variant="outline-secondary"
            disabled={!anyModified}
            onClick={reset}
          >
            Discard Changes
          </Button>
          <Button
            className="ml-1"
            variant="primary"
            disabled={!(anyModified && allValuesValid)}
            onClick={save}
          >
            Save Changes
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}

ControlGroupSetting.propTypes = {
  controlsList: PropTypes.arrayOf(marketManagementControlShape),
  removeControlOverride: PropTypes.func.isRequired,
  updateControlValue: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  fakeFooter: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#ffffff',
    paddingTop: '0.5em',
  },
});

export default ControlGroupSetting;
