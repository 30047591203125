import { MAX_CHEATSHEET_CHARS } from '~/helpers/constants';

export const CHEAT_SHEET_DEFAULT_STATE = {
  checked: false,
  text: '',
  tooLong: false,
  uploadAllowed: true,
};

const cheatSheetTooLong = (text) => {
  return text.length > MAX_CHEATSHEET_CHARS;
};

const cheatSheetReducer = (currentState, action) => {
  switch (action.type) {
    case 'toggle': {
      return {
        ...currentState,
        orderCheatSheets: currentState['orderCheatSheets'].filter(
          (cs) => cs.serviceType != action.serviceType
        ),
        [action.serviceType]: {
          ...currentState[action.serviceType],
          checked: !currentState[action.serviceType].checked,
          text: '',
          tooLong: false,
        },
      };
    }
    case 'addText': {
      let newOrderCheatsheets = currentState['orderCheatSheets'].filter(
        (cs) => cs.serviceType != action.serviceType
      );
      if (action.text != '') {
        const cheatsheetInfo = {
          serviceType: action.serviceType,
          content: action.text,
        };
        newOrderCheatsheets = newOrderCheatsheets.concat(cheatsheetInfo);
      }

      return {
        ...currentState,
        orderCheatSheets: newOrderCheatsheets,
        [action.serviceType]: {
          ...currentState[action.serviceType],
          text: action.text,
          tooLong: cheatSheetTooLong(action.text),
        },
      };
    }
    case 'upload': {
      return {
        ...currentState,
        orderCheatSheets: currentState.orderCheatSheets
          .filter((cs) => cs.serviceType != action.serviceType)
          .concat(action.info),
      };
    }
    case 'loadTemplate': {
      return {
        ...currentState,
        orderCheatSheets: action.instructions.map((instruction) => ({
          serviceType: instruction.service,
          content: instruction.text,
        })),
        ...action.instructions.reduce(
          (a, instruction) => ({
            ...a,
            [instruction.service]: {
              ...currentState[instruction.service],
              checked: true,
              text: instruction.text,
              tooLong: cheatSheetTooLong(instruction.text),
            },
          }),
          {}
        ),
      };
    }
    case 'remove': {
      return {
        ...currentState,
        orderCheatSheets: currentState.orderCheatSheets.filter(
          (cs) => cs.serviceType != action.serviceType
        ),
        [action.serviceType]: {
          ...currentState[action.serviceType],
          text: '',
          tooLong: false,
        },
      };
    }
    case 'disableUpload': {
      return {
        ...currentState,
        orderCheatSheets: currentState.orderCheatSheets.filter(
          (cs) => cs.serviceType != action.serviceType || cs.sourceS3File == undefined
        ), // Only remove cheat sheets that were uploaded (Text is fine)
        [action.serviceType]: {
          ...currentState[action.serviceType],
          uploadAllowed: false,
        },
      };
    }
    case 'enableUpload': {
      return {
        ...currentState,
        [action.serviceType]: {
          ...currentState[action.serviceType],
          uploadAllowed: true,
        },
      };
    }
    default:
      throw new Error(`Unknown Action: ${action.type}`);
  }
};

export default cheatSheetReducer;
