import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Input } from '../Input/Input';

export function Filters({ items, onChange }) {
  const [filters, setFilters] = useState();

  const handleChange = useMemo(
    () => (filter, value, filters) => {
      const newFilters = { ...filters };

      // Specific component change handlers need to pass an undefined value to clear the filter
      if (typeof value !== 'undefined') {
        newFilters[filter] = value;
      } else {
        delete newFilters[filter];
      }

      onChange && onChange(newFilters);
      setFilters(newFilters);

      // TODO: update query params so we can deep link
    },
    [onChange]
  );

  const onChangeSearch = useMemo(
    () =>
      debounce((filter, value, filters) => {
        handleChange(filter, value || undefined, filters);
      }, 300),
    [handleChange]
  );

  return (
    // TODO: Needs styling love
    <>
      {items.map((item) => {
        if (item.type === 'SEARCH') {
          return (
            <Input
              id={item.code}
              label={item.label}
              key={item.filter}
              type="search"
              onChange={(evt) => onChangeSearch(item.filter, evt.target.value, filters)}
            />
          );
        }

        // ...

        // TODO: Add Variant select and other filter types
      })}
    </>
  );
}
Filters.displayName = 'Filters';

Filters.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['SEARCH']), // , VARIANT
      label: PropTypes.string.isRequired,
      filter: PropTypes.string.isRequired,
      code: PropTypes.string, // Only for type = VARIANT
    })
  ),
  onChange: PropTypes.func,
};
