import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import { ADD, CLEAR } from '~/helpers/constants';
import { vpiNewPath } from '~/helpers/app/paths';
import IntegrationInformation from './IntegrationInformation';
import {
  integrationDetails,
  integrationVideoCount,
  youTubeIntegrationsDetails,
} from '~/components/app/video_platform_integrations/data/queries';

import { pluralize } from '~/helpers/strings';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { userLogger } from '~/logic/UserLogger';

import WithIcon from '~/components/app/common/WithIcon';

function LinkedAccountModal(props) {
  const [pageAfter, setPageAfter] = useState('');
  const [pageBefore, setPageBefore] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [integrationsDetails, setIntegrationsDetails] = useState([]);
  const [integrationVideoDetails, setIntegrationVideoDetails] = useState(null);
  const [pageToken, setPageToken] = useState('');
  const [pageTokenList, setPageTokenList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIntegration, setSelectedIntegration] = useState(0);
  const [searchParams, setSearchParams] = useState({ playlistId: '', libraryId: '' });
  const [sortParams, setSortParams] = useState({});
  const [videosPerPage, setVideosPerPage] = useState(10);

  useEffect(() => {
    if (props.show) {
      if (props.platformDetails.name === 'YouTube') {
        getAllYouTubeIntegrationsDetails(searchParams.playlistId !== '');
      } else {
        getAllIntegrationsDetails();
      }
      setSelectedIntegration(props.integrations[0].id);
    }
    // Cleanup - Reset everything when component unmounts
    return () => {
      setCurrentPage(0);
      setIntegrationsDetails([]);
      setIntegrationVideoDetails(null);
      setPageAfter('');
      setPageBefore('');
      setPageToken('');
      setPageTokenList([]);
      setSearchTerm('');
      setSearchParams({ playlistId: '', libraryId: '' });
      setSortParams({});
      setVideosPerPage(10);
    };
  }, [props.show]);

  useEffect(() => {
    // Get details only if any of the depending states change ONLY when the modal is open
    if (props.show) {
      getIntegrationDetails(searchTerm);
    }
  }, [
    currentPage,
    pageAfter,
    pageBefore,
    pageToken,
    pageTokenList,
    searchParams,
    selectedIntegration,
    sortParams,
    videosPerPage,
  ]);

  useEffect(() => {
    // YouTube public search fetches more results, need to update count of videos
    if (props.show && props.platformDetails.name === 'YouTube') {
      getAllYouTubeIntegrationsDetails(searchParams.playlistId !== '');
    }
  }, [searchParams.playlistId]);

  function selectIntegration(integrationId) {
    setSelectedIntegration(integrationId);
    setCurrentPage(0);
    setPageAfter('');
    setPageBefore('');
    setPageToken('');
    setPageTokenList([]);
    setSearchParams({ playlistId: '', libraryId: '' });
    setSortParams({});
    setVideosPerPage(10);
    props.setLinkedFilesToUpload(CLEAR);
  }

  function closeModal(type = ADD) {
    setSelectedIntegration(0);
    props.closeLinkedAccountModal();
    if (type === CLEAR) {
      props.setLinkedFilesToUpload(CLEAR);
    }
    if (type === ADD) {
      userLogger.logEvent('NewOrder', 'Start Upload', {
        'File Source': 'VPI',
        Platform: props.platformDetails.name,
        'File count': props.filesToUpload.length,
      });
    }
  }

  function updateSortParams(name, direction) {
    const params = {
      direction: direction,
      sortBy: name,
    };
    setSortParams(params);
  }

  async function getAllYouTubeIntegrationsDetails(playlistData) {
    const integrationIds = props.integrations.map((integration) => {
      return Number(integration.id);
    });

    const response = await threeplayApi.request(youTubeIntegrationsDetails, {
      playlistData: playlistData,
      videoPlatformIntegrationIds: integrationIds,
    });
    if (response.data.project) {
      const integration = response.data.project.youtubeLinkedAccounts[0].integrations;
      setIntegrationsDetails(integration);
    }
  }

  async function getAllIntegrationsDetails() {
    const integrationIds = props.integrations.map((integration) => {
      return Number(integration.id);
    });

    const response = await threeplayApi.request(integrationVideoCount, {
      searchParams: searchParams,
      videoPlatformIntegrationIds: integrationIds,
    });
    if (response.data.project) {
      const integration = response.data.project.linkedAccounts[0].integrations;
      setIntegrationsDetails(integration);
    }
  }

  // The state for search term gets updated on every keyup
  // Having this function rely on the state of search term would mean every key press would make a API call
  // Hence kept the search term as a function argument and the method call on button press
  // Whereas the rest of the dependent values are in useEffect, which would trigger this function on state updates
  async function getIntegrationDetails(searchTerm = '') {
    setFetching(true);
    const response = await threeplayApi.request(integrationDetails, {
      currentPage: currentPage + 1, // React Paginator is 0 indexed, whereas the backend expects the indexes to start from 1
      pageAfter: pageAfter,
      pageBefore: pageBefore,
      pageToken: pageToken,
      pageTokenList: pageTokenList,
      searchParams: searchParams,
      searchTerm: searchTerm,
      sortParams: sortParams,
      videosPerPage: videosPerPage,
      // Eventhough this is a single value, the backend expects this to be an array
      // This is used to compute the count of videos for all integrations on a platform
      videoPlatformIntegrationIds: [selectedIntegration],
    });

    if (response.data.project) {
      const integration = response.data.project.linkedAccounts[0].integrations[0];
      setFetching(false);
      setIntegrationVideoDetails(integration);
    }
  }

  function displayVideoCount(integration) {
    if (integration.error) {
      return (
        <div className={css(styles.alertWarning) + ' mt-2'}>
          <WithIcon icon="fa fa-exclamation-triangle">{integration.error}</WithIcon>
        </div>
      );
    } else if (integration.videoCount !== -1) {
      return <span className="mt-2">{pluralize('video', integration.videoCount)}</span>;
    }
  }

  return (
    <Modal dialogClassName="modal-width-x-large" show={props.show} onHide={() => closeModal(CLEAR)}>
      <Modal.Header closeButton>
        <Modal.Title>Add Videos from {props.platformDetails.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body data-testid="modalBody" style={{ minHeight: '50vh', maxHeight: '75vh' }}>
        <Container fluid={true}>
          {fetching && (
            <div className={css(styles.loading)}>
              <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
              <div className={css(styles.loadingText)}>Loading your data...</div>
            </div>
          )}
          {!fetching && (
            <Row>
              <Col xs={2} className={`${css(styles.modal)} p-0`}>
                <ListGroup role="radiogroup">
                  {integrationsDetails.map((integration) => {
                    return (
                      <ListGroup.Item
                        key={integration.id}
                        action
                        onClick={() => selectIntegration(integration.id)}
                        active={integration.id === selectedIntegration ? true : false}
                        role="radio"
                        aria-checked={integration.id === selectedIntegration ? true : false}
                      >
                        <b>{integration.displayName}</b>
                        <br />
                        {displayVideoCount(integration)}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Col>
              <Col xs={10} className={`${css(styles.modal)} px-4 py-1`}>
                {integrationVideoDetails !== null && (
                  <IntegrationInformation
                    currentPage={currentPage}
                    filesToUpload={props.filesToUpload}
                    integration={integrationVideoDetails}
                    searchIntegration={(searchTerm) => getIntegrationDetails(searchTerm)}
                    searchParams={searchParams}
                    searchTerm={searchTerm}
                    setPageAfter={setPageAfter}
                    setPageBefore={setPageBefore}
                    setCurrentPage={setCurrentPage}
                    setLinkedFilesToUpload={props.setLinkedFilesToUpload}
                    setPageToken={setPageToken}
                    setPageTokenList={setPageTokenList}
                    setSearchParams={setSearchParams}
                    setSearchTerm={setSearchTerm}
                    setSortParams={updateSortParams}
                    setVideosPerPage={setVideosPerPage}
                    supportSearching={props.platformDetails.supportSearching}
                    videosPerPage={videosPerPage}
                  />
                )}
              </Col>
            </Row>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <a className="font-weight-bold text-dark" href={vpiNewPath(props.platformDetails.name)}>
          <WithIcon icon="fa fa-info-circle">Looking to add a new account?</WithIcon>
        </a>
        <div>
          <Button className="mr-2" variant="secondary" onClick={() => closeModal(CLEAR)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => closeModal()}
            disabled={props.filesToUpload.length === 0}
          >
            Add Files
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const styles = StyleSheet.create({
  alertWarning: {
    color: '#856404',
    backgroundColor: '#fff3cd',
    borderColor: '#ffeeba',
    position: 'relative',
    padding: '.75rem 1.25rem',
    marginBottom: '1rem',
    border: '1px solid transparent',
    borderRadius: '.25rem',
  },
  loading: {
    textAlign: 'center',
    marginTop: '2rem',
  },
  loadingText: {
    fontSize: '1.5rem',
    marginTop: '.2rem',
  },
  modal: {
    overflowY: 'auto',
    maxHeight: '70vh',
  },
});

LinkedAccountModal.propTypes = {
  closeLinkedAccountModal: PropTypes.func,
  filesToUpload: PropTypes.arrayOf(PropTypes.object),
  integrations: PropTypes.arrayOf(PropTypes.object),
  platformDetails: PropTypes.shape({
    name: PropTypes.string,
    supportSearching: PropTypes.bool,
  }),
  setLinkedFilesToUpload: PropTypes.func,
  show: PropTypes.bool,
};

export default LinkedAccountModal;
