import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';

import ContractorsWithReferralCodes from './ContractorsWithReferralCodes';
import ReferredApplicants from './ReferredApplicants';
import * as services from './services/services';

const TABS = {
  REFERRAL_CODES: 'contractors_with_referral_codes',
  REFERRED_APPLICANTS: 'referred_applicants',
};

const ContractorReferralsPage = ({ getContractors = services.getContractors }) => {
  const [selectedTab, setSelectedTab] = useState(TABS.REFERRAL_CODES);
  const [contractors, setContractors] = useState([]);

  // TODO: abstract this out into a custom hook or a provider/consumer
  useEffect(() => {
    getContractors().then(({ data }) => {
      setContractors(data?.contractors?.items);
    });
  }, []);

  return (
    <>
      <h1>Referrals</h1>

      <Nav
        variant="tabs"
        defaultActiveKey={TABS.REFERRAL_CODES}
        onSelect={(tab) => setSelectedTab(tab)}
      >
        <Nav.Item>
          <Nav.Link eventKey={TABS.REFERRAL_CODES}>Contractors with Referral Codes</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={TABS.REFERRED_APPLICANTS}>Referred Applicants</Nav.Link>
        </Nav.Item>
      </Nav>
      {selectedTab === TABS.REFERRAL_CODES && (
        <ContractorsWithReferralCodes contractors={contractors} />
      )}
      {selectedTab === TABS.REFERRED_APPLICANTS && <ReferredApplicants />}
    </>
  );
};

ContractorReferralsPage.propTypes = {
  getContractors: PropTypes.func,
};

export default ContractorReferralsPage;
