(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("chart.xkcd"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "chart.xkcd"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("chart.xkcd")) : factory(root["react"], root["chart.xkcd"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 