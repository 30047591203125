export const LOOKUP_QUERY = `
  query termVersion($where: TermVersionWhereUniqueInput!) {
    termVersion(where: $where) {
      activeAt
      id
      url
    }
  }
`;

export const SUBMISSION_MUTATION = `
  mutation createTermAcceptance($data: TermAcceptanceCreateInput!) {
    createTermAcceptance(data: $data) {
      data {
        id
        createdAt
        updatedAt
      }
      errors { path code message }
    }
  }
`;
