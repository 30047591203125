import React from 'react';
import PropTypes from 'prop-types';

import { env } from '~/core/env';
import ErrorOverlay from './ErrorOverlay';
import { Notifier } from '@airbrake/browser';

// Generic error boundary component.
//
// To use a custom fallback UI, inherit from this component and override the
// `renderOnError` method.

const devAndStagingConfig = {
  projectId: 434660,
  projectKey: '68788c380b375cae3fffb8ce149f1507',
  environment: env.threeplayEnv,
};

// We send airbrake config to a different project in production
// to separate alerts and visualize stats more easily.
const productionConfig = {
  projectId: 11853,
  projectKey: '91653e45e95fa94f13e9f83554cd7655',
  environment: 'production',
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    if (process.env.NODE_ENV === 'production') {
      this.airbrake = new Notifier(
        env.threeplayEnv === 'production' ? productionConfig : devAndStagingConfig
      );
    }
  }

  // This function can take a 'error' arg, in case that's helpful for the UI
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (this.airbrake) {
      this.airbrake.notify({
        error: error,
        params: { info: info },
        context: { component: this.props.component },
      });
    }
  }

  renderOnError() {
    return <ErrorOverlay />;
  }

  render() {
    if (this.state.hasError) {
      return this.renderOnError();
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  component: PropTypes.string.isRequired,
};

export default ErrorBoundary;
