.previewHeader {
  position: absolute;
  top: 0;
  left: 0;
  padding: var(--padding-lg);
  z-index: 10;
}

.share {
  background-color: white;
  grid-area: share;
  padding: var(--padding-lg);
  border-top: 1px solid #eee;
  box-shadow: -1px 0 5px rgba(52, 53, 61, 0.1);
  z-index: 1;
}

.playerCanvas {
  padding: var(--padding-lg);
  overflow: scroll;
  max-height: 100%;
}

.player {
  /* --scale is set via the component and defaults to 100%. */
  margin: 3rem auto;
  width: max(100%, 300px);
  transform-origin: top center;
  transform: translateZ(0) scale(var(--scale));
  /* 
    Because the scaled content keeps it's original dimensions,
    we reduce the height by using a negative margin.
    This geometry calc is not quite correct but is close enough for a 
    nice result between 0 and 100%.
  */
  margin-bottom: calc((var(--scale) - 85%));

  transition: transform 0.2s;
}

@media (prefers-reduced-motion) {
  .player {
    transition: none;
  }
}

.canvasScale {
  position: absolute;
  margin: var(--padding-lg);
  top: 0;
  right: 0;
  width: calc(50px + 8ch);
}

.canvasScale input {
  text-align: center;
}

.canvasScale button,
.canvasScale button:disabled {
  background-color: #fff;
}
