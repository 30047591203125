import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';

import FilterComponent from './filter_component/FilterComponent';

import FacebookPaginator from './pagniator_component/FacebookPaginator';
import Paginator from './pagniator_component/Paginator';
import TokenListPaginator from './pagniator_component/TokenListPaginator';
import TokenPaginator from './pagniator_component/TokenPaginator';

import { ADD, CLEAR, PER_PAGE_COUNT, REMOVE } from '~/helpers/constants';
import { YOUTUBE_VIDEO_UNAVAILABLE_MESSAGE } from '~/components/app/live_auto_captioning/common/constants';

import { pluralize } from '~/helpers/strings';
import { secondsToTime } from '~/components/app/common/Helpers';
import WithIcon from '~/components/app/common/WithIcon';

import { vpiEditPath } from '~/helpers/app/paths';

const VIDEO_ALREADY_PROCESSED_STATES = ['already-processed', 'rejected'];

const durationDisplay = (duration) => {
  if (duration === undefined || duration === null || Math.round(duration) === 0) {
    return '---';
  }

  return secondsToTime(duration);
};

function HeaderComponent(props) {
  return (
    <>
      <h4 className="d-inline">{props.integration.displayName}</h4>
      <a
        href={vpiEditPath(props.integration.id)}
        className={css(styles.editLink, styles.floatRight, styles.link)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <WithIcon icon="fa fa-cog">Settings</WithIcon>
      </a>
    </>
  );
}

function VideoTableRow(props) {
  if (props.youtubeUnavailable) {
    return (
      <>
        <td>
          <Form.Check aria-label={props.video.name} disabled={true} type="checkbox" />
        </td>
        <td>
          <img className={css(styles.thumbnail)} src={props.video.thumbnailUrl} />
        </td>
        <td>{props.video.name}</td>
        <td>-</td>
        <td>{props.video.id}</td>
        <td>-</td>
      </>
    );
  } else {
    return (
      <>
        <td>
          <Form.Check
            aria-label={props.video.name}
            checked={props.videoSelected(props.video.id)}
            disabled={props.video.state === 'disabled'}
            onChange={() =>
              props.selectVideoForOrder(!props.videoSelected(props.video.id), props.video)
            }
            type="checkbox"
          />
        </td>
        <td
          className={
            props.video.state !== 'disabled' ? css(styles.thumbnailCell) : [props.video.state]
          }
          onClick={function () {
            if (props.video.state !== 'disabled') {
              props.selectVideoForOrder(!props.videoSelected(props.video.id), props.video);
            }
          }}
        >
          <img className={css(styles.thumbnail)} src={props.video.thumbnailUrl} />
        </td>
        <td>{props.video.name}</td>
        <td>{new Date(props.video.createdAt).toLocaleDateString()}</td>
        <td>{props.video.id}</td>
        {props.integration.platformName !== 'Ensemble' && (
          <td>{durationDisplay(props.video.duration)}</td>
        )}
      </>
    );
  }
}

function ErrorMessageComponent(props) {
  if (props.integration.platformName === 'Google Drive') {
    return (
      <Alert className="mt-2" variant="warning">
        Our Google Drive integration is no longer supported and your account has been disconnected.
        Please use another upload method to add videos. If you have any questions, please reach out to our support team. 
      </Alert>
    );
  }

  switch (props.integration.error) {
    case 'Authentication error':
      return (
        <Alert className="mt-2" variant="warning">
          We are having some trouble connecting to your <b>{props.integration.platformName}</b>{' '}
          account. Please try again or{' '}
          <Alert.Link
            href={vpiEditPath(props.integration.id) + '?reauth=true'}
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </Alert.Link>{' '}
          to reauthenticate.{' '}
          {props.integration.platformName === 'YouTube' && (
            <p>
              Due to changes at YouTube, 3Play now requires additional permissions to access videos
              in your YouTube account. Log in to your Youtube account and grant additional
              permissions to continue adding videos.{' '}
              <Alert.Link
                href="https://support.3playmedia.com/hc/en-us/articles/227731768-YouTube-Link-Accounts-and-Enable-Postback-Captions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More.
              </Alert.Link>
            </p>
          )}
        </Alert>
      );
    case 'Setup error':
      return (
        <Alert className="mt-2" variant="warning">
          We are having some trouble connecting to your {props.integration.platformName} account.{' '}
          <Alert.Link
            href={vpiEditPath(props.integration.id) + '?resetup=true'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>Reconnect your account</b>
          </Alert.Link>{' '}
          to continue adding videos.
        </Alert>
      );
    case 'Error':
      return (
        <Alert className="mt-2" variant="warning">
          We are having some trouble connecting to your {props.integration.platformName} account.
          Please try reconnecting your account or{' '}
          <Alert.Link href="mailto:support@3playmedia.com">
            <b>contact support</b>
          </Alert.Link>{' '}
          to continue adding videos.
        </Alert>
      );
  }
}

function TableHeader(props) {
  if (props.header.sortableField) {
    let [sortDirection, icon] =
      props.header.sortDirection === 'ascending'
        ? ['descending', 'fa fa-sort-asc']
        : ['ascending', 'fa fa-sort-desc'];
    if (props.header.sortDirection === null) {
      icon = 'fa fa-sort';
    }
    return (
      <th>
        <a href="#" onClick={() => props.setSortParams(props.header.name, sortDirection)}>
          <i className={icon}></i> {props.header.displayName}
        </a>
      </th>
    );
  } else {
    return <th>{props.header.displayName}</th>;
  }
}
function IntegrationInformation(props) {
  function videoSelected(id) {
    const index = props.filesToUpload.findIndex(
      (vid) => vid['sourceLinkedAccountVideoFile']['externalId'] === id
    );
    return index !== -1;
  }

  function selectVideoForOrder(checked, video) {
    const videoAlreadyProcessed = VIDEO_ALREADY_PROCESSED_STATES.includes(video.state);
    const type = checked ? ADD : REMOVE;
    if (videoAlreadyProcessed === true && type === ADD) {
      if (
        confirm("Are you sure? We've previously processed this file. Do you want to re-order?") ===
        true
      ) {
        addOrRemoveSelection(video, type);
      }
    } else {
      addOrRemoveSelection(video, type);
    }
  }

  function addOrRemoveSelection(video, type) {
    const selectedVideo = {
      duration: video.duration && Number(video.duration),
      name: video.name,
      sourceLinkedAccountVideoFile: {
        externalId: video.id,
        integrationId: Number(props.integration.id),
      },
    };
    props.setLinkedFilesToUpload(type, selectedVideo);
  }

  function handleVideosPerPageChange(perPage) {
    props.setVideosPerPage(parseInt(perPage));
    props.setCurrentPage(0);
    props.setPageAfter('');
    props.setPageBefore('');
    props.setPageToken('');
    props.setPageTokenList([]);
  }

  function selectAll(event) {
    const checked = event.target.checked;
    if (checked) {
      props.integration.videos.forEach((video) => {
        const videoAlreadyProcessed = VIDEO_ALREADY_PROCESSED_STATES.includes(video.state);
        if (!videoAlreadyProcessed && video.state !== 'disabled') {
          selectVideoForOrder(checked, video);
        }
      });
    } else {
      props.setLinkedFilesToUpload(CLEAR);
    }
  }

  function getPaginationComponent(type) {
    switch (type) {
      case 'paginator':
        return Paginator;
      case 'facebook_paginator':
        return FacebookPaginator;
      case 'token_paginator':
        return TokenPaginator;
      case 'token_list_paginator':
        return TokenListPaginator;
    }
  }

  if (!props.integration) {
    return null;
  }

  if (props.integration.error !== null) {
    return (
      <>
        <HeaderComponent {...props} />
        <ErrorMessageComponent {...props} />
      </>
    );
  }

  if (props.integration.videos.length === 0) {
    return (
      <>
        <HeaderComponent {...props} />
        <FilterComponent {...props} />
        <Alert className="mt-2">
          There are currently no videos listed for your <b>{props.integration.displayName}</b>{' '}
          integration.
        </Alert>
      </>
    );
  }

  const Pagination = getPaginationComponent(props.integration.paginationDetails.type);

  return (
    <>
      <HeaderComponent {...props} />
      <FilterComponent {...props} />
      <Table striped bordered hover responsive className="mt-2">
        <thead>
          <tr>
            <th width="2%">
              <Form.Check
                aria-label="Select all files"
                type="checkbox"
                onChange={(e) => selectAll(e)}
              />
            </th>
            {props.integration.headerDetails.map((header) => {
              return (
                <TableHeader
                  key={header.displayName}
                  header={header}
                  setSortParams={props.setSortParams}
                />
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.integration.videos &&
            props.integration.videos.map((video) => {
              if (video.tooltip === null) {
                return (
                  <tr key={video.id} className={[video.state]}>
                    <VideoTableRow
                      {...props}
                      selectVideoForOrder={selectVideoForOrder}
                      videoSelected={videoSelected}
                      video={video}
                    />
                  </tr>
                );
              } else {
                return (
                  <OverlayTrigger
                    key={video.id}
                    placement="top"
                    overlay={<Tooltip>{video.tooltip}</Tooltip>}
                    trigger={['hover', 'focus']}
                  >
                    <tr className={[video.state]}>
                      <VideoTableRow
                        {...props}
                        selectVideoForOrder={selectVideoForOrder}
                        videoSelected={videoSelected}
                        video={video}
                        youtubeUnavailable={video.tooltip === YOUTUBE_VIDEO_UNAVAILABLE_MESSAGE}
                      />
                    </tr>
                  </OverlayTrigger>
                );
              }
            })}
        </tbody>
      </Table>
      <div className={`${css(styles.pagination)} d-flex justify-content-between`}>
        <div>{pluralize('file', props.filesToUpload.length)} selected</div>
        <Pagination {...props} />
        <span className={css(appStyles.perPageSpan)}>
          Show&nbsp;
          <Form.Control
            as="select"
            className={css(styles.dropDown)}
            defaultValue={props.videosPerPage}
            onChange={(event) => handleVideosPerPageChange(event.target.value)}
          >
            {PER_PAGE_COUNT.filter((count) => {
              // YouTube's API maxes out at 50 results per page
              if (props.integration.platformName === 'YouTube' && count === 100) {
                return false;
              }
              return true;
            }).map((count) => (
              <option key={count} value={count}>
                {count}
              </option>
            ))}
          </Form.Control>
          &nbsp;per page
        </span>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  dropDown: {
    width: '30%',
    display: 'inline-block',
    textAlign: 'right',
  },
  editLink: {
    color: '#4D4D4D',
  },
  floatRight: {
    float: 'right',
  },
  link: {
    ':hover': {
      textDecoration: 'none',
    },
    ':active': {
      textDecoration: 'none',
    },
  },
  pagination: {
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    padding: '5px 10px',
  },
  table: {
    display: 'block',
    maxHeight: '50vh',
    overflowY: 'scroll',
  },
  tableMargins: {
    marginBottom: 0,
    marginTop: '0.5rem',
  },
  thumbnail: {
    height: '40px',
    width: '65px',
  },
  thumbnailCell: {
    cursor: 'pointer',
  },
});

ErrorMessageComponent.propTypes = {
  integration: PropTypes.object,
};

HeaderComponent.propTypes = {
  integration: PropTypes.object,
};
IntegrationInformation.propTypes = {
  filesToUpload: PropTypes.arrayOf(PropTypes.object),
  integration: PropTypes.object,
  setCurrentPage: PropTypes.func,
  setLinkedFilesToUpload: PropTypes.func,
  searchTerm: PropTypes.string,
  selectedPlaylistId: PropTypes.string,
  setPageAfter: PropTypes.func,
  setPageBefore: PropTypes.func,
  setPageToken: PropTypes.func,
  setPageTokenList: PropTypes.func,
  setSortParams: PropTypes.func,
  setVideosPerPage: PropTypes.func,
  videosPerPage: PropTypes.number,
};

VideoTableRow.propTypes = {
  integration: PropTypes.object,
  selectVideoForOrder: PropTypes.func,
  video: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    duration: PropTypes.number,
    name: PropTypes.string,
    state: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  }),
  videoSelected: PropTypes.func,
  youtubeUnavailable: PropTypes.bool,
};

export default IntegrationInformation;
