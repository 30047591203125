import React, { useState } from 'react';
import PropTypes from 'prop-types';

import OrderMore from '~/components/app/order_more/OrderMore';
import OutputAssetSelector from '~/components/app/order_more/components/OutputAssetSelector';
import OutputAssetShape from '~/components/app/order_more/components/OutputAssetShape';
import OutputAssetSummary from '~/components/app/order_more/components/OutputAssetSummary';
import SupplementalAssets, {
  supplementalAssetInitialState,
} from '~/components/app/media_files/supplemental_assets/SupplementalAssets';

const AudioMixingTipPanel = () => {
  return (
    <p>
      Audio Mixing is add-on service to generate additional media assets for your Voice Artist Audio
      Description service. Audio Mixing uses supplemental Music & Effects files to create Mono,
      Stereo, or 5.1 Surround audio or video embedded assets. The files that you upload can range
      from one to many based on the Audio Mixing request. If you upload additional files, we will
      replace the ones originally uploaded.
    </p>
  );
};

const AudioMixingOrderMore = ({ authToken, isInternalUser, isManagedAccount, mixingOptions, submissionUrl }) => {
  const [assetData, setAssetData] = useState(supplementalAssetInitialState);
  const [selectedOutputAssets, setSelectedOutputAssets] = useState([]);

  const canSubmit = () => {
    return (
      selectedOutputAssets.length > 0 &&
      selectedOutputAssets.every((mix) => mix.embeds.length > 0) &&
      ((
        assetData.acceptedFiles.length > 0 &&
        assetData.acceptedFiles.every((file) => file.percentComplete === 100)
      ) || assetData.fileName?.length > 0)
    );
  };

  const onSubmit = ({ setErrors, setSuccess }) => {
    const outputAssets = selectedOutputAssets.map((asset) => {
      return {
        code: asset.code,
        embeds: asset.embeds.map((e) => e.code),
      };
    });
    const uploadedFiles = assetData.acceptedFiles.map((file) => {
      return {
        name: file.name,
        externalUrl: file.externalUrl,
      };
    });

    const formData = new FormData();
    formData.append('output_assets', JSON.stringify(outputAssets));
    formData.append('uploaded_files', JSON.stringify(uploadedFiles));
    formData.append('filename', assetData.fileName);

    // TODO: This bit can probably be pulled out to a shared location
    return fetch(submissionUrl, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-TOKEN': authToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccess(true);
        } else {
          setErrors((currErrors) => [
            ...currErrors,
            'There was an error submitting your order. Please try again.',
          ]);
        }
      });
  };

  return (
    <OrderMore
      mainContent={
        <>
          <OutputAssetSelector
            mixingOptions={mixingOptions}
            selectedOutputAssets={selectedOutputAssets}
            setSelectedOutputAssets={setSelectedOutputAssets}
          />
          <SupplementalAssets
            canDecline={false}
            assetData={assetData}
            isInternalUser={isInternalUser}
            isManagedAccount={isManagedAccount}
            setAssetData={setAssetData}
          />
        </>
      }
      onSubmit={onSubmit}
      orderType="Audio Mixing"
      submissionDisabled={!canSubmit()}
      summaryContent={<OutputAssetSummary selectedOutputAssets={selectedOutputAssets} />}
      tipPanelContent={<AudioMixingTipPanel />}
    />
  );
};

AudioMixingOrderMore.propTypes = {
  authToken: PropTypes.string,
  isInternalUser: PropTypes.bool,
  isManagedAccount: PropTypes.bool,
  mixingOptions: PropTypes.shape({
    embed: PropTypes.arrayOf(OutputAssetShape),
    mix: PropTypes.arrayOf(OutputAssetShape),
  }),
  submissionUrl: PropTypes.string,
};

export default AudioMixingOrderMore;
