import React from 'react';
import PropTypes from 'prop-types';
import { timeToMs } from './helpers';
import { Row, Col, InputGroup, FormControl, Form, Button } from 'react-bootstrap';

class FileFormats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bulk: props.bulk,
      selectedFormats: this.props.selectedFormats,
      allSelected: false,
      formatType: this.props.formatType,
      searchTerm: '',
    };
  }

  toggleChecked(event) {
    const formatId = event.target.id;
    const formatObj = this.props.formats.find((f) => f.id == formatId);
    if (this.props.selectedFormats.includes(formatObj)) {
      this.setState({ allSelected: false });
    }
    this.props.toggleSelectedFormat(this.state.formatType, formatObj);
  }

  allSelected() {
    if (this.props.formats.length === 0) {
      return false;
    }
    return this.props.selectedFormats.length === this.props.formats.length;
  }

  toggleSelectAll() {
    if (this.allSelected()) {
      for (let i = 0; i < this.props.formats.length; i++) {
        this.props.toggleSelectedFormat(this.state.formatType, this.props.formats[i]);
      }
    } else {
      for (var j = 0; j < this.props.formats.length; j++) {
        if (
          this.props.selectedFormats.find((f) => f['id'] === this.props.formats[j]['id']) ==
          undefined
        ) {
          this.props.toggleSelectedFormat(this.state.formatType, this.props.formats[j]);
        }
      }
    }
  }

  saveFile(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }

  getAdditionalSettings() {
    let additionalSettings = '';
    switch (this.props.formatType) {
      case 'smpteCaptionFormats':
        if (this.props.settings['smpteFrameRate']) {
          additionalSettings += '&frame_settings=' + this.props.settings['smpteFrameRate'];
        }
        if (this.props.settings['smpteStartTime']) {
          additionalSettings += '&starting_timecode_smpte=' + this.props.settings['smpteStartTime'];
        }
        break;
      case 'transcriptFormats':
        additionalSettings = '&offset=' + timeToMs(this.props.settings['transcriptFirstWordStart']);
        break;
      case 'webCaptionFormats':
        additionalSettings = '&offset=' + timeToMs(this.props.settings['webFirstCaptionStart']);
        break;
    }
    return additionalSettings;
  }

  singleFileDownload(event) {
    const id = event.target.value.split(',')[0];
    const ext = event.target.value.split(',')[1];
    const full_ext = event.target.value.split(',')[2];
    const fileId = Object.keys(this.props.mediaFiles)[0];
    const fileName = this.props.mediaFiles[Object.keys(this.props.mediaFiles)[0]]['title'];
    let fullName =
      fileName +
      full_ext
        .replace('{name}', '')
        .replace('{facebook_lang}', this.props.mediaFiles[fileId]['facebook_lang']);
    let requestUrl = '/files/' + fileId + '/output_formats/' + id + '?dl=1&format=' + ext;
    requestUrl += this.getAdditionalSettings();
    fetch(requestUrl, {
      method: 'GET',
      credentials: 'same-origin',
    })
      .then((response) => {
        const matches = response.headers
          .get('content-disposition')
          .match(/filename[^;\n]*=["']([^;\n]+)["']/);
        if (matches) {
          fullName = decodeURI(matches[1]);
        }
        return response.blob();
      })
      .then((blob) => this.saveFile(blob, fullName));
  }

  buildColumns(formats, selectedFormats) {
    const columns = [];
    const column_height = this.props.formatType == 'favoriteFormats' ? 2 : 6;
    if (formats.length == 0) {
      columns.push(<i className="text-muted">No Results</i>);
      return columns;
    }
    for (let i = 0, j = formats.length; i < j; i += column_height) {
      const column = [];
      var slicedFormatsArray = formats.slice(i, i + column_height);
      for (var k = 0; k < slicedFormatsArray.length; k++) {
        if (this.state.bulk) {
          const checked =
            Object.keys(selectedFormats).find(
              (key) => selectedFormats[key]['id'] == slicedFormatsArray[k]['id']
            ) != undefined;
          column.push(
            <Form.Group
              key={'format-checkbox-' + slicedFormatsArray[k]['id']}
              controlId={'formatCheckbox-' + this.state.formatType + slicedFormatsArray[k]['id']}
            >
              <Form.Check
                name={slicedFormatsArray[k]['name']}
                id={slicedFormatsArray[k]['id']}
                type="checkbox"
                label={slicedFormatsArray[k]['name']}
                onChange={(e) => this.toggleChecked(e)}
                checked={checked}
              />
            </Form.Group>
          );
        } else {
          column.push(
            <div key={'button' + slicedFormatsArray[k]['name']}>
              <Button
                variant="link"
                value={
                  slicedFormatsArray[k]['id'] +
                  ',' +
                  slicedFormatsArray[k]['ext'] +
                  ',' +
                  slicedFormatsArray[k]['full_extension']
                }
                onClick={(e) => this.singleFileDownload(e)}
                disabled={this.props.errors}
              >
                <i className="fa fa-download"></i> {slicedFormatsArray[k]['name']}
              </Button>
            </div>
          );
        }
      }
      columns.push(column.map((col) => col));
    }
    return columns;
  }

  buildFavoriteSections() {
    const sections = [];
    for (let i = 0; i < Object.keys(this.props.formatTypes.formatType).length; i++) {
      var type = this.props.formatTypes.formatType[i];
      sections.push(<h3 key={'fav-title-' + type}>{type}</h3>);
      if (
        this.props.formats.filter(function (format) {
          return format.formatType == type;
        }).length < 1
      )
        continue;
      const formats = this.filterFormatsBySearch(
        this.props.formats.filter(function (format) {
          return format.formatType == type;
        })
      );
      const columns = this.buildColumns(formats, this.props.selectedFormats);
      sections.push(
        <Row key={'fav-row-' + i}>
          {columns.map((col, index) => (
            <Col key={'checkbox-column-' + index}>{col}</Col>
          ))}
        </Row>
      );
    }
    return sections;
  }

  filterFormatsBySearch(formats) {
    if (this.state.searchTerm === '') {
      return formats;
    }
    const filteredFormats = [];
    for (let i = 0; i < formats.length; i++) {
      if (formats[i]['name'].toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
        filteredFormats.push(formats[i]);
      }
    }
    return filteredFormats;
  }

  render() {
    let favoriteColumns = null;
    const columns = this.buildColumns(
      this.filterFormatsBySearch(this.props.formats),
      this.props.selectedFormats
    );
    if (this.props.formatType == 'favoriteFormats') {
      favoriteColumns = this.buildFavoriteSections();
    }
    const columnsToShow =
      favoriteColumns == null ? (
        <Row>
          {columns.map((col, index) => (
            <Col key={'checkbox-column-' + index}>{col}</Col>
          ))}
        </Row>
      ) : (
        favoriteColumns
      );
    return (
      <React.Fragment>
        <Row>
          <Col md="4">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text className="search-icon" id="search-input-addon">
                  <i className="fa fa-search" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className="formats-search-box"
                onChange={(e) => this.setState({ searchTerm: e.target.value })}
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-input-addon"
                value={this.state.searchTerm}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary clear-search-box"
                  onClick={() => this.setState({ searchTerm: '' })}
                  aria-label="Clear Search"
                >
                  <i className="fa fa-times" aria-hidden="true" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col>
            {this.props.bulk ? (
              <Form>
                <Form.Group controlId={'formSelectAllCheckbox-' + this.props.formatType}>
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    onChange={() => this.toggleSelectAll()}
                    checked={this.allSelected()}
                  />
                </Form.Group>
              </Form>
            ) : (
              <span />
            )}
          </Col>
        </Row>
        {columnsToShow}
      </React.Fragment>
    );
  }
}

FileFormats.propTypes = {
  formatTypes: PropTypes.object,
  formatType: PropTypes.string,
  bulk: PropTypes.bool,
  selectedFormats: PropTypes.array,
  formats: PropTypes.array,
  toggleSelectedFormat: PropTypes.func,
  mediaFiles: PropTypes.object,
  formAuthToken: PropTypes.string,
  errors: PropTypes.bool,
  settings: PropTypes.object,
};
export default FileFormats;
