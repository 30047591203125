import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

function AddAlignment(props) {
  const alignmentText = props.alignmentText?.text || '';

  return (
    <div>
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label className="d-none">Alignment Text</Form.Label>
        <Form.Control
          draggable="false"
          as="textarea"
          rows="3"
          value={alignmentText}
          placeholder="Paste your transcript here and make sure it is in unformatted plain text."
          onChange={(e) => props.setAlignmentText(props.index, e.target.value)}
        />
      </Form.Group>
    </div>
  );
}

AddAlignment.propTypes = {
  alignmentText: PropTypes.object,
  index: PropTypes.number,
  setAlignmentText: PropTypes.func,
};

export default AddAlignment;
