import { IOptions, Notifier } from '@airbrake/browser';
import { ErrorLoggingEvent, LoggerInstance, LoggingEvent } from './_types';

import { env } from '~/core/env';

/** The dev and staging airbrake config */
const devAndStagingConfig = {
  projectId: 434660,
  projectKey: '68788c380b375cae3fffb8ce149f1507',
  environment: env.threeplayEnv,
};

/**
 * The production airbrake config
 *
 * We send airbrake config to a different project in production
 * to separate alerts and visualize stats more easily.
 */
const productionConfig = {
  projectId: 11853,
  projectKey: '91653e45e95fa94f13e9f83554cd7655',
  environment: 'production',
};

/** A class for logging to airbrake */
export class AirbrakeLogger implements LoggerInstance {
  /** The airbrake notifier */
  private _notifier: Notifier;

  constructor(
    config: IOptions = env.threeplayEnv === 'production' ? productionConfig : devAndStagingConfig
  ) {
    this._notifier = new Notifier(config);
  }

  /** A method for sending warn logs to airbrake */
  warn(event: LoggingEvent) {
    void this._notifier.notify({
      error: event.message,
      context: { severity: 'warning' },
      params: { info: event.info },
    });
  }

  /** A method for sending error logs to airbrake */
  error(event: ErrorLoggingEvent) {
    void this._notifier.notify({
      error: event.message,
      params: { info: event.info },
    });
  }
}
