import { groupBy } from 'lodash';

import { iso8601ToJsDate } from './dates';
import {
  BookedLiveEventTimeSlot,
  CaptionerAvailabilityCalendarEntry,
  CaptionerAvailableSlot,
  CaptionerShiftCalendarEntry,
  CaptionerShiftTimeSlot,
  DateTimeRange,
  LiveEventCalendarEntry,
  UnBookedLiveEventTimeSlot,
  isBookedLiveEventTimeSlot,
  isUnbookedLiveEventTimeSlot,
} from './types';

interface ConvertAvailabilitySlotOptions {
  title?: string; // Allow hard-coding a title for the calendar entry
}

export function convertCaptionerTimeShiftSlots(
  availabilitySlots: CaptionerShiftTimeSlot[],
  options: ConvertAvailabilitySlotOptions = {}
): CaptionerShiftCalendarEntry[] {
  return availabilitySlots.map((availabilitySlot) => {
    const title =
      options.title ||
      `${availabilitySlot.bookable.firstname} ${availabilitySlot.bookable.lastname}\n${availabilitySlot.bookable.profile.contractor_type}`;

    return {
      id: availabilitySlot.id,
      title: title,
      start: iso8601ToJsDate(availabilitySlot.starts_at),
      end: iso8601ToJsDate(availabilitySlot.ends_at),
      object: availabilitySlot,
      isSelected: false,
    };
  });
}

// TODO: get typescript function overloading working here.  It was fighting hard
// We should be able to combine with
export function convertCaptionerAvailabilitySlots(
  availabilitySlots: CaptionerAvailableSlot[],
  options: ConvertAvailabilitySlotOptions = {}
): CaptionerAvailabilityCalendarEntry[] {
  return availabilitySlots.map((availabilitySlot) => {
    const title =
      options.title ||
      `${availabilitySlot.bookable.firstname} ${availabilitySlot.bookable.lastname}\n${availabilitySlot.bookable.profile.contractor_type}`;

    return {
      id: availabilitySlot.id,
      title: title,
      start: iso8601ToJsDate(availabilitySlot.starts_at),
      end: iso8601ToJsDate(availabilitySlot.ends_at),
      object: availabilitySlot,
      isSelected: false,
    };
  });
}

// We load the data from the live_event and make it all the way down to time slots
// Since events can have multiple jobs, we need to flatten the tree of live events
// Down to a sinleFlatten a live events object that could have multiple jobs into a flattened array of
// time slots.  We also store the job on the time slot in case we want to present any of the data
export function convertBookings(
  liveEventSlots: Array<BookedLiveEventTimeSlot | UnBookedLiveEventTimeSlot>
): LiveEventCalendarEntry[] {
  return liveEventSlots.map((liveEventSlot) => {
    const eventBase = {
      id: liveEventSlot.id,
      start: iso8601ToJsDate(liveEventSlot.starts_at),
      end: iso8601ToJsDate(liveEventSlot.ends_at),
      isSelected: false,
    };

    if (isBookedLiveEventTimeSlot(liveEventSlot)) {
      const captioner = `${liveEventSlot.bookable.firstname} ${liveEventSlot.bookable.lastname}`;
      return {
        ...eventBase,
        title: `${liveEventSlot?.booking.name} (${captioner})`, // Show the name and the captioner
        object: liveEventSlot,
      };
    } else {
      // Unbooked event
      liveEventSlot.bookable = null; // ensure bookable is null to make TS happy
      return {
        ...eventBase,
        title: String(liveEventSlot.booking.name), // Just show the name
        object: liveEventSlot,
      };
    }
  });
}

export function convertAvailabilitySegments(
  bookables: CaptionerShiftTimeSlot[],
  bookings: Array<BookedLiveEventTimeSlot | UnBookedLiveEventTimeSlot>
): CaptionerAvailabilityCalendarEntry[] {
  const bookablesByUserId = groupBy(bookables, 'bookable.id');

  const bookedBookings = bookings.filter(
    (booking): booking is BookedLiveEventTimeSlot => !isUnbookedLiveEventTimeSlot(booking)
  );
  const bookingsByUserId = groupBy(bookedBookings, 'bookable.id');

  const actualAvailability = Object.entries(bookablesByUserId).flatMap(([userId, availability]) => {
    const bookings = bookingsByUserId[userId] || [];
    return adjustAvailability(availability, bookings);
  });

  return convertCaptionerAvailabilitySlots(actualAvailability);
}

function adjustAvailability(
  availability: CaptionerShiftTimeSlot[],
  bookings: BookedLiveEventTimeSlot[]
): CaptionerAvailableSlot[] {
  if (availability.length === 0) {
    return [];
  }

  function getCaptionerShiftTimeSlot({
    starts_at,
    ends_at,
  }: DateTimeRange): CaptionerAvailableSlot {
    const availabilityTemplate = availability[0] as CaptionerShiftTimeSlot;
    if (!availabilityTemplate) {
      console.error('availabilityTemplate is not defined');
      throw new Error('availabilityTemplate is not defined');
    }

    return {
      ...availabilityTemplate,
      starts_at,
      ends_at,
      id: `${availabilityTemplate.id}_${new Date().getTime()}_${Math.random() * 1000000}}`,
      time_slot_type: 'bookable_segment',
    };
  }

  const actualAvailability: CaptionerAvailableSlot[] = [];

  availability.forEach((avail) => {
    let availPeriods = [
      getCaptionerShiftTimeSlot({ starts_at: avail.starts_at, ends_at: avail.ends_at }),
    ];

    bookings.forEach((booking) => {
      const updatedAvailPeriods: CaptionerAvailableSlot[] = [];

      availPeriods.forEach((period) => {
        if (booking.ends_at <= period.starts_at || booking.starts_at >= period.ends_at) {
          // No overlap, keep the period as is
          updatedAvailPeriods.push(period);
        } else {
          // Overlap, adjust the availability
          if (booking.starts_at > period.starts_at) {
            // Booking starts after the availability starts
            updatedAvailPeriods.push(
              getCaptionerShiftTimeSlot({
                starts_at: period.starts_at,
                ends_at: booking.starts_at,
              })
            );
          }
          if (booking.ends_at < period.ends_at) {
            // Booking ends before the availability ends
            updatedAvailPeriods.push(
              getCaptionerShiftTimeSlot({
                starts_at: booking.ends_at,
                ends_at: period.ends_at,
              })
            );
          }
        }
      });

      availPeriods = updatedAvailPeriods;
    });

    actualAvailability.push(...availPeriods);
  });

  return actualAvailability;
}
