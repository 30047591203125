import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import TermInvoices from './TermInvoices';
import AutoRenewModal from './AutoRenewModal';

import { numberToCurrency } from '~/helpers/numbers';

function ProAccountStatus(props) {
  const [showAutoRenewModal, setShowAutoRenewModal] = useState(false);

  function getAutoRenewState() {
    if (props.autoRenew) {
      return <>On</>;
    } else {
      return <>Off</>;
    }
  }

  function paymentByFrequency(defaultPrice, paymentFrequency) {
    if (paymentFrequency === 'annual') {
      return `${numberToCurrency.format(defaultPrice)} / year`;
    } else {
      return `${numberToCurrency.format(defaultPrice / 12)} / month`;
    }
  }

  return (
    <>
      <hr />
      <Container fluid>
        <Row>
          <Col sm={2}>
            <Row className="h5 text-secondary">Billing Frequency</Row>
          </Col>
          <Col>
            <Row>
              <Col sm={2}>
                <Row className="h6 uppercase text-secondary">Billing Cycle</Row>
                <Row className="font-weight-bold capitalize text-truncate status-font-size">
                  {props.paymentFrequency}
                </Row>
              </Col>
              <Col sm={4}>
                <Row className="h6 uppercase text-secondary">Current Subscription Term</Row>
                <Row className="font-weight-bold capitalize text-truncate status-font-size">
                  {`${props.termStart} - ${props.termEnd}`}
                </Row>
              </Col>
              <Col sm={2}>
                <Row className="h6 uppercase text-secondary">Auto Renew</Row>
                <Row className="font-weight-bold capitalize text-truncate status-font-size">
                  {getAutoRenewState()}
                </Row>
              </Col>
              <Col sm={4}>
                <Row className="h6 uppercase text-secondary">Renewal Billing Cycle</Row>
                <Row className="font-weight-bold capitalize text-truncate status-font-size">
                  {props.renewalPaymentFrequency}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={{ span: 6, offset: 6 }}>
                <Row>
                  {props.autoRenew && (
                    <span className="renew-date font-weight-bold">
                      (set to renew for{' '}
                      {paymentByFrequency(props.defaultPrice, props.renewalPaymentFrequency)} on{' '}
                      {props.termEnd})
                    </span>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Button
                size="md"
                onClick={() => setShowAutoRenewModal(true)}
                variant="outline-primary"
              >
                Manage Billing
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
      {props.termInvoices && props.termInvoices.length > 0 && (
        <>
          <hr />
          <TermInvoices termInvoices={props.termInvoices} />
        </>
      )}
      <AutoRenewModal
        accountPlanId={props.accountPlanId}
        autoRenew={props.autoRenew}
        closeModal={() => setShowAutoRenewModal(false)}
        onSave={props.refreshAccountData}
        show={showAutoRenewModal}
        termEnd={props.termEnd}
        termStart={props.termStart}
        paymentFrequency={props.paymentFrequency}
        renewalPaymentFrequency={props.renewalPaymentFrequency}
        defaultPrice={props.defaultPrice}
      />
    </>
  );
}

ProAccountStatus.propTypes = {
  accountPlanId: PropTypes.string,
  autoRenew: PropTypes.bool,
  defaultPrice: PropTypes.number,
  paymentFrequency: PropTypes.string,
  renewalPaymentFrequency: PropTypes.string,
  refreshAccountData: PropTypes.func,
  termStart: PropTypes.string,
  termEnd: PropTypes.string,
  termInvoices: PropTypes.arrayOf(PropTypes.object),
};
export default ProAccountStatus;
