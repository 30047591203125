import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import { Card, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import OrderOptionsContainer from '~/components/app/order_form/serviceOptions/OrderOptionsContainer';
import { translationProfileType } from '~/components/app/order_form/propTypes';
import ObnoxiousYellowBox from '~/components/app/order_form/units/ObnoxiousYellowBox';

import TranslationOrderOptionsTable from './translation/TranslationOrderOptionsTable';
import TranslationTypeSelector from './translation/TranslationTypeSelector';

import 'react-bootstrap-typeahead/css/Typeahead.css';

function TranslationOrderOptions(props) {
  const [availableOptionsForType, setAvailableOptionsForType] = useState(
    props.translationOrderOptions
  );

  useEffect(() => {
    const mediaAndEntertainment = 'Media/Entertainment (Non-SDH subtitles)';
    const mAndEType = props.translationTypes.filter(
      (type) => type.displayName === mediaAndEntertainment
    );
    if (mAndEType.length > 0) {
      props.setSelectedTranslationType(mediaAndEntertainment);
    } else if (props.translationTypes.length > 0) {
      props.setSelectedTranslationType(props.translationTypes[0]?.displayName);
    }
  }, [props.translationTypes]);

  useEffect(() => {
    setAvailableOptionsForType(
      filterTOOByTranslationType(props.translationOrderOptions, props.selectedTranslationType)
    );
    props.setSelectedOrderOptions([]);
    props.setSelectedLanguages([]);
  }, [props.selectedTranslationType]);

  useEffect(() => {
    props.updateOrderOptions('Translation', {
      ...props.orderOptions,
      selectedOptions: props.selectedOrderOptions,
    });
  }, [props.selectedOrderOptions]);

  function setSelectedLanguagesOrderOption(languages) {
    const notPresentInState = languages.filter(
      (language) => props.selectedLanguages.indexOf(language) === -1
    );
    const notPresentInNewList = props.selectedLanguages.filter(
      (language) => languages.indexOf(language) === -1
    );

    if (languages.length === 0) {
      props.setSelectedOrderOptions([]);
    } else if (notPresentInNewList.length > 0) {
      removeSelectedOrderOption(notPresentInNewList[0]);
    } else {
      filterOrderOption(notPresentInState[0]);
    }
    props.setSelectedLanguages(languages);
  }

  function filterOrderOption(language) {
    let filteredOptions = filterTOOByTargetLanguage(language);

    props.setAvailableOptionsForLanguages({
      ...props.availableOptionsForLanguages,
      [language]: filteredOptions,
    });
    if (
      props.defaultTranslationVendorId &&
      defaultVendorPresentInOptions(filteredOptions, props.defaultTranslationVendorId)
    ) {
      filteredOptions = filterTOOByVendor(filteredOptions, props.defaultTranslationVendorId);
    }
    setSelectedOptionForLanguage(filteredOptions[0], language);
    props.setTranslationVendorForInfo(Number(filteredOptions[0].translationVendor.id));
  }

  function setSelectedVendorForLanguage(language, vendorId) {
    let filteredOptions = filterTOOByTargetLanguage(language);
    filteredOptions = filterTOOByVendor(filteredOptions, vendorId);
    setSelectedOptionForLanguage(filteredOptions[0], language);
    props.setTranslationVendorForInfo(Number(vendorId));
  }

  function setSelectedServiceForLanguage(language, serviceLevelId) {
    let filteredOptions = filterTOOByTargetLanguage(language);
    filteredOptions = filterTOOByServiceLevel(filteredOptions, serviceLevelId);
    setSelectedOptionForLanguage(filteredOptions[0], language);
  }

  function removeSelectedOrderOption(language) {
    const index = props.selectedOrderOptions.findIndex(
      (option) => option.targetLanguage === language
    );
    const selectedOptions = [...props.selectedOrderOptions];
    selectedOptions.splice(index, 1);
    props.setSelectedOrderOptions(selectedOptions);
  }

  function defaultVendorPresentInOptions(options, vendorId) {
    return options.filter((option) => option.translationVendor.id === vendorId).length > 0;
  }

  function filterTOOByTargetLanguage(language) {
    return availableOptionsForType.filter((toos) => {
      if (toos.targetLanguage.name === language) {
        return toos;
      }
    });
  }

  function filterTOOByVendor(options, vendorId) {
    return options.filter((option) => {
      if (option.translationVendor.id === vendorId) {
        return option;
      }
    });
  }

  function filterTOOByTranslationType(options, translationType) {
    if (props.translationTypes.length > 0) {
      const selectedTranslationTypeId = props.translationTypes.find(
        (t) => t.displayName === translationType
      )?.id;
      return options.filter((too) => {
        return too.serviceLevel.translationServiceLevelTypeId === selectedTranslationTypeId;
      });
    } else {
      return options;
    }
  }

  function filterTOOByServiceLevel(options, serviceLevelId) {
    return options.filter((option) => {
      if (option.serviceLevel.id === serviceLevelId) {
        return option;
      }
    });
  }

  function setSelectedOptionForLanguage(option, language) {
    const index = props.selectedOrderOptions.findIndex(
      (option) => option.targetLanguage === language
    );
    const selectedOption = {
      price: option.price,
      serviceLevel: option.serviceLevel,
      targetLanguage: language,
      vendor: option.translationVendor,
    };
    const selectedOptions = [...props.selectedOrderOptions];

    if (index === -1) {
      selectedOption['translationProfile'] = props.defaultTranslationProfileId;
      selectedOptions.push(selectedOption);
    } else {
      selectedOption['translationProfile'] = selectedOptions[index]['translationProfile'];
      selectedOptions[index] = selectedOption;
    }
    props.setSelectedOrderOptions(selectedOptions);
  }

  function setSelectedTranslationProfile(event, language) {
    const index = props.selectedOrderOptions.findIndex(
      (option) => option.targetLanguage === language
    );

    const selectedOption = props.selectedOrderOptions[index];
    selectedOption['translationProfile'] = event.target.value;
    const selectedOptions = [...props.selectedOrderOptions];

    selectedOptions[index] = selectedOption;
    props.setSelectedOrderOptions(selectedOptions);
  }

  const options = [
    ...new Set(
      availableOptionsForType
        ?.map((options) => options.targetLanguage)
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((option) => option.name)
    ),
  ];
  return (
    <OrderOptionsContainer
      title="Subtitle &amp; Translation"
      componentName="TranslationOrderOptions"
    >
      {props.translationTypes.length > 0 && (
        <TranslationTypeSelector
          translationTypes={props.translationTypes}
          selectedTranslationType={props.selectedTranslationType}
          setTranslationType={props.setSelectedTranslationType}
        />
      )}
      <Card.Title className={css(appStyles.title)}>Select Languages</Card.Title>
      <Card.Subtitle className="mb-2 text-muted">
        Select languages that you want to translate your original file audio to:
      </Card.Subtitle>
      <Typeahead
        clearButton
        id="transcription-language-dropdown"
        labelKey="name"
        multiple={true}
        onChange={setSelectedLanguagesOrderOption}
        options={options}
        placeholder="Search Languages"
        selected={props.selectedLanguages}
      />
      <TranslationOrderOptionsTable
        availableOptionsForLanguages={props.availableOptionsForLanguages}
        selectedOrderOptions={props.selectedOrderOptions}
        sourceLanguage={props.sourceLanguage?.fullName}
        setSelectedServiceForLanguage={setSelectedServiceForLanguage}
        setSelectedTranslationProfile={setSelectedTranslationProfile}
        setSelectedVendorForLanguage={setSelectedVendorForLanguage}
        translationOrderOptions={availableOptionsForType}
        translationProfiles={props.translationProfiles}
      />
      {props.isManagedAccount && props.selectedLanguages.length > 0 && (
        <Form.Group>
          <Form.Check
            checked={props.refusedSourceLanguageTemplate}
            className="d-inline-block"
            onChange={() => props.setRefusedSourceLanguageTemplate((current) => !current)}
          />
          <Form.Label>I do not need a source language template</Form.Label>
        </Form.Group>
      )}
      <ObnoxiousYellowBox
        checked={props.translationWarningSelected}
        supportLink="https://support.3playmedia.com/hc/en-us/articles/227734668-Translation-Submit-a-Request"
        title="Translations cannot be Refunded"
        toggleChecked={props.setTranslationWarningSelected}
      >
        By checking this box, you indicate that you will not have the opportunity to review your
        transcripts before they are submitted for translation. Translation will begin as soon as the
        main service is completed.
      </ObnoxiousYellowBox>
    </OrderOptionsContainer>
  );
}

TranslationOrderOptions.propTypes = {
  availableOptionsForLanguages: PropTypes.object,
  defaultTranslationProfileId: PropTypes.string,
  defaultTranslationVendorId: PropTypes.string,
  isManagedAccount: PropTypes.bool,
  orderOptions: PropTypes.object,
  refusedSourceLanguageTemplate: PropTypes.bool,
  selectedLanguage: PropTypes.object,
  selectedLanguages: PropTypes.arrayOf(PropTypes.string),
  selectedOrderOptions: PropTypes.array,
  selectedTranslationType: PropTypes.string,
  setAvailableOptionsForLanguages: PropTypes.func,
  setRefusedSourceLanguageTemplate: PropTypes.func,
  setSelectedLanguages: PropTypes.func,
  setSelectedOrderOptions: PropTypes.func,
  setSelectedTranslationType: PropTypes.func,
  sourceLanguage: PropTypes.object,
  setTranslationVendorForInfo: PropTypes.func,
  setTranslationWarningSelected: PropTypes.func,
  translationOrderOptions: PropTypes.array,
  translationProfiles: PropTypes.arrayOf(translationProfileType),
  translationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codeName: PropTypes.string,
      displayName: PropTypes.string,
    })
  ),
  translationWarningSelected: PropTypes.bool,
  updateOrderOptions: PropTypes.func,
};

export default TranslationOrderOptions;
