import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

const GENERIC_ERROR_MESSAGE = 'There was an error importing the translation.';
const ALLOWED_ERROR_MESSAGES = [GENERIC_ERROR_MESSAGE, 'File contains invalid UTF-8 characters.'];

export function ErrorAlert({ error }) {
  return (
    <Alert variant="error">
      {ALLOWED_ERROR_MESSAGES.includes(error) ? error : GENERIC_ERROR_MESSAGE}
    </Alert>
  );
}

function ImportTranslationModal(props) {
  const [fetching, setFetching] = useState(null);
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [error, setError] = useState(null);
  const [fileRequired, setFileRequired] = useState(false);

  function handleClose() {
    props.onClose();
  }

  function handleImport() {
    if (validateForm()) {
      setFileRequired(false);
      setFetching(true);
      const payload = buildFormData();
      fetch('/caption_imports', {
        method: 'POST',
        body: payload,
        credentials: 'same-origin',
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setFetching(false);
          setError(null);
          if (data) {
            if (data.success) {
              setSuccessAlert(true);
              setTimeout(() => {
                handleClose();
                setSuccessAlert(false);
              }, 3000);
            } else {
              setError(data.error);
            }
          }
        })
        .catch(() => {
          setFetching(false);
          setError(GENERIC_ERROR_MESSAGE);
        });
    } else {
      setFileRequired(true);
    }
  }

  // File is mandatory, and the default required attribute doesn't work since we aren't actually submitting a
  function validateForm() {
    return document.querySelector('input[type="file"]').files.length > 0;
  }

  function buildFormData() {
    const formData = new FormData();
    const translatedLanguage = $('#translatedLanguage').val();
    const autoParagraphs = $('#autoParagraphs').val();
    const file = document.querySelector('input[type="file"]').files[0];

    formData.append('v2', true);
    formData.append('authenticity_token', props.authToken);
    formData.append('caption_import[resource_type]', 'Translation');
    formData.append('caption_import[language_id]', translatedLanguage);
    formData.append('caption_import[autoparagraph]', autoParagraphs);
    formData.append('media_file_id', props.fileId);
    formData.append('caption_file', file);
    return formData;
  }

  function successAlert() {
    return <Alert variant="success">Translation import was successful!</Alert>;
  }

  const importTranslation = props.fileActions.importTranslation;

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Import a Translation</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {fetching && <p>Loading...</p>}
        {showSuccessAlert && successAlert()}
        {error && <ErrorAlert error={error} />}
        {importTranslation && (
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Source Language
              </Form.Label>
              <Col sm="8">
                <Form.Control plaintext readOnly defaultValue={importTranslation.sourceLanguage} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="translatedLanguage">
              <Form.Label column sm="4">
                Translated Language
              </Form.Label>
              <Col sm="8">
                <Form.Control as="select">
                  {importTranslation.supportedLanguages &&
                    importTranslation.supportedLanguages.map((lang) => (
                      <option key={lang.id} value={lang.id}>
                        {lang.fullName}
                      </option>
                    ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="autoParagraphs">
              <Form.Label column sm="4">
                Add Paragraph Breaks?
              </Form.Label>
              <Col sm="8">
                <Form.Control as="select">
                  <option value={1}>Yes - add paragraph breaks</option>
                  <option value={0}>No - don&apos;t add paragraph breaks</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Select File
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="file"
                  name="caption_file"
                  accept={importTranslation.supportedFormats}
                />
                {fileRequired && (
                  <span className={css(styles.error)}>You need to upload a file</span>
                )}
              </Col>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleImport} disabled={fetching}>
          Import Translation
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ErrorAlert.propTypes = {
  error: PropTypes.string.isRequired,
};

ImportTranslationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  fileActions: PropTypes.shape({
    importTranslation: PropTypes.shape({
      sourceLanguage: PropTypes.string,
      supportedLanguages: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      supportedFormats: PropTypes.string,
    }),
  }),
  authToken: PropTypes.string,
  fileId: PropTypes.string,
};

const styles = StyleSheet.create({
  error: {
    width: '100%',
    marginTop: '.25rem',
    fontSize: '80%',
    color: '#dc3545',
  },
});

export default ImportTranslationModal;
