import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import BasicInformationForm from '../shared/forms/BasicInformationForm';
import VoiceWritingNav from './VoiceWritingNav';
import ConfirmEmail from '../shared/forms/ConfirmEmail';
import LocationInformation from '../shared/forms/LocationInformation';
import VoiceWritingExperience from './VoiceWritingExperience';
import { submitBasicInformation } from './services/services';

const BASIC_INFORMATION = 0;
const CONFIRM_EMAIL = 1;
const LOCATION_INFORMATION = 2;
const SKILLS_AND_EXPERIENCE = 3;

const APPLICATION_STEPS = {
  [BASIC_INFORMATION]: 'Basic Information',
  [CONFIRM_EMAIL]: 'Confirm Email',
  [LOCATION_INFORMATION]: 'Location Information',
  [SKILLS_AND_EXPERIENCE]: 'Skills and Experience',
};

const VoiceWritingApplication = ({
  countries = [],
  usStates = [],
  redirectPage = () => {},
  referrerParams = {},
  onSuccessfulSubmission,
  jobTypeId,
}) => {
  const [step, setStep] = useState(0);
  const [authToken, setAuthToken] = useState({});
  const [applicant, setApplicant] = useState({});

  const nextStep = ({ applicantInfo, authToken }) => {
    authToken && setAuthToken(authToken);
    applicantInfo && setApplicant({ ...applicant, ...applicantInfo });

    setStep(step + 1);
  };

  const previousStep = ({ applicantInfo }) => {
    applicantInfo && setApplicant({ ...applicant, ...applicantInfo });
    setStep(step - 1);
  };

  return (
    <Row>
      <Col lg={3}>
        <VoiceWritingNav steps={APPLICATION_STEPS} currentStep={step} />
      </Col>
      <Col lg={9}>
        <div className={'pl-1 mt-lg-5 ml-lg-5 pl-3 pr-2'}>
          {(() => {
            switch (step) {
              case BASIC_INFORMATION:
                return (
                  <BasicInformationForm
                    applicantInfo={applicant}
                    countries={countries}
                    usStates={usStates}
                    redirectPage={redirectPage}
                    nextStep={nextStep}
                    jobTypeId={jobTypeId}
                    submitBasicInformation={submitBasicInformation}
                    referrerParams={referrerParams}
                  />
                );
              case CONFIRM_EMAIL:
                return (
                  <ConfirmEmail
                    authToken={authToken}
                    previousStep={previousStep}
                    onSubmit={nextStep}
                    email={applicant.email}
                  />
                );
              case LOCATION_INFORMATION:
                return (
                  <LocationInformation
                    applicantInfo={applicant}
                    previousStep={previousStep}
                    nextStep={nextStep}
                    authToken={authToken}
                  />
                );
              case SKILLS_AND_EXPERIENCE:
                return (
                  <VoiceWritingExperience
                    applicant={applicant}
                    authToken={authToken}
                    nextStep={onSuccessfulSubmission}
                  />
                );
            }
          })()}
        </div>
      </Col>
    </Row>
  );
};

VoiceWritingApplication.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string.isRequired),
  usStates: PropTypes.arrayOf(PropTypes.string.isRequired),
  redirectPage: PropTypes.func,
  onSuccessfulSubmission: PropTypes.func,
  jobTypeId: PropTypes.number.isRequired,
  referrerParams: PropTypes.object,
};

export default VoiceWritingApplication;
