import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import TipPanelContainer from "~/components/app/order_form/tipPanels/TipPanelContainer";

function TranslationTypeInfoPanel(props) {
  return (
    <TipPanelContainer title="Translation Types">
      <Card.Body>
        {props.translationTypes.some((type) => type.codeName === 'general') && (
          <p>
            <b>General/Accessibility (SDH subtitles): </b>Choose this if you need translation or SDH
            subtitles for accessibility accommodations. This translation is also best for a general
            corporate (legal, finance, tech, marketing) and educational content. If your content has
            medical or life sciences terminology, please choose Medical/Life Sciences.
          </p>
        )}
        {props.translationTypes.some((type) => type.codeName === 'media') && (
          <p>
            <b>Media/Entertainment (non-SDH subtitles): </b>Choose this if you need non-SDH
            subtitles. Non-SDH subtitles are intended for a hearing audience in another language.
            They are the standard subtitle type for the distribution of content internationally.
          </p>
        )}
      </Card.Body>
    </TipPanelContainer>
  );
}

TranslationTypeInfoPanel.propTypes = {
  translationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codeName: PropTypes.string,
      displayName: PropTypes.string,
    })
  ),
};

export default TranslationTypeInfoPanel;
