import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';

import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';

import BlankState from './BlankState';
import DetailedEventStatus from '../common/DetailedEventStatus';
import Paginator from '../common/Paginator';
import TableHeader from './TableHeader';

import { checkKeyPress } from '~/helpers/checkKeyPress';
import { PER_PAGE_COUNT } from '~/helpers/constants';
import { durationString } from '~/helpers/numbers';
import { pluralize } from '~/helpers/strings';

import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '../common/constants';

import { fileShowEndpoint, upgradeTranscriptEndpoint } from '~/helpers/app/paths';

import '~/components/app/common/app.css';

function EventsList(props) {
  const statusIcons = {
    complete: 'fa fa-check-circle-o',
    rejected: 'fa fa-times-circle-o',
  };

  function clearSearchTerm() {
    props.setSearchTerm('');
    props.getPreviousEvents('');
  }

  function getCurrentVideoRange(paginationParam, totalVideos) {
    const startIndex = (paginationParam.currentPage - 1) * paginationParam.entriesPerPage + 1;
    const endIndex = paginationParam.currentPage * paginationParam.entriesPerPage;
    return `${startIndex} - ${Math.min(endIndex, totalVideos)}`;
  }

  function upgradeDisabled(event) {
    if (event.state !== 'complete' || event.hasSource === false || event.upgraded === true) {
      return true;
    }
    return false;
  }

  function getUpgradeStatus(event) {
    const errors = [];
    if (event.state !== 'complete') {
      errors.push('Upgrades can not be ordered for an incomplete or failed event');
    }
    if (event.hasSource === false) {
      errors.push('This event requires a source video or audio file in order to be upgraded');
    }
    if (event.upgraded === true) {
      errors.push('This event has already been upgraded');
    }
    return errors;
  }

  return (
    <>
      {((props.events.length === 0 && props.searchTerm.length > 0) || props.events.length > 0) && (
        <InputGroup className="col-sm-3 p-0">
          <FormControl
            aria-label="Search by Name or ID"
            value={props.searchTerm}
            onChange={(e) => props.setSearchTerm(e.target.value)}
            onKeyDown={(e) =>
              checkKeyPress(e, () => {
                props.setPageNumber(1);
                props.getPreviousEvents(props.searchTerm);
              })
            }
            placeholder="Search by Name or ID"
          />
          <InputGroup.Append>
            <Button
              className={css(appStyles.clearButton)}
              variant="link"
              onClick={() => clearSearchTerm()}
            >
              &times;
            </Button>
            <Button aria-label="Search" variant="primary" onClick={() => props.setPageNumber(1)}>
              Search
            </Button>
          </InputGroup.Append>
        </InputGroup>
      )}
      {props.events.length === 0 && <BlankState eventType="previous" />}
      {props.events.length > 0 && (
        <>
          <Table className="mb-0 mt-3 v-align-middle-table">
            <thead>
              <tr>
                {props.headerDetails.map((header) => {
                  return (
                    <TableHeader
                      key={header.displayName}
                      header={header}
                      setSortParams={props.setSortParams}
                    />
                  );
                })}
                <td></td>
              </tr>
            </thead>
            <tbody>
              {props.events.map((event) => {
                const icon = statusIcons[event.state];
                return (
                  <tr key={event.id}>
                    <td>
                      <a href={fileShowEndpoint(event.mfId)}>{event.name}</a>
                    </td>
                    <td>
                      <DetailedEventStatus status={event.status} />
                    </td>
                    <td>{event.captionServiceType}</td>
                    <td>
                      {event.startTime === null
                        ? 'Unknown'
                        : moment(event.startTime * 1000)
                            .tz(props.userTimeZone)
                            .format(DATE_TIME_FORMAT)}
                    </td>
                    <td>{durationString(Math.floor(event.duration / 1000))}</td>
                    <td>{event.integrationName === ' : ' ? 'n/a' : event.integrationName}</td>
                    <td>
                      {
                        <TooltipIfErrors errors={getUpgradeStatus(event)}>
                          <a
                            className={
                              'btn btn-primary btn-sm ' +
                              (upgradeDisabled(event) ? css(styles.disabledUpgradeButton) : '')
                            }
                            href={upgradeTranscriptEndpoint(event.mfId)}
                            disabled={upgradeDisabled(event)}
                          >
                            <i className={`${css(styles.iconSpacing)} fa fa-arrow-up`} />
                            Upgrade
                          </a>
                        </TooltipIfErrors>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className={css(styles.pagination)}>
            <div className="d-flex justify-content-between">
              <span className="m-2">
                Showing{' '}
                {props.paginationDetails.totalPages === 1
                  ? 'all'
                  : `${getCurrentVideoRange(
                      props.paginationParams,
                      props.paginationDetails.totalVideos
                    )} of`}{' '}
                {pluralize('Event', props.paginationDetails.totalVideos)}
              </span>
              <Paginator
                paginationParams={props.paginationParams}
                setPageNumber={(pageNumber) => props.setPageNumber(Number(pageNumber))}
                totalPages={props.paginationDetails.totalPages}
              />
              <span className={css(appStyles.perPageSpan)}>
                Show&nbsp;
                <Form.Control
                  as="select"
                  className={css(styles.dropDown)}
                  defaultValue={props.paginationParams.entriesPerPage}
                  onChange={(event) => props.setEntriesPerPage(Number(event.target.value))}
                >
                  {PER_PAGE_COUNT.map((count) => (
                    <option key={count} value={count}>
                      {count}
                    </option>
                  ))}
                </Form.Control>
                &nbsp;per page
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function PreviousEvents(props) {
  if (props.fetching) {
    return null;
  } else {
    return (
      <div className="border border-top-0 p-2">
        <EventsList
          events={props.previousEvents.liveEventDetails}
          getPreviousEvents={props.getPreviousEvents}
          headerDetails={props.previousEvents.headerDetails}
          paginationDetails={props.previousEvents.paginationDetails}
          paginationParams={props.paginationParams}
          searchTerm={props.searchTerm}
          setEntriesPerPage={props.setEntriesPerPage}
          setPageNumber={props.setPageNumber}
          setSearchTerm={props.setSearchTerm}
          setSortParams={props.setSortParams}
          userTimeZone={props.userTimeZone}
        />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  dropDown: {
    width: '30%',
    display: 'inline-block',
    textAlign: 'right',
  },
  pagination: {
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    padding: '5px 10px',
  },
  disabledUpgradeButton: {
    pointerEvents: 'none',
    opacity: '.65',
  },
  iconSpacing: {
    margin: '0px 3px 0px 0px',
  },
});

EventsList.propTypes = {
  events: PropTypes.array,
  getPreviousEvents: PropTypes.func,
  headerDetails: PropTypes.array,
  paginationDetails: PropTypes.object,
  paginationParams: PropTypes.object,
  searchTerm: PropTypes.string,
  setEntriesPerPage: PropTypes.func,
  setPageNumber: PropTypes.func,
  setSearchTerm: PropTypes.func,
  setSortParams: PropTypes.func,
  userTimeZone: PropTypes.string,
};

PreviousEvents.propTypes = {
  entriesPerPage: PropTypes.number,
  fetching: PropTypes.bool,
  getPreviousEvents: PropTypes.func,
  paginationParams: PropTypes.object,
  previousEvents: PropTypes.object,
  searchTerm: PropTypes.string,
  setEntriesPerPage: PropTypes.func,
  setPageNumber: PropTypes.func,
  setSearchTerm: PropTypes.func,
  userTimeZone: PropTypes.string,
};

export default PreviousEvents;
