import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import RequestPaybumpForm from './RequestPaybumpForm';

const RequestPaybumpModal = ({
  jobId = 0,
  show = false,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  return (
    <Modal show={show} onHide={onClose} data-testid="request-paybumps-modal">
      <Modal.Header closeButton>
        <Modal.Title>Request a Paybump</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RequestPaybumpForm onSuccess={onSuccess} jobId={jobId} />
      </Modal.Body>
    </Modal>
  );
};

RequestPaybumpModal.propTypes = {
  jobId: PropTypes.number,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default RequestPaybumpModal;
