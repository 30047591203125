function ransackQueryParams(params, paramName = 'q') {
  if (!params) {
    return {};
  }

  const query = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        query.append(`${paramName}[${key}][]`, item);
      });
    } else {
      query.append(`${paramName}[${key}]`, value);
    }
  });
  return query;
}

export const graphqlPath = '/data';

export function contractorsPath(query) {
  const basePath = '/contractors';
  const q = ransackQueryParams(query);
  return basePath + '?' + q.toString();
}

const jobPathTemplate = '/jobs/:id';
export function jobPath(id) {
  return jobPathTemplate.replace(':id', id);
}

export function jobsPath(query) {
  const basePath = '/jobs';
  const q = ransackQueryParams(query);
  return basePath + '?' + q.toString();
}
