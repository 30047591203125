import React from 'react';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function RuleDisplay(props) {
  return (
    <div>
      {props.rules.map((rule, ii) => (
        <div key={ii}>
          <FontAwesomeIcon
            icon={rule.ok ? faCheckCircle : faCircle}
            className={rule.ok ? 'text-success' : 'text-muted'}
          />{' '}
          {rule.rule}
        </div>
      ))}
    </div>
  );
}

RuleDisplay.propTypes = {
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      rule: PropTypes.string,
      ok: PropTypes.bool,
    })
  ),
};

export default RuleDisplay;
