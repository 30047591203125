import React from 'react';

import { Alert as BootstrapAlert } from 'react-bootstrap';

export interface AlertProps {
  children: React.ReactNode;
  variant?: 'success' | 'danger' | 'warning' | 'info';
  onClose?: () => void;
  dismissible?: boolean;
}

// TODO: Dismissible needs to implemented via state management
export const Alert = ({ dismissible, onClose, variant, children }: AlertProps) => {
  return (
    <BootstrapAlert {...{ dismissible, onClose, variant }} aria-live="polite">
      {children}
    </BootstrapAlert>
  );
};
