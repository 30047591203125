import React, { ReactElement } from 'react';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';

export interface TooltipProps {
  children: ReactElement;
  id: string;
  text: string;
  placement?: 'auto' | 'top' | 'right' | 'bottom' | 'left';
}

export const Tooltip = ({ children, id, text, placement = 'auto' }: TooltipProps) => (
  <OverlayTrigger
    placement={placement}
    overlay={<BootstrapTooltip id={id}>{text}</BootstrapTooltip>}
  >
    {children}
  </OverlayTrigger>
);
