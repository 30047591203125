import { datadogLogs } from '@datadog/browser-logs';
import { ErrorLoggingEvent, LoggerInstance, LoggingEvent } from './_types';

import { env } from '~/core/env';

/**
 * The production client token
 *
 * For security reasons, API keys cannot be used to send data from a browser, as
 * they would be exposed client-side in the JavaScript code. Instead, web browsers
 * and other clients use client tokens to send data to Datadog.
 *
 * @link https://docs.datadoghq.com/account_management/api-app-keys/#client-tokens
 */
const clientToken =
  env.threeplayEnv === 'production'
    ? 'pub4820924d247c11b3285e66ed1c5ef7c6'
    : 'pub6dc3fb421c7aa5ce9ef8293d9d1c52df';

/** A class for logging to Datadog */
export class DatadogLogger implements LoggerInstance {
  constructor() {
    datadogLogs.init({
      // The client token
      clientToken,
      // The service env
      env: env.threeplayEnv,
      // Don't automatically forward console logs
      forwardConsoleLogs: [],
      // Don't automatically forward console errors
      forwardErrorsToLogs: false,
      // Track all sessions
      sampleRate: 100,
      // The name of the service
      service: 'ui',
    });
  }

  /** Triggers console logs with an info type and the logging event */
  info(event: LoggingEvent) {
    datadogLogs.logger.info(event.message, { info: event.info });
  }

  /** Triggers console logs with a warn type and the logging event */
  warn(event: LoggingEvent) {
    datadogLogs.logger.warn(event.message, { info: event.info });
  }

  /** Triggers console logs with an error type and the logging event */
  error(event: ErrorLoggingEvent) {
    datadogLogs.logger.error(String(event.message), { info: event.info });
  }
}
