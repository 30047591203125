import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';
import { offPlatformVideoEmbeddingJobsPaths } from './offPlatformVideoEmbeddingJobsPaths';
import { OffPlatformVideoEmbeddingJob } from './:id/OffPlatformVideoEmbeddingJob';

function OffPlatformVideoEmbeddingJobsApp({ permissions }: RootProps) {
  return (
    <JobAppContainer
      showPath={offPlatformVideoEmbeddingJobsPaths.show.route}
      jobComponent={<OffPlatformVideoEmbeddingJob />}
      permissions={permissions}
    />
  );
}

export default OffPlatformVideoEmbeddingJobsApp;
