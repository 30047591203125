import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import Table from 'react-bootstrap/Table';

import { contractorGroupShape, jobTypeShape } from '~/components/ops/common/shapes';
import { marketManagementControlShape } from '~/components/ops/market/shapes';

import { contractorsPath } from '../paths';

import CellSettingsOverlay from './CellSettingsOverlay';
import ManagementMatrixCell from './ManagementMatrixCell';

function pct(fraction) {
  return String(Math.round(fraction * 100));
}

function controlMatrixIndex(control) {
  return `${control.controlType}:${control.jobTypeId}:${control.contractorGroupId}`;
}

function indexControlMatrix(controlMatrix) {
  const indexed = {};
  controlMatrix.forEach((control) => {
    indexed[controlMatrixIndex(control)] = control;
  });
  return indexed;
}

function ManagementMatrix(props) {
  const indexedControls = useMemo(() => {
    return indexControlMatrix(props.controlMatrix);
  }, [props.controlMatrix]);

  function controlFor(controlType, { jobTypeId, contractorGroupId }) {
    const index = controlMatrixIndex({ controlType, jobTypeId, contractorGroupId });
    const control = indexedControls[index];
    return control;
  }

  function labeledControlsFor({ jobTypeId, contractorGroupId }) {
    return {
      total_hour_target: controlFor('total_hour_target', { jobTypeId, contractorGroupId }),
      job_count_range: controlFor('job_count_range', { jobTypeId, contractorGroupId }),
      project_cap: controlFor('project_cap', { jobTypeId, contractorGroupId }),
      deadline_distribution: controlFor('deadline_distribution', { jobTypeId, contractorGroupId }),
      job_order_randomization: controlFor('job_order_randomization', {
        jobTypeId,
        contractorGroupId,
      }),
    };
  }

  function displayAsOverridden(control) {
    return control && (control.jobTypeId || control.contractorGroupId) && !control.inherited;
  }

  function cellDisplayData({ jobTypeId, contractorGroupId }) {
    const controls = labeledControlsFor({ jobTypeId, contractorGroupId });
    const overriden =
      displayAsOverridden(controls.total_hour_target) ||
      displayAsOverridden(controls.job_count_range) ||
      displayAsOverridden(controls.project_cap) ||
      displayAsOverridden(controls.deadline_distribution) ||
      displayAsOverridden(controls.job_order_randomization);
    const total_hour_target = controls.total_hour_target && controls.total_hour_target.controlValue;
    const project_cap = controls.project_cap && controls.project_cap.controlValue;
    return {
      total_hour_target,
      total_hour_target_overridden: displayAsOverridden(controls.total_hour_target),
      project_cap,
      project_cap_overridden: displayAsOverridden(controls.project_cap),
      overriden,
    };
  }

  function cellStats(displayData) {
    return (
      <>
        {displayData.total_hour_target}&nbsp;hrs&nbsp;
        {displayData.total_hour_target_overridden && '*'}
        <br />
        &lt; {pct(displayData.project_cap)}%&nbsp;{displayData.project_cap_overridden && '*'}
      </>
    );
  }

  function cellVariant({ contractorGroup, jobType, displayData }) {
    if (displayData.overriden) {
      return 'modified';
    } else if (contractorGroup.id === null || jobType.id === null) {
      return 'default';
    } else {
      return null;
    }
  }

  const defaultGroup = { name: 'Default', display: true, id: null };
  const groups = [defaultGroup, ...props.contractorGroups];
  const defaultJobType = { fullName: 'Default', display: true, id: null };
  const jobTypes = [defaultJobType, ...props.jobTypes];
  return (
    <Table responsive bordered>
      <thead>
        <tr>
          <th className="border-0 align-bottom">
            <strong>Contractor Group</strong>
          </th>
          {jobTypes.map((jobType) => (
            <ManagementMatrixCell key={jobType.id} hideX={!jobType.display} header>
              <strong>{jobType.fullName}</strong>
            </ManagementMatrixCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {groups.map((group) => {
          return (
            <tr key={group.id}>
              <ManagementMatrixCell hideY={!group.display} header>
                <strong>{group.name}</strong>
                {group.id !== null && (
                  <>
                    <br />
                    <a
                      href={contractorsPath({ profile_contractor_group_id_in: [group.id] })}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {group.contractorCount}&nbsp;
                      <i className="fa fa-user" />
                    </a>
                  </>
                )}
              </ManagementMatrixCell>
              {jobTypes.map((jobType) => {
                const displayData = cellDisplayData({
                  jobTypeId: jobType.id,
                  contractorGroupId: group.id,
                });
                const variant = cellVariant({ contractorGroup: group, jobType, displayData });
                return (
                  <ManagementMatrixCell
                    key={jobType.id}
                    hideX={!jobType.display}
                    hideY={!group.display}
                    variant={variant}
                  >
                    <CellSettingsOverlay
                      enter={() =>
                        props.openControls({ jobTypeId: jobType.id, contractorGroupId: group.id })
                      }
                    />
                    {cellStats(displayData)}
                  </ManagementMatrixCell>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

ManagementMatrix.propTypes = {
  contractorGroups: PropTypes.arrayOf(contractorGroupShape),
  jobTypes: PropTypes.arrayOf(jobTypeShape),
  openControls: PropTypes.func,
  controlMatrix: PropTypes.arrayOf(marketManagementControlShape),
};

export default ManagementMatrix;
