import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import HostedCreditCardForm from '~/components/app/account_plans/steps/HostedCreditCardForm';

import PaymentAnnually from './PaymentAnnually';
import WithIcon from '~/components/app/common/WithIcon';
import { MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX } from '~/helpers/constants';

function Payment(props) {
  const [showAddNewCard, setShowAddNewCard] = useState(false);

  function onPaymentChange(paymentType, paymentAccountId) {
    props.setPaymentType(paymentType);
    if (paymentType === 'payment_account') {
      paymentAccountId = parseInt(paymentAccountId);
    } else {
      paymentAccountId = null;
    }
    props.setPaymentAccount(paymentAccountId);
  }

  function openAddCardSection() {
    setShowAddNewCard(true);
    props.setShowInvoiceBillingForm(false);
  }

  function closeAddCardSection() {
    setShowAddNewCard(false);
  }

  return (
    <Container>
      {props.selectedFrequency === 'annual' && (
        <PaymentAnnually
          authenticityToken={props.authenticityToken}
          fetchManagePlanData={props.fetchManagePlanData}
          hasValidCard={props.hasValidCard}
          onPaymentChange={onPaymentChange}
          paymentAccount={props.paymentAccount}
          paymentAccounts={props.paymentAccounts}
          paymentType={props.paymentType}
          setPaymentAccount={props.setPaymentAccount}
          setPaymentType={props.setPaymentType}
          setShowInvoiceBillingForm={props.setShowInvoiceBillingForm}
          showInvoiceBillingForm={props.showInvoiceBillingForm}
          billingInformation={props.billingInformation}
        />
      )}
      <div className="ml-1">
        <Button
          variant="link"
          onClick={() => openAddCardSection()}
          tabIndex={MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX}
        >
          <WithIcon icon={'fa fa-plus-circle'}>Add New Card</WithIcon>
        </Button>
      </div>
      {showAddNewCard && (
        <HostedCreditCardForm
          authenticityToken={props.authenticityToken}
          clientToken={props.clientToken}
          closeAddCardSection={closeAddCardSection}
          fetchManagePlanData={props.fetchManagePlanData}
        />
      )}
    </Container>
  );
}

Payment.propTypes = {
  authenticityToken: PropTypes.string,
  clientToken: PropTypes.string,
  hasValidCard: PropTypes.func,
  paymentAccount: PropTypes.number,
  paymentAccounts: PropTypes.arrayOf(PropTypes.object),
  paymentType: PropTypes.string,
  fetchManagePlanData: PropTypes.func,
  selectedFrequency: PropTypes.string,
  setPaymentAccount: PropTypes.func,
  setPaymentType: PropTypes.func,
  setShowInvoiceBillingForm: PropTypes.func,
  showInvoiceBillingForm: PropTypes.bool,
  siteKey: PropTypes.string,
  billingInformation: PropTypes.object,
};

export default Payment;
