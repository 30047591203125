import { Controller } from '@hotwired/stimulus';

// Use this controller to auto-submit a form on input change.
export default class extends Controller {
  connect() {
    this.element.addEventListener('change', () => {
      this.element.form.requestSubmit();
    });
  }
}
