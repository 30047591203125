import { Caption } from './Caption';
import { CaptionOutputData } from '../_types';
import { CuriousCaptionChecker } from '../CuriousCaptionChecker/CuriousCaptionChecker';

/** The config for the caption creator */
interface CaptionCreatorConfig {
  /** A curious caption checker to check and log curious captions */
  curiousCaptionChecker?: CuriousCaptionChecker;

  /** A function to submit the caption to the CaptioningInterface */
  submitCaption: (caption: Caption) => void;

  /** The zero offset for the captions */
  zeroOffsetDateObject: Date;
}

/** Class that creates captions */
export class CaptionCreator {
  /** A curious caption checker to check and log curious captions */
  private _curiousCaptionChecker: CuriousCaptionChecker;
  /** A function to submit the caption to the CaptioningInterface */
  private _submitCaption: CaptionCreatorConfig['submitCaption'];
  /** The last word from the last caption submitted */
  public lastWordSent: string;
  /** The last caption submitted */
  public lastCaptionSent?: Caption;
  /** The zero offset for the captions */
  private zeroOffsetDateObject: Date;

  /** Create a captionCreator */
  constructor({
    curiousCaptionChecker = new CuriousCaptionChecker(),
    submitCaption,
    zeroOffsetDateObject,
  }: CaptionCreatorConfig) {
    this._curiousCaptionChecker = curiousCaptionChecker;
    this._submitCaption = submitCaption;
    this.zeroOffsetDateObject = zeroOffsetDateObject;
    this.lastWordSent = '';
  }

  /** Create and submit a caption to the CaptioningInterface */
  submitCaption(
    captionTextToSend: string,
    captionOutputData: CaptionOutputData,
  ) {
    const caption = new Caption(
      captionTextToSend,
      captionOutputData,
      this.zeroOffsetDateObject
    );
    this.lastWordSent = caption.lastWord || '';
    this._submitCaption(caption);
    const prevCaption = this.lastCaptionSent;
    this.lastCaptionSent = caption;
    // Log any curious captions; intentionally ignore the result here
    void this._curiousCaptionChecker.check(prevCaption, caption);
  }
}
