import React from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, Form } from 'react-bootstrap';

class TranscriptSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      transcriptFirstWordStart: this.props.settings['transcriptFirstWordStart'],
      defaultSettings: this.props.settings,
      errors: {
        transcript: '',
      },
    };
  }

  onTimeChange(e) {
    const errors = this.state.errors;
    const newTime = e.target.value;
    this.setState({ transcriptFirstWordStart: newTime });
    const validTimeRegex = new RegExp(
      /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9](\.[0-9][0-9][0-9])?$/
    );
    if (newTime.match(validTimeRegex) !== null) {
      errors.transcript = '';
      const formatSettings = this.props.settings;
      formatSettings['transcriptFirstWordStart'] = newTime;
      this.props.updateFormatSettings(this.props.formatType, formatSettings);
    } else {
      errors.transcript = 'Please provide a valid time.';
    }
    this.props.setError(this.props.formatType, errors.transcript.length > 0);
  }

  render() {
    const { errors } = this.state;
    return (
      <>
        <Button
          className="collapse-btn"
          onClick={() => this.setState({ collapsed: !this.state.collapsed })}
          aria-controls="transcript-collapse-text"
          aria-expanded={this.state.collapsed}
          variant="link"
        >
          Additional Transcript Settings{' '}
          {this.state.collapsed ? (
            <i className="fa fa-angle-down"></i>
          ) : (
            <i className="fa fa-angle-up"></i>
          )}
        </Button>
        <Collapse in={!this.state.collapsed}>
          <div id="transcript-collapse-text">
            <Form.Label>First Word Start Time</Form.Label>
            <Form.Control
              value={this.state.transcriptFirstWordStart}
              onChange={(e) => this.onTimeChange(e)}
              type="text"
            />
            {errors.transcript.length > 0 && (
              <small className="text-danger">{errors.transcript}</small>
            )}
          </div>
        </Collapse>
      </>
    );
  }
}

TranscriptSettings.propTypes = {
  setError: PropTypes.func,
  formatType: PropTypes.string,
  settings: PropTypes.object,
  updateFormatSettings: PropTypes.func,
};

export default TranscriptSettings;
