import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

function TableHeader(props) {
  let [sortDirection, icon] =
    props.header.sortDirection === 'ASC' ? ['DESC', 'fa fa-sort-asc'] : ['ASC', 'fa fa-sort-desc'];
  if (props.header.sortDirection === null) {
    icon = 'fa fa-sort';
  }

  return (
    <th
      className={props.header.displayName === 'Name' ? css(styles.nameColumn) : css(styles.column)}
    >
      {props.header.name ? (
        <a
          href="#"
          onClick={() =>
            props.setSortParams({ direction: sortDirection, sortBy: props.header.name })
          }
        >
          <i className={icon}></i> {props.header.displayName}
        </a>
      ) : (
        <>{props.header.displayName}</>
      )}
    </th>
  );
}

const styles = StyleSheet.create({
  nameColumn: {
    width: '30vw',
  },
  column: {
    minWidth: '10vw',
  },
});

TableHeader.propTypes = {
  header: PropTypes.object,
  setSortParams: PropTypes.func,
};

export default TableHeader;
