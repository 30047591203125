import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Table } from '@threeplayground/index';

const formatCurrency = (amount) => `$${amount}`;

const AwardLevelsTable = ({ awardLevels }) => {
  const tableData = useMemo(
    () =>
      awardLevels.map((awardLevel) => ({
        level: awardLevel.name,
        target: awardLevel.targetDisplayText,
        bonus: formatCurrency(awardLevel.awardAmount),
      })),
    [awardLevels]
  );

  const columns = useMemo(
    () => [
      { header: 'Level', accessor: 'level', cell: 'string' },
      { header: 'Target', accessor: 'target', cell: 'number' },
      { header: 'Bonus', accessor: 'bonus', cell: 'string' },
    ],
    []
  );

  return <Table columns={columns} data={tableData} />;
};

AwardLevelsTable.propTypes = {
  awardLevels: PropTypes.array,
};

export default AwardLevelsTable;
