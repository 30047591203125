import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

function InputErrorList(props) {
  return (
    <>
      {props.title && <p className={css(styles.title)}>{props.title}</p>}
      <ul className={css(styles.list)}>
        {props.errors.map((msg) => (
          <li key={msg}>{msg}</li>
        ))}
      </ul>
    </>
  );
}

InputErrorList.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

const styles = StyleSheet.create({
  list: {
    color: '#c00',
    marginBottom: '0px',
    paddingLeft: '0px',
  },
  title: {
    color: '#c00',
  },
});

export default InputErrorList;
