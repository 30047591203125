import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';
import { offPlatformDubbingJobsPaths } from './offPlatformDubbingJobsPaths';
import { OffPlatformDubbingJob } from './:id/OffPlatformDubbingJob';

function OffPlatformDubbingJobsApp({ permissions }: RootProps) {
  return (
    <JobAppContainer
      showPath={offPlatformDubbingJobsPaths.show.route}
      jobComponent={<OffPlatformDubbingJob />}
      permissions={permissions}
    />
  );
}

export default OffPlatformDubbingJobsApp;
