{
  "splash:welcome": "¡Conviértete en contratista de 3Play Media y ayuda a crear videos accesibles en español!",
  "splash:welcome_benefits": "Trabaja en remoto - Recibe pago semanal - Crea tu propio horario",
  "splash:button_start": "Empieza la solicitud para ser contratista",
  "splash:button_continue": "Continúa con una solicitud existente",
  "splash:about_this_role_title": "SOBRE EL TRABAJO",
  "splash:about_this_role": "Buscamos contratistas con un excelente dominio del idioma español para realizar transcripciones del habla y elaborar subtítulos en español.",
  "splash:benefits_title": "Beneficios de ser editor con 3Play Media:",
  "splash:benefits": [
    "Elige los archivos en los que deseas trabajar entre una selección de contenidos del ámbito corporativo, educativo y de medios y entretenimiento.",
    "Como parte de nuestro equipo de élite de editores, podrás elegir dónde y cuándo trabajar.",
    "Recibirás pagos semanales basados en una tarifa por proyecto y por minuto."
  ],
  "splash:tips_computer": "Te recomendamos que realices la solicitud preliminar en una computadora  (no un dispositivo móvil) y durante una sola sesión.",
  "splash:tips_timer": "El tiempo estimado es de 15 minutos. Recuerda, revisa tu ortografía y puntuación al terminar y no olvides las tildes.",
  "splash:tips_envelope": "Al aprobar esta etapa inicial, recibirás un correo con información sobre los pasos a seguir.",
  "splash:requirements_title": "Los contratistas de 3Play Media requieren las siguientes calificaciones:",
  "splash:requirements": [
    "Excelente comprensión oral, ortografía y puntuación en español.",
    "Habilidades de comunicación escrita.",
    "Capacidad para realizar búsquedas por internet y usar obras de consulta.",
    "Habilidad para gestionar proyectos puntualmente y de forma independiente.",
    "Dominio del idioma inglés.",
    "Tener por lo menos 18 años de edad."
  ],
  "splash:background_tooltip": "Esta verificación incluirá nombre, dirección, número de seguridad social, convicciones de delitos graves (de los últimos 7 años), registro de delincuentes sexuales y la lista de vigilancia terrorista. NO incluirá delitos menores o verificaciones de crédito. Esta verificación será pagada por 3Play Media.",
  "splash:background_bold": "3Play Media obtendrá una verificación de antecedentes de todos los solicitantes en los EE.UU.",
  "splash:background": "Completar esta verificación es obligatorio.",
  "splash:not_allowed_selling": "En 3Play Media, solo aceptamos solicitudes de individuos que ofrecen servicios de contratista independiente. Por favor, abstente de realizar la solicitud en nombre de otra persona. La venta y subcontratación de tu trabajo o cuenta de contratista queda explícitamente prohibida.",
  "featured_contractor:title": "Editores destacados",
  "featured_contractor:contractor_title": "Contratista en 3Play",
  "featured_contractor:beau_s": "Soy bastante nómada. Tengo 25 años y disfruto mudarme de lugar a lugar. Ahora mismo, estoy en Nueva York sacando mi título de máster en Columbia y este trabajo me ha dado la flexibilidad que necesito para equilibrar mis responsabilidades. Además, el trabajo me interesa. Me encanta ser una mosca en la pared en lugares que nunca estaría en la vida real. Tengo curiosidad por muchas cosas y aprecio aprender en el trabajo.",
  "featured_contractor:daniel_t": "Tengo una granja urbana orgánica que suministra a los restaurantes y mercados locales. A veces estoy increíblemente ocupado y a veces no. Incluso, un trabajo regular de medio tiempo no me funcionaría porque no sería lo suficientemente flexible para lo que hago. 3Play es perfecto para mí, porque me permite trabajar cuando puedo y recortar cuando no. Además, me ofrece un ingreso adicional muy bueno durante la temporada baja cuando la granja menos ingresos genera.",
  "featured_contractor:maria_t": "3Play es perfecto para mí porque me encanta la gramática, la revisión de textos. Soy súper atenta a los detalles. Y estaba buscando trabajo desde casa. No tenía idea de que disfrutaría el trabajo tanto como lo hago. Estoy muy agradecida de haber sido contratada.",
  "featured_contractor:mirissa_s": "Me gradué con un título de inglés y quería trabajar con el lenguaje, especializándome en revisión. Pero también quería la conveniencia de un trabajo de medio tiempo con horas flexibles mientras el horario de la pasantía de mi marido es tan inestable. Este trabajo es perfecto para nuestras vidas, porque podemos viajar cuando necesitamos hacerlo o cuando queremos. No tengo que solicitar tiempo libre si quiero pasar tiempo con la familia y puedo trabajar cuando tengo una media hora libre. La flexibilidad es lo mejor.",
  "featured_contractor:nicky_b": "Soy madre soltera con un hijo hermoso de 16 meses. 3Play me permite obtener ingresos adicionales más allá de mi salario y, así, me alcanza para comprarle a mi hijo las mejores cosas y me permite enviarlo al mejor centro de cuidado infantil.",
  "test:instructions": "Transcribe el habla completa de cada uno de los siguientes archivos omitiendo muletillas y palabras de relleno. Este estilo de transcripción se llama “transcripción natural”. Además, agrega puntuación usando principalmente punto y coma y, de ser necesario, signos de interrogación y dos guiones (--) para indicar frases inconclusas. Sigue las reglas de ortografía de la <a href='https://www.rae.es' target='_blank' rel='noopener'>RAE</a> y consulta nombres propios en Google."
}
