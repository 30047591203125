import React from 'react';
import PropTypes from 'prop-types';

import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import WithIcon from '~/components/app/common/WithIcon';

function ViccRating(props) {
  let tooltipText;
  let ratingBadge;
  let renderBlock;

  if (!props.score || props.score < 0.1) {
    tooltipText =
      'There was an error creating the captions for this clip. Please make sure the clip belongs to the parent and try again!';
    ratingBadge = (
      <Badge variant="dark">
        <WithIcon icon="fa fa-exclamation-triangle">Error</WithIcon>
      </Badge>
    );
  } else if (props.score < 0.75) {
    tooltipText =
      "There may be some errors in this clip's captions. You should consider upgrading to the full transcription service!";
    ratingBadge = <Badge variant="warning">Uncertain</Badge>;
  } else if (props.score < 0.95) {
    tooltipText = 'You should consider reviewing this clip before publishing it!';
    ratingBadge = (
      <Badge variant="success">
        <WithIcon icon="fa fa-check">Good</WithIcon>
      </Badge>
    );
  } else {
    tooltipText = '';
    ratingBadge = (
      <Badge variant="success">
        <WithIcon icon="fa fa-check">Excellent</WithIcon>
      </Badge>
    );
  }

  if (tooltipText) {
    renderBlock = (
      <OverlayTrigger placement="auto" overlay={tooltipText && <Tooltip>{tooltipText}</Tooltip>}>
        {ratingBadge}
      </OverlayTrigger>
    );
  } else {
    renderBlock = ratingBadge;
  }

  ViccRating.propTypes = {
    score: PropTypes.number,
  };

  return <>{renderBlock}</>;
}

export default ViccRating;
