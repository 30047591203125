import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { PreviousButton, NextButton } from '../ApplicationButtons';
import { applicantShape } from '../../shapes';
import { hasFormErrors, isStringEmpty } from '../../helpers';
import * as workflow from '../../../applicationWorkflow';
import PleaseWait from '../../PleaseWait';

const validate = ({ addressStreet, addressNumber, city, postalCode, phoneNumber }) => {
  const errors = {};

  if (isStringEmpty(addressStreet)) {
    errors.addressStreet = 'Please enter a value.';
  } else if (addressStreet.length > 200) {
    errors.addressStreet = 'Address is too long.';
  }

  if (addressNumber?.length > 30) {
    errors.addressNumber = 'Apt/Floor # is too long.';
  }

  if (isStringEmpty(city)) {
    errors.city = 'Please enter a value.';
  } else if (city.length > 200) {
    errors.city = 'City is too long.';
  }

  if (isStringEmpty(postalCode)) {
    errors.postalCode = 'Please enter a value.';
  } else if (postalCode.length > 50) {
    errors.postalCode = 'Postal/Zip Code is too long.';
  }

  if (isStringEmpty(phoneNumber)) {
    errors.phoneNumber = 'Please enter a value.';
  } else if (phoneNumber.length > 30) {
    errors.phoneNumber = 'Phone Number is too long.';
  }

  return errors;
};

const LocationInformation = ({
  applicantInfo = {},
  previousStep = () => {},
  nextStep = () => {},
  submitLocationInfo = workflow.submitLocationInfo,
  authToken = {},
}) => {
  const [addressStreet, setAddressStreet] = useState(applicantInfo?.addressStreet || '');
  const [addressNumber, setAddressNumber] = useState(applicantInfo?.addressNumber || '');
  const [city, setCity] = useState(applicantInfo?.city || '');
  const [postalCode, setPostalCode] = useState(applicantInfo?.postalCode || '');
  const [phoneNumber, setPhoneNumber] = useState(applicantInfo?.phoneNumber || '');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const serialize = () => ({
    addressStreet,
    addressNumber,
    city,
    postalCode,
    phoneNumber,
    country: applicantInfo?.country,
    region: applicantInfo?.region,
  });

  const handleNextStep = () => {
    const data = serialize();
    const formErrors = validate(data);

    setErrors(formErrors);
    if (hasFormErrors(formErrors)) {
      return;
    }

    setLoading(true);

    submitLocationInfo(data, { authToken })
      .then((r) => {
        setLoading(false);
        const success = r?.data?.updateApplication?.success;

        if (r?.errors || !success) {
          setErrors({ locationInformation: 'Something went wrong! Try again later.' });
          return false;
        }

        nextStep({ applicantInfo: data });
      })
      .catch(() => {
        setErrors({ locationInformation: 'Something went wrong! Try again later.' });
        setLoading(false);
      });
  };

  const handlePreviousStep = () => {
    const data = serialize();
    previousStep({ applicantInfo: data });
  };

  return (
    <>
      {loading && <PleaseWait />}
      <div className={'slide-up ' + css(styles.div)}>
        <h2>Now, let&apos;s find out where you&apos;re located.</h2>

        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="streetaddress">
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                value={addressStreet}
                onChange={(e) => setAddressStreet(e.target.value)}
                className={errors.addressStreet && 'is-invalid'}
              />
              {errors.addressStreet && (
                <Form.Text className="text-danger">{errors.addressStreet}</Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} xs={2} controlId="number">
              <Form.Label>Apt/Floor #</Form.Label>
              <Form.Control
                value={addressNumber}
                onChange={(e) => setAddressNumber(e.target.value)}
                className={errors.addressNumber && 'is-invalid'}
              />
              {errors.addressNumber && (
                <Form.Text className="text-danger">{errors.addressNumber}</Form.Text>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className={errors.city && 'is-invalid'}
              />
              {errors.city && <Form.Text className="text-danger">{errors.city}</Form.Text>}
            </Form.Group>
            <Form.Group as={Col} controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control readOnly value={applicantInfo?.country} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="region">
              <Form.Label>State/Province/Region</Form.Label>
              <Form.Control readOnly value={applicantInfo?.region} />
            </Form.Group>
            <Form.Group as={Col} controlId="postalcode">
              <Form.Label>Postal/Zip Code</Form.Label>
              <Form.Control
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                className={errors.postalCode && 'is-invalid'}
              />
              {errors.postalCode && (
                <Form.Text className="text-danger">{errors.postalCode}</Form.Text>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={6} controlId="phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className={errors.phoneNumber && 'is-invalid'}
              />
              {errors.phoneNumber && (
                <Form.Text className="text-danger">{errors.phoneNumber}</Form.Text>
              )}
            </Form.Group>
          </Form.Row>
          {errors.locationInformation && (
            <Form.Text className="text-danger">{errors.locationInformation}</Form.Text>
          )}
        </Form>
        <>
          <PreviousButton onClick={handlePreviousStep} />
          <NextButton disabled={loading} onClick={handleNextStep} />
        </>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  div: {
    maxWidth: '650px',
  },
});

LocationInformation.propTypes = {
  applicantInfo: applicantShape,
  authToken: PropTypes.object,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  submitLocationInfo: PropTypes.func,
};

export default LocationInformation;
