import React from 'react';
import PropTypes from 'prop-types';

import MultiServiceTable from './MultiServiceTable';

import { captionEncodingDownloadPath } from '~/helpers/app/paths';

import WithIcon from '~/components/app/common/WithIcon';

function CaptionEncodingServiceCard(props) {
  const headers = ['Output Type', 'Language(s)', 'Status', '', ''];
  return (
    <MultiServiceTable headers={headers}>
      {props.services.map((service) => (
        <tr key={service.id}>
          <td width="30%">{service.outputType || 'Unavailable'}</td>
          <td>{service.languages || 'Unavailable'}</td>
          <td>{service.expiry}</td>
          {service.available && !service.studio && (
            <>
              <td>
                <a href={captionEncodingDownloadPath(service.encodingId)}>
                  <WithIcon icon="fa fa-download">Download</WithIcon>
                </a>
              </td>
              <td>
                <a
                  href="#"
                  onClick={() => {
                    props.setShowModal.editEncodedCaptions(true, service.encodingId);
                  }}
                >
                  <WithIcon icon="fa fa-pencil">Edit</WithIcon>
                </a>
              </td>
            </>
          )}
        </tr>
      ))}
    </MultiServiceTable>
  );
}

CaptionEncodingServiceCard.propTypes = {
  services: PropTypes.array,
  onUpdate: PropTypes.func,
  setShowModal: PropTypes.shape({
    editEncodedCaptions: PropTypes.func,
  }),
};

export default CaptionEncodingServiceCard;
