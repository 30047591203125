import { useEffect, useMemo, useState } from 'react';

export function useControlValue(controlsList, controlName, defaultValue = '') {
  // Get the named control object from the list
  function controlsListItem() {
    if (!controlsList) {
      return null;
    }
    return controlsList.find((control) => control.controlType === controlName);
  }

  // Return a copy of the control object with a parsed value
  function parsedControlListItem() {
    const control = controlsListItem() || {};
    const controlValue = control.controlValue && JSON.parse(control.controlValue);
    const parsedControl = { ...control, controlValue };
    return parsedControl;
  }

  // TODO: add controlName to deps? It's pathological for this to change.
  const persistedControl = useMemo(parsedControlListItem, [controlsList]);
  const [value, setValue] = useState(persistedControl?.controlValue || defaultValue);
  const isModified = JSON.stringify(persistedControl?.controlValue) !== JSON.stringify(value);

  function reset() {
    setValue(persistedControl?.controlValue || defaultValue);
  }

  // Resync inputs from persisted values if props change
  useEffect(reset, [persistedControl]);

  return {
    persistedControl,
    value,
    setValue,
    isModified,
    reset,
  };
}
