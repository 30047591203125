const archiveFileMutation = `mutation ArchiveFile($fileId: ID!) {
  archiveFile(fileId: $fileId) {
    errors,
    file{ id }
  }
}`;

const unarchiveFileMutation = `mutation UnarchiveFile($fileId: ID!) {
  unarchiveFile(fileId: $fileId) {
    errors,
    file{ id }
  }
}`;

const encodedCaptionsMutation = `mutation EncodedCaptions($encodedCaptionId: ID!, $fontSize: ID!, $captionLines: ID!, $verticalMargin: ID) {
  encodedCaptions(encodedCaptionId: $encodedCaptionId, fontSize: $fontSize, captionLines: $captionLines, verticalMargin: $verticalMargin) {
    success
  }
}`;

const addAlignmentMutation = `mutation AddAlignment($id: ID!, $alignmentText: String!) {
  addAlignment(id: $id, transcriptForAlignment: $alignmentText) {
    error,
    success
  }
}`;

const replaceSourceMutation = `mutation ReplaceSource($fileId: ID!, $fileName: String!, $externalFileUrl: String, $isFinal: Boolean!) {
  replaceSource(fileId: $fileId, fileName: $fileName, externalFileUrl: $externalFileUrl, isFinal: $isFinal) {
    errors,
    success
  }
}`;

export {
  addAlignmentMutation,
  archiveFileMutation,
  unarchiveFileMutation,
  encodedCaptionsMutation,
  replaceSourceMutation,
};
