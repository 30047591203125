import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import WithIcon from '~/components/app/common/WithIcon';

import { fileDataPropSpec } from './data/propspec';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';

function PanelHeader(props) {
  const name = props.fileData.name || 'File';
  const uploadedDate =
    props.fileData.createdAt === null
      ? 'unknown'
      : moment(parseInt(props.fileData.createdAt) * 1000)
          .tz(props.userTimeZone)
          .format(DATE_TIME_FORMAT);
  const uploadedBy = props.fileData.uploadedByUserName || props.fileData.uploadMethod || 'unknown';
  const assetsReprocessing = props.fileData?.reprocessingAssets?.length > 0;

  return (
    <div className={css(styles.header)}>
      <h3 id="showPanelDialogTitle" className={css(styles.fileName)}>
        {assetsReprocessing && <WithIcon icon="fa fa-spinner"></WithIcon>}
        {name}
      </h3>
      <h5 className={css(styles.uploadMethod)}>
        Uploaded by {uploadedBy} on {uploadedDate}
      </h5>
      {props.children}
    </div>
  );
}

PanelHeader.propTypes = {
  children: PropTypes.node,
  fileData: fileDataPropSpec,
  userTimeZone: PropTypes.string,
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#202435',
    color: '#ffffff',
    width: '100%',
    height: '100%',
    padding: '4px 6px 4px 18px',
    flex: '0 0 auto',
    zIndex: '10000',
    overflow: 'visible',
  },
  fileName: {
    color: '#FFFFFF',
    marginBottom: '0px',
    fontSize: '17px',
    marginTop: '2px',
    fontWeight: 'bold',
    letterSpacing: '-.3px',
    lineHeight: '28px',
    width: 'calc(100% - 50px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  uploadMethod: {
    marginTop: '0px',
    color: '#DEEEF5',
    fontSize: '14px',
    marginBottom: '5px',
  },
});

export default PanelHeader;
