import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import ChallengeTracker from './ChallengeTracker';
import EngagementProgramTracker from './engagement_program/EngagementProgramTracker';
import { getAllChallengesQuery } from './services/queries';
import { threeplayApi } from '~/components/jobs/common/threeplayApi';
import { CHALLENGE_STATES } from './constants';

const NO_ACTIVE_CHALLENGES_COPY = `There are no active challenges right now.  Please check back later!`;

const NoActiveChallenges = () => (
  <>
    <h2>Challenges</h2>
    <Container>
      <p>{NO_ACTIVE_CHALLENGES_COPY}</p>
    </Container>
  </>
);

const Loading = () => (
  <Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
);

const fetchChallenges = () => {
  return threeplayApi.json(getAllChallengesQuery, { state: CHALLENGE_STATES.active });
};

const ChallengesPage = ({ getChallenges = fetchChallenges }) => {
  const [data, setData] = useState({});

  const challenges = useMemo(() => {
    return data?.challenges ?? [];
  }, [data]);
  const engagementPrograms = useMemo(() => {
    return data?.engagementPrograms ?? [];
  }, [data]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getChallenges()
      .then(({ errors, data }) => {
        if (errors) {
          throw new Error();
        }
        setData(data);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className={css(styles.challengesPage)}>
      {isError && <Alert variant={'danger'}>Something went wrong. Try refreshing the page.</Alert>}
      {isLoading && <Loading />}
      {!isLoading && !isError && !challenges.length && !engagementPrograms.length ? (
        <NoActiveChallenges />
      ) : (
        <>
          <EngagementProgramTracker data={engagementPrograms} />
          <ChallengeTracker data={challenges} />
        </>
      )}
    </div>
  );
};

ChallengesPage.propTypes = {
  getChallenges: PropTypes.func,
};

const styles = StyleSheet.create({
  challengesPage: {
    marginTop: '20px',
  },
});

export default ChallengesPage;
