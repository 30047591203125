import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';

import { Alert, Button } from '@threeplayground/index';

import PricingBlocksTable from './PricingBlocksTable';
import SkuLookup from './SkuLookup';
import { Collapse } from 'react-bootstrap';

const PricingBlocksApp = ({
  authToken,
  resourceId,
  resourceName,
  resourceType,
  breadcrumbs,
  pricingBlocks,
  skus,
  downloadUrl,
  uploadUrl,
  loadError,
}) => {
  const [file, setFile] = useState();
  const [blocks, setBlocks] = useState(pricingBlocks);
  const [updated, setUpdated] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [errorsExpanded, setErrorsExpanded] = useState(false);

  const handleFileSelect = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    setUpdated(false);
    setUploadErrors([]);

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('resource_type', resourceType);
    formData.append('resource_id', resourceId);
    formData.append('file', file);

    fetch(uploadUrl, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-TOKEN': authToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUpdated(true);
        handleErrors(data);
        setBlocks(data.pricingBlocks);
      })
      .catch((err) => setUploadErrors(err));
  };

  const handleErrors = (response) => {
    const newErrors = [];
    if (Object.keys(response.errors).length !== 0) {
      setUpdated(false);
      for (const [key, value] of Object.entries(response.errors)) {
        if (key === 'columns') {
          value.forEach((error) => newErrors.push(error));
        } else {
          value.forEach((error) => newErrors.push(`Line ${key}: ${error}`));
        }
      }
    }
    setUploadErrors(newErrors);
  };

  return (
    <>
      {loadError && <Alert variant="danger">{loadError}</Alert>}
      {!loadError && (
        <>
          {updated && <Alert variant="success">Update successful</Alert>}
          {uploadErrors.length > 0 && (
            <Alert variant="danger">
              <ul className={css(styles.errorList)}>
                {uploadErrors.slice(0, 6).map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
              {uploadErrors.length > 6 && (
                <span>
                  <Collapse in={errorsExpanded}>
                    <ul className={css(styles.errorList)}>
                      {uploadErrors.slice(6).map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </Collapse>
                  <span className={css(styles.collapseBtn)}>
                    <Button
                      onClick={() => setErrorsExpanded(!errorsExpanded)}
                      aria-expanded={errorsExpanded}
                      variant="secondary"
                    >
                      {errorsExpanded ? 'View less' : `View ${uploadErrors.length - 6} more`}
                    </Button>
                  </span>
                </span>
              )}
            </Alert>
          )}
          <div className={css(styles.flexContainer)}>
            <Card.Title className={css(styles.flexRow)}>
              Pricing Blocks for {resourceName} ({resourceType}: {resourceId})
            </Card.Title>
            <div className={css(styles.buttons)}>
              <input type="file" accept=".csv" onChange={handleFileSelect} />
              <Button onClick={handleUpload}>Upload</Button>
              <a href={downloadUrl} className={css(styles.download)}>
                <Button>Download</Button>
              </a>
            </div>
          </div>
          {breadcrumbs.length > 0 && (
            <Breadcrumb>
              {breadcrumbs.map((link, index) => (
                <Breadcrumb.Item key={index} href={link.href}>
                  {link.name}
                </Breadcrumb.Item>
              ))}
              <Breadcrumb.Item key={breadcrumbs.length} active href="#">
                {resourceType}
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
          <Alert className="in" variant="info">
            To update pricing blocks:
            <ol>
              <li>
                Click the &apos;download&apos; button in the upper right-hand corner for a CSV
                template that also includes any existing pricing blocks
              </li>
              <li>
                Modify the CSV to include ALL desired pricing blocks for the resource
                <ol>
                  <li>Existing pricing blocks that you do not keep in your CSV will be deleted!</li>
                </ol>
              </li>
              <li>
                Select the modified CSV via the file picker, and press the &apos;Upload&apos; button
              </li>
            </ol>
            Rows with pricing blocks for resources other than the one on this page will be skipped.
          </Alert>
          <PricingBlocksTable pricingBlocks={blocks} />
          <SkuLookup skus={skus} />
        </>
      )}
    </>
  );
};

PricingBlocksApp.propTypes = {
  authToken: PropTypes.string,
  resourceId: PropTypes.number,
  resourceName: PropTypes.string,
  resourceType: PropTypes.string,
  breadcrumbs: PropTypes.array,
  pricingBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      startMinute: PropTypes.number,
      endMinute: PropTypes.number,
      price: PropTypes.number,
      sku: PropTypes.string,
    })
  ),
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  downloadUrl: PropTypes.string,
  uploadUrl: PropTypes.string,
  loadError: PropTypes.string,
};

const styles = StyleSheet.create({
  collapseBtn: {
    display: 'inline-block',
    'margin-left': 60,
    'margin-top': 10,
  },
  download: {
    'margin-left': '0.25rem',
  },
  errorList: {
    'margin-bottom': 0,
  },
  flexContainer: {
    display: 'flex',
    'margin-bottom': '1em',
  },
  flexRow: {
    flex: 1,
  },
});

export default PricingBlocksApp;
