import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { offPlatformAudioMixingJobsPaths } from './offPlatformAudioMixingJobsPaths';
import { OffPlatformAudioMixingJob } from './:id/OffPlatformAudioMixingJob';
import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';

function OffPlatformAudioMixingJobsApp({ permissions }: RootProps) {
  return (
    <JobAppContainer
      showPath={offPlatformAudioMixingJobsPaths.show.route}
      jobComponent={<OffPlatformAudioMixingJob />}
      permissions={permissions}
    />
  );
}

export default OffPlatformAudioMixingJobsApp;
