const ThemeColors = {
  background: '#FAFAFA',
  coralMeHappy: '#FF7575',
  threeplayBlue: '#DEEFF5',
  skysTheLimit: '#DEEFF5',
  spaceCadet: '#273469',
  stayGolden: '#007EB5',
};

export default ThemeColors;
