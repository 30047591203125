import { LogLevel } from '~/logic/Logger/_types';
import { Caption } from '../CaptionCreator/Caption';
import { CuriousCaptionMatcher } from './_types';

interface RepeatedWordsMatcherConfig {
  /** The number of repeated words that will trigger the matcher */
  numberOfRepeats?: number;
}

/** A curious caption matcher for identifying repeated words */
export class RepeatedWordsMatcher implements CuriousCaptionMatcher {
  logLevel: LogLevel = 'warn';

  /** The number of repeated words that will trigger the matcher */
  private _numberOfRepeats: number;

  constructor({ numberOfRepeats = 4 }: RepeatedWordsMatcherConfig = {}) {
    this._numberOfRepeats = numberOfRepeats;
  }

  /** Whether or not the captions have repeated words */
  matches(prevCaption: Caption | undefined, newCaption: Caption) {
    const repeatedWordsRecord: Record<string, boolean> = {};
    const prevCaptionWords = prevCaption ? prevCaption.captionWordsArray : [];
    const newCaptionWords = newCaption.captionWordsArray;
    const wordsInCaption = [prevCaptionWords, newCaptionWords].flat();
    const indexOfNewCaption = prevCaptionWords.length;
    /*
     * To avoid matching twice when the repeated words are all in one caption,
     * we need to start looking for repeats at the start of the new words minus
     * one less than the number of repeats that trigger the matcher.
     */
    const startIndexOfPossibleRepeats = Math.max(
      indexOfNewCaption - (this._numberOfRepeats - 1),
      0
    );
    const wordsToSearch = wordsInCaption.slice(startIndexOfPossibleRepeats);
    wordsToSearch.forEach((word, index) => {
      const nextNWords = wordsToSearch.slice(index, index + this._numberOfRepeats);
      if (nextNWords.length >= this._numberOfRepeats && nextNWords.every((w) => w === word)) {
        repeatedWordsRecord[word] = true;
      }
    });
    return Object.keys(repeatedWordsRecord).length > 0;
  }
}
