import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';
import { offPlatformLocalizationJobsPaths } from './offPlatformLocalizationJobsPaths';
import { OffPlatformLocalizationJob } from './:id/OffPlatformLocalizationJob';

function OffPlatformLocalizationJobsApp({ permissions }: RootProps) {
  return (
    <JobAppContainer
      showPath={offPlatformLocalizationJobsPaths.show.route}
      jobComponent={<OffPlatformLocalizationJob />}
      permissions={permissions}
    />
  );
}

export default OffPlatformLocalizationJobsApp;
