import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Alert, Link } from '@threeplayground/index';
import { Page } from '@threeplayground/unstable';
import { pineapplePaths } from './pineapplePaths';
import { PineappleForm } from './PineappleForm';
import { useVariantList } from '~/core/hooks/useVariantList';
import { use3PMutation } from '~/logic/unstable/use3PMutation';

const CREATE_PINEAPPLE_MUTATION = `
  mutation createPineapple($data: PineappleCreateInput!) {
    createPineapple(data: $data) {
      data {
        id
        createdAt
        updatedAt
      }
      errors { path code message }
    }
  }
`;

export function CreatePineapplePage() {
  const { mutateAsync } = use3PMutation('createPineapple', CREATE_PINEAPPLE_MUTATION, {
    extractKey: 'createPineapple',
  });
  const [globalSuccessMessage, setGlobalSuccessMessage] = useState('');
  const [globalErrors, setGlobalErrors] = useState(undefined);
  const [fieldErrors, setFieldErrors] = useState(null);
  const { data: varieties, isLoading: isLoadingVarieties } = useVariantList('PINEAPPLE_VARIETY');

  // TODO we probably want some standard spinner for loading states
  if (isLoadingVarieties) {
    return <span className="spinner-border spinner-border-sm mr-1" />;
  }

  const onSubmit = async (data) => {
    //TODO Input type needs to be fixed so we dont need to do this
    data.weight = +data.weight;
    const {
      data: recordData,
      errors,
      globalErrors: recordGlobalErrors,
    } = await mutateAsync({
      data,
    });

    // TODO these would be nice to expose from `use3PMutation` rather than our own state.
    setGlobalErrors(recordGlobalErrors || undefined);
    setFieldErrors(errors || null);

    // Note: once we have a list page, this would likely route back to the list page instead of putting up a success message
    if (recordData && !errors) {
      setGlobalSuccessMessage(`Pineapple with ID ${recordData.id} created!`);
      setTimeout(() => {
        setGlobalSuccessMessage('');
      }, 5000);
      return;
    }

    globalErrors?.forEach((error) => {
      console.error('global error :>> ', error);
    });
  };

  return (
    <Page fullWidth={false}>
      <Page.Title>{'Create Pineapple'}</Page.Title>
      <Page.Body>
        {globalSuccessMessage && <Alert variant="success">{globalSuccessMessage}</Alert>}
        <Link to={pineapplePaths.list.getLink()}>Back to Pineapple List</Link>
        <Row className="py-2">
          <Col>
            <PineappleForm
              fieldErrors={fieldErrors}
              globalErrors={globalErrors}
              onSubmit={onSubmit}
              varieties={varieties}
            />
          </Col>
        </Row>
      </Page.Body>
    </Page>
  );
}
