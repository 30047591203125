import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';

function LiveServiceCard(props) {
  return (
    <>
      <Row>
        {props.service.startTime && (
          <Col xs={6}>
            <b>Stream Time:</b>{' '}
            {moment(parseInt(props.service.startTime))
              .tz(props.userTimeZone)
              .format(DATE_TIME_FORMAT)}
          </Col>
        )}
        {props.service.endTime && props.service.status === 'complete' && (
          <Col xs={6}>
            <b>Completed:</b>{' '}
            {moment(parseInt(props.service.completedAt))
              .tz(props.userTimeZone)
              .format(DATE_TIME_FORMAT)}
          </Col>
        )}
        {props.service.duration && (
          <Col xs={6}>
            <b>Duration:</b> {props.service.duration}
          </Col>
        )}
        {props.service.videoPlatform && (
          <Col xs={6}>
            <b>Platform:</b> {props.service.videoPlatform}
          </Col>
        )}
        <Col xs={12}>
          <button
            className={`${css(styles.serviceAction)} border-0 bg-transparent`}
            onClick={() => props.setShowModal.cheatSheet(true, props.service.type)}
            type="button"
          >
            Upload PDF Instructions
          </button>
        </Col>
      </Row>
    </>
  );
}

const styles = StyleSheet.create({
  serviceAction: {
    color: '#007EB5',
    cursor: 'pointer',
    fontWeight: 'bold',
    padding: '0',
  },
});

LiveServiceCard.propTypes = {
  service: PropTypes.object,
  userTimeZone: PropTypes.string,
  setShowModal: PropTypes.shape({
    cheatSheet: PropTypes.func,
  }),
};

export default LiveServiceCard;
