export const PAGES = {
  LANDING_PAGE: 'landing_page',
  NEW_APPLICATION: 'new_application',
  CONTINUE_APPLICATION: 'continue_application',
  NOT_ACCEPTING_NEW_APPLICATIONS: 'not_accepting_new_applications',
  NOT_ACCEPTING_LOCATION: 'not_accepting',
  USER_EMAIL_EXISTS: 'user_email_exists',
  ALREADY_SUBMITTED_APPLICATION: 'already_submitted',
  SUCCESSFULLY_SUBMITTED_APPLICATION: 'successfully_submitted',
  TEST: 'test',
};
