import React from 'react';
import PropTypes from 'prop-types';

const EventCancellationMessage = ({ cancelCaptionsWithCharge, eventName }) => {
  return cancelCaptionsWithCharge ? (
    <p>
      Cancellation of a professionally captioned event that has been matched to a captioner within
      24 hours of the Event Start will result in full charges for the event. Are you sure you want
      to cancel?
    </p>
  ) : (
    <p>
      Are you sure you want to delete captions for your upcoming event, <b>{eventName}</b>? Captions
      will no longer show up at the scheduled time for your event.
    </p>
  );
};

EventCancellationMessage.propTypes = {
  cancelCaptionsWithCharge: PropTypes.bool,
  eventName: PropTypes.string,
};

export default EventCancellationMessage;
