import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

import Table from 'react-bootstrap/Table';

function MultiServiceTable(props) {
  return (
    <Table size="sm">
      <thead>
        <tr>
          {props.headers.map((name, ii) => (
            <th key={ii} className={css(styles.th)}>
              {name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </Table>
  );
}

MultiServiceTable.propTypes = {
  children: PropTypes.node,
  headers: PropTypes.arrayOf(PropTypes.string),
};

const styles = StyleSheet.create({
  th: {
    fontWeight: 'bold',
  },
});

export default MultiServiceTable;
