import React, { Dispatch } from 'react';
import { Alert, Col, Form } from 'react-bootstrap';

import { Input } from '@threeplayground/index';
import { vpiViewPath } from '~/helpers/app/paths';

import { State, Action } from '../../../state/builderReducer';

interface AdditionalSettingsProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export function AdditionalBrightcoveSettings({ state, dispatch }: AdditionalSettingsProps) {
  const source = state.source;

  if (source.platform !== 'Brightcove') {
    return null;
  }

  return (
    <>
      <Form.Row>
        <Col>
          <Input
            label="Brightcove Account ID"
            name="bcAccount"
            size="small"
            withTypingState
            value={source.bcAccount}
            onFocus={(event) => event.currentTarget.select()}
            onUpdate={(bcAccount) =>
              dispatch({
                type: 'UPDATED_OTHER_SOURCE_ATTRIBUTES',
                source: { bcAccount: bcAccount ?? '' },
              })
            }
          />
        </Col>

        <Col>
          <Input
            label="Brightcove Player ID"
            name="bcPlayer"
            size="small"
            placeholder="default"
            withTypingState
            value={source.bcPlayer || ''}
            onFocus={(event) => event.currentTarget.select()}
            onUpdate={(bcPlayer) =>
              dispatch({ type: 'UPDATED_OTHER_SOURCE_ATTRIBUTES', source: { bcPlayer } })
            }
          />
        </Col>
      </Form.Row>

      {!source.bcPlayer && source.id !== 'custom' && (
        <Alert variant="warning">
          We are falling back to the default Brightcove player. You may want to adjust your{' '}
          <Alert.Link target="_blank" href={vpiViewPath(source.id)}>
            Brightcove integration settings
          </Alert.Link>{' '}
          to ensure the correct player is always used.
        </Alert>
      )}
    </>
  );
}
