import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import PopoverContent from 'react-bootstrap/PopoverContent';

import { StyleSheet, css } from 'aphrodite';
import Button from 'react-bootstrap/Button';
import { CHEATSHEET_FILE_BUTTON_INDEX } from '~/helpers/constants';

function CheatsheetUpload(props) {
  // 3145728 bytes = 3MB
  const maxSize = 3145728;

  const onDrop = useCallback(
    (acceptedCheatsheets, rejectedCheatsheet) => {
      if (acceptedCheatsheets.length > 0) {
        props.openStartCheatsheetUploadModal(acceptedCheatsheets, props.serviceType);
      }
      if (rejectedCheatsheet) {
        const rejected = rejectedCheatsheet.map((file) => {
          const isFileTooLarge = file.size > maxSize;
          return {
            name: file.path,
            size: file.size,
            fileTooLarge: isFileTooLarge,
          };
        });
        props.setRejectedCheatsheet(rejected);
      }
    },
    [props.cheatsheetToUpload, props.rejectedCheatsheet]
  );

  function uploadButton() {
    if (props.disabled) {
      return (
        <OverlayTrigger
          placement="auto"
          trigger={['hover', 'focus']}
          className={css(styles.removeText)}
          overlay={
            <Popover>
              <PopoverContent>
                Please remove text from the text box above in order to upload a PDF file
              </PopoverContent>
            </Popover>
          }
        >
          <div className={css(styles.dropzone)}>
            <div className={css(styles.centerText)}>
              <p>
                <b>or</b>
              </p>

              <Button variant="primary" disabled={true} tabIndex={CHEATSHEET_FILE_BUTTON_INDEX}>
                Choose File
              </Button>
              <p>
                <small className="text-muted">File must be smaller than 3MB</small>
              </p>
            </div>
          </div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div {...getRootProps({ className: css(styles.dropzone) })}>
          <input {...getInputProps()} />
          <div className={css(styles.centerText)}>
            <p>
              <b>or</b>
            </p>
            <Button variant="primary" tabIndex={CHEATSHEET_FILE_BUTTON_INDEX}>
              Choose File
            </Button>
            <p>
              <small className="text-muted">File must be smaller than 3MB</small>
            </p>
          </div>
        </div>
      );
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: '.pdf',
    minSize: 0,
    maxSize: maxSize,
    multiple: false,
  });

  return <>{uploadButton()}</>;
}

const styles = StyleSheet.create({
  dropzone: {
    border: '3px dashed #eee',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px 20px 10px',
    borderWidth: '2px',
    borderRadius: '2px',
    color: '#212529',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    ':focus': {
      borderColor: '#2196f3',
    },
  },
  removeText: {
    ':hover': {
      cursor: 'not-allowed',
    },
  },
  dragging: {
    opacity: 0.6,
  },
  centerText: {
    textAlign: 'center',
  },
});

CheatsheetUpload.propTypes = {
  cheatsheetToUpload: PropTypes.object,
  disabled: PropTypes.bool,
  openStartCheatsheetUploadModal: PropTypes.func,
  rejectedCheatsheet: PropTypes.arrayOf(PropTypes.object),
  removeFromCheatsheetUploadList: PropTypes.func,
  setRejectedCheatsheet: PropTypes.func,
  serviceType: PropTypes.string,
};

export default CheatsheetUpload;
