import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LanguageSelector from '../LanguageSelector';
import { threeplayApi } from '~/logic/ThreeplayApi';

import { primaryServiceDetailsQuery } from '~/components/app/order_form/data/queries';

function AsrOrderOptions(props) {
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const selectedLanguage = props.orderOptions?.language?.fullName || 'English';

  function setLanguage(languageName) {
    const language = availableLanguages.find((lang) => lang.fullName === languageName);
    props.updateOrderOptions('Asr', {
      ...props.orderOptions,
      language: {
        ids: [parseInt(language.id)],
        fullName: languageName,
      },
    });
  }

  useEffect(() => {
    threeplayApi.request(primaryServiceDetailsQuery, { selectedServiceType: 'Asr' }).then((res) => {
      const data = res.data || {};
      if (data.project?.orderableServices[0].orderOptions.languages) {
        const availableLang = data.project.orderableServices.find(
          (service) => service.serviceType == 'Asr'
        ).orderOptions.languages;
        setAvailableLanguages(availableLang);
        // set defaults
        const defaultLang = availableLang.find((lang) => lang.fullName === selectedLanguage);
        props.updateOrderOptions('Asr', {
          ...props.orderOptions,
          language: {
            ids: [parseInt(defaultLang.id)],
            fullName: defaultLang.fullName,
          },
        });
      }
    });
  }, []);

  return (
    availableLanguages && (
      <LanguageSelector
        {...{
          languages: availableLanguages,
          setLanguage,
          language: selectedLanguage,
        }}
      />
    )
  );
}

AsrOrderOptions.propTypes = {
  orderOptions: PropTypes.object,
  updateOrderOptions: PropTypes.func,
};

export default AsrOrderOptions;
