// Imports for payment account images
import braintree from './images/payment_accounts/braintree.png';
import cardAmex32 from './images/payment_accounts/card_amex_32.png';
import cardDiscover32 from './images/payment_accounts/card_discover_32.png';
import cardMc32 from './images/payment_accounts/card_mc_32.png';
import cardVisa32 from './images/payment_accounts/card_visa_32.png';
import mcard from './images/payment_accounts/mcard.png';
import verticalNav from './images/payment_accounts/vertical_nav.png';
import visa from './images/payment_accounts/visa.png';

// Imports for video platform images
import aspera from './images/video_platforms/aspera.png';
import blackboard from './images/video_platforms/blackboard.png';
import box from './images/video_platforms/box.png';
import brightcove from './images/video_platforms/brightcove.png';
import canvas from './images/video_platforms/canvas.png';
import canvasStudio from './images/video_platforms/canvas_studio.png';
import captionConductor from './images/video_platforms/caption_conductor.png';
import cvent from './images/video_platforms/cvent.png';
import dropbox from './images/video_platforms/dropbox.png';
import echo360Alp from './images/video_platforms/echo360_alp.png';
import ensemble from './images/video_platforms/ensemble.png';
import facebook from './images/video_platforms/facebook.png';
import googleDrive from './images/video_platforms/google_drive.png';
import jwPlatform from './images/video_platforms/jw_platform.png';
import kaltura from './images/video_platforms/kaltura.png';
import limelight from './images/video_platforms/limelight.png';
import mediaamp from './images/video_platforms/mediaamp.png';
import mediacore from './images/video_platforms/mediacore.png';
import mediasilo from './images/video_platforms/mediasilo.png';
import mediasite from './images/video_platforms/mediasite.png';
import movingimage from './images/video_platforms/movingimage.png';
import ooyala from './images/video_platforms/ooyala.png';
import panopto from './images/video_platforms/panopto.png';
import rtmp from './images/video_platforms/rtmp.png';
import techsmithKnowmia from './images/video_platforms/techsmith_knowmia.png';
import tegrity from './images/video_platforms/tegrity.png';
import theplatform from './images/video_platforms/theplatform.png';
import vidyard from './images/video_platforms/vidyard.png';
import vimeo from './images/video_platforms/vimeo.png';
import vimeoOtt from './images/video_platforms/vimeoott.png';
import voicethread from './images/video_platforms/voicethread.png';
import warpwire from './images/video_platforms/warpwire.png';
import wistia from './images/video_platforms/wistia.png';
import youtube from './images/video_platforms/youtube.png';
import yuja from './images/video_platforms/yuja.png';
import zoom from './images/video_platforms/zoom.png';

const paymentAccountImages = {
  braintree,
  card_amex_32: cardAmex32,
  card_discover_32: cardDiscover32,
  card_mc_32: cardMc32,
  card_visa_32: cardVisa32,
  mcard,
  vertical_nav: verticalNav,
  visa,
};

const videoPlatformImages = {
  aspera,
  blackboard,
  box,
  brightcove,
  canvas,
  canvas_studio: canvasStudio,
  caption_conductor: captionConductor,
  cvent,
  dropbox,
  echo360_alp: echo360Alp,
  ensemble,
  facebook,
  google_drive: googleDrive,
  jw_platform: jwPlatform,
  kaltura,
  limelight,
  mediaamp,
  mediacore,
  mediasilo,
  mediasite,
  movingimage,
  ooyala,
  panopto,
  rtmp,
  techsmith_knowmia: techsmithKnowmia,
  tegrity,
  theplatform,
  vidyard,
  vimeo,
  vimeoott: vimeoOtt,
  voicethread,
  warpwire,
  wistia,
  youtube,
  yuja,
  zoom,
};

export function ccLogoPath(accountName) {
  const name = accountName.toLowerCase();
  let logoPath = '';
  switch (name) {
    case 'mastercard':
    case 'master':
      logoPath = paymentAccountImages['card_mc_32'];
      break;
    case 'visa':
      logoPath = paymentAccountImages['card_visa_32'];
      break;
    case 'amex':
    case 'american express':
      logoPath = paymentAccountImages['card_amex_32'];
      break;
    case 'discover':
      logoPath = paymentAccountImages['card_discover_32'];
      break;
  }
  return logoPath;
}

export default function logoPath(platform) {
  return videoPlatformImages[platform.replace(/ /g, '_').toLowerCase()];
}
