import { Source, Platform } from '../types/AccessPlayerTypes';

export type Priority = 'highPriority' | 'lowPriority';

export function emptySourceForPlatform(platform: Platform): Source {
  switch (platform) {
    case 'Kaltura':
      return {
        platform: 'Kaltura',
        id: 'custom',
        displayName: 'Custom',
        mediaId: '',
        kaPartnerId: '',
        kaUiconfId: '',
      };
    case 'Brightcove':
      return {
        platform: 'Brightcove',
        id: 'custom',
        displayName: 'Custom',
        mediaId: '',
        bcAccount: '',
        bcPlayer: '',
      };
    case 'YouTube':
      return {
        platform: 'YouTube',
        id: 'custom',
        displayName: 'Custom',
        mediaId: '',
      };
    case 'Wistia':
      return {
        platform: 'Wistia',
        id: 'custom',
        displayName: 'Custom',
        mediaId: '',
        wistiaAccount: '',
        wistiaHash: '',
      };
    default:
      return {
        platform: 'native',
        id: 'custom',
        displayName: 'Custom',
        playAs: 'infer',
      };
  }
}

export type State = {
  priority: Priority;
  source: Source;
  loadingParsedURL: string | null;
  advancedSettingsVisible: boolean;

  grantedToken: string | null;
  loadingGrantedToken: boolean;

  showTranscript: boolean;
  showAd: boolean;
  showDownload: boolean;
  showLanguageDropdown: boolean;

  betaTermsAccepted: boolean;
};

export type Action =
  | { type: 'CHANGED_VERSION'; newValue: string }
  | { type: 'TOGGLED_ADVANCED_SETTINGS' }
  | { type: 'PICKED_CUSTOM_SOURCE' }
  | { type: 'PICKED_SOURCE'; source: Source }
  | { type: 'PICKED_PLATFORM'; platform: Platform }
  | { type: 'LOADING_PARSED_SOURCE'; url: string }
  | { type: 'FAILED_PARSED_SOURCE'; url: string }
  | { type: 'PARSED_SOURCE'; source: Source }
  | { type: 'UPDATED_MEDIA_ID'; mediaId: string }
  | { type: 'UPDATED_OTHER_SOURCE_ATTRIBUTES'; source: Partial<Source> }
  | { type: 'CHANGED_PRIORITY'; newValue: Priority }
  | { type: 'TOGGLED_TRANSCRIPT' }
  | { type: 'TOGGLED_TRANSCRIPT_DOWNLOAD' }
  | { type: 'TOGGLED_AUDIO_DESCRIPTION' }
  | { type: 'TOGGLED_LANGUAGE_DROPDOWN' }
  | { type: 'GRANTED_TOKEN'; token: string }
  | { type: 'LOADING_GRANTED_TOKEN' }
  | { type: 'FAILED_GRANTED_TOKEN' }
  | { type: 'TOGGLE_BETA_TERMS_ACCEPTANCE' };

export function builderReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'TOGGLED_ADVANCED_SETTINGS':
      return { ...state, advancedSettingsVisible: !state.advancedSettingsVisible };
    case 'PICKED_SOURCE':
      return { ...state, source: action.source, advancedSettingsVisible: false };
    case 'PICKED_CUSTOM_SOURCE':
      if (state.source.id === 'custom') {
        // don't lose state if we are already editing a custom source
        return state;
      }

      return { ...state, advancedSettingsVisible: true, source: emptySourceForPlatform('native') };
    case 'PICKED_PLATFORM':
      return { ...state, source: emptySourceForPlatform(action.platform) };
    case 'LOADING_PARSED_SOURCE':
      return { ...state, loadingParsedURL: action.url };
    case 'FAILED_PARSED_SOURCE':
      return {
        ...state,
        loadingParsedURL: null,
        source: {
          id: 'custom',
          displayName: 'Custom',
          platform: 'native',
          playback: action.url,
          playAs: 'infer',
        },
      };
    case 'PARSED_SOURCE':
      return { ...state, loadingParsedURL: null, source: action.source };
    case 'UPDATED_OTHER_SOURCE_ATTRIBUTES':
      return { ...state, source: { ...state.source, ...(action.source as typeof state.source) } };
    case 'UPDATED_MEDIA_ID':
      if (state.source.platform !== 'native') {
        return { ...state, source: { ...state.source, mediaId: action.mediaId } };
      }

      return state;
    case 'CHANGED_PRIORITY':
      return { ...state, priority: action.newValue };
    case 'TOGGLED_TRANSCRIPT':
      return { ...state, showTranscript: !state.showTranscript };
    case 'TOGGLED_TRANSCRIPT_DOWNLOAD':
      return { ...state, showDownload: !state.showDownload };
    case 'TOGGLED_AUDIO_DESCRIPTION':
      return { ...state, showAd: !state.showAd };
    case 'TOGGLED_LANGUAGE_DROPDOWN':
      return { ...state, showLanguageDropdown: !state.showLanguageDropdown };
    case 'LOADING_GRANTED_TOKEN':
      return { ...state, loadingGrantedToken: true };
    case 'FAILED_GRANTED_TOKEN':
      return { ...state, loadingGrantedToken: false };
    case 'GRANTED_TOKEN':
      return { ...state, loadingGrantedToken: false, grantedToken: action.token };
    case 'TOGGLE_BETA_TERMS_ACCEPTANCE':
      return { ...state, betaTermsAccepted: !state.betaTermsAccepted };
    default:
      return state;
  }
}
