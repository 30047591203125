import React from 'react';

export interface DownloadableAsset {
  url?: string;
  displayName: string;
}

export interface DownloadableAssetCellProps {
  assets: DownloadableAsset[];
}

export const DownloadableAssetCell = ({ assets }: DownloadableAssetCellProps) => (
  <div>
    {assets.map((asset, index) => (
      <div key={`div-${index}`}>
        <a key={asset.displayName} href={asset.url} target="_blank" rel="noreferrer">
          {asset.displayName}
        </a>
        <br key={index} />
      </div>
    ))}
  </div>
);
