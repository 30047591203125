export const pineapplePaths = {
  create: {
    getLink: () => '/pattern_library/pineapples/new',
    route: '/pattern_library/pineapples/new',
  },
  list: { getLink: () => '/pattern_library/pineapples', route: '/pattern_library/pineapples' },
  show: {
    getLink: (id) => `/pattern_library/pineapples/${id}`,
    route: '/pattern_library/pineapples/:id',
  },
};
