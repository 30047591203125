import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { applicantShape } from '../../shapes';
import { PreviousButton, NextButton } from '../ApplicationButtons';
import { hasFormErrors, isStringEmpty, startApplicationErrorsToPageRedirect } from '../../helpers';
import { validateEmail } from '../../../applicationWorkflow';

export const validate = ({ firstname, lastname, country, region, email }) => {
  const errors = {};

  if (isStringEmpty(firstname)) {
    errors.firstname = 'Please enter a value.';
  } else if (firstname.length > 50) {
    errors.firstname = 'First name is too long.';
  }

  if (isStringEmpty(lastname)) {
    errors.lastname = 'Please enter a value.';
  } else if (lastname.length > 50) {
    errors.lastname = 'Last name is too long.';
  }

  if (isStringEmpty(country)) {
    errors.country = 'Please enter a value.';
  }

  if (country === 'United States' && isStringEmpty(region)) {
    errors.region = 'Please enter a state.';
  }

  if (region?.length > 100) {
    errors.region = 'State/Province/Region is too long.';
  }

  const emailErrors = validateEmail(email);
  if (emailErrors) {
    errors.email = emailErrors;
  }

  return errors;
};

const BasicInformationForm = ({
  applicantInfo = {},
  countries,
  usStates,
  submitBasicInformation = () => {},
  redirectPage,
  nextStep,
  jobTypeId,
  languageId = null,
  referrerParams = {},
}) => {
  const [firstname, setFirstname] = useState(applicantInfo.firstname || '');
  const [lastname, setLastname] = useState(applicantInfo.lastname || '');
  const [email, setEmail] = useState(applicantInfo.email || '');
  const [country, setCountry] = useState(applicantInfo.country || '');
  const [region, setRegion] = useState(applicantInfo.region || '');
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const isApplicantCountryUSA = country === 'United States';

  const serializeApplicantData = () => ({
    firstname,
    lastname,
    email,
    country,
    region,
  });

  const serialize = () => ({
    ...serializeApplicantData(),
    jobTypeId,
    languageId,
    referrerParams,
  });

  const handleNextStep = () => {
    setLoading(true);
    const applicantData = serializeApplicantData();
    const data = serialize();
    const formErrors = validate(data);
    setFormErrors(formErrors);

    if (hasFormErrors(formErrors)) {
      return;
    }

    submitBasicInformation(data)
      .then(({ errors, data }) => {
        if (errors) {
          throw Error();
        }

        return data;
      })
      .then(({ startApplication: { success, error, authToken } }) => {
        setLoading(false);
        if (!success || error) {
          const page = startApplicationErrorsToPageRedirect(error);
          page ? redirectPage(page) : setError(true);
          return;
        }

        nextStep({ applicantInfo: applicantData, authToken });
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={`slide-up ${css(styles.div)}`}>
      {error && <p>Error</p>}
      <h2>Let&apos;s start with the basics.</h2>
      <p className={'mb-3'}>
        To get started with the application, please provide your full legal name.
      </p>
      <p className={'mb-3'}>
        <strong>Note:</strong> We do not support the use of VPNs &#40;Virtual Private Networks&#41;
        in our system. If we detect the use of a VPN at any time, we reserve the right to
        immediately terminate your account.
      </p>

      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="firstname">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              className={formErrors.firstname && 'is-invalid'}
            />
            {formErrors.firstname && (
              <Form.Text className="text-danger">{formErrors.firstname}</Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="lastname">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className={formErrors.lastname && 'is-invalid'}
            />
            {formErrors.lastname && (
              <Form.Text className="text-danger">{formErrors.lastname}</Form.Text>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={formErrors.email && 'is-invalid'}
          />
          {formErrors.email && <Form.Text className="text-danger">{formErrors.email}</Form.Text>}
          <Form.Text className="text-muted">You&apos;ll use this email to sign in!</Form.Text>
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="country">
            <Form.Label>Country</Form.Label>
            <Form.Control
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className={formErrors.country && 'is-invalid'}
              list="countries"
            />
            <datalist id="countries">
              {countries?.map((country, ii) => (
                <option key={ii} value={country}>
                  {country}
                </option>
              ))}
            </datalist>
            {formErrors.country && (
              <Form.Text className="text-danger">{formErrors.country}</Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="region">
            <Form.Label>State/Province/Region</Form.Label>
            <Form.Control
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              className={formErrors.region && 'is-invalid'}
              list={isApplicantCountryUSA ? 'states' : null}
            />
            {isApplicantCountryUSA && (
              <datalist id="states">
                {usStates.map((state, ii) => (
                  <option key={ii} value={state}>
                    {state}
                  </option>
                ))}
              </datalist>
            )}
            {formErrors.region && (
              <Form.Text className="text-danger">{formErrors.region}</Form.Text>
            )}
          </Form.Group>
        </Form.Row>
      </Form>
      <small>By continuing, you agree to allow us to store your information.</small>
      <div>
        <PreviousButton disabled={true} />
        <NextButton disabled={loading} onClick={handleNextStep} />
      </div>
    </div>
  );
};

BasicInformationForm.propTypes = {
  applicantInfo: applicantShape,
  countries: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  usStates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  nextStep: PropTypes.func,
  submitBasicInformation: PropTypes.func,
  redirectPage: PropTypes.func,
  jobTypeId: PropTypes.number,
  languageId: PropTypes.number,
  referrerParams: PropTypes.object,
};

const styles = StyleSheet.create({
  div: {
    maxWidth: '530px',
  },
});

export default BasicInformationForm;
