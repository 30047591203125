import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { unixToMMDDYY } from '~/components/jobs/utils/DateHelpers';
import ThreeplayTable from '~/components/ops/common/ThreeplayTable';

const formatPercentageComplete = (percentCompleted) => percentCompleted && `${percentCompleted}%`;

const shapeData = (data) =>
  data.map((referral) => {
    const applicant = referral?.referredApplicant;
    return {
      dateReferred: unixToMMDDYY(referral?.createdAt),
      name: applicant?.name,
      email: applicant?.email,
      percentCompleted: formatPercentageComplete(referral?.percentCompleted),
    };
  });

const ReferredApplicantsTable = ({ data = [] }) => {
  const tableData = useMemo(() => shapeData(data), []);
  const columns = useMemo(
    () => [
      { Header: 'Date Referred', accessor: 'dateReferred' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Email Address', accessor: 'email' },
      { Header: 'Completion %', accessor: 'percentCompleted' },
    ],
    []
  );

  return <ThreeplayTable data={tableData} columns={columns} disableSortBy />;
};

ReferredApplicantsTable.propTypes = {
  data: PropTypes.array,
};

export default ReferredApplicantsTable;
