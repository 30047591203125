import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

function TokenListPaginator(props) {
  // New token(current page) is always added at the end of the list
  // Prev page token is always the 2nd last in the list
  function getTokenPaginationData() {
    const secondLastTokenIndex = props.integration.paginationDetails.pageTokenList.length - 2;
    const tokenListPresent = props.integration.paginationDetails.pageTokenList.length > 0;
    const prevPageToken = tokenListPresent
      ? props.integration.paginationDetails.pageTokenList[secondLastTokenIndex] || ''
      : '';
    const prevPageTokenList = tokenListPresent
      ? props.integration.paginationDetails.pageTokenList.slice(0, secondLastTokenIndex)
      : [];
    const nextPageTokenList = props.integration.paginationDetails.pageTokenList.concat(
      props.integration.paginationDetails.nextPage
    );
    return {
      prevPageToken,
      prevPageTokenList,
      nextPageTokenList,
    };
  }

  function goToPage(pageToken, pageTokenList) {
    props.setPageTokenList(pageTokenList);
    props.setPageToken(pageToken);
  }

  const { prevPageToken, prevPageTokenList, nextPageTokenList } = getTokenPaginationData();
  return (
    <ButtonGroup className="mr-2" aria-label="Pagination button group">
      <Button
        aria-label="Previous Page"
        className="m-1"
        disabled={props.integration.paginationDetails.pageTokenList.length === 0}
        onClick={() => goToPage(prevPageToken, prevPageTokenList)}
        variant="outline-primary"
      >
        <i className="fa fa-angle-double-left"></i>
      </Button>
      <Button
        aria-label="Next Page"
        className="m-1"
        disabled={props.integration.paginationDetails.nextPage === null}
        onClick={() => goToPage(props.integration.paginationDetails.nextPage, nextPageTokenList)}
        variant="outline-primary"
      >
        <i className="fa fa-angle-double-right"></i>
      </Button>
    </ButtonGroup>
  );
}

TokenListPaginator.propTypes = {
  integration: PropTypes.object,
  setPageToken: PropTypes.func,
  setPageTokenList: PropTypes.func,
};

export default TokenListPaginator;
