import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Card, Form, Table } from 'react-bootstrap';
import OrderMore from '~/components/app/order_more/OrderMore';
import { SCRIPTING_OPTIONS_START_INDEX } from '~/helpers/constants';

const BroadcastScriptsAlert = ({ warningSelected, setWarningSelected }) => {
  return (
    <Alert className="mt-2" variant="warning">
      <Alert.Heading>Broadcast Scripts cannot be Refunded</Alert.Heading>
      <Form.Group controlId="broadcastScriptWarning">
        <Form.Check
          checked={warningSelected}
          className="d-inline-block"
          onChange={() => setWarningSelected((currState) => !currState)}
          type="checkbox"
        />
        By checking this box, you indicate that you will not have the opportunity to review your
        transcripts before they are submitted for Broadcast Scripts. Broadcast Scripts will begin as
        soon as the main service is completed.
      </Form.Group>
    </Alert>
  );
};

BroadcastScriptsAlert.propTypes = {
  warningSelected: PropTypes.bool.isRequired,
  setWarningSelected: PropTypes.func.isRequired,
};

const BroadcastScriptsOrderSummary = ({ selectedFormats }) => {
  return (
    <div>
      <b>Services Ordered</b>
      <ul>
        {selectedFormats.map((format) => (
          <li key={format.code}>{format.name}</li>
        ))}
      </ul>
    </div>
  );
};

BroadcastScriptsOrderSummary.propTypes = {
  selectedFormats: PropTypes.arrayOf(PropTypes.any),
};

const BroadcastScriptsTable = ({ formats, selectedFormats, setSelectedFormats }) => {
  const updateSelectedFormats = (formatCode) => {
    const format = formats.find((format) => format.code === formatCode);
    const inList = selectedFormats.some((format) => format.code === formatCode);

    if (inList) {
      setSelectedFormats((currFormats) => currFormats.filter((f) => f.code !== format.code));
    } else {
      setSelectedFormats((currFormats) => [...currFormats, format]);
    }
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th></th>
          <th>Format</th>
          <th>Description</th>
          <th>Business Days</th>
          <th>Price/min</th>
        </tr>
      </thead>
      <tbody>
        {formats?.map((option, index) => (
          <tr key={option.name}>
            <td>
              <input
                type="checkbox"
                tabIndex={index + SCRIPTING_OPTIONS_START_INDEX}
                disabled={option.disabled}
                name={option.code}
                value={option.code}
                checked={selectedFormats.map((format) => format.code).includes(option.code)}
                onChange={(e) => updateSelectedFormats(e.target.value)}
              />
            </td>
            <td>{option.name}</td>
            <td>{option.description}</td>
            <td>{option.turnaround_hours / 24} days</td>
            <td>{option.display_price}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BroadcastScriptsTable.propTypes = {
  formats: PropTypes.arrayOf(PropTypes.any),
  selectedFormats: PropTypes.arrayOf(PropTypes.any),
  setSelectedFormats: PropTypes.func.isRequired,
};

const BroadcastScriptsTipPanel = () => {
  return (
    <>
      <strong>To order Broadcast Scripts:</strong>
      <p>
        3Play Media offers transcripts in a variety of post-production formats, which include both
        basic audio scripts reflecting dialogue only, or as complex post-production scripts that
        include not only dialogue but also sound effects, speaker identification, scene headings,
        continuity, on-screen graphics, timestamps, and more. Broadcast Scripts can only be
        completed on English source transcripts at this time.
      </p>
    </>
  );
};

const BroadcastScriptsOrderMore = ({ authToken, formats, mediaFiles, submissionUrl }) => {
  const [selectedFormats, setSelectedFormats] = useState([]);
  const [warningSelected, setWarningSelected] = useState(false);

  const onSubmit = ({ setErrors, setSuccess }) => {
    const formData = new FormData();
    formData.append('media_file_ids', mediaFiles.map((file) => file.id).join(','));
    formData.append('format_codes', selectedFormats.map((format) => format.code).join(','));

    return fetch(submissionUrl, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-TOKEN': authToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccess(true);
        } else {
          setErrors((currErrors) => [
            ...currErrors,
            'There was an error submitting your order. Please try again.',
          ]);
        }
      });
  };

  return (
    <OrderMore
      mainContent={
        <>
          <Card.Title>Choose Broadcast Script Formats</Card.Title>
          <BroadcastScriptsTable
            formats={formats}
            selectedFormats={selectedFormats}
            setSelectedFormats={setSelectedFormats}
          />
          <BroadcastScriptsAlert
            warningSelected={warningSelected}
            setWarningSelected={setWarningSelected}
          />
        </>
      }
      onSubmit={onSubmit}
      orderType="Broadcast Scripts"
      submissionDisabled={selectedFormats.length === 0 || !warningSelected}
      summaryContent={<BroadcastScriptsOrderSummary selectedFormats={selectedFormats} />}
      tipPanelContent={<BroadcastScriptsTipPanel />}
    />
  );
};

BroadcastScriptsOrderMore.propTypes = {
  authToken: PropTypes.string,
  formats: PropTypes.arrayOf(PropTypes.any),
  mediaFiles: PropTypes.arrayOf(PropTypes.any),
  submissionUrl: PropTypes.string,
};

export default BroadcastScriptsOrderMore;
