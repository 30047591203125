import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';

function AlertMessage(props) {
  const [show, setShow] = useState(true);

  return (
    show && (
      <Alert dismissible onClose={() => setShow(false)} variant={props.variant}>
        {props.message}
      </Alert>
    )
  );
}

AlertMessage.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
};

export default AlertMessage;
