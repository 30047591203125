import { RecognizerResult, ResultData } from './RecognizerResult';
import { TimestampInMs } from '../_types';

/** Consistent data from the recognized result */
type RecognizedResultData = ResultData & {
  /** The confidence of the recognized result */
  confidence?: number;
};

/**
 * Class representing a speech recognizer "recognized" result
 * @extends RecognizerResult
 */
export class RecognizedResult extends RecognizerResult {
  /**
   * Create a recognized result
   * @param {TimestampInMs} start When the speech recognizer was started (timestamp in ms)
   * @param {RecognizedResultData} resultData The data for the result from the speech recognizer
   */
  constructor(start: TimestampInMs, resultData: RecognizedResultData) {
    super(start, resultData);
    this.result_type = 'recognized';
  }
}
