import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChallengeBadge from '../ChallengeBadge';
import { acceptChallengeMutation } from '../services/mutations';
import { DEFAULT_COPY } from '../constants';
import { threeplayApi } from '~/components/jobs/common/threeplayApi';

const acceptChallenge = (challengeId = null) => {
  return threeplayApi.json(acceptChallengeMutation, { challengeId });
};

const NewChallengeModal = ({
  challenge = {},
  show = false,
  onClose = () => {},
  markChallengeAsAccepted = () => {},
  onAcceptChallenge = acceptChallenge,
}) => {
  const [isChallengeAccepted, setIsChallengeAccepted] = useState(false);
  const [errors, setErrors] = useState(false);

  const handleAcceptChallenge = () => {
    onAcceptChallenge(challenge.id)
      .then((r) => {
        const { errors, success } = r.data.challengeAccepted
          ? r.data.challengeAccepted
          : { errors: [], success: false };

        if (errors.length || !success) {
          throw new Error();
        }

        setIsChallengeAccepted(true);
      })
      .catch(() => setErrors(true));
  };

  const handleClose = () => {
    onClose();

    if (isChallengeAccepted) {
      setIsChallengeAccepted(false);
      markChallengeAsAccepted(challenge.id);
    }
  };

  return (
    <Modal size="lg" data-testid="active-challenge-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton onHide={handleClose}>
        <Modal.Title>New Challenge Available!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isChallengeAccepted && (
          <Alert variant={'success'}>
            You&apos;ve successfully accepted this challenge -- good luck!
          </Alert>
        )}
        {errors && <Alert variant={'danger'}>error</Alert>}
        <Row>
          <Col>
            <ChallengeBadge />
          </Col>
          <Col className={'col-8'}>
            <>
              <h5>{challenge.name}</h5>
              <p>
                {challenge.description
                  ? challenge.description
                  : DEFAULT_COPY.noChallengeDescription}
              </p>
              {!isChallengeAccepted ? (
                <>
                  <Button onClick={handleAcceptChallenge} className={css(styles.uppercase)}>
                    Accept the challenge
                  </Button>
                </>
              ) : (
                <>
                  <p className={css(styles.challengeAccepted)}>
                    <i className={'fa fa-check-circle'} /> <b>Challenge Accepted</b>
                  </p>
                  <Button onClick={handleClose}>Back to Challenges</Button>
                </>
              )}
            </>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

NewChallengeModal.propTypes = {
  challenge: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  markChallengeAsAccepted: PropTypes.func,
  onAcceptChallenge: PropTypes.func,
};

const styles = StyleSheet.create({
  uppercase: {
    textTransform: 'uppercase',
  },
  challengeAccepted: {
    fontSize: '1.2em',
    color: '#067A00',
  },
});

export default NewChallengeModal;
