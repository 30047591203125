import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { userLogger } from '~/logic/UserLogger';

import WithIcon from '~/components/app/common/WithIcon';

function TabContainer(props) {
  function activateTab(whichTab) {
    props.onChangeTab(whichTab);
  }

  function tabClass(tab) {
    if (tab === props.activeTab) {
      return css(styles.activeTab);
    } else {
      return css(styles.inactiveTab);
    }
  }

  return (
    <div className={css(styles.tabContainer)}>
      <button
        className={tabClass('overview')}
        onClick={() => {
          activateTab('overview');
          userLogger.logEvent('FileShow', 'Change Tab', { Tab: 'Overview' });
        }}
      >
        <WithIcon icon="fa fa-tachometer">Overview</WithIcon>
      </button>
      <button
        className={tabClass('details')}
        onClick={() => {
          activateTab('details');
          userLogger.logEvent('FileShow', 'Change Tab', { Tab: 'Details' });
        }}
      >
        <WithIcon icon="fa fa-info-circle">Details</WithIcon>
      </button>
    </div>
  );
}

TabContainer.propTypes = {
  activeTab: PropTypes.oneOf(['overview', 'details']),
  onChangeTab: PropTypes.func,
};

const styles = StyleSheet.create({
  tabContainer: {
    width: '100%',
    height: '32px',
    fontSize: '15px',
    fontWeight: 'bold',
    flex: '0 0 auto',
    zIndex: '100',
  },
  activeTab: {
    backgroundColor: '#EFF7FA',
    color: '#273469',
    width: '50%',
    height: '100%',
    display: 'inline-block',
    border: 'none',
    borderBottom: '4px solid #202435',
    textAlign: 'center',
  },
  inactiveTab: {
    backgroundColor: '#FFFFFF',
    color: '#888888',
    width: '50%',
    height: '100%',
    display: 'inline-block',
    border: 'none',
    borderBottom: '2px solid #BDBDBD',
    textAlign: 'center',
    ':hover': {
      cursor: 'pointer',
    },
  },
});

export default TabContainer;
