import PropTypes from 'prop-types';

export const serviceDataPropSpec = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
});

export const cheatSheetPropSpec = PropTypes.shape({
  content: PropTypes.string,
  pdf: PropTypes.string,
});

export const mediaFileIntegrationPropSpec = PropTypes.shape({
  externalId: PropTypes.string,
  name: PropTypes.string,
  videoPlatformIntegrationId: PropTypes.string,
});

export const fileDataPropSpec = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  duration: PropTypes.number,
  services: PropTypes.arrayOf(serviceDataPropSpec),
  cheatSheet: cheatSheetPropSpec,
  mediaFileIntegrations: PropTypes.arrayOf(mediaFileIntegrationPropSpec),
});

export const transcriptListSpec = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    language: PropTypes.shape({
      name: PropTypes.string,
    }),
  })
);

const timedStringPropType = PropTypes.shape({
  time: PropTypes.number,
  string: PropTypes.string,
});

export const transcriptSpec = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  language: PropTypes.shape({
    name: PropTypes.string,
  }),
  speakers: PropTypes.arrayOf(timedStringPropType),
  paragraphs: PropTypes.arrayOf(PropTypes.number),
  formattedTranscript: PropTypes.string,
});

export const turnaroundLevelSpec = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  deliveryDate: PropTypes.string,
  surcharge: PropTypes.number,
});

export const defaultFileData = {
  name: 'Loading file...',
  duration: 0,
  services: [],
};
