import React from 'react';
import PropTypes from 'prop-types';

import TakeMeToThreeplayButton from './TakeMeToThreeplayButton';
import Rejected from './images/rejected.png';

const OPERATIONS_EMAIL_ADDRESS = 'ops_managers@3playmedia.com';
const SUBJECT = 'Contractor Application: Duplicate User Email — Assistance Required';

export const generateBody = ({ firstname, lastname, email }) => `Dear 3Play Operations,

I was directed to reach out from the contractor application.
It looks like my email is associated with an existing user account.  Can you help me resolve this issue?

I applied with this email: ${email}.

Best,
${firstname} ${lastname}`;

const PleaseEmailOps = ({ firstname = '', lastname = '', email = '' }) => {
  const emailBody = generateBody({ firstname, lastname, email });
  const emailLink = `mailto:${OPERATIONS_EMAIL_ADDRESS}?subject=${encodeURIComponent(
    SUBJECT
  )}&body=${encodeURIComponent(emailBody)}`;

  return (
    <div className="d-flex flex-column align-items-center justy-content-center">
      <img className="main-image mb-4" src={Rejected}></img>
      <div className="middle-lane text-center">
        <h1>Please Contact 3Play Operations!</h1>
        <p className="primary-text">
          Your email is associated with an existing user account. Please email{' '}
          <a href={emailLink} rel="noreferrer noopener" target="_blank">
            3Play Operations
          </a>{' '}
          to resolve the issue.
        </p>
        <TakeMeToThreeplayButton />
      </div>
    </div>
  );
};

PleaseEmailOps.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
};

export default PleaseEmailOps;
