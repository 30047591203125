import React, {
  ReactNode,
  useCallback,
  useContext,
  useState,
  FocusEvent,
  FormEvent,
  ChangeEvent,
  FocusEventHandler,
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
} from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import { useUniqueId } from '../../utilities/useUniqueId';

import { ErrorsContext, GraphQLOrCustomError } from '../Form/ErrorsContext';
import { InputDecorations, InputDecorationsList } from './InputDecorations';

const sizeMapping = {
  small: 'sm',
  medium: undefined, // bootstrap doesn't have a named variant for medium, must be undefined to map correctly
  large: 'lg',
} as const;

type WrappableElement = HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement;

interface WrappedInputProps<Element extends WrappableElement> {
  'aria-describedby'?: string;
  form?: string;
  formNoValidate?: boolean;
  className?: string;
  isInvalid?: boolean;
  name?: string;
  onClick?: MouseEventHandler<Element>;
  onChange?: ChangeEventHandler<Element>;
  onInvalid?: FormEventHandler<Element>;
  onFocus?: FocusEventHandler<Element>;
  onBlur?: FocusEventHandler<Element>;
}

export interface InputWrapperProps<Element extends WrappableElement> {
  label: string;
  hideLabel?: boolean;
  name?: string;
  error?: ReactNode | GraphQLOrCustomError;
  help?: ReactNode;
  className?: string;
  id?: string;

  /** @default 'medium' */
  size?: 'small' | 'medium' | 'large';

  /**
   * By default, form group elements have margin. Sometimes this is annoying.
   * This is a convenience for a common `m-0` utility class.
   *
   * @default false
   */
  noMargin?: boolean;

  /**
   * Allows for decorations as a string, string[], or a renderProp delegate
   * returning a ReactNode. Decorations components are passed to the delegate,
   * including:
   *
   * `Button`, `Icon`, `Dropdown`, and `Text`.
   *
   * @see `InputDecoration.tsx` for types
   */
  prepend?: InputDecorations;

  /**
   * Allows for decorations as a string, string[], or a renderProp delegate
   * returning a ReactNode. Decorations components are passed to the delegate,
   * including:
   *
   * `Button`, `Icon`, `Dropdown`, and `Text`.
   *
   * @see `InputDecoration.tsx` for types
   */
  append?: InputDecorations;

  /**
   * Specify when validation takes effect. Within a Form comopnent, this
   * defaults to `onSubmit`, otherwise defaults to `onBlurAndSubmit.
   * You should usually omit this prop to opt into default behavior for a
   * consistent UX.
   *
   * @see ErrorsContext.ts, Form.tsx
   *
   * @default 'onSubmit'
   */
  revalidateMode?: 'onBlurAndSubmit' | 'onSubmit';

  /**
   * Allows associating an input with a form.
   * Useful for odd UI cases where Inputs aren't nested directly within a
   * native form.
   */
  form?: string;

  formNoValidate?: boolean;

  // TODO are these types good enough for the validation API?
  onClick?: MouseEventHandler<Element>;
  onChange?: ChangeEventHandler<Element>;
  onFocus?: FocusEventHandler<Element>;
  onBlur?: FocusEventHandler<Element>;

  /**
   * Adds description or hint text below the label and above the input and links
   * to the input via aria-describedby.
   *
   * This follows Adam Silver's Form Design Patterns and puts the hint text above the input to:
   * - make sure it's read by screen readers
   * - create a larger touch target on mobile
   * - prevent it from being obscured by auto-complete panels and on-screen keyboards
   */
  descriptionText?: ReactNode;
}

/**
 * We define a separate object for prosp that are only meant to be added by
 * the component-library components so that implementing components can easily
 * forward InputWrapperProps as a public interface.
 */
export interface InternalWrapperProps<Element extends WrappableElement> {
  /**
   * This is called as a plain old function, NOT via createElement.
   * Don't pass a function with hooks here (you can nest another
   * element if you need hooks.)
   */
  children: (props: WrappedInputProps<Element>) => ReactNode;

  /** Delegated validation function to allow for validation on native behavior */
  onValidate?: (target: Element) => string | null;
}

function isErrorObject(error: unknown): error is GraphQLOrCustomError {
  return error !== null && typeof error === 'object' && 'message' in error;
}

function defaultComputeValidationError(
  target: HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement
) {
  // We rely on the native validity API browsers provide.
  // This is feature-filled, well-supported, and localized by default.
  // https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
  return target.validationMessage || null;
}

/**
 * This component is used for shared layout/features on
 * `Input`, `Select`, `Textarea`, and future "text-like" Form components.
 *
 * It has a shared functionality for:
 * - Label rendering (and hiding visual labels in a screenreader-friendly way)
 * - Error and help text rendering
 * - Automatic ID setup for a11y on labels, errors, help text
 * - Support for decorations (Icons, Buttons, Dropdowns, etc) before and after the Input
 * - Hooking into the native validation flow for easy, native hookup with the
 *   Form component.
 */
export function InputWrapper<Element extends WrappableElement>(
  props: InputWrapperProps<Element> & InternalWrapperProps<Element>
) {
  const {
    children,
    descriptionText,
    label,
    hideLabel,
    id,
    className,
    name,
    noMargin,
    form,
    formNoValidate,
    onChange,
    onClick,
    onFocus,
    onBlur,
    onValidate = defaultComputeValidationError,
    prepend = [],
    append = [],
    size = 'medium',
    error,
    revalidateMode: parentRevalidateMode,
  } = props;

  const { errors: graphQLErrors, revalidateMode: formRevalidateMode } = useContext(ErrorsContext);
  const [validationError, setValidationError] = useState<string | null>(null);
  const uniqueId = useUniqueId(id);

  const revalidateMode = parentRevalidateMode || formRevalidateMode; // defaults to onBlur if not in a form

  const parentError = isErrorObject(error) ? error.message : error;
  const graphQLError = graphQLErrors[name || ''];
  const hasError = Boolean(parentError || graphQLError || validationError);

  // Our standard behavior clears out validation errors on change, regardless
  // of when they were triggered.
  const handleChange = useCallback(
    (event: ChangeEvent<Element>) => {
      if (validationError) {
        setValidationError(null);
      }
      onChange && onChange(event);
    },
    [onChange, validationError, setValidationError]
  );

  // We set errors on form submission in both modes.
  const handleInvalid = useCallback(
    (event: FormEvent<Element>) => {
      event.preventDefault(); // prevent browser popups from appear
      setValidationError(onValidate(event.currentTarget));
    },
    [setValidationError, onValidate]
  );

  // If we are in `onBlurAndSubmit` mode, we also set errors on blur.
  const handleBlur = useCallback(
    (event: FocusEvent<Element>) => {
      if (revalidateMode === 'onBlurAndSubmit') {
        setValidationError(onValidate(event.currentTarget));
      }
      onBlur && onBlur(event);
    },

    [onBlur, revalidateMode, setValidationError, onValidate]
  );

  return (
    <Form.Group controlId={uniqueId} className={classNames({ 'm-0': noMargin }, className)}>
      <Form.Label className={descriptionText ? 'mb-0' : ''} srOnly={hideLabel}>
        {label}
      </Form.Label>
      {descriptionText && (
        <Form.Text className="mt-0 mb-2" id={`${uniqueId}-description`}>
          {descriptionText}
        </Form.Text>
      )}
      <InputGroup size={sizeMapping[size]} hasValidation>
        <InputDecorationsList direction="prepend" decorations={prepend} />

        {children({
          // id: uniqueId, // Omitting for now, as `controlId` handles this
          className: parentError || graphQLError || validationError ? 'is-invalid' : '',
          isInvalid: hasError,
          onChange: handleChange,
          onInvalid: handleInvalid,
          onBlur: handleBlur,
          onFocus,
          onClick,
          name,
          form,
          formNoValidate,
          ...(descriptionText && { 'aria-describedby': `${uniqueId}-description` }),
          ...(hasError && {
            'aria-invalid': true,
            'aria-errormessage': `${uniqueId}-errormessage`,
            // aria-errormessage is not fully supported, so we switch the describedby
            // to the error message when there is an error
            'aria-describedby': `${uniqueId}-errormessage`,
          }),
        })}

        <InputDecorationsList direction="append" decorations={append} />
      </InputGroup>
      {/* 
        Bootstrap doesn't seem to want to show this element, even when in a form / using invalid classes.
        We force it with a display: block.
      */}
      <Form.Control.Feedback
        id={`${uniqueId}-errormessage`}
        type="invalid"
        className="is-invalid"
        style={{ display: 'block' }}
      >
        {parentError || graphQLError?.message || validationError}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

InputWrapper.displayName = 'InputWrapper';
