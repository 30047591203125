import React, { Dispatch } from 'react';
import { Collapse, Form } from 'react-bootstrap';

import { State, Action } from '../../state/builderReducer';

import AD from '../../icons/AD.svg';
import Transcript from '../../icons/Transcript.svg';

import css from './PlayerSettings.module.css';

interface PlayerSettingsProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export function PlayerSettings({ state, dispatch }: PlayerSettingsProps) {
  return (
    <>
      <h6>Player Settings</h6>

      <div className={css.checkGrid}>
        <label className="m-0" htmlFor="interactive-transcript">
          <img className={css.icon} src={Transcript} alt="Transcript Icon" />
        </label>

        <Form.Check
          className={css.primaryCheck}
          id="interactive-transcript"
          type="switch"
          label="Interactive Transcript"
          checked={state.showTranscript}
          onChange={() => dispatch({ type: 'TOGGLED_TRANSCRIPT' })}
        />

        <div className={css.secondaryChecks}>
          <Collapse in={state.showTranscript}>
            <div>
              <Form.Check
                id="downloadable-transcript"
                type="switch"
                label="Downloadable Transcript"
                disabled={!state.showTranscript}
                checked={state.showDownload}
                onChange={() => dispatch({ type: 'TOGGLED_TRANSCRIPT_DOWNLOAD' })}
              />
              {/* Reenable once product wants to expose this premium feature */}
              {/* <Form.Check
                id="show-language-dropdown"
                type="switch"
                label="Show Language dropdown (when translations are available)"
                disabled={!state.showTranscript}
                onChange={() => dispatch({ type: 'TOGGLED_LANGUAGE_DROPDOWN' })}
              /> */}
            </div>
          </Collapse>
        </div>
      </div>

      <div className={css.checkGrid}>
        <label className="m-0" htmlFor="audio-description">
          <img className={css.icon} src={AD} alt="Audio Description Icon" />
        </label>

        <Form.Check
          className={css.primaryCheck}
          id="audio-description"
          type="switch"
          label="Audio Description"
          checked={state.showAd}
          onChange={() => dispatch({ type: 'TOGGLED_AUDIO_DESCRIPTION' })}
        />
      </div>
    </>
  );
}
