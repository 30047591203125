import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Card } from 'react-bootstrap';

import { appStyles } from '~/components/app/common/styles';
import OrderMore from '~/components/app/order_more/OrderMore';
import OutputAssetSelector from '~/components/app/order_more/components/OutputAssetSelector';
import OutputAssetShape from '~/components/app/order_more/components/OutputAssetShape';
import OutputAssetSummary from '~/components/app/order_more/components/OutputAssetSummary';
import selectedStyles from '~/components/app/styles/selectedDeselectedStyles';

const ServiceLevelSelector = ({ serviceLevels, selectedServiceLevel, setSelectedServiceLevel }) => {
  return (
    <>
      <Card.Title>Service Level</Card.Title>
      <div className="d-flex">
        {serviceLevels.map((level) => (
          <Card
            key={level.id}
            bg="light"
            className={
              selectedServiceLevel.id === level.id
                ? css(selectedStyles.selected, styles.box)
                : css(selectedStyles.deselected, styles.box)
            }
            onClick={() => setSelectedServiceLevel(level)}
          >
            <Card.Body>
              <div className={css(styles.bold)}>
                <span className={css(styles.left)}>{level.name}</span>
                <span className={css(styles.right)}>{level.voiceArtistDisplayPrice}</span>
              </div>
              <div className={css(styles.description)}>{level.description}</div>
            </Card.Body>
          </Card>
        ))}
      </div>
    </>
  );
};

const serviceLevelShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  voiceArtistDisplayPrice: PropTypes.string,
  description: PropTypes.string,
});

ServiceLevelSelector.propTypes = {
  selectedServiceLevel: serviceLevelShape,
  serviceLevels: PropTypes.arrayOf(serviceLevelShape),
  setSelectedServiceLevel: PropTypes.func,
};

const TurnaroundLevelSelector = ({
  selectedTurnaroundLevel,
  setSelectedTurnaroundLevel,
  turnaroundLevels,
}) => {
  return (
    <div className={css(appStyles.sectionPadding)}>
      <Card.Title>Turnaround Level</Card.Title>
      <div className="d-flex">
        {turnaroundLevels.map((level) => (
          <Card
            key={level.id}
            bg="light"
            className={
              selectedTurnaroundLevel.id === level.id
                ? css(selectedStyles.selected, styles.box, styles.center, styles.turnaround)
                : css(selectedStyles.deselected, styles.box, styles.center, styles.turnaround)
            }
            onClick={() => setSelectedTurnaroundLevel(level)}
          >
            <Card.Body>
              <div className={css(styles.bold)}>{level.displayName}</div>
              <div>{level.deadline}</div>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

const turnaroundLevelShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  displayName: PropTypes.string,
  deadline: PropTypes.string,
  surcharge: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.string,
  }),
});

TurnaroundLevelSelector.propTypes = {
  selectedTurnaroundLevel: turnaroundLevelShape,
  setSelectedTurnaroundLevel: PropTypes.func,
  turnaroundLevels: PropTypes.arrayOf(turnaroundLevelShape),
};

const VoiceArtistAudioDescriptionTipPanel = () => {
  return (
    <p>
      3Play Media offers Audio Descriptions to narrate relevant visual information in a video to
      accommodate blind and low-vision viewers. Voice Artist Audio Description utilizes professional
      describers to create a written description for your file. Then our voice artist talent is used
      to voice the audio descriptions in the audio and video outputs. Voice Artists are trained
      narrators who record the voiceover of the written descriptions.
    </p>
  );
};

const VoiceArtistAudioDescriptionOrderMore = ({
  authToken,
  canOrderMixing,
  mediaFiles,
  mixingOptions,
  serviceLevels,
  turnaroundLevels,
  submissionUrl,
}) => {
  const [selectedServiceLevel, setSelectedServiceLevel] = useState(serviceLevels[0]);
  const [selectedTurnaroundLevel, setSelectedTurnaroundLevel] = useState(turnaroundLevels[0]);
  const [selectedOutputAssets, setSelectedOutputAssets] = useState([]);
  const [filteredMixingOptions, setFilteredMixingOptions] = useState(mixingOptions);

  // Filter out five_one mixingOption if the selectedServiceLevel is "Extended" or "Choose for Me"
  useEffect(() => {
    if (selectedServiceLevel.name != 'Standard') {
      setFilteredMixingOptions((mixingOptions) => {
        return {
          embed: mixingOptions.embed,
          mix: mixingOptions.mix.filter((mix) => mix.code !== 'fiveOne'),
        };
      });
      setSelectedOutputAssets((selectedOutputAssets) => {
        return selectedOutputAssets.filter((asset) => asset.code !== 'fiveOne');
      });
    } else {
      setFilteredMixingOptions(mixingOptions);
    }
  }, [mixingOptions, selectedServiceLevel]);

  const canSubmit = () => {
    if (!canOrderMixing) {
      return true;
    }
    return (
      selectedOutputAssets.length > 0 && selectedOutputAssets.every((mix) => mix.embeds.length > 0)
    );
  };

  const onSubmit = ({ setErrors, setSuccess }) => {
    const outputAssets = selectedOutputAssets.map((asset) => {
      return {
        code: asset.code,
        embeds: asset.embeds.map((e) => e.code),
      };
    });

    const formData = new FormData();
    formData.append('media_file_ids', mediaFiles.map((file) => file.id).join(','));
    formData.append('service_level', selectedServiceLevel.name);
    formData.append('turnaround_level_id', selectedTurnaroundLevel.id);
    formData.append('output_assets', JSON.stringify(outputAssets));

    return fetch(submissionUrl, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-TOKEN': authToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccess(true);
        } else {
          setErrors((currErrors) => [
            ...currErrors,
            'There was an error submitting your order. Please try again.',
          ]);
        }
      });
  };

  return (
    <OrderMore
      mainContent={
        <>
          <ServiceLevelSelector
            serviceLevels={serviceLevels}
            selectedServiceLevel={selectedServiceLevel}
            setSelectedServiceLevel={setSelectedServiceLevel}
          />
          <TurnaroundLevelSelector
            selectedTurnaroundLevel={selectedTurnaroundLevel}
            setSelectedTurnaroundLevel={setSelectedTurnaroundLevel}
            turnaroundLevels={turnaroundLevels}
          />
          {canOrderMixing && (
            <div className={css(appStyles.sectionPadding)}>
              <OutputAssetSelector
                mixingOptions={filteredMixingOptions}
                selectedOutputAssets={selectedOutputAssets}
                setSelectedOutputAssets={setSelectedOutputAssets}
              />
            </div>
          )}
        </>
      }
      onSubmit={onSubmit}
      orderType="Voice Artist Audio Description"
      submissionDisabled={!canSubmit()}
      summaryContent={<OutputAssetSummary selectedOutputAssets={selectedOutputAssets} />}
      tipPanelContent={<VoiceArtistAudioDescriptionTipPanel />}
    />
  );
};

VoiceArtistAudioDescriptionOrderMore.propTypes = {
  authToken: PropTypes.string,
  canOrderMixing: PropTypes.bool,
  mediaFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  mixingOptions: PropTypes.shape({
    embed: PropTypes.arrayOf(OutputAssetShape),
    mix: PropTypes.arrayOf(OutputAssetShape),
  }),
  serviceLevels: PropTypes.arrayOf(serviceLevelShape),
  submissionUrl: PropTypes.string,
  turnaroundLevels: PropTypes.arrayOf(turnaroundLevelShape),
};

const styles = StyleSheet.create({
  bold: {
    'font-weight': 'bold',
  },
  box: {
    'margin-right': '0.25rem',
    cursor: 'pointer',
  },
  center: {
    'text-align': 'center',
  },
  description: {
    display: 'inline-block',
    'margin-top': '0.25rem',
  },
  left: {
    float: 'left',
  },
  right: {
    float: 'right',
  },
  turnaround: {
    'max-width': '15rem',
  },
});

export default VoiceArtistAudioDescriptionOrderMore;
