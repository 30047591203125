import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import SlideCheck from '~/lib/global/animations/SlideCheck';

const CheckIcon = () => <i className="fa fa-check text-success" />;
const ErrorIcon = () => <i className="fa fa-times text-danger" />;

function ConfirmationIcon({ success, displayKey }) {
  return (
    <SlideCheck inline displayKey={displayKey}>
      {success ? <CheckIcon /> : <ErrorIcon />}
    </SlideCheck>
  );
}

ConfirmationIcon.propTypes = {
  success: PropTypes.bool.isRequired,
  displayKey: PropTypes.any,
};

function RemoveOverrideButton({ onClick }) {
  return (
    <OverlayTrigger overlay={<Tooltip>Revert to default value</Tooltip>}>
      <Button variant="link" size="sm" className="p-0 mr-1" onClick={onClick}>
        <i className="fa fa-trash" />
      </Button>
    </OverlayTrigger>
  );
}

RemoveOverrideButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function ControlLabel({ children, control, confirmation, onRemoveOverride }) {
  const { persistedControl } = control;
  const showRemoveButton =
    !persistedControl.inherited &&
    (persistedControl.jobTypeId || persistedControl.contractorGroupId);
  const removeOverride = () => onRemoveOverride(persistedControl);

  return (
    <Form.Label>
      {showRemoveButton && (
        <RemoveOverrideButton control={control.persistedControl} onClick={removeOverride} />
      )}
      {control.isModified && '*'}
      {children}
      {confirmation && <ConfirmationIcon {...confirmation} />}
    </Form.Label>
  );
}

ControlLabel.propTypes = {
  children: PropTypes.node,
  control: PropTypes.shape({
    contractorGroupId: PropTypes.number,
    isModified: PropTypes.boolean,
    inherited: PropTypes.bool,
    jobTypeId: PropTypes.number,
    persistedControl: PropTypes.object,
  }).isRequired,
  confirmation: PropTypes.shape({
    displayKey: PropTypes.any,
    success: PropTypes.bool,
  }),
  onRemoveOverride: PropTypes.func.isRequired,
};

export default ControlLabel;
