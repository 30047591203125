import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import ChallengeModal from './modals/ChallengeModal';
import ChallengeCard from './ChallengeCard';
import { MEMBERSHIP_STATES } from './constants';

const findChallengeById = (id, challenges) =>
  challenges ? challenges.find((challenge) => challenge.id === id) : {};

const ActiveChallenges = ({ data = [] }) => {
  const [challenges, setChallenges] = useState(data);
  const [showModal, setShowModal] = useState(false);
  const [selectedChallenge, setSelectedChallenge] = useState({});

  const acceptChallenge = (challengeId) => {
    const updated = challenges.map((challenge) => {
      if (challenge.id === challengeId) {
        challenge.membership.state = MEMBERSHIP_STATES.accepted;
      }
      return challenge;
    });

    setChallenges(updated);
  };

  const openModal = (challengeId) => {
    setShowModal(true);
    const challenge = findChallengeById(challengeId, challenges);
    setSelectedChallenge(challenge);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedChallenge({});
  };

  return !challenges.length ? null : (
    <>
      <h2>Active Challenges</h2>
      <Row>
        {challenges.map((challenge) => {
          const { id, name, description, membership } = challenge;
          return (
            <ChallengeCard
              key={id}
              id={id}
              name={name}
              description={description}
              membershipState={membership?.state}
              endTime={membership?.expiresAt}
              completedAt={membership?.completedAt}
              onButtonClick={openModal}
            />
          );
        })}
      </Row>
      <ChallengeModal
        show={showModal}
        onClose={closeModal}
        challenge={selectedChallenge}
        membershipState={selectedChallenge.membership?.state}
        markChallengeAsAccepted={acceptChallenge}
      />
    </>
  );
};

ActiveChallenges.propTypes = {
  data: PropTypes.array,
};

export default ActiveChallenges;
