import React, { useState } from 'react';

import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * @deprecated Use `Select instead; it has all behavior for variants built in
 * with generic / typing support.
 */
export const SelectInput = ({
  children,
  disabled,
  errors,
  fieldId,
  isInvalid,
  label,
  onChange,
  register,
  required,
  size,
}) => {
  const elemProps = { disabled, isInvalid, onChange, size };
  const [registerOptions] = useState(required ? { required: `${label} is required` } : {});
  const fieldError = errors && errors[fieldId];

  return (
    <Form.Control
      {...register(fieldId, registerOptions)}
      as="select"
      className={`form-control ${fieldError ? 'is-invalid' : ''}`}
      {...elemProps}
    >
      {children}
    </Form.Control>
  );
};

SelectInput.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  errors: PropTypes.object, // TODO: this can't have a schema. Can we use a scalar?
  isInvalid: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  register: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
};

SelectInput.defaultProps = {
  disabled: false,
  isInvalid: false,
  required: false,
};
