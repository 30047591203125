import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import { AddNewContractorsTable } from './CreateReferralCodesTables';
import ContractorSelect from './ContractorSelect';
import Results from './Results';
import * as services from '../services/services';

const parseIdFromString = (selected) => {
  const splitString = selected.split('-');
  return parseInt(splitString[0]);
};

const findContractor = ({ id, contractors }) =>
  contractors.find((contractor) => parseInt(contractor.id) === parseInt(id));

const CreateNewReferralCodes = ({
  selectedContractors = [],
  contractors = [],
  createReferralCodes = services.createReferralCodes,
}) => {
  const [selected, setSelected] = useState('');
  const [createTokensFor, setCreateTokensFor] = useState(selectedContractors);
  const [results, setResults] = useState([]);

  const isContractorAlreadySelected = (id) => findContractor({ id, contractors: createTokensFor });

  const serialize = () => createTokensFor.map((contractor) => contractor.id);

  const handleAddContractor = () => {
    const id = parseIdFromString(selected);

    if (isContractorAlreadySelected(id)) {
      return;
    }

    const contractor = findContractor({ id, contractors });
    contractor && setCreateTokensFor([...createTokensFor, contractor]);
  };

  const handleCreateReferralCodes = () => {
    const data = serialize();
    createReferralCodes(data).then(({ data }) => {
      setResults(data?.createReferralCodes?.results);
    });
  };

  return (
    <>
      <ContractorSelect value={selected} setValue={setSelected} contractors={contractors} />

      <Button className={'mb-5'} onClick={handleAddContractor}>
        Add
      </Button>

      {!!createTokensFor.length && (
        <>
          <AddNewContractorsTable data={createTokensFor} />
          <Button disabled={!createTokensFor.length} onClick={handleCreateReferralCodes}>
            Create Referral Codes!
          </Button>{' '}
        </>
      )}

      <hr />

      {results?.length > 0 && <Results data={results} contractors={contractors} />}
    </>
  );
};

CreateNewReferralCodes.propTypes = {
  selectedContractors: PropTypes.array,
  contractors: PropTypes.array,
  createReferralCodes: PropTypes.func,
};

export default CreateNewReferralCodes;
