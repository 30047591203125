import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';
import { offPlatformBroadcastScriptingJobsPaths } from './offPlatformBroadcastScriptingJobsPaths';
import { OffPlatformBroadcastScriptingJob } from './:id/OffPlatformBroadcastScriptingJob';

const OffPlatformBroadcastScriptingJobsApp = ({ permissions }: RootProps) => {
  return (
    <JobAppContainer
      showPath={offPlatformBroadcastScriptingJobsPaths.show.route}
      jobComponent={<OffPlatformBroadcastScriptingJob />}
      permissions={permissions}
    />
  );
};

export default OffPlatformBroadcastScriptingJobsApp;
