import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, FormControl, FormLabel, InputGroup } from 'react-bootstrap';

// TODO: Find a suiteable shared location for this

const FileLinkComponent = ({ enabled, setFileLink }) => {
  const [linkText, setLinkText] = useState('');

  // Some implementations share state between returned S3 URL and what's provided here.
  // Separating these out so as not to inadvertently inherit undesired state.
  const updateLink = (input) => {
    setLinkText(input);
    setFileLink(input);
  }

  return (
    <>
      <FormLabel>Link to media file</FormLabel>
      <InputGroup>
        <FormControl
          disabled={!enabled}
          onChange={(e) => updateLink(e.target.value)}
          placeholder="Enter the URL to your media file"
          value={linkText}
        />
      </InputGroup>
    </>
  );
};

FileLinkComponent.propTypes = {
  enabled: PropTypes.bool,
  setFileLink: PropTypes.func,
};

export default FileLinkComponent;
