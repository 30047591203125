import React from 'react';

import TermsAcceptance from './terms_acceptance/TermsAcceptance';
import Welcome from './Welcome';
function TermsPage(props) {
  return (
    <Welcome {...props} sidePanel={'whats_new'}>
      <TermsAcceptance {...props} />
    </Welcome>
  );
}

export default TermsPage;
