import React from 'react';
import PropTypes from 'prop-types';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

function InfoPopover(props) {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ hide: 2000 }}
      trigger={['hover', 'focus']}
      overlay={
        <Popover>
          {props.title && <Popover.Title>{props.title}</Popover.Title>}
          <Popover.Content>
            <div className="mb-1">{props.children}</div>
            {props.linkText && props.linkUrl && (
              <a href={props.linkUrl} rel="noreferrer noopener" target="_blank">
                {props.linkText}
              </a>
            )}
          </Popover.Content>
        </Popover>
      }
    >
      <i className="fa fa-info-circle"></i>
    </OverlayTrigger>
  );
}

InfoPopover.propTypes = {
  title: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default InfoPopover;
