import React, { ReactNode } from 'react';

export const PermissionContext = React.createContext({ canViewDeliveryInstructions: false });

const { Provider } = PermissionContext;

interface PermissionProviderProps {
  children: ReactNode;
  permissions: PermissionProps;
}

export interface RootProps {
  permissions: PermissionProps;
}

export interface PermissionProps {
  canViewDeliveryInstructions: boolean;
}

export const PermissionProvider = ({ children, permissions }: PermissionProviderProps) => (
  <Provider value={permissions}>{children}</Provider>
);
