import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

const ContractorSelect = ({ contractors, value, setValue }) => {
  return (
    <Form.Group controlId={'contractor-select'}>
      <Form.Label>Select a contractor</Form.Label>
      <Form.Control value={value} onChange={(e) => setValue(e.target.value)} list={'contractors'} />
      <datalist id="contractors">
        {contractors.map((contractor) => {
          const text = `${contractor.id} - ${contractor.name}`;
          return (
            <option key={contractor.id} value={text}>
              {text}
            </option>
          );
        })}
      </datalist>
    </Form.Group>
  );
};

ContractorSelect.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  contractors: PropTypes.array,
};

export default ContractorSelect;
