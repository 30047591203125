import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

class TranscriptPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <h2>Transcript Preview</h2>
        </Card.Header>
        <Card.Body>
          <Card.Title>
            {this.props.mediaFiles[Object.keys(this.props.mediaFiles)[0]]['title']}
          </Card.Title>
          <div
            className="transcript-preview-text"
            dangerouslySetInnerHTML={{ __html: this.props.transcriptContent }}
          />
        </Card.Body>
      </Card>
    );
  }
}

TranscriptPreview.propTypes = {
  transcriptContent: PropTypes.string,
  mediaFiles: PropTypes.object,
};

export default TranscriptPreview;
