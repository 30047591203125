export default function stepReducer(currentStep, action) {
  switch (action.type) {
    case 'jump': {
      if (action.destination > currentStep.id || currentStep.usingTemplate) {
        return currentStep;
      } else {
        return {
          ...currentStep,
          id: action.destination,
          type: currentStep.stepIndex[action.destination],
        };
      }
    }
    case 'next': {
      return {
        ...currentStep,
        id: currentStep.id + 1,
        type: currentStep.stepIndex[currentStep.id + 1],
      };
    }
    case 'previous': {
      if (currentStep.usingTemplate) {
        return currentStep;
      }
      return {
        ...currentStep,
        id: currentStep.id - 1,
        type: currentStep.stepIndex[currentStep.id - 1],
      };
    }
    case 'templateJump': {
      return { ...currentStep, id: 2, type: currentStep.stepIndex[2], usingTemplate: true };
    }
  }
}
