// copied from the react-use library - updated to run in our environment:
// https://github.com/streamich/react-use/blob/a38f026beb193ac31a728bd06095337eaa6afe95/src/useInterval.ts

import { useEffect, useRef } from 'react';

const useInterval = (callback: VoidFunction, delay?: number | null) => {
  const savedCallback = useRef<VoidFunction | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (delay !== null) {
      const interval = setInterval(() => savedCallback.current?.(), delay || 0);
      return () => clearInterval(interval);
    }

    return undefined;
  }, [delay]);
};

export default useInterval;
