import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import LoadingOverlay from '~/components/app/common/LoadingOverlay';
import ManagePlanWizard from './ManagePlanWizard';

function handleCreatePlanSuccess() {
  window.location.replace('/account_plans/overview');
}

function handleCreatePlanErrors() {
  //TODO how to display the errors
}

function NewProAccountScreen(props) {
  const [submitting, setSubmitting] = useState(false);
  return (
    <>
      <ErrorBoundary component="AccountPlansNewPro">
        {submitting && <LoadingOverlay message="Subscribing To Pro..." />}
        <ManagePlanWizard
          authenticityToken={props.authenticityToken}
          clientToken={props.clientToken}
          defaultPrice={props.defaultPrice}
          handleCreatePlanErrors={handleCreatePlanErrors}
          handleCreatePlanSuccess={handleCreatePlanSuccess}
          introPrice={props.introPrice}
          setSubmitting={setSubmitting}
          siteKey={props.siteKey}
          submitting={submitting}
          termsDocument={props.termsDocument}
        />
      </ErrorBoundary>
    </>
  );
}

NewProAccountScreen.propTypes = {
  authenticityToken: PropTypes.string,
  clientToken: PropTypes.string,
  defaultPrice: PropTypes.number,
  introPrice: PropTypes.number,
  termsDocument: PropTypes.object,
  siteKey: PropTypes.string,
};
export default NewProAccountScreen;
