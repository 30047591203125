const liveAsrQuery = `query LiveEvents(
    $paginationParams: PaginationInput!,
    $searchTerm: String!,
    $sortParams: SortInput!,
    $type: String!,
    $dashboardErrors: Boolean = false
    $detailedEventStatus: Boolean = false
  ) {
  project {
    liveAsrs(
        paginationParams: $paginationParams,
        searchTerm: $searchTerm,
        sortParams: $sortParams,
        type: $type
      ) {
      liveEventDetails{
        id,
        cancelCaptionsWithCharge,
        captionIngestionUrl,
        captionServiceType,
        duration,
        dashboardErrors @include(if: $dashboardErrors){
          action
          warning
        }
        editPath,
        errors @skip(if: $detailedEventStatus),
        hasSource,
        integrationId,
        integrationName,
        liveRecurringEventSetId,
        mfId,
        name,
        startTime,
        state @skip(if: $detailedEventStatus),
        status @include(if: $detailedEventStatus) {
          icon
          text
          tooltip
          type
        }
        upgraded,
        videoPlatform,
        zoomApiTokenPresent,
      }
      headerDetails{
        displayName,
        name,
        sortDirection,
      }
      paginationDetails {
        totalPages,
        totalVideos
      }
    },
    userTimeZone
  }
}`;

const statusForLiveEventQuery = `query StatusForEvent($liveEventId: ID!) {
  project {
    liveEventDetails(liveEventId: $liveEventId) {
      captionStatusDescription{
        text,
        type
      },
      streamStatusDescription{
        text,
        type
      },
    }
  }
}`;

export { liveAsrQuery, statusForLiveEventQuery };
