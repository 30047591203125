import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import styles from '~/components/app/order_form/serviceDetails/serviceDetailStyles';

const HumanDubbingServiceDetails = ({ dubbingOrderOptions, embedding }) => (
  <>
    {dubbingOrderOptions.map((doo) => (
      <div className={css(styles.serviceOptionHeader)} key={doo.targetLanguage.id}>
        <span>
          <b>{doo.targetLanguage.fullName}</b>
        </span>
      </div>
    ))}
    <b>Output Asset:</b> {embedding ? 'Video Embedded' : 'Audio Only'}
    <p className="pt-2">
      You will be invoiced according to the Dubbing Quote linked with this order.
    </p>
  </>
);

HumanDubbingServiceDetails.propTypes = {
  dubbingOrderOptions: PropTypes.arrayOf(PropTypes.object),
  embedding: PropTypes.boolean,
};

export default HumanDubbingServiceDetails;
