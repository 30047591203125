import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@threeplayground/index';
import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';

import { OpsTasksPage } from './OpsTasksPage';
import { OpsTasksShowPage } from './OpsTasksShowPage';
import { opsTasksPaths } from './opsTasksPaths';

const apiClient = new ThreeplayApiV2('/data');

function OpsTasksApp() {
  return (
    <ThemeProvider>
      <ThreeplayAPIProvider client={apiClient}>
        <OpsTasksRoutes />
        <ReactQueryDevtools initialIsOpen={false} />
      </ThreeplayAPIProvider>
    </ThemeProvider>
  );
}

function OpsTasksRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {/* todo: routing */}
        <Route path={opsTasksPaths.list.route} element={<OpsTasksPage />} />
        <Route path={opsTasksPaths.show.route} element={<OpsTasksShowPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default OpsTasksApp;
