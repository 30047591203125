import { getUrlParams } from '~/logic/UrlManager';
import { convertSnakeCaseToCamelCase } from '~/helpers/strings';

const PERMITTED_REFERRER_PARAMS = [
  'js_source', // js_source is mapped to referrer in database
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
];

export const getReferrerParams = () => {
  const urlParams = getUrlParams();
  const referrerParams = {};
  Object.keys(urlParams)
    .filter((key) => PERMITTED_REFERRER_PARAMS.includes(key))
    .forEach((key) => (referrerParams[getFormattedKey(key)] = urlParams[key]));

  return referrerParams;
};

const getFormattedKey = (key) => {
  return key == 'js_source' ? 'referrer' : convertSnakeCaseToCamelCase(key);
};
