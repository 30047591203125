import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

function Thumbnail(props) {
  const [useFallback, setUseFallback] = useState(false);

  useEffect(() => {
    setUseFallback(false);
  }, [props.src]);

  function src() {
    // TODO: use `image_url('placeholders/media_file_thumbnail_placeholder.png')`
    return useFallback ? null : props.src;
  }

  function fallback() {
    setUseFallback(true);
  }

  return (
    <div className={css(styles.frame)}>
      {props.type === 'video' && (
        <img
          className={css(styles.thumbnail)}
          src={src()}
          onError={useFallback ? null : fallback}
        />
      )}
      {props.type === 'audio' && <i className="fa fa-volume-up" />}
      {props.type === 'text' && <i className="fa fa-file-text-o" />}
      {props.type === 'processing' && <i className="fa fa-spinner" />}
    </div>
  );
}

const styles = StyleSheet.create({
  frame: {
    background: '#C8C8C8',
    color: '#767676',
    textAlign: 'center',
    paddingTop: '6px',
    fontSize: '2.5em',
  },
  thumbnail: {
    width: '84px',
    height: '47px',
  },
});

Thumbnail.propTypes = {
  type: PropTypes.oneOf(['video', 'audio', 'text', 'processing']).isRequired,
  src: PropTypes.string,
};

export default Thumbnail;
