import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import LandingPage from './landing_page/LandingPage';
import MainApplication from './preliminary_application/transcription/MainApplication';
import ContinueApplicationPage from './preliminary_application/transcription/ContinueApplicationPage';
import AlreadySubmitted from './submitted_states/AlreadySubmitted';
import NotAcceptingLocation from './submitted_states/NotAcceptingLocation';
import Thanks from './submitted_states/Thanks';
import PleaseEmailOps from './submitted_states/PleaseEmailOps';
import PleaseWait from './preliminary_application/PleaseWait';
import Test from './transcription_test/Test';
import NotAcceptingApplications from '../not_accepting_applications/NotAcceptingApplications';
import { PAGES } from './preliminary_application/constants';

import { applicantShape, blankApplicantInfo } from './preliminary_application/shapes';
import { getReferrerParams } from './helpers';

import * as workflow from './applicationWorkflow';
import { localeFor } from './locales/locale';

import { LocaleContext } from './locales/LocaleContext';

export const FlipperFeatureContext = React.createContext();

const Apply = ({
  action,
  applicant,
  countries,
  emailCode,
  usStates,
  languageId,
  jobTypeId,
  flipperFeatures,
  logReferrer = workflow.logReferrer,
}) => {
  const initialApplicantInfo = blankApplicantInfo;
  if (applicant) {
    Object.assign(initialApplicantInfo, applicant);
  }
  const initialPath = action === 'verify_email' ? PAGES.NEW_APPLICATION : PAGES.LANDING_PAGE;
  const initialStep =
    action === 'verify_email' ? MainApplication.CONFIRM_EMAIL : MainApplication.BASIC_INFORMATION;

  const referrerParams = useMemo(() => getReferrerParams(), []);

  const [path, setPath] = useState(initialPath);
  const [step, setStep] = useState(initialStep);
  const [pleaseWait, setPleaseWait] = useState(false);
  const [error, setError] = useState(null);

  const [authToken, setAuthToken] = useState({});
  const [applicantInfo, setApplicantInfo] = useState(initialApplicantInfo);
  const [testData, setTestData] = useState([]);

  // If we're dropping in from an email verification link, mimic code submission actions
  useEffect(() => {
    action === 'verify_email' && setStep(MainApplication.CONFIRM_EMAIL);
  }, []);

  const startApplication = (path) => {
    const isNewApplication = path === PAGES.NEW_APPLICATION;

    if (isNewApplication && !!flipperFeatures?.blockNewApplicants) {
      setPath(PAGES.NOT_ACCEPTING_NEW_APPLICATIONS);
      return;
    }

    setPleaseWait(true);
    logReferrer({ referrerParams, jobTypeId }).then(() => {
      setPleaseWait(false);
    });

    setPath(path);
  };

  const handleRedirect = (error) => {
    setPath(error);
  };

  const startTest = ({ tests }) => {
    setTestData(tests);
    setTimeout(setPath(PAGES.TEST), 500);
  };

  async function submitTest(time) {
    setPleaseWait(true);
    const promise = workflow.submitTest(testData, { authToken, time });
    promise.then(() => {
      setPleaseWait(false);
    });
    const response = await promise;

    if (response.errors) {
      setError('unknown');
      return false;
    } else {
      setPath(PAGES.SUCCESSFULLY_SUBMITTED_APPLICATION);
    }
  }

  const updateApplicant = (fields) => {
    setApplicantInfo((applicantInfo) => ({ ...applicantInfo, ...fields }));
  };

  const updateTest = useCallback(
    (index, transcript) => {
      const newTestData = testData.slice();
      newTestData[index] = { ...newTestData[index], transcript };
      setTestData(newTestData);
    },
    [testData]
  );

  if (error === 'failed_recaptcha') {
    return (
      <PleaseWait message="Hmm, our servers could not verify your request. Please try restarting your browser." />
    );
  } else if (error) {
    return <PleaseWait message="Something went wrong. Please try reloading this page." />;
  }

  return (
    <FlipperFeatureContext.Provider value={flipperFeatures}>
      <LocaleContext.Provider value={localeFor(languageId)}>
        <div className="full">
          {pleaseWait && <PleaseWait />}

          {path === PAGES.LANDING_PAGE && <LandingPage onStart={startApplication} />}

          {path === PAGES.NOT_ACCEPTING_LOCATION && <NotAcceptingLocation />}

          {path === PAGES.NOT_ACCEPTING_NEW_APPLICATIONS && (
            <NotAcceptingApplications countries={countries} usStates={usStates} />
          )}

          {path === PAGES.ALREADY_SUBMITTED_APPLICATION && <AlreadySubmitted />}

          {path === PAGES.USER_EMAIL_EXISTS && (
            <PleaseEmailOps
              firstname={applicantInfo.firstname}
              lastname={applicantInfo.lastname}
              email={applicantInfo.email}
            />
          )}

          {path === PAGES.SUCCESSFULLY_SUBMITTED_APPLICATION && (
            <Thanks email={applicantInfo.email} />
          )}

          {path === PAGES.NEW_APPLICATION && (
            <div className={'full ' + css(styles.main)}>
              <MainApplication
                applicantInfo={applicantInfo}
                countries={countries}
                usStates={usStates}
                onStartTest={startTest}
                languageId={languageId}
                onUpdate={updateApplicant}
                step={step}
                setStep={setStep}
                authToken={authToken}
                setAuthToken={setAuthToken}
                emailCode={emailCode}
                redirectPage={handleRedirect}
              />
            </div>
          )}

          {path === PAGES.CONTINUE_APPLICATION && (
            <div className={'full ' + css(styles.main)}>
              <ContinueApplicationPage
                applicantInfo={applicantInfo}
                countries={countries}
                usStates={usStates}
                onStartTest={startTest}
                languageId={languageId}
                onUpdate={updateApplicant}
                step={step}
                setStep={setStep}
                authToken={authToken}
                setAuthToken={setAuthToken}
                emailCode={emailCode}
                redirectPage={setPath}
              />
            </div>
          )}

          {path === PAGES.TEST && (
            <div className={'full d-flex flex-row ' + css(styles.main)}>
              <Test testData={testData} onSubmit={submitTest} onUpdate={updateTest} />
            </div>
          )}
        </div>
      </LocaleContext.Provider>
    </FlipperFeatureContext.Provider>
  );
};

Apply.propTypes = {
  action: PropTypes.string,
  applicant: applicantShape,
  countries: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  emailCode: PropTypes.string,
  usStates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  languageId: PropTypes.number.isRequired,
  jobTypeId: PropTypes.number.isRequired,
  flipperFeatures: PropTypes.object,
  logReferrer: PropTypes.func,
};

const styles = StyleSheet.create({
  main: {
    height: '100%',
    position: 'relative',
  },
});

export default Apply;
