import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import InputErrorList from '~/components/app/common/InputErrorList';

import { moduleAddonPath } from '~/helpers/app/paths';

// import InputErrorList from '~/components/app/common/InputErrorList';

import { threeplayApi } from '~/logic/ThreeplayApi';
const moduleAddonQuery = 'moduleAddons { id, name }';

function AmaraOrderModal(props) {
  const [updating, setUpdating] = useState(false);
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [errors, setErrors] = useState([]);
  const [moduleAddons, setModuleAddons] = useState(null);
  const amaraModuleAddon = moduleAddons && moduleAddons['Amara Integration'];

  useEffect(() => {
    if (!props.show) {
      return;
    }
    // Don't re-fetch static data
    if (moduleAddons) {
      return;
    }

    let cancelled = false;
    async function fetchModuleAddons() {
      const response = await threeplayApi.query(moduleAddonQuery, 'ModuleAddons');
      if (cancelled) {
        return;
      }
      const moduleAddons = {};
      response.data.moduleAddons.forEach((ma) => {
        moduleAddons[ma.name] = ma.id;
      });
      setModuleAddons(moduleAddons);
    }
    fetchModuleAddons();
    return () => {
      cancelled = true;
    };
  }, [props.show]);

  async function sendToAmaraMutation() {
    setUpdating(true);
    const response = await threeplayApi.mutate(
      sendAmaraOrderMutation(props.fileId),
      'ModuleAddons'
    );
    if (response.data && response.data.amaraSendFile) {
      if (response.data.amaraSendFile.error) {
        setErrors([response.data.amaraSendFile.error]);
        setUpdating(false);
        return;
      }
      setUpdating(false);
      setSuccessAlert(true);
      setTimeout(() => {
        handleClose(), setSuccessAlert(false);
      }, 3000);
    } else {
      setErrors([response.errors]);
      setUpdating(false);
      return;
    }
  }

  function successAlert() {
    return <Alert variant="success">Success! Your file has been ordered.</Alert>;
  }

  function sendAmaraOrderMutation(fileId) {
    return `amaraSendFile(
      fileId: ${fileId}
    ) { success, error }`;
  }

  function handleClose() {
    props.onClose();
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Files to Amara</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
        <React.Fragment>
          {showSuccessAlert && successAlert()}
          <p>Are you sure you want to upload to your Amara account for translation?</p>
          <p>These file(s) will be added to the team within your Amara account.</p>
          <p>
            If you would like to add these file(s) to a different team, please change your Amara
            settings <a href={moduleAddonPath(amaraModuleAddon)}>here</a>.
          </p>
          {errors && <InputErrorList errors={errors} />}
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={sendToAmaraMutation} disabled={updating}>
          Yes - Send Files
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AmaraOrderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fileId: PropTypes.string.isRequired,
};

export default AmaraOrderModal;
