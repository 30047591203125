import React from 'react';
import PropTypes from 'prop-types';

import BasicServiceInfo from './BasicServiceInfo';

function DescriptiveTranscriptServiceCard(props) {
  return (
    <BasicServiceInfo
      service={props.service}
      showOrderedAt
      showComplete
      showLanguage
      userTimeZone={props.userTimeZone}
    />
  );
}

DescriptiveTranscriptServiceCard.propTypes = {
  service: PropTypes.object,
  userTimeZone: PropTypes.string,
};

export default DescriptiveTranscriptServiceCard;
