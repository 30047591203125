export const graphqlPath = '/apply/data';

function compact(string) {
  return string.replace(/\s+/g, ' ');
}

export const continueApplicationQuery = compact(`query ContinueApplication (
  $tokenId: String!
  $secret: String!
) {
  contractorApplication (
    authToken: {
      tokenId: $tokenId,
      secret: $secret,
    }
  ) {
    firstname
    lastname
    email
    addressStreet
    addressNumber
    city
    region
    country
    postalCode
    phoneNumber
    wordsPerMinute
    experience
    languages
    referralSource
    referralText
    referralToken
  }
}`);
