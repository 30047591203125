import React from "react";
import PropTypes from "prop-types";

import { css, StyleSheet } from "aphrodite";

import { Table } from "~/packages/threeplayground";

const UploadTable = ({ acceptedFiles, removeUploadedFile }) => {
  const sorted = [...acceptedFiles].sort((a, b) => {
    return a.id - b.id;
  });
  const columns = [
    {
      header: 'File Name',
      accessor: 'name',
      cell: 'string',
    },
    {
      header: '',
      accessor: 'percentComplete',
      cell: ({ value }) => {
        return `(${value}%)`;
      },
    },
    {
      header: '',
      accessor: 'id',
      cell: ({ value }) => {
        return (
          <>
            <a href="#" onClick={() => removeUploadedFile(value)}>
              <i className="fa fa-trash-o" />
            </a>
          </>
        );
      },
    },
  ];
  return (
    <div className={css(styles.uploadTable)}>
      <Table columns={columns} data={sorted} />
    </div>
  );
};

const styles = StyleSheet.create({
  uploadTable: {
    marginBottom: '0.5em',
  },
});

UploadTable.propTypes = {
  acceptedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  removeUploadedFile: PropTypes.func,
};

export default UploadTable;
