const compact = (string) => {
  return string.replace(/\s+/g, ' ');
};

export const contractorReferralCodesQuery = compact(`query contractorReferralCodes {
    contractorReferralCodes {
      items {
        contractor {
          name
        }
        active
        referrals {
          applicant {
            id
            name
            state
          }
          hoursTarget
          payout
          state
        }
        token
        referralLimit
      }
    }
  }
`);

export const referredApplicantsQuery = compact(`query referredApplicants {
    applicants(isReferredByContractor: true) {
      items {
        id
        name
        email
        state
        contractorReferral {
          contractor {
            id
            name
          }
          hoursTarget
          payout
          state
          createdAt
          updatedAt
          expiresAt
          completedAt
        }
      }
    }
  }
`);

export const contractorQuery = compact(`query getContractors {
    contractors {
      items {
        name
        id
      }
    }
  }
`);
