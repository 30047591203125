import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';

import EditingTimeTracker from './EditingTimeTracker';
import { formatNumber } from './utils/utils';

const isValid = (progress, target) => {
  const isProgressValid = !!progress || progress === 0;
  return isFinite(progress / target) && isProgressValid;
};

const calculatePercentComplete = (progress, target) => {
  return progress > target ? 100 : (progress / target) * 100;
};

const ProgressTracker = ({ progress = null, target = null, challengeName = null }) => {
  const percentComplete = calculatePercentComplete(progress, target);
  const label = `${formatNumber(percentComplete)}%`;
  return (
    <div className="progress-tracker">
      <h5>Track Your Progress</h5>
      {!isValid(progress, target) ? (
        <p>Could not load progress data. Check back in later.</p>
      ) : (
        <>
          {challengeName === 'Auditing Challenge' ? (
            `${progress} of ${target} jobs completed`
          ) : (
            <EditingTimeTracker progress={progress} target={target} />
          )}
          <ProgressBar now={percentComplete} label={label} />
        </>
      )}
    </div>
  );
};

ProgressTracker.propTypes = {
  challengeName: PropTypes.string,
  progress: PropTypes.number,
  target: PropTypes.number,
};

export default ProgressTracker;
