export const offPlatformVideoEmbeddingJobsPaths = {
  show: {
    getLink: (id: string) => `/off_platform_video_embedding_jobs/${id}`,
    route: '/off_platform_video_embedding_jobs/:id',
  },
  downloadInputTranscript: {
    getLink: (id: string) =>
      `/off_platform_video_embedding_jobs/download_input_transcript?id=${id}`,
  },
};
