import React from 'react';

interface OutputCaption {
  content: string;
  id: string;
}

interface OutputCaptionsProps {
  captions: OutputCaption[];
}

export function OutputCaptions({ captions }: OutputCaptionsProps) {
  return (
    <div className="d-inline w-100">
      {captions.map(({ id, content }, index) => {
        const lastCaption = index === captions.length - 1;
        const testId = lastCaption ? 'previous-caption-output' : 'recent-caption-output';
        return (
          <p className={`mb-1${lastCaption ? ' bg-light' : ''}`} key={id} data-testid={testId}>
            {content}
          </p>
        );
      })}
    </div>
  );
}
