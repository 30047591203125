import React from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';

function ContinuousCardLayout(props) {
  return <Row className="pt-4">{props.children}</Row>;
}

ContinuousCardLayout.propTypes = {
  children: PropTypes.node,
};

export default ContinuousCardLayout;
