import React from 'react';
import PropTypes from 'prop-types';

import { Table } from '@threeplayground/index';

const OrderTemplatesTable = ({
  orderTemplates,
  setCurrentlyUpdatingID,
  setCurrentlyDeletingID,
}) => {
  const columns = [
    {
      header: 'Name',
      accessor: 'name',
      cell: 'string',
    },
    { header: 'Created At', accessor: 'created_at', cell: 'date' },
    {
      header: '',
      accessor: 'id',
      cell: ({ value }) => {
        return (
          <>
            <a href="#" onClick={() => setCurrentlyUpdatingID(value)}>
              rename
            </a>
            &nbsp;|&nbsp;
            <a href="#" onClick={() => setCurrentlyDeletingID(value)}>
              delete
            </a>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table columns={columns} data={orderTemplates} />
    </>
  );
};

OrderTemplatesTable.propTypes = {
  orderTemplates: PropTypes.arrayOf(PropTypes.hash),
  setCurrentlyUpdatingID: PropTypes.func,
  setCurrentlyDeletingID: PropTypes.func,
};

export default OrderTemplatesTable;
