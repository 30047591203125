import React, { useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ContinueApplication from './ContinueApplication';
import BasicInformationForm from '../shared/forms/BasicInformationForm';
import ConfirmEmail from '../shared/forms/ConfirmEmail';
import LocationInformation from '../shared/forms/LocationInformation';
import SingleStepNav from '../shared/SingleStepNav';
import SkillzAndExperience from './SkillzAndExperience';
import StepperNav from '../shared/StepperNav';
import { getInProgressApplication } from '../../applicationWorkflow';
import { applicantShape } from '../shapes';
import { palette } from '~/components/contractor_applications/theme';
import { submitBasicInfo } from '../../applicationWorkflow';

const CONTINUE_APPLICATION = 0;
const CONFIRM_EMAIL = 1;
const BASIC_INFORMATION = 2;
const LOCATION_INFORMATION = 3;
const SKILLS_AND_EXPERIENCE = 4;

const APPLICATION_STEPS = {
  [CONTINUE_APPLICATION]: 'Continue Application',
  [CONFIRM_EMAIL]: 'Confirm Email',
  [BASIC_INFORMATION]: 'Basic Information',
  [LOCATION_INFORMATION]: 'Location Information',
  [SKILLS_AND_EXPERIENCE]: 'Skills and Experience',
};

const ContinueApplicationPage = ({
  applicantInfo,
  countries,
  usStates,
  step,
  setStep,
  redirectPage,
  onStartTest,
  authToken,
  setAuthToken,
  emailCode,
  languageId,
}) => {
  const [email, setEmail] = useState('');
  const [applicant, setApplicant] = useState(applicantInfo);

  const confirmEmail = ({ authToken }) => {
    setAuthToken(authToken);
    getInProgressApplication({ authToken }).then((response) => {
      if (response?.errors) {
        // TODO: show error message
        return;
      }
      setApplicant(response?.data?.contractorApplication);
      setStep(step + 1);
    });
  };

  function nextStep({ applicantInfo = {} }) {
    setApplicant({ ...applicant, ...applicantInfo });
    setStep(step + 1);
  }

  const handleNextStepSkillzAndExperience = ({ tests }) => {
    onStartTest({ tests });
  };

  const previousStep = ({ applicantInfo = {} }) => {
    setApplicant({ ...applicant, ...applicantInfo });
    step > 0 && setStep(step - 1);
  };

  const nextStepContinueApp = ({ email, authToken }) => {
    setAuthToken(authToken);
    setEmail(email);
    setStep(step + 1);
  };

  return (
    <>
      <Row>
        <Col lg={3}>
          <div className={'d-lg-none d-block'}>
            <SingleStepNav currentStep={step} appSteps={APPLICATION_STEPS}></SingleStepNav>
          </div>
          <div className={'mt-lg-5 ml-lg-5 pl-2 pr-2 d-none d-lg-block ' + css(styles.nav)}>
            <h3 className={css(styles.navSectionHeader)}>Initial Application</h3>
            <StepperNav currentStep={step}>
              <StepperNav.Step>1. Continue Application</StepperNav.Step>
              <StepperNav.Step>2. Confirm Email</StepperNav.Step>
              <StepperNav.Step>3. Basic Information</StepperNav.Step>
              <StepperNav.Step>4. Location Information</StepperNav.Step>
              <StepperNav.Step>5. Skills and Experience</StepperNav.Step>
            </StepperNav>
            <h3 className={css(styles.navSectionHeader, styles.pending)}>Transcription Test</h3>
          </div>
        </Col>
        <Col lg={9}>
          <div className={'pl-1 mt-lg-5 ml-lg-5 pl-3 pr-2 ' + css(styles.form)}>
            {step === CONTINUE_APPLICATION && (
              <ContinueApplication
                email={applicantInfo.email}
                nextStep={nextStepContinueApp}
                redirectPage={redirectPage}
              />
            )}
            {step === CONFIRM_EMAIL && (
              <ConfirmEmail
                email={email}
                isNewApplication={false}
                onSubmit={confirmEmail}
                previousStep={previousStep}
                emailCode={emailCode}
                authToken={authToken}
              />
            )}
            {step === BASIC_INFORMATION && (
              <BasicInformationForm
                countries={countries}
                usStates={usStates}
                applicantInfo={applicant}
                submitBasicInformation={submitBasicInfo}
                nextStep={nextStep}
                redirectPage={redirectPage}
                languageId={languageId}
              />
            )}
            {step === LOCATION_INFORMATION && (
              <LocationInformation
                applicantInfo={applicant}
                previousStep={previousStep}
                nextStep={nextStep}
                authToken={authToken}
              />
            )}
            {step === SKILLS_AND_EXPERIENCE && (
              <SkillzAndExperience
                applicantInfo={applicant}
                previousStep={previousStep}
                nextStep={handleNextStepSkillzAndExperience}
                authToken={authToken}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

ContinueApplicationPage.propTypes = {
  applicantInfo: applicantShape,
  countries: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  usStates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  redirectPage: PropTypes.func,
  onStartTest: PropTypes.func.isRequired,
  authToken: PropTypes.object.isRequired,
  setAuthToken: PropTypes.func.isRequired,
  emailCode: PropTypes.string,
  languageId: PropTypes.number,
};

const styles = StyleSheet.create({
  nav: {
    fontWeight: 600,
    fontSize: '13px',
  },
  navSectionHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '1em',
    marginBottom: '1em',
    color: palette.text,
  },
  pending: {
    color: palette.inactive,
  },
  form: {
    maxWidth: '675px',
  },
});

Object.assign(ContinueApplicationPage, {
  BASIC_INFORMATION,
  LOCATION_INFORMATION,
  CONFIRM_EMAIL,
  SKILLS_AND_EXPERIENCE,
});

export default ContinueApplicationPage;
