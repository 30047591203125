import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import CreateNewReferralCodes from './create_referral_codes/CreateNewReferralCodes';

const CreateNewReferralCodeModal = ({ show = false, closeModal, contractors }) => {
  return (
    <Modal size={'lg'} show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Referral Codes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateNewReferralCodes contractors={contractors} />
      </Modal.Body>
    </Modal>
  );
};

CreateNewReferralCodeModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  contractors: PropTypes.array,
};

export default CreateNewReferralCodeModal;
