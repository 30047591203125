import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';

import AccessibilityQuote from '~/components/app/common/AccessibilityQuote';
import OrderSuccessImage from '~/components/app/order_form/images/orderSuccess.svg';

function SuccessfulOrder({ orderID, postOrderRedirect, postOrderRedirectUrl }) {
  return (
    <div className={css(styles.center)}>
      <>
        <div className={css(styles.container)}>
          <img src={OrderSuccessImage} alt="" />
          <div className={css(styles.success)}>Your Order was Successfully Placed!</div>
          <div className={css(styles.buttonContainer)}>
            {postOrderRedirect && orderID && (
              <Button
                variant="primary"
                className={css(styles.buttons)}
                href={`${postOrderRedirectUrl}?id=${orderID}`}
              >
                Manage Order
              </Button>
            )}
            <Button variant="outline-secondary" className={css(styles.buttons)} href="/files">
              Back to My Files
            </Button>
            <Button variant="primary" className={css(styles.buttons)} href="/order_form/new">
              Order More Services
            </Button>
          </div>
          <AccessibilityQuote />
        </div>
      </>
    </div>
  );
}

const styles = StyleSheet.create({
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2rem',
  },
  buttons: {
    margin: '0px 10px',
  },
  container: {
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '20px 0px 20px 0px',
  },
  success: {
    fontSize: '24px',
    color: '#273469',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '1rem',
  },
});

SuccessfulOrder.propTypes = {
  orderID: PropTypes.number,
  postOrderRedirect: PropTypes.bool,
  postOrderRedirectUrl: PropTypes.string,
};

export default SuccessfulOrder;
