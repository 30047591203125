export const graphqlPath = '/apply/data';

function compact(string) {
  return string.replace(/\s+/g, ' ');
}

export const logReferrerMutation = compact(`mutation LogReferrer(
  $referrerParams: ReferrerParamsInput
  $jobTypeId: Int!
) {
  logReferrer(
    referrerParams: $referrerParams
    jobTypeId: $jobTypeId
  ) {
    success
  }
}`);

export const startApplicationMutation = compact(`mutation StartApplication(
  $email: String!
  $firstname: String!
  $lastname: String!
  $country: String!
  $region: String!
  $languageId: Int
  $referrerParams: ReferrerParamsInput
) {
  startApplication (
    fields: {
      email: $email
      firstname: $firstname
      lastname: $lastname
      country: $country
      region: $region
      languageId: $languageId
      referrerParams: $referrerParams
    }
  ) {
    success error authToken { tokenId secret }
  }
}`);

export const sendEmailConfirmationMutation = compact(`mutation SendEmailConfirmation(
  $tokenId: String!
  $secret: String!
) {
  sendEmailConfirmation (
    authToken: {
      tokenId: $tokenId
      secret: $secret
    }
  ) {
    success error
  }
}`);

export const continueApplicationMutation = compact(`mutation ContinueApplication(
  $email: String!
  $grecaptchaToken: String!
) {
  continueApplication (
    email: $email
    grecaptchaToken: $grecaptchaToken
  ) {
    success error authToken { tokenId secret }
  }
}`);

export const confirmEmailMutation = compact(`mutation ConfirmEmail(
  $email: String!
  $token: String!
) {
  confirmEmail (
    email: $email
    token: $token
  ) {
    success error authToken { tokenId secret }
  }
}`);

export const submitApplicationMutation = compact(`mutation SubmitApplication (
  $tokenId: String!
  $secret: String!
  $addressStreet: String!
  $addressNumber: String!
  $city: String!
  $postalCode: String!
  $phoneNumber: String!
  $wordsPerMinute: Int!
  $experience: String!
  $languages: String!
  $referralSource: String!
  $referralText: String
  $referralToken: String
) {
  submitApplication (
    authToken: {
      tokenId: $tokenId
      secret: $secret
    }
    fields: {
      addressStreet: $addressStreet
      addressNumber: $addressNumber
      city: $city
      postalCode: $postalCode
      phoneNumber: $phoneNumber
      wordsPerMinute: $wordsPerMinute
      experience: $experience
      languages: $languages
      referralSource: $referralSource
      referralText: $referralText
      referralToken: $referralToken
    }
  ) {
    success error tests { name instructions testFile { id mediaType url } }
  }
}`);

export const updateLocationInformationMutation = compact(`mutation UpdateApplication(
  $tokenId: String!
  $secret: String!
  $addressStreet: String!
  $addressNumber: String!
  $city: String!
  $postalCode: String!
  $phoneNumber: String!
) {
  updateApplication (
    authToken: {
      tokenId: $tokenId
      secret: $secret
    }
    fields: {
      addressStreet: $addressStreet
      addressNumber: $addressNumber
      city: $city
      postalCode: $postalCode
      phoneNumber: $phoneNumber
    }
  ) {
    success error
  }
}`);

export const submitTestResponsesMutation = compact(`mutation SubmitTestResponses (
  $tokenId: String!
  $secret: String!
  $transcripts: [ApplicationTestResponseInput!]!
  $time: Float!
) {
  submitTestResponses (
    authToken: {
      tokenId: $tokenId,
      secret: $secret,
    }
    transcripts: $transcripts
    time: $time
  ) {
    success error
  }
}`);
