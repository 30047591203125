import { AirbrakeLogger } from './AirbrakeLogger';
import { ConsoleLogger } from './ConsoleLogger';
import { DatadogLogger } from './DatadogLogger';
import { ErrorLoggingEvent, FullLogger, LoggerInstance, LoggingEvent } from './_types';

/**
 * A GLOBAL SINGLETON class to submit logging events to all of the loggers
 *
 * This should be used for logging events to the console in development and test
 * and external systems in production. In production, this currently logs all info,
 * warn, and error logs to Datadog and all warn and error logs to Airbrake.
 *
 * @example
 * // Logs info to console in development and test and to Datadog in production
 * Logger.getInstance().info('This an info log');
 *
 * @example
 * // Logs warn to console in development and test and to Datadog and Airbrake
 * // in production
 * Logger.getInstance().warn('This an warn log');
 */
export class Logger implements FullLogger {
  /** The GLOBAL SINGLETON instance */
  private static instance: Logger;
  /** An array of loggers */
  private _loggers: LoggerInstance[];

  private constructor() {
    this._loggers = [
      ...(process.env.NODE_ENV === 'production' ? [new AirbrakeLogger(), new DatadogLogger()] : []),
      ...(process.env.NODE_ENV === 'development' ? [new ConsoleLogger()] : []),
      ...(process.env.NODE_ENV === 'test' ? [new ConsoleLogger()] : []),
    ];
  }

  /** Gets or creates a single Logger instance */
  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  /**
   * Resets the logger instance
   *
   * This is valuable when testing, but should not be necessary in code.
   */
  public static reset() {
    Logger.instance = new Logger();
  }

  /** Triggers info logs for all loggers that implement info */
  public info(event: LoggingEvent) {
    this._loggers.forEach((logger) => {
      if (typeof logger.info === 'function') logger.info(event);
    });
  }

  /** Triggers warn logs for all loggers that implement warn */
  public warn(event: LoggingEvent) {
    this._loggers.forEach((logger) => {
      if (typeof logger.warn === 'function') logger.warn(event);
    });
  }

  /** Triggers error logs for all loggers that implement error */
  public error(event: ErrorLoggingEvent) {
    this._loggers.forEach((logger) => {
      if (typeof logger.error === 'function') logger.error(event);
    });
  }
}
