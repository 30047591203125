import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

const SelectOptionsComponent = ({ optionName, options, selectedOptions, setSelectedOptions }) => {
  const handleOptionSelection = (option) => {
    if (!optionIsSelected(option)) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      const newSelections = selectedOptions.filter((item) => item.id !== option.id);
      setSelectedOptions(newSelections);
    }
  };

  const isAllSelected = () => {
    return selectedOptions.length === options.length;
  };

  const optionIsSelected = (option) => {
    const matches = selectedOptions.filter((item) => item.id === option.id);
    return matches.length > 0;
  };

  const toggleSelectAll = () => {
    if (isAllSelected()) {
      setSelectedOptions([]);
      return;
    }

    setSelectedOptions(options);
  };

  return (
    <>
      <h5>Select {optionName}</h5>
      <Form>
        <Form.Group>
          <Form.Check
            key="0"
            type="checkbox"
            label="Select All"
            checked={isAllSelected()}
            onChange={() => toggleSelectAll()}
          />
          <hr />
          {options.map((option) => (
            <Form.Check
              key={option.id}
              type="checkbox"
              id={option.id}
              label={option.displayName}
              checked={optionIsSelected(option)}
              onChange={() => handleOptionSelection(option)}
            />
          ))}
        </Form.Group>
      </Form>
    </>
  );
};

SelectOptionsComponent.propTypes = {
  optionName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      displayName: PropTypes.string,
    })
  ),
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      displayName: PropTypes.string,
    })
  ),
  setSelectedOptions: PropTypes.func,
};

export default SelectOptionsComponent;
