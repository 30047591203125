import React from 'react';
import Button from 'react-bootstrap/Button';

const redirect = () => {
  window.location.href = 'https://www.3playmedia.com';
};

const TakeMeToThreeplayButton = () => (
  <Button variant="3play" className="mt-4" onClick={redirect}>
    Back to 3Play Media
  </Button>
);

export default TakeMeToThreeplayButton;
