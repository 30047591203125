import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { threeplayApi } from '~/logic/ThreeplayApi';

const alertLightBlue = '#d4edf8';
const alertDarkBlue = '#1a5a73';
const buttonBlue = '#68bedb';

const MUTATION = `
  mutation createTermAcceptance($data: TermAcceptanceCreateInput!) {
    createTermAcceptance(data: $data) {
      data {
        id
        createdAt
        updatedAt
      }
      errors { path code message }
    }
  }
`;

const handleSubmit = (termVersionId, setError, setIsLoading) => {
  setIsLoading(true);
  threeplayApi
    .request(MUTATION, {
      data: { termVersionId },
    })
    .then((res) => {
      const data = res.data || {};
      if (data.createTermAcceptance?.data?.id) {
        window.location.assign('/');
      }
      if (data.createTermAcceptance?.errors) {
        setError(true);
      }
    })
    .catch(() => {
      setError(true);
    });
  setIsLoading(false);
};

const TermsAcceptance = ({ activeDate, activeVersionId, legalese, needsAcceptance, termsName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      {error && (
        <Alert variant="danger" aria-live="assertive">
          <Alert.Heading className={css(styles.heading)}>Error: Login Failed</Alert.Heading>
          Something went wrong while processing your request. Please reload the page and try again.
        </Alert>
      )}
      {!needsAcceptance && (
        <Alert variant="success">You have already accepted the most recent terms.</Alert>
      )}
      <TermsAcceptanceForm
        activeDate={activeDate}
        isAccepted={isAccepted}
        legalese={legalese}
        setIsAccepted={setIsAccepted}
        termsName={termsName}
      />
      <Button
        size="lg"
        className={css(styles.button)}
        disabled={!isAccepted || !needsAcceptance || isLoading}
        type="submit"
        onClick={() => handleSubmit(activeVersionId, setError, setIsLoading)}
      >
        Accept and Continue
      </Button>
    </>
  );
};

export const TermsAcceptanceForm = ({
  activeDate,
  isAccepted,
  legalese,
  setIsAccepted,
  termsName = 'Terms Of Service and Privacy Policy',
}) => {
  const displayTermsText = () => {
    if (legalese?.length > 0) {
      return <div className={css(styles.legal)} dangerouslySetInnerHTML={{ __html: legalese }} />;
    }

    return (
      <p>
        By checking the box below, you indicate that you agree to our{' '}
        <Alert.Link className={css(styles.link)} href="https://www.3playmedia.com/account-terms/">
          Terms of Service
        </Alert.Link>{' '}
        and that you have read our{' '}
        <Alert.Link className={css(styles.link)} href="https://www.3playmedia.com/privacy-policy/">
          Privacy Policy.
        </Alert.Link>
      </p>
    );
  };

  return (
    <Alert variant="primary" className={css(styles.alert)}>
      <Alert.Heading className={css(styles.heading)}>{termsName}</Alert.Heading>
      <i>Last updated {activeDate}</i>
      {displayTermsText()}
      <Form>
        <Form.Group className="mb-3" controlId="termsAcceptanceCheckBox">
          <Form.Check
            label={`I have read and accept the ${termsName}`}
            onChange={() => {
              setIsAccepted((currentChecked) => !currentChecked);
            }}
            checked={isAccepted}
          />
        </Form.Group>
      </Form>
    </Alert>
  );
};

TermsAcceptanceForm.propTypes = {
  activeDate: PropTypes.string,
  isAccepted: PropTypes.bool,
  legalese: PropTypes.string,
  setIsAccepted: PropTypes.func,
  termsName: PropTypes.string,
};

const styles = StyleSheet.create({
  button: {
    width: '100%',
    fontWeight: 700,
    backgroundColor: buttonBlue,
    borderColor: buttonBlue,
  },
  alert: {
    backgroundColor: alertLightBlue,
    color: alertDarkBlue,
  },
  heading: {
    fontWeight: 700,
  },
  link: {
    textDecoration: 'underline',
    color: alertDarkBlue,
  },
  legal: {
    marginTop: '1rem',
  },
});

TermsAcceptance.propTypes = {
  activeDate: PropTypes.string,
  activeVersionId: PropTypes.number,
  legalese: PropTypes.string,
  needsAcceptance: PropTypes.bool,
  termsName: PropTypes.string,
};

export default TermsAcceptance;
