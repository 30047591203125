import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import { Alert, Button, Col, Row } from 'react-bootstrap';

import WithIcon from '~/components/app/common/WithIcon';

import SupplementalAssets, {
  supplementalAssetInitialState,
} from '~/components/app/media_files/supplemental_assets/SupplementalAssets';
import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';
import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { use3PMutation } from '~/logic/unstable/use3PMutation';

const AudioMixingServiceCardWrapper = (props) => {
  const apiClient = new ThreeplayApiV2('/data');
  return (
    <ThreeplayAPIProvider client={apiClient}>
      <AudioMixingServiceCard {...props} />
    </ThreeplayAPIProvider>
  );
};

function AudioMixingServiceCard({ services, fileData, isInternalUser, isManagedAccount, userTimeZone }) {
  const awaitingUpload = services.filter((service) => service.waitingForCustomer).length > 0;
  const shouldShowOopsUploadButton =
    services.filter((service) => service.canChangeMindOnNoUpload).length > 0;

  const [assetData, setAssetData] = useState(supplementalAssetInitialState);
  const [errors, setErrors] = useState([]);
  const [requiresAssetUpload, setRequiresAssetUpload] = useState(awaitingUpload);
  const [showOopsUploadButton, setShowOopsUploadButton] = useState(shouldShowOopsUploadButton);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const displayDate = (timestamp) => {
    const dateOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: userTimeZone,
      timeZoneName: 'short',
    };

    const orderedAt = new Date(parseInt(timestamp));
    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);

    return dateFormatter.format(orderedAt);
  };

  const submitEnabled = useCallback(() => {
    return assetData.acceptedFiles.every((file) => file.percentComplete === 100);
  }, [assetData]);

  const specifySupplementalAssetsMutation = `mutation SpecifySupplementalAssetsMutation($data: SupplementalAssetInput!) {
    specifySupplementalAssetsMutation(data: $data) {
      data {
        success
      }
      errors {
        code
        message
      }
    }
  }
  `;

  const { mutateAsync } = use3PMutation(specifySupplementalAssetsMutation, {
    extractKey: 'specifySupplementalAssetsMutation',
  });

  const submitFiles = async () => {
    setSubmitting(true);
    const additionalAssets = assetData.acceptedFiles.map((file) => {
      return { name: file.name, externalUrl: file.externalUrl };
    });

    const data = {
      mediaFileId: fileData.id,
      additionalAssets: additionalAssets,
      declined: assetData.declined,
      fileName: assetData.fileName,
    };
    const {
      data: responseData,
      errors,
      globalErrors: mutationGlobalErrors,
    } = await mutateAsync({
      data,
    });
    setSubmitting(false);

    if (errors) {
      setSuccess(true)
      setErrors(errors);
    } else if (mutationGlobalErrors) {
      setSuccess(true)
      setErrors(mutationGlobalErrors);
    }

    if (responseData) {
      setSuccess(true)
      setErrors([]);
    }
  };

  const toggleChangeMindOnAssets = async () => {
    setRequiresAssetUpload(true);
    setShowOopsUploadButton(false);
  };

  return (
    <>
      {services.map((service) => (
        <div key={service.id}>
          <Row>
            <Col xs={6}>
              <b>Ordered:</b> {displayDate(service.orderedAt)}
            </Col>
            <Col xs={6}>
              <b>Mix Type:</b> {service.mixType}
            </Col>
          </Row>
        </div>
      ))}
      {showOopsUploadButton && (
        <span className={css(styles.oopsUploadButton)}>
          <a href="#" onClick={() => toggleChangeMindOnAssets()}>
            <WithIcon icon="fa fa-file-text-o">I have files to upload now</WithIcon>
          </a>
        </span>
      )}
      {requiresAssetUpload && (
        <>
          <div className={css(styles.audioUploadHeader)}>Supplemental Audio Upload</div>
          <div className={css(styles.alerts)}>
            {success && errors.length === 0 && (
              <Alert variant="success">
                {assetData.declined ? (
                  <>Success!</>
                ) : (
                  <>
                    Upload succeeded
                    <ul>
                      {assetData.acceptedFiles.map((file) => (
                        <li key={file.name}>{file.name}</li>
                      ))}
                    </ul>
                  </>
                )}
              </Alert>
            )}
            {errors.length > 0 && (
              <Alert variant="danger">There was a problem with your upload</Alert>
            )}
          </div>
          {!success && errors.length === 0 && (
            <>
              <SupplementalAssets
                canDecline={true}
                assetData={assetData}
                isInternalUser={isInternalUser}
                isManagedAccount={isManagedAccount}
                setAssetData={setAssetData}
              />
              <Button
                variant="primary"
                onClick={submitFiles}
                disabled={submitting || !submitEnabled()}
                className={css(styles.submitButton)}
              >
                {submitting ? 'Submitting' : 'Submit'}
                {submitting && <span className="spinner-border spinner-border-sm ml-1"></span>}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

AudioMixingServiceCard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  fileData: PropTypes.shape({
    id: PropTypes.string,
    reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
  }),
  isInternalUser: PropTypes.bool,
  isManagedAccount: PropTypes.bool,
  userTimeZone: PropTypes.string,
};

const styles = StyleSheet.create({
  alerts: {
    marginTop: '1em',
  },
  audioUploadHeader: {
    display: 'inline-block',
    margin: '10px 10px 0',
    fontSize: '17px',
    fontWeight: 'bold',
  },
  oopsUploadButton: {
    display: 'table',
    marginLeft: 'auto',
    marginRight: '0',
    fontWeight: 'bold',
    border: '1px solid #007eb5',
    borderRadius: '5px',
    padding: '3px 8px 3px 8px',
  },
  uploadType: {
    margin: '0.25em',
    paddingLeft: '0.5rem',
  },
});

export default AudioMixingServiceCardWrapper;
