import { OutputFormatCodeEnum } from '../constants';
import { DownloadableAsset } from './OffPlatformDownloadableAssetCell';

interface Transcript {
  __typename?: string;
  offPlatformFiles?: AssetFile[];
  captionImport?: CaptionImport;
}

interface AssetFile {
  presignedDownloadUrl: string;
  assetFileFormat: {
    extension: string;
  };
}

interface CaptionImport {
  importedFilePresignedDownloadUrl: string | null;
  importedFileType: string | null;
}
interface OutputFormat {
  displayName: string;
  key: OutputFormatCodeEnum;
}

interface GenerateUrl {
  (outputFormatCode?: OutputFormatCodeEnum): string;
}

export const formatTranscriptForDownload = ({
  transcript,
  generateUrl,
  outputFormats,
  displayName = 'Source Transcript',
}: {
  transcript?: Transcript;
  generateUrl?: GenerateUrl;
  outputFormats?: OutputFormat[];
  displayName?: string;
}): DownloadableAsset[] => {
  if (
    transcript?.__typename == 'ImportedTranscriptGraphql' &&
    transcript.captionImport?.importedFilePresignedDownloadUrl
  ) {
    return formatCaptionImport(transcript.captionImport, displayName);
  }
  if (
    transcript?.__typename == 'OffPlatformTranscriptGraphql' &&
    transcript.offPlatformFiles &&
    transcript.offPlatformFiles.length != 0
  ) {
    return formatAssetFiles(transcript.offPlatformFiles, displayName);
  } else {
    if (!generateUrl || generateUrl == undefined) {
      throw new Error();
    }

    return formatOnPlatformTranscripts(generateUrl, displayName, outputFormats);
  }
};

const formatOnPlatformTranscripts = (
  generateUrl: GenerateUrl,
  displayName: string,
  outputFormats?: OutputFormat[]
): DownloadableAsset[] => {
  // if we specify output formats, the endpoint will download the transcript in the appropriate output format.
  if (outputFormats && outputFormats.length) {
    return outputFormats.map((outputFormat) => {
      const url = generateUrl(outputFormat.key);

      return { displayName: `${displayName} (${outputFormat.displayName})`, url: url };
    });
  } else {
    // if they don't specify, display default download format
    const url = generateUrl();
    return [{ displayName: displayName, url: `${window.location.origin}${url}` }];
  }
};

const formatAssetFiles = (assetFiles: AssetFile[], displayName: string): DownloadableAsset[] =>
  assetFiles.map((file) => {
    const fileFormat = file.assetFileFormat.extension;
    return { displayName: `${displayName} (${fileFormat})`, url: file.presignedDownloadUrl };
  });

const formatCaptionImport = (
  captionImport: CaptionImport,
  displayName: string
): DownloadableAsset[] => {
  const { importedFileType, importedFilePresignedDownloadUrl } = captionImport;
  if (importedFileType && importedFilePresignedDownloadUrl) {
    return [
      {
        displayName: `${displayName} (${importedFileType})`,
        url: importedFilePresignedDownloadUrl,
      },
    ];
  }
  return [];
};
