import React from 'react';

import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

function WhatsNew(props) {
  const text_below = props.whatsNew?.text_below_image;
  const header_text = props.whatsNew?.header_text;
  const header_color = props.whatsNew?.header_color;
  const link_text = props.whatsNew?.link_text;
  const link_url = props.whatsNew?.link_url;
  const link_color = props.whatsNew?.link_color;
  const image_url = props.whatsNew?.image_url;
  const text = (
    <p
      className={css(styles.description)}
      style={{ color: props.whatsNew?.description_text_color }}
    >
      {props.whatsNew?.description_text}
    </p>
  );

  return (
    <div className={css(styles.main)}>
      {text_below ? null : text}
      <img src={image_url} className={css(styles.mainImage)} />
      <h3 className={css(styles.headline)} style={{ color: header_color }}>
        {header_text}
      </h3>
      {text_below ? text : null}
      <a
        href={link_url}
        target="_blank"
        rel="noopener noreferrer"
        className={css(styles.learnMore)}
        style={{ color: link_color }}
      >
        {link_text}
      </a>
    </div>
  );
}

const styles = StyleSheet.create({
  main: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainImage: {
    maxWidth: '100%',
  },
  headline: {
    fontSize: '1.3rem',
    width: '80%',
    textAlign: 'center',
  },
  description: {
    width: '70%',
    textAlign: 'center',
  },
  learnMore: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
});

WhatsNew.propTypes = {
  whatsNew: PropTypes.object,
};

export default WhatsNew;
