// Be careful with environment values here: we build a bundle that is shared
// across dev, staging, QA, and production environments. As such, values are
// baked outside of local environment. If you need keys to be different,
// you should instead server-render them in a layout (as a script adding to
// the window or as a data attribute) and reference from the window.
//
// Alternatively, you can manually copy keys to the envByDomain block
// if it is appropriate to share across environments.

const envByDomain = {
  '3playmedia.com': {
    stage: 'production',
    threeplayEnv: 'production',
  },
  '3pmstaging.com': {
    stage: 'staging',
    threeplayEnv: 'staging',
  },
  '3paw-dev.com': {
    stage: 'development',
    threeplayEnv: 'development',
  },
  '3paw-sandbox.com': {
    stage: 'sandbox',
    threeplayEnv: 'sandbox',
  },
  'mando3play.com': {
    stage: 'qa',
    threeplayEnv: 'mando',
  },
  '3pleia.com': {
    stage: 'qa',
    threeplayEnv: '3pleia',
  },
  'bb83play.com': {
    stage: 'qa',
    threeplayEnv: 'bb8',
  },
  '3play.test': {
    stage: 'local',
    threeplayEnv: 'local',
  },
  'pr-*.3paw-dev.com': {
    stage: 'preview',
    threeplayEnv: 'preview',
  },
} as const;

/**
 * This is a lazy version to compute the environment for now, for cases
 * where we hardcode different keys.
 */
function computeDomainEnv() {
  const { hostname } = new URL(window.location.href);
  const secondLevelDomain = hostname.split('.').slice(-2).join('.') as keyof typeof envByDomain;
  const previewMatch = hostname.match(/pr-\d+.3paw-dev.com$/);

  if (previewMatch) {
    return {
      ...envByDomain['pr-*.3paw-dev.com'],
      defaultIngressDomain: '3paw-dev.com' as const,
    };
  }

  return envByDomain[secondLevelDomain]
    ? {
        defaultIngressDomain: secondLevelDomain,
        ...envByDomain[secondLevelDomain],
      }
    : {
        ...envByDomain['3play.test'],
        defaultIngressDomain: '3play.test' as const,
      };
}

const domainEnv = computeDomainEnv();

export const env = {
  nodeEnv: process.env.NODE_ENV || 'development',
  ...domainEnv,
} as const;

declare global {
  interface Window {
    __THREEPLAY_ENV__: typeof env;
  }
}

// Make it easy to debug
window.__THREEPLAY_ENV__ = env;
