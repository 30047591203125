import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import WithIcon from '~/components/app/common/WithIcon';

function UnarchiveButton(props) {
  return (
    <Button variant="light" size="sm" onClick={props.onClick}>
      <WithIcon icon="fa fa-archive">Unarchive</WithIcon>
    </Button>
  );
}

UnarchiveButton.propTypes = {
  onClick: PropTypes.func,
};

export default UnarchiveButton;
