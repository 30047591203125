import React from 'react';

import DarkOverlay from './DarkOverlay';

function BlankLoadingOverlay() {
  return (
    <DarkOverlay>
      <div className="light-loader">
        <div className="loader">
          <span></span>
        </div>
        <div className="loading-text" data-loading-message="Loading your data "></div>
      </div>
    </DarkOverlay>
  );
}

export default BlankLoadingOverlay;
