import React, { Dispatch, useCallback } from 'react';
import { Col, Collapse, Form } from 'react-bootstrap';

import { Input, Select } from '@threeplayground/index';

import { State, Action } from '../../state/builderReducer';
import { Source } from '../../types/AccessPlayerTypes';

import { AdditionalKalturaSettings } from './integrationSettings/AdditionalKalturaSettings';
import { AdditionalBrightcoveSettings } from './integrationSettings/AdditionalBrightcoveSettings';

import css from './MediaSource.module.css';
import { asyncParseSourceFromURL } from '../../helpers/parseSourceFromURL';

interface MediaSourceProps {
  availableSources: Source[];
  state: State;
  dispatch: Dispatch<Action>;
}

function sourceToUrl(source: Source) {
  switch (source.platform) {
    case 'YouTube':
      if (!source.mediaId) {
        return '';
      }
      return `https://www.youtube.com/watch?v=${source.mediaId ?? ''}`;
    case 'Kaltura':
      if (!source.kaUiconfId) {
        return ''; // we won't populate the field if this is missing
      }

      return `https://www.kaltura.com/index.php/extwidget/preview/partner_id/${source.kaPartnerId}/uiconf_id/${source.kaUiconfId}/entry_id/${source.mediaId}/embed/dynamic?`;
    case 'Brightcove':
      return `https://players.brightcove.net/${source.bcAccount}/${
        source.bcPlayer || 'default'
      }_default/index.html?videoId=${source.mediaId}`;
    case 'Wistia':
      // Sometimes we get embed.wistia.com/some hash/
      if (source.wistiaHash) {
        return `https://embed.wistia.com/deliveries/${source.wistiaHash}/`;
      }
      // Sometimes we have the customer account sub-domain, so we construct a URL from that
      if (source.wistiaAccount) {
        return `https://${source.wistiaAccount}.wistia.com/medias/${source.mediaId}`;
      }

      // If we don't have either, this should provide a reasonable fallback
      return `https://fast.wistia.net/embed/iframe/${source.mediaId}`;
    case 'native':
      return source.playback || '';
    default:
      return '';
  }
}

export function MediaSource(props: MediaSourceProps) {
  const { availableSources, state, dispatch } = props;

  const updateSourceUrl = useCallback(
    (newUrl: string | null) => {
      const url = newUrl ?? '';

      dispatch({ type: 'LOADING_PARSED_SOURCE', url });
      asyncParseSourceFromURL(url ?? '')
        .then((source) => {
          dispatch({ type: 'PARSED_SOURCE', source });
        })
        .catch(() => {
          dispatch({ type: 'FAILED_PARSED_SOURCE', url });
        });
    },
    [dispatch]
  );

  return (
    <div>
      <h6>Media Source</h6>

      <Input
        label="Media Source"
        hideLabel
        noMargin
        withTypingState
        size="small"
        readOnly={Boolean(state.loadingParsedURL)}
        placeholder="Choose or copy a media URL"
        onClick={(event) => event.currentTarget.select()}
        onUpdate={updateSourceUrl}
        prepend={({ Dropdown }) => (
          <Dropdown variant="outline-primary" title="Sources" id="input-group-dropdown-1">
            {availableSources.map((source) => {
              return (
                <Dropdown.Item
                  className={css.dropdownItem}
                  key={source.id}
                  href="#"
                  onSelect={() => dispatch({ type: 'PICKED_SOURCE', source })}
                >
                  <div className={css.dropdownItemName}>{source.displayName}</div>
                  <div className={css.dropdownUrl}>{sourceToUrl(source)}</div>
                </Dropdown.Item>
              );
            })}

            <Dropdown.Divider />

            <Dropdown.Item onSelect={() => dispatch({ type: 'PICKED_CUSTOM_SOURCE' })} href="#">
              Custom
            </Dropdown.Item>
          </Dropdown>
        )}
        value={state.loadingParsedURL ?? sourceToUrl(state.source)}
      />

      <div className={css.advancedSettings}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            dispatch({ type: 'TOGGLED_ADVANCED_SETTINGS' });
          }}
        >
          Advanced Settings
        </a>
      </div>

      <Collapse in={state.advancedSettingsVisible}>
        <div>
          <Form.Row className="mt-3">
            <Col>
              <Select
                label="Player"
                size="small"
                value={state.source.platform}
                options={
                  [
                    { id: 'YouTube', name: 'YouTube' },
                    { id: 'Kaltura', name: 'Kaltura' },
                    { id: 'Brightcove', name: 'Brightcove' },
                    { id: 'Wistia', name: 'Wistia' },
                    { id: 'native', name: 'Native HTML Player' },
                  ] as const
                }
                onSelect={(platform) => {
                  dispatch({ type: 'PICKED_PLATFORM', platform: platform.id });
                }}
              />
            </Col>

            {/*
              This column is treated specially for all platforms.

              In the case of native, we want a video URL, so we replace
              the "mediaId" field with another native specific control.

              After this, the rest of controls are based on the source.
             */}
            <Col>
              {state.source.platform === 'native' ? (
                <Select
                  label="Play as"
                  size="small"
                  defaultValue="Infer from media type"
                  options={['Infer from media type', 'Video', 'Audio'] as const}
                />
              ) : (
                <Input
                  label="Video ID"
                  name="mediaId"
                  size="small"
                  withTypingState
                  value={state.source.mediaId}
                  onFocus={(event) => event.currentTarget.select()}
                  onUpdate={(mediaId) =>
                    dispatch({ type: 'UPDATED_MEDIA_ID', mediaId: mediaId ?? '' })
                  }
                />
              )}
            </Col>
          </Form.Row>

          {state.source.platform === 'Kaltura' && <AdditionalKalturaSettings {...props} />}
          {state.source.platform === 'Brightcove' && <AdditionalBrightcoveSettings {...props} />}
        </div>
      </Collapse>
    </div>
  );
}
