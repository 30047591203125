export function getQueryParam(key: string) {
  if (!('URLSearchParams' in window)) {
    throw new Error('getQueryParam: URLSearchParams not supported');
  }

  return new URLSearchParams(window.location.search).get(key);
}

export function toggleQueryParam(name: string, val1: string, val2: string | null) {
  const value = getQueryParam(name);

  if (value === String(val1)) {
    if (val2 === null) {
      return clearQueryParam(name);
    }

    setQueryParam(name, val2);
  } else {
    setQueryParam(name, val1);
  }
}

export function setQueryParam(key: string, value: string | Date) {
  if (!('URLSearchParams' in window)) {
    throw new Error('setQueryParam: URLSearchParams not supported');
  }

  if (value instanceof Date) {
    const dateParts = value.toISOString().split('T');
    if (dateParts.length === 0) {
      throw new Error('setQueryParam: bad date');
    }
    value = dateParts[0] as string;
  }

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);

  const newPath = window.location.pathname + '?' + searchParams.toString();
  history.pushState(null, '', newPath);
}

export function clearQueryParam(key: string) {
  if (!('URLSearchParams' in window)) {
    throw new Error('clearQueryParam: URLSearchParams not supported');
  }

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);

  const newPath = window.location.pathname + '?' + searchParams.toString();
  history.pushState(null, '', newPath);
}
