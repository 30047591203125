import React from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export const PlanOverviewAlert = ({ alertVariant, header, bodyText, buttonText, onClick }) => (
  <Alert className="mt-2 mb-4" variant={alertVariant}>
    <Container fluid>
      <Row className="font-weight-bold h5">{header}</Row>
      <Row className="plan-overview-alert-text mb-2">{bodyText}</Row>
      {!!buttonText && (
        <Row>
          <Button onClick={onClick} size="md" variant="outline-primary">
            {buttonText}
          </Button>
        </Row>
      )}
    </Container>
  </Alert>
);

PlanOverviewAlert.propTypes = {
  alertVariant: PropTypes.string,
  header: PropTypes.string,
  bodyText: PropTypes.string,
  buttonText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
};

PlanOverviewAlert.defaultProps = {
  alertVariant: 'warning',
};

export default PlanOverviewAlert;
