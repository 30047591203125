import React from 'react';
import PropTypes from 'prop-types';

import MultiServiceTable from './MultiServiceTable';
import { adImportServicePath } from '~/helpers/app/paths';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';

function AudioDescriptionImportServiceCard(props) {
  const headers = ['Ordered', 'Level', 'Speaker', 'Speed', 'Status'];
  return (
    <MultiServiceTable headers={headers}>
      {props.services.map((service) => (
        <tr key={service.id}>
          <td>
            {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
          </td>
          <td>{service.level}</td>
          <td>{service.speaker}</td>
          <td>{service.speakingRate}</td>
          <td>{service.state}</td>
          <td>
            {service.state === 'Complete' && (
              <a href={adImportServicePath(service.adId)}>Preview</a>
            )}
          </td>
        </tr>
      ))}
    </MultiServiceTable>
  );
}

AudioDescriptionImportServiceCard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  userTimeZone: PropTypes.string,
};

export default AudioDescriptionImportServiceCard;
