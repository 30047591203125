import React from 'react';
import PropTypes from 'prop-types';

import { Textarea } from '@threeplayground/index';

import S3UploadComponent from '~/components/app/media_files/order_more/S3UploadComponent';
import SelectOptionsComponent from '~/components/app/media_files/order_more/SelectOptionsComponent';
import FileLinkComponent from '~/components/app/media_files/order_more/FileLinkComponent';

const RevisionsForm = ({
  mediaFile,
  options,
  selectedFile,
  selectedLanguages,
  selectedScriptingFormats,
  serviceType,
  setErrors,
  setSelectedFile,
  setSelectedLanguages,
  setSelectedScriptingFormats,
  setRevisionFileLink,
  setInstructions,
  setSourceUploaded,
  sourceUploaded,
  supportedFormats,
}) => {
  return (
    <>
      <h4>{mediaFile.name}</h4>
      <br />
      {serviceType === 'captions' && (
        <>
          <h6>
            <b>Upload File For Revision</b>
          </h6>
          <S3UploadComponent
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            sourceUploaded={sourceUploaded}
            setSourceUploaded={setSourceUploaded}
            setErrors={setErrors}
            setSelectedFileUrl={setRevisionFileLink}
            mediaFile={mediaFile}
            supportedFormats={supportedFormats}
          />
          <div style={{ textAlign: 'center', paddingBottom: '16px' }}>
            <p>Upload not required</p>
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>- OR -</p>
            <FileLinkComponent
              enabled={sourceUploaded === false}
              setFileLink={setRevisionFileLink}
            />
          </div>

          <hr />
          <h6>
            <b>File Details</b>
          </h6>
          {selectedFile ? (
            <>
              <b>Uploaded File:</b> {selectedFile.name}
            </>
          ) : (
            <>
              <b>Current File:</b> {mediaFile.name}
            </>
          )}
        </>
      )}
      <hr />
      <Textarea
        label={
          <h6 style={{ marginTop: '.6rem' }}>
            <b>Revision Instructions</b>
          </h6>
        }
        placeholder="Please enter any revision instructions here."
        onChange={(e) => setInstructions(e.target.value)}
        required
      />
      {options.languages.length > 0 && (
        <SelectOptionsComponent
          options={options.languages}
          optionName="Langauges"
          selectedOptions={selectedLanguages}
          setSelectedOptions={setSelectedLanguages}
        />
      )}
      {options.broadcastScriptingFormats.length > 0 && (
        <SelectOptionsComponent
          options={options.broadcastScriptingFormats}
          optionName="Formats"
          selectedOptions={selectedScriptingFormats}
          setSelectedOptions={setSelectedScriptingFormats}
        />
      )}
    </>
  );
};

const optionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    displayName: PropTypes.string,
  })
);

RevisionsForm.propTypes = {
  mediaFile: PropTypes.object,
  options: PropTypes.shape({
    broadcastScriptingFormats: optionsShape,
    languages: optionsShape,
    mixingOptions: PropTypes.shape({
      mix: optionsShape,
      embed: optionsShape,
    }),
  }),
  setErrors: PropTypes.func,
  selectedFile: PropTypes.shape({
    path: PropTypes.string,
    lastModified: PropTypes.number,
    lastModifiedDate: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    webkitRelativePath: PropTypes.string,
  }),
  selectedLanguages: PropTypes.any,
  selectedScriptingFormats: PropTypes.any,
  serviceType: PropTypes.string,
  setInstructions: PropTypes.func,
  setRevisionFileLink: PropTypes.func,
  setSelectedFile: PropTypes.func,
  setSelectedLanguages: PropTypes.func,
  setSelectedScriptingFormats: PropTypes.func,
  setSourceUploaded: PropTypes.func,
  sourceUploaded: PropTypes.bool,
  supportedFormats: PropTypes.arrayOf(PropTypes.string),
};

export default RevisionsForm;
