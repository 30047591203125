import React from 'react';
import PropTypes from 'prop-types';

import Table from 'react-bootstrap/Table';

import EditTableRow from './EditTableRow';
import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';

function AssignmentTable(props) {
  return (
    <Table className="wordlist-edit-table m-0">
      <thead className="wordlist-edit-table-header">
        <tr>
          <th>Wordlist Name</th>
          <th className="capitalize">{`${props.resourceType} Name`}</th>
          <th>Last Updated By</th>
          <th>Last Updated</th>
          <th>
            Wordlist
            <ThreePlayTooltip tooltipText="Edit your wordlist words or change the applied wordlist to a different one" />
          </th>
          <th className="text-center">Remove</th>
        </tr>
      </thead>
      <tbody className="wordlist-edit-table-body">
        {props.wordlistResources.map((resource) => {
          if (resource.wordlistAssignment) {
            return (
              <EditTableRow
                key={resource.id}
                openWordlistModal={props.openWordlistModal}
                removeWordlist={props.removeWordlist}
                resourceId={Number(resource.id)}
                resourceType={props.resourceType}
                showRemoveWordlistConfirmation={() =>
                  props.showRemoveWordlistConfirmation(
                    props.resourceType,
                    Number(resource.wordlistAssignment.id),
                    resource.name
                  )
                }
                wordlistResource={resource}
              />
            );
          }
        })}
      </tbody>
    </Table>
  );
}
function EditTable(props) {
  return (
    <div className="wordlist-edit-div">
      {props.wordlistResources.filter((resource) => resource.wordlistAssignment).length === 0 ? (
        ''
      ) : (
        <AssignmentTable
          openWordlistModal={props.openWordlistModal}
          resourceType={props.resourceType}
          showRemoveWordlistConfirmation={props.showRemoveWordlistConfirmation}
          wordlistResources={props.wordlistResources}
          wordlists={props.wordlistResources}
        />
      )}
    </div>
  );
}

AssignmentTable.propTypes = {
  openWordlistModal: PropTypes.func,
  resourceType: PropTypes.string,
  removeWordlist: PropTypes.func,
  wordlistResources: PropTypes.array,
};

EditTable.propTypes = {
  openWordlistModal: PropTypes.func,
  resourceType: PropTypes.string,
  showRemoveWordlistConfirmation: PropTypes.func,
  wordlistResources: PropTypes.array,
};

export default EditTable;
