function getNameCaseMap() {
  return fetch('/swatei/cased_name_list', { method: 'GET' }).then((res) => res.json());
}

// TODO: If this map is not too big, then we should pass this to the LiveEvent as
// initial props from the server as opposed to fetching it separately. This will
// avoid any potential race conditions.
export let casedNames = {};

export const initializeCasedNames = async () => {
  casedNames = await getNameCaseMap();
};
