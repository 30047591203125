import React from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import ControlTextInput from './ControlTextInput';

function round(num, precision) {
  return Math.round(num * 10 ** precision) / 10 ** precision;
}

function HoursPercentageSlider({
  controlId,
  label,
  target,
  readOnly,
  valid = true,
  totalHours,
  onChange,
}) {
  const value = Math.round(target * 100);
  return (
    <Form.Group as={Form.Row} controlId={controlId}>
      <Col lg={2} className="d-flex align-items-center">
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col className="d-flex justify-content-center">
        {!readOnly && (
          <Form.Control type="range" value={value} min={0} max={100} onChange={onChange} />
        )}
      </Col>
      <Col lg={2} className="d-flex align-items-center">
        <ControlTextInput
          value={value}
          readOnly={readOnly}
          valid={valid}
          units="%"
          onChange={readOnly ? null : onChange}
        />
      </Col>
      <Col lg={1} className="d-flex align-items-center">
        {round(target * totalHours, 1)}&nbsp;hrs
      </Col>
    </Form.Group>
  );
}

HoursPercentageSlider.propTypes = {
  controlId: PropTypes.string,
  label: PropTypes.node,
  target: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  totalHours: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

export default HoursPercentageSlider;
