import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import WithIcon from '~/components/app/common/WithIcon';

function ServiceItem(props) {
  const [open, setOpen] = useState(true);

  return (
    <React.Fragment>
      <div className={css(styles.eachService)}>
        <h5 className={css(styles.eachServiceName)}>{props.service.displayType}</h5>
        <div className="service-status-icon">
          <div className={css(styles.serviceStatus)}>
            <div className={props.service.statusColor}>
              <WithIcon icon={props.service.statusIcon}>
                <b>{props.service.status}</b>
              </WithIcon>
            </div>
          </div>
        </div>
        <button
          className={css(styles.eachService, styles.showHideButton)}
          onClick={() => setOpen(!open)}
        >
          Hide Details
        </button>
      </div>
      {open && <div>banannana</div>}
    </React.Fragment>
  );
}

ServiceItem.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    displayType: PropTypes.string,
    status: PropTypes.string,
    statusIcon: PropTypes.string,
    statusColor: PropTypes.string,
  }),
};

const styles = StyleSheet.create({
  eachService: {
    padding: '2px 13px',
  },
  eachServiceName: {
    display: 'inline-block',
    margin: '3px 0px',
  },
  serviceStatus: {
    display: 'inline-block',
    paddingLeft: '4px',
  },
  showHideButton: {
    fontWeight: 'bold',
    float: 'right',
    color: '#007EB5',
    border: 'none',
    background: 'none',
    paddingTop: '2px',
  },
});

export default ServiceItem;
