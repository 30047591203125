import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import styles from '~/components/app/order_form/serviceDetails/serviceDetailStyles';

const BroadcastScriptDetails = ({ selectedFormats }) => (
  <>
    {selectedFormats.map((format) => {
      return (
        <div key={format.code} className={css(styles.serviceOptionHeader)}>
          <div>{format.name}</div>
          {format.displayPrice && (
            <div className={css(styles.serviceOptionPrice)}>{format.displayPrice}</div>
          )}
        </div>
      );
    })}
  </>
);

BroadcastScriptDetails.propTypes = {
  selectedFormats: PropTypes.arrayOf(PropTypes.object),
};

export default BroadcastScriptDetails;
