import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';

import FileUpload from '~/components/app/media_files/ShowPanel/file_details/FileUpload';

import { awsUploadFileQuery } from '~/components/app/order_form/data/queries';
import { replaceSourceMutation } from '~/components/app/media_files/ShowPanel/data/mutations';

import { threeplayApi } from '~/logic/ThreeplayApi';
import FileLinkComponent from '~/components/app/media_files/order_more/FileLinkComponent';

function ReplaceSourceModal({
  canReplaceSourceWithLinks,
  currentFileIsProof,
  fileId,
  fileName,
  onClose,
  onUpdate,
  show,
  supportedFormats,
}) {
  const [confirmationCheckBox, setConfirmationCheckBox] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [file, setFile] = useState(null);
  const [uploadedFileIsFinal, setUploadedFileIsFinal] = useState(false);
  const [replaceSourceLink, setReplaceSourceLink] = useState('');
  const [sourceUploaded, setSourceUploaded] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (uploadingProgress === 100 && !sourceUploaded) {
      setSourceUploaded(true);
      replaceSource();
    }
  }, [uploadingProgress]);

  async function fetchUploadData(file) {
    const response = await threeplayApi.request(awsUploadFileQuery, {
      fileId: fileId,
      fileName: file.name,
      type: 'replaceSource',
    });
    return response.data.project.awsUpload;
  }

  async function handleNewFile() {
    if (validForm(file)) {
      if (replaceSourceLink.length === 0) {
        const awsData = await fetchUploadData(file);
        uploadFileToS3(awsData);
      } else {
        replaceSource();
      }
    }
  }

  function uploadFileToS3(awsData) {
    const payload = buildFormData(awsData);
    setUploading(true);

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', uploadProgress, false);
    xhr.addEventListener('error', uploadFailed, false);
    xhr.addEventListener('abort', uploadCanceled, false);
    xhr.open('POST', awsData.amazonUrl, true);
    xhr.send(payload);
  }

  function uploadProgress(evt) {
    if (evt.lengthComputable) {
      const percentComplete = Math.round((evt.loaded * 100) / evt.total);
      setUploadingProgress(percentComplete);
    }
  }

  async function replaceSource() {
    const fileName = file ? file.name : 'placeholder';
    const response = await threeplayApi.request(replaceSourceMutation, {
      fileId: fileId,
      fileName: fileName,
      externalFileUrl: replaceSourceLink,
      isFinal: uploadedFileIsFinal,
    });
    if (response.errors) {
      setError(true);
      if (response.errors[0].message === 'Invalid URL') {
        setErrorMessage('Invalid URL'); // Carving out this case to provide a more specific error message
      } else {
        setErrorMessage('There was an error while uploading your file. Please try again.');
      }
      return;
    }
    if (response.data.replaceSource.success) {
      setError(false);
      setSuccess(true);
      setSuccessMessage(`You have successfully added your source file.
      Please note that it may take some time for your video to be processed.`);
      onUpdate();
      resetForm();
      setTimeout(onClose, 3000);
    }
  }

  // Failed - Show error message
  function uploadFailed() {
    setError(true);
    setErrorMessage('There was an error attempting to upload the file.');
    setUploading(false);
  }

  // Cancelled - Show error message
  function uploadCanceled() {
    setError(true);
    setErrorMessage(
      'The upload has been canceled by the user or the browser dropped the connection.'
    );
    setUploading(false);
  }

  function validForm(file) {
    if (!file && replaceSourceLink.length === 0) {
      setError(true);
      setErrorMessage("You haven't selected a file to be uploaded");
      return false;
    }

    if (!confirmationCheckBox) {
      setError(true);
      setErrorMessage("You haven't accepted confirmation for file overwrite");
      return false;
    }

    setError(false);
    return true;
  }

  function buildFormData(awsData) {
    const formData = new FormData();
    formData.append('key', awsData.key);
    formData.append('AWSAccessKeyId', awsData.accessKey);
    formData.append('acl', 'private');
    formData.append('policy', awsData.policy);
    formData.append('signature', awsData.signature);
    formData.append('success_action_status', 200);
    formData.append('file', file);
    return formData;
  }

  function displayError() {
    return <Alert variant="error">{errorMessage}</Alert>;
  }

  function displaySuccess() {
    return <Alert variant="success">{successMessage}</Alert>;
  }

  function resetForm() {
    setConfirmationCheckBox(false);
    setUploadingProgress(0);
    setUploading(false);
    setSuccess(false);
  }

  function canProvideLink() {
    return canReplaceSourceWithLinks && file === null;
  }

  function closeModal() {
    // TODO: Fix this
    /**
     * Currently, when the modal closes, any previously uploaded file remains in state, but is not visible to the user
     * There is currently no way to remove the file from the UI, so closing and reopening the modal appears to have done
     * that, but the field to provide a link remains disabled. Overall, unexpected behaviour. This attempts to make the
     * modal behave as one would expect given what's displayed in the UI.
     */
    setFile(null);
    onClose();
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Replace Source Video</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && displayError()}
        {success && displaySuccess()}
        <p>
          Uploading a new file will delete your existing file permanently and replace it will a new
          source video.
        </p>
        <p>
          <b>Note:</b> that if the new source media does not match with the text synchronization, it
          will not be re-synchronized.
        </p>
        <FileUpload supportedFormats={supportedFormats} setFile={setFile} />
        {uploading && <ProgressBar variant="success" now={uploadingProgress} striped />}

        {canReplaceSourceWithLinks && (
          <div style={{ marginTop: '1rem', marginBottom: '2rem' }}>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>- OR -</p>
            <FileLinkComponent enabled={canProvideLink()} setFileLink={setReplaceSourceLink} />
          </div>
        )}

        <Form.Check
          type="checkbox"
          label={`By uploading this file, I am confirming that it will overwrite my existing file for ${fileName}.`}
          checked={confirmationCheckBox}
          onChange={() => setConfirmationCheckBox(!confirmationCheckBox)}
        />
        {currentFileIsProof && (
          <Form.Check
            type="checkbox"
            label={`The file I am uploading is the final version of ${fileName}.`}
            checked={uploadedFileIsFinal}
            onChange={() => setUploadedFileIsFinal(!uploadedFileIsFinal)}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button className="float-left" variant="primary" onClick={handleNewFile}>
          Update
        </Button>
        <Button className="float-right" variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ReplaceSourceModal.propTypes = {
  canReplaceSourceWithLinks: PropTypes.bool,
  currentFileIsProof: PropTypes.bool,
  fileId: PropTypes.string,
  fileName: PropTypes.string,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  show: PropTypes.bool,
  supportedFormats: PropTypes.string,
};

export default ReplaceSourceModal;
