import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ScheduledOrdersModal from './ScheduledOrdersModal';
import ScheduledOrdersTable from './ScheduledOrdersTable';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

const ScheduledOrders = ({
  transcriptionLanguages,
  fromEnglishLanguages,
  toEnglishLanguages,
  transperfectNoticeRecords,
}) => {
  const [show, setShow] = useState(false);
  return (
    <div className={css(styles.shiftLeft)}>
      <h1 className={css(styles.scheduledTitle)}>Scheduled Orders</h1>
      <Button
        variant="primary"
        onClick={() => {
          setShow(true);
        }}
        className={css(styles.addButton)}
      >
        Add Scheduled Order
      </Button>
      <ScheduledOrdersModal
        show={show}
        handleClose={() => setShow(false)}
        transcriptionLanguages={transcriptionLanguages}
        fromEnglishLanguages={fromEnglishLanguages}
        toEnglishLanguages={toEnglishLanguages}
      />
      <ScheduledOrdersTable transperfectNoticeRecords={transperfectNoticeRecords} />
    </div>
  );
};

const styles = StyleSheet.create({
  addButton: {
    margin: '30px 0px',
    padding: '10px 25px',
    borderWidth: '2px',
    fontWeight: 'bold',
    fontSize: '14px',
    ':hover': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  shiftLeft: {
    marginLeft: '15px',
  },
});

ScheduledOrders.propTypes = {
  transcriptionLanguages: PropTypes.arrayOf(PropTypes.shape({ fullName: PropTypes.string })),
  fromEnglishLanguages: PropTypes.arrayOf(PropTypes.string),
  toEnglishLanguages: PropTypes.arrayOf(PropTypes.string),
  transperfectNoticeRecords: PropTypes.arrayOf(
    PropTypes.shape({
      order_option_type: PropTypes.string,
      start_time: PropTypes.string,
      end_time: PropTypes.string,
      file_count_limit: PropTypes.string,
      total_duration_limit: PropTypes.sting,
      requested_by_user_email: PropTypes.string,
      discount_code: PropTypes.string,
      turnaround: PropTypes.string,
    })
  ),
};

export default ScheduledOrders;
