import React from 'react';
import PropTypes from 'prop-types';

import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ad_badge_variants = {
  Pending: 'danger',
  Extended: 'info',
  Uncertain: 'warning',
  Standard: 'success',
};

function SuggestedAudioDescriptionLevel(props) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          This is our automated recommendation based on an evaluation of natural pauses in your
          audio track.
        </Tooltip>
      }
    >
      <Badge variant={ad_badge_variants[props.value]}>{props.value}</Badge>
    </OverlayTrigger>
  );
}

SuggestedAudioDescriptionLevel.propTypes = {
  value: PropTypes.string,
};

export default SuggestedAudioDescriptionLevel;
