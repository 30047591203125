import { Formatter } from '../_types';

export class DecapitalizeAfterApostropheFormatter implements Formatter {
  /**
   * A formatter that capitalizes the first person singular pronoun I.
   *
   * @example new DecapitalizeAfterApostropheFormatter().format("hi I'M Bob")
   * // returns "hi I'm Bob"
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  format(text: string) {
    return text.replace(/(')(\w)/g, (match, p1: string, p2: string) => `${p1}${p2.toLowerCase()}`);
  }
}
