import React from 'react';
import { Card } from '@threeplayground/unstable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

interface JobInstructionInterface {
  translationProfile: TranslationProfileType | null;
}

type TranslationProfileType = {
  id: string;
  name: string;
  s3Url: string;
};

export function OffPlatformLocalizationJobInstructions({
  translationProfile,
}: JobInstructionInterface) {
  return (
    <Card>
      <Card.Header className="font-weight-bold">
        <FontAwesomeIcon icon={faExclamationCircle} /> Instructions
      </Card.Header>
      <Card.Body>
        <a
          key={translationProfile?.id}
          href={translationProfile?.s3Url}
          target="_blank"
          rel="noreferrer"
        >
          {translationProfile?.name}
        </a>
      </Card.Body>
    </Card>
  );
}
