import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import { css, StyleSheet } from 'aphrodite';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { encodedCaptionsQuery } from '~/components/app/media_files/ShowPanel/data/queries';
import { encodedCaptionsMutation } from '~/components/app/media_files/ShowPanel/data/mutations';

function EditEncodedCaptionsModal(props) {
  const fontSizes = [
    {
      id: 0,
      value: 'Use Default',
    },
    {
      id: 10,
      value: 10,
    },
    {
      id: 12,
      value: 12,
    },
    {
      id: 14,
      value: 14,
    },
    {
      id: 16,
      value: 16,
    },
    {
      id: 20,
      value: 20,
    },
    {
      id: 24,
      value: 24,
    },
    {
      id: 30,
      value: 30,
    },
    {
      id: 36,
      value: 36,
    },
    {
      id: 48,
      value: 48,
    },
    {
      id: 60,
      value: 60,
    },
    {
      id: 72,
      value: 72,
    },
    {
      id: 84,
      value: 84,
    },
    {
      id: 100,
      value: 100,
    },
    {
      id: 120,
      value: 120,
    },
    {
      id: 140,
      value: 140,
    },
    {
      id: 160,
      value: 160,
    },
  ];

  const verticalMargins = [
    {
      id: 0,
      value: 'Use Default',
    },
    {
      id: 10,
      value: 10,
    },
    {
      id: 25,
      value: 25,
    },
    {
      id: 50,
      value: 50,
    },
    {
      id: 100,
      value: 100,
    },
    {
      id: 150,
      value: 150,
    },
    {
      id: 200,
      value: 200,
    },
    {
      id: 250,
      value: 250,
    },
    {
      id: 300,
      value: 300,
    },
    {
      id: 350,
      value: 350,
    },
    {
      id: 400,
      value: 400,
    },
  ];

  const [encodedCaption, setEncodedCaption] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  // Default values on load
  const [selectedFontSize, setFontSize] = useState(0);
  const [selectedCaptionLines, setCaptionLines] = useState(0);
  const [selectedVerticalMargin, setVerticalMargin] = useState(0);

  useEffect(() => {
    if (props.encodedCaptionId) {
      threeplayApi
        .request(encodedCaptionsQuery, {
          encodedCaptionId: props.encodedCaptionId,
        })
        .then((response) => {
          setEncodedCaption(response.data.encodedCaptions);
        });
    }
  }, [props.encodedCaptionId]);

  function handleReprocess() {
    const variables = {
      encodedCaptionId: props.encodedCaptionId,
      fontSize: selectedFontSize,
      captionLines: selectedCaptionLines,
    };
    if (encodedCaption.verticalMarginEnabled) {
      variables['verticalMargin'] = selectedVerticalMargin;
    }
    threeplayApi.request(encodedCaptionsMutation, variables).then((response) => {
      if (response.error) {
        setError(response.error);
      }

      if (response.data.encodedCaptions.success) {
        setSuccess(true);
        setTimeout(() => {
          handleClose();
          props.onUpdate();
        }, 3000);
      }
    });
  }

  function handleClose() {
    props.onClose();
  }

  function successMessage() {
    return <Alert variant="success">You have successfully submitted your Encoded Caption!</Alert>;
  }

  function errorMessage() {
    return <Alert variant="error">{error}</Alert>;
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Encoded Caption Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success && successMessage()}
        {error && errorMessage()}
        <Form>
          <Form.Group as={Row}>
            <Form.Label className={css(styles.bold)} column sm={4}>
              Current Font Size (in pixels)
            </Form.Label>
            <Col sm={8}>
              <Form.Control plaintext readOnly defaultValue={encodedCaption.currentFontSize} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="fontSize">
            <Form.Label className={css(styles.bold)} column sm={4}>
              Font Size (in pixels)
            </Form.Label>
            <Col sm={8}>
              <Form.Control as="select" size="sm" onChange={(e) => setFontSize(e.target.value)}>
                {fontSizes.map((fontSize) => {
                  return (
                    <option key={fontSize.id} value={fontSize.id}>
                      {fontSize.value}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="captionFrameLines">
            <Form.Label className={css(styles.bold)} column sm={4}>
              Caption Lines
            </Form.Label>
            <Col sm={8}>
              <Form.Control as="select" size="sm" onChange={(e) => setCaptionLines(e.target.value)}>
                <option key={0} value={0}>
                  Two Lines Per Caption
                </option>
                <option key={1} value={1}>
                  One Line Per Caption
                </option>
              </Form.Control>
            </Col>
          </Form.Group>
          {encodedCaption.verticalMarginEnabled && (
            <>
              <Form.Group as={Row}>
                <Form.Label className={css(styles.bold)} column sm={4}>
                  Current Vertical Margin (in pixels)
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={encodedCaption.currentVerticalMargin}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="verticalMargins">
                <Form.Label className={css(styles.bold)} column sm={4}>
                  Vertical Margin (in pixels)
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    as="select"
                    size="sm"
                    onChange={(e) => setVerticalMargin(e.target.value)}
                  >
                    {verticalMargins.map((verticalMargin) => {
                      return (
                        <option key={verticalMargin.id} value={verticalMargin.id}>
                          {verticalMargin.value}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </>
          )}
        </Form>
        <Alert variant="primary">
          Please note that it may take up to 24 hours for a new version of encoded captions to
          process.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" size="sm" onClick={handleReprocess}>
          Re-process Encoded Captions
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

EditEncodedCaptionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  handleModalChange: PropTypes.func,
  encodedCaptionId: PropTypes.string,
};

const styles = StyleSheet.create({
  bold: {
    fontWeight: '600',
  },
});

export default EditEncodedCaptionsModal;
