// Contains snippets from https://github.com/WowzaMediaSystems/webrtc-examples

export class WebRTCConnection {
  wssUrl;
  applicationName;
  streamName;
  videoElement;
  clearHeartbeatIntervalId;
  retryConnectInterval;
  streamInfo;
  peerConnection = null;
  wsConnection = null;
  repeaterRetryCount = 0;
  userData = null;

  constructor({ videoUrl, retryConnectInterval = 10000 }) {
    if (!videoUrl) {
      throw new Error(`WebRTCConnection: Missing valid videoUrl`);
    }

    this.wssUrl = videoUrl.url;
    this.applicationName = videoUrl.app_name;
    this.streamName = videoUrl.stream_name;
    this.videoElement = document.querySelector('video');
    this.retryConnectInterval = retryConnectInterval;
    this.streamInfo = {
      applicationName: videoUrl.app_name,
      streamName: videoUrl.stream_name,
      sessionId: null,
    };
  }

  // Public Methods
  connect = () => {
    const connectionState = this.peerConnection?.connectionState;
    // Do nothing if there is an existing healthy stream
    if (
      connectionState === 'new' ||
      connectionState === 'connected' ||
      connectionState === 'connecting'
    ) {
      return;
    }

    // If there are unhealthy connections, clean them up
    if (
      connectionState === 'failed' ||
      connectionState === 'disconnected' ||
      connectionState === 'closed'
    ) {
      this.stop();
      return;
    }

    // If there are no active streams, attempt to connect
    if (!this.peerConnection && !this.wsConnection) {
      this.userData = { iceServers: [] };
      this.peerConnection = new RTCPeerConnection(this.userData);
      this.repeaterRetryCount = 0;

      try {
        this.wsConnection = new WebSocket(this.wssUrl);
      } catch (e) {
        console.log(e);
      }

      this.wsConnection.binaryType = 'arraybuffer';
      this.wsConnection.onopen = this.handleWebSocketOpen;
      this.wsConnection.onmessage = this.handleWebSocketMessage;
      this.wsConnection.onclose = this.handleWebSocketClose;
      this.wsConnection.onerror = this.handleWebSockerError;
    }

    // This kickstarts the heartbeat, which will run throughout the life of the instance
    if (!this.clearHeartbeatIntervalId) {
      this.startHeartbeat();
    }
  };

  stop = () => {
    if (this.peerConnection !== null) {
      this.peerConnection.close();
    }
    if (this.wsConnection !== null) {
      this.wsConnection.close();
    }

    this.peerConnection = null;
    this.wsConnection = null;
  };

  cleanup = () => {
    if (this.clearHeartbeatIntervalId) {
      clearInterval(this.clearHeartbeatIntervalId);
    }
    this.stop();
  };

  // Private Methods

  // This method will poll the stream, and attempt to reconnect if not already connected.
  startHeartbeat = () => {
    if (!this.clearHeartbeatIntervalId) {
      this.clearHeartbeatIntervalId = setInterval(this.connect, this.retryConnectInterval);
    }
  };

  handleWebSocketOpen = () => {
    console.log('onopen');
    this.peerConnection.ontrack = (evt) => {
      console.log('gotRemoteTrack: kind:' + evt.track.kind + ' stream:' + evt.streams[0]);
      try {
        this.videoElement.srcObject = evt.streams[0];
      } catch (error) {
        this.videoElement.src = window.URL.createObjectURL(evt.streams[0]);
      }
    };
    this.sendPlayGetOffer();
  };

  handleWebSocketMessage = (evt) => {
    console.log('wsConnection.onmessage: ' + evt.data);
    let msgJSON = JSON.parse(evt.data);
    let msgStatus = Number(msgJSON['status']);
    let msgCommand = msgJSON['command'];

    // repeater stream not ready
    if (msgStatus >= 500) {
      this.repeaterRetryCount++;
      if (this.repeaterRetryCount < 10) {
        setTimeout(this.sendPlayGetOffer, 500);
      } else {
        console.log('Live stream repeater timeout: ' + this.streamName);
        this.stop();
      }
    }

    // Error
    else if (msgStatus !== 200) {
      console.log(msgJSON['statusDescription']);
      this.stop();
    } else {
      const streamInfoResponse = msgJSON['streamInfo'];
      if (streamInfoResponse !== undefined) {
        this.streamInfo.sessionId = streamInfoResponse.sessionId;
      }

      const sdpData = msgJSON['sdp'];
      if (sdpData != null) {
        console.log('sdp: ' + JSON.stringify(msgJSON['sdp']));

        // Copy-paste from Wowza's example
        if (this.mungeSDP !== null) {
          msgJSON.sdp.sdp = this.mungeSDP(msgJSON.sdp.sdp);
        }

        // Enhance here if Safari is a published stream.
        console.log('SDP Data: ' + msgJSON.sdp.sdp);

        this.peerConnection
          .setRemoteDescription(new RTCSessionDescription(msgJSON.sdp))
          .then(() =>
            this.peerConnection.createAnswer().then((description) => {
              this.peerConnection
                .setLocalDescription(description)
                .then(() => {
                  console.log('sendAnswer');
                  this.wsConnection.send(`
                      {
                        "direction": "play",
                        "command": "sendResponse",
                        "streamInfo": ${JSON.stringify(this.streamInfo)},
                        "sdp": ${JSON.stringify(description)},
                        "userData": ${JSON.stringify(this.userData)}
                      }
                    `);
                  this.videoElement.play();
                })
                .catch((err) => console.log('set local description error', err));
            })
          )
          .catch((err) => console.log('set remote description error', err));
      }

      const iceCandidates = msgJSON['iceCandidates'];
      if (iceCandidates !== null) {
        for (let index in iceCandidates) {
          console.log('iceCandidates: ' + JSON.stringify(iceCandidates[index]));
          this.peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidates[index]));
        }
      }
    }

    if ('sendResponse'.localeCompare(msgCommand) == 0) {
      if (this.wsConnection !== null) {
        this.wsConnection.close();
      }
      this.wsConnection = null;
    }
  };

  handleWebSocketClose = () => {
    console.log('wsConnection.onclose');
  };

  handleWebSockerError = (evt) => {
    console.log(`wsConnection.error: ${evt}`);
    this.stop();
  };

  sendPlayGetOffer = () => {
    console.log('sendPlayGetOffer: ' + JSON.stringify(this.streamInfo));
    this.wsConnection.send(`
      {
        "direction": "play",
        "command": "getOffer",
        "streamInfo": ${JSON.stringify(this.streamInfo)},
        "userData": ${JSON.stringify(this.userData)}
      }
    `);
  };

  // Copy-paste from Wowza's example
  mungeSDP(sdpStr) {
    // For greatest playback compatibility,
    // force H.264 playback to baseline (42e01f).
    let sdpLines = sdpStr.split(/\r\n/);
    let sdpStrRet = '';

    for (var sdpIndex in sdpLines) {
      let sdpLine = sdpLines[sdpIndex];

      if (sdpLine.length == 0) continue;

      if (sdpLine.includes('profile-level-id')) {
        // The profile-level-id string has three parts: XXYYZZ, where
        //   XX: 42 baseline, 4D main, 64 high
        //   YY: constraint
        //   ZZ: level ID
        // Look for codecs higher than baseline and force downward.
        const profileLevelId = sdpLine.substr(sdpLine.indexOf('profile-level-id') + 17, 6);
        let profile = Number('0x' + profileLevelId.substr(0, 2));
        let constraint = Number('0x' + profileLevelId.substr(2, 2));
        let level = Number('0x' + profileLevelId.substr(4, 2));
        if (profile > 0x42) {
          profile = 0x42;
          constraint = 0xe0;
          level = 0x1f;
        }
        let newProfileLevelId =
          ('00' + profile.toString(16)).slice(-2).toLowerCase() +
          ('00' + constraint.toString(16)).slice(-2).toLowerCase() +
          ('00' + level.toString(16)).slice(-2).toLowerCase();

        sdpLine = sdpLine.replace(profileLevelId, newProfileLevelId);
      }

      sdpStrRet += sdpLine;
      sdpStrRet += '\r\n';
    }

    return sdpStrRet;
  }
}
