import React, { useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'react-bootstrap';
import {
  SERVICE_START_INDEX,
  ENGLISH_ID,
  SPANISH_ID,
  ENGLISH_UK_ID,
  SPANISH_SPAIN_ID,
} from '~/helpers/constants';
import { userLogger } from '~/logic/UserLogger';

import {
  selectedServicesType,
} from '~/components/app/order_form/propTypes';
import ServiceCard from '~/components/app/order_form/ServiceCard';
import TranscriptionOrderOptions from '~/components/app/order_form/serviceOptions/TranscriptionOrderOptions';
import AlignmentOrderOptions from '~/components/app/order_form/serviceOptions/AlignmentOrderOptions';
import AsrOrderOptions from '~/components/app/order_form/serviceOptions/AsrOrderOptions';

import { ProjectContext } from '../OrderForm';
import SurchargeNote from '~/components/app/order_form/SurchargeNote';
import TemplateSelection from '~/components/app/order_form/TemplateSelection';
import SelectSeries from '~/components/app/order_form/SelectSeries';

function ChooseService(props) {
  const { accountType, features, orderableServices, orderTemplates } = useContext(ProjectContext);
  useEffect(() => {
    userLogger.logEvent('NewOrder', 'Choose Primary Service Page', {});
  }, []);

  const transcriptionService = props.selectedServices.find(
    (s) => s.serviceType === 'Transcription'
  );
  const asrService = props.selectedServices.find((s) => s.serviceType === 'Asr');
  const alignmentService = props.selectedServices.find((s) => s.serviceType === 'Alignment');

  const transcriptionLanguage =
    (transcriptionService &&
      transcriptionService.orderOptions &&
      transcriptionService.orderOptions.language &&
      transcriptionService.orderOptions.language.ids) ||
    [];

  function toggleService(serviceType, price, orderOptions) {
    const service = orderableServices.find((service) => service.serviceType === serviceType);
    const description = service.description;
    const displayName = service.displayName;
    const serviceInfo = {
      serviceType: serviceType,
      displayName: displayName,
      price: price,
      orderOptions: orderOptions,
      primary: true,
      description: description,
    };
    const serviceAlreadyIncluded = props.selectedServices.some(
      (s) => s.serviceType === serviceType
    );
    if (serviceAlreadyIncluded) {
      props.setSelectedServices([]);
      props.setAvailableAdditionalServices([]);
    } else {
      props.setSelectedServices([serviceInfo]);
      const additionalServices = orderableServices.find(
        (service) => service.serviceType === serviceType
      ).additionalServices;
      props.setAvailableAdditionalServices(additionalServices);
    }
  }

  function shouldDisplaySurchargeNote() {
    return (
      !accountType.express &&
      !!features.difficulty_surcharge_note &&
      (transcriptionLanguage.includes(ENGLISH_ID) ||
        transcriptionLanguage.includes(SPANISH_ID) ||
        transcriptionLanguage.includes(ENGLISH_UK_ID) ||
        transcriptionLanguage.includes(SPANISH_SPAIN_ID))
    );
  }

  function RenderServiceCard() {
    return (
      <>
        <div className={css(styles.flex)}>
          {orderTemplates.length > 0 && (
            <TemplateSelection
              selectedTemplate={props.selectedTemplate}
              setLoadedTemplate={props.setLoadedTemplate}
              setSelectedTemplate={props.setSelectedTemplate}
              templates={orderTemplates}
            />
          )}
        </div>
        {props.showSeriesForm && (
          <Row>
            <Col>
              <span className={css(styles.folderOrTemplateForm)}>
                <SelectSeries series={props.series} setSeries={props.setSeries} />
              </span>
            </Col>
          </Row>
        )}
        <div className={css(styles.flex)}>
          {orderableServices
            ?.filter(function (service) {
              return ['Transcription', 'Alignment', 'Asr'].includes(service.serviceType);
            })
            .map((service, index) => (
              <ServiceCard
                key={service.serviceType}
                index={index + SERVICE_START_INDEX}
                displayName={service.displayName}
                serviceType={service.serviceType}
                serviceDisabledTooltip={''}
                price={service.price}
                description={service.description}
                additionalServices={service.additionalServices}
                selectedServices={props.selectedServices}
                toggleService={toggleService}
                role="radio"
              />
            ))}
        </div>
      </>
    );
  }

  return (
    <>
      {!!features.other && <RenderServiceCard />}
      {shouldDisplaySurchargeNote() && <SurchargeNote />}
      {props.selectedServices.filter((service) => service.serviceType === 'Transcription').length >
        0 && (
        <TranscriptionOrderOptions
          cheatSheetComponent={props.cheatSheetComponent('transcriptionService')}
          orderOptions={transcriptionService.orderOptions}
          transcriptionVendorId={props.transcriptionVendorId}
          updateOrderOptions={props.updateOrderOptions}
        />
      )}
      {props.selectedServices.filter((service) => service.serviceType === 'Alignment').length >
        0 && (
        <AlignmentOrderOptions
          orderOptions={alignmentService.orderOptions}
          updateOrderOptions={props.updateOrderOptions}
        />
      )}
      {props.selectedServices.filter((service) => service.serviceType === 'Asr').length > 0 && (
        <AsrOrderOptions
          orderOptions={asrService.orderOptions}
          updateOrderOptions={props.updateOrderOptions}
        />
      )}
    </>
  );
}

ChooseService.propTypes = {
  cheatSheetComponent: PropTypes.func,
  selectedServices: selectedServicesType,
  selectedTemplate: PropTypes.string,
  series: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  setAvailableAdditionalServices: PropTypes.func,
  setLoadedTemplate: PropTypes.func,
  setSelectedServices: PropTypes.func,
  setSelectedTemplate: PropTypes.func,
  setSeries: PropTypes.func.isRequired,
  showSeriesForm: PropTypes.bool,
  transcriptionVendorId: PropTypes.number,
  updateOrderOptions: PropTypes.func,
};

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-10px',
  },
  stepTitle: {
    'font-weight': 'bold',
    'margin-bottom': '.25rem',
  },
});
export default ChooseService;
