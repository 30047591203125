const linkedAccountMutation = `
  mutation RemoveLinkedAccount($integrationId: ID!){
    deleteLinkedAccount(integrationId: $integrationId){ 
      success
    }
  }
`;

const refreshEnsembleLibrariesMutation = `
  mutation RefreshEnsembleLibraries($videoPlatformIntegrationId: ID!) {
    refreshEnsembleLibraries(videoPlatformIntegrationId: $videoPlatformIntegrationId) {
      errors,
      ensembleLibraries {
        ensembleId,
        name
      }
    }
  }
`;

export { linkedAccountMutation, refreshEnsembleLibrariesMutation };
