import { env } from '~/core/env';

export interface VersionManifest {
  'access-player': string[];
}

const LOCAL_URL = 'http://localhost:8787';
const PROD_URL = 'https://view.3playmedia.com';
const HOST = env.threeplayEnv === 'local' ? LOCAL_URL : PROD_URL;

export async function versionQuery() {
  const response = await fetch(`${HOST}/assets/metadata/version-manifest.json`);

  if (!response.ok) {
    throw new Error(`Failed to load versions: ${response.status} ${response.statusText}`);
  }

  const data = (await response.json()) as VersionManifest;
  return data['access-player'];
}
