import React from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { numberToCurrency } from '~/helpers/numbers';

function TermInvoices(props) {
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={2}>
            <Row className="h5 text-secondary">Term Invoices</Row>
          </Col>
          <Col>
            <Table striped bordered size="sm">
              <thead>
                <tr>
                  <th>Invoice ID</th>
                  <th>Invoice Amount</th>
                  <th>Line Items</th>
                  <th>State</th>
                </tr>
              </thead>
              <tbody>
                {props.termInvoices.map((invoice, index) => (
                  <tr key={index}>
                    <td>
                      <a href={'/invoices/' + invoice.id}>{invoice.id}</a>
                    </td>
                    <td>{numberToCurrency.format(invoice.amount)}</td>
                    <td>{invoice?.line_items?.length || 0}</td>
                    <td>{invoice.paid_at ? 'paid' : 'unpaid'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
TermInvoices.propTypes = {
  termInvoices: PropTypes.arrayOf(PropTypes.object),
};
export default TermInvoices;
