type This = typeof globalThis;
interface PageGlobals extends This {
  setRightNavScrollingDisplay: () => void; // Defined in the index template
  setCalendarSidebarVisibility: (visibility: 'open' | 'closed') => void; // Defined in the index template
  Turbo: {
    visit: (location: string, options: { frame: string }) => void;
  };
}

const globalObj = global as unknown as PageGlobals;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, View } from 'react-big-calendar';

import {
  AnyTimeSlot,
  BookedLiveEventTimeSlot,
  CaptionerAvailabilityCalendarEntry,
  CaptionerShiftCalendarEntry,
  CaptionerShiftTimeSlot,
  isBookedLiveEventTimeSlot,
  isCaptionerAvailabeSlot,
  isCaptionerShiftTimeSlot,
  isNewBookingCalendarEntry,
  isUnbookedLiveEventTimeSlot,
  LiveEventCalendarEntry,
  NewBookingTimeSlot,
  UnBookedLiveEventTimeSlot,
} from './shared/types';

import {
  CALENDAR_BOOKED_LIVE_EVENT_COLOR,
  CALENDAR_CAPTIONER_AVAILABLE_COLOR,
  CALENDAR_CAPTIONER_SHIFT_COLOR,
  CALENDAR_DISABLED_BUTTON_BACKGROUND_COLOR,
  CALENDAR_DISABLED_BUTTON_FONT_COLOR,
  CALENDAR_FILTER_BUTTON_FONT_COLOR,
  CALENDAR_UNBOOKED_LIVE_EVENT_COLOR,
  eventStyleGetter,
  getLocalizer,
  onRangeChange,
} from './shared/calendar';
import {
  convertAvailabilitySegments,
  convertBookings,
  convertCaptionerTimeShiftSlots,
} from './shared/convert-data';
import { toIsoString } from './shared/dates';
import { getQueryParam, toggleQueryParam } from './shared/query-params';

interface OpsSchedulingCalendarAppProps {
  bookables: CaptionerShiftTimeSlot[];
  bookings: Array<BookedLiveEventTimeSlot | UnBookedLiveEventTimeSlot>;
  contractorTypes: string[];
  date: string;
  userTimeZone: string;
  view: View;
}

type CalendarToggleButtonProps = {
  label: string;
  active: boolean;
  backgroundColor: string;
  onClick: () => void;
};

const CalendarToggleButton = ({
  label,
  active,
  backgroundColor,
  onClick,
}: CalendarToggleButtonProps) => {
  const styles = active
    ? {
        backgroundColor,
        borderColor: backgroundColor,
        color: CALENDAR_FILTER_BUTTON_FONT_COLOR,
      }
    : // Don't actual disable the button so that we can re-click to activate
      {
        backgroundColor: CALENDAR_DISABLED_BUTTON_BACKGROUND_COLOR,
        borderColor: CALENDAR_DISABLED_BUTTON_BACKGROUND_COLOR,
        color: CALENDAR_DISABLED_BUTTON_FONT_COLOR,
      };

  return (
    <button
      type="button"
      style={styles}
      className={`btn mr-2 calendar-selector ${active ? 'btn-primary' : 'btn-secondary'}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

function OpsSchedulingCalendarApp(props: OpsSchedulingCalendarAppProps) {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<AnyTimeSlot | undefined>(undefined);
  const [filteredBookables, setFilteredBookables] = useState<CaptionerShiftCalendarEntry[]>([]);
  const [filteredBookableSegments, setFilteredBookableSegments] = useState<
    CaptionerAvailabilityCalendarEntry[]
  >([]);
  const [filteredBookings, setFilteredBookings] = useState<LiveEventCalendarEntry[]>([]);
  const [showBookedEvents] = useState<boolean>(getQueryParam('q[show_booked]') !== 'false');
  const [showUnbookedEvents] = useState<boolean>(getQueryParam('q[show_unbooked]') !== 'false');
  const [showBookables] = useState<boolean>(getQueryParam('q[show_bookable]') !== 'false');
  const [showBookableSegments] = useState<boolean>(
    getQueryParam('q[show_bookable_segments]') !== 'false'
  );

  // Listen to the calendar details close event from _outside_ this React component
  useEffect(() => {
    document.addEventListener('closeCalendarDetails', clearActiveTimeSlot);
  }, []);

  const setActiveTimeSlot = (slot: AnyTimeSlot) => {
    globalObj.setCalendarSidebarVisibility('open');
    setSelectedTimeSlot(slot);
  };

  const clearActiveTimeSlot = () => {
    globalObj.setCalendarSidebarVisibility('closed');
    setSelectedTimeSlot(undefined);
  };

  const onClickTimeSlot = useCallback((slot: AnyTimeSlot) => {
    let location;

    // Clicking on a captioner shift will toggle the captioner filter and reload the page
    // See https://3playmedia.atlassian.net/browse/LIVE-2362
    if (isCaptionerShiftTimeSlot(slot) || isCaptionerAvailabeSlot(slot)) {
      toggleQueryParam(`q[bookable_of_User_type_id_eq]`, slot.bookable.id, null);
      return window.location.reload();
    }

    // Clicking on a time slot should open the sidebar with the edit screen filled in
    if (isBookedLiveEventTimeSlot(slot) || isUnbookedLiveEventTimeSlot(slot)) {
      location = `/time_slots/${slot.id}/edit?contractor_types=${(props.contractorTypes || []).join(
        ','
      )}`;
    } else if (isNewBookingCalendarEntry(slot)) {
      // Dragging a new time slot should pop open the "new" route
      location = `/time_slots/new`;
    } else {
      // Should never get here, fail loudly
      throw new Error('onClickTimeSlot: Unknown time_slot_type');
    }

    globalObj.Turbo.visit(location, { frame: 'partial_container' });
    setActiveTimeSlot(slot);
  }, []);

  // This toggles a series of booleans on the calendar on whether we show
  // booked events, unbooked events, or bookables
  const toggleCalendarFilter = (name: string) => {
    return () => {
      toggleQueryParam(`q[${name}]`, 'false', 'true');
      window.location.reload();
    };
  };

  const handleSelectSlot = useCallback(
    ({ start, end }: { start: Date; end: Date }) => {
      const newBookingSlot: NewBookingTimeSlot = {
        starts_at: toIsoString(start),
        ends_at: toIsoString(end),
        time_slot_type: 'new_booking',
      };
      onClickTimeSlot(newBookingSlot);
    },
    [onClickTimeSlot]
  );

  // Decide which bookables (captioners) to show based on the selected time slot
  useMemo(() => {
    if (!showBookables) {
      return setFilteredBookables([]);
    }

    const bookables = convertCaptionerTimeShiftSlots(props.bookables).filter(
      (captionerCalendarEntry: CaptionerShiftCalendarEntry) => {
        // When no time slot is selected, show all captioner shifts
        if (!selectedTimeSlot) {
          return true;
        }

        // When a captioner shift is selected, show only their captioner shifts
        if (isCaptionerShiftTimeSlot(selectedTimeSlot)) {
          return captionerCalendarEntry.object.bookable.id === selectedTimeSlot.bookable.id;
        }

        return true;
      }
    );

    setFilteredBookables(bookables);
  }, [props.bookables, selectedTimeSlot]);

  // Decide which bookables segments (captioner actual availability) to show based on the selected time slot
  useMemo(() => {
    if (!showBookableSegments) {
      return setFilteredBookableSegments([]);
    }
    const bookableSegments = convertAvailabilitySegments(props.bookables, props.bookings);
    setFilteredBookableSegments(bookableSegments);
  }, [props.bookables, props.bookings, selectedTimeSlot]);

  // Decide which bookings (live events) to show based on the selected time slot
  useMemo(() => {
    const bookings = props.bookings.filter(
      (liveEventTimeSlot: BookedLiveEventTimeSlot | UnBookedLiveEventTimeSlot) => {
        if (isUnbookedLiveEventTimeSlot(liveEventTimeSlot)) {
          return showUnbookedEvents;
        } else if (isBookedLiveEventTimeSlot(liveEventTimeSlot)) {
          if (isCaptionerShiftTimeSlot(selectedTimeSlot)) {
            const myEvent = liveEventTimeSlot.bookable.id === liveEventTimeSlot.bookable.id;
            return myEvent && showBookedEvents;
          }
          return showBookedEvents;
        }

        return true;
      }
    );

    setFilteredBookings(convertBookings(bookings));
  }, [props.bookings, selectedTimeSlot]);

  return (
    <>
      <div>
        <CalendarToggleButton
          label="Booked Events"
          active={showBookedEvents}
          backgroundColor={CALENDAR_BOOKED_LIVE_EVENT_COLOR}
          onClick={toggleCalendarFilter('show_booked')}
        />
        <CalendarToggleButton
          label="Unbooked Events"
          active={showUnbookedEvents}
          backgroundColor={CALENDAR_UNBOOKED_LIVE_EVENT_COLOR}
          onClick={toggleCalendarFilter('show_unbooked')}
        />
        <CalendarToggleButton
          label="Shifts"
          active={showBookables}
          backgroundColor={CALENDAR_CAPTIONER_SHIFT_COLOR}
          onClick={toggleCalendarFilter('show_bookable')}
        />

        <CalendarToggleButton
          label="Availability"
          active={showBookableSegments}
          backgroundColor={CALENDAR_CAPTIONER_AVAILABLE_COLOR}
          onClick={toggleCalendarFilter('show_bookable_segments')}
        />
      </div>
      <div className="d-flex bd-highlight">
        <div className="p-2 flex-grow-1">
          <Calendar
            localizer={getLocalizer(props.userTimeZone)}
            dayLayoutAlgorithm="no-overlap"
            defaultDate={props.date}
            defaultView={props.view}
            events={[...filteredBookables, ...filteredBookings, ...filteredBookableSegments]}
            showMultiDayTimes
            eventPropGetter={eventStyleGetter}
            step={30}
            style={{ height: 1200 }}
            // onView={onView}
            onSelectSlot={handleSelectSlot}
            onSelectEvent={(e: { object: AnyTimeSlot }) => onClickTimeSlot(e.object)}
            onRangeChange={onRangeChange}
            views={['day', 'week']}
            popup
          />
        </div>
      </div>
    </>
  );
}

export default OpsSchedulingCalendarApp;
