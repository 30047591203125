import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import Badge from 'react-bootstrap/Badge';

const NewBadge = () => (
  <Badge variant={'primary'} className={css(styles.uppercase)}>
    New!
  </Badge>
);

const styles = StyleSheet.create({
  uppercase: {
    textTransform: 'uppercase',
  },
});

export default NewBadge;
