import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import WithIcon from '~/components/app/common/WithIcon';
import { titleize } from '~/helpers/strings';

function icon(status) {
  switch (status) {
    case 'rejected':
      return 'fa fa-times-circle-o rejected';
    case 'complete':
      return 'fa fa-check-circle-o complete';
    case 'in_progress':
      return 'fa fa-clock-o in_progress';
    case 'archived':
      return 'fa fa-ban archived';
    case 'pending':
      return 'fa fa-clock-o pending';
    case 'alignment_review':
      return 'fa fa-exclamation-triangle alignment_review';
    case 'finalizing':
      return 'fa fa-clock-o finalizing';
  }
}

function ServiceStatus(props) {
  return (
    <span className={css(styles.serviceStatus, styles[props.status])}>
      <WithIcon icon={icon(props.status)}>
        <b>{titleize(props.status)}</b>
      </WithIcon>
    </span>
  );
}

ServiceStatus.propTypes = {
  percentComplete: PropTypes.number,
  status: PropTypes.oneOf([
    'rejected',
    'complete',
    'in_progress',
    'archived',
    'pending',
    'alignment_review',
    'finalizing',
  ]),
};

const styles = StyleSheet.create({
  serviceStatus: {
    paddingLeft: '0.5em',
  },
  rejected: {
    color: '#D60000',
  },
  in_progress: {
    color: '#976C00',
  },
  complete: {
    color: '#067A00',
  },
  cancelled: {
    color: '#364659',
  },
  pending: {
    color: '#976C00',
  },
  alignment_review: {
    color: '#976C00',
  },
  finalizing: {
    color: '#976C00',
  },
});

export default ServiceStatus;
