import React, { Dispatch, useCallback } from 'react';

import { Button, Textarea } from '@threeplayground/index';

import { State, Action } from '../../state/builderReducer';
import css from './SharePlayer.module.css';
import { useUpsertViewApiAccessMutation } from '~/api/appApi';

interface SharePlayerProps {
  state: State;
  dispatch: Dispatch<Action>;
  shareSnippet: string;
  hasApiKey: boolean;
}

export function SharePlayer({ state, dispatch, shareSnippet, hasApiKey }: SharePlayerProps) {
  const { mutateAsync: createToken } = useUpsertViewApiAccessMutation();

  const createAndDispatchToken = useCallback(() => {
    dispatch({ type: 'LOADING_GRANTED_TOKEN' });

    createToken({})
      .then((response) => {
        if (response.upsertViewApiAccess?.token) {
          dispatch({
            type: 'GRANTED_TOKEN',
            token: response.upsertViewApiAccess.token,
          });
        } else {
          // TODO give a reason to the user with a toast?
          dispatch({ type: 'FAILED_GRANTED_TOKEN' });
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: 'FAILED_GRANTED_TOKEN' });
      });
  }, [createToken, dispatch]);

  return (
    <>
      <h2>Share Player</h2>

      <div className={css.overlayArea}>
        <Textarea
          label="Code snippet"
          hideLabel
          disabled={!hasApiKey}
          className={css.embed}
          placeholder="Loading..."
          readOnly
          onFocus={(event) => event.currentTarget.select()}
          onClick={(event) => event.currentTarget.select()}
          value={shareSnippet}
        />

        <div className={css.overlay} hidden={hasApiKey}>
          <div>
            <p>
              To share videos from your 3Play project, you'll need to allow public transcript and
              audio streaming to the internet. You can manage this later in Project Settings.
            </p>
            <Button
              type="button"
              disabled={state.loadingGrantedToken}
              onClick={createAndDispatchToken}
            >
              Grant Public Access
            </Button>
          </div>
        </div>

        <div className={css.actions}>
          <div>{/* Put multiple players support doc here in future. */}</div>

          <Button
            size="small"
            disabled={!hasApiKey}
            onClick={() => {
              if (!navigator.clipboard) {
                console.error(
                  'Failed to copy; dynamically copying to clipboard requires a secure context (HTTPS)'
                );
                return;
              }

              navigator.clipboard
                .writeText(shareSnippet)
                .catch((err) => console.error('Failed to copy snippet.', err));
            }}
          >
            Copy Embed Code
          </Button>
        </div>
      </div>
    </>
  );
}
