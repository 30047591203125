import ThreeplayApi from './services/ThreeplayApi';
import {
  graphqlPath,
  confirmEmailMutation,
  logReferrerMutation,
  sendEmailConfirmationMutation,
  startApplicationMutation,
  submitApplicationMutation,
  submitTestResponsesMutation,
  updateLocationInformationMutation,
  continueApplicationMutation,
} from './services/mutations';
import { continueApplicationQuery } from './services/queries';
import { isStringEmpty } from './preliminary_application/helpers';

// Regex from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
// Disabling eslint rules since this is a direct copy-paste from mozilla docs.
// eslint-disable-next-line max-len,no-useless-escape
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const api = new ThreeplayApi(graphqlPath);

function handleError(err) {
  return {
    errors: [
      {
        message: `Unknown error: ${err}`,
      },
    ],
  };
}

export function logReferrer({ referrerParams, jobTypeId }) {
  return api.json(logReferrerMutation, { referrerParams, jobTypeId }).catch(handleError);
}

export const submitBasicInfo = ({
  languageId,
  referrerParams,
  email,
  firstname,
  lastname,
  country,
  region,
}) => {
  return api
    .json(startApplicationMutation, {
      languageId,
      referrerParams,
      email,
      firstname,
      lastname,
      country,
      region,
    })
    .catch(handleError);
};

export const submitLocationInfo = (applicantInfo, { authToken }) =>
  api
    .json(updateLocationInformationMutation, { ...authToken, ...applicantInfo })
    .catch(handleError);

export const validateEmail = (email) => {
  if (isStringEmpty(email)) {
    return 'Please enter a value.';
  } else if (email.length > 50) {
    return 'Email is too long.';
  } else if (!email.match(EMAIL_REGEX)) {
    return 'Invalid value.';
  }

  return;
};

export function sendEmailConfirmation({ authToken }) {
  return api.json(sendEmailConfirmationMutation, authToken);
}

export const confirmEmail = ({ email, token }) => api.json(confirmEmailMutation, { email, token });

export const continueApplication = ({ email, grecaptchaToken }) =>
  api.json(continueApplicationMutation, { email, grecaptchaToken });

export const getInProgressApplication = ({ authToken }) =>
  api.json(continueApplicationQuery, authToken);

export function submitSkillzAndExperience(applicantInfo, { authToken }) {
  return api.json(submitApplicationMutation, { ...authToken, ...applicantInfo }).catch(handleError);
}

function formatTestResponse(test) {
  return {
    testFileId: test.testFile.id,
    transcript: test.transcript || '',
  };
}

export function submitTest(testData, { authToken, time }) {
  const transcripts = testData.map(formatTestResponse);
  return api
    .json(submitTestResponsesMutation, { ...authToken, transcripts, time })
    .catch(handleError);
}
