// This method is present to provide a token that links all locations in which
// dubbing audio embed permissions should be controlled together.
export const canOrderDubbingAudioEmbed = (isStudio) => {
  return isStudio;
};

const isHubspotUrl = (url) => {
  const urlRegex = /^https:\/\/(.*\.hubspotusercontent.*\.net\/hubfs\/)/;
  return url.match(urlRegex) && url.includes('Signature=') && url.includes('Key-Pair-Id');
};

const is3PlayDealUrl = (url) => {
  const urlRegex = /^https:\/\/go.3playmedia.com\/\w+/;
  return url.match(urlRegex);
};

// Use this for testing:
// https://173833.cdnp1.hubspotusercontent-na1.net/hubfs/173833/?Signature=Sig&Key-Pair-Id=Keys
export const validQuoteUrl = (url) => {
  return isHubspotUrl(url) || is3PlayDealUrl(url);
};
