import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { MULTI_METHOD_TOOLTIP } from '~/helpers/constants';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';

function FileUploadComponent(props) {
  return (
    <Card.Body>
      <div className={css(styles.uploadIcon)}>
        <i className="fa fa-link"></i>
      </div>
      <div className={css(styles.uploadName)}>Add Files from Links</div>
      <Card.Text>
        <Button
          tabIndex={props.index}
          style={{ marginTop: '4px' }}
          className={props.disabled ? 'disabled' : ''}
          onClick={() => props.openLinksModal()}
          variant="primary"
        >
          Add Files from Links
        </Button>
      </Card.Text>
    </Card.Body>
  );
}

function FileUpload(props) {
  if (props.disabled) {
    return (
      <TooltipIfErrors errors={[MULTI_METHOD_TOOLTIP]} location="top">
        <FileUploadComponent {...props} />
      </TooltipIfErrors>
    );
  } else {
    return <FileUploadComponent {...props} />;
  }
}

const styles = StyleSheet.create({
  uploadIcon: {
    display: 'flex',
    alignContent: 'flex-start',
    fontSize: '2.5rem',
    color: '#a5a5a5',
  },
  uploadName: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
});

FileUpload.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
};

FileUploadComponent.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  openLinksModal: PropTypes.func,
};

export default FileUpload;
