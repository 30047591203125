import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import { threeplayApi } from '~/logic/ThreeplayApi';
import { turnaroundLevelSpec } from '../ShowPanel/data/propspec';
import { numberToCurrency } from '~/helpers/numbers';

function TurnaroundLevelModal(props) {
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState(false);
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [turnaroundLevelId, setTurnaroundLevelId] = useState(
    props.turnaroundLevels && props.turnaroundLevels[0].id
  );

  async function sendTurnaroundLevelMutation() {
    setUpdating(true);
    const response = await threeplayApi.mutate(
      sendUpgradeTurnaroundLevelMutation(props.service.id, turnaroundLevelId)
    );
    if (response.data && response.data.upgradeTurnaroundLevel) {
      if (response.data.upgradeTurnaroundLevel.errors) {
        setErrors([response.data.upgradeTurnaroundLevel.errors]);
        setUpdating(false);
        return;
      }
      setUpdating(false);
      setSuccessAlert(true);
      setTimeout(() => {
        handleClose(), setSuccessAlert(false);
      }, 2000);
    } else {
      setErrors([response.errors]);
      setUpdating(false);
      return;
    }
  }

  function TurnaroundLevelList() {
    return (
      <>
        <Form>
          <Table striped bordered>
            <thead>
              <tr>
                <th className={css(styles.table)}></th>
                <th>Turnaround Level</th>
                <th>Delivery Date (ET)</th>
                <th>Additional Cost per min</th>
              </tr>
            </thead>
            <tbody>
              {props.turnaroundLevels &&
                props.currentTurnaroundLevel &&
                props.turnaroundLevels.map((turnaround) => (
                  <tr key={turnaround.id}>
                    <td>
                      <Form.Check
                        type="radio"
                        id={turnaround.id}
                        name={turnaround.name}
                        checked={turnaround.id === turnaroundLevelId}
                        onChange={() => setTurnaroundLevelId(turnaround.id)}
                      />
                    </td>
                    <td>
                      <span>{turnaround.name}</span>
                    </td>
                    <td>
                      <span>{turnaround.deliveryDate}</span>
                    </td>
                    <td>
                      <span>
                        {numberToCurrency.format(
                          turnaround.surcharge.amount -
                            props.currentTurnaroundLevel.surcharge.amount
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Form>
        {!props.currentTurnaroundLevel.withinBusinessHours && (
          <div>
            <p>
              <b>
                * You are ordering outside of business hours. Please note the adjusted delivery date
                for our fastest services. Deadlines are calculated from 8:00am ET next business day.
              </b>
            </p>
            <p>
              For more details on delivery times and file limits, refer to our
              <a href="https://www.3playmedia.com/sla/" target="_blank" rel="noopener noreferrer">
                {' '}
                Turnaround SLA
              </a>
            </p>
          </div>
        )}
      </>
    );
  }

  TurnaroundLevelList.propTypes = {
    turnaroundLevels: PropTypes.arrayOf(turnaroundLevelSpec),
    currentTurnaroundLevel: turnaroundLevelSpec,
  };

  function sendUpgradeTurnaroundLevelMutation(serviceId, turnaroundLevelId) {
    return `upgradeTurnaroundLevel(
      serviceId: ${serviceId},
      turnaroundLevelId: ${turnaroundLevelId}
    ) { success, errors }`;
  }

  function handleClose() {
    props.setShowTurnaroundLevelModal(false);
  }

  function successAlert() {
    return <Alert variant="success">You successfully modified the turnaround level!</Alert>;
  }

  function errorAlert() {
    return (
      <Alert variant="error">There was an error upgrading the turnaround level of this file.</Alert>
    );
  }

  function sendingTurnaroundLevel() {
    return <Alert variant="info">Upgrading turnaround level. Please wait....</Alert>;
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upgrade Turnaround Level</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showSuccessAlert && successAlert()}
        {errors && errorAlert()}
        {updating && sendingTurnaroundLevel()}

        <TurnaroundLevelList />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={css(styles.submitButton)}
          variant="primary"
          type="submit"
          onClick={sendTurnaroundLevelMutation}
        >
          Submit
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

TurnaroundLevelModal.propTypes = {
  show: PropTypes.bool,
  setShowTurnaroundLevelModal: PropTypes.func,
  contentErrors: PropTypes.arrayOf(PropTypes.string),
  service: PropTypes.object,
  turnaroundLevels: PropTypes.arrayOf(turnaroundLevelSpec),
};

const styles = StyleSheet.create({
  submitButton: {
    float: 'right',
  },
});

export default TurnaroundLevelModal;
