import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { adAssetDownloadPath, adTextAssetDownloadPath } from '~/helpers/app/paths';
import {
  adForProjectQuery,
  bwfDownloadEnabledQuery,
  flipperFeatureEnabledQuery,
} from '~/components/app/media_files/ShowPanel/data/queries';

import { threeplayApi } from '~/logic/ThreeplayApi';

function DownloadADModal(props) {
  const [adForProject, setAdForProject] = useState(null);
  const [showBwfFormat, setShowBwfFormat] = useState(false);

  useEffect(() => {
    threeplayApi.request(adForProjectQuery).then((response) => {
      if (response.data?.descriptionFormats) {
        setAdForProject(response.data.descriptionFormats);
      }
    });
    threeplayApi
      .request(bwfDownloadEnabledQuery, { audioDescriptionId: props.audioDescriptionId })
      .then((response) => {
        if (response.data?.project?.bwfDownloadEnabled) {
          setShowBwfFormat(true);
        }
      });
  }, [props.audioDescriptionId]);

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Download Audio Description Assets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>AD Only Media Assets</h6>
        <a className="m-2" href={adAssetDownloadPath(props.audioDescriptionId, 'ad_only_mp3')}>
          MP3
        </a>
        <a className="m-2" href={adAssetDownloadPath(props.audioDescriptionId, 'ad_only_ogg')}>
          OGG
        </a>
        <a className="m-2" href={adAssetDownloadPath(props.audioDescriptionId, 'ad_only_wav')}>
          WAV
        </a>
        {showBwfFormat && (
          <a className="m-2" href={adAssetDownloadPath(props.audioDescriptionId, 'ad_only_bwf')}>
            BWF
          </a>
        )}
        <h6 className="pt-2">Mixed AD + Source Media Assets</h6>
        <a className="m-2" href={adAssetDownloadPath(props.audioDescriptionId, 'mp4')}>
          MP4
        </a>
        <a className="m-2" href={adAssetDownloadPath(props.audioDescriptionId, 'mp3')}>
          MP3
        </a>
        <a className="m-2" href={adAssetDownloadPath(props.audioDescriptionId, 'ogg')}>
          OGG
        </a>
        <a className="m-2" href={adAssetDownloadPath(props.audioDescriptionId, 'wav')}>
          WAV
        </a>
        {showBwfFormat && (
          <a className="m-2" href={adAssetDownloadPath(props.audioDescriptionId, 'bwf')}>
            BWF
          </a>
        )}
        <h6 className="pt-2">Text Assets</h6>
        {adForProject &&
          adForProject.map((adFormat) => {
            return (
              <a
                key={adFormat.id}
                className="m-2"
                href={adTextAssetDownloadPath(
                  props.fileId,
                  adFormat.id,
                  adFormat.httpFormat,
                  props.audioDescriptionId
                )}
              >
                {adFormat.name}
              </a>
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={props.onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DownloadADModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  audioDescriptionId: PropTypes.string,
  fileId: PropTypes.string,
  setShowModal: PropTypes.object,
};

export default DownloadADModal;
