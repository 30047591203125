import { createContext } from 'react';

export interface GraphQLOrCustomError {
  message: string;
}

export interface ErrorContextInterface {
  errors: Record<string, GraphQLOrCustomError>;
  revalidateMode: 'onBlurAndSubmit' | 'onSubmit';
  withinForm: boolean;
}

export const ErrorsContext = createContext<ErrorContextInterface>({
  errors: {},
  revalidateMode: 'onBlurAndSubmit',
  withinForm: false,
});
