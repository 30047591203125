const awsUploadFileQuery = `query AWSUpload($fileId: ID, $fileName: String!, $type: String!, $keyPrefix: String){
  project{
    awsUpload(fileId: $fileId, fileName: $fileName, keyPrefix: $keyPrefix, uploadType: $type){
      amazonUrl,
      accessKey,
      bucket,
      key,
      policy,
      signature
    }
  }
}`;

const primaryServiceDetailsQuery = `query PrimaryServiceOptions ($selectedServiceType: String!, $languageType: String, $liveUpgradeFileId: ID){
  project {
    orderableServices (selectedServiceType: $selectedServiceType) {
      serviceType,
      displayName,
      orderOptions{
        ... on AlignmentOrderOption {
          languages {
            id,
            fullName
          }
        },
        ... on AsrOrderOption {
          languages {
            id,
            fullName
          }
        },
        ... on TranscriptionOrderOption {
          transcriptionTurnaroundOptions(languageType: $languageType){
            turnaroundLevel{
              id,
              name,
              displayName,
              serviceType,
              surcharge(languageType: $languageType) {
                amount,
                unit
              }
              deliveryDate,
              withinBusinessHours,
              maxFileDuration,
              active
            },
            default,
          }
          transcriptionServiceOptions(liveUpgradeFileId: $liveUpgradeFileId){
            price {
              amount,
              originalAmount,
              unit,
            }
            languages {
              id,
              fullName
            }
          }
        }
      },
    },
  }
}`;

const additionalServicesQuery = `
  query AdditionalServicesQuery ($selectedAdditionalServiceType: String!,
    $languageType: String,
    $primaryServiceType: String,
    $primaryServiceTurnaroundLevel: Int) {
      project {
        orderableServices (selectedServiceType: $primaryServiceType) {
          additionalServices (selectedAdditionalServiceType: $selectedAdditionalServiceType) {
            serviceType,
            orderOptions {
              ... on AudioDescriptionOrderOption {
                turnaroundLevels (languageType: $languageType){
                  id,
                  name,
                  displayName,
                  surcharge {
                    amount,
                    unit
                  }
                  serviceType
                  deliveryDate (primaryServiceType: $primaryServiceType,
                    primaryServiceTurnaroundLevel: $primaryServiceTurnaroundLevel)
                },
                serviceLevels (languageType: $languageType){
                  name,
                  price {
                    amount,
                    unit
                  }
                  voiceArtistPrice {
                    amount,
                    unit
                  }
                  displayPrice
                  voiceArtistDisplayPrice
                  description
                },
                speakerTypes (languageType: $languageType){
                  name,
                  price {
                    amount,
                    unit
                  }
                  displayPrice,
                  description
                }
              },
              ... on CaptionPlacementOrderOption {
                serviceLevels {
                  name,
                  description,
                  price {
                    amount,
                    unit
                  },
                  displayPrice
                }
              },
              ... on BroadcastScriptOrderOption {
                broadcastScriptFormats {
                  id,
                  name,
                  code,
                  description,
                  price {
                    amount,
                    unit
                  },
                  displayPrice,
                  turnaroundHours
                }
              }
            }
          }
        }
      }
    }
  `;

const batchesQuery = `query BatchesQuery {
  batches {
    id,
    name,
    archived,
  }
}`;

const turnaroundLevelsQuery = `query TurnaroundLevelsQuery ($batchId: String!) {
  turnaroundLevels(batchId: $batchId) {
    id,
    name,
    displayName,
    serviceType,
    maxDailyDuration
  }
}`;

const resourceConfigurationsQuery = `query ResourceConfigurations($names: [String!]){
  project {
    resourceConfigurations(names: $names){
      name,
      value
    }
  }
}`;

const translationOrderOptionsQuery = `
query TranslationOrderOptions ($primaryServiceType: String,
                               $selectedAdditionalServiceType: String!,
                               $sourceLanguageId: ID!) {
  project {
   defaultTranslationVendorId,
   defaultVendorTranscriptionVendorId,
   orderableServices(selectedServiceType: $primaryServiceType) {
       additionalServices(selectedAdditionalServiceType: $selectedAdditionalServiceType) {
        serviceType,
        orderOptions{
          ...on TranslationOrderOption {
            translationOrderOptions(sourceLanguageId: $sourceLanguageId) {
              id,
              price {
                amount,
                unit
              },
              sourceLanguage {
                id,
                name
              },
              serviceLevel {
                id,
                name,
                translationVendor {
                  id
                },
                translationServiceLevelTypeId,
              },
              targetLanguage {
                id,
                name
              },
              translationVendor {
                id,
                name,
              }
            },
            translationServiceLevelTypes(sourceLanguageId: $sourceLanguageId) {
              id,
              codeName,
              displayName,
            }
          }
        }
      }
    }
  }
}
`;

const dubbingOrderOptionsQuery = `
query DubbingOrderOptions ($primaryServiceType: String,
                               $selectedAdditionalServiceType: String!) {
  project {
    orderableServices(selectedServiceType: $primaryServiceType) {
      additionalServices(selectedAdditionalServiceType: $selectedAdditionalServiceType) {
        serviceType,
        orderOptions {
          ...on DubbingOrderOption {
            dubbingOrderOptions {
              id,
              perMinuteRate,
              sourceLanguage {
                id,
                fullName
              },
              targetLanguage {
                id,
                fullName
              }
            }
          }
        }
      }
    }
  }
}
`;

const translationProfilesQuery = `
query TranslationProfiles {
  project {
    translationProfiles {
      id
      default
      name
    }
  }
}
`;

const validateS3ObjectQuery = `query ValidateS3Object($bucket: String!, $key: String!) {
  validateS3Object(bucket: $bucket, key: $key)
}`;

const projectFlipperQuery = `query ProjectFlipperQuery($feature: String!) {
  project {
    flipperFeatureEnabled(feature: $feature)
  }
}`;

export {
  additionalServicesQuery,
  awsUploadFileQuery,
  batchesQuery,
  dubbingOrderOptionsQuery,
  resourceConfigurationsQuery,
  primaryServiceDetailsQuery,
  translationOrderOptionsQuery,
  translationProfilesQuery,
  turnaroundLevelsQuery,
  validateS3ObjectQuery,
  projectFlipperQuery,
};
