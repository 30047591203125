import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import TranscriptionOrderDetails from './TranscriptionOrderDetails';
import TranslationOrderDetails from './TranslationOrderDetails';
import LanguageSelector from '../order_form/LanguageSelector';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import { threeplayApi } from '~/logic/ThreeplayApi';

import { CreateTransperfectScheduledOrderMutation } from '~/components/app/scheduled_orders/data/mutations';

const ScheduledOrdersForm = ({
  transcriptionLanguages,
  handleClose,
  fromEnglishLanguages,
  toEnglishLanguages,
}) => {
  const [errors, setErrors] = useState({});
  const [dirtyFields, setDirtyFields] = useState({
    contentMinutes: false,
    numberOfFiles: false,
    transcriptionLanguage: false,
    checkboxes: false,
    startDate: false,
    endDate: false,
    transcriptionTurnaroundLevel: false,
    translationLanguagesAndTurnarounds: false,
  });
  const [contentMinutes, setContentMinutes] = useState('');
  const [numberOfFiles, setNumberOfFiles] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isTranscriptionChecked, setIsTranscriptionChecked] = useState(false);
  const [isTranslationChecked, setIsTranslationChecked] = useState(false);
  const [transcriptionTurnaroundLevel, setTranscriptionTurnaroundLevel] = useState('');
  const [transcriptionLanguage, setTranscriptionLanguage] = useState('');
  const [moreServiceInformation, setMoreServiceInformation] = useState('');
  const [translationLanguagesAndTurnarounds, setTranslationLanguagesAndTurnarounds] = useState({});
  const [isTranscriptionDisabled, setIsTranscriptionDisabled] = useState(true);
  const [isTranslationDisabled, setIsTranslationDisabled] = useState(true);
  const [requestError, setRequestError] = useState(false);

  const today = new Date().toISOString().split('T')[0];

  const populateInitialTurnaroundLevel = (languagesArray) => {
    setTranslationLanguagesAndTurnarounds((prev) => {
      const languages = languagesArray.map((language) => {
        if (prev[language]) {
          return [language, prev[language]];
        } else {
          return [language, { language: language, turnaroundLevel: 'Standard' }];
        }
      });

      return Object.fromEntries(languages);
    });
  };

  function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  const validate = useCallback(() => {
    const errors = {};

    if (dirtyFields.contentMinutes) {
      if (contentMinutes === '' || parseInt(contentMinutes) < 1 || contentMinutes.includes('.')) {
        errors.contentMinutes = 'Please enter a whole number value greater than 0.';
      }
    }

    if (dirtyFields.numberOfFiles) {
      if (numberOfFiles === '' || parseInt(numberOfFiles) < 1 || contentMinutes.includes('.')) {
        errors.numberOfFiles = 'Please enter a whole number value greater than 0.';
      }
    }

    if (dirtyFields.transcriptionLanguage) {
      if (transcriptionLanguage === '') {
        errors.transcriptionLanguage = 'Please select a language for your files.';
      }
    }

    if (dirtyFields.checkboxes) {
      if (!isTranscriptionChecked && !isTranslationChecked) {
        errors.checkboxes = 'Please select services for your files.';
      }
    }

    if (dirtyFields.startDate && dirtyFields.endDate) {
      if (startDate === '' || endDate === '') {
        errors.dates = 'Please select both a start date and end date for your order.';
      } else if (startDate > endDate) {
        errors.dates = 'Please select a valid date range.';
      } else if (getDifferenceInDays(new Date(startDate), new Date(endDate)) > 7) {
        errors.dates = 'Your expected upload range should not exceed a week.';
      }
    }

    if (isTranscriptionChecked && dirtyFields.transcriptionTurnaroundLevel) {
      // Only considered dirty upon form submission.
      if (transcriptionTurnaroundLevel === '') {
        errors.transcriptionTurnaroundLevel = 'Please select a turnaround level.';
      }
    }

    if (isTranslationChecked && dirtyFields.translationLanguagesAndTurnarounds) {
      if (Object.keys(translationLanguagesAndTurnarounds).length === 0) {
        errors.translationLanguagesAndTurnarounds = 'Please select one or more languages.';
      }
    }

    return errors;
  }, [
    dirtyFields,
    contentMinutes,
    numberOfFiles,
    transcriptionLanguage,
    isTranscriptionChecked,
    isTranslationChecked,
    startDate,
    endDate,
    transcriptionTurnaroundLevel,
    translationLanguagesAndTurnarounds,
  ]);

  const createTransperfectScheduledOrder = async () => {
    const response = await threeplayApi.request(CreateTransperfectScheduledOrderMutation, {
      input: {
        contentMinutes: contentMinutes,
        numberOfFiles: numberOfFiles,
        startDate: startDate,
        endDate: endDate,
        transcriptionTurnaroundLevel: transcriptionTurnaroundLevel,
        transcriptionLanguage: transcriptionLanguage,
        moreServiceInformation: moreServiceInformation,
        translationLanguagesAndTurnarounds: Object.values(translationLanguagesAndTurnarounds),
      },
    });
    if (response?.data?.createTransperfectScheduledOrder?.errors?.length) {
      setRequestError(true);
    } else {
      handleClose();
      window.location.reload(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Object.keys(dirtyFields).forEach((field) => (dirtyFields[field] = true)); // Make all fields dirty
    const formErrors = validate();
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      createTransperfectScheduledOrder();
    }
  };

  useEffect(() => {
    if (transcriptionLanguage === '') {
      setIsTranscriptionDisabled(true);
      setIsTranslationDisabled(true);
    } else if (transcriptionLanguage === 'English') {
      setIsTranscriptionDisabled(true);
      setIsTranslationDisabled(false);
    } else {
      setIsTranscriptionDisabled(false);
      setIsTranslationDisabled(!toEnglishLanguages.includes(transcriptionLanguage));
    }
    setIsTranscriptionChecked(false);
    setIsTranslationChecked(false);
    // Set based on current value to reduce rerenders
    setTranscriptionTurnaroundLevel((currVal) => (currVal === '' ? currVal : ''));
    setTranslationLanguagesAndTurnarounds((currVal) =>
      Object.keys(currVal).length === 0 ? currVal : {}
    );
  }, [transcriptionLanguage]);

  // I don't want to modify LangaugeSelector or Typeahead to take in an onChange, so consider these fields dirty upon user modification.
  const isInitialTranscriptionLanguage = useRef(true);
  useEffect(() => {
    if (isInitialTranscriptionLanguage.current) {
      isInitialTranscriptionLanguage.current = false;
      return;
    }
    setDirtyFields((oldFields) => ({ ...oldFields, transcriptionLanguage: true }));
  }, [transcriptionLanguage]);

  const isInitialTranslationLanguagesAndTurnarounds = useRef(true);
  useEffect(() => {
    if (isInitialTranslationLanguagesAndTurnarounds.current) {
      isInitialTranslationLanguagesAndTurnarounds.current = false;
      return;
    }
    setDirtyFields((oldFields) => ({ ...oldFields, translationLanguagesAndTurnarounds: true }));
  }, [translationLanguagesAndTurnarounds]);

  useEffect(() => {
    setErrors(validate());
  }, [validate]);

  return (
    <>
      <Form>
        {requestError && (
          <Alert variant="danger"> Scheduled Order was not successful, please try again</Alert>
        )}
        <Form.Group controlId="contentMinutes">
          {contentMinutes > 120 && (
            <Alert variant="warning" transition={false}>
              <p>
                <b>Note</b>: All orders placed are still subject to 3Play Media’s file length and
                daily upload limits as stated in our Service Level Agreement. Learn more about our
                file and duration limits.
              </p>
              <Alert.Link></Alert.Link>
            </Alert>
          )}
          <Form.Label className={css(styles.labels)}>
            How many total minutes of content will you be uploading?
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="0"
            value={contentMinutes}
            onChange={(e) => setContentMinutes(e.target.value)}
            onBlur={() => setDirtyFields((oldFields) => ({ ...oldFields, contentMinutes: true }))}
            min="0"
            className={css(styles.numberInput)}
          />
          <Form.Text className={css(styles.text)}> minutes </Form.Text>
          {errors.contentMinutes && (
            <Form.Text className="text-danger">{errors.contentMinutes}</Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="numberOfFiles">
          <Form.Label className={css(styles.labels)}>
            How many files will you be uploading?
          </Form.Label>
          <Form.Control
            placeholder="0"
            type="number"
            value={numberOfFiles}
            onChange={(e) => setNumberOfFiles(e.target.value)}
            onBlur={() => setDirtyFields((oldFields) => ({ ...oldFields, numberOfFiles: true }))}
            min="0"
            className={css(styles.numberInput)}
          />
          <Form.Text className={css(styles.text)}> files </Form.Text>
          {errors.numberOfFiles && (
            <Form.Text className="text-danger">{errors.numberOfFiles}</Form.Text>
          )}
        </Form.Group>
        <Form.Group className={css(styles.languageInput)}>
          <LanguageSelector
            languages={transcriptionLanguages}
            selectedLanguage={transcriptionLanguage}
            setLanguage={setTranscriptionLanguage}
            className={css(styles.languageInput)}
            noTabIndex
          />
          {errors.transcriptionLanguage && (
            <Form.Text className="text-danger">{errors.transcriptionLanguage}</Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="serviceOptions" className={css(styles.checkBoxes)}>
          <Form.Label className={css(styles.labels)}>
            Which services will you be ordering on these files?
          </Form.Label>
          <OverlayTrigger
            placement="left"
            delay={{ hide: 2000 }}
            trigger={transcriptionLanguage && isTranscriptionDisabled ? ['hover', 'focus'] : []}
            overlay={
              <Tooltip>Orders for english transcription do not require advance notice.</Tooltip>
            }
          >
            <div>
              <Form.Check
                type="checkbox"
                aria-label="Transcription"
                label="Transcription"
                checked={isTranscriptionChecked}
                disabled={isTranscriptionDisabled}
                onChange={() => {
                  setIsTranscriptionChecked((currentChecked) => !currentChecked);
                  setDirtyFields((oldFields) => ({ ...oldFields, checkboxes: true }));
                }}
              />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            delay={{ hide: 2000 }}
            trigger={transcriptionLanguage && isTranslationDisabled ? ['hover', 'focus'] : []}
            overlay={
              <Tooltip>
                Our translation partner does not currently support translation for your selected
                language.
              </Tooltip>
            }
          >
            <div>
              <Form.Check
                type="checkbox"
                label="Translation"
                aria-label="Translation"
                checked={isTranslationChecked}
                disabled={isTranslationDisabled}
                onChange={() => {
                  setIsTranslationChecked((currentChecked) => !currentChecked);
                  setDirtyFields((oldFields) => ({ ...oldFields, checkboxes: true }));
                }}
              />
            </div>
          </OverlayTrigger>
          {errors.checkboxes && <Form.Text className="text-danger">{errors.checkboxes}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="expectedUpload">
          <Form.Label className={css(styles.labels)}>
            When do you expect to place your order?
          </Form.Label>
          <Form.Control
            type="date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
            onBlur={() => setDirtyFields((oldFields) => ({ ...oldFields, startDate: true }))}
            min={today}
            max={endDate}
            className={css(styles.dates)}
          />
          <Form.Text className={css(styles.text)}> to </Form.Text>
          <Form.Control
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            onBlur={() => setDirtyFields((oldFields) => ({ ...oldFields, endDate: true }))}
            min={startDate}
            className={css(styles.dates)}
          />
          {errors.dates && <Form.Text className="text-danger">{errors.dates}</Form.Text>}
        </Form.Group>
        {isTranscriptionChecked && (
          <TranscriptionOrderDetails
            transcriptionLanguages={transcriptionLanguages}
            setTranscriptionLanguage={setTranscriptionLanguage}
            transcriptionLanguage={transcriptionLanguage}
            setTranscriptionTurnaroundLevel={setTranscriptionTurnaroundLevel}
            transcriptionTurnaroundLevel={transcriptionTurnaroundLevel}
            errors={errors}
          />
        )}
        {isTranslationChecked && (
          <TranslationOrderDetails
            fromEnglishLanguages={fromEnglishLanguages}
            translationLanguagesAndTurnarounds={translationLanguagesAndTurnarounds}
            setTranslationLanguagesAndTurnarounds={setTranslationLanguagesAndTurnarounds}
            transcriptionLanguage={transcriptionLanguage}
            contentMinutes={contentMinutes}
            numberOfFiles={numberOfFiles}
            populateInitialTurnaroundLevel={populateInitialTurnaroundLevel}
            errors={errors}
          />
        )}
        <hr className={css(styles.line)} />
        <Form.Group controlId="moreServiceInformation">
          <Form.Label className={css(styles.labels)}>
            Please let us know if you need anything else not captured in your responses above.
          </Form.Label>
          <Form.Control
            value={moreServiceInformation}
            as="textarea"
            onChange={(e) => setMoreServiceInformation(e.target.value)}
            placeholder="Include information that might be helpful in completing your order such as file lengths and types of content"
            className={css(styles.textBox)}
          />
        </Form.Group>
        <Button
          className={css(styles.formButtons)}
          variant="primary"
          type="submit"
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
        <Button className={css(styles.formButtons)} variant="outline-primary" onClick={handleClose}>
          Close
        </Button>
      </Form>
    </>
  );
};

const styles = StyleSheet.create({
  numberInput: {
    width: '5rem',
    display: 'inline-block',
  },
  languageInput: {
    marginBottom: '1rem',
  },
  checkBoxes: {
    marginBottom: '1rem',
  },
  textBox: {
    width: '100%',
    height: '111px',
  },
  formButtons: {
    float: 'right',
    margin: '5px',
  },
  labels: {
    fontWeight: 'bold',
    display: 'block',
  },
  dates: {
    display: 'inline-block',
    width: '40%',
  },
  text: {
    display: 'inline',
    marginBottom: '1.5rem',
  },
});

ScheduledOrdersForm.propTypes = {
  handleClose: PropTypes.func,
  transcriptionLanguages: PropTypes.arrayOf(PropTypes.shape({ fullName: PropTypes.string })),
  fromEnglishLanguages: PropTypes.arrayOf(PropTypes.string),
  toEnglishLanguages: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduledOrdersForm;
