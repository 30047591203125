import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import ArchiveButton from './ArchiveButton';

function moreDropdown(props) {
  const actions = [
    props.more.manageTags?.enabled && props.enabledFeatures.includes('other') && (
      <Dropdown.Item
        key="1"
        className="dropdown-item"
        onClick={() => props.setShowModal.manageTags(true)}
      >
        <i className="fa fa-tag" aria-label="Manage Tags"></i> Manage Tags
      </Dropdown.Item>
    ),
    props.more.import?.enabled && props.enabledFeatures.includes('translation_import') && (
      <Dropdown.Item
        key="2"
        className="dropdown-item"
        onClick={() => props.setShowModal.importTranslations(true)}
      >
        <i className="fa fa-globe"></i> Import Translation
      </Dropdown.Item>
    ),
    (props.more.cheatSheet?.enabled ||
      props.more.manageTags?.enabled ||
      props.more.import?.enabled) &&
      props.more.archive.enabled && (
        <React.Fragment key="3">
          <Dropdown.Divider />
          <ArchiveButton archiveFile={props.fileActionFunctions.archiveFile} />
        </React.Fragment>
      ),
  ];
  return actions;
}

function MoreButton(props) {
  return (
    <React.Fragment>
      <DropdownButton
        id="dropdown-variants-secondary"
        variant="light"
        size="sm"
        title="More"
        focusFirstItemOnShow="keyboard"
      >
        {props !== {} && moreDropdown(props)}
      </DropdownButton>
    </React.Fragment>
  );
}

MoreButton.propTypes = {
  more: PropTypes.object,
  fileActionFunctions: PropTypes.shape({
    archiveFile: PropTypes.func,
  }),
  setShowModal: PropTypes.shape({
    cheatSheet: PropTypes.func,
    manageTags: PropTypes.func,
    importTranslations: PropTypes.func,
  }),
};

export default MoreButton;
