import enUS from './en_us-text.json';
import esUS from './es_us-text.json';

const LOCALES = {
  en_us: enUS,
  es_us: esUS,
};

export function localeFor(languageId) {
  if (languageId === 13) {
    return 'es_us';
  } else {
    return 'en_us';
  }
}

export function localeText(locale) {
  return LOCALES[locale];
}
