import React, { FormEvent, ForwardedRef, forwardRef, ReactNode, useCallback, useMemo } from 'react';
import { keyBy } from 'lodash';
import { Form as BootstrapForm } from 'react-bootstrap';

import { ErrorsContext } from './ErrorsContext';
import { Alert } from '../Alert/Alert';

export type ForwardedNativeFormProps =
  | 'action'
  | 'autocomplete'
  | 'enctype'
  | 'method'
  | 'name'
  | 'target';

interface GraphQLError {
  message: string;
  path: string;
}

export interface GraphQLGlobalError extends GraphQLError {
  code?: string;
}

function SummaryBanner({ errors }: { errors: GraphQLGlobalError[] }) {
  if (errors.length === 0) {
    return null;
  }

  // TODO: we should probably add a title top to Alert and make the paragraph
  // TODO: Make this dismissible and properly handle close
  return (
    <Alert variant="danger">
      <p>Please correct the following issues:</p>
      <ul>
        {errors.map((error) => (
          <li key={error.message}>{error.message}</li>
        ))}
      </ul>
    </Alert>
  );
}

// This function ensures that `FormData` and submit events
// have values parsed to numbers, dates, etc.
const parseFormData = (event: FormEvent<HTMLFormElement>) => {
  // http://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/elements
  const { elements } = event.target as HTMLFormElement;
  const formData = new FormData(event.target as HTMLFormElement);

  // Allow data to be nullable with "safe" type cast here. Should get warnings
  // if the types don't align as we aren't doing the `as unknown` danger.
  const data = Object.fromEntries(formData.entries()) as {
    [k: string]: string | number | null;
  };

  // FormData is all strings, so we parse out number and date types where we
  // can.
  for (const [name, stringValue] of formData) {
    if (typeof stringValue !== 'string') {
      throw new Error('[Unimplemented] File is not currently supported in Form.');
    }

    const element = elements.namedItem(name);

    if (stringValue.length === 0) {
      data[name] = null;
    } else if (element instanceof HTMLInputElement) {
      // This is safe to do in all browsers.
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement#properties
      const value = element.valueAsNumber;

      if (!isNaN(value)) {
        // If we can parse the value, we will set it. We don't if it's a NaN
        // as we'd prefer to pass the wrong type and original string to GraphQL.
        // This is important as setting to `null` could inadvertently unset an
        // invalid value silently, where a string should be unexpected.
        //
        // We don't set validation errors here as the input should have caught
        // this already.
        data[name] = value;
      }
    }

    // TODO Once we implement a DateTimeInput:
    // Use 'datetime-local' and `valueAsDate`? We may want to attach
    // timezone information to timestamps? Would like to return ISO-8601 strings
    // here with timezone attached.
  }

  return data;
};

export interface FormProps extends Partial<Pick<HTMLFormElement, ForwardedNativeFormProps>> {
  className?: string;

  children?: ReactNode;

  /**
   * Enhanced onSubmit.
   *
   * The second argument includes a parsed set of values appropriate for
   * passing to a GraphQL mutation with numbers parsed.
   *
   * @example
   *
   * onSubmit((event, values) => {
   *   console.log(values); // object of { [key]: valueForType }
   *   // object with string values.
   *   // FormData is appropriate for REST / POST serialization
   *   console.log(Object.fromEntries(new FormData(event.target).entries()));
   * });
   */
  onSubmit?: (
    event: FormEvent<HTMLFormElement>,
    values: Record<string, string | number | null>
  ) => void;

  revalidateMode?: 'onBlurAndSubmit' | 'onSubmit';

  /** Pass `errors` from the `use3PMutation` hook */
  errors?: GraphQLError[];

  /** Pass `globalErrors` from the `use3PMutation` hook */
  globalErrors?: GraphQLGlobalError[];
}

/**
 * Not sure this is the best way to communicate with React Hook Form
 * type-wise, but it saves a lot of gluing.
 */
export interface ModifiedFormEvent extends FormEvent<HTMLFormElement> {
  parsedValues?: { [k: string]: string | number | null };
}

const FormInner = (
  {
    children,
    className,
    globalErrors = [],
    errors = [],
    onSubmit,
    revalidateMode = 'onSubmit',
    ...forwardedNativeProps
  }: FormProps,
  ref: ForwardedRef<HTMLFormElement>
) => {
  const errorsByPath = useMemo(() => keyBy(errors, 'path'), [errors]);

  const handleSubmit = useCallback(
    (event: ModifiedFormEvent) => {
      // This yields the following to parents:
      // event.formData: A map of stringified values ready to send over fetch.
      // values: a map of values already parsed out, ready to send over GraphQL.
      const parsedValues = parseFormData(event);
      event.parsedValues = parsedValues; // We also add this to the event for react-hook-form to access
      onSubmit && onSubmit(event, parsedValues);
    },
    [onSubmit]
  );

  // Errors context allows inputs to render errors without annoying threading over and over.
  return (
    <ErrorsContext.Provider value={{ errors: errorsByPath, revalidateMode, withinForm: true }}>
      <BootstrapForm
        {...forwardedNativeProps}
        ref={ref}
        onSubmit={handleSubmit}
        className={className}
      >
        <SummaryBanner errors={globalErrors} />
        {children}
      </BootstrapForm>
    </ErrorsContext.Provider>
  );
};

export const Form = forwardRef(FormInner);
