import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

import { StyleSheet, css } from 'aphrodite';

const TranscriptionOrderDetails = ({
  setTranscriptionTurnaroundLevel,
  transcriptionTurnaroundLevel,
  errors,
}) => {
  return (
    <>
      <hr className={css(styles.line)} />
      <Form.Label className={css(styles.title)} as="h3">
        Transcription Order Details
      </Form.Label>
      <Form.Label className={css(styles.labels)}>Requested Turnaround Level</Form.Label>
      <Form.Group>
        <Form.Check
          type="radio"
          name="formHorizontalRadios"
          id="standard"
          onChange={() => setTranscriptionTurnaroundLevel('standard')}
          checked={transcriptionTurnaroundLevel === 'standard'}
          label="Standard"
          className={css(styles.turnaround)}
        />
        <Form.Text>Five business day turnaround from date order is placed</Form.Text>
        <Form.Check
          type="radio"
          name="formHorizontalRadios"
          id="expedited"
          onChange={() => setTranscriptionTurnaroundLevel('expedited')}
          checked={transcriptionTurnaroundLevel === 'expedited'}
          label="Expedited"
          className={css(styles.turnaround)}
        />
        <Form.Text>Two business day turnaround from date order is placed</Form.Text>
        <Form.Check
          type="radio"
          name="formHorizontalRadios"
          id="rush"
          onChange={() => setTranscriptionTurnaroundLevel('rush')}
          checked={transcriptionTurnaroundLevel === 'rush'}
          label="Rush"
          className={css(styles.turnaround)}
        />
        <Form.Text>One business day turnaround from date order is placed</Form.Text>
        {errors.transcriptionTurnaroundLevel && (
          <Form.Text className="text-danger">{errors.transcriptionTurnaroundLevel}</Form.Text>
        )}
      </Form.Group>
    </>
  );
};

const styles = StyleSheet.create({
  turnaround: {
    display: 'flex',
    fontWeight: 'bold',
    color: '#7E7E7E',
  },
  title: {
    color: '#666666',
    fontSize: '24px',
    fontWeight: '400',
  },
  line: {
    margin: '20px 0',
    borderBottom: '1px solid #dcdcdc',
  },
  labels: {
    fontWeight: 'bold',
  },
});

TranscriptionOrderDetails.propTypes = {
  setTranscriptionTurnaroundLevel: PropTypes.func,
  transcriptionTurnaroundLevel: PropTypes.string,
  errors: PropTypes.object,
};

export default TranscriptionOrderDetails;
