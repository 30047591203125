import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Card } from '@threeplayground/unstable';

import { OffPlatformJobLayout } from '~/components/jobs/common/OffPlatformJobLayout';
import {
  JobDetailsRow,
  OffPlatformJobDetails,
} from '~/components/jobs/common/OffPlatformJobDetails';
import { offPlatformBroadcastScriptingJobsPaths as paths } from '../offPlatformBroadcastScriptingJobsPaths';
import { JobLoadError } from '~/components/jobs/common/JobLoadError';
import { UploadedFileList } from '~/components/jobs/common/file_uploads/UploadedFileList';
import { FileUploader } from '~/components/jobs/common/file_uploads/FileUploader';
import { JobInstructions } from '~/components/jobs/common/JobInstructions';
import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { OutputFormatCodeEnum } from '~/components/jobs/common/constants';
import { DownloadableAsset } from '~/components/jobs/common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';
import { DeliveryInstructions } from '~/components/jobs/common/DeliveryInstructions';
import { formatTranscriptForDownload } from '../../common/downloadable_asset_cell/helpers';

type ParamType = {
  id: string;
};

export const OffPlatformBroadcastScriptingJob = () => {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, isError } = useJobQuery({ where: { idEq: id } });

  if (isError) return <JobLoadError />;
  if (isLoading) return null;

  if (!data.job || data.job.__typename !== 'OffPlatformBroadcastScriptingJobGraphql')
    return <JobLoadError />;

  const job = data.job;
  const { orderInstructions, deliveryInstructions, service, mediaFile, broadcastScript } = data.job;

  const assets = (): DownloadableAsset[] => {
    const assets: DownloadableAsset[] = [
      {
        displayName: 'Source Video',
        url: `/off_platform_broadcast_scripting_jobs/${id}/download_source_video`,
      },
    ];

    if (
      service.__typename == 'BroadcastScriptingServiceGraphql' ||
      service.__typename == 'BroadcastScriptingRevisionServiceGraphql'
    ) {
      const generateUrl = (): string =>
        paths.downloadInputTranscript.getLink(
          service.inputTranscript.id,
          OutputFormatCodeEnum.VttNoBlankFrames
        );

      const sourceScripts = formatTranscriptForDownload({
        transcript: service.inputTranscript,
        generateUrl,
      });
      assets.push(...sourceScripts);
    }

    if (service.__typename == 'BroadcastScriptingServiceGraphql') {
      service.inputBroadcastScript?.files?.map((file) => {
        const fileFormat = file.assetFileFormat.displayName;

        assets.push({
          displayName: `Broadcast Script (${fileFormat})`,
          url: file.presignedDownloadUrl,
        });
      });
    }
    return assets;
  };

  const customJobDetails = (): JobDetailsRow[] => {
    const jobDetails = [];
    if (service.__typename == 'BroadcastScriptingServiceGraphql') {
      jobDetails.push({ header: 'Reformat', value: `${service.reformat.toString()}` });
    }
    if (
      service.__typename == 'BroadcastScriptingServiceGraphql' ||
      service.__typename == 'BroadcastScriptingRevisionServiceGraphql'
    ) {
      jobDetails.push({
        header: 'Broadcast Scripting Format',
        value: service.broadcastScriptingFormat.displayName,
      });
    }
    return jobDetails;
  };

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Broadcast Scripting Job'}>
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails
            job={job}
            downloadableAssets={assets()}
            customJobDetails={customJobDetails()}
          />
          <Card>
            <Card.Header className="font-weight-bold">Broadcast Script</Card.Header>
            <Card.Body>
              <UploadedFileList files={broadcastScript.files} />
              <FileUploader
                assetId={broadcastScript.id}
                assetType={AssetClassNameEnum.BroadcastScript}
                allowedFileFormats={broadcastScript.allowedFileFormats}
                uploadedFiles={broadcastScript.files}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="mt-4">
          <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
          <DeliveryInstructions
            cheatSheets={deliveryInstructions}
            shouldDeliver={mediaFile.proofToFinalFinalized}
          />
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
};
