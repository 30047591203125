/* eslint no-console:0 */

// This file is the primary entrypoint for Parcel.
// This entrypoint mirrors the previous webpacker setup and follows a lot of the
// conventions in organization to support react-rails; which maps
// `= react_component("my_path/to/component") to the components folder as
// all of our entrypoints.
//
// Parcel supports import globbing with the @parcel/resolver-glob package.
// We map that to a function that mirrors the resolve.context globbing API in
// webpack.

import ReactRailsUJS from 'react_ujs';
import components from './components/**/*.{jsx,tsx}';
import '@hotwired/turbo-rails';
import './controllers';
import './turbo/custom_actions';

// Turn Turbo Drive off by default
// https://turbo.hotwired.dev/reference/drive#turbo.session.drive
window.Turbo.session.drive = false;

// Access should mirror the assumptions hardcoded here.
// https://github.com/reactjs/react-rails/blob/c662ba580b674d6b12f5289538319fad80081ce1/react_ujs/src/getConstructor/fromRequireContext.js
//
// If react-rails / react_ujs ever update to have a version that doesn't
// assume webpacker usage; we should update to use a better API.
function fauxWebpackRequireContext(relativeImportPath) {
  const keyPath = relativeImportPath.slice(2); // remove `./`
  let narrowedComponent = components;

  keyPath.split('/').forEach((key) => {
    if (!narrowedComponent[key]) {
      if (process.env.NODE_ENV === 'development') {
        console.log('Imported components:', components);
        throw new Error(
          `Could not find component file for \`= react_component(${keyPath}..., ...)\`. \n\nEnsure your path is correct and a matching file exists within \`app/javascript/components\`.`
        );
      } else {
        throw new Error(`Could not find component file for \`${keyPath}\``);
      }
    }

    narrowedComponent = narrowedComponent[key];
  });

  // Because we import multiple file types, Parcel adds that to the object.
  // We pull it out here; preferring tsx if it's there.
  narrowedComponent = narrowedComponent?.tsx || narrowedComponent?.jsx;

  if (!narrowedComponent.__esModule) {
    if (process.env.NODE_ENV === 'development') {
      console.log('Imported components:', components);
      throw new Error(
        `Could not find component file for \`= react_component(${keyPath}..., ...)\`. \n\nAre you sure you aren't pointing to a folder instead of a file in \`app/javascript/components\`?`
      );
    } else {
      throw new Error(`Could not find component file for \`${keyPath}\``);
    }
  }

  return narrowedComponent;
}

ReactRailsUJS.useContext(fauxWebpackRequireContext); // eslint-disable-line react-hooks/rules-of-hooks
