import React, { Dispatch } from 'react';
import { Alert, Col, Form } from 'react-bootstrap';

import { Input } from '@threeplayground/index';
import { vpiViewPath } from '~/helpers/app/paths';

import { State, Action } from '../../../state/builderReducer';

interface AdditionalSettingsProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export function AdditionalKalturaSettings({ state, dispatch }: AdditionalSettingsProps) {
  const source = state.source;

  if (source.platform !== 'Kaltura') {
    return null;
  }

  return (
    <>
      <Form.Row>
        <Col>
          <Input
            label="Kaltura Partner ID"
            name="kaPartnerId"
            size="small"
            withTypingState
            value={source.kaPartnerId}
            onFocus={(event) => event.currentTarget.select()}
            onUpdate={(kaPartnerId) =>
              dispatch({
                type: 'UPDATED_OTHER_SOURCE_ATTRIBUTES',
                source: { kaPartnerId: kaPartnerId ?? '' },
              })
            }
          />
        </Col>

        <Col>
          <Input
            label="Kaltura Player ID"
            name="kaUiconfId"
            size="small"
            withTypingState
            value={source.kaUiconfId || ''}
            onFocus={(event) => event.currentTarget.select()}
            onUpdate={(kaUiconfId) =>
              dispatch({ type: 'UPDATED_OTHER_SOURCE_ATTRIBUTES', source: { kaUiconfId } })
            }
          />
        </Col>
      </Form.Row>

      {!source.kaUiconfId && source.id !== 'custom' && (
        <Alert variant="warning">
          Tired of filling out the Player ID field?{' '}
          <Alert.Link target="_blank" href={vpiViewPath(source.id)}>
            Update your Kaltura integration settings!
          </Alert.Link>
        </Alert>
      )}
    </>
  );
}
