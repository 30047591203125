import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTable, useSortBy, useExpanded } from 'react-table';
import Table from 'react-bootstrap/Table';
import { StyleSheet, css } from 'aphrodite';

const SortedIcon = ({ column = {} }) =>
  column.isSorted &&
  (column.isSortedDesc ? (
    // ▴
    <span> &#9652;</span>
  ) : (
    // ▾
    <span> &#9662;</span>
  ));

SortedIcon.propTypes = {
  column: PropTypes.shape({
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
  }),
};

const RowExpander = ({ row }) => {
  return row.isExpanded ? (
    // ▼
    <span {...row.getToggleRowExpandedProps()}>&#9660;</span>
  ) : (
    // ▶
    <span {...row.getToggleRowExpandedProps()}>&#9654;</span>
  );
};

RowExpander.propTypes = {
  row: PropTypes.object,
};

const EXPANDER_COLUMN = {
  id: 'expander',
  Header: () => null,
  Cell: RowExpander,
};

const ThreeplayTable = ({
  columns,
  data = [],
  striped = false,
  RowSubComponent = null,
  disableSortBy = false,
}) => {
  // if we have subcomponents or subrows, add the "row expander" column
  const getColumns = () => (RowSubComponent ? [EXPANDER_COLUMN, ...columns] : columns);

  const tableColumns = useMemo(() => getColumns(), [columns]);

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } =
    useTable(
      {
        columns: tableColumns,
        data,
        disableSortBy,
      },
      useSortBy,
      useExpanded
    );

  // Render the UI for your table
  return (
    <Table
      striped={striped}
      bordered
      hover
      size="sm"
      className={css(styles.table)}
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => {
          return (
            // getHeaderGroupProps() returns a key
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // getHeaderProps() returns a key
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={css(styles.th)}
                >
                  {column.render('Header')} <SortedIcon column={column} />
                </th>
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <React.Fragment key={index}>
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  // getCellProps() returns a key
                  // eslint-disable-next-line react/jsx-key
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
              {/* If the row is in an expanded state, render a row with a
                column that fills the entire length of the table. */}
              {row.isExpanded ? (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {/* Inside it, render our RowSubComponent.
                      We are passing in row.original (rather than row.value) to have access to all of the data.  
                      This allow us to, in the parent component, format the data that the row subcomponent needs. */}
                    {<RowSubComponent {...row.original} />}
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

ThreeplayTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  expanded: PropTypes.bool,
  striped: PropTypes.bool,
  disableSortBy: PropTypes.bool,
  // this should be a function that returns a react component
  // it's a func and not a PropType.node so that we can pass in the row props
  RowSubComponent: PropTypes.func,
};

const styles = StyleSheet.create({
  table: {
    width: '100%',
  },
  idCell: {
    whiteSpace: 'nowrap',
  },
  th: {
    position: 'sticky',
    top: '0',
    backgroundColor: 'white',
  },
});

export default ThreeplayTable;
