import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import PopoverContent from 'react-bootstrap/PopoverContent';
import PopoverTitle from 'react-bootstrap/PopoverTitle';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { css, StyleSheet } from 'aphrodite';
import {
  CHEATSHEET_CHECK_INDEX,
  CHEATSHEET_TEXT_INDEX,
  MAX_CHEATSHEET_CHARS,
} from '~/helpers/constants';
import CheatsheetUpload from '~/components/app/order_form/uploads/local/CheatsheetUpload';
import FileUploadTable from '~/components/app/order_form/uploads/FileUploadTable';

function AddCheatSheet({
  cheatSheetDispatch,
  cheatSheetState,
  cheatsheetToUpload,
  openStartCheatsheetUploadModal,
  removeFromCheatsheetUploadList,
  serviceType,
  showVoiceArtistAudioDescriptionNote = false,
  uploading,
  uploadProgress,
}) {
  const hasCheatsheetText = cheatSheetState[serviceType].text.length > 0;
  const cheatsheetTextTooLong = cheatSheetState[serviceType].text.length > MAX_CHEATSHEET_CHARS;

  const [rejectedCheatsheet, setRejectedCheatsheet] = useState([]);

  const textPopover = (
    <Popover id="popover-cheatsheet-textarea">
      <PopoverContent>
        Please remove all attached files in order to enter cheatsheet text
      </PopoverContent>
    </Popover>
  );

  function cheatSheetPopover() {
    return (
      <Popover id="popover-sheet">
        <PopoverTitle>Order Instruction Tips</PopoverTitle>
        <PopoverContent>
          Order Instructions can either be attached as a PDF or provided as unformatted plain text.
          Order Instructions typically include additional information for the transcript editor
          working on your content. For example, they can provide the editor with speaker names and
          specialized acronyms in the transcript.
        </PopoverContent>
      </Popover>
    );
  }

  function infoPopover() {
    if (serviceType === 'audioDescriptionService') {
      return (
        <Popover id="popover-cheatsheet">
          <PopoverTitle>What are Order Instructions?</PopoverTitle>
          <PopoverContent>
            Help 3Play provide you with the most accurate synthesized speech possible by providing
            correct pronunciation for acronyms and words that are not easily researchable.
          </PopoverContent>
        </Popover>
      );
    } else {
      return (
        <Popover id="popover-cheatsheet">
          <PopoverTitle>What are Order Instructions?</PopoverTitle>
          <PopoverContent>
            Help 3Play deliver the most accurate captions possible by providing Order Instructions
            for our transcript editors. By providing this information, our team is able to caption
            your content more efficiently and accurately.
          </PopoverContent>
        </Popover>
      );
    }
  }

  function placeholderText() {
    if (serviceType === 'audioDescriptionService') {
      return 'E.g. WYSIWYG is pronounced wizzyWig. Worcester is pronounced wooster';
    } else {
      return 'E.g. Company name must always be written as 3Play Media.';
    }
  }

  function textArea() {
    return (
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label>Order Instructions</Form.Label>
        <OverlayTrigger
          placement="top"
          trigger={['hover', 'focus']}
          delay={{ hide: 500 }}
          overlay={cheatSheetPopover()}
        >
          <i className={css(styles.cheatsheetIcon) + ' fa fa-info-circle'}></i>
        </OverlayTrigger>
        {showVoiceArtistAudioDescriptionNote && (
          <div>
            <b>Note:</b> If your order is part of a related series of videos, please note them here
            and select the appropriate folder in the next step. If you have a request for a specific
            type of description voice (pitch, speed, other characteristics), please note them here.
            Keep in mind requests cannot be guaranteed.
          </div>
        )}
        {formInput()}
        <div className={css(styles.charCount)}>
          {MAX_CHEATSHEET_CHARS -
            cheatSheetState[serviceType].text.length +
            ' Characters Remaining'}
        </div>
      </Form.Group>
    );
  }

  function formInput() {
    if (cheatsheetToUpload?.sourceS3File) {
      return (
        <OverlayTrigger placement="top" trigger={['hover', 'focus']} overlay={textPopover}>
          <Form.Control
            as="textarea"
            rows="4"
            tabIndex={CHEATSHEET_TEXT_INDEX}
            disabled={true}
            isInvalid={cheatsheetTextTooLong}
            placeholder={placeholderText()}
          />
        </OverlayTrigger>
      );
    } else {
      return (
        <Form.Control
          as="textarea"
          tabIndex={CHEATSHEET_TEXT_INDEX}
          rows="4"
          onChange={(e) =>
            cheatSheetDispatch({ type: 'addText', text: e.target.value, serviceType: serviceType })
          }
          isInvalid={cheatsheetTextTooLong}
          placeholder={placeholderText()}
          value={cheatSheetState[serviceType].text}
        />
      );
    }
  }

  return (
    <Form className="mt-3">
      <Form.Group controlId="cheatSheetGlossary" className={css(styles.flex)}>
        <Form.Check
          aria-checked={cheatSheetState[serviceType].checked}
          defaultChecked={cheatSheetState[serviceType].checked}
          label="Add Order Instructions"
          onClick={() => cheatSheetDispatch({ type: 'toggle', serviceType: serviceType })}
          tabIndex={CHEATSHEET_CHECK_INDEX}
          type="checkbox"
        />
        <OverlayTrigger
          placement="top"
          trigger={['hover', 'focus']}
          delay={{ hide: 500 }}
          overlay={infoPopover()}
        >
          <i className={css(styles.cheatsheetIcon) + ' fa fa-info-circle'}></i>
        </OverlayTrigger>
      </Form.Group>
      {cheatSheetState[serviceType].checked && (
        <>
          <Row>
            <Col>
              {textArea()}
              {cheatSheetState[serviceType].uploadAllowed && (
                <CheatsheetUpload
                  cheatsheetToUpload={cheatsheetToUpload}
                  openStartCheatsheetUploadModal={openStartCheatsheetUploadModal}
                  rejectedCheatsheet={rejectedCheatsheet}
                  setRejectedCheatsheet={setRejectedCheatsheet}
                  disabled={hasCheatsheetText}
                  serviceType={serviceType}
                />
              )}
            </Col>
          </Row>
        </>
      )}
      {cheatsheetToUpload?.sourceS3File && cheatSheetState[serviceType].uploadAllowed && (
        <FileUploadTable
          cheatsheet={true}
          // Pass array with one item, which is the cheatsheet for the right service
          filesToUpload={cheatsheetToUpload ? [cheatsheetToUpload] : []}
          rejectedFiles={rejectedCheatsheet}
          removeFromUploadList={() => removeFromCheatsheetUploadList(serviceType)}
          setRejectedFiles={setRejectedCheatsheet}
          sizeLimit={'3MB'}
          uploading={uploading}
          uploadProgress={uploadProgress}
          showFileSize
          showUploader
        />
      )}
    </Form>
  );
}

AddCheatSheet.propTypes = {
  cheatSheetDispatch: PropTypes.func,
  cheatSheetState: PropTypes.object,
  cheatsheetToUpload: PropTypes.object,
  openStartCheatsheetUploadModal: PropTypes.func,
  removeFromCheatsheetUploadList: PropTypes.func,
  serviceType: PropTypes.string,
  uploading: PropTypes.bool,
  uploadProgress: PropTypes.object,
  showVoiceArtistAudioDescriptionNote: PropTypes.bool,
};

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
  },
  cheatsheetIcon: {
    padding: '0.25em; 0.25em; 0em',
  },
  charCount: {
    'text-align': 'right',
    'font-size': '.9rem',
    'margin-top': '.2rem',
    'font-weight': '600',
    opacity: '.8',
  },
});

export default AddCheatSheet;
