import React from 'react';
import PropTypes from 'prop-types';

import ActiveChallenges from './ActiveChallenges';

const ChallengeTracker = ({ data = [] }) => {
  return !data.length ? (
    <></>
  ) : (
    <>
      <h2>Challenge Tracker</h2>
      <p>Participate and complete more challenges to unlock bonuses and rewards.</p>

      <ActiveChallenges data={data} />
    </>
  );
};

ChallengeTracker.propTypes = {
  data: PropTypes.array,
};

export default ChallengeTracker;
