import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';

import { useDropzone } from 'react-dropzone';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import { Table } from '@threeplayground/index';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import { awsUploadFileQuery } from '~/components/app/order_form/data/queries';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';
import { use3PMutation } from '~/logic/unstable/use3PMutation';
import { userLogger } from '~/logic/UserLogger';

let nextId = 1;

const UploadTable = ({ acceptedFiles, removeUploadedFile }) => {
  const sorted = [...acceptedFiles].sort((a, b) => {
    return a.id - b.id;
  });
  const columns = [
    {
      header: 'File Name',
      accessor: 'name',
      cell: 'string',
    },
    {
      header: '',
      accessor: 'percentComplete',
      cell: ({ value }) => {
        return `(${value}%)`;
      },
    },
    {
      header: '',
      accessor: 'id',
      cell: ({ value }) => {
        return (
          <>
            <a href="#" onClick={() => removeUploadedFile(value)}>
              <i className="fa fa-trash-o" />
            </a>
          </>
        );
      },
    },
  ];
  return (
    <div className={css(styles.uploadTable)}>
      <Table columns={columns} data={sorted} />
    </div>
  );
};

const MediaUploadWrapper = ({
  acceptedFileTypes,
  allowMultiple,
  disabled,
  instructions,
  mediaFileId,
}) => {
  const apiClient = new ThreeplayApiV2('/data');
  return (
    <>
      <ErrorBoundary component="MediaUploadWrapper">
        <ThreeplayAPIProvider client={apiClient}>
          <MediaUpload
            acceptedFileTypes={acceptedFileTypes}
            allowMultiple={allowMultiple}
            disabled={disabled}
            instructions={instructions}
            mediaFileId={mediaFileId}
          />
        </ThreeplayAPIProvider>
      </ErrorBoundary>
    </>
  );
};

const MediaUpload = ({ acceptedFileTypes, allowMultiple, disabled, instructions, mediaFileId }) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [uploadDisabled, setUploadDisabled] = useState(disabled);

  //  5,368,709,120 bytes = 5 GB
  const maxSize = 5_368_709_120;

  const createAudioAssetMutation = `mutation CreateAudioAssetMutation($data: CreateAudioAssetInput!) {
    createAudioAssetMutation(data: $data) {
      data {
        externalUrl
      }
      errors {
        code
        message
      }
    }
  }
  `;

  const { mutateAsync } = use3PMutation(createAudioAssetMutation, {
    extractKey: 'createAudioAssetMutation',
  });

  useEffect(() => {
    if (allowMultiple === false) {
      if (acceptedFiles.length === 0) {
        setUploadDisabled(false);
      } else {
        setUploadDisabled(true);
      }
    }
  }, [acceptedFiles]);

  const onDrop = useCallback(
    async (accepted, rejected) => {
      if (accepted.length > 0) {
        accepted.forEach(async (file) => {
          const awsData = await fetchUploadData({ file: file });
          const s3Location = `s3://${awsData.bucket}/${awsData.key}`;
          const acceptedFile = {
            id: nextId++,
            name: file.name,
            externalUrl: s3Location,
            percentComplete: 0,
          };
          setAcceptedFiles((existing) => [...existing, acceptedFile]);
          uploadFileToS3(awsData, file);
        });
      }
      if (rejected) {
        setRejectedFiles(rejected);
      }
    },
    [acceptedFiles, rejectedFiles]
  );

  const fetchUploadData = async ({ file = file, type = 'newUpload', keyPrefix = '' }) => {
    const response = await threeplayApi.request(awsUploadFileQuery, {
      fileName: file.name,
      type: type,
      keyPrefix: keyPrefix,
    });
    return response.data.project.awsUpload;
  };

  const buildFormData = (awsData, file) => {
    const formData = new FormData();
    formData.append('key', awsData.key);
    formData.append('AWSAccessKeyId', awsData.accessKey);
    formData.append('acl', 'private');
    formData.append('policy', awsData.policy);
    formData.append('signature', awsData.signature);
    formData.append('success_action_status', 200);
    formData.append('file', file);
    return formData;
  };

  const uploadFailed = (key) => {
    console.log('Failed: ' + key);
  };

  const updateProgress = useCallback(
    (e, file) => {
      if (e.lengthComputable) {
        setAcceptedFiles((existing) => {
          const percentComplete = Math.round((e.loaded * 100) / e.total);
          const matching = existing.find((item) => item.name === file.name);
          if (matching) {
            const updated = { ...matching, percentComplete: percentComplete };
            const others = existing.filter((item) => item.name !== file.name);
            return [...others, updated];
          }
          return [...existing];
        });
      }
    },
    [setAcceptedFiles]
  );

  const uploadFileToS3 = (awsData, file) => {
    const payload = buildFormData(awsData, file);
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (e) => updateProgress(e, file));
    xhr.open('POST', awsData.amazonUrl, true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
        const errorDetails = {
          status: xhr.status,
          statusText: xhr.statusText,
          response: xhr.responseText,
        };
        userLogger.logEvent('AdditionalAssets', 'S3 source upload error', {
          bucket: awsData.bucket,
          key: awsData.key,
          errorDetails: errorDetails,
        });
        uploadFailed(awsData.key);
      }
    };
    xhr.send(payload);
  };

  const removeUploadedFile = (id) => {
    const keepers = acceptedFiles.filter((file) => file.id != id);
    setAcceptedFiles(keepers);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    disabled: uploadDisabled,
    multiple: allowMultiple,
    onDrop: onDrop,
    minSize: 0,
    maxSize: maxSize,
  });

  const submitEnabled = useCallback(() => {
    return acceptedFiles.every((file) => file.percentComplete === 100);
  }, [acceptedFiles]);

  const submitFiles = async (acceptedFiles) => {
    setSubmitting(true);
    const additionalAssets = acceptedFiles.map((file) => {
      return { name: file.name, externalUrl: file.externalUrl };
    });

    const data = {
      mediaFileId: mediaFileId,
      additionalAssets: additionalAssets,
    };

    const {
      data: responseData,
      errors,
      globalErrors: mutationGlobalErrors,
    } = await mutateAsync({
      data,
    });
    setSubmitting(false);

    if (errors) {
      setUploadedFiles([]);
      setUploadErrors(errors);
    } else if (mutationGlobalErrors) {
      setUploadedFiles([]);
      setUploadErrors(mutationGlobalErrors);
    }

    if (responseData) {
      setUploadedFiles(responseData);
      setUploadErrors([]);
    }
  };

  return (
    <>
      <div className={css(styles.alerts)}>
        {uploadedFiles.length > 0 && uploadErrors.length === 0 && (
          <Alert variant="success">Upload succeeded</Alert>
        )}
        {uploadErrors.length > 0 && (
          <Alert variant="danger">There was a problem with your upload</Alert>
        )}
      </div>
      {uploadedFiles.length === 0 && (
        <div {...getRootProps({ className: css(styles.dropzone) })}>
          <input {...getInputProps()} />
          <div className={css(styles.centerText)}>
            <p>{instructions}</p>
            <Button variant="primary" disabled={uploadDisabled} tabIndex="123456789">
              Choose File
            </Button>
            <p>
              <small className="text-muted">File must be smaller than 5 GB</small>
            </p>
          </div>
        </div>
      )}
      {acceptedFiles.length > 0 && (
        <>
          <UploadTable acceptedFiles={acceptedFiles} removeUploadedFile={removeUploadedFile} />
          {uploadedFiles.length === 0 && (
            <div className={css(styles.submit)}>
              <Button
                variant="primary"
                onClick={() => submitFiles(acceptedFiles)}
                disabled={submitting || !submitEnabled()}
                className={css(styles.submitButton)}
              >
                {submitting ? 'Submitting' : 'Submit'}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  alerts: {
    marginTop: '1em',
  },
  dropzone: {
    border: '3px dashed #eee',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px 20px 10px',
    borderWidth: '2px',
    borderRadius: '2px',
    color: '#212529',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    ':focus': {
      borderColor: '#2196f3',
    },
    marginTop: '1em',
    marginBottom: '1em',
  },
  removeText: {
    ':hover': {
      cursor: 'not-allowed',
    },
  },
  submit: {
    marginBottom: '1rem',
  },
  submitButton: {
    marginTop: '0.5em',
  },
  dragging: {
    opacity: 0.6,
  },
  centerText: {
    textAlign: 'center',
  },
  uploadTable: {
    marginBottom: '0.5em',
  },
});

MediaUpload.propTypes = {
  acceptedFileTypes: PropTypes.object,
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  instructions: PropTypes.string,
  mediaFileId: PropTypes.number,
};

MediaUploadWrapper.propTypes = {
  acceptedFileTypes: PropTypes.object,
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  instructions: PropTypes.string,
  mediaFileId: PropTypes.number,
};

UploadTable.propTypes = {
  acceptedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  removeUploadedFile: PropTypes.func,
};

export default MediaUploadWrapper;
