import { StyleSheet } from 'aphrodite';

const selectedSimple = {
  background: '#d9ecf4',
  border: '4px solid #007EB5',
  'border-radius': '5px',
  'box-shadow': '2px 2px 4px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  margin: '10px 15px 10px 0px',
};

const deselectedSimple = {
  border: '4px solid rgba(100,100,100,.15)',
  'box-shadow': 'none',
  cursor: 'pointer',
  margin: '10px 15px 10px 0px',
};

const flexDetails = {
  'flex-basis': 'calc(33% - 10px)',
  'flex-grow': '0',
  'flex-shrink': '0',
};

const selectedStyles = StyleSheet.create({
  selected: {
    ...selectedSimple,
    ...flexDetails,
    'min-width': '12rem',
  },
  deselected: {
    ...deselectedSimple,
    ...flexDetails,
    'min-width': '12rem',
  },
  selectedSimple: selectedSimple,
  deselectedSimple: deselectedSimple,
  selectedWithoutMargin: {
    ...selectedSimple,
    ...flexDetails,
    margin: null,
  },
  deselectedWithoutMargin: {
    ...deselectedSimple,
    ...flexDetails,
    margin: null,
  },
});

export default selectedStyles;
