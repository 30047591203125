import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { StyleSheet, css } from 'aphrodite';
import Button from 'react-bootstrap/Button';

import { bytesToSize } from '~/helpers/files';

function FileUpload(props) {
  // 2147483648 bytes = 2 GB
  const maxSize = 2147483648;

  const [isRejected, setIsRejected] = useState(false);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length === 0 && rejectedFiles.length > 0) {
      setIsRejected(true);
      props.setFile(null);
    } else {
      setIsRejected(false);
      props.setFile(acceptedFiles[0]);
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, rejectedFiles } = useDropzone({
    onDrop: onDrop,
    accept: props.supportedFormats,
    minSize: 0,
    maxSize: maxSize,
    multiple: false,
  });
  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  const files = acceptedFiles.map((file) => (
    <span key={file.path}>
      <b>File:</b> {file.path} ({bytesToSize(file.size)})
    </span>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: css(styles.dropzone) })}>
        <input {...getInputProps()} />
        <div className={css(styles.centerText)}>
          <b>Drag your new file here</b>
          <p>or</p>
          <Button variant="primary">Choose File</Button>
          <p>
            <small>Maximum file size is 2GB</small>
          </p>
        </div>
        {isFileTooLarge && <div className="text-danger mt-2">File is too large.</div>}
        {isRejected && <div className="text-danger mt-2">File type not accepted, sorry!</div>}
      </div>
      {acceptedFiles.length > 0 && (
        <aside className="mt-3">
          <p>{files}</p>
        </aside>
      )}
    </section>
  );
}

const styles = StyleSheet.create({
  dropzone: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#959595',
    borderStyle: 'dashed',
    backgroundColor: '#F1F1F1',
    color: '#212529',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    ':focus': {
      borderColor: '#2196f3',
    },
  },
  dragging: {
    opacity: 0.6,
  },
  centerText: {
    textAlign: 'center',
  },
});

FileUpload.propTypes = {
  supportedFormats: PropTypes.string,
  setFile: PropTypes.func,
};

export default FileUpload;
