import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { numberToCurrency } from '~/helpers/numbers';
import { checkKeyPress } from '~/helpers/checkKeyPress';
import WithIcon from '~/components/app/common/WithIcon';
import selectedStyles from '../styles/selectedDeselectedStyles';

function TurnaroundLevelCardBody(props) {
  return (
    <Card
      bg="light"
      data-testid={props.name}
      tabIndex={props.index}
      onClick={() => (props.turnaroundDisabled ? false : props.setTurnaroundLevel(props.id))}
      onKeyDown={(e) =>
        checkKeyPress(e, () => props.setTurnaroundLevel(props.id), { triggerOnSpace: true })
      }
      className={
        props.orderOptions.turnaroundLevel && props.orderOptions.turnaroundLevel.name === props.name
          ? css(
              selectedStyles.selectedWithoutMargin,
              styles.box,
              props.turnaroundDisabled && styles.disabled
            )
          : css(
              selectedStyles.deselectedWithoutMargin,
              styles.box,
              props.turnaroundDisabled && styles.disabled
            )
      }
    >
      <Card.Body className={css(styles.turnaroundLevel)}>
        <div className={css(styles.turnaroundName)}>{props.displayName}</div>
        <div>{props.deliveryDate}</div>
        <b>
          {props.surcharge &&
            (props.surcharge.amount >= 0 ? '+' : '') +
              numberToCurrency.format(props.surcharge.amount) +
              '/' +
              props.surcharge.unit}
        </b>
      </Card.Body>
    </Card>
  );
}

function TurnaroundLevelCard(props) {
  if (props.turnaroundDisabled) {
    return (
      <OverlayTrigger
        placement="auto"
        trigger={['hover', 'focus']}
        delay={{ hide: 2000 }}
        overlay={
          <Tooltip>
            <div className={css(styles.tooltip)}>
              <WithIcon icon="fa fa-ban">{props.turnaroundDisabledTooltip}</WithIcon>
            </div>
          </Tooltip>
        }
      >
        <span className="d-inline-block">
          <TurnaroundLevelCardBody {...props} />
        </span>
      </OverlayTrigger>
    );
  } else {
    return (
      <span className="d-inline-block">
        <TurnaroundLevelCardBody {...props} />
      </span>
    );
  }
}

TurnaroundLevelCardBody.propTypes = {
  deliveryDate: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
  displayName: PropTypes.string,
  orderOptions: PropTypes.object,
  setTurnaroundLevel: PropTypes.func,
  surcharge: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.unit,
  }),
  turnaroundDisabled: PropTypes.bool,
};

TurnaroundLevelCard.propTypes = {
  deliveryDate: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
  displayName: PropTypes.string,
  orderOptions: PropTypes.object,
  turnaroundDisabled: PropTypes.bool,
  setTurnaroundLevel: PropTypes.func,
  surcharge: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.string,
  }),
  turnaroundDisabledTooltip: PropTypes.string,
};

const styles = StyleSheet.create({
  box: {
    'margin-right': '1rem',
    'margin-bottom': '0.5rem',
    cursor: 'pointer',
    'max-width': '25rem',
    'min-width': '15rem',
  },
  turnaroundLevel: {
    'text-align': 'center',
  },
  turnaroundName: {
    'font-size': '1.1rem',
    'font-weight': 'bold',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
});

export default TurnaroundLevelCard;
