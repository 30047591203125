const RULES = [
  { name: 'length', regex: /.{8,}/, rule: 'At least 8 characters' },
  { name: 'lowercase', regex: /[a-z]/, rule: 'At least 1 lowercase character' },
  { name: 'uppercase', regex: /[A-Z]/, rule: 'At least 1 uppercase character' },
  { name: 'number', regex: /[0-9]/, rule: 'At least 1 number' },
  {
    name: 'symbol',
    regex: /[\^\$\*\.\[\]\{\}\(\)\?\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`]/,
    rule: 'At least 1 special character or symbol',
  },
];

function validatePassword(password) {
  const results = RULES.map((rule) => ({
    name: rule.name,
    rule: rule.rule,
    ok: rule.regex.test(password),
  }));
  const ok = results.some((ok) => ok === false);
  return { ok, results };
}

export { validatePassword };
