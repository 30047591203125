import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

import MultiServiceTable from './MultiServiceTable';
import { adPreviewPath, adUpgradePath } from '~/helpers/app/paths';
import { titleize } from '~/helpers/strings';
import { userLogger } from '~/logic/UserLogger';

import WithIcon from '~/components/app/common/WithIcon';
import moment from 'moment-timezone';
import { DATE_FORMAT } from '~/components/app/live_auto_captioning/common/constants';
import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';

function AudioDescriptionServiceCardContents(props) {
  const headers = [null, 'Ordered', 'Level', 'Voice Type', 'Status', null, null];
  const assetReprocessing = props.fileData?.reprocessingAssets?.length > 0;
  const assetsRemoved = props.fileData?.assetsRemoved;
  const tooltipText = assetsRemoved
    ? 'Downloading is disabled for files with scrubbed content'
    : null;
  const downloadButton = assetsRemoved ? (
    <OverlayTrigger placement="auto" overlay={<Tooltip>{tooltipText}</Tooltip>}>
      <span>
        <WithIcon icon="fa fa-download"></WithIcon>
      </span>
    </OverlayTrigger>
  ) : (
    <WithIcon icon="fa fa-download"></WithIcon>
  );

  function serviceIsVoiceArtist(service) {
    return (
      service.type === 'AudioDescriptionNarrationService' ||
      service.type === 'AudioDescriptions::VoiceOvers::VoiceOverAudioDescriptionService' ||
      service.type === 'AudioDescriptions::Reformats::AudioDescriptionReformatService' ||
      service.type === 'AudioDescriptions::Revisions::AudioDescriptionRevisionService'
    );
  }

  function serviceDisplayText(service) {
    if (serviceIsVoiceArtist(service)) {
      return 'Voice Artist';
    }
    return `${service.speaker} (${service.language.name}, ${service.speakingRate})`;
  }

  function downloadClick(service) {
    if (!assetsRemoved) {
      props.setShowModal.downloadAd(true, service.audioDescriptionId);
      userLogger.logEvent('FileShow', 'AD Download Button');
    }
  }

  return (
    <MultiServiceTable headers={headers}>
      {props.services.map((service) => (
        <tr key={service.id}>
          <td>
            {!serviceIsVoiceArtist(service) && service.status === 'complete' && !assetReprocessing && (
              <a
                href={adPreviewPath(service.audioDescriptionId)}
                target="blank"
                className={css(styles.serviceAction)}
                title="Preview Audio Description"
              >
                <FontAwesomeIcon icon={faPlayCircle} />
              </a>
            )}
            {!serviceIsVoiceArtist(service) && service.status === 'in_progress' && (
              <ThreePlayTooltip
                tooltipText={
                  <div>
                    <b>Due Date:</b>{' '}
                    {moment(parseInt(service.deadline)).tz(props.userTimeZone).format(DATE_FORMAT)}
                    <br />
                    <b>Turnaround:</b> {service.turnaroundLevel}
                  </div>
                }
                appearInTabNavigation
              />
            )}
          </td>
          <td>{moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_FORMAT)}</td>
          <td>{service.level + (service.autoExtend ? ' (3Play Recommended)' : '')}</td>
          <td>{serviceDisplayText(service)}</td>
          <td>
            {service.status === 'complete' && assetReprocessing ? (
              <i>Processing</i>
            ) : (
              titleize(service.status)
            )}
          </td>
          <td>
            {service.status === 'complete' && !assetReprocessing && (
              <a
                href={assetsRemoved ? null : '#'}
                onClick={() => {
                  downloadClick(service);
                }}
                title="Download Audio Description"
              >
                {downloadButton}
              </a>
            )}
          </td>
          <td className={css(styles.rightPadding)}>
            {service.status === 'complete' && !assetReprocessing && service.canUpgrade && (
              <a
                href={adUpgradePath(props.fileData.id, service.id)}
                key="upgrade-ad"
                className={css(styles.serviceAction)}
              >
                Upgrade
              </a>
            )}
          </td>
        </tr>
      ))}
    </MultiServiceTable>
  );
}

AudioDescriptionServiceCardContents.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  fileData: PropTypes.shape({
    id: PropTypes.string,
    reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
    assetsRemoved: PropTypes.bool,
  }),
  setShowModal: PropTypes.shape({
    downloadAd: PropTypes.func,
  }),
  userTimeZone: PropTypes.string,
};

const styles = StyleSheet.create({
  serviceAction: {
    color: '#007EB5',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  noPadding: {
    paddingLeft: '0px',
    paddingRight: '0em',
  },
  rightPadding: {
    paddingLeft: '0px',
    paddingRight: '0.5em',
  },
});

export default AudioDescriptionServiceCardContents;
