import React from 'react';

import PropTypes from 'prop-types';
import SelectedFormats from './SelectedFormats';

import { timeToMs } from './helpers';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';

class FinalizeDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        smpteCaptionFormats: false,
        transcriptFormats: false,
        audioDescriptionFormats: false,
      },
      name: '',
      emailUser: false,
      errorResponse: false,
    };
    this.setError = this.setError.bind(this);
  }

  setError(formatType, isError) {
    const errors = this.state.errors;
    if (errors[formatType] !== isError) {
      errors[formatType] = isError;
      this.setState({ errors: errors });
    }
  }

  useSmpteOverrides(settings) {
    return !!settings['settings']['smpteFrameRate'];
  }

  useAdvancedOptions(web, transcript) {
    if (
      web['settings']['webFirstCaptionStart'] !== web['defaultSettings']['webFirstCaptionStart'] ||
      transcript['settings']['transcriptFirstWordStart'] !==
        transcript['settings']['transcriptFirstWordStart']
    ) {
      return true;
    }
    return false;
  }

  buildFormData(smpte = [], web = [], transcript = []) {
    const formData = new FormData();
    formData.append('authenticity_token', this.props.formAuthToken);
    formData.append('download[media_file_ids]', Object.keys(this.props.mediaFiles).join(','));
    for (const formatType in this.props.formatInfo) {
      for (const format in this.props.formatInfo[formatType].selectedFormats) {
        if (this.props.formatInfo[formatType].selectedFormats[format].adFormat) {
          if (this.props.formatInfo[formatType].selectedFormats[format]['id'] == 'encoded_ad') {
            formData.append('download[encoded_ad]', true);
          } else {
            formData.append(
              'download[ad_format_ids][]',
              this.props.formatInfo[formatType].selectedFormats[format]['id']
            );
          }
        } else if (formatType === 'aiDubbingFormats') {
          formData.append(
            'download[ai_dubbing_ids][]',
            this.props.formatInfo[formatType].selectedFormats[format]['id']
          );
        } else if (formatType === 'broadcastScripts') {
          formData.append(
            'download[broadcast_format_ids][]',
            this.props.formatInfo[formatType].selectedFormats[format]['id']
          );
        } else if (formatType === 'captionStyling') {
          formData.append(
            'download[caption_styling_format_ids][]',
            this.props.formatInfo[formatType].selectedFormats[format]['id']
          );
        } else if (formatType === 'chooseForMeFormats') {
          formData.append(
            'download[choose_for_me_format_ids][]',
            this.props.formatInfo[formatType].selectedFormats[format]['id']
          );
        } else if (formatType === 'localizationFormats') {
          formData.append(
            'download[localization_format_ids][]',
            this.props.formatInfo[formatType].selectedFormats[format]['id']
          );
        } else {
          if (
            this.props.formatInfo[formatType].selectedFormats[format]['id'] == 'caption_encoding'
          ) {
            formData.append('download[caption_encoding]', true);
          } else {
            formData.append(
              'download[output_format_ids][]',
              this.props.formatInfo[formatType].selectedFormats[format]['id']
            );
          }
        }
      }
    }
    if (smpte.selectedFormats && smpte.selectedFormats.length > 0) {
      formData.append('use_smpte_overrides', this.useSmpteOverrides(smpte));
      if (this.useSmpteOverrides(smpte)) {
        if (smpte['settings']['smpteFrameRate']) {
          formData.append(
            'download[smpte_overrides][frames_per_second]',
            smpte['settings']['smpteFrameRate']
          );
        }
        if (smpte['settings']['smpteStartTime']) {
          formData.append(
            'download[smpte_overrides][starting_timecode_smpte]',
            smpte['settings']['smpteStartTime']
          );
        }
      }
    }
    formData.append('download[name]', this.state.name);
    if (
      web.selectedFormats &&
      web.selectedFormats.length > 0 &&
      transcript.selectedFormats &&
      transcript.selectedFormats.length > 0
    ) {
      formData.append('use_advanced_options', this.useAdvancedOptions(web, transcript));
    }
    if (web.selectedFormats && web.selectedFormats.length > 0) {
      formData.append(
        'download[advanced_options][web_caption_start_time]',
        timeToMs(web['settings']['webFirstCaptionStart'])
      );
    }
    if (transcript.selectedFormats && transcript.selectedFormats.length > 0) {
      formData.append(
        'download[advanced_options][transcript_start_time]',
        timeToMs(transcript['settings']['transcriptFirstWordStart'])
      );
    }
    formData.append('download[email_user]', this.state.emailUser);
    return formData;
  }

  createDownload() {
    const smpte = this.props.formatInfo['smpteCaptionFormats'];
    const web = this.props.formatInfo['webCaptionFormats'];
    const transcript = this.props.formatInfo['transcriptFormats'];
    const formData = this.buildFormData(smpte, web, transcript);
    const requestUrl = '/downloads';

    fetch(requestUrl, {
      method: 'POST',
      body: formData,
      credentials: 'same-origin',
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.success) {
            window.location.href = '/downloads?status=processing';
          }
          if (data.error) {
            this.setState({ errorResponse: true });
          }
        }
      });
  }

  toggleEmail() {
    this.setState({ emailUser: !this.state.emailUser });
  }

  shouldDisplaySelectedFormats(formatType) {
    if (this.props.formatInfo[formatType]['selectedFormats']) {
      return (
        this.props.formatInfo[formatType]['selectedFormats'].length > 0 &&
        formatType !== 'favoriteFormats'
      );
    }
    return false;
  }

  getTotalSelected() {
    let totalSelected = 0;
    for (const format in this.props.formatInfo) {
      totalSelected += this.props.formatInfo[format]['selectedFormats'].length;
    }
    return totalSelected;
  }

  render() {
    const totalSelected = this.getTotalSelected();
    return (
      <React.Fragment>
        <Card>
          <Card.Header>
            <h2>Finalize Download</h2>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group as={Row} controlId="formHorizontalName">
                <Form.Label column sm={3}>
                  Name
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    placeholder="Folder Name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={3}>
                  Email
                </Form.Label>
                <Col sm={9}>
                  <Row className="ml-0 align-row-with-label">
                    <Form.Check
                      onChange={() => this.toggleEmail()}
                      value={this.state.emailUser}
                      type="checkbox"
                      label="Receive Zip Download By Email"
                    />
                    <OverlayTrigger
                      key={'top-overlay-email-notification'}
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-email-notification`}>
                          Checking this box will send an email to {this.props.emailAddress} with a
                          link to download the zip folder.
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}
                    >
                      <i className="fa fa-question-circle align-question-tooltip" />
                    </OverlayTrigger>
                  </Row>
                </Col>
              </Form.Group>
              <hr />
              <h3>Selected Formats</h3>
              {totalSelected === 0 ? (
                <p className="text-muted">No Formats Selected</p>
              ) : (
                Object.keys(this.props.formatInfo).map((formatType, index) =>
                  this.props.formatInfo[formatType]['selectedFormats'].length > 0 &&
                  formatType !== 'favoriteFormats' ? (
                    <SelectedFormats
                      key={'selected-formats-list-' + index}
                      setError={this.setError}
                      toggleSelectedFormat={this.props.toggleSelectedFormat}
                      formatType={formatType}
                      formatTypeInfo={this.props.formatInfo[formatType]}
                      updateFormatSettings={this.props.updateFormatSettings}
                    />
                  ) : (
                    <span key={'no-selected-formats-' + index} />
                  )
                )
              )}
            </Form>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col className="col-lg-7 my-auto">
                <a href="/captions_settings" target="_blank">
                  Set Default Additional Settings
                </a>
              </Col>
              <Col>
                <Button
                  className="float-right"
                  variant="primary"
                  disabled={
                    Object.keys(this.state.errors)
                      .map((k) => this.state.errors[k])
                      .includes(true) || totalSelected == 0
                      ? true
                      : false
                  }
                  onClick={() => this.createDownload()}
                >
                  Create Download
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
        <Modal
          show={this.state.errorResponse}
          onHide={() => this.setState({ errorResponse: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Error Downloading Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger">
              There was a problem with your download. Please try again later.
            </Alert>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

FinalizeDownload.propTypes = {
  toggleSelectedFormat: PropTypes.func,
  formats: PropTypes.array,
  mediaFiles: PropTypes.object,
  formatInfo: PropTypes.object,
  formAuthToken: PropTypes.string,
  updateFormatSettings: PropTypes.func,
  emailAddress: PropTypes.string,
};

export default FinalizeDownload;
