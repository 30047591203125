import React, { useState, useCallback, useMemo } from 'react';
import { StyleSheet, css } from 'aphrodite';

import Form from 'react-bootstrap/Form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import { useInfiniteMediaFileSeriesQuery } from '~/api/appApi';

const PAGE_SIZE = 30;

interface Selection {
  id: string;
  name: string;
}

interface SelectSeriesProps {
  series: Selection;
  setSeries: (series: Selection | null) => void;
}

function SelectSeries({ series, setSeries }: SelectSeriesProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const { data, fetchNextPage, isFetching } = useInfiniteMediaFileSeriesQuery(
    { page: 1, perPage: PAGE_SIZE, searchTerm },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.series.nextPage ? { page: lastPage.series.nextPage } : undefined;
      },
    }
  );

  const options = useMemo(() => {
    return data?.pages.map((page) => page.series.results).flat() ?? [];
  }, [data]);

  const handleEmptySearch = useCallback((val: string) => {
    // We need to specially clear the search term to refetch the initial page,
    // since AsyncTypeahead onSearch does not fire when the input becomes empty.
    if (val.length === 0) {
      setSearchTerm('');
    }
  }, []);

  function onPaginate() {
    // fetchNextPage will be a noop when there are no more pages to fetch,
    // so this is safe to call even on the last page with the funky page offset.
    fetchNextPage().catch(console.error);
  }

  return (
    <>
      <div className={css(styles.question)}>Is this part of a series?</div>
      <Form.Group className={css(styles.inputWidth)}>
        <AsyncTypeahead
          id="select-series"
          labelKey="name"
          paginate
          // wish there was a better prop API for specifying "has more", but this
          // is what the docs say to do.
          maxResults={PAGE_SIZE - 1}
          minLength={0}
          useCache={false} // useInfiniteMediaFileSeriesQuery already caches
          onInputChange={handleEmptySearch}
          onPaginate={onPaginate}
          onSearch={setSearchTerm}
          selected={series ? [series] : []}
          onChange={(selected) => setSeries(selected[0] ?? null)}
          isLoading={isFetching}
          options={options}
        />
      </Form.Group>
    </>
  );
}

const styles = StyleSheet.create({
  question: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    marginBottom: '0.25rem',
  },
  inputWidth: {
    maxWidth: '19rem',
  },
});

export default SelectSeries;
