import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import ContactInformationForm from './ContactInformationForm';
import * as services from './services/services';

const NotAcceptingApplications = ({
  countries = [],
  usStates = [],
  createApplicantWaitlist = services.createApplicantWaitlist,
}) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (data) => {
    setSuccess(null);
    setError(null);
    setIsLoading(true);

    createApplicantWaitlist(data)
      .then(({ errors }) => {
        errors ? setError(true) : setSuccess(true);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="d-flex flex-column align-items-center justy-content-center">
      <div className="tight mt-4">
        <div className="text-center mb-4 pb-4">
          <h1>We&apos;re not currently accepting applications right now!</h1>
          <p className="primary-text">
            Enter your email address to receive a notification when we open applications again.
          </p>
        </div>
        <ContactInformationForm
          className={'my-4'}
          countries={countries}
          usStates={usStates}
          onSubmit={handleSubmit}
          disableSubmit={isLoading}
        />

        <div className="mt-4">
          {success && (
            <Alert variant="success">
              <p>
                Thanks for submitting your email! We&apos;ll reach out when we reopen applications.
              </p>
            </Alert>
          )}
          {error && (
            <Alert variant="danger">
              <p>
                <b>Something went wrong!</b>
              </p>
              <p>Try refreshing the page and resubmitting.</p>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

NotAcceptingApplications.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string.isRequired),
  usStates: PropTypes.arrayOf(PropTypes.string.isRequired),
  createApplicantWaitlist: PropTypes.func,
};

export default NotAcceptingApplications;
