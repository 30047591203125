import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Collapse, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

class SmpteSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      smpteStartTime: this.props.settings['smpteStartTime'],
      defaultSettings: this.props.defaultSettings,
      settings: this.props.settings,
      errors: {
        smpte: '',
      },
      showStartTime: false,
    };
  }

  frameRateTooltip() {
    return (
      "By default, this will set the frame rate of the output file to your file's frame rate in 3Play Media. " +
      'You can select another frame rate to override this setting for this download.'
    );
  }

  onSmpteChange(value) {
    const errors = this.state.errors;
    const timecode = value;
    const isValidSMPTETimeCode = new RegExp(
      /(^(?:(?:[0-1][0-9]|[0-2][0-3]):)(?:[0-5][0-9]:){2}(?:[0-2][0-9])$)/
    );
    if (timecode.match(isValidSMPTETimeCode) !== null) {
      errors.smpte = '';
      const formatSettings = this.props.settings;
      formatSettings['smpteStartTime'] = timecode;
      this.props.updateFormatSettings(this.props.formatType, formatSettings);
    } else {
      errors.smpte = 'Please provide a valid SMPTE timecode.';
    }
    this.setState({ smpteStartTime: timecode });
    this.props.setError(this.props.formatType, errors.smpte.length > 0);
  }

  onFrameRateChange(e) {
    const frameRate = e.target.value;
    const formatSettings = this.props.settings;
    formatSettings['smpteFrameRate'] = frameRate;
    this.props.updateFormatSettings(this.props.formatType, formatSettings);
  }

  toggleSelected(id) {
    if (id === 'defaultStartTime') {
      const errors = this.state.errors;
      errors.smpte = '';
      this.props.setError(this.props.formatType, errors.smpte.length > 0);
      const formatSettings = this.props.settings;
      delete formatSettings['smpteStartTime'];
      this.props.updateFormatSettings(this.props.formatType, formatSettings);
      this.setState({ smpteStartTime: '', showStartTime: false });
    } else {
      this.setState({
        showStartTime: true,
        smpteStartTime: this.state.defaultSettings.smpteStartTime,
      });
      this.onSmpteChange(this.state.defaultSettings.smpteStartTime);
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <>
        <Button
          className="collapse-btn"
          onClick={() => this.setState({ collapsed: !this.state.collapsed })}
          aria-controls="smpte-collapse-text"
          aria-expanded={this.state.collapsed}
          variant="link"
        >
          Additional SMPTE Caption Settings{' '}
          {this.state.collapsed ? (
            <i className="fa fa-angle-down"></i>
          ) : (
            <i className="fa fa-angle-up"></i>
          )}
        </Button>
        <Collapse in={!this.state.collapsed}>
          <div id="smpte-collapse-text">
            <Form.Group
              as={Row}
              noValidate
              className="collapsable-row"
              controlId="formHorizontalName"
            >
              <Col sm={8}>
                <Form.Label className="font-weight-bold mr-1">Frame Rate</Form.Label>
                <OverlayTrigger
                  placement="right"
                  trigger={['hover', 'focus']}
                  overlay={<Tooltip id={`tooltip-right`}>{this.frameRateTooltip()}</Tooltip>}
                >
                  <i className="fa fa-question-circle"></i>
                </OverlayTrigger>
                <Form.Control
                  as="select"
                  onChange={(e) => this.onFrameRateChange(e)}
                  value={this.props.settings['smpteFrameRate']}
                >
                  <option value="">Default</option>
                  <option value="29.97df">29.97 Drop Frame</option>
                  <option value="29.97">29.97 Non-Drop Frame</option>
                  <option value="25">25</option>
                  <option value="24">24</option>
                  <option value="23.98">23.98</option>
                  <option value="23.976">23.976</option>
                  <option value="59.94df">59.94 Drop Frame</option>
                  <option value="59.94">59.94 Non-Drop Frame</option>
                </Form.Control>
                <Form.Label className="mt-3 font-weight-bold">File Start Time</Form.Label>
                <Form.Check
                  type="radio"
                  label="Use File Start Time"
                  name="additionalSettings"
                  id="defaultStartTime"
                  onChange={(e) => this.toggleSelected(e.target.id)}
                  defaultChecked={true}
                />
                <Form.Check
                  type="radio"
                  label="Override File Start Time"
                  name="additionalSettings"
                  id="customStartTime"
                  onChange={(e) => this.toggleSelected(e.target.id)}
                  defaultChecked={false}
                />
                <Form.Control
                  noValidate
                  className={errors.smpte.length > 0 ? 'is-invalid' : ''}
                  type="text"
                  value={
                    this.state.smpteStartTime
                      ? this.state.smpteStartTime
                      : this.state.defaultSettings.smpteStartTime
                  }
                  onChange={(e) => this.onSmpteChange(e.target.value)}
                  disabled={!this.state.showStartTime}
                  hidden={!this.state.showStartTime}
                />
                {errors.smpte.length > 0 && <small className="text-danger">{errors.smpte}</small>}
              </Col>
            </Form.Group>
            <hr />
          </div>
        </Collapse>
      </>
    );
  }
}

SmpteSettings.propTypes = {
  setError: PropTypes.func,
  formatType: PropTypes.string,
  settings: PropTypes.object,
  updateFormatSettings: PropTypes.func,
  defaultSettings: PropTypes.object,
};

export default SmpteSettings;
