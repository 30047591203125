import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';
import MultiServiceTable from './MultiServiceTable';
import MediaUploadWrapper from '../MediaUpload';

function DubbingServiceCard(props) {
  const headers = ['Language', 'Ordered'];

  const isMissingAssets = () => {
    const needsAssets = props.services.filter((service) => service.waitingForCustomer);

    return needsAssets.length > 0;
  };

  return (
    <>
      <MultiServiceTable headers={headers}>
        {props.services.map((service) => (
          <tr key={service.id}>
            <td>{service.targetLanguage.fullName}</td>
            <td>
              {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
            </td>
          </tr>
        ))}
      </MultiServiceTable>
      {isMissingAssets() && (
        <MediaUploadWrapper
          acceptedFileTypes="audio/*,video/*"
          disabled={false}
          instructions="Please upload the required supplemental audio assets"
          mediaFileId={props.fileData.id}
        />
      )}
    </>
  );
}

DubbingServiceCard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  fileData: PropTypes.shape({
    id: PropTypes.string,
    reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
  }),
  userTimeZone: PropTypes.string,
};

export default DubbingServiceCard;
