import { OutputFormatCodeEnum } from '../common/constants';

export const offPlatformCaptionQualityControlJobsPaths = {
  show: {
    getLink: (id: string) => `/off_platform_caption_quality_control_jobs/${id}`,
    route: '/off_platform_caption_quality_control_jobs/:id',
  },
  downloadTranscript: {
    getLink: (id: string, outputFormatCode: OutputFormatCodeEnum | undefined) => {
      const outputFormat = outputFormatCode ? `&output_format=${outputFormatCode}` : '';
      return `/off_platform_caption_quality_control_jobs/download_transcript?transcript_id=${id}${outputFormat}`;
    },
  },
};
