import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const CustomerSupportModal = ({ closeModal, createSupportIssue, issue, setIssue, show }) => {
  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Request Customer Support</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Describe your issue</Form.Label>
            <Form.Control
              as="textarea"
              onChange={(e) => setIssue(e.target.value)}
              rows={3}
              value={issue}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-flex-end">
        <Button className="mr-2" variant="outline-dark" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button disabled={issue === ''} onClick={createSupportIssue} variant="primary">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CustomerSupportModal.propTypes = {
  closeModal: PropTypes.func,
  createSupportIssue: PropTypes.func,
  issue: PropTypes.string,
  setIssue: PropTypes.func,
  show: PropTypes.bool,
};

export default CustomerSupportModal;
