import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const capitalizeWords = (string) => {
  return string.split(' ').map(capitalize).join(' ');
};

const NoRefundWarning = ({ serviceName }) => (
  <Alert className="mt-2" variant="warning">
    <b>Note:</b> {capitalizeWords(serviceName)} orders cannot be refunded. By submitting this order,
    you acknowledge that you will not have the opportunity to review your transcripts before they
    are submitted for {serviceName}. {capitalizeWords(serviceName)} will begin as soon as the
    primary service is completed.
  </Alert>
);

NoRefundWarning.propTypes = {
  serviceName: PropTypes.string,
};

export default NoRefundWarning;
