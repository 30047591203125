import { Formatter } from '../_types';

export class CapitalizeIPronounFormatter implements Formatter {
  /**
   * A formatter that capitalizes the first person singular pronoun I.
   *
   * @example new CapitalizeIPronounFormatter().format("hi i'm Bob")
   * // returns "hi I'm Bob"
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  format(text: string) {
    return text.replace(/\bi\b/g, 'I');
  }
}
