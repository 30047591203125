import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const FileUpload = ({ disabled, index, openSampleFileModal }) => {
  return (
    <Card.Body>
      <div className={css(styles.uploadIcon)}>
        <i className="fa fa-space-shuttle"></i>
      </div>
      <div className={css(styles.uploadName)}>Select a Sample File</div>
      <Card.Text>
        <Button
          className={disabled ? 'disabled' : ''}
          tabIndex={index}
          style={{ marginTop: '4px' }}
          onClick={() => openSampleFileModal()}
          variant="primary"
        >
          Select a Sample File
        </Button>
      </Card.Text>
    </Card.Body>
  );
};

const styles = StyleSheet.create({
  uploadIcon: {
    display: 'flex',
    alignContent: 'flex-start',
    fontSize: '2.5rem',
    color: '#a5a5a5',
  },
  uploadName: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
});

FileUpload.propTypes = {
  disabled: PropTypes.bool,
  openSampleFileModal: PropTypes.func,
  index: PropTypes.number,
};

export default FileUpload;
