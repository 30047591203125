import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

export interface LinkProps {
  children: React.ReactNode;
  to: string;
  variant?: 'primary' | 'secondary' | 'danger';
}
export const Link = ({ variant = 'primary', ...props }: LinkProps) => (
  <ReactRouterLink {...props} className={`text-${variant}`} />
);
