import {
  AnyTimeSlot,
  isBookedLiveEventTimeSlot,
  isCaptionerShiftTimeSlot,
  isUnbookedLiveEventTimeSlot,
} from './types';

import moment from 'moment';
import 'moment-timezone';
import { momentLocalizer, View, Views } from 'react-big-calendar';
import { iso8601ToJsDate } from './dates';
import { getQueryParam, setQueryParam } from './query-params';

export const CALENDAR_BOOKED_LIVE_EVENT_COLOR = '#93E48A';
export const CALENDAR_UNBOOKED_LIVE_EVENT_COLOR = '#E49090';
export const CALENDAR_CAPTIONER_SHIFT_COLOR = '#68BDE2';
export const CALENDAR_CAPTIONER_AVAILABLE_COLOR = '#CAA0D2';
export const CALENDAR_FILTER_BUTTON_FONT_COLOR = '#212529';
export const CALENDAR_DISABLED_BUTTON_BACKGROUND_COLOR = '#AAAAAA';
export const CALENDAR_DISABLED_BUTTON_FONT_COLOR = '#FFFFFF';

export const getLocalizer = (timezone?: string) => {
  if (timezone) {
    moment.tz.setDefault(timezone);
  }
  return momentLocalizer(moment);
};

export const DATE_QUERY_PARAM = 'q[date]';
export const VIEW_QUERY_PARAM = 'q[view]';

// eslint-disable-next-line
export const VIEWS: string[] = Object.keys(Views as any).map((k) => (Views as any)[k] as View);

export function eventStyleGetter(event: {
  end: Date;
  start: Date;
  object: AnyTimeSlot;
  isSelected: boolean;
}) {
  let backgroundColor;
  if (isCaptionerShiftTimeSlot(event.object)) {
    backgroundColor = CALENDAR_CAPTIONER_SHIFT_COLOR;
  } else if (isBookedLiveEventTimeSlot(event.object)) {
    backgroundColor = CALENDAR_BOOKED_LIVE_EVENT_COLOR;
  } else if (isUnbookedLiveEventTimeSlot(event.object)) {
    backgroundColor = CALENDAR_UNBOOKED_LIVE_EVENT_COLOR;
  } else {
    backgroundColor = CALENDAR_CAPTIONER_AVAILABLE_COLOR;
  }

  return {
    style: {
      backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'black',
      border: '0px',
      display: 'block',
    },
  };
}

// As we change the calender date, update the query param in the URL so that we get back here after a reload
// ReactBigCalendar also has a onView callback that fires when the view changes, but that doesn't give
// The new date range, so we just use onRangeChange and infer the view from the length of the dates array
export const onRangeChange = (dates: Date[] | { start: Date; end: Date }) => {
  if (!Array.isArray(dates)) {
    throw new Error('onRangeChange: dates is not an array');
  }

  if (dates.length === 0) {
    throw new Error('onRangeChange: dates.length === 0');
  }

  const view = dates.length > 1 ? 'week' : 'day';
  const firstDate = dates[0];

  if (typeof firstDate === 'undefined') {
    throw new Error('onRangeChange: typeof firstDate === "undefined"');
  }

  setQueryParam(DATE_QUERY_PARAM, firstDate.toISOString().slice(0, 10));
  setQueryParam(VIEW_QUERY_PARAM, view);

  // After updating the query param, reload the page so that we get the
  // events for the new day from the server
  window.location.reload();
};

export function getDateQueryParam() {
  const date = getQueryParam(DATE_QUERY_PARAM);

  return date ? iso8601ToJsDate(`${date}T12:00:00Z`) : new Date();
}

export function getViewQueryParam(defaultView: View = 'week') {
  const view = getQueryParam(VIEW_QUERY_PARAM);

  return (view || defaultView) as View;
}
