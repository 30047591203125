import React from 'react';
import PropTypes from 'prop-types';

import BasicServiceInfo from './BasicServiceInfo';
import MessageList from '~/components/app/common/MessageList';

function AlignmentServiceCard(props) {
  return (
    <>
      {props.service.needsTranscript && (
        <MessageList variant="warning" messages={['Need alignment transcript']} />
      )}
      <BasicServiceInfo
        service={props.service}
        showOrderedAt
        showDeadline
        showComplete
        showLanguage
        userTimeZone={props.userTimeZone}
      />
    </>
  );
}

AlignmentServiceCard.propTypes = {
  service: PropTypes.object,
  userTimeZone: PropTypes.string,
};

export default AlignmentServiceCard;
