import React, { useContext } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { css, StyleSheet } from 'aphrodite';

import { localeText } from '../locales/locale';
import { LocaleContext } from '../locales/LocaleContext';

const BackgroundCheckTooltip = () => {
  const locale = useContext(LocaleContext);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          <small>{localeText(locale)['splash:background_tooltip']}</small>
        </Tooltip>
      }
    >
      <i className={'fa fa-info-circle ' + css(styles.backgroundCheckTooltip)}></i>
    </OverlayTrigger>
  );
};

const BackgroundCheck = () => {
  const locale = useContext(LocaleContext);

  return (
    <section className={css(styles.backgroundCheck)}>
      <p>
        <BackgroundCheckTooltip /> <strong>{localeText(locale)['splash:background_bold']}</strong>
        <br />
        {localeText(locale)['splash:background']}
      </p>
      <p>{localeText(locale)['splash:not_allowed_selling']}</p>
    </section>
  );
};

const styles = StyleSheet.create({
  backgroundCheck: {
    fontSize: '14px',
  },
  backgroundCheckTooltip: {
    cursor: 'pointer',
  },
});

export default BackgroundCheck;
