import React from 'react';

import { Form as BootstrapForm } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Button, Input } from '@threeplayground/index';
import { Form } from '@threeplayground/unstable';
import { VariantSelect } from '@threeplayground/deprecated'; // Replace this with Select
import PropTypes from 'prop-types';

export const PineappleForm = ({
  fieldErrors,
  globalErrors,
  onSubmit,
  initialPineappleData,
  disableSKU = false,
  varieties = [],
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({});

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
      globalErrors={globalErrors}
      errors={fieldErrors}
    >
      <Input
        label="SKU"
        disabled={disableSKU}
        defaultValue={initialPineappleData?.sku}
        required
        {...register('sku')}
      />
      <Input
        label="Name"
        defaultValue={initialPineappleData?.name}
        required
        {...register('name')}
      />
      <Input
        label="Weight"
        type={'number'}
        {...register('weight')}
        append={['lbs']}
        min={0}
        defaultValue={initialPineappleData?.weight}
      />
      <BootstrapForm.Row>
        <BootstrapForm.Label>Type</BootstrapForm.Label>
        <VariantSelect
          register={register}
          fieldId="varietyId"
          label="Pineapple Type"
          variants={varieties}
          showPlaceholder={true}
          placeholder="What kind of pineapple?"
          required={true}
        />
      </BootstrapForm.Row>
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
        Save
      </Button>
    </Form>
  );
};

const PineappleType = PropTypes.shape({
  name: PropTypes.string,
  sku: PropTypes.string,
  weight: PropTypes.number,
});

PineappleForm.propTypes = {
  fieldErrors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  disableSKU: PropTypes.bool,
  globalErrors: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func,
  initialPineappleData: PineappleType,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      description: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
};
