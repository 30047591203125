import React from 'react';
import { StyleSheet, css } from 'aphrodite';

interface StatusIndicatorProps {
  outputConnected: boolean;
  speechEngineConnected: boolean;
}

export function StatusIndicator({ outputConnected, speechEngineConnected }: StatusIndicatorProps) {
  const statuses = [
    { connected: outputConnected, name: 'Output' },
    ...(speechEngineConnected !== undefined
      ? [{ connected: speechEngineConnected, name: 'Speech Engine' }]
      : []),
  ];

  return (
    <ul className={css(styles.statusList)}>
      {statuses.map(({ connected, name }) => (
        <li className={css(styles.statusListItem)} key={name}>
          <div
            className={css(styles.statusIcon, connected ? styles.connected : styles.disconnected)}
          />
          <div className={css(styles.statusText)}>
            {`${name} ${connected ? 'Connected' : 'Not Connected'}`}
          </div>
        </li>
      ))}
    </ul>
  );
}

const styles = StyleSheet.create({
  connected: {
    backgroundColor: '#22aa22',
  },
  disconnected: {
    backgroundColor: '#cc3333',
  },
  statusListItem: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '1rem',
  },
  statusList: {
    display: 'flex',
    listStyle: 'none',
    padding: '0',
    margin: '0',
  },
  statusIcon: {
    display: 'inline-block',
    width: '14px',
    height: '14px',
    borderRadius: '14px',
  },
  statusText: {
    lineHeight: 0,
    marginLeft: '0.5rem',
  },
});
