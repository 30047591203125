import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import IdeaIcon from '~/components/app/order_form/images/idea.svg';
import Card from 'react-bootstrap/Card';

function WordlistTipPanel() {
  return (
    <Card className="mt-3">
      <Card.Header as="h5" className={css(styles.header)}>
        <img className="mr-2" src={IdeaIcon} alt="Idea" />
        Tips for Adding a Wordlist
      </Card.Header>
      <Card.Body>
        3Play&apos;s Live ASR service is completely automated, so make sure you follow the tips
        below to make sure your words are correctly added to our system.
        <ul>
          <li>Do not add words with 3 or fewer letters - they will be removed from the list</li>
          <li>Do not add words with special characters, except for apostrophes and periods</li>
          <li>When adding a name or short phrase, keep all words together on a single line</li>
          <li>Wordlists should be under 75 items in length</li>
        </ul>
      </Card.Body>
    </Card>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#ECF5F8',
  },
});

export default WordlistTipPanel;
