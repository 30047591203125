import React from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import CreditCardLabel from './CreditCardLabel';
import { MANAGE_PLAN_PAYMENT_START_TAB_INDEX } from '~/helpers/constants';

function PaymentAnnually(props) {
  return (
    <Container>
      <Row>
        You must pay for any future purchases or charges associated with your account by credit
        card.
      </Row>
      <Row className="h5 font-weight-bold mt-3">Select Payment Method</Row>
      {!props.hasValidCard() && !props.billingInformation.validBillingAddress && (
        <Row>You do not have any payment options in your account. Please add a credit card.</Row>
      )}
      <div>
        <Form.Group>
          {props.paymentAccounts.map((account, idx) => (
            <Form.Check type="radio" key={account.id.toString()} value={account.id}>
              <Form.Check.Input
                type="radio"
                checked={
                  props.paymentType == 'payment_account' &&
                  props.paymentAccount === parseInt(account.id)
                }
                role="radio"
                tabIndex={MANAGE_PLAN_PAYMENT_START_TAB_INDEX + idx}
                onChange={() => props.onPaymentChange('payment_account', account.id)}
              />
              <Form.Check.Label>
                <CreditCardLabel
                  card={{
                    billingContactName: account.billingContactName,
                    lastDigits: account.lastDigits,
                    name: account.name,
                  }}
                />
              </Form.Check.Label>
            </Form.Check>
          ))}
        </Form.Group>
      </div>
    </Container>
  );
}

PaymentAnnually.propTypes = {
  authenticityToken: PropTypes.string,
  fetchManagePlanData: PropTypes.func,
  hasValidCard: PropTypes.func,
  onPaymentChange: PropTypes.func,
  paymentAccount: PropTypes.number,
  paymentAccounts: PropTypes.arrayOf(PropTypes.object),
  paymentType: PropTypes.string,
  setShowInvoiceBillingForm: PropTypes.func,
  showInvoiceBillingForm: PropTypes.bool,
  billingInformation: PropTypes.object,
};

export default PaymentAnnually;
