import { Clock } from '../Clock';
import { CaptionOutputData } from '../_types';
import moment from 'moment';

/** Timing data for a word */
interface WordData {
  /** The word itself */
  word: string;
  /** An estimate of the time it took for the word to be spoken */
  length: number;
  /** An estimate of when the word started */
  start_time: number;
}

/** Class representing a caption to be sent */
export class Caption {
  /** The caption's text */
  captionText: string;
  /** The caption metadata to be sent to the connector */
  private _captionOutputData: CaptionOutputData;
  /** An array of the words in the caption with metadata baout their timing */
  private _wordsData: WordData[];
  /** An array of the words in the caption */
  public captionWordsArray: string[];
  /** The zero offset for the captions */
  private zeroOffsetDateObject: Date;

  /** Create a caption */
  constructor(
    captionText: string,
    captionOutputData: CaptionOutputData,
    zeroOffsetDateObject: Date
  ) {
    this.captionText = captionText;
    this.zeroOffsetDateObject = zeroOffsetDateObject;
    this._captionOutputData = captionOutputData;
    this._wordsData = [];
    this.captionWordsArray = [];
    this._setCaptionOutputData();
    this._setCaptionWordsArray();
    this._setWordsData();
  }

  /** Get the data for the transcript */
  get dataForTranscript() {
    return this._wordsData;
  }

  /** Get the data to be sent to Kinesis */
  get dataForKinesis() {
    return {
      length: this.getCaptionDuration() / 1000,
      start_time: this.getCaptionOffset() / 1000,
      swatei_caption_output: this._captionOutputData,
      transcript: this.captionText,
      words: this._wordsData,
    };
  }

  /** Get the last word in the caption */
  get lastWord() {
    return this.captionWordsArray[this.captionWordsArray.length - 1] || '';
  }

  private wordDuration() {
    // This makes sure that pasted caption words or caption words created via a keyboard
    // shortcut have some duration and do not get lost in a transcript word object
    // where the keys are the word's start time.
    if (this.getCaptionDuration() === 0) {
      // Makes sure that each word is at least 1 millisecond long, since we
      // record the start time of each word in milliseconds.
      return 1;
    }
    return this.getCaptionDuration() / this.captionWordsArray.length;
  }

  private getCaptionStart() {
    if (
      this._captionOutputData.captioner_typing_starts_at &&
      !this._captionOutputData.captioner_audio_starts_at
    ) {
      return moment(this._captionOutputData.captioner_typing_starts_at);
    }

    if (
      !this._captionOutputData.captioner_typing_starts_at &&
      this._captionOutputData.captioner_audio_starts_at
    ) {
      return moment(this._captionOutputData.captioner_audio_starts_at);
    }

    return moment.min([
      moment(this._captionOutputData.captioner_audio_starts_at),
      moment(this._captionOutputData.captioner_typing_starts_at),
    ]);
  }

  private getCaptionEnd() {
    if (
      this._captionOutputData.captioner_typing_ends_at &&
      !this._captionOutputData.captioner_audio_ends_at
    ) {
      return moment(this._captionOutputData.captioner_typing_ends_at);
    }

    if (
      !this._captionOutputData.captioner_typing_ends_at &&
      this._captionOutputData.captioner_audio_ends_at
    ) {
      return moment(this._captionOutputData.captioner_audio_ends_at);
    }

    return moment.max([
      moment(this._captionOutputData.captioner_audio_ends_at),
      moment(this._captionOutputData.captioner_typing_ends_at),
    ]);
  }

  private getCaptionDuration() {
    return this.getCaptionEnd().diff(this.getCaptionStart());
  }

  private getCaptionOffset() {
    return moment(this.getCaptionStart()).diff(this.zeroOffsetDateObject);
  }

  _setCaptionOutputData() {
    this._captionOutputData.text_sent_from_swatei = this.captionText;
    this._captionOutputData.sent_from_swatei_at = new Date(new Clock().now()).toISOString();
  }

  // This came from swatei/utils/formatCaptionsUtil getCaptionsToSubmit
  _setCaptionWordsArray() {
    let captionWordsArray = [];

    if (this.captionText.match(/\[(.*?)\]/)) {
      captionWordsArray = this.captionText.replace(/\[/g, ' [').replace(/\]/g, '] ').split(' ');
    } else {
      captionWordsArray = this.captionText.split(' ');
    }
    // filter out empty strings
    this.captionWordsArray = captionWordsArray.filter((e) => e);
  }

  _setWordsData() {
    this._wordsData = this.captionWordsArray.map((word, index) => ({
      word: word,
      length: this.wordDuration() / 1000,
      start_time: (this.getCaptionOffset() + this.wordDuration() * index) / 1000,
    }));
  }
}
