import React from 'react';

import { OverlayTrigger, Popover, PopoverContent, PopoverTitle } from 'react-bootstrap';

import { translationProfilesPath } from '~/helpers/app/paths';

const TranslationProfileInfoPopover = () => {
  const infoPopover = () => {
    return (
      <Popover>
        <PopoverTitle>About Translation Profiles</PopoverTitle>
        <PopoverContent>
          <p>
            Selecting the correct translation profile will help your translator better understand
            the context in which you are communicating to your audience.
          </p>
          <a href={translationProfilesPath} rel="noreferrer noopener" target="_blank">
            Manage Translation Profiles
          </a>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <OverlayTrigger
      placement="right"
      trigger={['hover', 'focus']}
      delay={{ hide: 2000 }}
      overlay={infoPopover()}
    >
      <i className="fa fa-info-circle"></i>
    </OverlayTrigger>
  );
};

export default TranslationProfileInfoPopover;
