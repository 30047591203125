import React from 'react';
import Card from 'react-bootstrap/Card';
import TipPanelContainer from "~/components/app/order_form/tipPanels/TipPanelContainer";

function AlignmentTipPanel() {
  return (
    <TipPanelContainer title="Tips for Submitting Transcripts for Alignment">
      <Card.Body>
        The Alignment service is completely automated, so make sure you follow the tips below to
        ensure your files are successfully processed.
        <ul>
          <li>Speaker IDs can be added before each line (eg. BILL JOHNSON)</li>
          <li>All hard line breaks are interpreted as paragraphs</li>
          <li>
            Text in the transcript should correspond with the words spoken in the video/audio file.
          </li>
          <li>Make sure your transcript is in the same language as the video/audio.</li>
          <li>Make sure your transcript only contains valid characters.</li>
          <li>
            More Alignment best practices can be found on this&nbsp;
            <a href="https://support.3playmedia.com/hc/en-us/articles/227730048-Alignment-Best-Practices">
              support article
            </a>
          </li>
        </ul>
      </Card.Body>
    </TipPanelContainer>
  );
}

export default AlignmentTipPanel;
