import React, { MouseEventHandler, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Alert, Button } from '@threeplayground/index';
import {
  TransitionsEnum,
  useJobInputsReadyQuery,
  useTransitionJobStateMutation,
} from '~/api/jobsApi';
import { InputsNotReady } from './InputsNotReady';
import { JobLoadError } from './JobLoadError';

export interface JobLayoutProps {
  children: React.ReactNode;
  jobId: string;
  jobTypeName: string;
}

export const OffPlatformJobLayout = ({ children, jobId, jobTypeName }: JobLayoutProps) => {
  const [errors, setErrors] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data,
    isLoading: queryLoading,
    isError: queryError,
  } = useJobInputsReadyQuery({ where: { idEq: jobId } });

  const { mutateAsync } = useTransitionJobStateMutation();

  if (queryLoading) return null;
  if (queryError || data.job == null) return <JobLoadError />;

  const { inputsReady } = data.job;

  const finishJob = async () => {
    const response = await mutateAsync({
      where: { idEq: jobId },
      transition: TransitionsEnum.Finish,
    });

    if (!response || response.transitionJobState?.errors) {
      setErrors(true);
      setIsLoading(false);
      return;
    }
    if (response.transitionJobState?.data) {
      setSubmitted(true);
      setIsLoading(false);
      return;
    }
  };

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (confirm('Are you sure you want to finalize the job? ')) {
      setIsLoading(true);
      void finishJob();
    }
  };

  return (
    <Container className="py-4">
      {errors && <Alert variant="danger">Error! Failed to finalize job</Alert>}
      {submitted && <Alert variant="success">Success! Finalized job</Alert>}
      <Row>
        <Col>
          <div className="float-right">
            <Button onClick={handleSubmit} variant="primary" disabled={isLoading || !inputsReady}>
              Submit Job
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>{`${jobTypeName} - ${jobId}`}</h1>
        </Col>
      </Row>
      {inputsReady ? children : <InputsNotReady />}
    </Container>
  );
};
