import { PunctuationFormatter, PunctuationFormatterConfig } from '../_types';

export class EndOfSentencePunctuationFormatter implements PunctuationFormatter {
  command: PunctuationFormatterConfig['command'];
  punctuation: PunctuationFormatterConfig['punctuation'];

  /**
   * A formatter that adds the punctuation and capitalizes the next letter
   *
   * @example new EndOfSentencePunctuationFormatter({ command: 'peermak', punctuation: '.' }).format("hello peermak welcome")
   * // returns "hello. Welcome"
   */
  constructor({ command, punctuation }: PunctuationFormatterConfig) {
    this.command = command;
    this.punctuation = punctuation;
  }

  format(text: string) {
    return text.replace(
      this.regex(),
      (match, p1: string) => `${this.punctuation}${p1 ? p1.toUpperCase() : ''}`
    );
  }

  regex() {
    return new RegExp(`\\s*\\b${this.command}\\b(\\s+[a-z])?`, 'gi');
  }
}
