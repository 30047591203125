export function highlightFileSelection(fileId) {
  window.highlightFileSelection(fileId);
}

export function unhighlightAllFileSelection() {
  window.unhighlightAllFileSelection();
}

export function toggleSidebar() {
  window.sidebarToggle();
}
