import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';

import BasicServiceInfo from './BasicServiceInfo';
import TurnaroundLevelModal from '~/components/app/media_files/modals/TurnaroundLevelModal';

function TranscriptionServiceCard(props) {
  const [showTurnaroundLevelModal, setShowTurnaroundLevelModal] = useState(false);

  function handleAddTranscriptionCheatsheet() {
    props.setShowModal.cheatSheet(true, 'TranscriptionService');
  }

  function handleUpgradeTurnaroundLevel() {
    setShowTurnaroundLevelModal(true);
  }

  return (
    <>
      {props.service.cancellable && (
        <div className="d-flex flex-row justify-content-start">
          <div
            className={css(styles.serviceAction)}
            key="add-transcription-cheatsheet"
            onClick={handleAddTranscriptionCheatsheet}
          >
            Add Order Instructions
          </div>

          {props.service.upgradableTurnaroundLevels?.length > 0 && (
            <div
              className={css(styles.serviceAction)}
              key="upgrade-turnaround"
              onClick={handleUpgradeTurnaroundLevel}
            >
              Upgrade Turnaround
            </div>
          )}
        </div>
      )}
      <BasicServiceInfo
        service={props.service}
        showOrderedAt
        showDeadline={props.service.deadline && Number(props.service.deadline) > 0 ? true : false}
        showComplete
        showTurnaround={props.service.turnaroundLevel ? true : false}
        showLanguage
        userTimeZone={props.userTimeZone}
      />

      {props.service.upgradableTurnaroundLevels?.length > 0 && (
        <TurnaroundLevelModal
          show={showTurnaroundLevelModal}
          fileId={props.fileData.id}
          setShowTurnaroundLevelModal={setShowTurnaroundLevelModal}
          turnaroundLevels={props.service.upgradableTurnaroundLevels}
          currentTurnaroundLevel={props.service.currentTurnaroundLevel}
          service={props.service}
        />
      )}
    </>
  );
}

TranscriptionServiceCard.propTypes = {
  fileData: PropTypes.shape({
    id: PropTypes.string,
    reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
  }),
  service: PropTypes.object,
  setShowModal: PropTypes.shape({
    cheatSheet: PropTypes.func,
  }),
  userTimeZone: PropTypes.string,
};

const styles = StyleSheet.create({
  serviceAction: {
    color: '#007EB5',
    cursor: 'pointer',
    fontWeight: 'bold',
    textAlign: 'right',
    marginRight: '3em',
  },
});

export default TranscriptionServiceCard;
