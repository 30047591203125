type This = typeof globalThis;
interface PageGlobals extends This {
  setCalendarSidebarVisibility: (status: 'open' | 'closed') => void;
}

import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, View } from 'react-big-calendar';

import {
  AnyTimeSlot,
  BookedLiveEventTimeSlot,
  CaptionerShiftCalendarEntry,
  CaptionerShiftTimeSlot,
  LiveEventCalendarEntry,
  NewBookingTimeSlot,
  isCaptionerShiftTimeSlot,
  isNewBookingCalendarEntry,
} from './shared/types';

import { eventStyleGetter, getLocalizer, onRangeChange } from './shared/calendar';
import { convertBookings, convertCaptionerTimeShiftSlots } from './shared/convert-data';
import { toIsoString } from './shared/dates';

interface JobsContractorAvailabilityAppProps {
  bookables: CaptionerShiftTimeSlot[];
  bookings: BookedLiveEventTimeSlot[];
  date: string;
  userTimeZone: string;
  view: View;
}

function JobsContractorAvailabilityApp(props: JobsContractorAvailabilityAppProps) {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<AnyTimeSlot | undefined>(undefined);
  const [filteredBookables, setFilteredBookables] = useState<CaptionerShiftCalendarEntry[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<LiveEventCalendarEntry[]>([]);

  // Listen to the calendar details close event from _outside_ this React component
  useEffect(() => {
    document.addEventListener('closeCalendarDetails', clearActiveTimeSlot);
  }, []);

  const setActiveTimeSlot = (slot: AnyTimeSlot) => {
    (global as unknown as PageGlobals).setCalendarSidebarVisibility('open');
    setSelectedTimeSlot(slot);
  };

  const clearActiveTimeSlot = () => {
    (global as unknown as PageGlobals).setCalendarSidebarVisibility('closed');
    setSelectedTimeSlot(undefined);
  };

  const onClickTimeSlot = (slot: AnyTimeSlot) => {
    let location;
    // All timeslots with an ID, regarless of type go to the same route
    if (isCaptionerShiftTimeSlot(slot)) {
      location = `/time_slots/${slot.id}/edit`;
    } else if (isNewBookingCalendarEntry(slot)) {
      // When trying to book a new slot, sent to the Rails /new route
      location = `/time_slots/new?time_slot[starts_at]=${slot.starts_at}&time_slot[ends_at]=${slot.ends_at}`;
    } else {
      throw new Error('onClickTimeSlot: Unknown time_slot_type');
    }

    Turbo.visit(location, { frame: 'partial_container' });
    setActiveTimeSlot(slot);
  };

  const newTimeSlot = () => {
    onClickTimeSlot({
      starts_at: '',
      ends_at: '',
      time_slot_type: 'new_booking',
    });
  };

  const handleSelectSlot = useCallback(({ start, end }: { start: Date; end: Date }) => {
    const newBookingSlot: NewBookingTimeSlot = {
      starts_at: toIsoString(start),
      ends_at: toIsoString(end),
      time_slot_type: 'new_booking',
    };

    onClickTimeSlot(newBookingSlot);
  }, []);

  // Set the captioner blocks
  useEffect(() => {
    const bookableEvents = convertCaptionerTimeShiftSlots(props.bookables, {
      title: 'Available',
    });
    setFilteredBookables(bookableEvents);
  }, [props.bookables]);

  // Decide which bookings (live events) to show based on the selected time slot
  useEffect(() => {
    const bookings = convertBookings(props.bookings);

    setFilteredBookings(bookings);
  }, [props.bookings, selectedTimeSlot]);

  return (
    <div className="d-flex bd-highlight">
      <div className="p-2 flex-grow-1">
        <Calendar
          /* eslint-disable @typescript-eslint/no-unsafe-assignment */
          localizer={getLocalizer(props.userTimeZone)}
          dayLayoutAlgorithm="no-overlap"
          defaultDate={props.date}
          defaultView={props.view}
          events={[...filteredBookings, ...filteredBookables]}
          showMultiDayTimes
          eventPropGetter={eventStyleGetter}
          selectable
          step={30}
          style={{ height: 1200 }}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={(e: { object: AnyTimeSlot }) => onClickTimeSlot(e.object)}
          onRangeChange={onRangeChange}
          popup
        />
      </div>
      <div
        className="p-2 bd-highlight"
        style={{ display: typeof selectedTimeSlot === 'undefined' ? 'block' : 'none' }}
      >
        <button type="button" className="btn btn-primary btn-lg" onClick={newTimeSlot}>
          <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
  );
}

export default JobsContractorAvailabilityApp;
