const removeWordlistMutation = `
mutation RemoveWordlistMutation($wordlistAssignmentId: ID!) {
  removeWordlist(wordlistAssignmentId: $wordlistAssignmentId) {
    errors,
  }
}`;

const saveWordlistMutation = `
  mutation SaveWordlistMutation($wordlistAssignment: WordlistAssignmentInput!) {
    saveWordlist(wordlistAssignment: $wordlistAssignment) {
      invalidWords,
      errors,
  }
}`;

export { removeWordlistMutation, saveWordlistMutation };
