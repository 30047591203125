import React from 'react';
import PropTypes from 'prop-types';

import reactPaginateModule from 'react-paginate';

// This is gross, but necessary for vitest currently.
// It appears that vitest is not importing as expected
// with a esModule export missing.
//
// TODO: It would be good to figure out if vite can do this.
const ReactPaginate = reactPaginateModule.default
  ? reactPaginateModule.default
  : reactPaginateModule;

// React Paginate has 0 based indexes, whereas the paginate gem in rails is 1 based index
// Hence the get is subtracted by 1 and set is added by 1
function Paginator(props) {
  return (
    <ReactPaginate
      previousLabel={'< Previous'}
      nextLabel={'Next >'}
      breakLabel={'...'}
      pageClassName={'page-item'}
      pageCount={props.totalPages}
      initialPage={props.paginationParams.currentPage - 1}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={(event) => props.setPageNumber(Number(event.selected) + 1)}
      pageLinkClassName={'page-link'}
      breakLinkClassName={'page-link'}
      previousLinkClassName={'page-link'}
      nextLinkClassName={'page-link'}
      containerClassName={'pagination m-0'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
      disabledClassName={'disabled'}
      disableInitialCallback={true}
    />
  );
}

Paginator.propTypes = {
  paginationParams: PropTypes.object,
  setPageNumber: PropTypes.func,
  totalPages: PropTypes.number,
};

export default Paginator;
