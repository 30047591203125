import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import InputErrorList from '~/components/app/common/InputErrorList';

import { threeplayApi } from '~/logic/ThreeplayApi';

const framerates = [
  { framerate: 29.97, dropframe: true },
  { framerate: 29.97, dropframe: false },
  { framerate: 59.94, dropframe: true },
  { framerate: 59.94, dropframe: false },
  { framerate: 25.0 },
  { framerate: 24.0 },
  { framerate: 23.98 },
  { framerate: 23.976 },
];

function framerateOptions(file) {
  if (framerates.some((fps) => fps.framerate === file.framerate)) {
    return framerates;
  } else {
    const fileValues = [{ framerate: file.framerate, dropframe: file.dropframe }];
    return fileValues.concat(framerates);
  }
}

function framerateOption({ framerate, dropframe }) {
  if (framerate === undefined) {
    return {
      value: '',
      name: '',
    };
  } else if ([29.97, 59.94].includes(framerate)) {
    return {
      value: String(framerate) + (dropframe ? 'df' : ''),
      name: String(framerate) + (dropframe ? ' Drop Frame' : ' Non-Drop Frame'),
    };
  } else {
    return {
      value: String(framerate),
      name: String(framerate),
    };
  }
}

function FileInformation(props) {
  const { data = {}, errors } = props.fileDetails;
  const [vpis, setVpis] = useState([]);
  const file = data.file || {};
  const cheatSheet = file.cheatSheet || {};
  const integration = file.mediaFileIntegration || null;

  useEffect(() => {
    getProjectIntegrations();
  }, []);

  function handleChange(event) {
    props.onChange({ [event.target.name]: event.target.value });
  }

  async function getProjectIntegrations() {
    const response = await threeplayApi.request(linkedAccountsQuery);
    const linkedAccounts = response.data.project.linkedAccounts;
    const vpis = linkedAccounts.map((linkedAccount) => {
      return linkedAccount.integrations.map((integration) => ({
        id: integration.id,
        name: integration.displayName,
        platformName: integration.platformName,
      }));
    });
    setVpis(vpis.flat());
  }

  const linkedAccountsQuery = `query LinkedAccounts{
    project {
      linkedAccounts{
        integrations {
          id,
          displayName,
          platformName,
        }
      }
    }
  }`;

  function integrationRow(integration) {
    if (integration) {
      return (
        <td>
          {integration.name}
          <Button
            className={css(styles.btnSmall, styles.right)}
            variant="danger"
            onClick={unlinkIntegration}
          >
            Unlink this file
          </Button>
        </td>
      );
    }

    if (vpis.length > 0) {
      return (
        <td>
          <Button
            className={css(styles.btnSmall)}
            variant="success"
            onClick={() => props.setShowModal.linkIntegration(true, vpis, file.videoId)}
          >
            Connect to Linked Account
          </Button>
        </td>
      );
    }

    // Project has no VPIs
    if (vpis.length === 0) {
      return <td>N/A</td>;
    }
  }

  async function unlinkIntegration() {
    const fileId = file.id;
    const integrationId = integration.videoPlatformIntegrationId;

    const response = await threeplayApi.mutate(unlinkIntegrationQuery(fileId, integrationId));

    // Server error
    if (response.error) {
      props.setErrorsAlert(response.data.error);
    }

    // Data error
    if (response.data.unlinkIntegration.error) {
      props.setErrorsAlert(response.data.unlinkIntegration.errors);
    }
    // Success
    else {
      props.setSuccess(response.data.unlinkIntegration.success);
      props.onUpdate();
    }
  }

  function unlinkIntegrationQuery(fileId, integrationId) {
    return `unlinkIntegration(
        fileId: ${fileId}, integrationId: ${integrationId}
      ){ success, error }`;
  }

  if (errors) {
    return <p>There was an error loading your file information.</p>;
  }

  return (
    <Table>
      <tbody>
        <tr>
          <td>Description</td>
          <td>
            {props.editing ? (
              <textarea
                name="description"
                value={
                  props.edits.hasOwnProperty('description')
                    ? props.edits.description
                    : file.description
                }
                onChange={handleChange}
              />
            ) : (
              file.description
            )}
          </td>
        </tr>
        <tr>
          <td>Order Instructions</td>
          <td>{cheatSheet.content || '-'}</td>
        </tr>
        <tr>
          <td>Order Instructions PDF</td>
          <td>{cheatSheet.pdf && <a href={cheatSheet.pdf}>PDF</a>}</td>
        </tr>
        <tr>
          <td>Linked Account</td>
          {integrationRow(integration)}
        </tr>
        <tr>
          <td>Video ID</td>
          <td>
            {props.editing ? (
              <input
                name="videoId"
                value={props.edits.hasOwnProperty('videoId') ? props.edits.videoId : file.videoId}
                onChange={handleChange}
              />
            ) : (
              file.videoId
            )}
          </td>
        </tr>
        <tr>
          <td>Attribute 1</td>
          <td>
            {props.editing ? (
              <input
                name="attribute1"
                value={
                  props.edits.hasOwnProperty('attribute1')
                    ? props.edits.attribute1
                    : file.attribute1
                }
                onChange={handleChange}
              />
            ) : (
              file.attribute1
            )}
          </td>
        </tr>
        <tr>
          <td>Attribute 2</td>
          <td>
            {props.editing ? (
              <input
                name="attribute2"
                value={
                  props.edits.hasOwnProperty('attribute2')
                    ? props.edits.attribute2
                    : file.attribute2
                }
                onChange={handleChange}
              />
            ) : (
              file.attribute2
            )}
          </td>
        </tr>
        <tr>
          <td>Attribute 3</td>
          <td>
            {props.editing ? (
              <input
                name="attribute3"
                value={
                  props.edits.hasOwnProperty('attribute3')
                    ? props.edits.attribute3
                    : file.attribute3
                }
                onChange={handleChange}
              />
            ) : (
              file.attribute3
            )}
          </td>
        </tr>
        <tr>
          <td>Starting Timecode</td>
          <td>
            {props.editing ? (
              <input
                name="startingTimecode"
                value={
                  props.edits.hasOwnProperty('startingTimecode')
                    ? props.edits.startingTimecode
                    : file.startingTimecode
                }
                onChange={handleChange}
              />
            ) : (
              file.startingTimecode
            )}
            {props.editing && props.errors.startingTimecode && (
              <InputErrorList errors={props.errors.startingTimecode} />
            )}
          </td>
        </tr>
        <tr>
          <td>Frame Rate Settings</td>
          <td>
            {props.editing ? (
              <Form.Control
                name="framerate"
                as="select"
                value={
                  props.edits.hasOwnProperty('framerate')
                    ? props.edits.framerate
                    : framerateOption(file).value
                }
                onChange={handleChange}
              >
                {framerateOptions(file).map((item) => (
                  <option key={framerateOption(item).value} value={framerateOption(item).value}>
                    {framerateOption(item).name}
                  </option>
                ))}
              </Form.Control>
            ) : (
              framerateOption(file).name
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

const styles = StyleSheet.create({
  btnSmall: {
    padding: '.25rem .4rem',
    fontSize: '.875rem',
    lineHeight: '.75',
    borderRadius: '.2rem',
    fontWeight: 500,
  },
  right: {
    float: 'right',
  },
});

FileInformation.propTypes = {
  editing: PropTypes.bool,
  edits: PropTypes.shape({
    description: PropTypes.string,
    attribute1: PropTypes.string,
    attribute2: PropTypes.string,
    attribute3: PropTypes.string,
    startingTimecode: PropTypes.string,
    framerate: PropTypes.string,
    videoId: PropTypes.string,
  }),
  errors: PropTypes.shape({
    startingTimecode: PropTypes.arrayOf(String),
  }),
  fileDetails: PropTypes.shape({
    data: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.object),
  }),
  onChange: PropTypes.func.isRequired,
  setErrorsAlert: PropTypes.func,
  setSuccess: PropTypes.func,
  onUpdate: PropTypes.func,
  setShowModal: PropTypes.shape({
    linkIntegration: PropTypes.func,
  }),
};

export default FileInformation;
