import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ViccRating from './ViccRating';
import ViccTimeline from './ViccTimeline';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';

import { mediaFilePath } from '~/helpers/app/paths';

function VideoClipCaptioningServiceChildrenCard(props) {
  const service = props.service || {};
  const parentFile = service.parentFile || {};
  const clipMap = service.clipMap || {};
  const matchedRegions = clipMap.matchedRegions || [];

  return (
    <>
      <Row>
        <Col xs={6}>
          <b>Ordered:</b>{' '}
          {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
        </Col>
        <Col xs={6}>
          <b>Rating: </b>
          {clipMap?.score && (
            <span>
              <ViccRating score={clipMap.score} />
            </span>
          )}
        </Col>
        <Col xs={6}>
          <div className={css(styles.timelineContainer)}>
            <b>Clip Timeline: </b>
            <div className={css(styles.timeline)}>
              {matchedRegions && (
                <ViccTimeline
                  matchedRegions={matchedRegions}
                  parentDuration={parentFile.duration}
                />
              )}
            </div>
          </div>
        </Col>
        <Col xs={6}>
          <div className={css(styles.parent)}>
            <b>Parent File: </b>
            {parentFile && <a href={mediaFilePath(parentFile.id)}>{parentFile.name}</a>}
          </div>
        </Col>
        {props.service.status === 'complete' && (
          <Col xs={6}>
            <b>Completed:</b>{' '}
            {moment(parseInt(service.completedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
          </Col>
        )}
      </Row>
    </>
  );
}

VideoClipCaptioningServiceChildrenCard.propTypes = {
  service: PropTypes.object,
  userTimeZone: PropTypes.string,
};

const styles = StyleSheet.create({
  parent: {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
  },
  timeline: {
    flex: '1 0 0',
    paddingLeft: '3px',
  },
  timelineContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
});

export default VideoClipCaptioningServiceChildrenCard;
