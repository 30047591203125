import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import IdeaIcon from '~/components/app/order_form/images/idea.svg';
import Card from 'react-bootstrap/Card';

function TipPanel(props) {
  return (
    <Card>
      <Card.Header className={css(styles.header)}>
        <img className="mr-2" src={IdeaIcon} alt="Idea" />
        <strong>{props.header}</strong>
      </Card.Header>
      <Card.Body>{props.children}</Card.Body>
    </Card>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#ECF5F8',
  },
});

TipPanel.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
};

export default TipPanel;
