import React from 'react';
import { Container, Row, Image } from 'react-bootstrap';

export const JobLoadError = () => (
  <Container className="py-4">
    <Row className="justify-content-center">
      Failed to load job. Please contact operations@3playmedia.com
    </Row>
    <Row className="justify-content-center">
      <Image src="/images/maintenance_images/constructionbo.png" width="300px" alt="" />
    </Row>
  </Container>
);
