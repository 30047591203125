import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT_WITH_SECONDS } from '../common/constants';
import { threeplayApi } from '../../../../logic/ThreeplayApi';
import { statusForLiveEventQuery } from '../data/queries';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  errorStateColor: {
    color: '#D60000',
  },
  successStateColor: {
    color: '#067A00',
  },
  warning: {
    color: '#976C00',
  },
});

function getStatusTextColor(status) {
  if (status === 'error') {
    return styles.errorStateColor;
  } else if (status === 'success') {
    return styles.successStateColor;
  } else if (status === 'warning') {
    return styles.warning;
  }
}

export default function LiveStatusCard({
  streamStatusDescription = '',
  streamStatus = '',
  captionStatusDescription = '',
  captionStatus = '',
  liveEventId,
}) {
  const [lastUpdated, setLastUpdated] = useState(
    moment(new Date()).format(DATE_TIME_FORMAT_WITH_SECONDS)
  );
  const [streamStatusDescriptionState, setStreamStatusDescription] = useState({
    text: streamStatusDescription,
    type: streamStatus,
  });
  const [captionStatusDescriptionState, setCaptionStatusDescription] = useState({
    text: captionStatusDescription,
    type: captionStatus,
  });

  const onRefreshHandler = () => {
    threeplayApi.request(statusForLiveEventQuery, { liveEventId }).then((parsedJson) => {
      const { captionStatusDescription, streamStatusDescription } =
        parsedJson?.data?.project?.liveEventDetails || {};

      if (streamStatusDescription) {
        setStreamStatusDescription(streamStatusDescription);
      }

      if (captionStatusDescription) {
        setCaptionStatusDescription(captionStatusDescription);
      }

      setLastUpdated(moment(new Date()).format(DATE_TIME_FORMAT_WITH_SECONDS));
      setTimeout(onRefreshHandler, 15000);
    });
  };

  useEffect(() => {
    onRefreshHandler();
  }, []);

  return (
    <>
      <Container className="m-0 p-0">
        <Row className="m-0">
          <b>Status</b>
          <p className="ml-1 mb-0">{`(last updated at ${lastUpdated})`}</p>
        </Row>
        <Row className="m-0 pt-2">
          <b>Stream Status:</b>
          <p className={`${css(getStatusTextColor(streamStatusDescriptionState.type))} ml-1 mb-0`}>
            {streamStatusDescriptionState?.text}
          </p>
        </Row>
        {!!captionStatus && (
          <Row className="m-0">
            <b>Caption Status:</b>
            <p
              className={`${css(getStatusTextColor(captionStatusDescriptionState.type))} ml-1 mb-0`}
            >
              {captionStatusDescriptionState?.text}
            </p>
          </Row>
        )}
      </Container>
      <hr></hr>
    </>
  );
}

LiveStatusCard.propTypes = {
  liveEventId: PropTypes.number,
  streamStatus: PropTypes.string,
  streamStatusDescription: PropTypes.string,
  captionStatus: PropTypes.string,
  captionStatusDescription: PropTypes.string,
};
