import { graphql_endpoint } from '~/helpers/app/paths';

class ThreeplayApi {
  constructor({ endpoint, params }) {
    this.endpoint = endpoint;
    this.params = params;
  }

  request(query, variables) {
    const sendVars = typeof variables === 'string' ? variables : JSON.stringify(variables);
    let url = this.endpoint;
    if (this.params !== undefined) {
      url += `?${this.params}`;
    }
    return window['$'].post(url, { query, variables: sendVars });
  }

  query(querystring, queryname = '') {
    return this.request(`query ${queryname}{ ${querystring} }`);
  }

  mutate(querystring, mutationname = '') {
    return this.request(`mutation ${mutationname}{ ${querystring} }`);
  }
}

export const threeplayApi = new ThreeplayApi({ endpoint: graphql_endpoint });

export function threeplayApiExternal(params) {
  const threeplayApiExternal = new ThreeplayApi({ endpoint: graphql_endpoint, params: params });
  return threeplayApiExternal;
}
