import React from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReactModule from 'highcharts-react-official';

import moment from 'moment-timezone';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// Highcharts is not properly exported as an esmodule
const HighchartsReact = HighchartsReactModule.default
  ? HighchartsReactModule.default
  : HighchartsReactModule;

function CaptionPercentageModal(props) {
  let totalVideos = 0;
  let captionedVideos = 0;
  let totalDuration = 0;
  let captionedDuration = 0;
  const monthlyData = {};
  const latest3MonthsData = {};

  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: 200,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '<b>{point.y}</b><br/>',
      valueSuffix: ' Videos',
      shared: true,
    },
    series: [
      {
        name: 'Videos',
        colorByPoint: true,
        data: [],
      },
    ],
  };

  function calculateChartOptions(month, data) {
    const template = JSON.parse(JSON.stringify(chartOptions));
    const monthName = moment(month).utc().format("MMM 'YY");

    template['title']['text'] = monthName;
    template['series'][0]['data'] = [];
    template['series'][0]['data'].push({ name: 'Captioned', y: data.totalCaptioned });
    template['series'][0]['data'].push({
      name: 'Uncaptioned',
      y: data.totalVideos - data.totalCaptioned,
    });
    return template;
  }

  function calculateTotals(integration) {
    if (integration) {
      integration.vpiMonthlyReports.forEach((vmr) => {
        if (!vmr) {
          return;
        }
        monthlyData[vmr.month] = {
          totalVideos: vmr.videoCount,
          totalCaptioned: vmr.captionedCount,
        };

        totalVideos += vmr.videoCount || 0;
        captionedVideos += vmr.captionedCount || 0;
        totalDuration += parseInt(vmr.videoDuration) || 0;
        captionedDuration += parseInt(vmr.captionedDuration) || 0;
      });

      const latest3Months = Object.keys(monthlyData).slice(-3);

      latest3Months.forEach(function (month) {
        latest3MonthsData[month] = monthlyData[month];
      });
    }
  }

  function closeModal() {
    props.setShowCaptionPercentageModal(false);
  }

  function formatDuration(durationInSeconds) {
    const hours = Math.floor(durationInSeconds / 3600);
    durationInSeconds %= 3600;
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return hours + 'h : ' + minutes + 'm : ' + seconds.toFixed(2) + 's';
  }

  calculateTotals(props.currentIntegration);

  return (
    <Modal show={props.show} onHide={() => closeModal('X')} dialogClassName={'modal-width-small'}>
      <Modal.Header closeButton>
        <Modal.Title>
          VPI Stats for {props.currentIntegration && props.currentIntegration.displayName}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="col">
          <div className="row">
            <div className="col">Total Videos: {totalVideos}</div>
            <div className="col">{formatDuration(totalDuration / 1000)}</div>
          </div>
          <div className="row">
            <div className="col">Captioned Videos: {captionedVideos}</div>
            <div className="col">{formatDuration(captionedDuration / 1000)}</div>
          </div>
          {Object.keys(latest3MonthsData).length > 0 && (
            <>
              <div className="row">
                <h5 className="col text-center mt-2">Most Recent Months</h5>
              </div>
              <div className="row" id="chart">
                {Object.keys(latest3MonthsData).map((key) => (
                  <div className="col" key={key} data-testid="highcharts">
                    <HighchartsReact
                      key={key}
                      highcharts={Highcharts}
                      options={calculateChartOptions(key, latest3MonthsData[key])}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="mr-auto" onClick={() => closeModal('Close Button')}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CaptionPercentageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowCaptionPercentageModal: PropTypes.func.isRequired,
  currentIntegration: PropTypes.object,
};

export default CaptionPercentageModal;
