import React, { ReactNode } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';

// Note: Must be nested under a Threeplayground Themeprovider for styles to be set correctly
import './Button.css';

// Map named 3play sizes to underlying control size names.
const SIZEMAP = {
  small: 'sm',
  medium: undefined, // bootstrap doesn't have a named variant for medium, must be undefined to map correctly
  large: 'lg',
} as const;

export interface ButtonProps {
  children: ReactNode;
  id?: string;
  variant?: 'primary' | 'secondary' | 'danger' | 'outline-primary' | 'outline-secondary';
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const Button = ({
  id,
  variant = 'primary',
  children,
  onClick,
  size = 'medium',
  type = 'button',
  disabled = false,
}: ButtonProps) => (
  <BootstrapButton
    id={id}
    variant={variant}
    type={type}
    className={`.btn .btn-${variant}`}
    onClick={onClick}
    size={SIZEMAP[size]}
    disabled={disabled}
  >
    {children}
  </BootstrapButton>
);
