import React from 'react';
import PropTypes from 'prop-types';

import PlanOverviewAlert from './PlanOverviewAlert';
import WithIcon from '~/components/app/common/WithIcon';
import { formatDate } from './utils/AccountPlanUtils';

export const DEFAULT_HEADER = 'Important Note About Your Account Status';

export const downgradeExpressCopy = (
  createdAt
) => `On ${createdAt}, your account was automatically downgraded to the Express Plan because we did not receive an opt-in to our 
  Pro subscription from you. Want to upgrade to Pro to access our full suite of features and technical support? Confirm your plan today.`;

function PlanOverviewNote(props) {
  const isDowngradedToExpress = () =>
    props.account_type === 'express' &&
    ['sunset', 'pro'].includes(props.account_type_history?.previous_type);

  // we only want the express alert to be blue
  const alertVariant = () => (props.account_type === 'express' ? 'primary' : 'warning');

  const headerText = () => (isDowngradedToExpress() ? 'Upgrade to Pro Today!' : DEFAULT_HEADER);

  function buttonText() {
    switch (props.account_type) {
      case 'sunset':
        return 'Confirm Plan';
      case 'express':
        return <WithIcon icon="fa fa-arrow-up">Upgrade</WithIcon>;
      case 'pro':
      case 'transcription_only':
      case 'enterprise':
      default:
        return '';
    }
  }

  function noteText() {
    if (props.account_type === 'pro') {
      const termEnd = formatDate(props.account_plan?.term_end);
      return props.account_plan.auto_renew
        ? `Your Pro account subscription term will automatically renew on ${termEnd}.`
        : `Your Pro account subscription term will expire on ${termEnd}.`;
    }

    if (props.account_type === 'express') {
      return isDowngradedToExpress()
        ? downgradeExpressCopy(formatDate(props.account_type_history.created_at))
        : `Want to receive access to our full suite of features and technical support? Confirm your plan today.`;
    }

    if (props.account_type === 'sunset') {
      return `We are making some changes to our account structure to better support the needs of our customers. 
        Confirm your plan now and take action to avoid losing access to specific features and services.`;
    }
  }

  return !props.account_type ? (
    <></>
  ) : (
    <PlanOverviewAlert
      alertVariant={alertVariant()}
      header={headerText()}
      bodyText={noteText()}
      buttonText={buttonText()}
      onClick={props.openManagePlanModal}
    />
  );
}

PlanOverviewNote.propTypes = {
  account_plan: PropTypes.object,
  account_type: PropTypes.string,
  account_type_history: PropTypes.object,
  openManagePlanModal: PropTypes.func,
};

export default PlanOverviewNote;
