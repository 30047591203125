import { Table } from '@threeplayground/index';
import { use3PQuery } from '~/logic/unstable/use3PQuery';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { opsTasksPaths } from './opsTasksPaths';

export const OpsTasksTable = ({ query, where }) => {
  const [limit] = useState(20);
  const [offset, setOffset] = useState(0);

  const handleNextPage = () => {
    setOffset((prevOffset) => prevOffset + limit);
  };
  const handlePrevPage = () => {
    setOffset((prevOffset) => Math.min(Math.abs(prevOffset - limit)), 0);
  };

  const { data, isLoading, globalError } = use3PQuery(query, {
    variables: { limit, where, offset },
    keepPreviousData: true,
    extractKey: 'opsTasks',
  });

  const tableData = useMemo(() => data && data.edges.map((el) => el.node), [data]);

  //TODO: Add Link to enqueue page
  const TABLE_COLUMNS = [
    { header: 'Name', accessor: 'displayName', cell: 'string' },
    { header: 'Description', accessor: 'description', cell: 'string' },
    { header: 'Typical run time	', accessor: 'typicalRunTimeInMinutes', cell: 'number' },
    { header: 'Updated', accessor: 'updatedAt', cell: 'date' },
    { header: 'Notes', accessor: 'notes', cell: 'string' },
    {
      header: '',
      accessor: 'enqueueLink',
      cell: 'link',
      cellProps: (rowData) => ({
        href: opsTasksPaths.show.getLink(rowData.id),
        linkText: 'Enqueue',
      }),
    },
  ];

  // TODO: Replace with better alert
  if (globalError) return 'Something Went Wrong';
  if (isLoading || !data) return <span className="spinner-border spinner-border-sm mr-1" />;
  return (
    <>
      <Table columns={TABLE_COLUMNS} data={tableData} />
      {/* TODO: Fix display */}
      {data?.pageInfo && (
        <Pagination className="d-flex">
          <Pagination.Prev disabled={!data.pageInfo.hasPreviousPage} onClick={handlePrevPage} />
          <Pagination.Next disabled={!data.pageInfo.hasNextPage} onClick={handleNextPage} />
        </Pagination>
      )}
    </>
  );
};

OpsTasksTable.propTypes = {
  query: PropTypes.string,
  where: PropTypes.object,
};
