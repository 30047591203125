export const getAllChallengesQuery = `query GetAllQuery($state: String) {
  engagementPrograms (state: $state) {
    displayName
    challenges {
      name
      description
      awardLevels {
        name
        targetDisplayText
        awardAmount
      }
      membership {
        highestAwardLevelReached {
          name
        }
        eligibleAwardAmount
        progressDisplayText
        state
        eligibleAt
        completedAt
        expiresAt
      }
    }
  }
  challenges (state: $state, isPartOfEngagementProgram: false) {
      id
      name
      description
      badgeUrl
      state
      startTime
      endTime
      awardLevels {
        name
        target
        targetUnit
        awardAmount
      }
      membership {
        challengeId
        progress
        highestAwardLevelReached {
          name
        }
        eligibleAwardAmount
        state
        eligibleAt
        completedAt
        expiresAt
      }
    }
  }`;
