import React, { useEffect, useState } from 'react';

import { css, StyleSheet } from 'aphrodite';

import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import { appStyles } from '~/components/app/common/styles';
import { CAPTION_PLACEMENT_OPTIONS_START_INDEX } from '~/helpers/constants';

import ServiceLevelCard from '../ServiceLevelCard';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { additionalServicesQuery } from '~/components/app/order_form/data/queries';

import OrderOptionsContainer from '~/components/app/order_form/serviceOptions/OrderOptionsContainer';

function CaptionPlacementOrderOptions(props) {
  const [serviceLevels, setServiceLevels] = useState([]);

  useEffect(() => {
    threeplayApi
      .request(additionalServicesQuery, {
        selectedAdditionalServiceType: 'CaptionPlacement',
        primaryServiceType: props.selectedPrimaryService.serviceType,
      })
      .then((res) => {
        const data = res.data || {};
        const serviceLevels = data.project?.orderableServices[0].additionalServices.find(
          (service) => service.serviceType == 'CaptionPlacement'
        ).orderOptions.serviceLevels;
        if (serviceLevels) {
          setServiceLevels(serviceLevels);
          if (!Object.keys(props.orderOptions).length) {
            // setDefaults
            props.updateOrderOptions('CaptionPlacement', {
              ...props.orderOptions,
              serviceLevel: {
                name: 'Automated',
                price: serviceLevels.find((sl) => sl.name === 'Automated').price,
                displayPrice: serviceLevels.find((sl) => sl.name === 'Automated').displayPrice,
              },
            });
          }
        }
      });
  }, []);

  function setServiceLevel(serviceLevel) {
    props.updateOrderOptions('CaptionPlacement', {
      ...props.orderOptions,
      serviceLevel: {
        name: serviceLevel,
        price: serviceLevels.find((sl) => sl.name === serviceLevel).price,
        displayPrice: serviceLevels.find((sl) => sl.name === serviceLevel).displayPrice,
      },
    });
  }

  return (
    <OrderOptionsContainer title="Caption Placement" componentName="CaptionPlacementOrderOptions">
      <Card.Title className={css(appStyles.title)}>Caption Placement Type</Card.Title>
      <div className={`d-flex ${css(styles.card)}`}>
        {serviceLevels?.map((serviceLevel, index) => (
          <ServiceLevelCard
            key={serviceLevel.name}
            index={index + CAPTION_PLACEMENT_OPTIONS_START_INDEX}
            name={serviceLevel.name}
            price={serviceLevel.price}
            displayPrice={serviceLevel.displayPrice}
            description={serviceLevel.description}
            deliveryDate={serviceLevel.deliveryDate}
            setServiceLevel={setServiceLevel}
            orderOptions={props.orderOptions}
          />
        ))}
      </div>
    </OrderOptionsContainer>
  );
}

CaptionPlacementOrderOptions.propTypes = {
  orderOptions: PropTypes.object,
  selectedPrimaryService: PropTypes.object,
  updateOrderOptions: PropTypes.func,
};

const styles = StyleSheet.create({
  card: {
    maxWidth: '15rem',
  },
});

export default CaptionPlacementOrderOptions;
