import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Fetched from '~/components/ops/common/Fetched';

import JobAccessByUser from './dashboard/JobAccessByUser';
import JobFilters from './dashboard/JobFilters';
import JobGraphUI from './dashboard/JobGraphUI';

import { useContractorStatsQuery, useJobStatsQuery } from '~/api/opsApi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function useContractorStats() {
  const query = { contractor_accesses_id_null: false };
  const queryString = JSON.stringify(query);
  return useContractorStatsQuery(
    { query: queryString },
    {
      select: (data) => ({
        ...data,
        contractors: {
          ...data.contractors,
          items: data.contractors.items.sort(
            (a, b) => a.availableJobAccessCount - b.availableJobAccessCount
          ),
        },
      }),
    }
  );
}

function useJobStats(filters) {
  const query = { state_eq: 'available', ...filters };
  const queryString = JSON.stringify(query);
  return useJobStatsQuery(
    { query: queryString },
    {
      select: (data) => ({
        ...data,
        jobs: {
          ...data.jobs,
          items: transformJobItems(data.jobs.items),
        },
      }),
    }
  );
}

function transformJobItems(items) {
  const now = new Date();
  return items.map((job) => ({
    ...job,
    deadline: new Date(job.deadline * 1000),
    hoursToDeadline: (job.deadline - now) / (60 * 60 * 1000),
    hoursOnMarket: job.timeOnMarket / (60 * 60),
  }));
}

function MarketDashboard(props) {
  const [displayGraph, setDisplayGraph] = useState('jobs');
  const [jobFilters, setJobFilters] = useState({ job_type_id_eq: '1' });
  const [selectedJobs, setSelectedJobs] = useState([]);

  const contractorStats = useContractorStats();
  const jobStats = useJobStats(jobFilters);

  const contractors = contractorStats.data?.contractors?.items || [];
  const jobs = jobStats.data?.jobs?.items || [];

  return (
    <>
      <Form.Group as={Row} controlId="MarketViewSelector">
        <Form.Label column="true" xs="auto" className="font-weight-bold my-auto">
          Select a view
        </Form.Label>
        <Col xs="auto">
          <Form.Control
            column="true"
            as="select"
            onChange={() => setDisplayGraph(event.target.value)}
          >
            <option value="jobs">Job Accesses by Hours to Deadline</option>
            <option value="accesses">Job Accesses by Contractor Totals</option>
          </Form.Control>
        </Col>
      </Form.Group>

      <JobFilters submarkets={props.submarkets} onSubmit={setJobFilters} />

      {displayGraph === 'jobs' && (
        <>
          <h3>Available Jobs</h3>
          <Fetched fetched={{ error: jobStats.isError, fetching: jobStats.isFetching }}>
            <JobGraphUI jobs={jobs} selectedJobs={selectedJobs} setSelectedJobs={setSelectedJobs} />
          </Fetched>
        </>
      )}

      {displayGraph === 'accesses' && (
        <>
          <h3>Access by contractor</h3>
          <Fetched
            fetched={{ error: contractorStats.isError, fetching: contractorStats.isFetching }}
          >
            <JobAccessByUser contractors={contractors} />
          </Fetched>
        </>
      )}
    </>
  );
}

MarketDashboard.propTypes = {
  submarkets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

const Container = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <MarketDashboard {...props}></MarketDashboard>
    </QueryClientProvider>
  );
};

export default Container;
