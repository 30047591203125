import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function RemoveWordlistModal(props) {
  return (
    <Modal show={props.show} onHide={() => props.closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Wordlist for {props.wordlistName}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Deleting this wordlist will remove the wordlist from any future orders for this{' '}
          {props.resource}. Existing orders with this wordlist will not be affected.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.closeModal()}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => props.removeWordlist()}>
          Yes - Delete Wordlist
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

RemoveWordlistModal.propTypes = {
  closeModal: PropTypes.func,
  removeWordlist: PropTypes.func,
  resource: PropTypes.string,
  show: PropTypes.bool,
  wordlistName: PropTypes.string,
};

export default RemoveWordlistModal;
