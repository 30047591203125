import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';

import CollapsiblePanel from '~/components/app/common/CollapsiblePanel';
import EditButton from '~/components/app/common/EditButton';
import FileInformation from './FileInformation';
import GeneralInformation from './GeneralInformation';

import { threeplayApi } from '~/logic/ThreeplayApi';

// TODO: Use Graphql variables
function query(fileId) {
  return `file(id: ${fileId}) {
    name,
    id,
    duration,
    videoId,
    description,
    thumbnailUrl,
    attribute1,
    attribute2,
    attribute3,
    startingTimecode,
    framerate,
    dropframe,
    audioRating,
    estimatedAccuracy,
    shouldSuggestAdLevel,
    suggestedAdLevel,
    uploadedByUserName,
    source { id },
    batch { name },
    mediaFileIntegration {
      externalId,
      name,
      videoPlatformIntegrationId,
    },
    cheatSheet {
      content,
      pdf,
    }
  }`;
}

function editMutations(fileId, edits) {
  let argstring = `fileId: ${fileId}`;
  Object.keys(edits)
    .filter((key) => edits.hasOwnProperty(key))
    .forEach((key) => {
      argstring = argstring + `, ${key}: "${edits[key]}"`;
    });
  return `editFile(${argstring}) {
    errors,
    file{ name },
  }`;
}

function EditActions(props) {
  return (
    <span className={css(styles.editActions)}>
      <span className={css(styles.editActionButtonNotLast)}>
        <Button size="sm" variant="outline-secondary" onClick={props.onCancel}>
          Cancel
        </Button>
      </span>
      <Button size="sm" onClick={props.onSave}>
        Save Changes
      </Button>
    </span>
  );
}

EditActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

function DetailsBody(props) {
  const [editing, setEditing] = useState(null);
  const [editErrors, setEditErrors] = useState({});
  const [edits, setEdits] = useState({});
  const [errors, setErrors] = useState([]);
  const [fileDetails, setFileDetails] = useState({});

  useEffect(() => {
    if (!props.fileId) {
      return;
    }
    setErrors([]);
    cancelEditing();
    loadData(props.fileId);
  }, [props.fileId, props.refreshToken]);

  async function loadData(fileId) {
    const querystring = query(fileId);
    const response = await threeplayApi.query(querystring, 'FileDetails');
    setFileDetails(response);
  }

  function validateEdits() {
    const errors = {};
    if (edits.hasOwnProperty('startingTimecode')) {
      if (!/^\d{2}:\d{2}:\d{2}:\d{2}$/.test(edits.startingTimecode)) {
        const msg = 'Must match format 00:00:00:00';
        errors.startingTimecode = (errors.startingTimecode || []).concat(msg);
      }
    }
    const ok = Object.keys(errors).length === 0;
    setEditErrors(errors);
    return { ok: ok, errors: errors };
  }

  async function updateData() {
    const { ok } = validateEdits();
    if (!ok) {
      return;
    }

    const mutation = editMutations(props.fileId, edits);
    const response = await threeplayApi.mutate(mutation);
    if (response.errors) {
      setErrors(response.errors);
      return;
    }
    await loadData(props.fileId);
    finishEditing();
  }

  function startEditing(componentName) {
    setEdits({});
    setEditing(componentName);
  }

  function cancelEditing() {
    setEdits({});
    setEditing(null);
  }

  function finishEditing() {
    setEdits({});
    setEditing(null);
    props.onUpdate();
  }

  function handleEdit(changes) {
    setEdits({ ...edits, ...changes });
  }

  if (errors.length > 0) {
    return (
      <div className={css(styles.body)}>
        <p>Unfortunately, something went wrong.</p>
      </div>
    );
  }

  const generalActionItems =
    editing === 'GeneralInformation' ? (
      <EditActions onSave={updateData} onCancel={cancelEditing} />
    ) : (
      <EditButton onClick={() => startEditing('GeneralInformation')} />
    );

  const fileActionItems =
    editing === 'FileInformation' ? (
      <EditActions onSave={updateData} onCancel={cancelEditing} />
    ) : (
      <EditButton onClick={() => startEditing('FileInformation')} />
    );

  return (
    <div className={css(styles.body)}>
      <CollapsiblePanel
        title="General Information"
        open={props.generalInfoOpen}
        setOpen={props.setGeneralInfoOpen}
        actionItems={generalActionItems}
      >
        <GeneralInformation
          fileDetails={fileDetails}
          editing={editing === 'GeneralInformation'}
          edits={edits}
          enabledFeatures={props.enabledFeatures}
          errors={editErrors}
          onChange={handleEdit}
          setShowModal={props.setShowModal}
          replaceSourceEnabled={props.replaceSourceEnabled}
        />
      </CollapsiblePanel>
      <CollapsiblePanel
        title="File Information"
        open={props.fileInfoOpen}
        setOpen={props.setFileInfoOpen}
        actionItems={fileActionItems}
      >
        <FileInformation
          fileDetails={fileDetails}
          editing={editing === 'FileInformation'}
          edits={edits}
          errors={editErrors}
          onChange={handleEdit}
          onUpdate={props.onUpdate}
          setErrorsAlert={props.setErrorsAlert}
          setShowModal={props.setShowModal}
          setSuccess={props.setSuccess}
        />
      </CollapsiblePanel>
    </div>
  );
}

const styles = StyleSheet.create({
  body: {
    overflowY: 'scroll',
  },
  editActions: {},
  editActionButtonNotLast: {
    marginRight: '10px',
  },
});

DetailsBody.propTypes = {
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  fileId: PropTypes.string,
  fileInfoOpen: PropTypes.bool,
  generalInfoOpen: PropTypes.bool,
  onUpdate: PropTypes.func,
  refreshToken: PropTypes.string,
  replaceSourceEnabled: PropTypes.bool,
  setGeneralInfoOpen: PropTypes.func,
  setFileInfoOpen: PropTypes.func,
  setErrorsAlert: PropTypes.func,
  setSuccess: PropTypes.func,
  setShowModal: PropTypes.shape({
    linkIntegration: PropTypes.func,
    replaceSource: PropTypes.func,
  }),
};

export default DetailsBody;
