import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import DeleteInProgressEventModal from './DeleteInProgressEventModal';
import CancelUpcomingEventModal from './CancelUpcomingEventModal';
import InProgressEvents from './InProgressEvents';
import PreviousEvents from './PreviousEvents';
import UpcomingEvents from './UpcomingEvents';
import AlertMessage from '~/components/app/common/AlertMessage';
import CustomerSupportModal from './CustomerSupportModal';

import { flipperFeaturesShape, liveTranscoderShape } from '../common/shapes';

import { sortBatches } from '../common/helpers';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { batchesQuery } from '~/components/app/order_form/data/queries';

import { liveAsrQuery } from '~/components/app/live_auto_captioning/data/queries';
import {
  createSupportIssueMutation,
  endInProgressEventMutation,
  endUpcomingEventMutation,
  updateZoomCaptionUrlForEventMutation,
} from '~/components/app/live_auto_captioning/data/mutations';
import { resendZoomApiTokenEmailQuery } from '~/components/app/video_platform_integrations/data/queries';

function Dashboard({
  flipperFeatures,
  liveTranscoders,
  maxStreamReconnectionWaitTime,
  maxStreamTime,
  createEventPath,
}) {
  const [alertNotification, setAlertNotification] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const [batches, setBatches] = useState([]);
  const [customerSupport, setCustomerSupport] = useState({
    eventId: null,
    issue: '',
    modal: false,
  });
  const [erroredEvents, setErroredEvents] = useState({});

  const [fetching, setFetching] = useState({ inProgress: true, previous: true, upcoming: true });
  const [key, setKey] = useState('upcoming');

  const [inProgressEvents, setInProgressEvents] = useState({});
  const [inProgressEventToEnd, setInProgressEventToEnd] = useState(null);

  const [previousEvents, setPreviousEvents] = useState({});
  const [previousEventsSearchTerm, setPreviousEventsSearchTerm] = useState('');

  const [upcomingEvents, setUpcomingEvents] = useState({});
  const [upcomingEventToEnd, setUpcomingEventToEnd] = useState(null);
  const [upcomingEventsSearchTerm, setUpcomingEventsSearchTerm] = useState('');

  const [userTimeZone, setUserTimeZone] = useState('America/New_York');

  useEffect(() => {
    const defaultPaginationParams = { currentPage: 1, entriesPerPage: 10 };
    const defaultSearchTerm = '';
    const defaultSortParams = {
      inProgress: {
        direction: 'DESC',
        sortBy: 'live_streams.start_time',
      },
      previous: {
        direction: 'DESC',
        sortBy: 'live_streams.start_time',
      },
      upcoming: {
        direction: 'ASC',
        sortBy: 'live_streams.start_time',
      },
    };

    const inProgressParams = {
      pagination: defaultPaginationParams,
      searchTerm: defaultSearchTerm,
      sortParams: defaultSortParams.inProgress,
    };
    setInProgressEvents({ params: inProgressParams });

    const upcomingParams = {
      pagination: defaultPaginationParams,
      sortParams: defaultSortParams.upcoming,
    };
    setUpcomingEvents({ params: upcomingParams });

    const previousParams = {
      pagination: defaultPaginationParams,
      sortParams: defaultSortParams.previous,
    };
    setPreviousEvents({ params: previousParams });
  }, []);

  useEffect(() => {
    if (inProgressEvents.params) {
      getInProgressEvents();
    }
  }, [inProgressEvents.params]);

  useEffect(() => {
    if (previousEvents.params) {
      getPreviousEvents(previousEventsSearchTerm);
    }
  }, [previousEvents.params]);

  useEffect(() => {
    if (upcomingEvents.params) {
      getUpcomingEvents(upcomingEventsSearchTerm);
    }
  }, [upcomingEvents.params]);

  function getInProgressEvents() {
    setFetching((prevState) => ({ ...prevState, inProgress: true }));
    threeplayApi
      .request(liveAsrQuery, {
        paginationParams: inProgressEvents.params.pagination,
        searchTerm: inProgressEvents.params.searchTerm,
        sortParams: inProgressEvents.params.sortParams,
        type: 'inProgressEvents',
        dashboardErrors: true,
      })
      .then((response) => {
        if (response?.data?.project?.liveAsrs) {
          setInProgressEvents((prevState) => ({
            ...prevState,
            data: response.data.project.liveAsrs,
          }));
          setFetching((prevState) => ({ ...prevState, inProgress: true }));
          setUserTimeZone(response.data.project.userTimeZone);
        }
      });
  }

  function getUpcomingEvents(searchTerm = '') {
    setFetching((prevState) => ({ ...prevState, upcoming: true }));
    threeplayApi
      .request(liveAsrQuery, {
        paginationParams: upcomingEvents.params.pagination,
        searchTerm: searchTerm,
        sortParams: upcomingEvents.params.sortParams,
        type: 'upcomingEvents',
        detailedEventStatus: true,
      })
      .then((response) => {
        if (response?.data?.project?.liveAsrs) {
          setUpcomingEvents((prevState) => ({
            ...prevState,
            data: response.data.project.liveAsrs,
          }));
          setFetching((prevState) => ({ ...prevState, upcoming: false }));
          setUserTimeZone(response.data.project.userTimeZone);
        }
      });
  }

  useEffect(() => {
    threeplayApi.request(batchesQuery).then((res) => {
      const data = res.data || {};
      if (data.batches) {
        const unarchivedBatches = sortBatches(data.batches);
        setBatches(unarchivedBatches);
      }
    });
  }, []);

  function openEndInProgressEventModal(event) {
    setInProgressEventToEnd({
      ...inProgressEventToEnd,
      buttonDisabled: false,
      event: event,
      modal: true,
    });
  }

  async function endInProgressEvent() {
    const eventId = inProgressEventToEnd.event.id;
    setInProgressEventToEnd({ ...inProgressEventToEnd, buttonDisabled: true });
    const response = await threeplayApi.request(endInProgressEventMutation, {
      eventId: Number(eventId),
    });
    if (response.data.endInProgressLiveEvent.success) {
      setInProgressEventToEnd({
        ...inProgressEventToEnd,
        buttonDisabled: true,
        error: null,
        success: response.data.endInProgressLiveEvent.success,
      });
      setTimeout(() => {
        closeInProgessEventToEndModal();
        getInProgressEvents();
      }, 5000);
    }
    if (response.data.endInProgressLiveEvent.error) {
      setInProgressEventToEnd({
        ...inProgressEventToEnd,
        buttonDisabled: false,
        error: response.data.endInProgressLiveEvent.error,
        success: null,
      });
    }
  }

  async function endUpcomingEvent({ eventCancellationOption = 'THIS_EVENT' } = {}) {
    const eventId = upcomingEventToEnd.event.id;
    setUpcomingEventToEnd({ ...upcomingEventToEnd, buttonDisabled: true });
    const response = await threeplayApi.request(endUpcomingEventMutation, {
      eventId: Number(eventId),
      eventCancellationOption,
    });
    if (response.data.endUpcomingLiveEvent.success) {
      setUpcomingEventToEnd({
        ...upcomingEventToEnd,
        buttonDisabled: true,
        error: null,
        success: response.data.endUpcomingLiveEvent.success,
      });
      setTimeout(() => {
        closeUpcomingEventToEndModal();
        getUpcomingEvents(upcomingEventsSearchTerm);
      }, 2000);
    }
    if (response.data.endUpcomingLiveEvent.error) {
      setUpcomingEventToEnd({
        ...upcomingEventToEnd,
        buttonDisabled: false,
        error: response.data.endUpcomingLiveEvent.error,
        success: null,
      });
    }
  }

  async function updateZoomCaptionUrlForEvent(type, eventId) {
    const events = getEventDataForType(type);
    const index = events.findIndex((event) => event.id === eventId);
    const newUrl = events[index]['captionIngestionUrl'];
    if (!validateZoomApiUrl(newUrl)) {
      setErroredEvents((prevState) => ({
        ...prevState,
        [eventId]: 'The API Token you entered is invalid',
      }));
      return;
    }

    const response = await threeplayApi.request(updateZoomCaptionUrlForEventMutation, {
      eventId: Number(eventId),
      zoomCaptionUrl: newUrl,
    });
    if (response.data.updateZoomCaptionUrlForEvent.success) {
      setErroredEvents((prevState) => ({ ...prevState, [eventId]: '' }));
      return;
    } else if (response.data.updateZoomCaptionUrlForEvent.error) {
      setErroredEvents((prevState) => ({
        ...prevState,
        [eventId]:
          'Your API Token failed to update: ' + response.data.updateZoomCaptionUrlForEvent.error,
      }));
      return;
    }
    //graph ql call failed entirely
    setErroredEvents((prevState) => ({
      ...prevState,
      [eventId]: 'An error occurred updating the API token. Please try again.',
    }));
  }

  function resendZoomApiTokenEmail(liveEventId, videoPlatformIntegrationId) {
    setFetching((prevState) => ({ ...prevState, previous: true }));
    threeplayApi
      .request(resendZoomApiTokenEmailQuery, {
        liveEventId: liveEventId,
        videoPlatformIntegrationIds: videoPlatformIntegrationId,
      })
      .then((response) => {
        const respData = response.data.project.linkedAccounts[0].integrations[0].resendZoomApiEmail;
        if (respData.success) {
          setInProgressEvents({ ...inProgressEvents, success: respData.success });
        }
        if (respData.error) {
          setInProgressEvents({ ...inProgressEvents, error: respData.error });
        }
      });
  }

  function getPreviousEvents(searchTerm = '') {
    threeplayApi
      .request(liveAsrQuery, {
        paginationParams: previousEvents.params.pagination,
        searchTerm: searchTerm,
        sortParams: previousEvents.params.sortParams,
        type: 'previousEvents',
        detailedEventStatus: true,
      })
      .then((response) => {
        if (response.data.project.liveAsrs) {
          setPreviousEvents((prevState) => ({
            ...prevState,
            data: response.data.project.liveAsrs,
          }));
          setFetching((prevState) => ({ ...prevState, previous: false }));
          setUserTimeZone(response.data.project.userTimeZone);
        }
      });
  }

  function openEndUpcomingEventModal(event) {
    setUpcomingEventToEnd({
      ...upcomingEventToEnd,
      buttonDisabled: false,
      event: event,
      modal: true,
    });
  }

  function closeUpcomingEventToEndModal() {
    setUpcomingEventToEnd({ ...upcomingEventToEnd, modal: false, error: null, success: null });
  }

  // Reset to first page when entries per page changes
  function handlePerPageEntryChange(count, type) {
    if (type === 'upcoming') {
      setUpcomingEvents({
        ...upcomingEvents,
        params: { ...upcomingEvents.params, pagination: { currentPage: 1, entriesPerPage: count } },
      });
    }
    if (type === 'previous') {
      setPreviousEvents({
        ...previousEvents,
        params: { ...previousEvents.params, pagination: { currentPage: 1, entriesPerPage: count } },
      });
    }
  }

  function handlePageNumberChange(pageNumber, type) {
    if (type === 'upcoming') {
      setUpcomingEvents({
        ...upcomingEvents,
        params: {
          ...upcomingEvents.params,
          pagination: { ...upcomingEvents.params.pagination, currentPage: pageNumber },
        },
      });
    }
    if (type === 'previous') {
      setPreviousEvents({
        ...previousEvents,
        params: {
          ...previousEvents.params,
          pagination: { ...previousEvents.params.pagination, currentPage: pageNumber },
        },
      });
    }
  }

  function closeInProgessEventToEndModal() {
    setInProgressEventToEnd({ ...inProgressEventToEnd, modal: false, error: null, success: null });
  }

  const getEventDataForType = (type) => {
    if (type === 'upcoming') {
      return upcomingEvents.data.liveEventDetails;
    }
    if (type === 'inprogress') {
      return inProgressEvents.data.liveEventDetails;
    }

    return [];
  };

  // TODO: Reducer to maintain states better
  const updateCaptionIngestUrl = (eventType, eventId, url) => {
    const newEvents = [...getEventDataForType(eventType)];
    const eventToUpdate = newEvents.find((event) => event.id === eventId);
    if (eventToUpdate) {
      eventToUpdate.captionIngestionUrl = url;
    } else {
      return;
    }

    if (eventType === 'upcoming') {
      setUpcomingEvents((prevState) => ({
        ...prevState,
        data: { ...prevState.data, liveEventDetails: newEvents },
      }));
    } else if (eventType === 'inprogress') {
      setInProgressEvents((prevState) => ({
        ...prevState,
        data: { ...prevState.data, liveEventDetails: newEvents },
      }));
    }
  };

  function validateZoomApiUrl(url) {
    // We want to let users clear a Zoom API token
    // This is to fix any incorrect entries made
    if (!url) {
      return true;
    }

    const zoomCaptionUrlRegEx = /^https:\/\/\w+.zoom.us\/closedcaption\?/;
    return zoomCaptionUrlRegEx.test(url);
  }

  const openCustomerSupportModal = (eventId) => {
    setCustomerSupport({ ...customerSupport, eventId: eventId, modal: true });
  };

  const createSupportIssue = () => {
    threeplayApi
      .request(createSupportIssueMutation, {
        eventId: Number(customerSupport.eventId),
        issue: customerSupport.issue,
      })
      .then((response) => {
        setCustomerSupport({ ...customerSupport, modal: false });
        if (response.data.createSupportIssueForLiveEvents.error) {
          setAlertNotification({ variant: 'danger', message: 'Error Creating Support Ticket' });
        } else {
          setAlertNotification({
            variant: 'success',
            message: 'Support request successfully created',
          });
        }
      });
  };

  return (
    <>
      {alertNotification !== null && (
        <AlertMessage
          display={true}
          message={alertNotification.message}
          variant={alertNotification.variant}
        />
      )}
      <a className="btn btn-primary btn-sm" href={createEventPath} role="button">
        Schedule Live Captions
      </a>
      {inProgressEvents.data &&
        inProgressEvents.data.liveEventDetails &&
        inProgressEvents.data.liveEventDetails.length > 0 && (
          <InProgressEvents
            data={inProgressEvents.data}
            error={inProgressEvents.error}
            erroredEvents={erroredEvents}
            openEndInProgressEventModal={openEndInProgressEventModal}
            resendZoomApiTokenEmail={resendZoomApiTokenEmail}
            setCaptionIngestUrl={(eventId, newUrl) =>
              updateCaptionIngestUrl('inprogress', eventId, newUrl)
            }
            setSortParams={(sort) =>
              setInProgressEvents({
                ...inProgressEvents,
                params: { ...inProgressEvents.params, sortParams: sort },
              })
            }
            openCustomerSupportModal={openCustomerSupportModal}
            success={inProgressEvents.success}
            updateZoomCaptionUrlForEvent={(eventId) =>
              updateZoomCaptionUrlForEvent('inprogress', eventId)
            }
            userTimeZone={userTimeZone}
          />
        )}
      <Tabs activeKey={key} className="dashboard-tab mt-3" onSelect={(tab) => setKey(tab)}>
        <Tab eventKey="upcoming" title="Upcoming Events" tabClassName="font-weight-bold">
          <UpcomingEvents
            erroredEvents={erroredEvents}
            fetching={fetching.upcoming}
            getUpcomingEvents={(searchTerm) => getUpcomingEvents(searchTerm)}
            openEndUpcomingEventModal={openEndUpcomingEventModal}
            paginationParams={upcomingEvents.params?.pagination}
            searchTerm={upcomingEventsSearchTerm}
            setCaptionIngestUrl={(eventId, newUrl) =>
              updateCaptionIngestUrl('upcoming', eventId, newUrl)
            }
            setEntriesPerPage={(count) => handlePerPageEntryChange(count, 'upcoming')}
            setPageNumber={(pageNumber) => handlePageNumberChange(pageNumber, 'upcoming')}
            setSearchTerm={(query) => setUpcomingEventsSearchTerm(query)}
            setSortParams={(sort) =>
              setUpcomingEvents({
                ...upcomingEvents,
                params: { ...upcomingEvents.params, sortParams: sort },
              })
            }
            openCustomerSupportModal={openCustomerSupportModal}
            upcomingEvents={upcomingEvents.data}
            updateZoomCaptionUrlForEvent={(eventId) =>
              updateZoomCaptionUrlForEvent('upcoming', eventId)
            }
            userTimeZone={userTimeZone}
            validateZoomApiToken={validateZoomApiUrl}
          />
        </Tab>
        <Tab eventKey="previous" title="Previous Events" tabClassName="font-weight-bold">
          <PreviousEvents
            fetching={fetching.previous}
            getPreviousEvents={(searchTerm) => getPreviousEvents(searchTerm)}
            previousEvents={previousEvents.data}
            paginationParams={previousEvents.params?.pagination}
            searchTerm={previousEventsSearchTerm}
            setEntriesPerPage={(count) => handlePerPageEntryChange(count)}
            setPageNumber={(pageNumber) => handlePageNumberChange(pageNumber, 'previous')}
            setSearchTerm={(query) => setPreviousEventsSearchTerm(query, 'previous')}
            setSortParams={(sort) =>
              setPreviousEvents({
                ...previousEvents,
                params: { ...previousEvents.params, sortParams: sort },
              })
            }
            userTimeZone={userTimeZone}
          />
        </Tab>
      </Tabs>
      {inProgressEventToEnd && (
        <DeleteInProgressEventModal
          buttonDisabled={inProgressEventToEnd.buttonDisabled}
          closeModal={closeInProgessEventToEndModal}
          endInProgressEvent={endInProgressEvent}
          error={inProgressEventToEnd.error}
          eventName={inProgressEventToEnd.event.name}
          show={inProgressEventToEnd.modal}
          success={inProgressEventToEnd.success}
        />
      )}
      {upcomingEventToEnd && (
        <CancelUpcomingEventModal
          cancelCaptionsWithCharge={upcomingEventToEnd.event.cancelCaptionsWithCharge}
          buttonDisabled={upcomingEventToEnd.buttonDisabled}
          closeModal={closeUpcomingEventToEndModal}
          endUpcomingEvent={endUpcomingEvent}
          error={upcomingEventToEnd.error}
          eventName={upcomingEventToEnd.event.name}
          show={upcomingEventToEnd.modal}
          success={upcomingEventToEnd.success}
          partOfSet={Boolean(upcomingEventToEnd.event.liveRecurringEventSetId)}
        />
      )}
      <CustomerSupportModal
        closeModal={() => setCustomerSupport({ ...customerSupport, modal: false })}
        createSupportIssue={createSupportIssue}
        issue={customerSupport.issue}
        setIssue={(issue) => setCustomerSupport({ ...customerSupport, issue })}
        show={customerSupport.modal}
      />
    </>
  );
}

Dashboard.propTypes = {
  flipperFeatures: flipperFeaturesShape,
  liveTranscoders: PropTypes.arrayOf(liveTranscoderShape),
  maxStreamReconnectionWaitTime: PropTypes.number,
  maxStreamTime: PropTypes.number,
  createEventPath: PropTypes.string.isRequired,
};

export default Dashboard;
