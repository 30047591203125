import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { reformatPath } from '~/helpers/app/paths';

const ReformatButton = ({ fileId, services }) => {
  return (
    <DropdownButton size="sm" className={css(styles.reformatButton)} title="Reformat">
      {services.map((service, index) => (
        <Dropdown.Item key={index} href={reformatPath(fileId, service.serviceSlug)}>
          {service.serviceName}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

ReformatButton.propTypes = {
  fileId: PropTypes.number,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      serviceName: PropTypes.string,
      serviceSlug: PropTypes.string,
    })
  ),
};

const styles = StyleSheet.create({
  reformatButton: {
    float: 'right',
    marginRight: '13px',
    marginTop: '-4px',
  },
});

export default ReformatButton;
