import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { pluralize } from '~/helpers/strings';

const formatDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString();
};

const formatTotalDurationLimit = (totalDurationLimit) =>
  Number.isNaN(totalDurationLimit) ? '' : pluralize('minute', totalDurationLimit);

const formatOrderOption = (orderOption, language) => {
  const languageList = language.join(', ');
  const firstLetterOfFileLanguage = languageList.match(/[A-Z]/g)[1];
  // source language of the Vendor Transcription file
  const fileLanguage = languageList.substring(languageList.indexOf(firstLetterOfFileLanguage));

  switch (orderOption) {
    case 'TranslationOrderOption':
      return `TransPerfect Translation (${language[0]})`;
    case 'VendorTranscriptionServiceOption':
      return `Vendor Transcription (${languageList})`;
    case 'TransPerfect Translation':
      return `TransPerfect Translation (${languageList})`;
    case 'Vendor Transcription, TransPerfect Translation':
      return `Vendor Transcription (${fileLanguage}), TransPerfect Translation (${languageList})`;
  }
};

const groupByDiscountCode = (transperfectNoticeRecords) => {
  const data = transperfectNoticeRecords.reduce((acc, notice) => {
    acc[notice.discount_code]
      ? acc[notice.discount_code].push(notice)
      : (acc[notice.discount_code] = [notice]);
    return acc;
  }, []);
  return Object.values(data);
};

const getTurnaroundDisplayName = (option) => {
  switch (option) {
    case 'VendorTranscriptionServiceOption':
      return 'Transcription';
    case 'TranslationOrderOption':
      return 'Translation';
  }
};

const formatTurnaround = (turnarounds) => {
  if (turnarounds.length === 1) {
    return turnarounds[0].turnaround;
  }

  const sorted = turnarounds.sort(function (a, b) {
    if (a.service < b.service) {
      return -1;
    }
    if (b.service < a.service) {
      return 1;
    }
    return 0;
  });

  const displayList = [];
  for (const item of sorted) {
    displayList.push(`${item.service}: ${item.turnaround}`);
  }

  return displayList.join(', ');
};

const shapeData = (transperfectNoticeRecords) => {
  return groupByDiscountCode(transperfectNoticeRecords).map((records) => {
    // stores all of the languages for a given grouped discount code batch
    const languagesArray = [];
    // stores all of the order option types for a given grouped discount code batch
    const orderOptionTypesArray = [];
    let orderOptionToDisplay = '';

    // Stores turnaround times for the given options
    const turnarounds = [];

    records.forEach((orderOptionObject) => {
      languagesArray.push(orderOptionObject['language']);
      orderOptionTypesArray.push(orderOptionObject['order_option_type']);
      turnarounds.push({
        service: getTurnaroundDisplayName(orderOptionObject['order_option_type']),
        turnaround: orderOptionObject['turnaround'],
      });
    });

    // if there is only one TranslationOrderOption
    //or there is only one VendorTranscriptionServiceOption
    if (
      orderOptionTypesArray.length === 1 &&
      orderOptionTypesArray[0] === 'TranslationOrderOption'
    ) {
      orderOptionToDisplay = 'TranslationOrderOption';
    } else if (
      orderOptionTypesArray.length === 1 &&
      orderOptionTypesArray[0] === 'VendorTranscriptionServiceOption'
    ) {
      orderOptionToDisplay = 'VendorTranscriptionServiceOption';
    } //  checks if the customer ordered just TransPerfect Translation
    //or Vendor Transcription and TransPerfect Translation
    else if (!orderOptionTypesArray.includes('VendorTranscriptionServiceOption')) {
      orderOptionToDisplay = 'TransPerfect Translation';
    } else {
      orderOptionToDisplay = 'Vendor Transcription, TransPerfect Translation';
    }

    const orderOptions = formatOrderOption(orderOptionToDisplay, languagesArray);
    return {
      orderOptionType: orderOptions,
      startTime: formatDate(records[0].start_time),
      endTime: formatDate(records[0].end_time),
      turnaround: formatTurnaround(turnarounds),
      requestedByUserEmail: records[0].requested_by_user_email,
      totalDurationLimit: formatTotalDurationLimit(records[0].total_duration_limit),
    };
  });
};

const ScheduledOrdersTable = ({ transperfectNoticeRecords }) => {
  const tableData = useMemo(() => shapeData(transperfectNoticeRecords), []);

  return (
    <div>
      <Table hover>
        <thead>
          <tr>
            <th>Order Type</th>
            <th>Requested By</th>
            <th>Upload Start Date</th>
            <th>Upload End Date</th>
            <th>Turnaround</th>
            <th>Expected Volume</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, key) => {
            return (
              <tr key={key}>
                <td>{row.orderOptionType}</td>
                <td>{row.requestedByUserEmail}</td>
                <td>{row.startTime}</td>
                <td>{row.endTime}</td>
                <td>{row.turnaround}</td>
                <td>{row.totalDurationLimit}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ScheduledOrdersTable.propTypes = {
  transperfectNoticeRecords: PropTypes.arrayOf(
    PropTypes.shape({
      order_option_type: PropTypes.string,
      start_time: PropTypes.string,
      end_time: PropTypes.string,
      file_count_limit: PropTypes.string,
      total_duration_limit: PropTypes.sting,
      requested_by_user_email: PropTypes.string,
      discount_code: PropTypes.string,
      turnaround: PropTypes.string,
    })
  ),
};
export default ScheduledOrdersTable;
