import React from 'react';

import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';

import DataDeletionPage from './DataDeletionPage';

const DataDeletionPageWrapper = () => {
  const apiClient = new ThreeplayApiV2('/data');

  return (
    <ThreeplayAPIProvider client={apiClient}>
      <DataDeletionPage />
    </ThreeplayAPIProvider>
  );
};

export default DataDeletionPageWrapper;
