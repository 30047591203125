import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Table from 'react-bootstrap/Table';
import { secondsToTime } from '~/components/app/common/Helpers';

import AudioRating from './file_details/AudioRating';
import Thumbnail from './Thumbnail';
import ReplaceSource from './file_details/ReplaceSource';
import SuggestedAudioDescriptionLevel from './file_details/SuggestedAudioDescriptionLevel';

function EstimatedAccuracy({ value }) {
  return <span>&nbsp; [Estimated Accuracy: {value}%]</span>;
}

EstimatedAccuracy.propTypes = {
  value: PropTypes.number,
};

function GeneralInformation(props) {
  const { data = {}, errors } = props.fileDetails;
  const file = data.file || {};
  const batch = file.batch || {};

  if (errors) {
    return <p>There was an error loading your file information</p>;
  }

  function handleEditFileName(event) {
    props.onChange({ [event.target.name]: event.target.value });
  }

  return (
    <Table>
      <tbody>
        <tr>
          <td>Source</td>
          <td className={css(styles.source)}>
            {file.source ? (
              <Thumbnail type="video" src={file.thumbnailUrl} />
            ) : (
              <span>No source media</span>
            )}
            <ReplaceSource
              setShowModal={props.setShowModal}
              action={file.source ? 'replace' : 'add'}
              disabled={props.replaceSourceEnabled === false}
            />
          </td>
        </tr>
        <tr>
          <td>Name</td>
          <td>
            {props.editing ? (
              <input
                name="name"
                value={props.edits.hasOwnProperty('name') ? props.edits.name : file.name}
                onChange={handleEditFileName}
                className={css(styles.name)}
              />
            ) : (
              <span>{file.name}</span>
            )}
          </td>
        </tr>
        <tr>
          <td>File ID</td>
          <td>{file.id}</td>
        </tr>
        <tr>
          <td>Folder</td>
          <td>{batch.name}</td>
        </tr>
        <tr>
          <td>Duration</td>
          <td>{file.duration && secondsToTime(file.duration)}</td>
        </tr>
        <tr>
          <td>Audio Quality</td>
          <td>
            <AudioRating rating={file.audioRating} />
            {file.estimatedAccuracy && <EstimatedAccuracy value={file.estimatedAccuracy} />}
          </td>
        </tr>
        {props.enabledFeatures.includes('other') && (
          <tr>
            <td>Suggested Audio Description Level</td>
            <td>
              {file.shouldSuggestAdLevel && (
                <SuggestedAudioDescriptionLevel value={file.suggestedAdLevel} />
              )}
            </td>
          </tr>
        )}
        <tr>
          <td>Uploaded By</td>
          <td>{file.uploadedByUserName || '-'}</td>
        </tr>
      </tbody>
    </Table>
  );
}

GeneralInformation.propTypes = {
  editing: PropTypes.bool,
  edits: PropTypes.shape({
    name: PropTypes.string,
  }),
  editErrors: PropTypes.shape({}),
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  fileDetails: PropTypes.shape({
    data: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.object),
  }),
  onChange: PropTypes.func.isRequired,
  replaceSourceEnabled: PropTypes.bool,
  setShowModal: PropTypes.shape({
    replaceSource: PropTypes.func,
  }),
};

const styles = StyleSheet.create({
  source: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    width: '100%',
  },
});

export default GeneralInformation;
