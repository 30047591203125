import React from 'react';

const FAQ_LINK = `https://www.3playmedia.com/international-contractor-challenge-faq`;

const InternationalEngagementProgram2024Copy = () => (
  <>
    <p>
      For the International Contractor Challenge, the more you complete as an editor in 2024, the
      more you&rsquo;re rewarded at the end of the year.
    </p>

    <p>
      Your individual accomplishments of cumulative number of hours completed and cumulative number
      of jobs completed are on this dashboard. Complete as many editing jobs as you want throughout
      the year, because all jobs count for both challenges!
    </p>

    <p>
      For more information and frequently asked questions about this challenge,{' '}
      <a href={FAQ_LINK}>please visit our FAQ page</a>.
    </p>
  </>
);

export default InternationalEngagementProgram2024Copy;
