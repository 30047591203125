import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const MINIMUM_PROGRESS_BAR_SIZE = 7;

const ProgressBar = ({ completed, message = undefined }) => {
  const styles = StyleSheet.create({
    containerStyles: {
      height: 20,
      width: '100%',
      backgroundColor: '#e0e0de',
      borderRadius: 50,
      marginTop: 10,
      marginBottom: 10,
    },
    fillerStyles: {
      height: '100%',
      width: `${Math.max(completed, MINIMUM_PROGRESS_BAR_SIZE)}%`,
      backgroundColor: '#007eb5',
      borderRadius: 'inherit',
      textAlign: 'center',
    },
    labelStyles: {
      padding: 5,
      color: 'white',
      fontWeight: 'bold',
    },
  });

  function barText(completed, message) {
    if (message) {
      return `${completed}% (${message})`;
    }
    return `${completed}%`;
  }

  return (
    <div className={css(styles.containerStyles)}>
      <div className={css(styles.fillerStyles)}>
        <span className={css(styles.labelStyles)}>{barText(completed, message)}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
