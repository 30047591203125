import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';
import { offPlatformVoiceOverJobsPaths } from './offPlatformVoiceOverJobsPaths';
import { OffPlatformVoiceOverJob } from './:id/OffPlatformVoiceOverJob';

function OffPlatformVoiceOverJobsApp({ permissions }: RootProps) {
  return (
    <JobAppContainer
      showPath={offPlatformVoiceOverJobsPaths.show.route}
      jobComponent={<OffPlatformVoiceOverJob />}
      permissions={permissions}
    />
  );
}

export default OffPlatformVoiceOverJobsApp;
