.checkGrid {
  display: grid;
  grid-template-areas: 'icon primary' 'blank secondary';
  grid-template-columns: auto 1fr;
}

.checkGrid + .checkGrid {
  margin-top: 5px;
}

.icon {
  grid-area: icon;
  align-self: center;
  margin-right: 10px;
}

.primaryCheck {
  grid-area: primary;
  align-self: center;
}

.secondaryChecks {
  grid-area: secondary;
  align-self: center;
}
