import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import AlertMessage from '~/components/app/common/AlertMessage';
import EventCancellationMessage from './EventCancellationMessage';

function CancelUpcomingEventModal(props) {
  const [eventCancellationOption, setEventCancellationOption] = useState('THIS_EVENT');

  return (
    <Modal show={props.show} onHide={() => props.closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Upcoming Live Captions?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.success && <AlertMessage message={props.success} variant="success" />}
        {props.error && <AlertMessage message={props.error} variant="danger" />}
        <EventCancellationMessage
          cancelCaptionsWithCharge={props.cancelCaptionsWithCharge}
          eventName={props.eventName}
        />
        {props.partOfSet && (
          <div>
            <p>This is a recurring event. Please select which events you would like to cancel.</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="cancelOption"
                value={'this_event'}
                checked={eventCancellationOption === 'THIS_EVENT'}
                onChange={() => setEventCancellationOption('THIS_EVENT')}
                id="cancelOptionThisEvent"
              />
              <label className="form-check-label" htmlFor="cancelOptionThisEvent">
                This event
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="cancelOption"
                value={'this_and_following_events'}
                checked={eventCancellationOption === 'THIS_AND_FOLLOWING_EVENTS'}
                onChange={() => setEventCancellationOption('THIS_AND_FOLLOWING_EVENTS')}
                id="cancelOptionThisAndFollowingEvents"
              />
              <label className="form-check-label" htmlFor="cancelOptionThisAndFollowingEvents">
                This and following events
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="cancelOption"
                value={true}
                checked={eventCancellationOption === 'ALL_SCHEDULED_EVENTS'}
                onChange={() => setEventCancellationOption('ALL_SCHEDULED_EVENTS')}
                id="cancelOptionAllScheduledEvents"
              />
              <label className="form-check-label" htmlFor="cancelOptionAllScheduledEvents">
                All scheduled events
              </label>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-flex-end">
        <div>
          <Button className="mr-2" variant="outline-dark" onClick={() => props.closeModal()}>
            Go Back
          </Button>
          <Button
            disabled={props.buttonDisabled}
            onClick={() => props.endUpcomingEvent({ eventCancellationOption })}
            variant="danger"
          >
            Cancel Captions
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

CancelUpcomingEventModal.propTypes = {
  buttonDisabled: PropTypes.bool,
  closeModal: PropTypes.func,
  endUpcomingEvent: PropTypes.func,
  error: PropTypes.string,
  cancelCaptionsWithCharge: PropTypes.bool,
  eventName: PropTypes.string,
  show: PropTypes.bool,
  success: PropTypes.string,
  partOfSet: PropTypes.bool,
};

export default CancelUpcomingEventModal;
