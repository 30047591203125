import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import WithIcon from '~/components/app/common/WithIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DetailedEventStatus = ({ status }) => {
  const getStatusStyling = (type) => {
    if (type === 'error') {
      return css(styles.errorState);
    }
    if (type === 'success') {
      return css(styles.successState);
    }
    if (type === 'warning') {
      return css(styles.warningState);
    }

    return '';
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{status.tooltip}</Tooltip>}
      trigger={['hover', 'focus']}
    >
      <span className={`font-weight-bold ${getStatusStyling(status.type)}`}>
        <WithIcon icon={`fa ${status.icon}`}>{status.text}</WithIcon>
      </span>
    </OverlayTrigger>
  );
};

const styles = StyleSheet.create({
  errorState: {
    color: '#D60000',
  },
  successState: {
    color: '#067A00',
  },
  warningState: {
    color: '#8A6D3B',
  },
});

DetailedEventStatus.propTypes = {
  status: PropTypes.shape({
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default DetailedEventStatus;
