import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';

import { threeplayApi } from '~/logic/ThreeplayApi';
import { streamQuery } from '~/components/app/media_files/ShowPanel/data/queries';

import { previewTrackEndPoint } from '~/helpers/app/paths';

function getTrack(previewTrack, languageCode) {
  return {
    file: {
      tracks: [{ kind: 'captions', src: previewTrack, srcLang: languageCode, default: true }],
      attributes: {
        controlsList: 'nodownload',
      },
    },
  };
}
function PreviewModal(props) {
  const [previewStream, setPreviewStream] = useState(null);
  const [previewTrack, setPreviewTrack] = useState(null);
  const [fetching, setFetching] = useState(false);
  const track = useMemo(
    () => getTrack(previewTrack, props.languageCode),
    [previewTrack, props.languageCode]
  );

  useEffect(() => {
    if (!props.show || !props.fileId) {
      return;
    }

    setFetching(true);
    if (props.fileId) {
      threeplayApi.request(streamQuery, { fileId: props.fileId }).then((response) => {
        if (response.data.file.stream) {
          setPreviewStream(response.data.file.stream.path);
        }
        setFetching(false);
      });
    }
  }, [props.show, props.fileId]);

  useEffect(() => {
    if (props.fileId && props.transcriptId) {
      const track = previewTrackEndPoint({
        fileId: props.fileId,
        transcriptId: props.transcriptId,
        shouldOffset: !props.hasVideoSource,
      });
      setPreviewTrack(track);
    }
  }, [props.fileId, props.transcriptId, props.hasVideoSource]);

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>CC Preview</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {fetching && <div>Loading...</div>}
        {!fetching && previewStream && (
          <ReactPlayer
            url={previewStream}
            playing
            controls={true}
            width="100%"
            height="100%"
            config={track}
          />
        )}
        {!fetching && !previewStream && (
          <div>
            Stream not found. You can add a stream for this file on its &apos;Details&apos; tab.
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

PreviewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  fileId: PropTypes.string.isRequired,
  transcriptId: PropTypes.string,
  languageCode: PropTypes.string,
  hasVideoSource: PropTypes.bool.isRequired,
};

export default PreviewModal;
