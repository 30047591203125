import { CapitalizeIPronounFormatter } from './Formatters/CapitalizeIPronounFormatter';
import { CapitalizeNamesFormatter } from './Formatters/CapitalizeNamesFormatter';
import { CapitalizeNewSentencesFormatter } from './Formatters/CapitalizeNewSentencesFormatter';
import { CapitalizeStartFormatter } from './Formatters/CapitalizeStartFormatter';
import { ColonNumberFormatter } from './Formatters/ColonNumberFormatter';
import { EnclosingPunctuationFormatter } from './Formatters/EnclosingPunctuationFormatter';
import { EndOfSentencePunctuationFormatter } from './Formatters/EndOfSentencePunctuationFormatter';
import { MidSentencePunctuationFormatter } from './Formatters/MidSentencePunctuationFormatter';
import { PunctuationWithSpacesFormatter } from './Formatters/PunctuationWithSpacesFormatter';
import { RemoveExcessWhitespaceFormatter } from './Formatters/RemoveExcessWhitespaceFormatter';
import { DecapitalizeAfterApostropheFormatter } from './Formatters/DecapitalizeAfterApostropheFormatter';
import { Formatter, PunctuationFormatter } from './_types';
import { AddLeadingWhitespaceFormatter } from './Formatters/AddLeadingWhitespaceFormatter';
import { casedNames } from '../../utils/caseFormattingUtil';

export class CaptionFormatter {
  /**
   * All of the punctuation formatters.
   *
   * Note: Their order is probably less critical, but any changes should be confirmed
   * with appropriate automated tests.
   */
  punctuationFormatters: PunctuationFormatter[] = [
    new MidSentencePunctuationFormatter({ command: 'bam bam', punctuation: ' [>>]' }),
    new MidSentencePunctuationFormatter({ command: 'comma', punctuation: ',' }),
    new EndOfSentencePunctuationFormatter({ command: 'peerk', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'peermak', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'kyumak', punctuation: '?' }),
    new EndOfSentencePunctuationFormatter({ command: 'quex', punctuation: '?' }),
    new EndOfSentencePunctuationFormatter({ command: 'sklam', punctuation: '!' }),
    new PunctuationWithSpacesFormatter({ command: 'apostrophe', punctuation: "'" }),
    new PunctuationWithSpacesFormatter({ command: 'hyphen', punctuation: '-' }),
    new MidSentencePunctuationFormatter({ command: 'double dash', punctuation: ' --' }),
    new MidSentencePunctuationFormatter({ command: 'colon', punctuation: ':' }),
    new EndOfSentencePunctuationFormatter({ command: 'peer mac', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'pier mac', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'pure mac', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'Permak', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'pirk', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'here Mac', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'pharmac', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'per mac', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'pyrmic', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'pyrmac', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'pyramac', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'pyramid', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'your mac', punctuation: '.' }),
    new EndOfSentencePunctuationFormatter({ command: 'q mac', punctuation: '?' }),
    new EndOfSentencePunctuationFormatter({ command: 'qmac', punctuation: '?' }),
    new EndOfSentencePunctuationFormatter({ command: 'cumach', punctuation: '?' }),
    new EndOfSentencePunctuationFormatter({ command: 'qemuch', punctuation: '?' }),
    new EndOfSentencePunctuationFormatter({ command: 'Qec', punctuation: '?' }),
    new EndOfSentencePunctuationFormatter({ command: 'qmet', punctuation: ',' }),
    new EndOfSentencePunctuationFormatter({ command: 'Tama', punctuation: ',' }),
    new EnclosingPunctuationFormatter({ command: 'bracket this', punctuation: '[]' }),
  ];

  /**
   * All of the formatters used in the CaptionFormatter pipeline
   *
   * Note: the order in which they are applied is critical to the end result.
   */
  formatters: Formatter[] = [
    new RemoveExcessWhitespaceFormatter(),
    ...this.punctuationFormatters,
    new CapitalizeStartFormatter(),
    new CapitalizeNewSentencesFormatter(),
    new ColonNumberFormatter(),
    new CapitalizeIPronounFormatter(),
    // TODO: Improve the fetching process and pass this value in from the CaptionPipeline
    new CapitalizeNamesFormatter({ casedNamesMap: casedNames as Record<string, string> }),
    new DecapitalizeAfterApostropheFormatter(),
    new AddLeadingWhitespaceFormatter(),
  ];

  public format(text: string, lastWordSent = '') {
    return this.pipe(this.formatters)(text, lastWordSent);
  }

  private pipe(formatters: Formatter[]) {
    return (text: string, lastWordSent: string) =>
      formatters.reduce((acc, formatter) => formatter.format(acc, lastWordSent), text);
  }
}
