import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import PleaseWait from '../../PleaseWait';

import SlideCheck from '~/lib/global/animations/SlideCheck';
import { PreviousButton } from '../ApplicationButtons';
import * as workflow from '../../../applicationWorkflow';

const ConfirmEmail = ({
  emailCode = '',
  email = '',
  confirmEmail = workflow.confirmEmail,
  sendConfirmationToken = workflow.sendEmailConfirmation,
  authToken = {},
  onSubmit = () => {},
  previousStep = () => {},
}) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [sendEmailKey, setSendEmailKey] = useState(null);

  useEffect(() => {
    emailCode ? handleFromEmailVerificationLink() : sendEmail();
  }, []);

  const resendConfirmation = (event) => {
    event.preventDefault();
    setSendEmailKey(new Date());
    sendEmail();
  };

  const sendEmail = () => {
    setErrors({});
    sendConfirmationToken({ authToken }).then((response) => {
      const { success, error } = response?.data?.sendEmailConfirmation
        ? response?.data?.sendEmailConfirmation
        : {};
      if (!success || error) {
        setErrors({
          resendToken:
            'Could not resend token! Try again later or reach out to us if the problem persists.',
        });
      }
    });
  };

  const handleNewApplication = (token) => {
    confirmEmail({ email, token })
      .then((response) => {
        setLoading(false);
        const success = response?.data?.confirmEmail?.success;
        if (!success) {
          setErrors({ confirmCode: "Hmm, that didn't quite work. Please try again!" });
          return;
        }

        onSubmit({ authToken: response.data.confirmEmail.authToken });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const submitToken = (token) => {
    setErrors({});
    setLoading(true);

    handleNewApplication(token);
  };

  const handleSubmit = () => {
    submitToken(code);
  };

  const handleFromEmailVerificationLink = () => {
    submitToken(emailCode);
  };

  return (
    <>
      {loading && <PleaseWait />}
      <div className="slide-up tight">
        <h2>Let&apos;s confirm we have the right email.</h2>
        <div className="primary-text mb-5">
          To continue with your application, check your email and enter the provided code into the
          field below or click on the link in the message to activate your account.
        </div>

        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="code">
              <Form.Label>Enter your code below</Form.Label>
              <Form.Control
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className={errors.confirmCode && 'is-invalid'}
                placeholder="Code here (ex: 193471)"
              />
              {errors.confirmCode && (
                <Form.Text className="text-danger">{errors.confirmCode}</Form.Text>
              )}
              {errors.resendToken && (
                <Form.Text className="text-danger">{errors.resendToken}</Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="code.submit">
              {/* Use &nbsp dummy label and display block button to align the button with the input box */}
              <Form.Label>&nbsp;</Form.Label>
              <Button variant="3play" className="d-block" disabled={loading} onClick={handleSubmit}>
                Submit Code
              </Button>
            </Form.Group>
          </Form.Row>
          <Form.Text className="text-muted">
            Not receiving a code? Check your email {email} or{' '}
            <a href="resend" size="small" variant="link" onClick={resendConfirmation}>
              resend confirmation email
            </a>
            <SlideCheck inline displayKey={sendEmailKey}>
              &nbsp;Sent!
            </SlideCheck>
          </Form.Text>
        </Form>

        <PreviousButton onClick={previousStep} />
      </div>
    </>
  );
};

ConfirmEmail.propTypes = {
  email: PropTypes.string,
  authToken: PropTypes.object,
  confirmEmail: PropTypes.func,
  sendConfirmationToken: PropTypes.func,
  onSubmit: PropTypes.func,
  previousStep: PropTypes.func,
  emailCode: PropTypes.string,
};

export default ConfirmEmail;
