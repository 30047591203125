import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Nav from 'react-bootstrap/Nav';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationTriangle,
  faExclamationCircle,
  faCheckCircle,
  faCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
library.add(faExclamationTriangle);
library.add(faExclamationCircle);
library.add(faCheckCircle);
library.add(faCircle);
library.add(faQuestionCircle);

import BasicAuth from './BasicAuth';
import LegalNotice from './LegalNotice';
import SingleSignOn from './SingleSignOn';

import Popover from 'react-bootstrap/Popover';
import PopoverContent from 'react-bootstrap/PopoverContent';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useCookies } from 'react-cookie';
import { userLogger } from '~/logic/UserLogger';

const ssoPopover = (
  <Popover id="sso_popover">
    <PopoverContent>
      3Play now supports integration of SAML 2.0 standard Single Sign-On services.
      <br />
      If you wish to enable SSO for your account, please contact your 3Play account manager.
    </PopoverContent>
  </Popover>
);

const Login = ({ csrfToken, missing_perishable_token, show_email_login, setSidePanel }) => {
  const [cookies, setCookie] = useCookies(['SigninTab']);
  const [activeTab, setActiveTab] = useState(cookies.SigninTab || 'basic');

  useEffect(() => {
    if (missing_perishable_token) {
      userLogger.logEvent('Login', 'Perishable Token Not Found');
    }
  }, []);

  function changeTab(newTab) {
    setCookie('SigninTab', newTab, { path: '/' });
    setActiveTab(newTab);
  }

  return (
    <>
      <div className={css(styles.nav)}>
        <Nav justify variant="tabs" onSelect={(val) => changeTab(val)}>
          <Nav.Item>
            <Nav.Link eventKey="basic" active={activeTab === 'basic'}>
              Login
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="sso" active={activeTab === 'sso'}>
              Single Sign-On
              <OverlayTrigger placement="right" overlay={ssoPopover} trigger={['hover', 'focus']}>
                <FontAwesomeIcon icon={faQuestionCircle} className="text-muted ml-2" />
              </OverlayTrigger>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      {activeTab == 'basic' && (
        <div className={css(styles.tabContents)}>
          <BasicAuth
            csrfToken={csrfToken}
            setSidePanel={setSidePanel}
            show_email_login={show_email_login}
          />
        </div>
      )}
      {activeTab == 'sso' && (
        <div className={css(styles.tabContents)}>
          <SingleSignOn csrfToken={csrfToken} />
        </div>
      )}
      <LegalNotice />
    </>
  );
};

Login.propTypes = {
  csrfToken: PropTypes.string,
  missing_perishable_token: PropTypes.bool,
  show_email_login: PropTypes.bool,
  setSidePanel: PropTypes.func,
};

const fadeIn = {
  from: {
    opacity: 0,
  },

  to: {
    opacity: 1,
  },
};

const slideUp = {
  from: {
    transform: 'translateY(10px)',
  },

  to: {
    transform: 'translateY(0)',
  },
};

const styles = StyleSheet.create({
  nav: {
    width: '100%',
    marginBottom: '3rem',
  },
  tabContents: {
    width: '100%',
    animationName: [fadeIn, slideUp],
    animationDuration: '0.2s, 0.1s',
    animationTimingFunction: 'ease, ease-out',
  },
});

export default Login;
