import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import { StyleSheet, css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import {
  transcriptListSpec,
  transcriptSpec,
} from '~/components/app/media_files/ShowPanel/data/propspec';

function SingleLanguage(props) {
  return (
    <div className={css(styles.languageGroup)}>
      <label className={css(styles.languageLabel)}>Language</label>
      {props.transcriptList[0].language.fullName + ' [#' + props.transcriptList[0].id + ']'}
    </div>
  );
}

SingleLanguage.propTypes = {
  transcriptList: transcriptListSpec,
};

function LanguageDropdown(props) {
  return (
    <div className={css(styles.languageGroup)}>
      <label htmlFor="dropdown-variants-secondary" className={css(styles.languageLabel)}>
        Language
      </label>
      {props.transcript && (
        <Dropdown style={{ display: 'inline-block', fontSize: 'initial' }}>
          <Dropdown.Toggle id="dropdown-variants-secondary" size="sm" variant="secondary">
            {props.transcript['language']['name']}
          </Dropdown.Toggle>
          <Dropdown.Menu className={css(styles.transcriptLanguageList)}>
            {props.transcriptList.map((transcriptItem) => (
              <Dropdown.Item
                key={transcriptItem.id}
                className={css(appStyles.dropdownLink)}
                onClick={() => props.updateTranscript(transcriptItem)}
              >
                {transcriptItem.language.fullName + ' [#' + transcriptItem.id + ']'}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}

LanguageDropdown.propTypes = {
  transcriptList: transcriptListSpec,
  transcript: transcriptSpec,
  updateTranscript: PropTypes.func,
};

function TranscriptLanguage(props) {
  return (
    <React.Fragment>
      {props.transcriptList.length > 1 && (
        <LanguageDropdown
          transcript={props.transcript}
          transcriptList={props.transcriptList}
          updateTranscript={props.updateTranscript}
        />
      )}
      {props.transcriptList.length === 1 && (
        <SingleLanguage transcriptList={props.transcriptList} />
      )}
    </React.Fragment>
  );
}

TranscriptLanguage.propTypes = {
  transcriptList: transcriptListSpec,
  transcript: transcriptSpec,
  updateTranscript: PropTypes.func,
};

const styles = StyleSheet.create({
  languageGroup: {
    float: 'right',
  },
  languageLabel: {
    display: 'inline-block',
    marginTop: '5px',
    marginRight: '8px',
    fontWeight: 'bold',
  },
  transcriptLanguageList: {
    overflowY: 'auto',
  },
});

export default TranscriptLanguage;
