import React from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import { palette } from '~/components/contractor_applications/theme';

function SingleStepNav(props) {
  return (
    <div className={'pl-3 ' + css(styles.stepContainer)}>
      <div className={css(styles.initialApplication)}>Initial Application</div>
      <div className={css(styles.step)}>
        Step {props.currentStep + 1} of {Object.keys(props.appSteps).length}:{' '}
        {props.appSteps[props.currentStep]}
      </div>
    </div>
  );
}

SingleStepNav.propTypes = {
  currentStep: PropTypes.number,
  appSteps: PropTypes.object,
};

SingleStepNav.defaultProps = {
  steps: [],
  currentStep: 0,
};

const styles = StyleSheet.create({
  stepContainer: {
    background: 'rgba(242, 242, 242, 1)',
  },
  step: {
    color: palette.primary,
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '700',
  },
  initialApplication: {
    textTransform: 'uppercase',
    fontWeight: '700',
  },
});

export default SingleStepNav;
