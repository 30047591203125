import { use3PQuery } from '~/logic/unstable/use3PQuery';

const QUERY = `
  query variantList($name: VariantsEnum!) {
    variants(name: $name) { id displayName }
  }
`;

export const useVariantList = (name: string) => {
  return use3PQuery(QUERY, {
    variables: { name: name },
    extractKey: 'variants',
  });
};
