/**
 * This logger is for logging events to Amplitude ONLY
 *
 * If you are looking to log to the console in development/test and to Datadog
 * and Airbrake in production, then use the Logger linked below instead
 *
 * @see ./Logger/Logger.ts
 *
 * @example
 * // Logs event to amplitude
 * userLogger.logEvent(group, event, properties);
 */
export const userLogger = window.userLogger || { logEvent: () => {} };
