import { difference, keyBy, omit } from 'lodash';

import { ENGLISH_ALPHABET } from './alphabetUtil';

const Mousetrap = require('mousetrap');

const EXCLUDED_CHARACTERS = ['N', 'T', 'R', 'W'];
export const VALID_CHARACTERS = difference(ENGLISH_ALPHABET, EXCLUDED_CHARACTERS);

function getFirstUnmappedCharacter(shortcuts) {
  // results in a map of keys for quick comparison e.g. {A: 'A', B: 'B', ...}
  const keysMappedOrBeingEdited = keyBy(
    shortcuts.reduce(
      (keys, { character, editedCharacter }) => [...keys, character, editedCharacter],
      []
    )
  );

  return VALID_CHARACTERS.map((key) => key.toLowerCase()).find(
    (key) => !keysMappedOrBeingEdited[key]
  );
}

const createNewKeyboardShortcut = (state, action) => {
  const unmappedCharacter = getFirstUnmappedCharacter(state);
  return [
    ...state,
    {
      character: unmappedCharacter,
      editedCharacter: unmappedCharacter,
      command: action.command || '',
      editedCommand: action.command || '',
      editing: true,
    },
  ];
};

const validateUpdate = (state, changedCharacter, changedIndex) => {
  const errors = [];

  if (
    state.some(
      (shortcut, index) => index !== changedIndex && shortcut.character === changedCharacter
    )
  ) {
    errors.push(`'Ctrl + ${changedCharacter}' is already assigned. Select a different key.`);
  }

  return errors;
};

export const keyboardShortcutsReducer = (state, action) => {
  switch (action.type) {
    case 'create':
      return createNewKeyboardShortcut(state, action);

    case 'delete':
      return state.filter((_, i) => i !== action.shortcutIndex);

    case 'edit':
      return state.map((shortcut, i) => {
        if (i === action.shortcutIndex) {
          return {
            ...omit(shortcut, 'error'),
            ...action.shortcut,
          };
        }
        return shortcut;
      });

    case 'toggleEditing':
      return state.map((shortcut, i) => {
        if (i === action.shortcutIndex) {
          return {
            ...omit(shortcut, 'error'),
            editing: !shortcut.editing,
            editedCharacter: shortcut.character,
            editedCommand: shortcut.command,
          };
        }
        return shortcut;
      });

    case 'update': {
      const { shortcutIndex } = action;
      const shortcutToUpdate = state[shortcutIndex];
      const errors = validateUpdate(state, shortcutToUpdate.editedCharacter, shortcutIndex);

      return state.map((shortcut, i) => {
        if (i === action.shortcutIndex) {
          if (errors.length) {
            return { ...shortcut, error: errors[0] };
          } else {
            Mousetrap.unbind(`ctrl+${shortcut.character}`);
            return {
              ...shortcut,
              character: shortcut.editedCharacter,
              command: shortcut.editedCommand,
              editing: false,
            };
          }
        }

        return shortcut;
      });
    }

    default:
      throw new Error('Unknown Action');
  }
};
