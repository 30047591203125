import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { threeplayApi } from '~/logic/ThreeplayApi';

function GetItNowModal(props) {
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [working, setWorking] = useState(false);

  function finishUrgently() {
    setWorking(true);
    threeplayApi.mutate(urgentFinishMutation(props.service.id)).then((response) => {
      setWorking(false);
      if (response.errors) {
        setSuccess(false);
        setErrors(response.errors);
      } else {
        const payload = response.data.urgentFinishMutation;
        if (payload.success === false) {
          setSuccess(false);
          setErrors(payload.errors || ['Something went wrong']);
        } else {
          setErrors([]);
          setSuccess(true);
          setTimeout(() => {
            handleClose();
            props.onUpdate();
            setSuccess(false);
          }, 3000);
        }
      }
    });
  }

  function urgentFinishMutation(serviceId) {
    return `urgentFinishMutation( serviceId: ${serviceId}) {
      success,
      errors,
    }`;
  }

  function handleClose() {
    props.onClose();
  }

  function handleGetItNow() {
    finishUrgently();
  }

  function showErrors() {
    return (
      <Alert variant="error" className="mb-2">
        We could not complete the request. Please contact support for further assistance.
      </Alert>
    );
  }

  function showSuccess() {
    return (
      <Alert variant="success" className="mb-2">
        We are finishing your transcripts and captions. They will be available shortly.
      </Alert>
    );
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Get It Now</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors.length > 0 && showErrors()}
        {success && showSuccess()}
        <p>
          This request will end any in-progress editing to make transcripts and captions available
          for download.
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button
          className="float-left"
          disabled={working || success}
          variant="primary"
          onClick={handleGetItNow}
        >
          Continue
        </Button>
        <Button className="float-right" variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

GetItNowModal.propTypes = {
  show: PropTypes.bool,
  service: PropTypes.object,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default GetItNowModal;
