const createSupportIssueMutation = `
  mutation CreateSupportIssue($eventId: ID!, $issue: String!) {
    createSupportIssueForLiveEvents(eventId: $eventId, issue: $issue) {
      error
    }
  }
`;

const endInProgressEventMutation = `
mutation EndInProgressLiveEvent($eventId: ID!) {
  endInProgressLiveEvent(eventId: $eventId) {
    error,
    success
  }
}`;

const endUpcomingEventMutation = `
mutation EndUpcomingLiveEvent($eventId: ID!, $eventCancellationOption: EventCancellationOption!) {
  endUpcomingLiveEvent(eventId: $eventId, eventCancellationOption: $eventCancellationOption) {
    error,
    success
  }
}`;

const updateZoomCaptionUrlForEventMutation = `
mutation UpdateZoomCaptionUrlForEventMutation($eventId: ID!, $zoomCaptionUrl: String!) {
  updateZoomCaptionUrlForEvent(eventId: $eventId,zoomCaptionUrl: $zoomCaptionUrl) {
    error,
    success
  }
}`;

const updateZoomCaptionUrlMutation = `
mutation UpdateZoomCaptionUrlMutation($zoomCaptionUrl: String!) {
  updateZoomCaptionUrl(zoomCaptionUrl: $zoomCaptionUrl) {
    error,
    success
  }
}`;

export {
  createSupportIssueMutation,
  endInProgressEventMutation,
  endUpcomingEventMutation,
  updateZoomCaptionUrlForEventMutation,
  updateZoomCaptionUrlMutation,
};
