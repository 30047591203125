import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { translationReviewPath } from '~/helpers/app/paths';
import { titleize } from '~/helpers/strings';

import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
} from '~/components/app/live_auto_captioning/common/constants';
import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';
import MultiServiceTable from './MultiServiceTable';

function reviewLink(service) {
  const translation = service.translationOrder || {};

  if (service.status !== 'reviewable') {
    return;
  }
  if (!translation) {
    return;
  }

  return <a href={translationReviewPath(service.mediaFileId, translation.id)}>Review</a>;
}

function TranslationServiceCard(props) {
  // Localization uses this same service card. If adding new fields to this view, update LocalizationServiceType to support them
  // or nil-safe them here if they're not necessary for Localization.
  const headers = [null, 'Language', 'Ordered', 'Level', 'Status', 'Vendor', null];
  return (
    <MultiServiceTable headers={headers}>
      {props.services.map((service) => (
        <tr key={service.id}>
          <td>
            {service.type === 'TranslationService' && service.deadline && (
              <ThreePlayTooltip
                tooltipText={
                  <div>
                    <b>Due Date:</b>{' '}
                    {moment(parseInt(service.deadline)).tz(props.userTimeZone).format(DATE_FORMAT)}
                  </div>
                }
                appearInTabNavigation
              />
            )}
          </td>
          <td>{service.targetLanguage.fullName}</td>
          <td>
            {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
          </td>
          <td>{service.serviceLevel}</td>
          <td>{titleize(service.status)}</td>
          <td>{service.vendor}</td>
          <td>{service.status === 'reviewable' && reviewLink(service)}</td>
        </tr>
      ))}
    </MultiServiceTable>
  );
}

TranslationServiceCard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  userTimeZone: PropTypes.string,
};

export default TranslationServiceCard;
