import { Logger } from '~/logic/Logger/Logger';

const DEFAULT_AUDIO_FILE_INTERVAL = 30000;
const DEFAULT_MEDIA_RECORDER_OPTIONS = {
  mimeType: `audio/webm; codecs="opus"`,
  audioBitsPerSecond: 128000,
};

interface AudioRecorderOptions {
  audioFileInterval?: number;
  mediaRecorderOptions: Partial<MediaRecorderOptions>;
}

export class AudioRecorder {
  private recorder: MediaRecorder | null = null;
  private chunks: Blob[] = [];
  private audioFileInterval: number;
  private intervalId: number | null = null;
  private audioStream: MediaStream | null = null;
  public onAudioFile: (file: File) => Promise<void> | void;
  public mediaRecorderOptions: MediaRecorderOptions;

  constructor(
    onAudioFile: (file: File) => Promise<void> | void,
    options: Partial<AudioRecorderOptions> = { mediaRecorderOptions: {} }
  ) {
    const { audioFileInterval, mediaRecorderOptions } = options;
    this.onAudioFile = onAudioFile;
    this.audioFileInterval = audioFileInterval ?? DEFAULT_AUDIO_FILE_INTERVAL;
    this.mediaRecorderOptions = {
      ...DEFAULT_MEDIA_RECORDER_OPTIONS,
      ...mediaRecorderOptions,
    };
  }

  /**
   * Starts a recording session. Each session will listen to a single audio stream
   * and create a new file every `audioFileInterval` milliseconds. Since MediaRecorders
   * cannot be started after they have been stopped, this method will start periodically
   * recreating the MediaRecorder with the same audio stream, until the stopRecording method
   * is called.
   */
  public async startRecording() {
    Logger.getInstance().info({ message: 'AudioRecorder: startRecording' });

    if (this.navigatorSupportsMediaRecorder()) {
      // We ensure that the recorder is stopped before starting a new recording session.
      this.stopRecording();

      try {
        this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.setupMediaRecorder(this.audioStream);
        this.startPeriodicFileCreation();
      } catch (error) {
        Logger.getInstance().error({
          message: 'AudioRecorder: error starting recording',
          info: { error },
        });
      }
    } else {
      Logger.getInstance().warn({
        message: `AudioRecorder: navigator does not support media recorder`,
      });
    }
  }

  public stopRecording() {
    if (this.recorder === null) {
      return;
    }

    Logger.getInstance().info({ message: 'AudioRecorder: stopRecording' });
    this.stopPeriodicFileCreation();
    this.recorder.stop();
    this.releaseAudioStream();
  }

  private setupMediaRecorder(audioStream: MediaStream) {
    this.recorder = new MediaRecorder(audioStream, this.mediaRecorderOptions);
    this.recorder.ondataavailable = this.handleDataAvailable;
    this.recorder.onstop = this.handleStop;
    this.chunks = [];
    this.recorder.start();
  }

  private startPeriodicFileCreation() {
    this.intervalId = window.setInterval(() => {
      if (this.recorder && this.recorder.state !== 'inactive') {
        this.recorder.stop();
        this.setupMediaRecorder(this.recorder.stream);
      }
    }, this.audioFileInterval);
  }

  private stopPeriodicFileCreation() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private handleDataAvailable = (e: BlobEvent) => {
    if (e.data?.size > 0) {
      this.chunks.push(e.data);
    }
  };

  private handleStop = () => {
    if (this.chunks.length > 0) {
      const file = new File(this.chunks, `temp_audio_${Date.now()}.webm`, {
        type: this.mediaRecorderOptions.mimeType,
      });
      try {
        void this.onAudioFile(file);
      } catch (error) {
        Logger.getInstance().error({
          message: 'AudioRecorder: error onAudioFile',
          info: { error },
        });
      }
      this.chunks = [];
    }
  };

  private navigatorSupportsMediaRecorder = () => {
    return Boolean(navigator.mediaDevices?.getUserMedia !== undefined);
  };

  private releaseAudioStream() {
    if (this.audioStream) {
      this.audioStream.getTracks().forEach((track) => track.stop());
      this.audioStream = null;
    }
  }
}
