export const WARNING_DEFAULT_STATE = {
  broadcastScripts: false,
  dubbing: false,
  translation: false,
  voiceArtistAD: false,
};

const warningReducer = (currentWarnings, action) => {
  switch (action.type) {
    case 'toggle': {
      return {
        ...currentWarnings,
        [action.warning]: !currentWarnings[action.warning],
      };
    }
  }
};

export default warningReducer;
