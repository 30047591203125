import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import InputErrorList from '~/components/app/common/InputErrorList';

import { threeplayApi } from '~/logic/ThreeplayApi';

const batchQuery = 'batches { id name }';
function moveToFolderMutation(fileId, batchId) {
  return `moveFileToFolder(
    fileId: ${fileId}, batchId: ${batchId}
  ) { errors, file { id } }`;
}

function BatchList(props) {
  return (
    <>
      <b>Folder Name</b>
      <Form>
        <Table striped bordered>
          <tbody>
            {props.batches.map((batch) => (
              <tr key={batch.id}>
                <td>
                  <Form.Check
                    id={`batch-${batch.id}`}
                    value={batch.id}
                    checked={props.selected === batch.id}
                    onChange={() => props.onChange(batch.id)}
                    type="radio"
                    label={batch.name}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Form>
    </>
  );
}

BatchList.propTypes = {
  batches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

function MoveToFolderModal(props) {
  const [batches, setBatches] = useState(null);
  const [errors, setErrors] = useState([]);
  const [moving, setMoving] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);

  const file = props.fileData || {};

  useEffect(() => {
    if (!props.show) {
      return;
    }
    if (batches) {
      return;
    }

    let cancelled = false;
    async function fetchBatches() {
      const response = await threeplayApi.query(batchQuery, 'Batches');
      if (cancelled) {
        return;
      }
      setBatches(response.data.batches);
    }

    fetchBatches();
    return () => {
      cancelled = true;
    };
  }, [props.show]);

  async function move() {
    if (!file.id || !selectedBatch) {
      return;
    }
    setMoving(true);
    const { errors } = await threeplayApi.mutate(moveToFolderMutation(file.id, selectedBatch));
    if (errors > 0) {
      setErrors(errors);
      setMoving(false);
      return;
    }
    props.onUpdate();
    props.onClose();
    setMoving(false);
  }

  return (
    <Modal show={props.show} onHide={props.onClose} dialogClassName={css(styles.modal)}>
      <Modal.Header closeButton>
        <Modal.Title>Move to Folder</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
        {batches === null ? (
          <p>Loading...</p>
        ) : (
          <>
            <BatchList batches={batches} selected={selectedBatch} onChange={setSelectedBatch} />
            {errors && <InputErrorList errors={errors} />}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className={css(styles.manageFolders)}>
          <Button variant="secondary">Manage Folders</Button>
        </div>
        {moving ? (
          <Button variant="primary" onClick={move} disabled={true}>
            Moving...
          </Button>
        ) : (
          <>
            <Button variant="secondary" onClick={props.onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={move} disabled={!selectedBatch}>
              Move
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

MoveToFolderModal.propTypes = {
  fileData: PropTypes.shape({
    id: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

const styles = StyleSheet.create({
  manageFolders: {
    flex: 1,
  },
  modal: {},
});

export default MoveToFolderModal;
