import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import RuleDisplay from './RuleDisplay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import PopoverContent from 'react-bootstrap/PopoverContent';
import PopoverTitle from 'react-bootstrap/PopoverTitle';
import { validatePassword } from '~/logic/PasswordValidator';

import { signoutPath } from '~/helpers/paths';

function LoginManagerForm(props) {
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [username, setUsername] = useState(props.login ? props.login.split('@')[0] : '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState([]);

  const passwordPopover = (
    <Popover id="password_popover">
      <PopoverTitle>Your password must have:</PopoverTitle>
      <PopoverContent>
        <RuleDisplay rules={passwordCheck.map(({ ok, rule }) => ({ ok, rule }))} />
      </PopoverContent>
    </Popover>
  );

  useEffect(() => {
    setPasswordCheck(validatePassword(password).results);
  }, [password]);

  function formData(form) {
    const data = new FormData(form);
    data.append('authenticity_token', props.csrfToken);
    return data;
  }

  async function attemptLogin(form) {
    const data = formData(form);
    const response = await fetch(props.submitPath, {
      method: 'POST',
      body: data,
      credentials: 'same-origin',
    });
    if (!response.ok) {
      setUnknownError();
      return { ok: false };
    }

    const responseData = await response.json();

    if (!responseData.ok) {
      if (responseData.error?.type === 'user') {
        // The error message is coming from Cognito, so we can't control it
        // We use "Username" instead of "Login" in our UI so in this
        // case, we'll update the message.
        let err_msg = responseData.error.message;
        if (err_msg == 'Login has already been taken') {
          err_msg = err_msg.replace('Login', 'Username');
        }
        setError({ message: err_msg });
      } else {
        setUnknownError();
      }
      return { ok: false };
    }
    return responseData;
  }

  function getFormErrors() {
    let errors = [];
    if (props.loginTask == 'NewUser') {
      if (username.length === 0) {
        errors.push({ location: 'username', message: 'This field cannot be empty' });
      }
      if (!/^[a-zA-Z0-9_]+$/.test(username)) {
        errors.push({
          location: 'username',
          message: 'Username may only contain letters, numbers, and underscores.',
        });
      }
      if (username.length > 30) {
        errors.push({
          location: 'username',
          message: 'Username cannot be longer than 30 characters.',
        });
      }
    }
    if (confirmPassword !== password) {
      errors.push({
        location: 'confirmPassword',
        message: "Those passwords don't match. Please try again.",
      });
    }
    if (passwordCheck.some((result) => !result.ok)) {
      errors = errors.concat({
        location: 'password',
        message: 'Password does not meet requirements',
      });
    }
    return errors;
  }

  function validateForm() {
    const errors = getFormErrors();
    const currentFormErrors = {};
    if (errors.length > 0) {
      errors.forEach(({ location, message }) => {
        if (!currentFormErrors[location]) {
          currentFormErrors[location] = [];
        }
        currentFormErrors[location].push(message);
      });
    }
    setFormErrors(currentFormErrors);

    return { ok: errors.length === 0 };
  }

  function setUnknownError() {
    setError({
      title: 'Something went wrong',
      message: 'Please try again. Contact support@3playmedia.com if this issue persists.',
    });
  }

  function submitName() {
    switch (props.loginTask) {
      case 'NewUser':
        return 'Create Your Account';
      case 'PasswordReset':
      case 'MigrateUser':
        return 'Update Password';
      default:
        return 'Submit';
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;

    const { ok } = validateForm();
    if (!ok) {
      return;
    }

    setFetching(true);
    const response = await attemptLogin(form);

    if (response.ok) {
      window.location.href = response.redirect;
    } else {
      setFetching(false);
    }
  }

  return (
    <div className="w-100">
      {error && (
        <Alert variant="danger" dismissible onClose={() => setError(null)}>
          {error.title && (
            <Alert.Heading>
              <FontAwesomeIcon icon={faExclamationTriangle} /> {error.title}
            </Alert.Heading>
          )}
          {error.message}
        </Alert>
      )}
      <div className={css(styles.formContainer)}>
        <Form onSubmit={handleSubmit}>
          <input type="hidden" name="perishable_token" value={props.perishableToken} />
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              readOnly
              type="text"
              disabled={fetching}
              defaultValue={props.email}
            />
            <Form.Text className="text-muted">
              Not {props.email}? <a href={signoutPath}>Sign out</a>.
            </Form.Text>
          </Form.Group>
          {['NewUser', 'MigrateUser'].includes(props.loginTask) && (
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                name="username"
                isInvalid={formErrors.username}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                disabled={fetching}
                placeholder="Username"
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.username?.map((message, index) => (
                  <React.Fragment key={index}>
                    <FontAwesomeIcon icon={faExclamationCircle} /> {message}
                    <br />
                  </React.Fragment>
                ))}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                Usernames may only contain letters, numbers, and underscores.
              </Form.Text>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <OverlayTrigger
              placement="right"
              overlay={passwordPopover}
              trigger={['hover', 'focus']}
            >
              <Form.Control
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={formErrors.password}
                type="password"
                disabled={fetching}
                placeholder="Password"
              />
            </OverlayTrigger>
            <Form.Control.Feedback type="invalid">
              {formErrors.password?.map((message, index) => (
                <React.Fragment key={index}>
                  <FontAwesomeIcon icon={faExclamationCircle} /> {message}
                  <br />
                </React.Fragment>
              ))}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              name="password_confirmation"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              isInvalid={formErrors.confirmPassword}
              type="password"
              disabled={fetching}
              placeholder="Confirm Password"
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.confirmPassword?.map((message, index) => (
                <React.Fragment key={index}>
                  <FontAwesomeIcon icon={faExclamationCircle} /> {message}
                  <br />
                </React.Fragment>
              ))}
            </Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" block disabled={fetching}>
            {fetching ? 'Please wait' : submitName()}
          </Button>
        </Form>
      </div>
    </div>
  );
}

LoginManagerForm.propTypes = {
  csrfToken: PropTypes.string,
  email: PropTypes.string,
  login: PropTypes.string,
  perishableToken: PropTypes.string,
  submitPath: PropTypes.string,
  loginTask: PropTypes.oneOf(['NewUser', 'PasswordReset', 'MigrateUser']),
};

const styles = StyleSheet.create({
  formContainer: {
    width: '100%',
  },
});

export default LoginManagerForm;
