import React from 'react';
import PropTypes from 'prop-types';
import MediaFileModal from './MediaFileModal';
import { Row, Col, InputGroup, FormControl, Form, Button } from 'react-bootstrap';

const COLUMN_HEIGHT = 2;

class AudioDescriptionFormats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFormats: this.props.selectedFormats,
      allSelected: false,
      formatType: this.props.formatType,
      searchTerm: '',
      showModal: false,
    };
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggleChecked(event, adType) {
    const format = event.target.name;
    const formatObj = this.props.adFormatTypes[adType]['formats'].find((f) => f.name === format);
    if (this.props.selectedFormats.includes(formatObj)) {
      this.setState({ allSelected: false });
    }
    this.props.toggleSelectedFormat(this.state.formatType, formatObj);
  }

  allSelected() {
    let adFormatCount = 0;
    for (let i = 0; i < Object.keys(this.props.adFormatTypes).length; i++) {
      const adType = Object.keys(this.props.adFormatTypes)[i];
      if (adType == 'selectedFormats' || this.props.adFormatTypes[adType]['formats'] == undefined)
        continue;
      adFormatCount += this.props.adFormatTypes[adType]['formats'].length;
    }
    if (adFormatCount === 0) {
      return false;
    }
    return this.props.selectedFormats.length === adFormatCount;
  }

  toggleSelectAll() {
    const allSelected = this.allSelected();
    for (let i = 0; i < Object.keys(this.props.adFormatTypes).length; i++) {
      const adType = Object.keys(this.props.adFormatTypes)[i];
      if (!this.props.adFormatTypes[adType] || !this.props.adFormatTypes[adType]['formats'])
        continue;
      const formats = this.props.adFormatTypes[adType]['formats'];
      for (let j = 0; j < formats.length; j++) {
        var format = formats[j];
        if (allSelected) {
          this.props.toggleSelectedFormat(this.state.formatType, format);
        } else {
          if (this.props.selectedFormats.find((f) => f['id'] === format['id']) == undefined) {
            this.props.toggleSelectedFormat(this.state.formatType, format);
          }
        }
      }
    }
  }

  buildColumns(formats, selectedFormats, adType) {
    const columns = [];
    if (formats.length == 0) {
      columns.push(<i className="text-muted">No Results</i>);
      return columns;
    }
    for (let i = 0, j = formats.length; i < j; i += COLUMN_HEIGHT) {
      const column = [];
      var slicedFormatsArray = formats.slice(i, i + COLUMN_HEIGHT);
      for (var k = 0; k < slicedFormatsArray.length; k++) {
        const checked =
          Object.keys(selectedFormats).find(
            (key) => selectedFormats[key]['id'] == slicedFormatsArray[k]['id']
          ) != undefined;
        column.push(
          <Form.Group
            key={'format-checkbox-' + slicedFormatsArray[k]['id']}
            controlId={'formatCheckbox-' + this.state.formatType + slicedFormatsArray[k]['id']}
          >
            <Form.Check
              name={slicedFormatsArray[k]['name']}
              type="checkbox"
              label={slicedFormatsArray[k]['name']}
              onChange={(e) => this.toggleChecked(e, adType)}
              checked={checked}
            />
          </Form.Group>
        );
      }
      columns.push(column.map((col) => col));
    }
    return columns;
  }

  filterFormatsBySearch(formats) {
    if (this.state.searchTerm === '') {
      return formats;
    }
    const filteredFormats = [];
    for (let i = 0; i < formats.length; i++) {
      if (formats[i]['name'].toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
        filteredFormats.push(formats[i]);
      }
    }
    return filteredFormats;
  }

  buildAdSections() {
    const sections = [];
    for (let i = 0; i < Object.keys(this.props.adFormatTypes).length; i++) {
      if (
        Object.keys(this.props.adFormatTypes)[i] == 'selectedFormats' ||
        Object.keys(this.props.adFormatTypes)[i] == 'formats'
      )
        continue;
      const adType = Object.keys(this.props.adFormatTypes)[i];
      sections.push(
        <h3 key={'ad-title-' + this.props.adFormatTypes[adType]['title']}>
          {this.props.adFormatTypes[adType]['title']}
        </h3>
      );
      if (!this.props.adFormatTypes[adType]['formats']) continue;
      const columns = this.buildColumns(
        this.filterFormatsBySearch(this.props.adFormatTypes[adType]['formats']),
        this.props.selectedFormats,
        adType
      );
      sections.push(
        <Row key={'ad-row-' + i}>
          {columns.map((col, index) => (
            <Col key={'checkbox-column-' + index}>{col}</Col>
          ))}
        </Row>
      );
    }
    return sections;
  }

  getAdMediaFiles() {
    const files = {};
    for (let i = 0; i < Object.keys(this.props.mediaFiles).length; i++) {
      var fileId = Object.keys(this.props.mediaFiles)[i];
      var file = this.props.mediaFiles[fileId];
      file['services'].map((service) => {
        if (service.match('Audio Description') !== null) {
          files[fileId] = file;
        }
      });
    }
    return files;
  }

  getAdNote() {
    const denominator = Object.keys(this.props.mediaFiles).length;
    const numerator = Object.keys(this.getAdMediaFiles()).length;
    const fraction = numerator.toString() + '/' + denominator.toString();
    return (
      <>
        <strong>Note:</strong> Audio Description will only be downloaded for{' '}
        <a href="#" onClick={this.handleShowModal}>
          {fraction} files
        </a>
      </>
    );
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleShowModal() {
    this.setState({ showModal: true });
  }

  render() {
    const sections = this.buildAdSections();
    const noteStyle = { paddingBottom: '10px' };
    const adMediaFiles = this.getAdMediaFiles();
    return (
      <React.Fragment>
        <MediaFileModal
          title="Audio Description Files"
          mediaFiles={adMediaFiles}
          show={this.state.showModal}
          handleCloseModal={this.handleCloseModal}
        />

        <Row style={noteStyle}>
          <Col>
            <small>{this.getAdNote()}</small>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text className="search-icon" id="search-input-addon">
                  <i className="fa fa-search" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className="formats-search-box"
                onChange={(e) => this.setState({ searchTerm: e.target.value })}
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={this.state.searchTerm}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary clear-search-box"
                  onClick={() => this.setState({ searchTerm: '' })}
                >
                  <i className="fa fa-times" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col>
            {
              <Form>
                <Form.Group controlId={'formSelectAllCheckbox-' + this.props.formatType}>
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    onChange={() => this.toggleSelectAll()}
                    checked={this.allSelected()}
                  />
                </Form.Group>
              </Form>
            }
          </Col>
        </Row>
        <>{sections}</>
      </React.Fragment>
    );
  }
}

AudioDescriptionFormats.propTypes = {
  formatType: PropTypes.string,
  selectedFormats: PropTypes.array,
  adFormatTypes: PropTypes.object,
  toggleSelectedFormat: PropTypes.func,
  mediaFiles: PropTypes.object,
};
export default AudioDescriptionFormats;
