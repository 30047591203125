import React from 'react';
import PropTypes from 'prop-types';

import DarkOverlay from './DarkOverlay';

function LoadingOverlay(props) {
  return (
    <DarkOverlay>
      <div className="light-loader">
        <div className="loader">
          <div className="loading-logo">
            <span></span>
          </div>
          <div className="loading-text" data-loading-message={props.message}></div>
        </div>
      </div>
    </DarkOverlay>
  );
}

LoadingOverlay.propTypes = {
  message: PropTypes.string,
};

export default LoadingOverlay;
