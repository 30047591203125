import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sidebar', 'toggle'];
  static values = { showSidebar: Boolean, toggleContent: String };

  toggleContentValueChanged() {
    this.toggleTargets.forEach((element) => {
      element.textContent = this.toggleContentValue;
    });
  }

  showSidebarValueChanged() {
    this.sidebarTargets.forEach((element) => {
      element.classList.toggle('hidden', !this.showSidebarValue);
    });
  }

  toggleSidebar() {
    this.showSidebarValue = !this.showSidebarValue;
    this.toggleContentValue = this.showSidebarValue ? 'Hide Filters' : 'Show Filters';
  }
}
