import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Button } from 'react-bootstrap';
import dropin from 'braintree-web-drop-in';

import { secureAddCreditCardPath } from '~/helpers/app/paths';

const HostedCreditCardForm = ({
  authenticityToken,
  clientToken,
  closeAddCardSection,
  fetchManagePlanData,
}) => {
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(!braintreeInstance);

  useEffect(() => {
    const initializeBraintree = () =>
      dropin.create(
        {
          authorization: `${clientToken}`,
          container: '#dropin',
          card: {
            cardholderName: {
              required: true,
            },
          },
        },
        function (error, instance) {
          if (error) {
            setSubmitEnabled(false);
          } else {
            setBraintreeInstance(instance);
            setSubmitEnabled(true);
          }
        }
      );

    if (braintreeInstance) {
      braintreeInstance.teardown().then(() => {
        initializeBraintree();
      });
    } else {
      initializeBraintree();
    }
  }, []);

  const saveCard = () => {
    setSubmitEnabled(false);
    braintreeInstance.requestPaymentMethod((error, payload) => {
      if (error) {
        setSubmitEnabled(true);
      } else {
        const nonce = payload.nonce;
        const cardholderName = payload.details.cardholderName;
        const lastFour = payload.details.lastFour;

        const data = new FormData();
        data.append('authenticity_token', authenticityToken);
        data.append('paymentMethodNonce', nonce);
        data.append('cardholderName', cardholderName);
        data.append('lastFour', lastFour);
        fetch(secureAddCreditCardPath, {
          method: 'POST',
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              closeAddCardSection();
              fetchManagePlanData();
            } else {
              setErrorMessage('Unable to validate credit card information');
            }
          });
      }
    });
  };

  return (
    <div id="wrapper">
      {errorMessage.length > 0 && <Alert variant="danger">{errorMessage}</Alert>}
      <div id="dropin"></div>
      <Button disabled={!submitEnabled} onClick={saveCard}>
        Add Card
      </Button>
    </div>
  );
};

HostedCreditCardForm.propTypes = {
  authenticityToken: PropTypes.string,
  clientToken: PropTypes.string,
  closeAddCardSection: PropTypes.func,
  fetchManagePlanData: PropTypes.func,
};

export default HostedCreditCardForm;
