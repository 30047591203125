export function getUrlParams(queryString = window.location.search.slice(1)) {
  const params = Object.fromEntries(
    queryString
      .split('&')
      .filter((str) => str)
      .map((str) => {
        const [name, encodedValue] = str.split('=');
        const value = decodeURIComponent(encodedValue);
        return [name, value];
      })
  );
  return params;
}

// Use replaceState to set the current window location param value
export function setUrlParams(key, value) {
  const url = window.location.href;
  const [, base, queryString, fragment] = url.match(/([^?#]+)(?:\?([^?#]*))?(?:#([^?#]*))?/);
  const params = getUrlParams(queryString);
  if (value === null) {
    delete params[key];
  } else {
    params[key] = encodeURIComponent(value);
  }
  const newQueryString = Object.keys(params)
    .filter((key) => params.hasOwnProperty(key))
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  const newLocation = `${base}?${newQueryString}${fragment ? `#${fragment}` : ''}`;
  window.history.replaceState(null, null, newLocation);
}

export function getUrlParam(param) {
  const params = getUrlParams();
  if (params.hasOwnProperty(param)) {
    return params[param];
  }
  return null;
}
