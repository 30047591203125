import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ChallengeBadge from './ChallengeBadge';
import { formatDates } from './utils/utils';
import { DEFAULT_COPY, MEMBERSHIP_STATES } from './constants';

const ChallengeCard = ({
  id = null,
  name = '',
  description = null,
  endTime = null,
  membershipState = '',
  completedAt = null,
  onButtonClick,
}) => {
  const handleClick = () => {
    onButtonClick(id);
  };

  const isEligible = membershipState === MEMBERSHIP_STATES.eligible && !completedAt;
  const isInProgress = membershipState === MEMBERSHIP_STATES.accepted && !completedAt;

  return (
    <Col md="auto" data-testid="challenge-card">
      <div className={css(styles.challengeCard)}>
        <ChallengeBadge />
        <h3 className={css(styles.h3)}>{name}</h3>
        <Row>
          <p className={css(styles.p)}>
            {description ? description : DEFAULT_COPY.noChallengeDescription}
          </p>
        </Row>
        <Row>
          {completedAt ? (
            <p className={css(styles.p, styles.uppercase)}>
              <b>{!!completedAt && `Completed ${formatDates(completedAt)}`}</b>
            </p>
          ) : (
            <p className={css(styles.p, styles.uppercase)}>
              <b>{!!endTime && `Closes ${formatDates(endTime)}`}</b>
            </p>
          )}
        </Row>
        {isEligible && <Button onClick={handleClick}>Learn More</Button>}
        {isInProgress && (
          <Button variant="success" onClick={handleClick}>
            View Progress
          </Button>
        )}
      </div>
    </Col>
  );
};

ChallengeCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  endTime: PropTypes.string,
  completedAt: PropTypes.string,
  membershipState: PropTypes.string,
  onButtonClick: PropTypes.func,
};

const styles = StyleSheet.create({
  challengeCard: {
    width: '150px',
    textAlign: 'center',
    margin: '15px',
  },
  h3: {
    marginTop: '5px',
  },
  p: {
    width: '100%',
    marginBottom: '.5rem',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

export default ChallengeCard;
