import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as services from './services/services';
import ReferredApplicantsTable from './ReferredApplicantsTable';
import { dateToMMDDYY } from '../util/DateHelpers';

const formatDate = (date) => dateToMMDDYY(date);

const shapeData = (data) =>
  data &&
  data.map((applicant) => {
    const contractorReferral = applicant?.contractorReferral;
    const contractor = contractorReferral?.contractor;

    return {
      applicantId: applicant?.id,
      applicant: applicant?.name,
      contractor: contractor?.name,
      applicantState: applicant?.state,
      referralState: contractorReferral?.state,
      hoursTarget: contractorReferral?.hoursTarget,
      payout: contractorReferral?.payout,
      createdAt: formatDate(contractorReferral?.createdAt),
      updatedAt: formatDate(contractorReferral?.updatedAt),
      expiresAt: formatDate(contractorReferral?.expiresAt),
      completedAt: formatDate(contractorReferral?.completedAt),
    };
  });

const ReferredApplicants = ({ getReferredApplicants = services.getReferredApplicants }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    getReferredApplicants()
      .then(({ errors, data }) => {
        setLoading(false);

        if (errors) {
          setError(errors);
        }

        return data;
      })
      .then((data) => {
        const items = data?.applicants?.items;
        const formattedData = shapeData(items);
        setData(formattedData);
      });
  }, []);

  return (
    <div data-testid="referred-applicants">
      {error && <>Something went wrong while fetching data.</>}
      {loading && <>loading</>}
      {!loading && !error && <ReferredApplicantsTable data={data} />}
    </div>
  );
};

ReferredApplicants.propTypes = {
  getReferredApplicants: PropTypes.func,
};

export default ReferredApplicants;
