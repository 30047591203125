import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

function ErrorOverlay(props) {
  return (
    <div className={css(styles.body)}>
      <div className={css(styles.mainMessage)}>Something went wrong.</div>
      {props.messages && (
        <div className={css(styles.messages)}>
          {props.messages.map((message, ii) => (
            <div key={ii}>{message}</div>
          ))}
        </div>
      )}
    </div>
  );
}

ErrorOverlay.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
};

const styles = StyleSheet.create({
  body: {
    width: '100%',
    height: '100%',
    color: '#ADADAD',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainMessage: {
    padding: 'auto',
    fontSize: '22px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messages: {
    flex: 4,
  },
});

export default ErrorOverlay;
