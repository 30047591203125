import React from 'react';
import PropTypes from 'prop-types';

import ReferredApplicantsTable from './ReferredApplicantsTable';

const ReferralStatus = ({ referralLimit = 0, referrals = [] }) => {
  const referralsCount = referrals.length;
  const hasRemainingReferrals = referralLimit > referralsCount;

  return (
    <>
      <h2>Referral Status</h2>
      <p>
        <b>
          {hasRemainingReferrals
            ? `You have used ${referralsCount} of your ${referralLimit} referrals!`
            : `You have used all of your ${referralLimit} referrals!`}
        </b>
      </p>
      <p>Anyone who enters your code when applying to 3Play will count as a referral. </p>

      <p>
        Once they’ve completed the application, you can see how it’s going with their completion
        percentage. Feel free to reach out with encouragement as they’re making their way toward
        their first 10 hours of captioning!
      </p>
      {!!referralsCount && <ReferredApplicantsTable data={referrals} />}
    </>
  );
};

ReferralStatus.propTypes = {
  referralLimit: PropTypes.number,
  referrals: PropTypes.array,
};

export default ReferralStatus;
