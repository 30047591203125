import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import ContractorReferralsTable from './ContractorReferralsTable';
import CreateNewReferralCodeModal from './CreateNewReferralCodeModal';
import * as services from './services/services';

const ContractorsWithReferralCodes = ({
  getContractorReferralsCodes = services.getContractorReferralsCodes,
  contractors = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setLoading(true);

    getContractorReferralsCodes().then(({ errors, data }) => {
      if (!isMounted.current) {
        return;
      }

      setLoading(false);

      if (errors) {
        setError(errors);
        return;
      }

      setData(data?.contractorReferralCodes?.items);
    });
  }, []);

  return (
    <div data-testid="contractors-with-referral-codes">
      <Button onClick={() => setShowModal(true)}>Add New Contractor</Button>

      <CreateNewReferralCodeModal
        contractors={contractors}
        show={showModal}
        closeModal={() => setShowModal(false)}
      />

      {error && <>Something went wrong while fetching data.</>}
      {loading && <>loading</>}

      {!loading && !error && <ContractorReferralsTable referralCodes={data} />}
    </div>
  );
};

ContractorsWithReferralCodes.propTypes = {
  getContractorReferralsCodes: PropTypes.func,
  contractors: PropTypes.array,
};

export default ContractorsWithReferralCodes;
