import React from 'react';
import PropTypes from 'prop-types';
import EngagementProgramChallenge from './EngagementProgramChallenge';
import InternationalEngagementProgram2024Copy from './InternationalEngagementProgram2024Copy';

const EngagementProgram = ({ engagementProgram }) => {
  return (
    <div>
      <h2> {engagementProgram.displayName} </h2>

      <InternationalEngagementProgram2024Copy />

      <br />
      {engagementProgram.challenges.map((challenge, index) => {
        return <EngagementProgramChallenge key={index} challenge={challenge} />;
      })}
    </div>
  );
};

EngagementProgram.propTypes = {
  engagementProgram: PropTypes.object.isRequired,
};

export default EngagementProgram;
