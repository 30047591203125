import { Logger } from '~/logic/Logger/Logger';
import { Caption } from '../CaptionCreator/Caption';
import { RepeatedCharactersMatcher } from './RepeatedCharactersMatcher';
import { RepeatedWordsMatcher } from './RepeatedWordsMatcher';
import { CuriousCaptionMatcher } from './_types';

const NUMBER_OF_REPEATED_WORDS_TO_MATCH_ON = 3;

/**
 * A class to check for and potentially log captions that are out of the
 * ordinary (ie: repeated words)
 */
export class CuriousCaptionChecker {
  /** The set of curious caption matchers that should be used */
  private _captionMatchers: CuriousCaptionMatcher[];
  /** The logger that should be used */
  private _logger: Logger;

  constructor(
    captionMatchers: CuriousCaptionMatcher[] = [
      new RepeatedWordsMatcher({ numberOfRepeats: NUMBER_OF_REPEATED_WORDS_TO_MATCH_ON }),
      new RepeatedCharactersMatcher(),
    ]
  ) {
    this._captionMatchers = captionMatchers;
    this._logger = Logger.getInstance();
  }

  /**
   * Check to see if the last two captions match any of the matchers and log if
   * necessary
   */
  check(prevCaption: Caption | undefined, newCaption: Caption) {
    return new Promise((resolve) => {
      try {
        this._captionMatchers
          .filter((captionMatcher) => captionMatcher.matches(prevCaption, newCaption))
          .forEach((captionMatcher) => {
            this._logger[captionMatcher.logLevel]({
              message: `${captionMatcher.constructor.name} matched`,
              info: { prevCaption, newCaption },
            });
          });
      } catch (error) {
        this._logger.error({
          message: this._getError(error),
          info: { prevCaption, newCaption },
        });
      }
      resolve(undefined);
    });
  }

  private _getError(error: unknown) {
    if (error instanceof Error) return error;
    return String(error);
  }
}
