import React, { useContext } from 'react';

import Alert from 'react-bootstrap/Alert';
import { numberToCurrency } from '~/helpers/numbers';
import { ProjectContext } from './OrderForm';

function SurchargeNote() {
  const { difficultySurcharge, features } = useContext(ProjectContext);
  return (
    <Alert className="mt-2" variant="warning">
      <span className="font-weight-bold">Note: </span>
      Each media file uploaded for 3Play Media&apos;s{' '}
      {features.other ? 'Transcription & Captioning Services' : 'Transcription Services'} (English,
      Spanish, Dual-Language) may be subject to an audio difficulty surcharge of{' '}
      {numberToCurrency.format(difficultySurcharge)} per minute.{' '}
      <Alert.Link
        href="https://support.3playmedia.com/hc/en-us/articles/227734388-What-is-a-Difficulty-Surcharge-"
        rel="noopener noreferrer"
        target="_blank"
      >
        Learn More
      </Alert.Link>
    </Alert>
  );
}

export default SurchargeNote;
