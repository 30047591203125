import { threeplayApi } from '~/components/ops/common/threeplayApi';
import { referredApplicantsQuery, contractorReferralCodesQuery, contractorQuery } from './queries';
import { createReferralCodesMutation } from './mutations';

export const getReferredApplicants = () => threeplayApi.json(referredApplicantsQuery);

export const getContractorReferralsCodes = () => threeplayApi.json(contractorReferralCodesQuery);

export const getContractors = () => threeplayApi.json(contractorQuery);

export const createReferralCodes = (contractorIds) =>
  threeplayApi.json(createReferralCodesMutation, { contractorIds });
