import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Table from 'react-bootstrap/Table';

import { Tooltip } from '@threeplayground/index';

import { durationString } from '~/helpers/numbers';

function LiveEventGeneralInfo(props) {
  const { platformName, webpageWithCaptionsUrl } = props;

  return (
    <>
      <h2 className="swatei-title">General Information</h2>
      <Table>
        <tbody>
          <tr>
            <th scope="row" className={css(styles.heading)}>
              Company Name
            </th>
            <td className={css(styles.data)}>{props.companyName}</td>
          </tr>
          <tr>
            <th scope="row" className={css(styles.heading)}>
              Event Name
            </th>
            <td className={css(styles.data)}>
              {props.eventName}
              {props.zoomEventJoinUrl && (
                <p>
                  <br />
                  <a href={props.zoomEventJoinUrl} rel="noreferrer" target="_blank">
                    Join Zoom Meeting/Webinar
                  </a>
                </p>
              )}
            </td>
          </tr>
          {props.description && (
            <tr>
              <th scope="row" className={css(styles.heading)}>
                Event Description
              </th>
              <td className={css(styles.data)}>{props.description}</td>
            </tr>
          )}
          <tr>
            <th scope="row" className={css(styles.heading)}>
              Event Type
            </th>
            <td className={css(styles.data)}>{props.eventType}</td>
          </tr>
          <tr>
            <th scope="row" className={css(styles.heading)}>
              Stream Platform
            </th>
            <td className={css(styles.data)}>
              <span>
                {platformName}
                {props.platformInstructionsText && (
                  <Tooltip id={`tooltip-${platformName}`} text={props.platformInstructionsText}>
                    <i className="fa fa-info-circle pl-1"></i>
                  </Tooltip>
                )}
              </span>
            </td>
          </tr>
          {props.streamStartDateTime && (
            <tr>
              <th scope="row" className={css(styles.heading)}>
                Stream Start Time
              </th>
              <td className={css(styles.data)}>{new Date(props.streamStartDateTime).toString()}</td>
            </tr>
          )}
          <tr>
            <th scope="row" className={css(styles.heading)}>
              Scheduled Job Start Time
            </th>
            <td className={css(styles.data)}>
              {new Date(props.scheduledStartTime * 1000).toString()}
            </td>
          </tr>
          {props.scheduledEndTime && (
            <tr>
              <th scope="row" className={css(styles.heading)}>
                Scheduled Job End Time
              </th>
              <td className={css(styles.data)}>
                {new Date(props.scheduledEndTime * 1000).toString()}
              </td>
            </tr>
          )}
          {props.scheduledDurationInMS && (
            <tr>
              <th scope="row" className={css(styles.heading)}>
                Scheduled Duration
              </th>
              <td className={css(styles.data)}>
                {durationString(props.scheduledDurationInMS / 1000)}
              </td>
            </tr>
          )}
          {props.overtimeOptionText && (
            <tr>
              <th scope="row" className={css(styles.heading)}>
                Customer Selected Overtime Option
              </th>
              <td className={css(styles.data)}>{props.overtimeOptionText}</td>
            </tr>
          )}
          {props.captionerJoinTime && (
            <tr>
              <th scope="row" className={css(styles.heading)}>
                Captioner Join Time
              </th>
              <td className={css(styles.data)}>{new Date(props.captionerJoinTime).toString()}</td>
            </tr>
          )}
          {props.additionalInfo && (
            <tr>
              <th scope="row" className={css(styles.heading)}>
                Additional Information
              </th>
              <td>
                <pre className={css(styles.data)}>{props.additionalInfo}</pre>
              </td>
            </tr>
          )}
          {props.cheatSheetPdfLink && (
            <tr>
              <th scope="row" className={css(styles.heading)}>
                Uploaded PDF Instructions
              </th>
              <td>
                <a href={props.cheatSheetPdfLink} target="_blank" rel="noreferrer">
                  Document
                </a>
              </td>
            </tr>
          )}
          {webpageWithCaptionsUrl && (
            <tr>
              <th>Audience Experience</th>
              <td>
                <a href={webpageWithCaptionsUrl} target="_blank" rel="noreferrer">
                  Click Here
                </a>{' '}
                (clicking this link will open a new window)
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

LiveEventGeneralInfo.propTypes = {
  companyName: PropTypes.string,
  eventName: PropTypes.string,
  description: PropTypes.string,
  eventType: PropTypes.string,
  zoomEventJoinUrl: PropTypes.string,
  scheduledStartTime: PropTypes.number,
  scheduledEndTime: PropTypes.number,
  scheduledDurationInMS: PropTypes.number,
  streamStartDateTime: PropTypes.string,
  captionerJoinTime: PropTypes.string,
  additionalInfo: PropTypes.string,
  overtimeOptionText: PropTypes.string,
  cheatSheetPdfLink: PropTypes.string,
  platformName: PropTypes.string,
  platformInstructionsText: PropTypes.string,
  webpageWithCaptionsUrl: PropTypes.string,
};

const styles = StyleSheet.create({
  heading: {
    fontSize: '14px',
    lineHeight: '17px',
    fontFamily: 'Helvetica Neue',
    color: '#0F0909',
  },
  data: {
    fontSize: '14px',
    lineHeight: '16px',
    fontFamily: 'Helvetica Neue',
    color: '#0F0909',
  },
});

export default LiveEventGeneralInfo;
