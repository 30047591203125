import React from 'react';
import PropTypes from 'prop-types';

import { Form, Table } from 'react-bootstrap';

import {
  aiDubbingOrderOptionType,
  translationProfileType,
} from '~/components/app/order_form/propTypes';

import TranslationProfileDropdown from '~/components/app/order_form/serviceOptions/translation/TranslationProfileDropdown';
import TranslationProfileInfoPopover from '~/components/app/order_form/serviceOptions/translation/TranslationProfileInfoPopover';
import { DUBBING_TRANSLATION_TYPE_MAP } from '~/helpers/constants';
import { displayPrice } from '~/helpers/numbers';

const TranslationTypeDropdown = ({
  availableTypesForLanguage,
  selectedType,
  swapToTranslationType,
  targetLanguage,
}) => {
  return (
    <Form.Control
      as="select"
      onChange={(e) => swapToTranslationType(targetLanguage, e.target.value)}
      value={selectedType}
      size="sm"
    >
      {availableTypesForLanguage.map((type) => {
        return (
          <option key={type} value={type}>
            {DUBBING_TRANSLATION_TYPE_MAP[type]}
          </option>
        );
      })}
    </Form.Control>
  );
};

const AIDubbingOrderOptionsTable = ({
  dubbingOrderData,
  dubbingOrderOptions,
  translationProfiles,
  updateOrderOptions,
}) => {
  const selectedOrderOptions = dubbingOrderData?.selectedDubbingOptions || [];
  const swapToTranslationType = (targetLanguage, translationType) => {
    const newSelectedDubbingOptions = [...dubbingOrderData.selectedDubbingOptions];
    const indexOfOrderOptionToReplace = newSelectedDubbingOptions.findIndex((option) => {
      return option.targetLanguage === targetLanguage;
    });
    const orderOptionOfCorrectType = dubbingOrderOptions.find((option) => {
      return (
        option.targetLanguage.id === targetLanguage.id && option.translationType === translationType
      );
    });
    const orderOptionWithTranslationProfile = {
      ...orderOptionOfCorrectType,
      translationProfileID:
        newSelectedDubbingOptions[indexOfOrderOptionToReplace].translationProfileID,
    };
    newSelectedDubbingOptions[indexOfOrderOptionToReplace] = orderOptionWithTranslationProfile;
    updateOrderOptions('Dubbing', {
      ...dubbingOrderData,
      selectedDubbingOptions: newSelectedDubbingOptions,
    });
  };

  const setTranslationProfileForLanguage = (targetLanguage, translationProfileID) => {
    const newSelectedDubbingOptions = [...dubbingOrderData.selectedDubbingOptions];
    const selectedOptionIndex = newSelectedDubbingOptions.findIndex((option) => {
      return option.targetLanguage === targetLanguage;
    });
    if (selectedOptionIndex !== -1) {
      newSelectedDubbingOptions[selectedOptionIndex]['translationProfileID'] = translationProfileID;
    }
    updateOrderOptions('Dubbing', {
      ...dubbingOrderData,
      selectedDubbingOptions: newSelectedDubbingOptions,
    });
  };

  if (selectedOrderOptions.length === 0) {
    return null;
  } else {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Language</th>
            <th>Translation Type</th>
            <th>
              Translation Profile <TranslationProfileInfoPopover />
            </th>
            <th>Turnaround Time</th>
            <th>Estimated Cost</th>
          </tr>
        </thead>
        <tbody>
          {selectedOrderOptions.map((orderOption) => {
            const relevantOptions = dubbingOrderOptions.filter(
              (option) => option.targetLanguage.id === orderOption.targetLanguage.id
            );
            const availableTypes = relevantOptions.map((option) => option.translationType);
            return (
              <tr key={orderOption.targetLanguage.id}>
                <td>{orderOption.targetLanguage.fullName}</td>
                <td>
                  <TranslationTypeDropdown
                    availableTypesForLanguage={availableTypes}
                    dubbingOrderData={dubbingOrderData}
                    selectedType={orderOption.translationType}
                    swapToTranslationType={swapToTranslationType}
                    targetLanguage={orderOption.targetLanguage}
                  />
                </td>
                <td>
                  <TranslationProfileDropdown
                    selectedTranslationProfile={orderOption.translationProfile}
                    setSelectedTranslationProfile={(e, targetLanguage) =>
                      setTranslationProfileForLanguage(targetLanguage, e.target.value)
                    }
                    targetLanguage={orderOption.targetLanguage}
                    translationProfiles={translationProfiles}
                  />
                </td>
                <td>Standard</td>
                <td>{displayPrice(orderOption.price.amount, orderOption.price.unit, false)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
};

AIDubbingOrderOptionsTable.propTypes = {
  dubbingOrderData: PropTypes.shape({
    selectedDubbingOptions: PropTypes.arrayOf(
      PropTypes.shape({
        ...aiDubbingOrderOptionType,
        translationProfileID: PropTypes.number,
      })
    ),
  }),
  dubbingOrderOptions: PropTypes.arrayOf(aiDubbingOrderOptionType),
  translationProfiles: PropTypes.arrayOf(translationProfileType),
  updateOrderOptions: PropTypes.func,
};

TranslationTypeDropdown.propTypes = {
  availableTypesForLanguage: PropTypes.object,
  selectedType: PropTypes.string,
  swapToTranslationType: PropTypes.func,
  targetLanguage: PropTypes.string,
};

export default AIDubbingOrderOptionsTable;
