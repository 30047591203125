import React, { useState } from 'react';

import { css, StyleSheet } from 'aphrodite';

import Transition from '~/lib/global/animations/Transition';

import ShowPanel from './ShowPanel/ShowPanel';

function AnimatedShowPanel(props) {
  const [open, setOpen] = useState(false);
  return (
    <Transition
      in={open}
      base={css(styles.base)}
      initial={css(styles.closed)}
      middle={css(styles.open)}
      final={css(styles.closed)}
    >
      <ShowPanel {...{ open, setOpen }} {...props} />
    </Transition>
  );
}

const styles = StyleSheet.create({
  base: {
    transition: 'left 200ms ease-in-out',
    position: 'relative',
    float: 'right',
    right: '0px',
    height: '100%',
    width: '0px',
    zIndex: '100',
  },
  open: { left: '0rem' },
  closed: { left: '45rem' },
});

export default AnimatedShowPanel;
