import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import TextBoxWithCopyButton from '~/components/app/common/TextBoxWithCopyButton';

import logo from '~/components/app/common/images/3play_logo.svg';
import tp_branded_logo from './images/tp_branded_logo.svg';
import start_captions_image from './images/start_captions.svg';
import start_live_streaming_image from './images/zoom_streaming.png';
import zoom_api_token_image from './images/copy_api_token.svg';

import { getUrlParam } from '~/logic/UrlManager';
import { threeplayApiExternal } from '~/logic/ThreeplayApi';
import { scheduledEventsShape } from '~/components/app/live_auto_captioning/common/shapes';
import { updateZoomCaptionUrlMutation } from '~/components/app/live_auto_captioning/data/mutations';

function AlertMessage(props) {
  return <Alert variant={props.variant}>{props.message}</Alert>;
}

/*
Eventually this will be the standard mailer. We will remove flipper logic and ZoomFullAutomationInstructions will be removed. 
*/
function ZoomFullAutomationInstructions(props = {}) {
  const { startTime = '', rtmpKey = '', streamUrl = '', ZoomApiTokenForm } = props;
  return (
    <>
      <div className="mt-4">
        <h2>Step 1: Confirm your API token has been added by 3Play</h2>
        <p>
          3Play has automated the addition of the Zoom API token to your event, you no longer need
          to copy and paste this in yourself. Once you have started your Zoom event at or after the
          Stream Start Time of {startTime} (Eastern Time), the API token should populate on this
          screen within 1 minute. Ensure you refresh this page to see that it&apos;s displayed.
        </p>
        <p>
          If, after refreshing this page, you do not see the API token populate the field below
          within 1 minute of starting your Zoom event, then you must copy / paste the API token from
          Zoom into this page. To do that, click the Closed Caption button in the Zoom toolbar, and
          click the &quot;Copy the API Token&quot; button.
        </p>
        <ZoomApiTokenForm />
      </div>
      <div className="mt-4">
        <h2>Step 2: Start Closed Captions</h2>
        <p className="mb-0">
          3Play has automated the initiation of the stream from Zoom to 3Play (which allows 3Play to
          access your event&apos;s audio so we can generate captions). Once you have started your
          Zoom event at or after the Stream Start Time of {startTime} (Eastern Time), you should
          receive a notification from within Zoom that your meeting is being live streamed within 1
          minute.
        </p>
        <p>
          <img src={start_live_streaming_image} alt="Zoom Start Streaming screenshot" />
        </p>
      </div>
      <div className="mt-4">
        <h2>Step 3: Troubleshooting</h2>
        <p>
          If you do not receive the notification from Zoom that your meeting is being live streamed,
          follow the steps below:
        </p>
        <p>
          Go back to your Zoom client and click on the &quot;More&quot; button and select&quot; Live
          on Custom Live Streaming Service&quot;. This will open a new page in your web browser.
        </p>
        <p>
          Note: Make sure you click this button at or after the captioning start time of {startTime}
          (Eastern Time). If you click this button before that time, the stream will fail to connect
          and captions will not display.
        </p>

        <img src={start_captions_image} alt="Zoom Start Captions screenshot" />
        <p>
          In the web page that opens, Zoom will take a few moments to confirm your event is valid.
          If prompted for additional information, copy and paste the following codes into each
          field. Make sure you click &quot;Go Live!&quot; at or after your event starts at{' '}
          {startTime}.
        </p>
        <Row className="mt-2">
          <Col sm="3">
            <b>Streaming URL:</b>
          </Col>
          <Col sm="9">
            <TextBoxWithCopyButton text={streamUrl} />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm="3">
            <b>Streaming Key:</b>
          </Col>
          <Col sm="9">
            <TextBoxWithCopyButton text={rtmpKey} />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm="3">
            <b>Live Streaming Page URL:</b>
          </Col>
          <Col sm="9">
            <span>Your own URL or https://www.3playmedia.com</span>
          </Col>
        </Row>
      </div>
    </>
  );
}

function ZoomCaptionUrl(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [updatingUrl, setUpdatingUrl] = useState(false);
  const [zoomCaptionUrl, setZoomCaptionUrl] = useState(props.caption_url);
  const { showFullyAutomatedInstructions = false } = props;

  useEffect(() => {
    if (props.caption_url !== null) {
      setSuccessMessage('Success! The Zoom API token has been added to your meeting.');
      setIsValidUrl(true);
    }
  }, []);

  function updateZoomCaptionUrlState(value) {
    setZoomCaptionUrl(value);
    validateUrl(value);
  }

  function validateUrl(url = null) {
    if (url === null) {
      url = zoomCaptionUrl;
    }

    const zoomCaptionUrlRegEx = /^https:\/\/\w+.zoom.us\/closedcaption\?/;
    const valid = zoomCaptionUrlRegEx.test(url);
    setIsValidUrl(valid);
  }

  async function updateCaptionUrl() {
    if (!isValidUrl) {
      return;
    }

    setUpdatingUrl(true);
    const exp_key = getUrlParam('exp_key');
    const response = await threeplayApiExternal(`exp_key=${exp_key}`).request(
      `${updateZoomCaptionUrlMutation}`,
      { zoomCaptionUrl: encodeURI(zoomCaptionUrl) }
    );

    if (response.data.updateZoomCaptionUrl.error) {
      setErrorMessage(response.data.updateZoomCaptionUrl.error);
      setUpdatingUrl(false);
      setTimeout(() => setErrorMessage(null), 5000);
    }
    if (response.data.updateZoomCaptionUrl.success) {
      setSuccessMessage(response.data.updateZoomCaptionUrl.success);
      setUpdatingUrl(false);
      setTimeout(() => setSuccessMessage(null), 5000);
    }
  }

  // only temporary this will be removed after flipper flag is removed.
  const ZoomApiTokenForm = () => {
    return (
      <p>
        <Form>
          <Form.Group>
            <Form.Control
              isInvalid={zoomCaptionUrl !== null && !isValidUrl}
              onChange={(event) => updateZoomCaptionUrlState(event.target.value)}
              type="text"
              value={zoomCaptionUrl}
              readOnly={props.caption_url !== null}
            />
            <Form.Control.Feedback type="invalid">
              Sorry, you entered an invalid URL. URLs start with
              &quot;https://wmcc.zoom.us/closedcaption?&quot; and change for each meeting.
            </Form.Control.Feedback>
          </Form.Group>
          {errorMessage && <AlertMessage variant="warning" message={errorMessage} />}
          {successMessage && <AlertMessage variant="success" message={successMessage} />}
          {props.caption_url === null && (
            <Button
              className="mt-1"
              disabled={zoomCaptionUrl === null || !isValidUrl || updatingUrl}
              onClick={() => updateCaptionUrl()}
              size="md"
              variant="primary"
            >
              Add Captions to Meeting
            </Button>
          )}
        </Form>
      </p>
    );
  };

  return (
    <div className={css(styles.header)}>
      <Container className="pb-5">
        <img className="my-4" src={props.tp_branded_logo ? tp_branded_logo : logo} alt="Logo" />
        <div className="mt-4">
          <h1>Add Captions to Zoom Meeting:</h1>
          <p className={css(styles.eventName)}>{props.event_details.name}</p>
          {showFullyAutomatedInstructions ? (
            <ZoomFullAutomationInstructions
              startTime={props.event_details?.start_time}
              streamUrl={props.event_details?.stream_details?.stream_url}
              rtmpKey={props.event_details?.stream_details?.rtmp_key}
              ZoomApiTokenForm={ZoomApiTokenForm}
            />
          ) : (
            <>
              <div className="mt-4">
                <h2>Step 1: Confirm your API token has been added by 3Play</h2>
                <p>
                  3Play has automated the addition of the Zoom API token to your event, you no
                  longer need to copy and paste this in yourself. Once you have started your Zoom
                  event, the API token should populate on this screen within 1 minute. Ensure you
                  refresh this page to see that it&apos;s displayed.
                </p>
                <p>
                  If, after refreshing this page, you do not see the API token populate the field
                  below within 1 minute of starting your Zoom event, then you must copy / paste the
                  API token from Zoom into this page. To do that, click the Closed Caption button in
                  the Zoom toolbar, and click the &quot;Copy the API Token&quot; button.
                </p>
                <p>
                  <img src={zoom_api_token_image} alt="Zoom API Token screenshot" />
                </p>
                <ZoomApiTokenForm />
              </div>
              <div className="mt-4">
                <h2>Step 2: Start Closed Captions</h2>
                <p className="mb-0">
                  Go back to your Zoom client and click on the &quot;More&quot; button and select
                  &quot;Live on Custom Live Streaming Service&quot;. This will open a new page in
                  your web browser.
                </p>
                <p>
                  <strong>Note: </strong>
                  Make sure you click this button at or after the captioning start time of{' '}
                  <strong>{props.event_details.start_time}</strong> (Eastern Time). If you click
                  this button before, captions might not show up correctly.
                </p>
                <img src={start_captions_image} alt="Zoom Start Captions screenshot" />
              </div>
              <div className="mt-4">
                <h2>Step 3: Confirm Codes</h2>
                <p>
                  In the web page that opens, Zoom will take a few moments to confirm your event is
                  valid. If prompted for additional information, copy and paste the following codes
                  into each field. Make sure you click “Go Live!” at or after your event starts at{' '}
                  <b>{props.event_details.start_time}</b> (Eastern Time).
                </p>
                <Row className="mt-2">
                  <Col sm="3">
                    <b>Streaming URL:</b>
                  </Col>
                  <Col sm="9">
                    <TextBoxWithCopyButton text={props.event_details.stream_details.stream_url} />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm="3">
                    <b>Streaming Key:</b>
                  </Col>
                  <Col sm="9">
                    <TextBoxWithCopyButton text={props.event_details.stream_details.rtmp_key} />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm="3">
                    <b>Live Streaming Page URL:</b>
                  </Col>
                  <Col sm="9">
                    <span>Your own URL or https://www.3playmedia.com</span>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  eventName: {
    fontSize: 'x-large',
  },
  header: {
    background: '#DEEFF5',
    height: '92px',
  },
});

AlertMessage.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
};

ZoomCaptionUrl.propTypes = {
  caption_url: PropTypes.string,
  event_details: scheduledEventsShape,
  tp_branded_logo: PropTypes.bool,
  showFullyAutomatedInstructions: PropTypes.bool,
};

ZoomFullAutomationInstructions.propTypes = {
  startTime: PropTypes.number,
  rtmpKey: PropTypes.string,
  streamUrl: PropTypes.string,
  ZoomApiTokenForm: PropTypes.func,
};

export default ZoomCaptionUrl;
