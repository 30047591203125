export const paths = {
  show: {
    getLink: (id: string) => `/off_platform_descriptive_transcript_jobs/${id}`,
    route: '/off_platform_descriptive_transcript_jobs/:id',
  },
  downloadTranscript: {
    getLink: (id: string, outputFormat: string) =>
      `/off_platform_broadcast_scripting_jobs/download_transcript?transcript_id=${id}&output_format=${outputFormat}`,
  },
};
