/**
 * Type must match what our codegen expects for hooks to call properly.
 *
 * This is very similar to the fetcher generated with
 * `fetcher: { endpoint: '"/data"', ... }`, but we populate errors with a
 * full set of global errors for easy in passing to our Form and Input
 * components.
 *
 * @see https://www.the-guild.dev/graphql/codegen/plugins/typescript/typescript-react-query#using-custom-fetcher
 */

let cachedToken: string | undefined;

export interface GraphQLError {
  message: string;
  extensions?: {
    path?: string;
    [key: string]: unknown;
  };
}

interface Payload<T> {
  data?: T;
  errors?: GraphQLError[];
}

interface NormalizedError {
  type: 'NO_INTERNET' | 'NON_GRAPHQL_RESPONSE' | 'UNKNOWN_ERROR' | 'GRAPHQL_ERROR';
  message: string;
  code?: string;
  path?: string;
  extensions?: Record<string, unknown>;
}

/**
 * This special error is thrown from the fetcher with the intent of being used
 * for first-class error handling in the app. It can typically be accessed
 * from react-query hooks via the `error` property returned.
 *
 * You can check that an error you get is an instance of FetchErrors then
 * access the errors property for a normalized set of errors with explicit
 * types, and optionally back-end code and paths. Abstractions in our component
 * library (such as Form) should do most of this error handling for you and
 * automatically set errors on Inputs and render global errors.
 *
 * We may provide more methods on this class for convenient error matching
 * in future.
 *
 * @example
 *
 * const { error } = useMyQuery();
 *
 * if (error instanceof FetchErrors) {
 *   error.errors // normalized list of all errors.
 * }
 */
export class FetchErrors extends Error {
  type: 'ApiFetchErrors';
  errors: NormalizedError[];

  constructor(message: string, errors: NormalizedError[], cause?: Error) {
    // http://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause
    super(message, { cause });

    this.type = 'ApiFetchErrors';
    this.errors = errors;

    // Error breaks the prototype chain generally, so extensions need to do this
    // to preserve properties, methods, and instanceof checks.
    // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    //
    // For most custom errors this is unnecessary as we simply want to associate
    // more data debugging/logging. However we expect this special error to be
    // used in a lot of @threeplayground code to extract out GraphQL errors in
    // a friendly way.
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/** Convenience error creator for custom errors */
function createFetchError(
  type: NormalizedError['type'],
  message: string,
  extra?: {
    extensions?: Record<string, unknown>;
    cause?: Error;
  }
) {
  return new FetchErrors(
    message,
    [
      {
        type,
        message,
        extensions: extra?.extensions,
      },
    ],
    extra?.cause
  );
}

function normalizedGraphQLErrors(errors: GraphQLError[]) {
  const message = errors[0]?.message || 'Request was not fully successful.';

  return new FetchErrors(
    message,
    errors.map((error) => {
      return { ...error, type: 'GRAPHQL_ERROR', path: error.extensions?.path };
    })
  );
}

function isPayload<T>(payload: unknown): payload is Payload<T> {
  return (
    Boolean(payload) &&
    payload !== null &&
    typeof payload === 'object' &&
    ('data' in payload || 'errors' in payload)
  );
}

function getCsrfToken() {
  if (cachedToken) {
    return cachedToken;
  }

  // querying the dom is a little expensive, so we cache this
  cachedToken =
    document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? undefined;

  return cachedToken;
}

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers']
): (() => Promise<TData>) => {
  return async () => {
    try {
      const csrfToken = getCsrfToken();

      const res = await fetch('/data', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          ...(csrfToken ? { 'X-CSRF-Token': csrfToken } : {}),
          ...options,
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      });

      const json: unknown = await res.json();

      if (!isPayload<TData>(json)) {
        throw createFetchError('NON_GRAPHQL_RESPONSE', 'Unrecognized data response returned.');
      }

      if (json.errors) {
        throw normalizedGraphQLErrors(json.errors);
      }

      return json.data as TData;
    } catch (err) {
      if (err instanceof Error && err.message === 'Failed to fetch') {
        throw createFetchError('NO_INTERNET', 'Failed to connect. Is your internet down?', err);
      }

      throw createFetchError(
        'UNKNOWN_ERROR',
        err instanceof Error
          ? err.message
          : 'Unknown error. Please contact the 3Play support team.',
        err instanceof Error ? err : undefined
      );
    }
  };
};
