import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { Dropdown, Button, ButtonGroup } from 'react-bootstrap';

import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';
import WithIcon from '~/components/app/common/WithIcon';

import { favoriteFormatsPath, newDownloadPath, outputFormatPath } from '~/helpers/app/paths';

import { userLogger } from '~/logic/UserLogger';

function DownloadButton(props) {
  const errors = (props.errors || []).concat(
    (props.reprocessingAssets || []).map((asset) => `${asset}s are still processing`)
  );
  const enabled = (props.enabled && errors.length === 0) || false;

  const actionData = props.actionData || {};
  const favorites = actionData.favoriteFormats || [];

  return (
    <TooltipIfErrors title="Download unavailable:" errors={errors}>
      <Dropdown as={ButtonGroup} focusFirstItemOnShow="keyboard">
        <Button
          variant="light"
          href={newDownloadPath(props.fileId)}
          target="_blank"
          onClick={() => {
            userLogger.logEvent('FileShow', 'Download', { split: 'no' });
          }}
          size="sm"
          id="dropdown-split-variants-secondary"
          key="secondary"
          disabled={!enabled}
        >
          Download
        </Button>

        <Dropdown.Toggle
          split
          variant="light"
          id="dropdown-split-basic"
          size="sm"
          disabled={!enabled}
        />

        <Dropdown.Menu className={enabled ? css(styles.scrollableList) : css(styles.disabled)}>
          {favorites.map((format) => (
            <Dropdown.Item
              eventKey={format.id}
              key={format.id}
              onClick={() => {
                userLogger.logEvent('FileShow', 'Download', { split: 'yes' });
              }}
              href={outputFormatPath(format, {
                mediaFileId: props.fileId,
                transcriptId: props.transcriptId,
              })}
            >
              {format.inputType === 'Description'
                ? format.name + ' (Audio Description)'
                : format.name}
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
          <Dropdown.Item eventKey="edit" href={favoriteFormatsPath} target="_blank">
            <WithIcon icon="fa fa-pencil">Edit Favorite Formats</WithIcon>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </TooltipIfErrors>
  );
}

DownloadButton.propTypes = {
  enabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  fileId: PropTypes.string,
  transcriptId: PropTypes.string,
  reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
  actionData: PropTypes.shape({
    favoriteFormats: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
};

const styles = StyleSheet.create({
  disabled: {
    pointerEvents: 'none',
  },
  scrollableList: {
    overflowY: 'auto',
  },
});

export default DownloadButton;
