import React from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import BillingInformation from './BillingInformation';
import { MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX } from '~/helpers/constants';

function BillingInformationAlert(props) {
  return (
    <>
      <Alert className="ml-2 mt-2" variant="warning">
        <Container fluid>
          <Row className="font-weight-bold">Billing Information</Row>
          {props.billingInformation.validBillingAddress && (
            <>
              <Row>This is your current billing information.</Row>
              <div className="text-dark ml-4">
                <Row className="font-weight-bold">
                  {props.billingInformation.billingContactName}
                </Row>
                {props.billingInformation.billingAddressOne && (
                  <Row>{props.billingInformation.billingAddressOne}</Row>
                )}
                {props.billingInformation.billingAddressTwo && (
                  <Row>{props.billingInformation.billingAddressTwo}</Row>
                )}
                {props.billingInformation.billingAddressThree && (
                  <Row>{props.billingInformation.billingAddressThree}</Row>
                )}
                {props.billingInformation.billingAddressFour && (
                  <Row>{props.billingInformation.billingAddressFour}</Row>
                )}
                <Row>
                  {`${props.billingInformation.billingAddressCity} ${props.billingInformation.billingAddressState} ${props.billingInformation.billingAddressZipcode}`}
                </Row>
              </div>
            </>
          )}
          {!props.billingInformation.validBillingAddress && (
            <Row>
              We currently have no billing information on file for your project. You must add your
              billing information below if you want to pay by invoice.
            </Row>
          )}
          {props.billingInformation.validBillingAddress && (
            <Row className="ml-1">
              <Button
                size="sm"
                variant="link"
                className="font-weight-bold"
                onClick={() => props.setShowInvoiceBillingForm(true)}
                tabIndex={MANAGE_PLAN_PAYMENT_INVOICE_TAB_INDEX + 1}
              >
                Edit Billing Information
              </Button>
            </Row>
          )}
        </Container>
      </Alert>
      {(props.showInvoiceBillingForm || !props.billingInformation.validBillingAddress) && (
        <BillingInformation
          authenticityToken={props.authenticityToken}
          billingInformation={props.billingInformation}
          closeInvoiceBillingForm={() => props.setShowInvoiceBillingForm(false)}
          fetchManagePlanData={props.fetchManagePlanData}
        />
      )}
    </>
  );
}

BillingInformationAlert.propTypes = {
  authenticityToken: PropTypes.string,
  billingInformation: PropTypes.object,
  fetchManagePlanData: PropTypes.func,
  setShowInvoiceBillingForm: PropTypes.func,
  showInvoiceBillingForm: PropTypes.bool,
};

export default BillingInformationAlert;
