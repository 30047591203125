import React from 'react';
import { RootProps } from '~/components/jobs/common/permission_provider/PermissionProvider';

import { JobAppContainer } from '~/components/jobs/common/JobAppContainer';
import { offPlatformVideoDescriptionTextQualityControlJobsPaths } from './offPlatformVideoDescriptionTextQualityControlJobsPaths';
import { OffPlatformVideoDescriptionTextQualityControlJob } from './:id/OffPlatformVideoDescriptionTextQualityControlJob';

function OffPlatformVideoDescriptionTextQualityControlJobsApp({ permissions }: RootProps) {
  return (
    <JobAppContainer
      showPath={offPlatformVideoDescriptionTextQualityControlJobsPaths.show.route}
      jobComponent={<OffPlatformVideoDescriptionTextQualityControlJob />}
      permissions={permissions}
    />
  );
}

export default OffPlatformVideoDescriptionTextQualityControlJobsApp;
