import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import PopoverContent from 'react-bootstrap/PopoverContent';
import Tooltip from 'react-bootstrap/Tooltip';

import { displayPrice } from '~/helpers/numbers';

import AudioDescriptionIcon from '~/components/app/order_form/images/service_icons/AD.svg';
import AlignmentIcon from '~/components/app/order_form/images/service_icons/Alignment.svg';
import AsrIcon from '~/components/app/order_form/images/service_icons/ASR.svg';
import DescriptiveTranscriptIcon from '~/components/app/order_form/images/service_icons/DescriptiveTranscript.svg';
import DubbingIcon from '~/components/app/order_form/images/service_icons/Dubbing.svg';
import CaptionImportIcon from '~/components/app/order_form/images/service_icons/CaptionImport.svg';
import CaptionPlacementIcon from '~/components/app/order_form/images/service_icons/CaptionPlacement.svg';
import TranscriptionIcon from '~/components/app/order_form/images/service_icons/Transcription.svg';
import TranslationIcon from '~/components/app/order_form/images/service_icons/Translation.svg';
import { selectedServicesType } from '~/components/app/order_form/propTypes';
import selectedStyles from '../styles/selectedDeselectedStyles';

import { checkKeyPress } from '~/helpers/checkKeyPress';
import { translationProfilesPath } from '~/helpers/app/paths';
import WithIcon from '~/components/app/common/WithIcon';

function ServiceCardBody({
  description,
  displayName,
  index,
  price,
  role,
  selected,
  serviceDisabled,
  serviceType,
  showBetaTag,
  toggleService,
}) {
  function tooltip() {
    switch (serviceType) {
      case 'Transcription':
        return 'Output transcript and caption file formats include: SRT, WebVTT, SCC, SMPTE-TT, DFXP, and more!';
      case 'Alignment':
        return 'Transcripts submitted must be in an UTF-8 unformatted plain text (.txt) format.';
      case 'Asr':
        return (
          'Output file formats: - MP4, MP3, OGG, WAV with Audio Description (and/or source audio) - ' +
          'WebVTT Audio Description Track - TXT, DOC, or Stamped Doc text file (with or without transcript and AD)'
        );
      case 'Dubbing':
        return (
          '3Play works with partners to complete hiqh-quality, broadcast-ready Dubbing. ' +
          "You'll receive a .wav file, with additional audio mixing available on request."
        );
      case 'CaptionPlacement':
        return (
          'The following caption/subtitle output file formats support Vertical Caption ' +
          'Placement: DFXP, SCC, SMPTE-TT STL, WEB-VTT'
        );
      case 'AudioDescription':
      case 'AudioDescriptionTranslation':
        return 'Output transcript and caption file formats include: SRT, WebVTT, SCC, SMPTE-TT, DFXP, and more!';
      case 'Translation':
        return (
          '3Play works with partners to ensure that your translations are high-quality. ' +
          'You can download your translations in both subtitle and transcript formats.'
        );
    }
  }

  function learnMoreLink() {
    switch (serviceType) {
      case 'Transcription':
        return 'https://support.3playmedia.com/hc/en-us/articles/360012043914-Available-Output-Formats-Captions-and-Transcripts';
      case 'Alignment':
        return 'https://www.3playmedia.com/solutions/features/tools/transcript-alignment/';
      case 'Dubbing':
        return 'https://www.3playmedia.com/services/localization/#dubbing';
      case 'CaptionPlacement':
        return 'https://support.3playmedia.com/hc/en-us/articles/360003798794-Automatic-Caption-Placement-vs-Manual-Caption-Placement';
      case 'AudioDescription':
        return 'https://support.3playmedia.com/hc/en-us/articles/115010653947-What-Is-Audio-Description-';
      case 'Translation':
        return 'https://www.3playmedia.com/solutions/services/translation-subtitling/';
      default:
        return null;
    }
  }

  function iconUrl() {
    switch (serviceType) {
      case 'BroadcastScript':
      case 'Transcription':
        return TranscriptionIcon;
      case 'Alignment':
        return AlignmentIcon;
      case 'Asr':
        return AsrIcon;
      case 'DescriptiveTranscript':
        return DescriptiveTranscriptIcon;
      case 'Dubbing':
        return DubbingIcon;
      case 'CaptionPlacement':
        return CaptionPlacementIcon;
      case 'AudioDescription':
      case 'AudioDescriptionTranslation':
        return AudioDescriptionIcon;
      case 'Translation':
        return TranslationIcon;
      case 'CaptionImport':
        return CaptionImportIcon;
    }
  }

  return (
    <Card
      tabIndex={index}
      data-testid={displayName}
      bg="light"
      className={
        selected
          ? css(selectedStyles.selectedSimple, serviceDisabled && styles.disabled)
          : css(selectedStyles.deselectedSimple, serviceDisabled && styles.disabled)
      }
      style={{ width: '15rem' }}
      onClick={() => (serviceDisabled ? false : toggleService(serviceType, price, {}))}
      onKeyDown={(e) =>
        checkKeyPress(e, () => toggleService(serviceType, price, {}), {
          triggerOnSpace: true,
          disabled: serviceDisabled,
        })
      }
      role={role}
    >
      <input
        type={role}
        name="primary-services"
        className="sr-only"
        id={'primary-services-' + index}
      />
      <Card.Body>
        <img src={iconUrl()} alt="" />
        &nbsp;
        {showBetaTag && <span className="badge badge-danger">Beta</span>}
        {(serviceType === 'Alignment' || serviceType === 'Asr') && (
          <div className={css(styles.price)}>
            {price && displayPrice(price.amount, price.unit, false)}
          </div>
        )}
        <div className="mt-2">
          <b>{displayName}</b>
          {!serviceDisabled && tooltip() && (
            <OverlayTrigger
              placement="auto"
              trigger={['hover', 'focus']}
              delay={{ hide: 2000 }}
              overlay={
                <Popover>
                  <PopoverContent>
                    {tooltip()}
                    <br />
                    {learnMoreLink() ? (
                      <a href={learnMoreLink()} target="_blank" rel="noopener noreferrer">
                        Learn More
                      </a>
                    ) : (
                      ''
                    )}
                  </PopoverContent>
                </Popover>
              }
            >
              <i className={css(styles.icon) + ' fa fa-info-circle'}></i>
            </OverlayTrigger>
          )}
        </div>
        <div className={css(styles.desc)}>{description}</div>
      </Card.Body>
    </Card>
  );
}
function ServiceCard(props) {
  const { selectedServices, serviceDisabledTooltip, serviceType } = props;

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (selectedServices) {
      setSelected(
        selectedServices.filter((service) => service.serviceType === serviceType).length > 0
      );
    }
  }, [selectedServices]);

  function popoverLink(service) {
    switch (service) {
      case 'Translation':
        return (
          <p>
            <a
              className={css(styles.tooltipLink)}
              href={translationProfilesPath}
              rel="noopener noreferrer"
              target="_blank"
            >
              Create a Translation Profile
            </a>
          </p>
        );
    }
  }

  if (serviceDisabledTooltip) {
    return (
      <OverlayTrigger
        placement="auto"
        trigger={['hover', 'focus']}
        delay={{ hide: 1200 }}
        overlay={
          <Tooltip>
            <div className={css(styles.tooltip)}>
              <WithIcon icon="fa fa-ban">
                {serviceDisabledTooltip}
                {popoverLink(serviceType)}
              </WithIcon>
            </div>
          </Tooltip>
        }
      >
        <span className="d-inline-flex">
          <ServiceCardBody
            selected={selected}
            serviceDisabled={!!serviceDisabledTooltip}
            {...props}
          />
        </span>
      </OverlayTrigger>
    );
  } else {
    return (
      <ServiceCardBody selected={selected} serviceDisabled={!!serviceDisabledTooltip} {...props} />
    );
  }
}

ServiceCard.propTypes = {
  selectedServices: selectedServicesType,
  role: PropTypes.string,
  serviceDisabledTooltip: PropTypes.string,
  serviceType: PropTypes.string,
  showBetaTag: PropTypes.bool,
};

ServiceCardBody.propTypes = {
  displayName: PropTypes.string,
  description: PropTypes.string,
  index: PropTypes.number,
  price: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.string,
  }),
  selected: PropTypes.bool,
  serviceDisabled: PropTypes.bool,
  serviceType: PropTypes.string,
  toggleService: PropTypes.func,
  role: PropTypes.string,
  showBetaTag: PropTypes.bool,
};

const styles = StyleSheet.create({
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  price: {
    float: 'right',
    'font-size': '.9rem',
    'font-weight': 'bold',
    color: '#4c4c4c',
  },
  icon: {
    'padding-left': '0.2em',
  },
  desc: {
    'font-size': '.9rem',
    'margin-top': '.2rem',
  },
  tooltip: {
    textAlign: 'left',
  },
  tooltipLink: {
    color: '#DEEFF5',
    textDecoration: 'underline',
  },
});

export default ServiceCard;
