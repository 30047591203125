import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ThreeplayTable from '../common/ThreeplayTable';

const COLUMNS = [
  { Header: 'Applicant Id', accessor: 'applicantId' },
  { Header: 'Applicant', accessor: 'applicant' },
  { detailed: true, id: 'contractorName', Header: 'Referred By', accessor: 'contractor' },
  { Header: 'Applicant State', accessor: 'applicantState' },
  { Header: 'Hours Target', accessor: 'hoursTarget' },
  { Header: 'Referral State', accessor: 'referralState' },
  { Header: 'Payout', accessor: 'payout' },
  { detailed: true, Header: 'Created At', accessor: 'createdAt' },
  { detailed: true, Header: 'Updated At', accessor: 'updatedAt' },
  { detailed: true, Header: 'Expires At', accessor: 'expiresAt' },
  { detailed: true, Header: 'Completed At', accessor: 'completedAt' },
];

const getColumns = (detailed) =>
  detailed ? COLUMNS : COLUMNS.filter((column) => !column.detailed);

const ReferredApplicantsTable = ({
  data = [],
  disableSortBy = false,
  detailed = true,
  striped = true,
}) => {
  const tableData = useMemo(() => data, [data]);
  const columns = useMemo(() => getColumns(detailed), []);

  return (
    <ThreeplayTable
      striped={striped}
      disableSortBy={disableSortBy}
      columns={columns}
      data={tableData}
    />
  );
};

ReferredApplicantsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      applicantId: PropTypes.number,
      applicant: PropTypes.string,
      applicantState: PropTypes.string,
      contractor: PropTypes.string,
      hoursTarget: PropTypes.number,
      payout: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      expiresAt: PropTypes.string,
      completedAt: PropTypes.string,
    })
  ),
  detailed: PropTypes.bool,
  striped: PropTypes.bool,
  disableSortBy: PropTypes.bool,
};

export default ReferredApplicantsTable;
