import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Dashboard from './dashboard/Dashboard';
import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';

function LiveAutoCaptioning(props) {
  return (
    <ErrorBoundary component="LiveAutoCaptioning">
      <Container className="my-3" fluid>
        <Row>
          <Col sm={12}>
            <Dashboard {...props} />
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

export default LiveAutoCaptioning;
