import React, { useContext } from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import ApplicationTips from './ApplicationTips';
import BackgroundCheck from './BackgroundCheck';
import FeaturedContractors from './FeaturedContractors';
import { PAGES } from '../preliminary_application/constants';

import { localeText } from '../locales/locale';
import { LocaleContext } from '../locales/LocaleContext';

const ApplicationButtons = ({ onClick }) => {
  const locale = useContext(LocaleContext);

  return (
    <>
      <Button
        className={`${css(styles.newApplicationButton)} my-3`}
        onClick={() => onClick(PAGES.NEW_APPLICATION)}
      >
        {localeText(locale)['splash:button_start']}
      </Button>
      <br />
      <Button
        className={`${css(styles.continueApplicationButton)} my-3`}
        onClick={() => onClick(PAGES.CONTINUE_APPLICATION)}
      >
        {localeText(locale)['splash:button_continue']}
      </Button>
    </>
  );
};

ApplicationButtons.propTypes = {
  onClick: PropTypes.func,
};

const LandingPage = ({ onStart }) => {
  const locale = useContext(LocaleContext);

  return (
    <div className="full splash">
      <Container className={css(styles.intro)} fluid>
        <h1 className={css(styles.title)}>{localeText(locale)['splash:welcome']}</h1>
        <p>{localeText(locale)['splash:welcome_benefits']}</p>
        <ApplicationButtons onClick={onStart} />
      </Container>

      <Container>
        <section>
          <h2 className={css(styles.about)}>
            {localeText(locale)['splash:about_this_role_title']}
          </h2>
          <p>{localeText(locale)['splash:about_this_role']}</p>
          <section>
            <p>{localeText(locale)['splash:benefits_title']}</p>
            <ul>
              {localeText(locale)['splash:benefits'].map((benefit, index) => {
                return <li key={index}>{benefit}</li>;
              })}
            </ul>
          </section>
        </section>

        <section className={'mb-5 pb-3'}>
          <ApplicationTips />
        </section>

        <Row>
          <Col>
            <section>
              <p>{localeText(locale)['splash:requirements_title']}</p>
              <ul>
                {localeText(locale)['splash:requirements'].map((requirement, index) => {
                  return <li key={index}>{requirement}</li>;
                })}
              </ul>
            </section>

            <ApplicationButtons onClick={onStart} />

            <BackgroundCheck />
          </Col>
          <Col className={'pl-4'}>
            <FeaturedContractors />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

LandingPage.propTypes = {
  onStart: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  intro: {
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    padding: '3em',
  },
  title: {
    margin: 'auto',
    maxWidth: '675px',
    paddingBottom: '0.5em',
  },
  newApplicationButton: {
    margin: 'auto',
    backgroundColor: '#E6006F',
    borderColor: '#E6006F',
    fontWeight: 'bold',
  },
  continueApplicationButton: {
    color: '#E6006F',
    backgroundColor: 'white',
    borderWidth: 'medium',
    borderColor: '#E6006F',
    fontWeight: 'bold',
  },
  about: {
    fontSize: '1em',
    textTransform: 'uppercase',
  },
});

export default LandingPage;
