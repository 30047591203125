import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { resetPasswordPath } from '~/helpers/paths';
import { userLogger } from '~/logic/UserLogger';

function ForgotPasswordModal(props) {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [fetching, setFetching] = useState(false);

  async function handleSubmit(event) {
    setFetching(true);
    event.preventDefault();

    const form = event.target;
    const data = new FormData(form);
    data.append('authenticity_token', props.csrfToken);
    const response = await fetch(resetPasswordPath, {
      method: 'POST',
      body: data,
    });
    setFetching(false);
    setDisableSubmit(true);

    if (response.ok) {
      props.onClose({
        status: 'success',
        title: 'Password Request Email Sent',
        message: `If ${data.get('identifier')} exists as a 3Play Media account,
          follow the instructions in your email to reset your password`,
      });
      userLogger.logEvent('Login', 'Password reset request submitted', { status: 'ok' });
    } else {
      props.onClose({
        status: 'error',
        title: 'Something went wrong',
        message: 'Please try again. Contact support@3playmedia.com if this issue persists.',
      });
      userLogger.logEvent('Login', 'Password reset request submitted', { status: 'error' });
    }
  }

  return (
    <Modal show={props.show} onHide={() => props.onClose()}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Your Password?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please enter your username or email address. If the username or email exists, you will
            receive a link at the associated email address to reset your password.
          </p>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Username or Email</Form.Label>
            <Form.Control
              name="identifier"
              type="text"
              placeholder="Username or Email Address"
              onChange={() => setDisableSubmit(event.target.value == '')}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onClose()}>
            Back to Login
          </Button>
          <Button variant="primary" type="submit" disabled={disableSubmit || fetching}>
            {fetching ? 'Please wait...' : 'Send Reset Link'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

ForgotPasswordModal.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ForgotPasswordModal;
