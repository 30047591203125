import { ErrorLoggingEvent, FullLogger, LoggingEvent } from './_types';

/** A logger that logs to the console. To be used in development */
export class ConsoleLogger implements FullLogger {
  /** Triggers console logs with an info type and the logging event */
  info(event: LoggingEvent) {
    console.info(event);
  }

  /** Triggers console logs with a warn type and the logging event */
  warn(event: LoggingEvent) {
    console.warn(event);
  }

  /** Triggers console logs with an error type and the logging event */
  error(event: ErrorLoggingEvent) {
    console.error(event);
  }
}
