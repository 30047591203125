import React from 'react';
import PropTypes from 'prop-types';
import FileFormatsContainer from './FileFormatsContainer';
import FinalizeDownload from './FinalizeDownload';
import TranscriptPreview from './TranscriptPreview';

import { Row, Col } from 'react-bootstrap';

class DownloadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formatInfo: this.props.formatInfo,
      formAuthToken: this.props.formAuthToken,
    };
    this.toggleSelectedFormat = this.toggleSelectedFormat.bind(this);
    this.updateFormatSettings = this.updateFormatSettings.bind(this);
  }

  updateFormatSettings(formatType, settings) {
    const formatInfoToUpdate = this.state.formatInfo;
    formatInfoToUpdate[formatType]['settings'] = settings;
    this.setState({
      formatInfo: formatInfoToUpdate,
    });
  }

  handleFavoriteFormats(formatInfo, format) {
    const favoriteFormats = formatInfo['favoriteFormats'];
    if (favoriteFormats['formats'].length === 0) {
      return formatInfo;
    }
    if (
      Object.keys(favoriteFormats['formats']).find(
        (key) => favoriteFormats['formats'][key]['id'] == format['id']
      )
    ) {
      formatInfo = this.toggleFormat(formatInfo, 'favoriteFormats', format);
    }
    return formatInfo;
  }

  toggleFormat(formatInfo, formatType, format) {
    const selectedFormats = formatInfo[formatType]['selectedFormats'];

    if (
      Object.keys(selectedFormats).find((key) => selectedFormats[key]['id'] == format['id']) !=
      undefined
    ) {
      // Remove it
      formatInfo[formatType]['selectedFormats'] = formatInfo[formatType]['selectedFormats'].filter(
        (f) => {
          return f['id'] !== format['id'];
        }
      );
    } else {
      // Add it
      formatInfo[formatType]['selectedFormats'].push(format);
    }

    if (formatType !== 'favoriteFormats' && formatInfo['favoriteFormats'] !== undefined) {
      formatInfo = this.handleFavoriteFormats(formatInfo, format);
    }
    return formatInfo;
  }

  determineFormatType(format) {
    for (let i = 0; i < Object.keys(this.state.formatInfo).length; i++) {
      var formatType = undefined;
      const formatKey = Object.keys(this.state.formatInfo)[i];
      var formats = [];
      if (formatKey === 'favoriteFormats') {
        continue;
      } else {
        formats = this.state.formatInfo[formatKey]['formats'];
        if (Object.keys(formats).find((key) => formats[key]['id'] === format['id'])) {
          formatType = formatKey;
        }
      }
      if (formatType !== undefined) {
        return formatType;
      }
    }
    return formatType;
  }

  toggleSelectedFormat(formatType, format) {
    let formatInfo = this.state.formatInfo;
    if (formatType == 'favoriteFormats') {
      // We have to determine which format type the favorite format belongs to in order
      // to remove it from the appropriate format type's selected formats array.
      formatType = this.determineFormatType(format);
    }
    formatInfo = this.toggleFormat(formatInfo, formatType, format);

    this.setState({ formatInfo: formatInfo });
  }

  render() {
    const isBulkDownload = this.props.isDownloadMany;

    return (
      <React.Fragment>
        <Row>
          <Col md={8}>
            <FileFormatsContainer
              formatInfo={this.state.formatInfo}
              toggleSelectedFormat={this.toggleSelectedFormat}
              bulk={isBulkDownload}
              mediaFiles={this.props.mediaFiles}
              formAuthToken={this.state.formAuthToken}
              updateFormatSettings={this.updateFormatSettings}
            />
          </Col>
          <Col>
            {isBulkDownload ? (
              <FinalizeDownload
                toggleSelectedFormat={this.toggleSelectedFormat}
                formatInfo={this.state.formatInfo}
                mediaFiles={this.props.mediaFiles}
                formAuthToken={this.state.formAuthToken}
                updateFormatSettings={this.updateFormatSettings}
                emailAddress={this.props.emailAddress}
              />
            ) : (
              <TranscriptPreview
                mediaFiles={this.props.mediaFiles}
                transcriptContent={this.props.transcriptContent}
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

DownloadForm.propTypes = {
  mediaFiles: PropTypes.object,
  formAuthToken: PropTypes.string,
  isDownloadMany: PropTypes.bool,
  formatInfo: PropTypes.object,
  transcriptContent: PropTypes.string,
  emailAddress: PropTypes.string,
};
export default DownloadForm;
