import React from 'react';
import PropTypes from 'prop-types';

import Table from 'react-bootstrap/Table';

function Wordlist({ keyboardShortcutsDispatcher, wordlist }) {
  return (
    <div className="wordlist-container">
      <h2 className="swatei-title">Wordlist</h2>
      <Table>
        <tbody>
          {wordlist.map((word) => {
            return (
              <tr
                className="cursor-pointer text-primary"
                onClick={() => keyboardShortcutsDispatcher({ type: 'create', command: word })}
                key={word}
              >
                <td className="swatei-bold-text">{word}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

Wordlist.propTypes = {
  keyboardShortcutsDispatcher: PropTypes.func,
  wordlist: PropTypes.arrayOf(PropTypes.string),
};

export default Wordlist;
