import { useEffect, useRef } from 'react';

import { AudioRecorder } from '../AudioRecorder';
import { uploadAudioChunk } from '../audioUploader';

const MIME_TYPE = `audio/webm;codecs="opus"`;

export const useAudioRecorder = ({
  swateiJobId,
  audioFileInterval,
}: {
  swateiJobId: number;
  audioFileInterval: number;
}) => {
  const audioRecorderRef = useRef<AudioRecorder | null>(null);

  useEffect(() => {
    const handleAudioFile = (file: File) => {
      void uploadAudioChunk(swateiJobId, file);
    };

    audioRecorderRef.current = new AudioRecorder(handleAudioFile, {
      audioFileInterval,
      mediaRecorderOptions: { mimeType: MIME_TYPE },
    });

    return () => {
      if (audioRecorderRef.current) {
        audioRecorderRef.current.stopRecording();
        audioRecorderRef.current = null;
      }
    };
  }, [audioFileInterval, swateiJobId]);

  const startRecording = () => {
    if (audioRecorderRef.current) {
      void audioRecorderRef.current.startRecording();
    }
  };

  const stopRecording = () => {
    if (audioRecorderRef.current) {
      audioRecorderRef.current.stopRecording();
    }
  };

  return { startRecording, stopRecording };
};
