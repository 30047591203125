import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import ModalErrorBoundary from '~/components/app/common/error_boundaries/ModalErrorBoundary';

import AsrWordlistModal from './AsrWordlistModal';
import EditTable from './EditTable';
import RemoveWordlistModal from './RemoveWordlistModal';

import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';

import { threeplayApi } from '~/logic/ThreeplayApi';
import { availableWordlistQuery } from './data/queries';
import { removeWordlistMutation, saveWordlistMutation } from './data/mutations';

function Settings() {
  const [error, setError] = useState(false);
  const [editingList, setEditingList] = useState(false);
  const [invalidWords, setInvalidWords] = useState([]);
  const [resourceOptions, setResourceOptions] = useState([]);
  const [resourceWithWordlist, setResourceWithWordlist] = useState({
    account: [],
    project: [],
    folder: [],
  });
  const [showModal, setShowModal] = useState({ asrWordlist: false, removeWordlist: false });
  const [successAlert, setSuccessAlert] = useState(false);
  const [wordlists, setWordlists] = useState({ account: [], project: [], folder: [] });
  const [wordlistAssignment, setWordlistAssignment] = useState({});
  const [wordlistToDelete, setWordlistToDelete] = useState({});

  const WORDLIST_RESOURCE_TYPE = ['account', 'project', 'folder'];

  useEffect(() => {
    getWordlistCollection();
  }, []);

  function openWordlistModal(resourceType, resourceId, wordlistAssignment) {
    const resource = ['account', 'project'].includes(resourceType)
      ? Number(wordlists[resourceType][0].id)
      : resourceId;
    setWordlistAssignment((prevState) => ({
      ...prevState,
      name: wordlistAssignment ? wordlistAssignment.name : '',
      resourceType: resourceType,
      resourceId: resource,
      wordlist: wordlistAssignment ? wordlistAssignment.words : '',
      wordlistId: wordlistAssignment ? Number(wordlistAssignment.id) : null,
    }));
    setResourceOptions(wordlists[resourceType]);
    setEditingList(resourceId !== null);
    setShowModal((prevState) => ({ ...prevState, asrWordlist: true }));
  }

  function saveWordlist() {
    threeplayApi
      .request(saveWordlistMutation, { wordlistAssignment: wordlistAssignment })
      .then((response) => {
        if (response.data.saveWordlist.errors === null) {
          getWordlistCollection();
          const invalidWords = response.data.saveWordlist.invalidWords;
          setSuccessAlert(true);
          if (invalidWords.length > 0) {
            setInvalidWords(invalidWords);
          } else {
            setTimeout(() => {
              setShowModal((prevState) => ({ ...prevState, asrWordlist: false }));
              setSuccessAlert(false);
            }, 5000);
          }
        } else {
          setError(true);
          setSuccessAlert(false);
        }
      });
  }

  function getResourceIds(resources) {
    return resources.filter((r) => r.wordlistAssignment !== null).map((r) => Number(r.id));
  }

  function getWordlistCollection() {
    threeplayApi.request(availableWordlistQuery).then((res) => {
      const data = res.data || {};
      if (data.availableWordlistResources.accounts) {
        setWordlists((prevState) => ({
          ...prevState,
          account: data.availableWordlistResources.accounts,
        }));
        setResourceWithWordlist((prevState) => ({
          ...prevState,
          account: getResourceIds(data.availableWordlistResources.accounts),
        }));
      }
      if (data.availableWordlistResources.projects) {
        setWordlists((prevState) => ({
          ...prevState,
          project: data.availableWordlistResources.projects,
        }));
        setResourceWithWordlist((prevState) => ({
          ...prevState,
          project: getResourceIds(data.availableWordlistResources.projects),
        }));
      }
      if (data.availableWordlistResources.folders) {
        setWordlists((prevState) => ({
          ...prevState,
          folder: data.availableWordlistResources.folders,
        }));
        setResourceWithWordlist((prevState) => ({
          ...prevState,
          folder: getResourceIds(data.availableWordlistResources.folders),
        }));
      }
    });
  }

  function removeWordlist() {
    threeplayApi
      .request(removeWordlistMutation, { wordlistAssignmentId: wordlistToDelete.wordlistId })
      .then((response) => {
        if (response.data.removeWordlist.errors.length === 0) {
          setShowModal((prevState) => ({ ...prevState, removeWordlist: false }));
          getWordlistCollection();
        }
      });
  }

  function closeModal() {
    setInvalidWords([]);
    setError(false);
    setShowModal((prevState) => ({ ...prevState, asrWordlist: false }));
  }

  function checkWordlistsForResource(resource) {
    return wordlists[resource].every((resource) => resource.wordlistAssignment !== null);
  }

  function addNewWordlistButtonTooltip(resource) {
    return [
      `All ${resource}s already have an associated wordlist.
      To make changes, edit the existing wordlist for the associated ${resource}.`,
    ];
  }

  function showRemoveWordlistConfirmation(resourceType, wordlistId, wordlistName) {
    setWordlistToDelete({
      resourceType,
      wordlistId,
      wordlistName,
    });
    setShowModal((prevState) => ({ ...prevState, removeWordlist: true }));
  }

  return (
    <>
      <ErrorBoundary component="WordlistSettings">
        <Card>
          <Card.Header className="settings-card-header">ASR Wordlist Settings</Card.Header>
          <Card.Body>
            <p>
              Adding an ASR wordlist will help 3Play more accurately caption your content using the
              technical terms and phrases specified for your account, projects, and folders. This
              wordlist will be used to help prepare our ASR engine to more accurately caption or
              transcribe any orders for ASR (Live ASR not included). In addition, these wordlists
              will also help provide a higher level of accuracy as it pertains to the work 3Play
              does for full transcription services. Note that each unique account, project, or
              folder can only have one wordlist.
            </p>
            {WORDLIST_RESOURCE_TYPE.map((wordlistResource, i) => {
              const addNewButtonDisabled = checkWordlistsForResource(wordlistResource);
              return (
                <React.Fragment key={i}>
                  <h2>
                    <span className="capitalize">{wordlistResource}</span> Wordlist
                  </h2>
                  <p>
                    Wordlists uploaded at the <span className="capitalize">{wordlistResource}</span>{' '}
                    level will apply to any files within that{' '}
                    <span className="capitalize">{wordlistResource}</span>, including any currently
                    pending files.
                  </p>
                  <EditTable
                    openWordlistModal={openWordlistModal}
                    removeWordlist={removeWordlist}
                    resourceType={wordlistResource}
                    showRemoveWordlistConfirmation={showRemoveWordlistConfirmation}
                    wordlistResources={wordlists[wordlistResource]}
                  />
                  <TooltipIfErrors
                    errors={
                      addNewButtonDisabled ? addNewWordlistButtonTooltip(wordlistResource) : []
                    }
                  >
                    <Button
                      className={addNewButtonDisabled ? css(styles.disabled) : ''}
                      disabled={addNewButtonDisabled}
                      onClick={() => openWordlistModal(wordlistResource, null, null)}
                      variant="secondary"
                    >
                      <span className="capitalize">{`+ ${wordlistResource} Wordlist`}</span>
                    </Button>
                  </TooltipIfErrors>
                  <hr />
                </React.Fragment>
              );
            })}
          </Card.Body>
        </Card>
      </ErrorBoundary>
      <ModalErrorBoundary component="AsrWordlistModal">
        <AsrWordlistModal
          editingList={editingList}
          error={error}
          onClose={() => closeModal()}
          invalidWords={invalidWords}
          name={wordlistAssignment.name}
          resourceId={wordlistAssignment.resourceId}
          resourceOptions={resourceOptions}
          resourceType={wordlistAssignment.resourceType}
          resourceWithWordlist={resourceWithWordlist[wordlistAssignment.resourceType]}
          saveWordlist={saveWordlist}
          setWordlistAssignment={(key, value) =>
            setWordlistAssignment((prevState) => ({ ...prevState, [key]: value }))
          }
          show={showModal.asrWordlist}
          successAlert={successAlert}
          wordlist={wordlistAssignment.wordlist}
        />
      </ModalErrorBoundary>
      <ModalErrorBoundary component="RemoveWordlistModal">
        <RemoveWordlistModal
          closeModal={() => setShowModal((prevState) => ({ ...prevState, removeWordlist: false }))}
          removeWordlist={removeWordlist}
          resource={wordlistToDelete.resourceType}
          show={showModal.removeWordlist}
          wordlistName={wordlistToDelete.wordlistName}
        />
      </ModalErrorBoundary>
    </>
  );
}

const styles = StyleSheet.create({
  disabled: {
    pointerEvents: 'none',
  },
});

export default Settings;
