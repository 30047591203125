import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {
  hasFormErrors,
  isStringEmpty,
} from '~/components/contractor_applications/application/preliminary_application/helpers';
import { validateEmail } from '~/components/contractor_applications/application/applicationWorkflow';

const ContactInformationForm = ({
  countries = [],
  usStates = [],
  onSubmit = () => {},
  disableSubmit = false,
}) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [errors, setErrors] = useState({});

  const usa = country === 'United States';

  const serialize = () => ({
    firstname,
    lastname,
    email,
    country,
    state,
  });

  const validate = () => {
    const errors = {};

    if (isStringEmpty(firstname)) {
      errors.firstname = 'Please enter a value.';
    } else if (firstname.length > 50) {
      errors.firstname = 'First name is too long.';
    }

    if (isStringEmpty(lastname)) {
      errors.lastname = 'Please enter a value.';
    } else if (lastname.length > 50) {
      errors.lastname = 'Last name is too long.';
    }

    if (isStringEmpty(country)) {
      errors.country = 'Please enter a value.';
    }

    if (country === 'United States' && isStringEmpty(state)) {
      errors.state = 'Please enter a state.';
    }

    if (state?.length > 100) {
      errors.state = 'State is too long.';
    }

    const emailErrors = validateEmail(email);
    if (emailErrors) {
      errors.email = emailErrors;
    }

    return errors;
  };

  const handleSubmit = () => {
    setErrors({});
    const errors = validate();

    if (hasFormErrors(errors)) {
      setErrors(errors);
      return;
    }

    const data = serialize();
    onSubmit(data);
  };

  return (
    <>
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="firstname">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              className={errors.firstname && 'is-invalid'}
            />
            {errors.firstname && <Form.Text className="text-danger">{errors.firstname}</Form.Text>}
          </Form.Group>
          <Form.Group as={Col} controlId="lastname">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className={errors.lastname && 'is-invalid'}
            />
            {errors.lastname && <Form.Text className="text-danger">{errors.lastname}</Form.Text>}
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={errors.email && 'is-invalid'}
          />
          {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="country">
            <Form.Label>Country</Form.Label>
            <Form.Control
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className={errors.country && 'is-invalid'}
              list="countries"
            />
            <datalist id="countries">
              {countries.map((country, ii) => (
                <option key={ii} value={country}>
                  {country}
                </option>
              ))}
            </datalist>
            {errors.country && <Form.Text className="text-danger">{errors.country}</Form.Text>}
          </Form.Group>
          {usa && (
            <Form.Group as={Col} controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                value={state}
                onChange={(e) => setState(e.target.value)}
                className={errors.state && 'is-invalid'}
                list={'states'}
              />
              <datalist id="states">
                {usStates.map((state, ii) => (
                  <option key={ii} value={state}>
                    {state}
                  </option>
                ))}
              </datalist>
              {errors.state && <Form.Text className="text-danger">{errors.state}</Form.Text>}
            </Form.Group>
          )}
        </Form.Row>
      </Form>

      <Button disabled={disableSubmit} onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
};

ContactInformationForm.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string.isRequired),
  usStates: PropTypes.arrayOf(PropTypes.string.isRequired),
  onSubmit: PropTypes.func,
  disableSubmit: PropTypes.bool,
};

export default ContactInformationForm;
