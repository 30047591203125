import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

function DarkOverlay(props) {
  return <div className={css(styles.overlay)}>{props.children}</div>;
}

DarkOverlay.propTypes = {
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10000,
    color: '#eee',
    backgroundColor: 'rgba(0,0,0,.8)',
    textAlign: 'center',
  },
});

export default DarkOverlay;
