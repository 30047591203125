import React from 'react';
import Card from 'react-bootstrap/Card';

export const InputsNotReady = () => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Input Assets Are Not Ready</Card.Title>
        <Card.Text>
          Please wait until the inputs are completed and have finished processing. If you believe
          this is an error, please email ops@3playmedia.com
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
