import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Alert, Link } from '@threeplayground/index';
import { Page } from '@threeplayground/unstable';
import { use3PQuery } from '~/logic/unstable/use3PQuery';
import { use3PMutation } from '~/logic/unstable/use3PMutation';

import { pineapplePaths } from './pineapplePaths';
import { PineappleForm } from './PineappleForm';
import { useParams } from 'react-router-dom';
import { useVariantList } from '~/core/hooks/useVariantList';

const UPDATE_PINEAPPLE_MUTATION = `
mutation updatePineapple($where: PineappleWhereUniqueInput!, $data: PineappleUpdateInput!) {
  updatePineapple(where: $where, data: $data) {
      data {
        id
        createdAt
        updatedAt
      }
      errors { path code message }
    }
  }
`;

const PINEAPPLE_QUERY = `
query pineapple($where: PineappleWhereUniqueInput!) {
  pineapple(where: $where) {
    id
    name
    sku
    weight
  }
}
`;

export function UpdatePineapplePage() {
  const { id } = useParams();
  const variables = { where: { idEq: id } };

  const {
    data: pineappleData,
    isLoading: pineappleIsLoading,
    error: pineappleError,
  } = use3PQuery(PINEAPPLE_QUERY, {
    variables,
    extractKey: 'pineapple',
  });

  const { mutateAsync } = use3PMutation(UPDATE_PINEAPPLE_MUTATION, {
    extractKey: 'updatePineapple',
  });
  const [globalSuccessMessage, setGlobalSuccessMessage] = useState('');
  const [globalErrors, setGlobalErrors] = useState(undefined);
  const [fieldErrors, setFieldErrors] = useState(null);
  const { data: varieties, isLoading: isLoadingVarieties } = useVariantList('PINEAPPLE_VARIETY');

  const onSubmit = async (data) => {
    //TODO: Fix this mess...
    data.weight = +data.weight;
    delete data.sku;

    const {
      data: recordData,
      errors,
      globalErrors: recordGlobalErrors,
    } = await mutateAsync({
      data,
      where: {
        idEq: id,
      },
    });

    // TODO these would be nice to expose from `use3PMutation` rather than our own state.
    setGlobalErrors(recordGlobalErrors || undefined);
    setFieldErrors(errors || null);

    // Note: once we have a list page, this would likely route back to the list page instead of putting up a success message
    if (recordData && !errors) {
      setGlobalSuccessMessage(`Pineapple with ID ${recordData.id} Updated!`);
      setTimeout(() => {
        setGlobalSuccessMessage('');
      }, 5000);
      return;
    }

    globalErrors?.forEach((error) => {
      console.error('global error :>> ', error);
    });
  };

  // TODO we probably want some standard spinner for loading states
  if (pineappleIsLoading || isLoadingVarieties) {
    return <span className="spinner-border spinner-border-sm mr-1" />;
  }

  //   We def want something more standard here
  if (pineappleError || !pineappleData) {
    return 'Unable To Load Pineapple Data';
  }
  console.log(pineappleData);

  return (
    <Page fullWidth={false}>
      <Page.Title>{'Update Pineapple'}</Page.Title>
      <Page.Body>
        {globalSuccessMessage && <Alert variant="success">{globalSuccessMessage}</Alert>}
        <Link to={pineapplePaths.list.getLink()}>Back to Pineapple List</Link>
        <Row className="py-2">
          <Col>
            <PineappleForm
              fieldErrors={fieldErrors}
              globalErrors={globalErrors}
              onSubmit={onSubmit}
              initialPineappleData={pineappleData}
              disableSKU={true}
              varieties={varieties}
            />
          </Col>
        </Row>
      </Page.Body>
    </Page>
  );
}
