import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { Button, ProgressBar, Container, Row, Col } from 'react-bootstrap';

import FileUpload from '../ShowPanel/file_details/FileUpload';

import { awsUploadFileQuery } from '~/components/app/order_form/data/queries';
import { threeplayApi } from '~/logic/ThreeplayApi';

function S3UploadComponent({
  selectedFile,
  setSelectedFile,
  sourceUploaded,
  setSourceUploaded,
  setErrors,
  setSelectedFileUrl,
  mediaFile,
  supportedFormats,
}) {
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [s3Link, setS3Link] = useState('');

  useEffect(() => {
    if (sourceUploaded && s3Link) {
      setSelectedFileUrl(s3Link);
    }
  }, [s3Link, sourceUploaded, setSelectedFileUrl]);

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setErrors(["You haven't selected a file to be uploaded"]);
    } else {
      setStatusMessage('Preparing upload...');
      const awsData = await fetchS3UploadMetaData(selectedFile);
      uploadFileToS3(awsData);
    }
  };

  const fetchS3UploadMetaData = async (file) => {
    const response = await threeplayApi.request(awsUploadFileQuery, {
      fileId: mediaFile.id,
      fileName: file.name,
      type: 'reformat',
    });
    return response?.data?.project?.awsUpload;
  };

  const uploadFileToS3 = (awsData) => {
    const payload = buildFileUploadFormData(awsData);
    setS3Link(awsData.amazonUrl + '/' + awsData.key);
    setStatusMessage('Uploading file...');

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', uploadProgress, false);
    xhr.addEventListener('error', uploadFailed, false);
    xhr.addEventListener('abort', uploadCanceled, false);
    xhr.addEventListener('load', uploadComplete, false);
    xhr.open('POST', awsData.amazonUrl, true);
    xhr.send(payload);
  };

  const buildFileUploadFormData = (awsData) => {
    const formData = new FormData();
    formData.append('key', awsData.key);
    formData.append('AWSAccessKeyId', awsData.accessKey);
    formData.append('acl', 'private');
    formData.append('policy', awsData.policy);
    formData.append('signature', awsData.signature);
    formData.append('success_action_status', 200);
    formData.append('file', selectedFile);
    return formData;
  };

  const resetFile = () => {
    setUploadingProgress(0);
    setUploading(false);
    setSelectedFile({});
    setSourceUploaded(false);
  };

  const uploadSelectedFile = async () => {
    setSourceUploaded(false);
    setUploading(true);
    handleFileUpload();
  };

  const uploadComplete = () => {
    setUploadingProgress(100);
    setUploading(false);
    setSourceUploaded(true);
  };

  const uploadProgress = (evt) => {
    if (evt.lengthComputable) {
      const percentComplete = Math.round((evt.loaded * 100) / evt.total);
      setUploadingProgress(percentComplete);
      setStatusMessage('Uploading file... ' + percentComplete + '%');
    }
  };

  // Failed - Show error message
  const uploadFailed = () => {
    setErrors(['There was an error attempting to upload the file.']);
    setUploading(false);
  };

  // Cancelled - Show error message
  const uploadCanceled = () => {
    setErrors(['The upload has been canceled or the browser dropped the connection.']);
    setUploading(false);
  };

  return (
    <>
      {!sourceUploaded && !selectedFile && (
        <FileUpload supportedFormats={supportedFormats} setFile={setSelectedFile} />
      )}
      {selectedFile && !sourceUploaded && (
        <Row
          className={
            'align-items-center justify-content-center ' + css(styles.selectedFileContainer)
          }
        >
          <Col xs="auto">
            <Row>
              <h3 className="mb-3">{selectedFile?.name}</h3>
            </Row>
            <Row className="justify-content-center">
              <Button xs="auto" className="mx-1" onClick={resetFile} variant="secondary">
                Cancel
              </Button>
              <Button xs="auto" className="mx-1" onClick={uploadSelectedFile} disabled={uploading}>
                Upload File
              </Button>
            </Row>
            {uploading && (
              <Row className="justify-content-center">
                <Col>
                  {<>{statusMessage}</>}
                  <ProgressBar variant="success" now={uploadingProgress} striped />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      )}

      {sourceUploaded && (
        <Row
          className={
            'align-items-center justify-content-center ' + css(styles.selectedFileContainer)
          }
        >
          <h3 className="mb-3">{selectedFile?.name}</h3>
          <Button size="sm" onClick={resetFile} className={css(styles.removeButton)}>
            <i className="btn fa fa-times pl-4 text-danger" aria-hidden="true" />
          </Button>
        </Row>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  selectedFileContainer: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    height: '175px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#959595',
    borderStyle: 'dashed',
    backgroundColor: '#F1F1F1',
    color: '#212529',
    outline: 'none',
    padding: '20px',
  },
  removeButton: {
    backgroundColor: 'transparent',
    border: 'none',
  },
});

S3UploadComponent.propTypes = {
  selectedFile: PropTypes.object,
  setSelectedFile: PropTypes.func,
  sourceUploaded: PropTypes.bool,
  setSourceUploaded: PropTypes.func,
  setErrors: PropTypes.func,
  setSelectedFileUrl: PropTypes.func,
  mediaFile: PropTypes.object,
  supportedFormats: PropTypes.arrayOf(PropTypes.string),
};

export default S3UploadComponent;
