import { PAGES } from './constants';

export const hasFormErrors = (formErrors) => Object.keys(formErrors).length > 0;
export const isStringEmpty = (str) => !str || !str.trim();

export const startApplicationErrorsToPageRedirect = (error) => {
  switch (error) {
    case 'applicant_exists':
      return PAGES.ALREADY_SUBMITTED_APPLICATION;
    case 'send_to_waitlist':
      return PAGES.NOT_ACCEPTING_NEW_APPLICATIONS;
    case 'not_accepting_location':
      return PAGES.NOT_ACCEPTING_LOCATION;
    case 'user_email_already_exists':
      return PAGES.USER_EMAIL_EXISTS;
    default:
      return null;
  }
};

export const continueApplicationErrorsToPageRedirect = (error) => {
  switch (error) {
    case 'already_submitted':
      return PAGES.ALREADY_SUBMITTED_APPLICATION;
    case 'send_to_waitlist':
      return PAGES.NOT_ACCEPTING_NEW_APPLICATIONS;
    case 'not_accepting_location':
      return PAGES.NOT_ACCEPTING_LOCATION;
    case 'user_email_already_exists':
      return PAGES.USER_EMAIL_EXISTS;
    default:
      return null;
  }
};
