import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

import { ensembleLibrariesQuery } from '~/components/app/video_platform_integrations/data/queries';
import { refreshEnsembleLibrariesMutation } from '~/components/app/video_platform_integrations/data/mutations';
import { threeplayApi } from '~/logic/ThreeplayApi';

function EnsembleLibrariesComponent(props) {
  const [ensembleLibraries, setEnsembleLibraries] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [refreshLibrariesError, setRefreshLibrariesError] = useState(null);

  useEffect(() => {
    getEnsembleLibraries();
  }, [props.integration.id]);

  async function getEnsembleLibraries() {
    const response = await threeplayApi.request(ensembleLibrariesQuery, {
      videoPlatformIntegrationIds: [props.integration.id],
    });
    if (response.data !== null) {
      setEnsembleLibraries(
        response.data.project.linkedAccounts[0].integrations[0].ensembleLibraries
      );
    }
  }

  async function updateEnsembleLibraries() {
    setRefreshing(true);
    const response = await threeplayApi.request(refreshEnsembleLibrariesMutation, {
      videoPlatformIntegrationId: props.integration.id,
    });
    if (response.data.refreshEnsembleLibraries.errors !== null) {
      setRefreshLibrariesError(response.data.refreshEnsembleLibraries.errors[0]);
      setRefreshing(false);
    }
    if (response.data.refreshEnsembleLibraries.ensembleLibraries) {
      setRefreshLibrariesError(null);
      setEnsembleLibraries(response.data.refreshEnsembleLibraries.ensembleLibraries);
      setRefreshing(false);
    }
  }

  function errorAlert() {
    return (
      <Alert variant="danger" onClose={() => setRefreshLibrariesError(null)} dismissible>
        <Alert.Heading>Error!</Alert.Heading>
        <p>{refreshLibrariesError.message}</p>
      </Alert>
    );
  }

  function searchEnsembleVideos(selectedLibraryId) {
    props.setSearchParams({ libraryId: selectedLibraryId });
  }

  return (
    <>
      <div className="d-flex justify-content-start col-sm-4">
        <Form.Control
          as="select"
          className="mr-2"
          onChange={(e) => searchEnsembleVideos(e.target.value)}
          value={props.searchParams.libraryId}
        >
          {ensembleLibraries &&
            ensembleLibraries.map((library) => {
              return (
                <option key={library.ensembleId} value={library.ensembleId}>
                  {library.name}
                </option>
              );
            })}
        </Form.Control>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              Click this button if you have recently updated your Libraries at Ensemble and
              don&apos;t see the Library you are looking for in the list.This may take a while if
              your account has many Libraries.
            </Tooltip>
          }
          trigger={['hover', 'focus']}
        >
          <Button variant="warning" onClick={() => updateEnsembleLibraries()} disabled={refreshing}>
            <FontAwesomeIcon icon={faRedo} /> Refresh Libraries
          </Button>
        </OverlayTrigger>
      </div>
      {refreshLibrariesError && errorAlert()}
    </>
  );
}

EnsembleLibrariesComponent.propTypes = {
  integration: PropTypes.object,
  searchParams: PropTypes.object,
  setSearchParams: PropTypes.func,
};

export default EnsembleLibrariesComponent;
