import React, { ReactNode } from 'react';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  rightPad: {
    paddingRight: '8px',
  },
  smallRightPad: {
    paddingRight: '4px',
  },
  leftPad: {
    paddingLeft: '4px',
  },
});

interface WithIconProps {
  side?: 'left' | 'right' | 'closeLeft';
  icon: string;
  children: ReactNode;
}

function WithIcon({ side = 'left', icon, children }: WithIconProps) {
  if (side === 'left') {
    return (
      <>
        <span className={css(styles.rightPad)}>
          <i className={icon} />
        </span>
        {children}
      </>
    );
  } else if (side === 'right') {
    return (
      <>
        {children}
        <span className={css(styles.leftPad)}>
          <i className={icon} />
        </span>
      </>
    );
  } else if (side === 'closeLeft') {
    return (
      <>
        <span className={css(styles.smallRightPad)}>
          <i className={icon} />
        </span>
        {children}
      </>
    );
  } else {
    return null;
  }
}

export default WithIcon;
