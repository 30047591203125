import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

function PleaseWait(props) {
  const element = useRef(null);

  // Add element to the document body
  useEffect(() => {
    element.current = document.createElement('div');
    const mountPoint = document.querySelector('body');
    mountPoint.appendChild(element.current);

    return () => mountPoint.removeChild(element.current);
  }, []);

  const overlay = (
    <div className={css(styles.overlay)}>
      <h1 className={css(styles.message)}>{props.message}</h1>
    </div>
  );

  return element.current ? ReactDOM.createPortal(overlay, element.current) : null;
}

PleaseWait.propTypes = {
  message: PropTypes.string,
};

PleaseWait.defaultProps = {
  message: 'Please Wait...',
};

const fadeIn = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
};

const styles = StyleSheet.create({
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '0',
    width: '100vw',
    height: '100vh',
    padding: '15%',
    animationName: [fadeIn],
    animationDuration: '0.5s',
    animationDelay: '0.25s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'both',
    backgroundColor: '#000c',
  },
  message: {
    color: '#fff',
  },
});

export default PleaseWait;
