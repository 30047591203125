import React from 'react';

import TermsAcceptance from './terms_acceptance/TermsAcceptance';
import Welcome from './Welcome';

function ContractorTermsPage(props) {
  return (
    <Welcome {...props} limitWidth={false}>
      <TermsAcceptance {...props} termsName={'Contractor Agreement'} />
    </Welcome>
  );
}

export default ContractorTermsPage;
