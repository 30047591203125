import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { appStyles } from '~/components/app/common/styles';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { checkKeyPress } from '~/helpers/checkKeyPress';

function SearchBoxWithButton(props) {
  return (
    <InputGroup>
      <FormControl
        aria-label={props.placeholder}
        value={props.searchTerm}
        onChange={(e) => props.updateSearchTerm(e.target.value)}
        onKeyDown={(e) =>
          checkKeyPress(e, () => props.search(props.searchTerm), { disabled: !props.searchOnEnter })
        }
        placeholder={props.placeholder}
        size={props.size || 'sm'}
      />
      <InputGroup.Append>
        <Button
          className={css(appStyles.clearButton)}
          variant="link"
          onClick={() => props.clearSearchTerm()}
        >
          &times;
        </Button>
        <Button
          aria-label="Search"
          variant="primary"
          onClick={() => props.search(props.searchTerm)}
        >
          Search
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
}

SearchBoxWithButton.propTypes = {
  clearSearchTerm: PropTypes.func,
  placeholder: PropTypes.string,
  search: PropTypes.func,
  searchOnEnter: PropTypes.bool,
  searchTerm: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  updateSearchTerm: PropTypes.func,
};

export default SearchBoxWithButton;
